import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core/styles"
import InvoiceListStore from "../../../stores/InvoiceListStore"
import GenerateInvoiceFromServiceDialogStore from "../../../stores/GenerateInvoiceFromServiceDialogStore"
import UIStore from "../../../stores/UIStore"
import AddIcon from "@material-ui/icons/Add"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./invoiceFilterFields"

import moment from "moment"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import InvoiceForm from "./invoiceForm/invoiceForm"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {priceToSKPrice} from "../../../../../global/helpers/functions"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"
import api from "../../../actions/api"
import GenerateInvoiceFromServiceDialog from "../generateInvoiceFromServiceDialog/generateInvoiceFromServiceDialog"
import ExportInvoicesDialogStore from "../../../stores/ExportInvoicesDialogStore"
import ExportInvoicesDialog from "./exportInvoicesDialog"
import InvoiceFormStore from "../../../stores/InvoiceFormStore"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
// import {TextField, Checkbox, FormControlLabel} from "@material-ui/core"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class InvoiceList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false

		//Load odberateľov
		InvoiceFormStore.loadCustomers()

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				InvoiceFormStore.getInvoiceFilters(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		InvoiceFormStore.currFilters = this.form

		InvoiceFormStore.getInvoiceFilters(this.form)
	}

	onHandleRowClick = (dataRow) => {
		InvoiceListStore.set("selectedInvoiceID", dataRow.id)
		InvoiceListStore.set("showForm", true)
	}

	onHandleNewButtonClick = () => {
		InvoiceListStore.set("selectedInvoiceID", null)
		InvoiceListStore.set("showForm", true)
	}

	componentWillUnmount() {
		InvoiceListStore.reset()
	}

	getFilterHeader() {
		InvoiceFormStore.isChangedFilter(this.form.values())

		return (
			<Grid item xs container spacing={8} alignItems="center">
				<Grid item xs={3}>
					<XsInput white field={this.form.$("invoice_number")} />
				</Grid>
				<Grid item xs={4}>
					<XsSearchSelect white field={this.form.$("customer")} items={InvoiceFormStore.customers} />
				</Grid>
				<Grid item className="pb-0">
					<XsCheckbox field={this.form.$("actualOrgUnit")} />
				</Grid>
				<Grid item className="pb-0">
					<XsIconButton
						rect
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						className="xs-default xs-outline"
						onClick={this.form.onSubmit}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
			</Grid>
		)
	}

	render() {
		const applyFilters = InvoiceFormStore.filters
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<React.Fragment>
				<Grid container spacing={16}>
					{InvoiceListStore.showForm && <InvoiceForm invoiceID={InvoiceListStore.selectedInvoiceID} />}
					{!InvoiceListStore.showForm && (
						<React.Fragment>
							<Grid item container justify="space-between" alignItems="center">
								{this.getFilterHeader()}
								<Grid item xs container spacing={8} justify="flex-end">
									<Grid item>
										<XsButton
											className="xs-primary"
											text={<FormattedMessage id="Common.label.exportInvoices" />}
											icon={<i className="fal fa-file-export fa-lg"></i>}
											onClick={() => {
												ExportInvoicesDialogStore.open()
											}}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-primary"
											text={<FormattedMessage id="Invoice.form.generateFromService" />}
											icon={<i className="fal fa-save fa-lg" />}
											onClick={() => {
												GenerateInvoiceFromServiceDialogStore.open()
											}}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-success"
											text={<FormattedMessage id="Invoice.form.new" />}
											icon={<AddIcon />}
											onClick={() => InvoiceListStore.set("showForm", true)}
										/>
									</Grid>
								</Grid>
							</Grid>
							{InvoiceFormStore.changedFilters ? (
								<Grid item xs={12} className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.form.onSubmit}></i>
									<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
								</Grid>
							) : (
								<Grid item xs={12}>
									<XsTableServer
										uri={`${api.loadInvoices().getPath()}`}
										filterValue={applyFilters}
										config={{
											columnDefs: {
												typeNameExt: {
													title: <FormattedMessage id="Delivery.List.Type" />,
													type: "string",
													design: {
														width: "10%"
													},
													sortable: true,
													dbName: "type.name_ext"
												},
												number: {
													title: <FormattedMessage id="Invoice.form.number" />,
													type: "string",
													design: {
														width: "25%"
													},
													sortable: true,
													dbName: "invoice_number"
												},
												customer: {
													title: <FormattedMessage id="Invoice.form.customer" />,
													type: "string",
													design: {
														width: "20%"
													},
													sortable: true,
													dbName: "customer.name"
												},
												customername: {
													title: <FormattedMessage id="Invoice.form.customername" />,
													type: "string",
													design: {
														width: "15%"
													},
													sortable: true,
													dbName: "customer_data.name"
												},
												issuedate: {
													title: <FormattedMessage id="Invoice.form.issuedate" />,
													type: "datetime",
													design: {
														width: "10%",
														body: {
															formatter: (props) => {
																return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
															}
														}
													},
													sortable: true,
													dbName: "issue_date"
												},
												duedate: {
													title: <FormattedMessage id="Invoice.form.duedate" />,
													type: "datetime",
													design: {
														width: "10%",
														body: {
															formatter: (props) => {
																return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
															}
														}
													},
													sortable: true,
													dbName: "due_date"
												},
												totalcost: {
													title: <FormattedMessage id="Invoice.form.totalcost" />,
													type: "string",
													design: {
														width: "20%",
														header: {
															className: "xs-text-align-right"
														},
														body: {
															className: "alignRight",
															formatter: (props) => {
																return priceToSKPrice(props)
															}
														}
													},
													sortable: true,
													dbName: "total_cost"
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													this.onHandleRowClick(dataRow)
												},
												mapper: (dataRow) => {
													return {
														id: dataRow._id,
														typeNameExt: dataRow.type.name_ext,
														number: dataRow.invoice_number,
														customer: isSafe(dataRow.customer) ? dataRow.customer.name_ext : "",
														customername: isSafe(dataRow.customer_data) ? dataRow.customer_data.name : "",
														issuedate: isSafe(dataRow.issue_date) ? dataRow.issue_date : "",
														duedate: isSafe(dataRow.due_date) ? dataRow.due_date : "",
														totalcost: isSafe(dataRow.total_cost) ? `${dataRow.total_cost} €` : ""
													}
												}
											}
										}}
									/>
								</Grid>
							)}
						</React.Fragment>
					)}
					{UIStore.isFormSaving && <XsLoading overlay={false} />}
				</Grid>
				<GenerateInvoiceFromServiceDialog />
				<ExportInvoicesDialog />
			</React.Fragment>
		)
	}
}
