import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTabs from "../../../../global/ui/xsTabs/xsTabs"
import EmployeesDetailStore from "../../stores/EmployeesDetailStore"
import EmployeeInfoForm from "./employeeInfoForm/employeeInfoForm"
import EmployeeContractForm from "./employeeContractForm/employeeContractForm"
import EmployeeCardContainer from "./employeeCard/employeeCardContainer"
import EmployeeMedicalWorkerContainer from "./employeeMedicalWorker/employeeMedicalWorkerContainer"
import EmployeeUserContainer from "./employeeUser/employeeUserContainer"
import UIStore from "../../stores/UIStore"
import UIStoreAmbulance from "../../../ambulance/stores/UIStore"
import "./xs-employeesDetail.less"

@observer
export default class EmployeeDetailContainer extends React.Component {
	constructor(props) {
		UIStoreAmbulance.mainMenuTab = "employees"
		super(props)
		EmployeesDetailStore.employeeData = {}
		UIStore.employeeDetailTabs = "employeeCard"
		EmployeesDetailStore.loadEmployeeDetail(props.personId)
	}
	render() {
		// logger("employee: ",  EmployeesDetailStore.employeeData)
		let age =
			isSafe(EmployeesDetailStore.employeeData) && isSafe(EmployeesDetailStore.employeeData.age)
				? EmployeesDetailStore.employeeData.age
				: ""
		let identifier = isSafe(EmployeesDetailStore.employeeData) ? EmployeesDetailStore.employeeData.identifier : ""
		let gender =
			isSafe(EmployeesDetailStore.employeeData) && isSafe(EmployeesDetailStore.employeeData.gender)
				? EmployeesDetailStore.employeeData.gender.name_ext
				: ""
		let name = isSafe(EmployeesDetailStore.employeeData)
			? EmployeesDetailStore.employeeData.first_name + " " + EmployeesDetailStore.employeeData.last_name
			: ""
		let tabs = [
			// {
			//   label: <FormattedMessage id="EmployeeDetail.cards.employeeInfo" />,
			//   value: "employeeInfo",
			//   content:
			//     (
			//       <div id="employeeInfoForm">
			//         <EmployeeInfoForm entityId={this.props.personId} employeeId={isSafe(EmployeesDetailStore.employeeData) && isSafe(EmployeesDetailStore.employeeData.specific_rels) && isSafe(EmployeesDetailStore.employeeData.specific_rels[0]) ? EmployeesDetailStore.employeeData.specific_rels[0]._id : ""} />
			//       </div>
			//     )
			// },
			{
				label: <FormattedMessage id="EmployeeDetail.cards.card" />,
				value: "employeeCard",
				content: <EmployeeCardContainer entityId={this.props.personId} employeeId={this.props.id} />
			},
			{
				label: <FormattedMessage id="EmployeeDetail.cards.worker" />,
				value: "employeeWorker",
				content: (
					<EmployeeMedicalWorkerContainer
						entityId={this.props.personId}
						employeeId={this.props.id}
						personnelId={
							isSafe(EmployeesDetailStore.employeeData) &&
							isSafe(EmployeesDetailStore.employeeData.specific_rels) &&
							isSafe(
								EmployeesDetailStore.employeeData.specific_rels.find((i) => i._type === "EHR.Data.Entity.Personnel")
							)
								? EmployeesDetailStore.employeeData.specific_rels.find((i) => i._type === "EHR.Data.Entity.Personnel")
										._id
								: ""
						}
					/>
				)
			},
			// {
			//   label: <FormattedMessage id="EmployeeDetail.cards.attendance" />,
			//   value: "attendance",
			//   content:
			//     (
			//       <div id="attendance">
			//         attendance
			//       </div>
			//     )
			// },
			{
				label: <FormattedMessage id="EmployeeDetail.cards.contracts" />,
				value: "employeeContracts",
				content: <EmployeeContractForm entityId={this.props.personId} employeeId={this.props.id} />
			},
			{
				label: <FormattedMessage id="EmployeeDetail.cards.user" />,
				value: "user",
				content: <EmployeeUserContainer entityId={this.props.personId} />
			}
		]

		return (
			<div id="xsEmplayeesDetail">
				<Grid container direction="column" className="pr-8 pl-8 pt-4 pb-4">
					<Grid item className="xs-title">
						<h1>{name}</h1>
					</Grid>
					<Grid item container spacing={8}>
						<Grid item>
							<h5>{gender + " " + age}</h5>
						</Grid>
						<Grid item>
							<h5>
								<span>ID: </span>
								{identifier}
							</h5>
						</Grid>
					</Grid>
				</Grid>

				{isSafe(EmployeesDetailStore.employeeData) &&
					isSafe(EmployeesDetailStore.employeeData.specific_rels) &&
					isSafe(EmployeesDetailStore.employeeData.specific_rels.find((i) => i._type === "Entity.Data.Employee")) &&
					isNotEmpty(
						EmployeesDetailStore.employeeData.specific_rels.find((i) => i._type === "Entity.Data.Employee")._id
					) && <EmployeeInfoForm entityId={this.props.personId} employeeId={this.props.id} />}

				{isSafe(this.props.id) && (
					<XsTabs
						background="white"
						mainClass="xs-patient-info-tab"
						scrollButtons={true}
						value={UIStore.employeeDetailTabs}
						onChangeTab={(value) => {
							UIStore.employeeDetailTabs = value
							EmployeesDetailStore.editCardForm = false
							EmployeesDetailStore.editUserForm = false
							EmployeesDetailStore.editMedicalWorkerForm = false
						}}
						ended={true}
						tabs={tabs}
					/>
				)}
			</div>
		)
	}
}
