//@flow
import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import {withStyles} from "@material-ui/core/styles"
import classNames from "classnames"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
// import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import {injectIntl} from "react-intl"
import XsDropDownButton from "../../../../../global/ui/xsDropDownButton/xsDropDownButton"
import {Checkbox} from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

const styles = () => ({
	checkContainer: {
		float: "right"
	},
	deliveriesCheck: {
		height: "32px"
	}
})

@observer
export class TimelineRow extends React.Component {
	static defaultProps = {
		maxHeight: 250
	}

	constructor(props) {
		super(props)
		this.state = {
			checked: DataStore.isCheckedEHR(this.props.EHR)
		}
	}

	openned = false
	useAccordion = false

	componentDidMount() {
		if (this.contentElement && this.contentElement.getBoundingClientRect().height > this.props.maxHeight) {
			this.useAccordion = true
		}
	}

	componentDidUpdate() {
		if (this.state.checked != DataStore.isCheckedEHR(this.props.EHR)) {
			this.setState({
				checked: DataStore.isCheckedEHR(this.props.EHR)
			})
		}
	}

	contentElement = null

	updateOpenned = () => {
		this.openned = !this.openned
	}

	onCheck = (event, checked) => {
		const {EHR} = this.props

		this.setState(
			{
				checked: checked
			},
			() => (checked ? DataStore.addToCheckedEHR(EHR) : DataStore.removeFromCheckedEHR(EHR))
		)
	}

	render() {
		// let classValue = this.openned ? "timeLineRow hidden" : "timeLineRow"
		let classValue = "timeLineRow"
		let EHRIdentifier = ""
		let EHRType = ""
		const {openned, useAccordion} = this
		const {
			date,
			dateFormat,
			bulletClass,
			bulletTooltip,
			bulletContent,
			children,
			title,
			subTitle,
			actions,
			maxHeight,
			hideTime,
			classes,
			EHR,
			titleClass
		} = this.props
		const arrowClass = openned ? "arrow-down" : "arrow-right"
		let titleClassDiv = useAccordion ? "title accordion" : "title"
		titleClassDiv += titleClass ? ` ${titleClass}` : ""
		const contentClass = !useAccordion || openned ? "content" : "content hidden"
		const showArrow = useAccordion && title

		const allowCheckbox =
			EHR &&
			(Boolean(UIStore.overviewAllowedCheckbox.includes(EHR.type_code) && EHR.version === "final") ||
				EHR.type_code == "RX")

		// const isDeliveries = EHR && Boolean(EHR.has_deliveries)

		const isChecked = this.state.checked
		classValue += isChecked ? " xs-checked" : ""

		const locale = this.props.intl.formatMessage({id: "Application.moment.locale"})

		let checkboxTooltip = isChecked ? "Common.label.uncheckRecord" : "Common.label.checkRecord"

		if (EHR && EHR.type_code) {
			EHRIdentifier = `${EHR.type_code}-${EHR.record_id}`
			EHRType = EHR.type_code
		}

		return (
			<div className={classValue} data-ehr-identifier={EHRIdentifier} data-ehr-type={EHRType}>
				{allowCheckbox && (
					<div className={"checkRecord"}>
						{/* {isChecked && <div className={classNames(classes.checkBorder)} />} */}
						<div className={isChecked ? "xs-bg-checkRecord xs-green" : "xs-bg-checkRecord"} />
						<Tooltip
							title={this.props.intl.formatMessage({
								id: checkboxTooltip
							})}
						>
							<div className={classes.checkContainer}>
								<Checkbox
									icon={<i className={classNames(["fal", "fa-circle", "xs-circle"])} />}
									checkedIcon={<i className={classNames(["fal", "fa-check", "xs-check"])} />}
									onChange={this.onCheck}
									checked={isChecked}
								/>
							</div>
						</Tooltip>
					</div>
				)}
				<div className="leftSideCover">
					<div className={isChecked ? "leftSideBorderTop xs-checked" : "leftSideBorderTop"} />
					<div className="leftSide">
						<div className="date">
							{moment(date)
								.locale(locale)
								.format(dateFormat)}
						</div>
						<div className="day">
							{moment(date)
								.locale(locale)
								.format("dddd")}
							{!hideTime &&
								", " +
									moment(date)
										.locale(locale)
										.format("HH:mm")}
						</div>

						{isNotEmpty(bulletTooltip) ? (
							<Tooltip
								title={this.props.intl.formatMessage({
									id: bulletTooltip
								})}
							>
								<div className={bulletClass || "bullet"}>{bulletContent}</div>
							</Tooltip>
						) : (
							<div className={bulletClass || "bullet"}>{bulletContent}</div>
						)}
					</div>
				</div>
				<div className="rightSideCover xs-column">
					<div className={isChecked ? "rightSideBorderTop xs-checked" : "rightSideBorderTop"} />
					<div className="rightSide">
						<div className="rightSideContent">
							<div className={titleClassDiv} onClick={this.updateOpenned}>
								<div
									className={`text ${
										isSafe(EHR) &&
										isSafe(EHR.prescription) &&
										isSafe(EHR.prescription.validity) &&
										isSafe(EHR.prescription.repeats_number) &&
										EHR.prescription.repeats_number > 1 &&
										!EHR.prescription.validity.now
											? "greyDefault"
											: ""
									}`}
								>
									{title}
									{isSafe(EHR) &&
										isSafe(EHR.prescription) &&
										isSafe(EHR.prescription.repeats_number) &&
										EHR.prescription.repeats_number > 1 && (
											<React.Fragment>
												<Tooltip
													title={this.props.intl.formatMessage({
														id:
															isSafe(EHR.prescription.validity) && !EHR.prescription.validity.now
																? "Common.label.repeatPrescriptionInvalidated"
																: "Common.label.repeatPrescription"
													})}
												>
													<i
														className={`fas fa-redo pl-3 ${
															isSafe(EHR.prescription.validity) && !EHR.prescription.validity.now ? "redDanger" : ""
														}`}
													></i>
												</Tooltip>
											</React.Fragment>
										)}
								</div>
								{showArrow && <div className={arrowClass} />}
							</div>
							<div className="subTitle">
								{subTitle}{" "}
								{/* {isSafe(EHR) && isSafe(EHR.attachments) && EHR.attachments.length > 0 ? (
									<span>
										<br />
										<i className={`far fa-paperclip ${isDeliveries ? "attachmentWithDeliveries" : "attachment"}`} />
									</span>
								) : (
									""
								)} */}
							</div>

							<div
								className={contentClass}
								style={!openned && useAccordion ? {maxHeight: maxHeight} : {}}
								ref={(content) => {
									this.contentElement = content
								}}
							>
								{children}
							</div>
						</div>
						<div className="rightSideMenu">
							<div className={`rightSideAction`}>
								{actions && (
									<XsDropDownButton buttons={actions} />
									// <div className={`rightSideAction ${isChecked ? "checked" : ""}`}>
									// 	{actions.map((action, key) => {
									// 		// return <span key={key}>{action.label}</span>
									// 		return (
									// 			<XsIconButton
									//         rect
									//         delay={500}
									//         placement="top"
									//         key={key}
									//         className="xs-default xs-outline ml-2"
									//         onClick={() => action.onButtonClick()}
									// 				icon={isSafe(action.icon) ? action.icon : <i className="fal fa-times fa-lg"></i>}
									// 				tooltip={action.label}
									// 			/>
									// 		)
									// 	})}
									// 	{/* <XsDropDownButton buttons={actions} /> */}
									// </div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(injectIntl(TimelineRow))
