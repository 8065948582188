import React, {Component} from "react"
import {observer} from "mobx-react"

import "./xsTableServerWithFilters.less"
import XsTableServerFilters from "./xsTableServerFilters/xsTableServerFilters"
import XsTableServer from "../xsTableServer/xsTableServer"

import DataStore from "../../../modules/ambulance/stores/DataStore"

@observer
class XsTableServerWithFilters extends Component {
	constructor(props) {
		super(props)

		// nastavenie defaultnych filtrov:
		let filterValue = []
		if (isSafe(this.props.config.columnDefs)) {
			Object.keys(this.props.config.columnDefs).forEach((column) => {
				const columnDef = this.props.config.columnDefs[column]
				const dbColumnName = columnDef.dbName
				if (isSafe(columnDef.filter) && isNotEmpty(columnDef.filter.defaultValue)) {
					filterValue.push({
						associated_column: dbColumnName,
						values: [{id_value: columnDef.filter.defaultValue}]
					})
					// TODO: nastavit aj do actualFilters => nasledne optimalizacia v TODO v xsTableServerFilters
				}
			})
		}

		// nastavenie externych filtrov mimo stlpcov v tabulke
		if (isSafe(this.props.config.filterValue)) {
			this.props.config.filterValue.forEach((filter) => {
				filterValue.push(filter)
			})
		}

		if (isSafe(this.props.specFilter) && typeof this.props.specFilter === "function") {
			if (
				isSafe(this.props.specFilter) &&
				typeof this.props.specFilter == "function" &&
				isSafe(this.props.specFilter()) &&
				this.props.specFilter().length > 0
			) {
				this.props.specFilter().forEach((filter) => {
					filterValue.push(filter)
				})
			}
		}

		this.state = {
			apiFilter: isSafe(filterValue) && filterValue.length > 0 ? filterValue : null,
			actualFilters: {}
		}
	}

	// apiFilter musi vyzerat takto:
	// [{
	//   associated_column: "nazov_stlpca_podla_ktoreho_sa_filtruje",
	//   values: [{ id_value: hodnota_filtra }]
	// }]

	handleChangeFilters = (filters, atcFilter) => {
		if (atcFilter) {
			let filterValue = []

			const filterKey = Object.keys(filters)[0]
			filterValue.push({
				associated_column: filterKey,
				values: [{id_value: filters[filterKey]}]
			})

			if (isSafe(this.props.config.filterValue)) {
				this.props.config.filterValue.forEach((filter) => {
					filterValue.push(filter)
				})
			}

			if (isSafe(this.props.specFilter) && typeof this.props.specFilter === "function") {
				if (this.props.specFilter().length > 0) {
					this.props.specFilter().forEach((filter) => {
						filterValue.push(filter)
					})
				}
			}

			this.setState({
				apiFilter: isSafe(filterValue) && filterValue.length > 0 ? filterValue : null,
				actualFilters: {}
			})
		} else {
			let stateApiFilter = this.state.apiFilter

			const filterKey = Object.keys(filters)[0]

			// ak uz je aplikovany filter na tento kluc, zmenim hodnotu
			if (isSafe(stateApiFilter) && stateApiFilter.filter((x) => x.associated_column === filterKey).length === 1) {
				stateApiFilter.filter((x) => x.associated_column === filterKey)[0].values[0].id_value = filters[filterKey]
			} else {
				// ak uz je nejaky iny filter aplikovany
				if (isSafe(stateApiFilter)) {
					stateApiFilter.push({
						associated_column: filterKey,
						values: [{id_value: filters[filterKey]}]
					})
				} else {
					// inak, ak je null na zaciatku
					stateApiFilter = [
						{
							associated_column: filterKey,
							values: [{id_value: filters[filterKey]}]
						}
					]
				}
			}

			const actFilters = Object.assign(this.state.actualFilters, filters)

			this.setState({
				actualFilters: actFilters,
				apiFilter: stateApiFilter
			})
		}
	}

	render() {
		return (
			<div className="xs-table-with-filters">
				<XsTableServerFilters
					config={this.props.config}
					onChange={(filter, atcFilter) => this.handleChangeFilters(filter, atcFilter)}
					defaultFilter={this.state.actualFilters}
				/>

				<XsTableServer
					uri={this.props.uri}
					config={this.props.config}
					filterValue={this.state.apiFilter}
					postAction={(resp) => {
						const isFindSameATC = isSafe(this.props.config.options) && this.props.config.options.findSameATC
						if (
							isFindSameATC &&
							resp.rows.length > 0 &&
							isSafe(resp.rows[0].atc) &&
							DataStore.filterATCValue != resp.rows[0].atc
						) {
							DataStore.filterATCValue = resp.rows[0].atc
						}
					}}
					onRenderFooterInModal={
						this.props.onRenderFooterInModal
							? (data) => {
									if (
										isSafe(this.props.onRenderFooterInModal) &&
										typeof this.props.onRenderFooterInModal == "function"
									) {
										this.props.onRenderFooterInModal(data)
									}
							  }
							: null
					}
				/>
			</div>
		)
	}
}

export default XsTableServerWithFilters
