"use strict"

import {observable, action} from "mobx"
// import config from "../../../global/config/settings"
// import WarningStore from "../../../global/store/WarningStore"

class EHealthProgressDialogStore {
	@observable isOpen = false
	@observable isFetching = false
	@observable processes = null

	// @action getProgress() {
	// this.isFetching = true
	// this.open()

	// fetch(`${config.GW_BASE_URL}/GetProgress`, {
	// 	method: "GET"
	// })
	// 	.then((response) => response.json())
	// 	.then((data) => {
	// 		this.isFetching = false

	// 		if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
	// 			WarningStore.open(data.ErrorMessage)
	// 			this.close()
	// 		}
	// 		/**
	// 		 * Created: "/Date(1548434541745+0100)/"
	// 		 * Description: "Registrujem prijímateľa zdravotnej starostlivosti"
	// 		 */
	// 		this.processes = data
	// 	})
	// 	.catch((err) => {
	// 		this.isFetching = false
	// 		logger(err)
	// 	})
	// }

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new EHealthProgressDialogStore()
export default singleton
