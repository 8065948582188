import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"

import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"

import fields from "./patientAllergiesFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"

import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientAllergiesDialogStore from "../../../stores/PatientAllergiesDialogStore"
import UIStore from "../../../stores/UIStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PatientAllergiesDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				let request = []
				let tempAllergies = []

				if (PatientAllergiesDialogStore.isEditable && isSafe(PatientAllergiesDialogStore.allergyId)) {
					tempAllergies = DataStore.patientDTO
						.get("alergiesChips")
						.filter((allergy) => allergy._id !== PatientAllergiesDialogStore.allergyId)
				} else {
					if (isSafe(DataStore.patientDTO.get("alergiesChips"))) {
						tempAllergies = isSafe(DataStore.patientDTO.get("alergiesChips"))
							? DataStore.patientDTO.get("alergiesChips")
							: []
					}
				}

				tempAllergies.forEach((allergy) => {
					let tempAllergy = {
						active: true,
						description: allergy.allergy,
						severity: {_id: allergy.code, _type: "EHR.CL.Severity"},
						symptoms: allergy.symptoms,
						_ref: false,
						_type: "EHR.Data.Allergy",
						_id: allergy._id
					}

					if (isNotEmpty(allergy.allergenCode)) {
						tempAllergy["allergen"] = {_id: allergy.allergenCode, _type: "Entity.CL.Allergen"}
					}

					if (isNotEmpty(allergy.atcCode)) {
						tempAllergy["atc"] = {_id: allergy.atcCode, _type: "EHR.CL.ATC.Classification"}
					}

					request.push(tempAllergy)
				})

				let tempReq = {
					active: true,
					description: form.values().allergy,
					severity: {_id: form.values().severity, _type: "EHR.CL.Severity"},
					symptoms: [],
					_ref: false,
					_type: "EHR.Data.Allergy"
				}

				if (isNotEmpty(PatientAllergiesDialogStore.allergyId)) {
					tempReq._id = PatientAllergiesDialogStore.allergyId
				}

				if (isNotEmpty(form.values().allergenCode)) {
					tempReq["allergen"] = {_id: form.values().allergenCode, _type: "Entity.CL.Allergen"}
				}

				if (isNotEmpty(form.values().atcCode)) {
					tempReq["atc"] = {_id: form.values().atcCode, _type: "EHR.CL.ATC.Classification"}
				}

				let originalSymptomIds = null
				if (PatientAllergiesDialogStore.isEditable && isSafe(PatientAllergiesDialogStore.allergyId)) {
					originalSymptomIds = DataStore.patientDTO
						.get("alergiesChips")
						.filter((allergy) => allergy._id === PatientAllergiesDialogStore.allergyId)[0]
						.symptoms.map((x) => x.type._id)
				}

				form.values().symptoms.forEach((sym) => {
					let obj = {
						_id:
							isSafe(originalSymptomIds) && originalSymptomIds.includes(sym)
								? DataStore.patientDTO
										.get("alergiesChips")
										.filter((allergy) => allergy._id === PatientAllergiesDialogStore.allergyId)[0]
										.symptoms.filter((s) => s.type._id == sym)[0]._id
								: "",
						_ref: false,
						_type: "EHR.Data.Allergy.Symptom",
						active: true,
						severity: "Medium",
						type: {
							_id: sym,
							_type: "EHR.CL.Entity.Symptom"
						}
					}
					tempReq.symptoms.push(obj)
				})

				if (isSafe(originalSymptomIds)) {
					originalSymptomIds.forEach((x) => {
						if (!form.values().symptoms.includes(x)) {
							let obj = {
								_id: DataStore.patientDTO
									.get("alergiesChips")
									.filter((allergy) => allergy._id === PatientAllergiesDialogStore.allergyId)[0]
									.symptoms.filter((s) => s.type._id == x)[0]._id,
								_ref: false,
								_type: "EHR.Data.Allergy.Symptom",
								active: false,
								severity: "Medium",
								type: {
									_id: x,
									_type: "EHR.CL.Entity.Symptom"
								}
							}
							tempReq.symptoms.push(obj)
						}
					})
				}

				request.push(tempReq)

				api
					.saveAllergies(request, DataStore.patientDTO.get("patientID"))
					.call()
					.then(() => {
						api
							.loadAllergies(DataStore.patientDTO.get("patientID"))
							.call()
							.then((allergy) => {
								let allergiesRecords = []

								allergy.records.forEach((rec) => {
									allergiesRecords.push({
										_id: rec._id,
										allergy: isSafe(rec.type) ? rec.type.name_ext : isSafe(rec.description) ? rec.description : "",
										allergen: isSafe(rec.allergen) ? rec.allergen.name_ext : "",
										allergenCode: isSafe(rec.allergen) ? rec.allergen._id : null,
										atc: isSafe(rec.atc) ? rec.atc.name_ext : "",
										atcCode: isSafe(rec.atc) ? rec.atc._id : "",
										severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
										code: isSafe(rec.severity) ? rec.severity.code : "",
										symptoms: isSafe(rec.symptoms) ? rec.symptoms : []
									})
								})

								DataStore.patientDTO.set("alergiesChips", allergiesRecords)
							})

						form.reset()
						UIStore.isFormSaving = false
						PatientAllergiesDialogStore.isEditable = false
						PatientAllergiesDialogStore.allergyId = null
					})
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		GlobalStore.refreshCodeLists(["severities", "allergens", "symptoms"])
	}

	form

	onRowClick = (allergy) => {
		PatientAllergiesDialogStore.isEditable = true
		PatientAllergiesDialogStore.allergyId = allergy._id

		const symIds = allergy.symptoms.map((x) => x.type._id)
		const symptomValues = GlobalStore.CL["symptoms"].filter((x) => symIds.includes(x._id)).map((x) => x._id)

		this.form.$("allergy").set("value", allergy.allergy)
		this.form.$("severity").set("value", allergy.code)
		this.form.$("allergenCode").set("value", allergy.allergenCode)
		this.form.$("atcCode").set("value", allergy.atcCode)
		this.form.$("symptoms").set("value", symptomValues)
	}

	deletePatientAllergy = () => {
		if (isSafe(DataStore.patientDTO.get("alergiesChips"))) {
			let allergiesRecords = DataStore.patientDTO
				.get("alergiesChips")
				.filter((allergy) => allergy._id != GlobalStore.confirmationDialogParams.allergyId)

			let request = []

			allergiesRecords.forEach((allergy) => {
				let tempReq = {
					active: true,
					description: allergy.allergy,
					severity: {_id: allergy.code, _type: "EHR.CL.Severity"},
					symptoms: allergy.symptoms,
					_ref: false,
					_type: "EHR.Data.Allergy"
				}

				if (isNotEmpty(allergy.allergenCode)) {
					tempReq["allergen"] = {_id: allergy.allergenCode, _type: "Entity.CL.Allergen"}
				}

				if (isNotEmpty(allergy.atcCode)) {
					tempReq["atc"] = {_id: allergy.atcCode, _type: "EHR.CL.ATC.Classification"}
				}

				request.push(tempReq)
			})

			api
				.saveAllergies(request, DataStore.patientDTO.get("patientID"))
				.call()
				.then(() => {
					api
						.loadAllergies(DataStore.patientDTO.get("patientID"))
						.call()
						.then((allergies) => {
							let allergiesRec = []

							allergies.records.forEach((rec) => {
								allergiesRec.push({
									_id: rec._id,
									allergy: isSafe(rec.type) ? rec.type.name_ext : isSafe(rec.description) ? rec.description : "",
									allergen: isSafe(rec.allergen) ? rec.allergen.name_ext : "",
									allergenCode: isSafe(rec.allergen) ? rec.allergen._id : "",
									atc: isSafe(rec.atc) ? rec.atc.name_ext : "",
									atcCode: isSafe(rec.atc) ? rec.atc._id : "",
									severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
									code: isSafe(rec.severity) ? rec.severity.code : "",
									symptoms: isSafe(rec.symptoms) ? rec.symptoms : []
								})
							})

							DataStore.patientDTO.set("alergiesChips", allergiesRec)

							PatientAllergiesDialogStore.isEditable = false
							this.form.reset()
						})
				})
		}
	}

	UNSAFE_componentWillUpdate() {
		if (isSafe(PatientAllergiesDialogStore.allergyObj)) {
			PatientAllergiesDialogStore.insertFormData(this.form)
		}
	}

	render() {
		return (
			<Dialog
				id="xsPatientAllergiesDialog"
				open={PatientAllergiesDialogStore.isOpen}
				onClose={() => {
					PatientAllergiesDialogStore.close()
					PatientAllergiesDialogStore.isEditable = false
					PatientAllergiesDialogStore.allergyId = null
					this.form.reset()
				}}
				className="xs-base-dialog dialog-patientallergies"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientAllergiesDialogStore.close()
								PatientAllergiesDialogStore.isEditable = false
								PatientAllergiesDialogStore.allergyId = null
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-allergies fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.allergies.title" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.allergies.subtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid container spacing={8}>
								<Grid item xs={6}>
									<XsInput field={this.form.$("allergy")} />
								</Grid>
								<Grid item xs={6}>
									<SearchSelect
										field={this.form.$("severity")}
										items={GlobalStore.CL["severities"]}
										getOptionLabel={(obj) => (
											<div className="xs-row xs-flex xs-align">
												<i
													className="fas fa-circle pr-2 fontSize12"
													style={
														obj.item_order == 1
															? {color: "#39B0DD"}
															: obj.item_order == 2
															? {color: "#FFB900"}
															: obj.item_order == 3
															? {color: "#FF623F"}
															: obj.item_order == 4
															? {color: "#C6184E"}
															: {color: "#01579b"}
													}
												/>
												<span>{obj.name_ext}</span>
											</div>
										)}
										required
									/>
								</Grid>
								<Grid item xs={6}>
									<SearchSelect field={this.form.$("allergenCode")} items={GlobalStore.CL["allergens"]} />
								</Grid>
								<Grid item xs={6}>
									<XsAutocomplete
										field={this.form.$("atcCode")}
										minLengthForSearch="3"
										api={api.loadClassification}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: (
															<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
														),
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px",
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														}
													},
													name_ext: {
														title: <FormattedMessage id="Common.label.name" />,
														type: "string",
														dbName: "search_column",
														design: {
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														},
														sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<SearchSelect field={this.form.$("symptoms")} items={GlobalStore.CL["symptoms"]} />
								</Grid>
							</Grid>
						</Grid>
						{PatientAllergiesDialogStore.isEditable ? (
							<Grid item xs={12} className="xs-action xs-row xs-justify-between">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.allergies.saveAllergies" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										PatientAllergiesDialogStore.isEditable = false
										PatientAllergiesDialogStore.allergyId = null
										this.form.reset()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						) : (
							<Grid item xs={12} className="xs-action">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.allergies.addAllergies" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
							</Grid>
						)}
						<Grid item xs={12} className="xs-items-scrollbar">
							<div className="xs-bold xs-items-title xs-row">
								<FormattedMessage id="Patient.sidebar.allergies.allAllergies" />
								<div className="xs-count-items">
									{isSafe(DataStore.patientDTO.get("alergiesChips")) &&
									DataStore.patientDTO.get("alergiesChips").length > 0
										? DataStore.patientDTO.get("alergiesChips").length
										: 0}
								</div>
							</div>
							<div className="xs-items">
								{isSafe(DataStore.patientDTO.get("alergiesChips")) &&
									DataStore.patientDTO.get("alergiesChips").map((allergy, idx) => {
										let levelJSON = {level: 1, color: "#01579b"}
										if (allergy.code === "Low") {
											levelJSON = {level: 2, color: "#39B0DD"}
										} else if (allergy.code === "Medium") {
											levelJSON = {level: 3, color: "#FFB900"}
										} else if (allergy.code === "High") {
											levelJSON = {level: 4, color: "#FF623F"}
										} else if (allergy.code === "Critical") {
											levelJSON = {level: 5, color: "#C6184E"}
										} else {
											levelJSON = {level: 1, color: "#01579b"}
										}

										return (
											<div key={idx} className="xs-item xs-row xs-flex">
												<div className="xs-item-content" onClick={() => this.onRowClick(allergy)}>
													<div className="xs-column xs-flex xs-justify info">
														<div className="xs-primary-info xs-blueInfo">{allergy.allergy.shortTo(30)}</div>
														<div className="xs-secondary-info">{allergy.allergen.shortTo(35)}</div>
														<div className="xs-secondary-info">{allergy.atc.shortTo(35)}</div>
													</div>
													<div className="xs-column xs-flex xs-justify">
														<div className="xs-primary-info xs-severity">
															{[1, 2, 3, 4, 5].map((item, idx) => {
																if (idx < levelJSON.level) {
																	return <i key={idx} className="fas fa-circle" style={{color: levelJSON.color}} />
																} else {
																	return <i key={idx} className="far fa-circle" style={{color: "#78849E"}} />
																}
															})}
														</div>
														<div className="xs-secondary-info xs-severity">{allergy.severity}</div>
													</div>
												</div>
												<div
													className="xs-trash"
													data-tip
													data-for="deleteAllergy"
													onClick={() => {
														GlobalStore.openConfirmationDialog("xsDeletePatientAllergy", {allergyId: allergy._id})
														PatientAllergiesDialogStore.nameOfDeleteAllergy = allergy
													}}
												>
													<i className="fal fa-trash-alt fa-lg" />
												</div>
												<ReactTooltip id="deleteAllergy">
													<FormattedMessage id="Common.label.deleteAllergy" />
												</ReactTooltip>
											</div>
										)
									})}
							</div>
						</Grid>
						<XsConfirmationDialog
							type="danger"
							name="xsDeletePatientAllergy"
							subtext={
								<div>
									{isSafe(PatientAllergiesDialogStore.nameOfDeleteAllergy) && (
										<div>
											{isNotEmpty(PatientAllergiesDialogStore.nameOfDeleteAllergy.allergy) && (
												<div className="pb-2">
													<span className="xs-greyDefault fontSize13">
														<FormattedMessage id="Common.label.description" />:{" "}
													</span>
													<span className="xs-bold fontSize15">
														{PatientAllergiesDialogStore.nameOfDeleteAllergy.allergy}
													</span>
												</div>
											)}
											{isNotEmpty(PatientAllergiesDialogStore.nameOfDeleteAllergy.allergen) && (
												<div className="pb-2">
													<span className="xs-greyDefault fontSize13">
														<FormattedMessage id="Common.label.substance" />:{" "}
													</span>
													<span className="xs-bold fontSize15">
														{PatientAllergiesDialogStore.nameOfDeleteAllergy.allergen}
													</span>
												</div>
											)}
											{isNotEmpty(PatientAllergiesDialogStore.nameOfDeleteAllergy.atc) && (
												<div>
													<span className="xs-greyDefault fontSize13">
														<FormattedMessage id="Common.label.drug" />:{" "}
													</span>
													<span className="xs-bold fontSize15">
														{PatientAllergiesDialogStore.nameOfDeleteAllergy.atc}
													</span>
												</div>
											)}
										</div>
									)}
								</div>
							}
							headerConfig={{
								text: "Delete.allergy.header.text",
								subtext: "Delete.allergy.header.subtext",
								icon: <i className="fal fa-trash-alt fa-2x" />
							}}
							cancelBtn={{
								icon: <i className="fal fa-times fa-lg" />,
								text: "Common.btn.not.delete"
							}}
							confirmBtn={{
								icon: <i className="fal fa-trash-alt fa-lg" />,
								text: "Common.label.deleteAllergy"
							}}
							onConfirmation={() => this.deletePatientAllergy()}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
