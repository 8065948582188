import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"

import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import InvoiceSettingsDetailDialog from "./invoiceSettingsDetailDialog"
import InvoiceSettingsDialog from "./invoiceSettingsDialog"
import InvoiceSettingsStore from "../../../stores/InvoiceSettingsStore"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import ReactTooltip from "react-tooltip"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

@injectIntl
@observer
export default class InvoiceSettings extends React.Component {
	constructor(props) {
		super(props)

		InvoiceSettingsStore.loadInvoiceSettings()

		InvoiceSettingsStore.loadCustomers()
		InvoiceSettingsStore.loadOrgunit()
		InvoiceSettingsStore.loadInvoice()

		GlobalStore.refreshCodeLists(["insuranceTypes"])
	}

	// componentDidMount() {
	// 	this._isMounted = true
	// }

	// componentWillUnmount() {
	// 	this._isMounted = false
	// }

	onHandleRowClick = (dataRow) => {
		InvoiceSettingsStore.openEditDetail(dataRow.id)
	}

	render() {
		const orgUnitId = getSelectedOrgUnit()._id

		return (
			<React.Fragment>
				<Grid container direction="column" spacing={16}>
					<Grid item container justify="flex-end" spacing={8}>
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Common.label.settingInvoiceAndBatch" />}
								icon={<i className="fal fa-cog fa-lg"></i>}
								onClick={() => InvoiceSettingsStore.openInvoiceSettings()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-success"
								text="Nová šablóna"
								icon={<i className="fal fa-plus fa-lg"></i>}
								onClick={() => InvoiceSettingsStore.openCreateDetail(orgUnitId)}
							/>
						</Grid>
					</Grid>
					{
						<Grid item>
							{UIStore.isFormSaving ? (
								<XsLoading />
							) : (
								<XsTable
									config={{
										columnDefs: {
											name: {
												title: <FormattedMessage id="Invoice.form.customername" />,
												type: "string",
												design: {
													width: "70%"
												}
											},
											customer: {
												title: <FormattedMessage id="Invoice.form.customer" />,
												type: "string"
											},
											delete: {
												title: "",
												type: "action",
												design: {
													width: "42px",
													body: {
														renderer: (props) => {
															return (
																<XsIconButton
																	className="action-delete"
																	icon={
																		<span>
																			<i
																				className="fal fa-trash-alt fa-lg xs-greyDefault"
																				data-tip
																				data-for="removeInvoiceSetting"
																			/>
																			<ReactTooltip id="removeInvoiceSetting" effect="solid">
																				<FormattedMessage id="Common.label.RemoveTemplate" />
																			</ReactTooltip>
																		</span>
																	}
																	onClick={() =>
																		GlobalStore.openConfirmationDialog("xsRemoveInvoiceSetting", {
																			id: props.value
																		})
																	}
																/>
															)
														}
													}
												}
											}
										},
										options: {
											showCursor: true,
											mapper: (dataRow) => {
												return {
													name: dataRow.name,
													customer: dataRow.customer,
													id: dataRow._id,
													delete: dataRow._id
												}
											},
											onRowClick: (dataRow) => {
												this.onHandleRowClick(dataRow)
											}
										},
										dataSource: InvoiceSettingsStore.invoiceSettingsDataStore
									}}
								/>
							)}
						</Grid>
					}
				</Grid>
				<InvoiceSettingsDetailDialog />
				<InvoiceSettingsDialog />
				{InvoiceSettingsStore.isDetailSaving && <XsLoading overlay />}
				<XsConfirmationDialog
					type="danger"
					name="xsRemoveInvoiceSetting"
					text={<FormattedMessage id="InvoicesSettings.RemoveTemplate.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.RemoveTemplate"
					}}
					onConfirmation={() => InvoiceSettingsStore.deleteInvoiceSetting(GlobalStore.confirmationDialogParams.id)}
				/>
			</React.Fragment>
		)
	}
}
