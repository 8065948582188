import React, {Component} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
// import {CopyToClipboard} from "react-copy-to-clipboard"
import Copy from "copy-html-to-clipboard"

import {Tooltip, Checkbox, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsExpansionPanel from "../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

import LabResultsStore from "../../../stores/LabResultsStore"
import UIStore from "../../../stores/UIStore"

import "./labResults.less"

@injectIntl
@observer
class LabResults extends Component {
	constructor(props) {
		super(props)

		LabResultsStore.selectedLabResults = {}

		this.state = {
			copyTable: props.results
				.map(
					(row) => `<b>${row.test}:</b> ${LabResultsStore.getValue(row)} ${isNotEmpty(row.unit) ? `[${row.unit}]` : ""}`
				)
				.join(", "),
			checkAll: false,
			selectedRows: []
		}
	}

	getClass = (status) => {
		if (status == "ok") return "greenSuccess"
		if (status == "low") return "redDark"
		if (status == "high") return "redDanger"
		return "greyDark"
	}

	render() {
		return (
			<XsExpansionPanel glOpen={UIStore.isOpenAllRecordsInEHROverview} lab>
				<Grid container direction="column">
					<Grid item container justify="flex-end" className="pa-2">
						{!LabResultsStore.fullTable ? (
							<XsButton
								disabled={isEmpty(this.state.copyTable) ? true : false}
								text={<FormattedMessage id="Common.label.copyAll" />}
								className={`${isEmpty(this.state.copyTable) ? "xs-default" : "xs-success"} xs-outline`}
								icon={<i className="fal fa-copy fa-lg"></i>}
								onClick={() =>
									Copy(`<p>${this.state.copyTable}</p>`, {
										asHtml: true
									})
								}
							/>
						) : (
							<XsButton
								// disabled={this.state.selectedRows.length == 0 ? true : false}
								text={<FormattedMessage id="Common.label.copySelected" />}
								className={`xs-success xs-outline ml-2`}
								icon={<i className="fal fa-copy fa-lg"></i>}
								onClick={() => {
									LabResultsStore.copySelectedResult()
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						<table className="labResults">
							<thead>
								<tr>
									{LabResultsStore.fullTable && (
										<th>
											<Checkbox
												checked={this.state.checkAll}
												onChange={(e) => {
													let value = e.target.checked
													if (value) {
														let selectedRows = []
														this.props.results.forEach((res) => {
															selectedRows.push(res.lab_result_id)
															LabResultsStore.selectedLabResults[res.lab_result_id] = res
														})

														this.setState({
															checkAll: true,
															selectedRows: selectedRows
														})
													} else {
														this.props.results.forEach((res) => {
															delete LabResultsStore.selectedLabResults[res.lab_result_id]
														})

														this.setState({checkAll: false, selectedRows: []})
													}
												}}
											/>
										</th>
									)}
									<th className="textLeft">
										<FormattedMessage id="PatientDetail.labResults.header.test" />
									</th>
									{LabResultsStore.fullTable && (
										<th className="textRight">
											<FormattedMessage id="PatientDetail.labResults.header.low" />
										</th>
									)}
									<th className="bgWhite refValue">
										{LabResultsStore.fullTable ? (
											<FormattedMessage id="PatientDetail.labResults.header.reference" />
										) : (
											<FormattedMessage id="Common.label.value" />
										)}
									</th>
									{LabResultsStore.fullTable && (
										<th className="textRight">
											<FormattedMessage id="PatientDetail.labResults.header.high" />
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{this.props.results.map((result, idx) => {
									if (isSafe(result.value) || isNotEmpty(result.value_text)) {
										const status = LabResultsStore.getStatus(result)
										const className = this.getClass(status)
										let value = result.value
										let range_low = result.range_low
										let range_high = result.range_high

										if (result.value_is_numeric && isNotEmpty(value) && value.toString().includes(".")) {
											value = value.toString().replace(".", ",")
										}

										if (isNotEmpty(range_low) && range_low.toString().includes(".")) {
											range_low = range_low.toString().replace(".", ",")
										}

										if (isNotEmpty(range_high) && range_high.toString().includes(".")) {
											range_high = range_high.toString().replace(".", ",")
										}

										const unit = result.unit ? " [" + result.unit + "]" : ""
										return (
											<tr key={idx}>
												{LabResultsStore.fullTable && (
													<td>
														<Checkbox
															checked={this.state.selectedRows.includes(result.lab_result_id)}
															onChange={(e) => {
																let value = e.target.checked
																let selectedRows = this.state.selectedRows

																if (value) {
																	selectedRows.push(result.lab_result_id)
																	LabResultsStore.selectedLabResults[result.lab_result_id] = result

																	this.setState({
																		selectedRows: selectedRows,
																		checkAll: this.props.results.length === selectedRows.length
																	})
																} else {
																	selectedRows = this.state.selectedRows.filter((val) => val != result.lab_result_id)
																	delete LabResultsStore.selectedLabResults[result.lab_result_id]

																	this.setState({
																		selectedRows: selectedRows,
																		checkAll: false
																	})
																}
															}}
														/>
													</td>
												)}
												<td className={className} width="35%">
													<div className="xs-row xs-align">{result.test + unit}</div>
												</td>
												{LabResultsStore.fullTable && (
													<td width="12.5%">
														<div className="xs-row xs-justify-end xs-align">
															{status === "low" && <div className="xs-badge bgRedDark">{value}</div>}
														</div>
													</td>
												)}
												<td width="40%" className="bgWhite refValue">
													{status === "text" ? (
														<div className="xs-row xs-justify xs-align">
															<Tooltip title={result.value_text}>
																<div
																	className={
																		isSafe(result.value_text) && result.value_text.length > 50 ? "xs-pointer" : ""
																	}
																	onClick={() =>
																		isSafe(result.value_text) && result.value_text.length > 50
																			? LabResultsStore.openTextDialog(result.value_text)
																			: null
																	}
																>
																	{isSafe(result.value_text) && result.value_text.length > 50
																		? result.value_text.substring(0, 46) + "..."
																		: result.value_text}
																</div>
															</Tooltip>
														</div>
													) : (
														<div className="xs-row">
															{LabResultsStore.fullTable ? (
																<React.Fragment>
																	<div className="rangeLow xs-row xs-justify-end xs-align">{range_low}</div>

																	<div
																		className={`xs-row xs-justify-end ${
																			LabResultsStore.fullTable ? " value" : " xs-flex"
																		}`}
																	>
																		{status === "ok" && <div className="xs-badge bgGreenSuccess">{value}</div>}
																		{status === "noValue" && <div className="xs-badge bgGreyDark">{value}</div>}
																	</div>

																	<div className="rangeHigh xs-row xs-justify-end xs-align">{range_high}</div>
																</React.Fragment>
															) : (
																<React.Fragment>
																	<div className="rangeLow xs-row xs-justify-end xs-align">{range_low}</div>
																	<div className={`xs-row xs-justify-end xs-flex xs-bold`}>
																		{status === "low" && <div className="redDark">{value}</div>}
																		{status === "ok" && <div className="greenSuccess">{value}</div>}
																		{status === "noValue" && <div className="greyDark">{value}</div>}
																		{status === "high" && <div className="redDanger">{value}</div>}
																	</div>
																	<div className="rangeHigh xs-row xs-justify-end xs-align">{range_high}</div>
																</React.Fragment>
															)}
														</div>
													)}
												</td>
												{LabResultsStore.fullTable && (
													<td width="12.5%">
														<div className="xs-row xs-justify-end xs-align">
															{status === "high" && <div className="xs-badge bgRedDanger">{value}</div>}
														</div>
													</td>
												)}
											</tr>
										)
									}
								})}
							</tbody>
						</table>
					</Grid>
				</Grid>
			</XsExpansionPanel>
		)
	}
}

export default LabResults
