"use strict"

import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import {FormattedMessage} from "react-intl"

import CalendarHeader from "./calendarHeader"
import DailyView from "./dailyView"
import WeeklyView from "./weeklyView"
import MonthlyView from "./monthlyView"
import ResourceView from "./resourceView"
import AddEventDialogContainer from "./addEventDialogContainer"
import EditEventDialogContainer from "./editEventDialogContainer"
import OtherDayDialog from "./otherDayDialog"

import UIStore from "../../stores/UIStore"
import AddEventTour from "../tours/addEventTour"
import AddNewEventTour from "../tours/addNewEventTour"
import CalendarTour from "../tours/calendarTour"
import CalendarStore from "../../stores/CalendarStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
// import DataStore from "../../stores/DataStore"

import "./xs-calendar.less"
import {isInThePast} from "../../../../global/ui/xsDateTimePicker/helpers"

@observer
export default class CalendarContainer extends React.Component {
	constructor(props) {
		super(props)

		CalendarStore.selectedDay = moment()
		CalendarStore.getEvents(true)
	}

	// Otvorí dialóg pre zadanie novej udalosti
	openAddEventDialog = (day, hour, month, year) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (
				moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.set("hour", hour)
					.isAfter(moment())
			) {
				CalendarStore.selectedDay = moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
				AddEventDialogStore.open()
			}
		}
	}

	/**
	 * Vykreslenie udalostí do týždenného kalendára
	 * hour - hodina 0-23
	 * hourIndex - poradie hodiny
	 * date - dátum
	 * dateIndex - poradie dátumu
	 * dayClass - trieda pre oštýlovanie dňa
	 *
	 */
	renderWeekEvents = (hour, hourIndex, date, dateIndex, dayClass) => {
		let inThePastClass =
			date.isBefore(moment().add(-1, "days")) ||
			(moment().year() === date.year() &&
				moment().month() === date.month() &&
				moment().date() === date.date() &&
				moment().hour() > hour)
				? "xs-in-the-past"
				: ""

		const day = moment(date).date()
		const month = moment(date).month()
		const year = moment(date).year()
		return (
			<div
				key={`${hourIndex}_${dateIndex}`}
				onClick={() => this.openAddEventDialog(day, hour, month, year)}
				className={`${dayClass}`}
			>
				{isSafe(CalendarStore.eventsData[day]) &&
					isSafe(CalendarStore.eventsData[day][hour]) &&
					Array.isArray(CalendarStore.eventsData[day][hour]) &&
					CalendarStore.eventsData[day][hour].map((event, index) => {
						const {services, status, client, interval_from, event_note_text} = event

						const service = isSafe(services) && services.length > 0 && isSafe(services[0]) ? services[0] : null
						let bgColor = isSafe(service) && isNotEmpty(service.back_color) ? service.back_color : "#808080"
						let fontColor = isNotEmpty(service.fore_color) ? service.fore_color : undefined
						let isEventEnd = isSafe(status) && status._id === "F" ? true : false

						let inThePast = isSafe(service) ? isInThePast(date, service.duration) : false
						let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd, inThePast)

						let lastName = isNotEmpty(client.last_name) ? client.last_name.toUpperCase() : ""

						return (
							<div
								key={`${hourIndex}_${dateIndex}_${index}`}
								className={`xs-event-item ${inThePastClass}`}
								onClick={() => {
									EditEventDialogStore.isClickedEdit = true
									EditEventDialogStore.open(event)
								}}
								style={inThePastClass ? {} : {backgroundColor: colors.backgroundColor, color: colors.color}}
							>
								<div className="xs-event-info">
									<div className="xs-full-name">
										{isEmpty(client.last_name) && isEmpty(client.first_name) ? (
											client.client_text
										) : (
											<span>
												<span className="xs-lastname">{lastName}</span>
												{" " + client.first_name} ({moment(interval_from).format("HH:mm")})
											</span>
										)}
									</div>
									<div className="xs-sub-info">{client.identifier + " " + service.service_name_ext}</div>
									{isNotEmpty(event_note_text) && <div>{event_note_text}</div>}
								</div>
								{inThePast && isEventEnd && (
									<div className="xs-state-icon">
										<i className="fas fa-user-check fa-lg greenSuccess" data-tip data-for="eventEnd" />
										<ReactTooltip id="eventEnd">
											<FormattedMessage id="Common.label.eventEnd" />
										</ReactTooltip>
										{/* {!isEventEnd && (
											<span>
												<i className="fas fa-user-cog fa-lg" data-tip data-for="notEventEnd" />
												<ReactTooltip id="notEventEnd">
													<FormattedMessage id="Common.label.notEventEnd" />
												</ReactTooltip>
											</span>
										)} */}
									</div>
								)}
							</div>
						)
					})}
			</div>
		)
	}

	render() {
		return (
			<div id="xsCalendarContainer">
				<CalendarHeader />
				<div className="xs-calendar-body" id="xsCalendarBody">
					{(UIStore.calendarTimePeriod === "today" ||
						UIStore.calendarTimePeriod === "tomorrow" ||
						UIStore.calendarTimePeriod === "otherDay") && <DailyView />}
					{UIStore.calendarTimePeriod === "week" && (
						<WeeklyView highlightWeekend={true} renderEvents={this.renderWeekEvents} />
					)}
					{UIStore.calendarTimePeriod === "month" && <MonthlyView />}
					{UIStore.calendarTimePeriod === "resources" && <ResourceView />}
				</div>
				<AddEventDialogContainer />
				<EditEventDialogContainer />
				<OtherDayDialog />
				<AddEventTour />
				<AddNewEventTour />
				<CalendarTour />
			</div>
		)
	}
}
