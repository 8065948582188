import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./patientDeliveryFilterFields"
import moment from "moment"
import classnames from "classnames"

import {
	Grid,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	TableSortLabel,
	InputAdornment,
	TablePagination,
	Tooltip
} from "@material-ui/core"
// import TablePagination from "@material-ui/core/TablePagination"

import TablePaginationActions from "../../../../../global/ui/xsTableServer/xsTablePaginationActions"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"

import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

import PatientDeliveryDialogStore from "../../../stores/PatientDeliveryDialogStore"
import AddDeliveryStore from "../../../stores/AddDeliveryStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import RouterStore from "../../../../../global/store/RouterStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PatientDeliveryDialog extends React.Component {
	constructor(props) {
		super(props)

		this.reloadGrid = true

		if (isSafe(PatientDeliveryDialogStore.insureeTypesCL) && PatientDeliveryDialogStore.insureeTypesCL.length === 0) {
			PatientDeliveryDialogStore.loadInsureeTypes()
		}

		if (isSafe(PatientDeliveryDialogStore.senderTypeCL) && PatientDeliveryDialogStore.senderTypeCL.length === 0) {
			PatientDeliveryDialogStore.loadSenderType()
		}

		if (isSafe(PatientDeliveryDialogStore.refundTypesCL) && PatientDeliveryDialogStore.refundTypesCL.length === 0) {
			PatientDeliveryDialogStore.loadRefundTypes()
		}

		if (
			isSafe(PatientDeliveryDialogStore.subItemSubTypesCL) &&
			PatientDeliveryDialogStore.subItemSubTypesCL.length === 0
		) {
			PatientDeliveryDialogStore.loadSubItemSubTypes()
		}

		if (isSafe(PatientDeliveryDialogStore.payerTypesCL) && PatientDeliveryDialogStore.payerTypesCL.length === 0) {
			PatientDeliveryDialogStore.loadPayerTypes()
		}

		this.state = {
			order: false,
			orderBy: null,
			sortType: ""
		}

		this.periodPickerRef = null

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PatientDeliveryDialogStore.filterFormRef = this.form
	}

	componentDidUpdate() {
		this.reloadGrid = false
	}

	UNSAFE_componentWillReceiveProps() {
		this.reloadGrid = true
	}

	handleChangePage = (event, page) => {
		if (!PatientDeliveryDialogStore.isEditable) {
			PatientDeliveryDialogStore.page = page
			PatientDeliveryDialogStore.loadPatientDelivery()
		}
	}

	handleChangeRowsPerPage = (event) => {
		if (!PatientDeliveryDialogStore.isEditable) {
			PatientDeliveryDialogStore.rowsPerPage = event.target.value
			PatientDeliveryDialogStore.loadPatientDelivery()
		}
	}

	createSortHandler = (property) => (event) => {
		this.handleRequestSort(event, property.columnName, property.type)
	}

	handleRequestSort = (event, property, type) => {
		this.reloadGrid = true
		const orderBy = property
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		this.setState({order, orderBy, sortType: type})
	}

	stringComparer = (a, b, direction) => {
		let result
		switch (direction) {
			case "asc":
				result = (isSafe(a) ? a : "").localeCompare(isSafe(b) ? b : "")
				break
			case "desc":
				result = (isSafe(b) ? b : "").localeCompare(isSafe(a) ? a : "")
				break
			default:
				result = 0
		}
		return result
	}

	getInsuranceTooltip = (insurance_type_id) => {
		if (insurance_type_id == "GH") {
			return "Common.label.insuranceVZP"
		}
		if (insurance_type_id == "EU") {
			return "Common.label.insuranceEU"
		}
		if (insurance_type_id == "FRGN") {
			return "Common.label.foreigner"
		}
	}

	getInsuranceCodeExt = (insuranceTypeId) => {
		let insType = GlobalStore.CL["insuranceTypes"].filter((x) => x.code == insuranceTypeId)

		if (isSafe(insType) && insType.length > 0) {
			return ` (${insType[0].code_ext})`
		} else {
			return ""
		}
	}

	tableBodyRows
	tableBody() {
		const {order, orderBy, sortType} = this.state

		//let dataSource = isSafe(this.data) ? this.data : []
		let dataSource = PatientDeliveryDialogStore.deliveryList

		if (isSafe(orderBy)) {
			switch (sortType) {
				case "string":
					dataSource = dataSource.slice().sort((a, b) => this.stringComparer(a[orderBy], b[orderBy], order))
					break
				case "number":
					dataSource = dataSource.slice().sort((a, b) => this.numberComparer(a[orderBy], b[orderBy], order))
					break
				case "datetime":
					dataSource = dataSource.slice().sort((a, b) => this.dateComparer(a[orderBy], b[orderBy], order))
					break
			}
		}

		PatientDeliveryDialogStore.isValidGrid = true
		this.tableBodyRows = []
		{
			if (isSafe(dataSource) && dataSource.length > 0) {
				dataSource.forEach((currRow, idx) => {
					if (!PatientDeliveryDialogStore.removeDeliveryIds.includes(currRow._id)) {
						let row = currRow
						if (PatientDeliveryDialogStore.changedData[currRow._id]) {
							row = Object.assign({}, currRow, PatientDeliveryDialogStore.changedData[row._id])
						}
						let countSubItemsPerRow = 0
						let colorSubItemsPerRow = "#E0E5EF"

						if (PatientDeliveryDialogStore.changedKeysTest.hasOwnProperty(row._id)) {
							colorSubItemsPerRow = "#8BC34A"
							let isValid = true

							if (!isNumber(row.count) || isEmpty(row.diagnosis_code) || isEmpty(row.item_code_ext)) {
								isValid = false
							}
							Object.keys(PatientDeliveryDialogStore.changedKeysTest[row._id]).forEach((ppId) => {
								if (
									!isPrice(PatientDeliveryDialogStore.subItems[row._id][ppId].unit_price) ||
									!isNumber(PatientDeliveryDialogStore.subItems[row._id][ppId].quantity)
								) {
									isValid = false
								}

								if (
									isSafe(PatientDeliveryDialogStore.subItems[row._id][ppId].sub_type) &&
									(PatientDeliveryDialogStore.subItems[row._id][ppId].sub_type === "adidrug" ||
										PatientDeliveryDialogStore.subItems[row._id][ppId].sub_type === "adidev" ||
										PatientDeliveryDialogStore.subItems[row._id][ppId].sub_type === "adicntr")
								) {
									if (isEmpty(PatientDeliveryDialogStore.subItems[row._id][ppId].drugs)) {
										isValid = false
									}
								} else {
									if (isEmpty(PatientDeliveryDialogStore.subItems[row._id][ppId].subItemType)) {
										isValid = false
									}
								}
							})
							if (!isValid) {
								colorSubItemsPerRow = "#E91E63"
								PatientDeliveryDialogStore.isValidGrid = false
							}
							if (PatientDeliveryDialogStore.subItems.hasOwnProperty(row._id)) {
								countSubItemsPerRow = Object.keys(PatientDeliveryDialogStore.subItems[row._id]).length
							}
						} else {
							countSubItemsPerRow = row.sub_items.length
						}
						// mapper flat napisat, osetrit nulovanie pri cancel a save a pri  search dat flag na renderovanie kvoli novemu riadku

						this.tableBodyRows.push(
							<TableRow key={idx}>
								<TableCell width="100px">
									{isSafe(row.created_at) ? moment(row.created_at).format("DD.MM.YYYY") : ""}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<React.Fragment>
											<div>{row.person_name + " " + this.getInsuranceCodeExt(row.insurance_type_id)}</div>
											<div>{row.person_identifier}</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div
												className="xs-person-name-routing"
												onClick={() => RouterStore.push(`/patient?id=${row.person_id}`)}
											>
												{row.person_name}
												{row.insurance_type_id == "GH" ||
												row.insurance_type_id == "EU" ||
												row.insurance_type_id == "FRGN" ? (
													<Tooltip
														title={this.props.intl.formatMessage({
															id: this.getInsuranceTooltip(row.insurance_type_id)
														})}
													>
														<span className="xs-insurer"> {this.getInsuranceCodeExt(row.insurance_type_id)}</span>
													</Tooltip>
												) : (
													<span className="xs-insurer">{this.getInsuranceCodeExt(row.insurance_type_id)}</span>
												)}
											</div>
											<div>{row.person_identifier}</div>
										</React.Fragment>
									)}
								</TableCell>
								<TableCell>{row.person_age}</TableCell>
								{/* <TableCell>{row.insurer_name_ext}</TableCell> */}
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsInputSearchFilter
											api={api.loadDiagnosisCl}
											minLengthForSearch={3}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) =>
												delayedCallback(500, () => PatientDeliveryDialogStore.searchDiagnosis(value, idx))
											}
											data={PatientDeliveryDialogStore.diagnosis[idx]}
											onSearchClose={() => (PatientDeliveryDialogStore.diagnosis[idx] = [])}
											defaultValue={row.diagnosis_code + " " + row.diagnosis_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											chooseItem={(value) =>
												PatientDeliveryDialogStore.changeDelivery("diagnosis_name_ext", row._id, value._id)
											}
											modalTitle={<FormattedMessage id="Common.label.diagnosis" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										row.diagnosis_code + " " + row.diagnosis_name_ext
									) //row.diagnosis_name_ext
									}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsInputSearchFilter
											api={api.loadDeliveries}
											minLengthForSearch={1}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) =>
												delayedCallback(500, () => PatientDeliveryDialogStore.searchDeliveries(value, idx))
											}
											data={PatientDeliveryDialogStore.deliveries[idx]}
											onSearchClose={() => (PatientDeliveryDialogStore.deliveries[idx] = [])}
											defaultValue={row.item_code_ext + " " + row.item_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.name_ext
											}}
											chooseItem={(value) =>
												PatientDeliveryDialogStore.changeDelivery("item_code_ext", row._id, value._id)
											}
											modalTitle={<FormattedMessage id="Delivery.list.modal.delivery.title" />}
											modalConfig={(textValue, clickHandler) => {
												// logger("textValue: ", textValue)
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										row.item_code_ext + " " + row.item_name_ext
									)}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={PatientDeliveryDialogStore.senderTypeCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={PatientDeliveryDialogStore.senderTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													PatientDeliveryDialogStore.changeDelivery("sender_type", row._id, item._id)
													PatientDeliveryDialogStore.senderTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{PatientDeliveryDialogStore.senderJSON[row.sender_type]}</span>
									)}
								</TableCell>
								<TableCell width="75px">
									{PatientDeliveryDialogStore.isEditable ? (
										<TextField
											className="xs-count"
											InputProps={{
												classes: {
													root: !isNumber(row.count) ? "xs-input-error xs-input" : "xs-input"
												}
											}}
											value={row.count}
											onChange={(e) => PatientDeliveryDialogStore.changeDelivery("count", row._id, e.target.value)}
										/>
									) : (
										<div>{row.count}</div>
									)}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={PatientDeliveryDialogStore.payerTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={PatientDeliveryDialogStore.payerTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													PatientDeliveryDialogStore.changeDelivery("payer_type", row._id, item._id)
													PatientDeliveryDialogStore.payerTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<div>{PatientDeliveryDialogStore.payerJSON[row.payer_type]}</div>
									)}
								</TableCell>
								<TableCell width="100px">{row.price_in_points}</TableCell>
								<TableCell width="100px">{row.price_in_currency}</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={PatientDeliveryDialogStore.insureeTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={PatientDeliveryDialogStore.insureeTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													PatientDeliveryDialogStore.changeDelivery("insuree_type", row._id, item._id)
													PatientDeliveryDialogStore.insureeTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{PatientDeliveryDialogStore.insureeJSON[row.insuree_type]}</span>
									)}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={PatientDeliveryDialogStore.refundTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={PatientDeliveryDialogStore.refundTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													PatientDeliveryDialogStore.changeDelivery("refund_type", row._id, item._id)
													PatientDeliveryDialogStore.refundTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{PatientDeliveryDialogStore.refundJSON[row.refund_type]}</span>
									)}
								</TableCell>
								<TableCell>
									{PatientDeliveryDialogStore.isEditable ? (
										<TextField
											className="xs-movement"
											InputProps={{
												classes: {
													root: "xs-input"
												}
											}}
											value={row.movement}
											onChange={(e) => PatientDeliveryDialogStore.changeDelivery("movement", row._id, e.target.value)}
										/>
									) : (
										<div>{row.movement}</div>
									)}
								</TableCell>
								{PatientDeliveryDialogStore.isEditable && (
									<TableCell width="40px" className="xs-align-text">
										<Tooltip
											title={this.props.intl.formatMessage({
												id:
													countSubItemsPerRow > 0
														? "Common.label.removeDeliveryContainsPP"
														: "Common.label.removeDelivery"
											})}
										>
											<i
												className={`fal fa-trash-alt fa-lg ${countSubItemsPerRow == 0 && "xs-redDanger"}`}
												onClick={() =>
													countSubItemsPerRow > 0 ? null : PatientDeliveryDialogStore.removeDelivery(row._id)
												}
											/>
										</Tooltip>
									</TableCell>
								)}
								<TableCell width="40px" className="xs-align-text">
									<XsIconButton
										className="xs-default xs-outline"
										style={{borderColor: colorSubItemsPerRow, backgroundColor: colorSubItemsPerRow, color: "#21212F"}}
										icon={countSubItemsPerRow}
										onClick={() => {
											PatientDeliveryDialogStore.createSubItemPP(row)
										}}
									/>
								</TableCell>
								{!PatientDeliveryDialogStore.isEditable && (
									<TableCell width="40px" className="xs-align-text">
										<XsIconButton
											icon={<i className="fal fa-trash-alt fa-lg redDanger" />}
											onClick={() => {
												GlobalStore.openConfirmationDialog("xsDeletePatientDelivery", {
													deliveryId: row._id
												})
											}}
										/>
									</TableCell>
								)}
							</TableRow>
						)
						// pridavanie riadku do gridy vo vynimocnych pripadoch!!!!, ak sa vyberie vykon ktoremu treba doplnujuce udaje
						if (
							PatientDeliveryDialogStore.idSubItemsRow === row._id &&
							isSafe(PatientDeliveryDialogStore.subItems) &&
							isSafe(PatientDeliveryDialogStore.subItems[row._id])
						) {
							const countSubItems = Object.keys(PatientDeliveryDialogStore.subItems[row._id]).length
							this.tableBodyRows.push(
								<TableRow key={idx + 10000}>
									<TableCell colSpan={12}>
										<Grid container direction="column">
											{Object.keys(PatientDeliveryDialogStore.subItems[row._id]).map((key, index) => {
												return (
													<Grid item xs={12} key={`${idx}_${index}`}>
														<Grid container direction="row" spacing={8} alignItems="center">
															<Grid item xs={2}>
																<XsDropdownList
																	disabled={!PatientDeliveryDialogStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Type" />*
																		</span>
																	}
																	items={PatientDeliveryDialogStore.subItemSubTypesCL}
																	renderer={(item) => {
																		return <span>{item.name_ext}</span>
																	}}
																	column="_id"
																	value={
																		isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type)
																			? PatientDeliveryDialogStore.subItems[row._id][key].sub_type
																			: "adigen"
																	}
																	chooseItem={(item) => {
																		{
																			PatientDeliveryDialogStore.changeSubItem("sub_type", row._id, key, item._id)
																		}
																	}}
																	onChange={() => {
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].unit_price)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].unit_price = ""
																		}
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].quantity)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].quantity = 1
																		}
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].drugs)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].drugs = ""
																		}
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].drugsName)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].drugsName = ""
																		}
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].description)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].description = ""
																		}
																		if (isSafe(PatientDeliveryDialogStore.subItems[row._id][key].subItemType)) {
																			PatientDeliveryDialogStore.subItems[row._id][key].subItemType = ""
																		}
																	}}
																/>
															</Grid>
															{isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type) &&
																PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "adidrug" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadDrug}
																			disabled={!PatientDeliveryDialogStore.isEditable}
																			isValid={isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => PatientDeliveryDialogStore.searchDrugs(value, key))
																			}
																			data={PatientDeliveryDialogStore.drugs[key]}
																			onSearchClose={() => (PatientDeliveryDialogStore.drugs[key] = [])}
																			defaultValue={
																				isSafe(PatientDeliveryDialogStore.subItems[row._id][key].drugs)
																					? PatientDeliveryDialogStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			// inputRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			inputRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			// valueRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			valueRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			chooseItem={(value) => {
																				PatientDeliveryDialogStore.changeSubItem("drugs", row._id, key, value._id)
																				if (isNotEmpty(value.insurer_bs_unit_price)) {
																					PatientDeliveryDialogStore.subItems[row._id][key].unit_price =
																						value.insurer_bs_unit_price
																					PatientDeliveryDialogStore.subItems[row._id][key].adorment = value.bs_unit
																				} else {
																					PatientDeliveryDialogStore.subItems[row._id][key].unit_price = ""
																				}
																			}}
																			onClear={() => {
																				PatientDeliveryDialogStore.subItems[row._id][key].unit_price = ""
																				PatientDeliveryDialogStore.subItems[row._id][key].drugsName = ""
																				PatientDeliveryDialogStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.drugs" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "90px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "150px"
																							}
																						},
																						patient_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
																							),
																							type: "string",
																							dbName: "patient_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						insurer_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
																							),
																							type: "string",
																							dbName: "insurer_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						atc: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />
																							),
																							type: "string",
																							dbName: "atc",
																							design: {
																								width: "90px"
																							}
																						}
																					},
																					filterValue: [
																						{
																							associated_column: "only_bsu",
																							predicate: "=",
																							values: [
																								{
																									id_value: 1
																								}
																							]
																						}
																					],
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						},
																						renderHeaderAsFirstRow: true
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type) &&
																PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "adidev" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadMedDev}
																			disabled={!PatientDeliveryDialogStore.isEditable}
																			isValid={isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () =>
																					PatientDeliveryDialogStore.searchMedicalDevices(value, key)
																				)
																			}
																			data={PatientDeliveryDialogStore.medicalDevices[key]}
																			onSearchClose={() => (PatientDeliveryDialogStore.medicalDevices[key] = [])}
																			defaultValue={
																				isSafe(PatientDeliveryDialogStore.subItems[row._id][key].drugs)
																					? PatientDeliveryDialogStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			chooseItem={(value) => {
																				PatientDeliveryDialogStore.changeSubItem("drugs", row._id, key, value._id)
																			}}
																			onClear={() => {
																				PatientDeliveryDialogStore.subItems[row._id][key].drugsName = ""
																				PatientDeliveryDialogStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Delivery.list.modal.help.title" />}
																			modalConfig={(textValue, clickHandler) => {
																				// logger("textValue: ", textValue)
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "225px"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type) &&
																PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "adicntr" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadCustomItem}
																			disabled={!PatientDeliveryDialogStore.isEditable}
																			isValid={isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () =>
																					PatientDeliveryDialogStore.searchCustomItems(value, key)
																				)
																			}
																			data={PatientDeliveryDialogStore.customItems[key]}
																			onSearchClose={() => (PatientDeliveryDialogStore.customItems[key] = [])}
																			defaultValue={
																				isSafe(PatientDeliveryDialogStore.subItems[row._id][key].drugs)
																					? PatientDeliveryDialogStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			chooseItem={(value) => {
																				PatientDeliveryDialogStore.changeSubItem("drugs", row._id, key, value._id)
																				api
																					.getDeliveryItemPrice(value._id, {
																						org_unit_id: getSelectedOrgUnitID(),
																						insurer_id: row.insurerId,
																						date: isNotEmpty(row.delivery_date_time)
																							? moment(row.delivery_date_time).format("YYYY-MM-DD")
																							: moment().format("YYYY-MM-DD")
																					})
																					.call()
																					.then((res) => {
																						if (isSafe(res) && isNotEmpty(res.price_in_currency)) {
																							PatientDeliveryDialogStore.subItems[row._id][key].unit_price =
																								res.price_in_currency
																						} else {
																							if (isNotEmpty(value.price_in_currency)) {
																								PatientDeliveryDialogStore.subItems[row._id][key].unit_price =
																									value.price_in_currency
																							} else {
																								PatientDeliveryDialogStore.subItems[row._id][key].unit_price = ""
																							}
																						}
																					})
																			}}
																			onClear={() => {
																				PatientDeliveryDialogStore.subItems[row._id][key].unit_price = ""
																				PatientDeliveryDialogStore.subItems[row._id][key].drugsName = ""
																				PatientDeliveryDialogStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.addItems" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Common.label.code" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Common.label.name" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{((isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type) &&
																(PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "adigen" ||
																	PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "drg")) ||
																isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].sub_type)) && (
																<Grid item xs={2}>
																	<TextField
																		disabled={!PatientDeliveryDialogStore.isEditable}
																		label={
																			<span className="xs-bold">
																				<FormattedMessage id="Delivery.List.Item" />*
																			</span>
																		}
																		value={
																			isSafe(PatientDeliveryDialogStore.subItems[row._id][key].subItemType)
																				? PatientDeliveryDialogStore.subItems[row._id][key].subItemType
																				: ""
																		}
																		onChange={(e) => {
																			PatientDeliveryDialogStore.changeSubItem(
																				"subItemType",
																				row._id,
																				key,
																				e.target.value
																			)
																			// PatientDeliveryDialogStore.changeSubItem("sub_type", row._id, key, "adigen") //TODO: davat bacha, teraz to je jedno lebo adigen a drg su tie iste podmienky, ale ked bude ine, treba upravit!
																		}}
																		InputLabelProps={{
																			shrink: true
																		}}
																		InputProps={{
																			classes: {
																				root: isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].subItemType)
																					? "xs-input-error"
																					: ""
																			}
																		}}
																	/>
																</Grid>
															)}
															<Grid item xs={1}>
																<TextField
																	disabled={!PatientDeliveryDialogStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Quantity" />*
																		</span>
																	}
																	value={
																		isSafe(PatientDeliveryDialogStore.subItems[row._id][key].quantity)
																			? PatientDeliveryDialogStore.subItems[row._id][key].quantity
																			: ""
																	}
																	onChange={(e) =>
																		PatientDeliveryDialogStore.changeSubItem("quantity", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isNumber(PatientDeliveryDialogStore.subItems[row._id][key].quantity)
																				? "xs-input-error"
																				: ""
																		}
																	}}
																/>
															</Grid>
															<Grid item xs={2}>
																<TextField
																	disabled={!PatientDeliveryDialogStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.UnitPrice" />*
																		</span>
																	}
																	value={
																		isSafe(PatientDeliveryDialogStore.subItems[row._id][key].unit_price)
																			? PatientDeliveryDialogStore.subItems[row._id][key].unit_price
																			: ""
																	}
																	onChange={(e) =>
																		PatientDeliveryDialogStore.changeSubItem("unit_price", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isPrice(PatientDeliveryDialogStore.subItems[row._id][key].unit_price)
																				? "xs-input-error"
																				: ""
																		},
																		endAdornment:
																			isNotEmpty(PatientDeliveryDialogStore.subItems[row._id][key].unit_price) &&
																			isNotEmpty(PatientDeliveryDialogStore.subItems[row._id][key].adorment) ? (
																				<InputAdornment
																					position="end"
																					variant="filled"
																					className="xs-unit-price-adorment"
																				>
																					{PatientDeliveryDialogStore.subItems[row._id][key].adorment}
																				</InputAdornment>
																			) : null
																	}}
																/>
															</Grid>
															<Grid item xs={4}>
																{!(
																	(isSafe(PatientDeliveryDialogStore.subItems[row._id][key].sub_type) &&
																		(PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "adigen" ||
																			PatientDeliveryDialogStore.subItems[row._id][key].sub_type === "drg")) ||
																	isEmpty(PatientDeliveryDialogStore.subItems[row._id][key].sub_type)
																) && (
																	<TextField
																		disabled={!PatientDeliveryDialogStore.isEditable}
																		label={<FormattedMessage id="Common.label.description" />}
																		defaultValue={
																			isSafe(PatientDeliveryDialogStore.subItems[row._id][key].description)
																				? PatientDeliveryDialogStore.subItems[row._id][key].description
																				: ""
																		}
																		onChange={(e) =>
																			PatientDeliveryDialogStore.changeSubItem(
																				"description",
																				row._id,
																				key,
																				e.target.value
																			)
																		}
																		InputLabelProps={{
																			shrink: true
																		}}
																	/>
																)}
															</Grid>
															{PatientDeliveryDialogStore.isEditable && (
																<Grid item xs={1}>
																	<Grid container direction="row" justify="flex-end">
																		<Grid item>
																			{countSubItems === index + 1 && (
																				<XsIconButton
																					className="xs-success xs-outline"
																					icon={<i className="fal fa-plus" />}
																					onClick={() => PatientDeliveryDialogStore.addSubItem(row._id)}
																				/>
																			)}
																			<XsIconButton
																				className="xs-danger xs-outline"
																				icon={<i className="fal fa-times" />}
																				onClick={() => PatientDeliveryDialogStore.deleteSubItem(row._id, key)}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
															)}
														</Grid>
													</Grid>
												)
											})}
										</Grid>
									</TableCell>
								</TableRow>
							)
						}
					}
				})
			} else {
				this.tableBodyRows.push(
					<TableRow key={1}>
						<TableCell className="textCenter xs-bold" colSpan={14}>
							<FormattedMessage id="Table.NoData" />
						</TableCell>
					</TableRow>
				)
			}
		}
	}

	render() {
		PatientDeliveryDialogStore.isChangedFilter(this.form.values())

		const {order, orderBy} = this.state

		if (this.reloadGrid) {
			this.data = PatientDeliveryDialogStore.deliveryList
		}

		this.tableBody()

		return (
			<Dialog
				id="xsPatientDeliveryDialog"
				open={PatientDeliveryDialogStore.isOpen}
				onClose={() => {
					PatientDeliveryDialogStore.close()
					this.form.reset()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog"
				}}
				disableBackdropClick={true}
				fullScreen
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientDeliveryDialogStore.close()
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-hand-holding-usd fa-2x" />
						</div>
						<div className="xs-header-title">
							{this.props.intl.formatMessage({id: "Common.label.overviewPatientDelivery"})}
						</div>
						{
							<div className="xs-header-title">
								{`${DataStore.patientDTO.get("patient").full_name} (${
									DataStore.patientDTO.get("patient").identifier
								}), ${DataStore.patientDTO.get("insurer")}`}
							</div>
						}
					</div>
				</DialogTitle>
				<DialogContent className="pa-4 hidden bgWhite">
					<div className="xs-column maxHeight">
						<div style={{height: "75px"}}>
							<Grid container spacing={8} alignItems="center">
								<Grid item xs={1}>
									<XsDateTimePicker white field={this.form.$("from")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={1}>
									<XsDateTimePicker white field={this.form.$("to")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={2}>
									<XsSearchSelect
										white
										field={this.form.$("payer_type")}
										items={PatientDeliveryDialogStore.payerTypesCL}
										required
									/>
								</Grid>
								<Grid item xs={2}>
									<XsInput white field={this.form.$("delivery_code")} />
								</Grid>
								<Grid item xs={1} className="pb-0">
									<XsIconButton
										rect
										tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
										className="xs-default xs-outline"
										onClick={() => {
											if (isNotEmpty(this.form.$("from").value) && isNotEmpty(this.form.$("to").value)) {
												PatientDeliveryDialogStore.loadPatientDelivery(true)
											} else {
												GlobalStore.setNotificationMessage(
													this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
												)
											}
										}}
										icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
									/>
								</Grid>
								<Grid item xs={5} container justify="flex-end" spacing={16}>
									{PatientDeliveryDialogStore.isEditable ? (
										<React.Fragment>
											<Grid item>
												<XsButton
													className="xs-success"
													text={<FormattedMessage id="Common.label.save" />}
													icon={<i className="far fa-save-alt fa-lg"></i>}
													onClick={() => {
														if (PatientDeliveryDialogStore.isValidGrid) {
															PatientDeliveryDialogStore.saveChanges()
															PatientDeliveryDialogStore.isEditable = false
															PatientDeliveryDialogStore.changedData = {}
															PatientDeliveryDialogStore.changedKeysTest = {}
															PatientDeliveryDialogStore.removeDeliveryIds = []
														} else {
															GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
														}
													}}
												/>
											</Grid>
											<Grid item>
												<XsButton
													className="xs-danger xs-outline"
													onClick={() => {
														PatientDeliveryDialogStore.resetGrid()
													}}
													text={<FormattedMessage id="Common.label.cancel" />}
													icon={<i className="fal fa-times fa-lg" />}
												/>
											</Grid>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Grid item>
												<XsButton
													className={classnames("xs-outline", {
														"xs-primary": !PatientDeliveryDialogStore.changedFilters,
														"xs-default": PatientDeliveryDialogStore.changedFilters
													})}
													disabled={PatientDeliveryDialogStore.changedFilters}
													text={<FormattedMessage id="Common.label.edit" />}
													onClick={() => {
														PatientDeliveryDialogStore.isEditable = true
														PatientDeliveryDialogStore.idSubItemsRow = ""
													}}
													icon={<i className="far fa-pencil-alt fa-lg"></i>}
												/>
											</Grid>
											<Grid item>
												<XsButton
													className={classnames({
														"xs-success": !PatientDeliveryDialogStore.changedFilters,
														"xs-default xs-outline": PatientDeliveryDialogStore.changedFilters
													})}
													disabled={PatientDeliveryDialogStore.changedFilters}
													onClick={() => AddDeliveryStore.open("patientDelivery")}
													text={<FormattedMessage id="Common.label.addDelivery" />}
													icon={<i className="fal fa-plus" />}
												/>
											</Grid>
										</React.Fragment>
									)}
								</Grid>
							</Grid>
						</div>
						<div className="overflowY maxHeight">
							<Grid container>
								{PatientDeliveryDialogStore.isFetchingData ? (
									<XsLoading />
								) : PatientDeliveryDialogStore.changedFilters ? (
									<Grid item xs={12} className="xs-table-no-data pa-3 borderRadius-1 mt-8">
										<i
											className="far fa-search fa-lg mr-3 xs-pointer"
											onClick={() => {
												if (isNotEmpty(this.form.$("from").value) && isNotEmpty(this.form.$("to").value)) {
													PatientDeliveryDialogStore.loadPatientDelivery(true)
													PatientDeliveryDialogStore.page = 0
												} else {
													GlobalStore.setNotificationMessage(
														this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
													)
												}
											}}
										></i>
										<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
									</Grid>
								) : (
									<Grid container item>
										<Grid item xs={12}>
											<Table className="xs-customTable">
												<TableHead>
													<TableRow>
														<TableCell>
															<FormattedMessage id="Common.label.date" />
														</TableCell>
														<TableCell
															sortDirection={order}
															onClick={
																!PatientDeliveryDialogStore.isEditable
																	? this.createSortHandler({columnName: "person_name", type: "string"})
																	: null
															}
														>
															{order === "asc" || order === "desc" ? (
																<TableSortLabel active={orderBy === "person_name"} direction={order}>
																	<FormattedMessage id="Delivery.List.Person" />
																</TableSortLabel>
															) : (
																<FormattedMessage id="Delivery.List.Person" />
															)}
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.Age" />
														</TableCell>
														{/* <TableCell>
															<FormattedMessage id="Common.label.insurer" />
														</TableCell> */}
														<TableCell>
															<FormattedMessage id="Delivery.List.Diagnosis" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Common.label.delivery" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.SenderType" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.Count" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Common.label.payerType" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.Points" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.Currency" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.InsureeType" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.RefundType" />
														</TableCell>
														<TableCell>
															<FormattedMessage id="Delivery.List.Movement" />
														</TableCell>
														{PatientDeliveryDialogStore.isEditable && (
															<TableCell width="40px" className="xs-align-text"></TableCell>
														)}
														<TableCell className="xs-align-text">
															<FormattedMessage id="Delivery.List.PP" />
														</TableCell>
														{PatientDeliveryDialogStore.isEditable && <TableCell className="xs-align-text"></TableCell>}
													</TableRow>
												</TableHead>
												<TableBody>{this.tableBodyRows}</TableBody>
											</Table>
										</Grid>
										<Grid item xs={12}>
											<div className="xs-grid-footer">
												{!PatientDeliveryDialogStore.isEditable && (
													<TablePagination
														component="div"
														className="xs-table-pagination"
														// count={isSafe(this.data) ? this.data.length : 0}
														count={PatientDeliveryDialogStore.rowCountFull}
														rowsPerPage={PatientDeliveryDialogStore.rowsPerPage}
														rowsPerPageOptions={[5, 10, 25, 50]}
														labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
														labelDisplayedRows={(pager) =>
															`${pager.from}-${pager.to} ${this.props.intl.formatMessage({
																id: "Table.Pagination.Of"
															})} ${pager.count}`
														}
														page={PatientDeliveryDialogStore.page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActions}
													/>
												)}
											</div>
										</Grid>
									</Grid>
								)}
							</Grid>
						</div>
					</div>
				</DialogContent>
				<DialogActions className="xs-footer xs-flex-end bgSnowWhite">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => {
							PatientDeliveryDialogStore.close()
							this.form.reset()
						}}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>
				</DialogActions>
				<XsConfirmationDialog
					name="xsDeletePatientDelivery"
					text={<FormattedMessage id="Common.label.deleteDeliveryTitle" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.deleteDeliveryBtn"
					}}
					onConfirmation={() =>
						PatientDeliveryDialogStore.removeDeliveryItem(GlobalStore.confirmationDialogParams.deliveryId)
					}
				/>
			</Dialog>
		)
	}
}
