// import api from "../actions/api"
// import moment from "moment"
import {observable, action} from "mobx"
import moment from "moment"

// import settings from "../../../global/config/settings"

class VaccinationListStore {
	@observable insurersData = []
	@observable filters = []

	formRef = undefined

	vaccinationReportListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		logger("formValues", formValues)
		logger("currFilters", this.currFilters)
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.sub_type != formValues.sub_type ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.org_unit != formValues.org_unit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(form) {
		this.currFilters = form.values()

		let filters = []

		if (isNotEmpty(form.$("org_unit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("org_unit").value}]})
		}

		if (isNotEmpty(form.$("identifier").value)) {
			filters.push({associated_column: "identifier", values: [{id_value: form.$("identifier").value}]})
		}

		if (isNotEmpty(form.$("date_from").value)) {
			filters.push({
				associated_column: "date_from",
				values: [{id_value: moment(form.$("date_from").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(form.$("date_to").value)) {
			filters.push({
				associated_column: "date_to",
				values: [{id_value: moment(form.$("date_to").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(form.$("sub_type").value)) {
			filters.push({associated_column: "sub_type_id", values: [{id_value: form.$("sub_type").value}]})
		}

		this.filters = filters

		this.isChangedFilter(form.values())
	}
}
var singleton = new VaccinationListStore()
export default singleton
