"use strict"

import api from "../../../modules/ambulance/actions/api"
import {action} from "mobx"
import moment from "moment"
import config from "../../../global/config/settings"
import actionCard from "../actions/patientCard"
import DataStore from "../stores/DataStore"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"

class PatientAnamnesisStore {
	anamnesisForm_textAreaCursors = {}
	operationForm_textAreaCursors = {}

	textAreaAnamnesisRef = undefined
	saveAndCloseBtnRef = null

	// formdata = {}

	@action saveForm(data, patientID, providerID, personnelID, type, summaryType, onSuccess, draftOnly=false) {
		UIStore.isFormSaving = true
		let items = data.values()

		let customRecordTime = undefined
		if (type == "EHR.Data.OperatingProtocol" && isSafe(items.examdate)) {
			customRecordTime = items.examdate
		}

		let anamnesisRequest = {
			_ref: false,
			_type: type,
			confidentiality_level: "STANDARD",
			version: {
				_type: "EHR.CL.Record.Version",
				_id: draftOnly ? "draft" : "final"
			},
			specific_rels: [
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: patientID,
						_type: "Entity.Data.Client"
					},
					type: "pat"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: personnelID,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "phy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: providerID,
						_type: "Entity.Data.OrgUnit"
					},
					type: "ou"
				}
			],
			summaries: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.anamnesis,
					type: summaryType
				}
			],
			recorded_events: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Event",
					active: true,
					recorded_at: moment(customRecordTime).format(config.DB_DATETIME_FORMAT),
					type: {
						_id: "WRT",
						_type: "EHR.CL.Record.EventType"
					}
				}
			]
		}

		if (isNotEmpty(items.socialAnamnesis)) {
			anamnesisRequest.summaries.push({
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: items.socialAnamnesis,
				type: "ANASOCIAL"
			})
		}

		if (isNotEmpty(items.deliveryAnamnesis)) {
			anamnesisRequest.summaries.push({
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: items.deliveryAnamnesis,
				type: "ANAOBSTETRIC"
			})
		}

		if (isNotEmpty(items.deliveryDate)) {
			anamnesisRequest.supposed_delivery_date = moment(items.deliveryDate).format(config.DB_DATE_FORMAT)
		} else {
			anamnesisRequest.supposed_delivery_date = ""
		}

		if (isNotEmpty(items.id)) {
			anamnesisRequest["_id"] = items.id
		}

		if (isSafe(items.wrt_recorded_event)) {
			const wrt = anamnesisRequest.recorded_events[0]
			wrt["_id"] = items.wrt_recorded_event
		}

		api
			.saveAnamnesis(anamnesisRequest, patientID)
			.call()
			.then((response) => {
				data.$("id").value = response._id
				// if (items.closeForm) {
				// 	data.reset()
				// }
				if (summaryType == "ANA") GlobalStore.setNotificationMessage("Patient.form.patientanamnesis.savesuccess")
				if (summaryType == "OP") GlobalStore.setNotificationMessage("Patient.form.patientOP.savesuccess")
				// if (items.closeForm) {
				// 	UIStore.patientCardTab = ""
				// }
				actionCard
					.loadPatientOverview(providerID, patientID, null, null, true)
					.then((overview) => {
						DataStore.setPatientEHR(overview)
						if (response._id && onSuccess) {
							onSuccess()
						}
						UIStore.isFormSaving = false
					})
					.catch(() => {
						UIStore.isFormSaving = false
					})
				window.scrollTo(0, 0)
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	// @action setAnamnesisData() {
	// 	// Predplníme anamnézu len z aktuálne zvoleného oddelenia
	// 	const providerID = getSelectedOrgUnitID()
	// 	if (isSafe(DataStore.patientDTO) && isSafe(DataStore.patientDTO.get("patientEHR"))) {
	// 		let anamData = DataStore.patientDTO
	// 			.get("patientEHR")
	// 			.rows.find(
	// 				(record) =>
	// 					record.type_code.toUpperCase() === "ANA" &&
	// 					record.provider_card.org_unit_id &&
	// 					record.provider_card.org_unit_id === providerID
	// 			)
	// 		if (isSafe(anamData)) {
	// 			this.loadData(anamData)
	// 		}
	// 	}
	// }

	@action async loadAnamData(form) {
		const anamnesis = await api.loadAnnamnesis(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID")).call()
		// Ak neexistuje ziadna anamneza pre pacienta BE vrati prazdny response takze awiat tam vlozi header typu Response a vtedy sa nic nema loadovat
		if (!(anamnesis instanceof Response)) {
			this.insertFormData(form, anamnesis)
		}
	}

	@action insertFormData(form, responseData) {
		form
			.$("anamnesis")
			.set(
				"value",
				isSafe(responseData) && isSafe(responseData.data.find((i) => i.type._id == "ANA"))
					? responseData.data.find((i) => i.type._id == "ANA").content
					: ""
			)
		form
			.$("socialAnamnesis")
			.set(
				"value",
				isSafe(responseData) && isSafe(responseData.data.find((i) => i.type._id == "ANASOCIAL"))
					? responseData.data.find((i) => i.type._id == "ANASOCIAL").content
					: ""
			)
		form
			.$("deliveryAnamnesis")
			.set(
				"value",
				isSafe(responseData) && isSafe(responseData.data.find((i) => i.type._id == "ANAOBSTETRIC"))
					? responseData.data.find((i) => i.type._id == "ANAOBSTETRIC").content
					: ""
			)
		form.$("id").set("value", responseData.record_id)
		form.$("deliveryDate").set("value", responseData.supposed_delivery_date)
		form.$("wrt_recorded_event").set("value", responseData.wrt_recorded_event._id)
	}
}

var singleton = new PatientAnamnesisStore()
export default singleton
