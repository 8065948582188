export default {
	load: {
		storeKey: "PatientMedication",
		params: ["prescriptionId"],
		type: "EHR.API.JSON.Proxy.Medication",
		flags: "CD"
	},
	scheme: {
		batch_number: {
			field: "batchNumber"
		},
		expiration_date: {
			field: "expiration"
		},
		barcode: {
			field: "barcode"
		},
		type_code: {
			field: "type_code"
		},
		medication_type: {
			// medication_type  recipe_type
			field: "medicationType"
		},
		vaccination_type: {
			field: "vaccinationType"
		},
		location: {
			field: "medicationLocation"
		},
		// repeats_type: {
		// 	//field: "repeatType"
		// },
		product_id: {
			field: "productCode"
			//IPLdescription
			//drugProductCode
		},
		// instructions_for_preparation: {
		// 	//field: "production"
		// },
		// instructions_for_use: {
		// 	//field: "useGuide"
		// },
		// supplement: {
		// 	//field: "supplement"
		// 	//IPLdescription
		// 	//drugSupplement
		// },
		// dose_every: {
		//   field: "everyHour"
		// },
		// dosage_type: {
		//   field: "dosage_type"
		// },
		amount: {
			field: "packs"
		},
		// dosage: {
		// 	//field: "dosage"
		// },
		dose_unit: {
			field: "unit"
		},
		route: {
			field: "medicationRoute"
		},
		// dose_periodicity: {
		// 	//field: "repeatingMoment"
		// },
		// duration_mins: {
		// 	//relation: "@days"
		// 	//prescription.duration_mins ? (Number(prescription.duration_mins) / (24 * 60)) : "",
		// },
		// note: {
		// 	//field: "note"
		// },
		// transcription: {
		// 	//field: "ds"
		// },
		// flags: {
		// 	//field: "flags"
		// },
		patient_id: {
			relation: {
				_type: "EHR.Data.Entity.Patient",
				_id: "#patientId"
			}
		},
		// doctor_id: {
		// 	//field: "doctor_id"
		// },
		// // personnel_id: {
		// //   field: "personnel_id"
		// // },
		// provider_id: {
		// 	//field: "provider_id"
		// },
		// components: {
		//   relation: "@components"
		// },
		// dose_every_unit: {
		//   relation: "@doseEveryUnit"
		// },
		record_diagnoses: {
			array: {
				load: (item) => item.active,
				descriptor: {
					_ref: false,
					_type: "EHR.Data.Record.Diagnosis",
					active: true,
					item_order: 1,
					life_threatening: false,
					type: "dgn"
				},
				scheme: {
					diagnosis_type: {
						object: {
							descriptor: {
								_id: "PRIMARY",
								_type: "EHR.CL.Record.DiagnosisType"
							}
						}
					},
					item: {
						object: {
							descriptor: {
								_type: "EHR.CL.Record.Diagnosis"
							},
							scheme: {
								_id: {
									field: "diagnosis"
								}
							}
						}
					}
				}
			}
		}
		// repeats_number: {
		// 	//field: "maxRepeats"
		// },
		// sending_doctor_id: {
		// 	//field: "sendingDoctor"
		// },
		// // sending_doctor_expertise_id: {
		// //   field: "sendingDoctorExpertiseId"
		// // },
		// // sending_personnel_expertise_id: {
		// //   field: "sendingDoctorPersonnelExpertiseId"
		// // },
		// // substituting_personnel_expertise_id: {
		// //   field: "substitutingPersonnelExpertiseId"
		// // },
		// sent_at: {
		// 	//field: "doctorDate"
		// },
		// sending_provider_id: {
		// 	//field: "doctorOUPZS"
		// },
		// substituting_doctor_id: {
		// 	//field: "substitutingDoctor"
		// },
		// substituting_doctor_expertise_id: {
		//   field: "substitutingExpertiseId"
		// },
		// substituting_provider_id: {
		//   field: "substitutingDoctorOUPZS"
		// },
		// forced_save: {
		//   field: "forcedSave"
		// },
		// forced_save_reason: {
		//   field: "forcedSaveReason"
		// },
		// prohibited_drugs: {
		//   relation: "@prohibitedDrugs"
		// },
		// replacement_prohibited: {
		//   field: "replacementProhibited"
		// },
		// record_id: {
		//   relation: "@receptId"
		// },
		// on_exclusion: {
		//   field: "exeptionCheck"
		// },
		// patient_payment_reason: {
		//   field: "pacientPaysReason"
		// }

		// product_code: {
		// 	//
		// },

		// product_barcode: {}
	}
}
