import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {insertFormDataByScheme} from "../../../../global/helpers/bindings"
import {getUserCompanyInfo} from "../../../../global/helpers/actions"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import fields from "./addOrgunitFields"
import {defaultSaveBindings} from "./addOrgunitBinding"

import OrgunitDetailStore from "../../stores/OrgunitDetailStore"
import RouterStore from "../../../../global/store/RouterStore"
import XsAutocomplete from "../../../../global/ui/xsInput/xsAutocomplete"
import ambulanceApi from "../../../ambulance/actions/api"
import AddOrgunitDialogStore from "../../stores/AddOrgunitDialogStore"
import GlobalStore from "../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class AddOrgunitDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {},
			onSuccess(form) {
				let company = getUserCompanyInfo()

				form.$("superiorOJ").value = isNotEmpty(form.$("superiorOJ").value) ? form.$("superiorOJ").value : null
				let idsObj = []
				if (isNotEmpty(form.$("OUPZScode").value)) {
					idsObj.push({
						_ref: false,
						_type: "IXS.Data.Object.Identifier",
						value: form.$("OUPZScode").value,
						type: "PROVIDERCODE"
					})
				}
				if (isNotEmpty(form.$("JRUZid").value)) {
					idsObj.push({
						_ref: false,
						_type: "IXS.Data.Object.Identifier",
						value: form.$("JRUZid").value,
						type: "JRUZID"
					})
				}

				insertFormDataByScheme(
					form,
					defaultSaveBindings,
					{
						idsObj: idsObj,
						orgunitId: "",
						companyId: isEmpty(form.$("company").value)
							? isNotEmpty(props.companyId)
								? props.companyId
								: company.id
							: form.$("company").value,
						owner: isNotEmpty(props.companyId) ? company.object_owner : ""
					},
					(res) => {
						AddOrgunitDialogStore.close()
						RouterStore.push(`/orgunit?id=${res._id}`)
					},
					false,
					"Common.notification.save"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
	}

	form

	render() {
		return (
			<Dialog
				id="xsAddOrgunitDialog"
				open={AddOrgunitDialogStore.isOpen}
				onClose={() => {
					this.form.reset()
					AddOrgunitDialogStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								this.form.reset()
								AddOrgunitDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-hospital fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.newOrgunit" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container spacing={8} className="pa-4">
						<Grid item xs={4}>
							<XsInput field={this.form.$("code")} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("name")} />
						</Grid>
						<Grid item xs={4}>
							<SearchSelect field={this.form.$("superiorOJ")} items={OrgunitDetailStore.companyOrgunits} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("OUPZScode")} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("JRUZid")} />
						</Grid>
						<Grid item xs={4}>
							<XsAutocomplete
								field={this.form.$("expertise")}
								minLengthForSearch="3"
								api={ambulanceApi.loadExpertise}
								apiCallType="Pr"
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj._id}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: (
													<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
												),
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px",
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												}
											},
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												dbName: "search_column",
												design: {
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => {
							this.form.reset()
							AddOrgunitDialogStore.close()
						}}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-hospital fa-lg" />}
						text={<FormattedMessage id="Common.label.addOrgunit" />}
						onClick={this.form.onSubmit}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
