"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import {getUserCompanyInfo, getSelectedOrgUnitID, getSelectedOrgUnitResourceID} from "../../../global/helpers/actions"
import {ixsIsEmpty} from "../../../global/helpers/functions"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "../stores/UIStore"
import moment from "moment"
import settings from "../../../global/config/settings"
import {getFilters} from "../../../global/helpers/api"

class CodeListStore {
	@observable clservices = []
	@observable providedServices = []
	@observable providedServicePrices = []
	@observable companyServices = []
	@observable deliveryChips = []
	@observable templateChips = []
	@observable clinicalValuesChips = []
	@observable showProvidedServicesForm = false
	@observable providedServiceLoaded = false
	@observable providedServiceLoading = false
	@observable companies = []
	@observable templates = null
	@observable companiesShowForm = false
	selectedProvidedServiceID = null
	selectedServiceID = null
	deliveryChipsForm = null
	templateChipsForm = null
	clinicalValuesChipsForm = null

	warningMessage = ""

	@action clearData(name) {
		this[name] = {}
	}

	@action resetProvidedServicesForm() {
		this.deliveryChips = []
		this.templateChips = []
		this.clinicalValuesChips = []
		this.providedServicePrices = []
		this.showProvidedServicesForm = false
		this.providedServiceLoaded = false
		this.providedServiceLoading = false
		this.selectedProvidedServiceID = null
		this.selectedServiceID = null
		this.deliveryChipsForm = null
		this.templateChipsForm = null
		this.clinicalValuesChipsForm = null
		this.templates = null
	}

	@action set(name, value) {
		this[name] = value
	}

	@action loadTemplates(type = null) {
		let searchRequest = {
			type_id: type,
			org_unit_id: getSelectedOrgUnitID(),
			scope: "Pu, Pr"
		}

		api
			.loadTemplates(searchRequest)
			.call()
			.then((resp) => {
				if (resp && resp.records) {
					const loadedTemplates = resp.records.map((r) => {
						if (r.active) {
							return {name_ext: r.name, _id: r._id, type: r.type_id}
						}
					})
					this.templates = loadedTemplates
				}
			})
	}

	getFilteredTemplates(type = null) {
		if (this.templates && type) {
			return this.templates.filter((t) => t.type === type)
		}
		return null
	}

	@action loadCLServices() {
		const isNew = ixsIsEmpty(this.selectedProvidedServiceID)
		this.loadCompanyServices().then(() => {
			api
				.loadCLServices()
				.call()
				.then((data) => {
					if (data.length > 0) {
						const companyServicesIds = this.providedServices.map((cs) => cs.provided_service.service._id)
						this.clservices = isNew
							? data.filter((d) => !companyServicesIds.includes(d._id))
							: data.filter((d) => !companyServicesIds.includes(d._id) || d._id === this.selectedServiceID)
					}
				})
		})
	}

	@action loadProvidedServicePriceList(providedServiceID = null) {
		const psID = providedServiceID === null ? this.selectedProvidedServiceID : providedServiceID
		const request = getFilters([`provider=${getSelectedOrgUnitResourceID()}`, `provided_service=${psID}`])

		api
			.loadPriceList(request)
			.call()
			.then((data) => {
				if (data && data.rows) {
					this.providedServicePrices = data.rows
				}
			})
	}

	@action pushCLSerivce(newCL) {
		this.clservices.push(newCL)
	}

	@action loadCompanyServices() {
		return api
			.loadCompanyServices(getUserCompanyInfo().id)
			.call()
			.then((data) => {
				const selectedOrgUnit = getSelectedOrgUnitID()
				if (isSafe(data) && isSafe(data.rows) && data.rows.length > 0) {
					this.providedServices = data.rows.filter((d) => d.orgunit_id === selectedOrgUnit)
					this.companyServices = data.rows.map((r) => r.provided_service.service)
				}
			})
	}

	@action deleteProvidedService(psID) {
		const updateRequest = {
			_id: psID,
			_type: "Service.Data.ProvidedService",
			_ref: false,
			active: false
		}
		api
			.updateProvidedService(updateRequest)
			.call()
			.then((response) => {
				if (response) {
					this.loadCompanyServices()
					this.resetProvidedServicesForm()
					GlobalStore.setNotificationMessage("Admin.codelist.providedservices.deleteSuccess")
				}
			})
	}

	@action saveProvidedServicesForm(data, onSuccess) {
		let items = data.values()
		const providerServiceId = items.id

		let saveRequest = {
			_ref: false,
			_type: "Service.Data.ProvidedService",
			active: true,
			default: items.default,
			item_order: items.item_order,
			visual: {
				back_color: items.back_color,
				fore_color: items.fore_color,
				_type: "IXS.Entity.Data.Visual"
			},
			age_from: items.age_from,
			age_to: items.age_to,
			valid_from: moment(items.valid_from)
				.startOf("day")
				.format(settings.DB_DATETIME_FORMAT),
			valid_to: items.valid_to
				? moment(items.valid_to)
						.endOf("day")
						.format(settings.DB_DATETIME_FORMAT)
				: null
		}

		//Robíme update
		if (providerServiceId) {
			saveRequest["_id"] = providerServiceId
		} else {
			saveRequest["provider"] = {
				active: true,
				class_name: "Entity.Data.OrgUnit",
				object_id: getSelectedOrgUnitID()
			}
		}

		//Trvanie služby
		if (items.duration) {
			saveRequest["duration"] = items.duration * 60
		}

		//Služba
		if (items.service) {
			saveRequest["service"] = {
				_type: "Service.Data.Service",
				_ref: false,
				_id: items.service
			}
		}

		const relateds = []

		//Pohlavie
		if (items.gender) {
			let genderObj = {
				_ref: false,
				_type: "Service.Data.ProvidedService.Related",
				active: true,
				rel_class_name: "Entity.CL.Gender",
				rel_object_id: items.gender,
				type_id: "Restriction"
			}
			if (providerServiceId) {
				genderObj["provided_service"] = providerServiceId
			}
			relateds.push(genderObj)
		}

		//Poisťovňa
		if (items.insurer) {
			let insurerObj = {
				_ref: false,
				_type: "Service.Data.ProvidedService.Related",
				active: true,
				rel_class_name: "EHR.Data.Entity.Insurer",
				rel_object_id: items.insurer,
				type_id: "Restriction"
			}
			if (providerServiceId) {
				insurerObj["provided_service"] = providerServiceId
			}
			relateds.push(insurerObj)
		}

		//Výkony
		if (this.deliveryChipsForm) {
			const delItems = this.deliveryChipsForm.getData()

			if (delItems) {
				let newDeliveries = []
				let newDiagnosis = []
				let newDeliveryItems = []

				Object.keys(delItems).forEach((key, idx) => {
					const dc = delItems[key]
					newDeliveries.push({
						item_order: idx,
						_ref: false,
						_type: "Service.Data.ProvidedService.Related",
						active: true,
						rel_class_name: "EHR.CL.Bill.Delivery",
						rel_object_id: dc.delivery,
						quantity: dc.deliveryQuantity,
						provided_service: providerServiceId,
						type_id: "General"
					})

					if (!isEmpty(dc.deliveryDiagnosis)) {
						newDiagnosis.push({
							active: true,
							item_order: idx,
							provided_service: providerServiceId,
							rel_class_name: "EHR.CL.Record.Diagnosis",
							rel_object_id: dc.deliveryDiagnosis,
							type_id: "General",
							_ref: false,
							_type: "Service.Data.ProvidedService.Related"
						})
					}

					if (!isEmpty(dc.deliveryItem)) {
						newDeliveryItems.push({
							active: true,
							item_order: idx,
							provided_service: providerServiceId,
							rel_class_name: "EHR.CL.Bill.DeliveryItem",
							rel_object_id: dc.deliveryItem,
							type_id: "General",
							_ref: false,
							_type: "Service.Data.ProvidedService.Related"
						})
					}
				})

				relateds.push(...newDeliveries.filter(Boolean))
				relateds.push(...newDiagnosis.filter(Boolean))
				relateds.push(...newDeliveryItems.filter(Boolean))
			}
		}

		//Pomôcky
		if (this.templateChipsForm) {
			const templateItems = this.templateChipsForm.getData()

			if (templateItems) {
				let newTemplateTypes = []
				Object.keys(templateItems).forEach((key, idx) => {
					const tc = templateItems[key]
					if (!isEmpty(tc.templateType)) {
						newTemplateTypes.push({
							active: true,
							item_order: idx,
							provided_service: providerServiceId,
							rel_class_name: "EHR.Data.Template.Summary",
							rel_object_id: tc.templateType,
							type_id: "General",
							_ref: false,
							_type: "Service.Data.ProvidedService.Related"
						})
					}
				})
				relateds.push(...newTemplateTypes.filter(Boolean))
			}
		}

		//Klinické hodnoty
		if (this.clinicalValuesChipsForm) {
			const cvItems = this.clinicalValuesChipsForm.getData()

			if (cvItems) {
				let newCV = []
				Object.keys(cvItems).forEach((key, idx) => {
					const cv = cvItems[key]
					if (!isEmpty(cv.cvMeasure)) {
						newCV.push({
							active: true,
							item_order: idx,
							quantity: 1,
							provided_service: providerServiceId,
							rel_class_name: "EHR.CL.Record.Measure",
							rel_object_id: cv.cvMeasure,
							_ref: false,
							_type: "Service.Data.ProvidedService.Related"
						})
					}
				})
				relateds.push(...newCV.filter(Boolean))
			}
		}

		saveRequest["relateds"] = relateds

		api
			.saveProvidedService(saveRequest)
			.call()
			.then((response) => {
				if (response._id && onSuccess) {
					onSuccess()
				}

				//Resetovanie
				UIStore.isFormSaving = false
				this.loadCompanyServices()
				this.resetProvidedServicesForm()
				GlobalStore.setNotificationMessage("Admin.codelist.providedservices.savesuccess")
			})
	}

	@action loadCompanies(seachString = "", searchICO = "") {
		if (isEmpty(seachString) && isEmpty(searchICO)) {
			UIStore.isFormSaving = true
		}
		const filter = {
			row_offset: 1,
			row_count_show: 100,
			filters: [
				{
					associated_column: "search_column",
					values: [
						{
							id_value: seachString
						}
					]
				},
				{
					associated_column: "identifier",
					values: [
						{
							id_value: searchICO
						}
					]
				}
				// {
				// 	associated_column: "object_owner",
				// 	values: [
				// 		{
				// 			id_value: "IXS"
				// 		}
				// 	]
				// },
				// {
				// 	associated_column: "company_id",
				// 	values: [
				// 		{
				// 			id_value: getUserCompanyInfo().id
				// 		}
				// 	]
				// }
				// {
				// 	associated_column: "param:MOC",
				// 	values: [
				// 		{
				// 			id_value: "1"
				// 		}
				// 	]
				// }
			]
		}
		api
			.loadCLCompanies(filter)
			.call()
			.then((response) => {
				this.companies = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action loadSpecificCompany(id, form) {
		api
			.loadEntityInfo(id, "L1")
			.call()
			.then((response) => {
				form.$("companyId").value = response._id
				form.$("ico").value = response.identifier
				form.$("name").value = response.name
				form.$("short_name").value = response.short_name
				form.$("DIC").value = response.tax_identification_number
				form.$("ICDPH").value = response.vat_identification_number
				form.$("description").value = response.description
			})
	}

	@action saveCompany(form) {
		let company = getUserCompanyInfo()
		let request = {
			active: true,
			identifier: form.$("ico").value,
			name: form.$("name").value,
			short_name: form.$("short_name").value,
			tax_identification_number: form.$("DIC").value,
			vat_identification_number: form.$("ICDPH").value,
			description: form.$("description").value,
			_ref: false,
			_type: "Entity.Data.Company"
		}

		if (isNotEmpty(form.$("companyId").value)) {
			request._id = form.$("companyId").value
		}

		if (isNotEmpty(form.$("code").value)) {
			let orgUnit = {
				code: form.$("code").value,
				code_ext: form.$("code").value,
				name: form.$("org_name").value,
				name_ext: form.$("org_name").value,
				object_owner: company.object_owner,
				_type: "Entity.Data.OrgUnit",
				identifiers: []
			}

			if (isNotEmpty(form.$("OUPZScode").value)) {
				orgUnit.identifiers.push({
					_ref: false,
					_type: "IXS.Data.Object.Identifier",
					value: form.$("OUPZScode").value,
					type: "PROVIDERCODE"
				})
			}

			if (isNotEmpty(form.$("JRUZid").value)) {
				orgUnit.identifiers.push({
					_ref: false,
					_type: "IXS.Data.Object.Identifier",
					value: form.$("JRUZid").value,
					type: "JRUZID"
				})
			}

			if (isNotEmpty(form.$("expertise").value)) {
				orgUnit.expertises = [
					{
						_type: "EHR.Data.Entity.ProviderExpertise",
						_ref: false,
						expertise: {_id: form.$("expertise").value}
					}
				]
			}

			request.specific_rels = [orgUnit]
		}

		api
			.updateEntityInfo(request)
			.call()
			.then((response) => {
				this.loadSpecificCompany(response._id, form)
				this.loadCompanies()
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}
}
var singleton = new CodeListStore()
export default singleton
