import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				price: {
					label: <FormattedMessage id="Delivery.List.Price" />,
					rules: "required",
					type: "string"
				},
				valid_from: {
					label: <FormattedMessage id="Common.label.validFrom" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				valid_to: {
					label: <FormattedMessage id="Common.label.validTo" />,
					type: "date"
				},
				currency_unit: {
					label: <FormattedMessage id="Invoice.form.currency" />,
					rules: "required",
					value: "EUR",
					type: "string"
				}
			}
		}
	}
}
