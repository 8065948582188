"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import moment from "moment"
import {getUserCompanyInfo} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "../stores/UIStore"

class ExportInvoicesDialogStore {
	@observable isOpen = false
	@observable invoicesCount = 0
	@observable invoices = []

	@observable password = ""
	@observable inputs = {}
	dateFrom = moment()
		.startOf("month")
		.format("YYYY-MM-DD")
	@observable warningMessage = ""

	@action open() {
		this.isOpen = true

		this.getInvoicesCount()

		this.getDrafts()
	}

	@action getDrafts() {
		let request = {
			filters: [
				{
					associated_column: "company_id",
					values: [{id_value: getUserCompanyInfo()._id}]
				}
			],
			time_from: moment(this.dateFrom).startOf("month"),
			time_to: moment(this.dateFrom).endOf("month")
		}

		api
			.loadDraftReport(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					this.warningMessage = "Common.label.showItemizedRecord"
				} else {
					this.warningMessage = ""
				}
			})
	}

	@action getInvoicesCount() {
		api
			.getInvoiceConfig(getUserCompanyInfo().id)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.insurers) && res.insurers.length > 0) {
					let sortedInsurers = res.insurers

					sortedInsurers.sort(function(a, b) {
						if (a.item_order < b.item_order) {
							return -1
						}
						if (a.item_order > b.item_order) {
							return 1
						}
						return 0
					})

					this.invoicesCount = sortedInsurers.length
					this.invoices = sortedInsurers
				}
			})
	}

	@action exportInvoices() {
		UIStore.isFormSaving = true
		if (Object.keys(this.inputs).length > 0 && Object.keys(this.inputs).length == this.invoicesCount) {
			let isNotValid = Object.values(this.inputs).some((invoice) => isEmpty(invoice))

			if (isNotValid) {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
			} else {
				let request = {
					company_id: getUserCompanyInfo().id,
					date: this.dateFrom,
					password: this.password,
					// invoice_numbers: Object.values(this.inputs)
					// 	.map((x) => x)
					// 	.join(",")
					invoice_numbers: this.invoices.map((x) => `${x.item_order}^${this.inputs[x.item_order].trim()}`).join(",")
				}

				api
					.generalAll(request)
					.call(null, null, null, 600000)
					.then((response) => {
						if (response.ok == true) {
							// logger("response.headers", response.headers)
							// logger("content-dispositon", response.headers.get("Content-Disposition"))
							// logger("filename", response.headers.values())
							// logger("filename", response.headers.keys())
							let fileName = isSafe(response.headers.get("content-disposition"))
								? response.headers.get("content-disposition").split("filename=")[1]
								: "davky"
							response.blob().then((blob) => {
								let FileSaver = require("file-saver") // eslint-disable-line no-undef
								FileSaver.saveAs(blob, fileName)
							})
							UIStore.isFormSaving = false
							this.close()
						}
					})
			}
		} else {
			UIStore.isFormSaving = false
			GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
		}
	}

	@action close() {
		this.isOpen = false

		this.password = ""
		this.inputs = {}
		this.dateFrom = moment()
			.startOf("month")
			.format("YYYY-MM-DD")
		this.warningMessage = ""
	}
}

var singleton = new ExportInvoicesDialogStore()
export default singleton
