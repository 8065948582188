"use strict"

import moment from "moment"
import api from "../actions/api"
import config from "../../../../app/global/config/settings"
import {getFilters} from "../../../../app/global/helpers/api"
import {
	getSelectedOrgUnitID,
	getSelectedOrgUnitResourceID,
	getUserPersonnelID,
	getUserCompanyInfo,
	getSelectedOrgUnit
} from "../../../../app/global/helpers/actions"
import {observable, action} from "mobx"
import UIStore from "./UIStore"
// import GlobalStore from "../../../global/store/GlobalStore"
import CalendarStore from "./CalendarStore"
// import AddEventDialogStore from "./AddEventDialogStore"
// import EditEventDialogStore from "./EditEventDialogStore"
import ExportToXlsDialogStore from "./ExportToXlsDialogStore"
import {formDataStateCL} from "../../../global/config/codeLists"

class DataStore {
	//Šablóny pre formuláre
	@observable templateIsOpen = false
	@observable templateIsMounted = false
	templateSpecialType = null
	@observable templateAnchorEl = null
	@observable templates = []
	@observable templateData = null
	@observable templateDataKeyVal = null
	@observable templateDataLoaded = false
	@observable templateSourceText = ""
	@observable templatesAll = {}
	templateSourceRange = null
	translateNonInsurer = ""
	deletingTemplate = false
	lastVisitedPatientID = null

	@observable nonPayer = ""
	contactUnknow = ""
	contactUnknowText = ""
	addressUnknow = ""
	addressDesc = ""
	// @observable isCapitation = false

	nonInsurerTxt = ""
	addNowTxt = ""
	patientIsNotEUInsuranceTxt = ""
	fixNowTxt = ""
	patientIsEUInsuranceTxt = ""
	patientIsSKInsurer = ""

	//Form Data States
	formDataState = {
		PatientRecordForm: formDataStateCL.EMPTY,
		PatientPrescriptionForm: formDataStateCL.EMPTY,
		PatientRequestForm: formDataStateCL.EMPTY,
		PatientMedicationForm: formDataStateCL.EMPTY,
		PatientOperationForm: formDataStateCL.EMPTY,
		PatientSicknessAbsForm: formDataStateCL.EMPTY,
		PatientAnamnesisForm: formDataStateCL.EMPTY
	}

	// recordDataState = formDataStateCL.EMPTY
	// prescriptionState = formDataStateCL.EMPTY
	requestState = formDataStateCL.EMPTY
	medicationState = formDataStateCL.EMPTY

	multiFormsFromStorage = {}

	//Všetko okolo pacienta - nezabudnite pridať!
	@observable patientDTO = observable.map({
		patient: null,
		patientID: null,
		patientEHR: null,
		clientId: null,
		capitationsDTO: null,
		capitations: null,
		contacts: null,
		currContact: null,
		contracts: null,
		addresses: null,
		currAddress: null,
		insurances: null,
		insurer: null,
		insurerId: null,
		insType: null,
		insurerType: null,
		insurerCodeExt: null,
		insurerShortName: null,
		consents: null,
		contactpersons: null,
		alertsChips: null,
		tagsChips: null,
		alergiesChips: null,
		implants: null,
		isNonPayer: false,
		isCapitation: false,
		isEUInsured: false,
		nextVisit: null,
		nextEventId: null,
		nextEventName: null,
		lastVisit: null,
		lastPreventiveExamination: null,
		lastRecord: null,
		diagnosis: null,
		bloodType: null,
		savedForms: []
	})

	//Formuláre Alerty, Alergie a Tagy
	patientAlertsDTOChipForm
	patientAllergiesDTOChipForm
	patientTagDTOChipForm
	alertsChipsForm
	alergiesChipsForm
	tagsChipsForm

	@observable filterATCValue = null

	//Zoznam pacientov
	patientsNotFilterData = []
	@observable orderedPatientsData = {}
	@observable orderedPatientsDataArr = []
	@observable patientsData = new Array()
	@observable patientsTagsData = new Array()
	// @observable patientsDiagnoses = new Array()
	patientsFilteredDiagnoses = null
	patientListTableRef = null
	// sendingPatientDiagnosesToAPI = false

	@observable resourcesData = new Array()
	@observable checkedEHR = new Array()
	@observable savedFormObject = null

	calendarFrom = undefined
	calendarTo = undefined
	patientIdReloaded = undefined
	providedServices = {}
	providedServicesNames = {}

	// @observable patientDraftIds = []

	@observable nameNewPatient = undefined
	@observable isCreatedFromEvent = false

	//Metóda pre overenie totožnosti pacienta pri nastavovaní jeho EHR do Overview
	@action setPatientEHR(ehrResult) {
		/*if (ehrResult && ehrResult.request && ehrResult.request.filters) {
			const patientFilter = ehrResult.request.filters.find((f) => f.associated_column === "patient_id")
			if (patientFilter) {
				if (
					patientFilter.values &&
					patientFilter.values.length > 0 &&
					this.patientDTO.get("patientID") === patientFilter.values[0].id_value
				) {
					this.patientDTO.set("patientEHR", ehrResult)
				}
			}
    }*/
		if (ehrResult && ehrResult.ixs_request_id === this.patientDTO.get("patientID")) {
			this.patientDTO.set("patientEHR", ehrResult)
		}
	}

	@action async loadTemplates(type = "AMB") {
		let ProviderID = getSelectedOrgUnitID()

		let searchRequest = {
			type_id: type,
			org_unit_id: ProviderID,
			scope: "Pu, Pr",
			with_streams: true
		}

		let response = await api.loadTemplates(searchRequest).call()
		this.templates = response
	}

	@action async loadTemplatesSync(type = "AMB", name) {
		let ProviderID = getSelectedOrgUnitID()

		let searchRequest = {
			type_id: type,
			org_unit_id: ProviderID,
			scope: "Pu, Pr",
			with_streams: true
		}

		let response = await api.loadTemplates(searchRequest).call()
		this.templatesAll[isNotEmpty(name) ? name : type] = response
	}

	@action openTemplateForm(specialType = null) {
		this.templateSpecialType = specialType
		this.templateIsOpen = true
	}

	@action closeTemplateForm() {
		this.templateSpecialType = null
		this.templateIsOpen = false
		this.templateDataKeyVal = null
	}

	@action editTemplate(target, record, specialType = null, multiKeyVal = null) {
		this.templateAnchorEl = target
		this.templateData = record
		this.templateDataKeyVal = multiKeyVal
		this.openTemplateForm(specialType)
	}

	@action insertDataToTemplateForm(form) {
		form.$("name").value = this.templateData.name
		form.$("scope").value = this.templateData.scope === "Pr" ? true : false
		if (this.templateData.type_id == "RINDIVID") {
			form.$("templateStream").value = htmlToPlain(this.templateData.template_stream)
		} else {
			form.$("templateStream").value = this.templateData.template_stream.replace(/\\\"/g, '"') // eslint-disable-line
		}
		this.templateDataLoaded = true
	}

	// searchPatientRequest = {
	//   filters: [],
	//   // row_count_show: 10,
	//   // row_offset: 1
	// }

	@observable search_column_patientListFilter = undefined
	@observable diagnoses_patientListFilter = undefined
	@observable tags_patientListFilter = undefined
	@observable all_patientListFilter = undefined
	@observable orgUnit_patientListFilter = true
	@observable insurer_patientListFilter = undefined

	// @action setPatientsData(filterValues, filterType) {

	//   this.sendingPatientDiagnosesToAPI = filterType === "diagnoses" && isSafe(filterValues) && filterValues.length > 0

	//   api.loadCompanyPatients(getUserCompanyInfo().id, this.searchPatientRequest).call().then(response => {
	//     isSafe(response.tags) ? this.patientsTagsData = response.tags : this.patientsTagsData = []

	//     let filterDiag = response.filters.filter(x => x.associated_column === "diagnoses")
	//     if (!this.sendingPatientDiagnosesToAPI && isSafe(filterDiag) && isSafe(filterDiag[0])) {
	//       this.patientsDiagnoses = filterDiag[0].values
	//       this.sendingPatientDiagnosesToAPI = false
	//     }
	//   })
	// }

	@action clearCheckedEHR() {
		this.checkedEHR = []
	}

	//Spustí sa pri zmene oddelenia cez dropdown v xsHeader
	@action onSelectedOrgUnitChange() {
		this.setServices()
		CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
		// AdminStore.loadOpeningHours();
		// CodeListStore.loadCLServices();
	}

	@action addToCheckedEHR(selected) {
		if (!this.isCheckedEHR(selected)) {
			this.checkedEHR.push(selected)
		}
	}

	@action removeFromCheckedEHR(selected) {
		if (this.isCheckedEHR(selected)) {
			const index = this.checkedEHR.map((a) => a.record_id === selected.record_id).indexOf(true)
			this.checkedEHR.splice(index, 1)
		}
	}

	@action isCheckedEHR(selected) {
		if (selected) {
			return Boolean(this.checkedEHR.find((ehr) => ehr.record_id === selected.record_id))
		}
	}

	@action filterPatients(filter) {
		if (filter === "all") {
			this.patientsData = this.patientsNotFilterData
		} else if (typeof filter === "boolean") {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.is_vip == filter)
		} else if (typeof filter === "number") {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.insurer_id == filter)
		} else {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.gender_id == filter)
		}
	}

	@action loadEntity(patientId, onSuccess) {
		let ixsRequestPatientId = null
		api
			.loadEntityInfo(patientId)
			.call(null, patientId, (id) => (ixsRequestPatientId = id))
			.then((info) => {
				//Len ak je ID pacienta zhodné s ID requestom
				if (patientId === ixsRequestPatientId) {
					//Vytiahneme si z info primárnu adresu, poisťovňu a iné. uložíme do validInfo
					const clientInfo = info.specific_rels.find((sr) => sr._type === "Entity.Data.Client")

					const addressInfo = clientInfo && clientInfo.addresses ? clientInfo.addresses.find((ad) => ad.primary) : null
					const contactInfo = clientInfo && clientInfo.contacts ? clientInfo.contacts.find((c) => c.primary) : null
					const insuranceInfo =
						clientInfo && clientInfo.insurances
							? clientInfo.insurances.find((ins) => ins.validity && ins.validity.now)
							: null

					const citizenshipInfo = info.citizenships.find((cs) => cs.active && cs.validity && cs.validity.now == true)

					info.validInfo = {
						client: clientInfo,
						address: addressInfo,
						contact: contactInfo,
						insurance: insuranceInfo,
						citizenship: citizenshipInfo
					}

					//Nastavujeme patientDTO do DataStore
					this.patientDTO.set("patient", info)
					this.patientDTO.set("patientID", clientInfo._id)

					//kontraola ci je pacient kapitovany, EU
					this.getPatientCapitations()

					if (isSafe(clientInfo)) {
						//nastavenie insurances
						if (isSafe(clientInfo.insurances)) {
							this.getInsurances(clientInfo.insurances)
						}

						//nastavenie kontaktov
						if (isSafe(clientInfo.contacts)) {
							this.getContacts(clientInfo.contacts)
						}

						//nastavenie adries
						if (isSafe(clientInfo.addresses)) {
							this.getAddresses(clientInfo.addresses)
						}
					}

					if (onSuccess) {
						onSuccess(info)
					}
				}
			})
	}

	@action setPatientInfo(data) {
		let patient = data.specific_rels.find((sr) => {
			return sr._type === "Entity.Data.Client"
		})
		if (isSafe(patient)) {
			//kontakty
			if (isSafe(patient.contacts)) {
				this.patientDTO.set("contacts", patient.contacts)
			}
			//adresy
			if (isSafe(patient.addresses)) {
				patient.addresses = patient.addresses.filter((x) => isSafe(x.type) && x.type._id === "CA")
				this.patientDTO.set("addresses", patient.addresses)
			}
			//insurance
			if (isSafe(patient.insurances)) {
				let actInsurer = patient.insurances.find((ins) => isSafe(ins.validity) && ins.validity.now)
				if (isSafe(actInsurer)) {
					if (isSafe(actInsurer.type) && actInsurer.type.code !== "GH") {
						this.patientDTO.set("insurerType", actInsurer.type.code_ext)
					} else {
						this.patientDTO.set("insurerType", "")
					}
					if (isSafe(actInsurer.type)) {
						this.patientDTO.set("insType", actInsurer.type.code_ext)
					}
					if (isSafe(actInsurer.insurer)) {
						this.patientDTO.set("insurerCodeExt", actInsurer.insurer.code_ext)
						this.patientDTO.set("insurer", actInsurer.insurer.code_ext + " - " + actInsurer.insurer.name_ext)
						if (actInsurer.type._id == "EU") {
							this.patientDTO.set("isEUInsured", true)
						} else {
							this.patientDTO.set("isEUInsured", false)
						}
					} else {
						this.patientDTO.set("insurer", this.translateNonInsurer)
					}
				} else {
					this.patientDTO.set("insurerType", "")
					this.patientDTO.set("insType", "")
					this.patientDTO.set("insurer", this.translateNonInsurer)
				}
				this.patientDTO.set("insurances", patient.insurances)
			}
		}
	}

	@action getPatientContacts(patientId) {
		api
			.loadEntityInfo(patientId)
			.call()
			.then((info) => {
				const patient = info.specific_rels.find((sr) => sr._type === "Entity.Data.Client")

				if (isSafe(patient) && isSafe(patient.contacts)) {
					this.getContacts(patient.contacts)

					//Update valid contact info (current contact)
					const contactInfo = patient.contacts.find((c) => c.primary)
					let pacInfo = this.patientDTO.get("patient")
					if (pacInfo && pacInfo.validInfo) {
						pacInfo.validInfo.contact = contactInfo
						this.patientDTO.set("patient", pacInfo)
					}
				}
			})
	}

	@action getContacts(contacts) {
		this.patientDTO.set("contacts", contacts)

		if (contacts.length > 0) {
			let currContact = contacts.find((x) => x.primary)

			if (isNotSafe(currContact)) {
				currContact = contacts[0]
			}

			if (isNotEmpty(currContact.mobile) || isNotEmpty(currContact.phone) || isNotEmpty(currContact.email)) {
				let contact = isNotEmpty(currContact.mobile)
					? currContact.mobile
					: isNotEmpty(currContact.phone)
					? currContact.phone
					: currContact.email
				this.patientDTO.set("currContact", {contact: contact, contact_type: currContact.contact_type.description})
			} else {
				this.patientDTO.set("currContact", {contact: this.contactUnknow, contact_type: this.contactUnknowText})
			}
		} else {
			this.patientDTO.set("currContact", {contact: this.contactUnknow, contact_type: this.contactUnknowText})
		}
	}

	@action getPatientAddresses(patientId) {
		api
			.loadEntityInfo(patientId)
			.call()
			.then((info) => {
				const patient = info.specific_rels.find((sr) => sr._type === "Entity.Data.Client")
				if (isSafe(patient) && isSafe(patient.addresses)) {
					this.getAddresses(patient.addresses)

					//Update valid address info (current address)
					const addressInfo = patient.addresses.find((ad) => ad.primary)
					let pacInfo = this.patientDTO.get("patient")
					if (pacInfo && pacInfo.validInfo) {
						pacInfo.validInfo.address = addressInfo
						this.patientDTO.set("patient", pacInfo)
					}
				}
			})
	}

	@action getAddresses(addresses) {
		let addressesPA = addresses.filter((x) => isSafe(x.type) && x.type._id === "PA")
		let addressesCA = addresses.filter((x) => isSafe(x.type) && x.type._id === "CA")

		this.patientDTO.set("addresses", addressesCA)

		if (addressesPA.length > 0) {
			let currAddress = addressesPA[0]

			if (isEmpty(currAddress.full_address)) {
				this.patientDTO.set("currAddress", {address: this.addressUnknow, address_desc: this.addressDesc})
			} else {
				this.patientDTO.set("currAddress", {address: currAddress.full_address, address_desc: this.addressDesc})
			}
		} else {
			this.patientDTO.set("currAddress", {address: this.addressUnknow, address_desc: this.addressDesc})
		}
	}

	@action getEmployeeContracts(patientId) {
		const filters = getFilters([`person_id=${patientId}`])
		api
			.loadEmployeeContracts(filters)
			.call()
			.then((contracts) => {
				if (isSafe(contracts) && isSafe(contracts.rows)) {
					this.patientDTO.set("contracts", contracts.rows)
				}
			})
	}

	@action getPatientInsurances(patientId, patientInfoForm = null) {
		api
			.loadEntityInfo(patientId)
			.call()
			.then((info) => {
				const patient = info.specific_rels.find((sr) => {
					return sr._type === "Entity.Data.Client"
				})
				if (isSafe(patient) && isSafe(patient.insurances)) {
					this.getInsurances(patient.insurances, patientInfoForm)

					//Update valid insurance info (current insirance)
					const insuranceInfo = patient.insurances.find((ins) => ins.validity && ins.validity.now)
					let pacInfo = this.patientDTO.get("patient")
					if (pacInfo && pacInfo.validInfo) {
						pacInfo.validInfo.insurance = insuranceInfo
						this.patientDTO.set("patient", pacInfo)
					}
				}
			})
	}

	@action getInsurances(insurances, patientInfoForm = null) {
		let actInsurer = insurances.find((ins) => isSafe(ins.validity) && ins.validity.now)

		if (isSafe(actInsurer)) {
			if (isSafe(actInsurer.type) && actInsurer.type.code !== "GH") {
				this.patientDTO.set("insurerType", actInsurer.type.code_ext)
			} else {
				this.patientDTO.set("insurerType", "")
			}

			if (isSafe(actInsurer.type)) {
				this.patientDTO.set("insType", actInsurer.type.code_ext)
			}

			if (isSafe(actInsurer.insurer)) {
				this.patientDTO.set("insurerCodeExt", actInsurer.insurer.code_ext)
				if (isNotEmpty(patientInfoForm)) {
					patientInfoForm.$("insurer").value = actInsurer.insurer._id
				}
				this.patientDTO.set("insurer", actInsurer.insurer.code_ext + " - " + actInsurer.insurer.name_ext)
				this.patientDTO.set("insurerId", actInsurer.insurer._id)
				if (actInsurer.type._id == "EU") {
					this.patientDTO.set("isEUInsured", true)
				} else {
					this.patientDTO.set("isEUInsured", false)
				}

				api
					.overpoistenca(this.patientDTO.get("patientID"), getSelectedOrgUnitID())
					.call()
					.then((response) => {
						if (isSafe(response)) {
							if (
								isSafe(response.poistny_vztah_poistenca) &&
								response.poistny_vztah_poistenca === "JePoistencom" &&
								this.patientDTO.get("insType") != "VZP"
							) {
								UIStore.insuranceMessage = {
									message: this.patientIsSKInsurer,
									subMessage: this.fixNowTxt
								}
							} else if (!this.patientDTO.get("isEUInsured") && response.poistny_vztah_poistenca === "JePoistencomEU") {
								UIStore.insuranceMessage = {
									message: this.patientIsEUInsuranceTxt,
									subMessage: this.fixNowTxt
								}
							} else if (
								isSafe(response.poistny_vztah_poistenca) &&
								response.poistny_vztah_poistenca === "NieJePoistencom"
							) {
								UIStore.insuranceMessage = {
									message: this.nonInsurerTxt,
									subMessage: this.addNowTxt
								}
							} else {
								UIStore.insuranceMessage = undefined
							}
						}
					})
			} else {
				this.patientDTO.set("insurer", this.translateNonInsurer)
				if (isNotEmpty(patientInfoForm)) {
					patientInfoForm.$("insurer").value = ""
				}
			}
		} else {
			this.patientDTO.set("insurerType", "")
			this.patientDTO.set("insType", "")
			this.patientDTO.set("insurer", this.translateNonInsurer)
			if (isNotEmpty(patientInfoForm)) {
				patientInfoForm.$("insurer").value = ""
			}
		}

		this.patientDTO.set("insurances", insurances)
	}

	@action getPatientCapitations() {
		const personnel_id = getUserPersonnelID()
		const capitationsFilter = getFilters(
			[`personnel_id=${personnel_id}`, `patient_id=${this.patientDTO.get("patientID")}`],
			{
				from: moment(1).format(config.DB_DATE_FORMAT),
				to: moment()
					.add(1, "years")
					.format(config.DB_DATE_FORMAT)
			}
		)

		api
			.loadCapitation(capitationsFilter)
			.call()
			.then((capitations) => {
				if (isSafe(capitations) && isSafe(capitations.rows)) {
					let hasCapitation = capitations.rows.find((cap) => isSafe(cap.interval) && cap.interval.now)
					if (isSafe(hasCapitation)) {
						this.patientDTO.set("isCapitation", true)
					} else {
						this.patientDTO.set("isCapitation", false)
					}
				}
				this.patientDTO.set("capitations", capitations.rows)
			})
	}

	@action getPatientConsents() {
		let consentsFilter = getFilters([`relship=${this.patientDTO.get("patientID")}`], {
			from: moment(1).format(config.DB_DATE_FORMAT),
			to: moment()
				.add(1, "years")
				.format(config.DB_DATE_FORMAT)
		})

		api
			.loadConsents(consentsFilter)
			.call()
			.then((consents) => {
				this.patientDTO.set("consents", consents.rows)
			})
	}

	@action getPatientContactPersons() {
		api
			.loadContactPersons(this.patientDTO.get("patientID"))
			.call()
			.then((cp) => {
				this.patientDTO.set("contactpersons", cp.filter((c) => c.rel_type._id.startsWith("PERS:")))
			})
	}

	@action setSavedFromObject(sf = null) {
		this.savedFormObject = sf
	}

	tempEventRequestId = 0
	acceptRequest = true

	@action getIXSRequestId(id) {
		this.acceptRequest = id == this.tempEventRequestId
	}

	// @action loadPatientsAgenda(/*from, to*/) {
	// 	const providerID = getSelectedOrgUnitID()

	// 	let from = moment(CalendarStore.selectedDay)
	// 		.startOf("day")
	// 		.utc()
	// 		.format()
	// 	let to = moment(CalendarStore.selectedDay)
	// 		.endOf("day")
	// 		.utc()
	// 		.format()

	// 	if (
	// 		UIStore.calendarTimePeriod == "otherDay" ||
	// 		UIStore.calendarTimePeriod == "today" ||
	// 		UIStore.calendarTimePeriod == "tomorrow"
	// 	) {
	// 		let request = {
	// 			filters: [
	// 				{
	// 					associated_column: "company_id",
	// 					values: [
	// 						{
	// 							id_value: getUserCompanyInfo().id
	// 						}
	// 					]
	// 				}
	// 			],
	// 			time_from: from,
	// 			time_to: to
	// 		}

	// 		api
	// 			.loadDraftReport(request)
	// 			.call()
	// 			.then((res) => {
	// 				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
	// 					this.patientDraftIds = res.rows.map((x) => x.patient_id)
	// 				} else {
	// 					this.patientDraftIds = []
	// 				}
	// 			})
	// 	}

	// 	if (UIStore.calendarTimePeriod === "month") {
	// 		from = moment(CalendarStore.selectedDay)
	// 			.startOf("week")
	// 			.utc()
	// 			.format()
	// 		to = moment(CalendarStore.selectedDay)
	// 			.add(1, "month")
	// 			.endOf("week")
	// 			.utc()
	// 			.format()
	// 	}

	// 	if (UIStore.calendarTimePeriod === "week") {
	// 		from = moment(CalendarStore.selectedDay)
	// 			.startOf("week")
	// 			.utc()
	// 			.format()
	// 		to = moment(CalendarStore.selectedDay)
	// 			.startOf("week")
	// 			.add(7, "day")
	// 			.utc()
	// 			.format()
	// 	}

	// 	const request = {
	// 		time_from: from,
	// 		time_to: to
	// 	}

	// 	this.tempEventRequestId = Math.floor(Math.random() * 1000)

	// 	api
	// 		.loadPatientsAgenda(providerID, request)
	// 		.call(null, this.tempEventRequestId, (id) => this.getIXSRequestId(id))
	// 		.then((events) => {
	// 			if (this.acceptRequest) {
	// 				let items = {}
	// 				const localEvents = events.map((event) => {
	// 					return {event: event, from: moment(event.interval.from)}
	// 				})
	// 				if (UIStore.calendarTimePeriod === "month") {
	// 					localEvents.forEach((localEvent) => {
	// 						const localMonth = localEvent.from.month()
	// 						const localDate = localEvent.from.date()

	// 						let month = items[localMonth] //items[+darr[1] - 1]
	// 						if (isSafe(month)) {
	// 							let day = month[localDate] //month[+darr[2]]
	// 							if (isSafe(day)) {
	// 								day.push(localEvent.event)
	// 							} else {
	// 								items[localMonth][localDate] = [localEvent.event]
	// 							}
	// 						} else {
	// 							items[localMonth] = {}
	// 							items[localMonth][localDate] = [localEvent.event]
	// 						}
	// 					})

	// 					this.countEvents = localEvents.length

	// 					// let fromIsSame =
	// 					// 	moment(from).isSame(this.calendarFrom, "day") &&
	// 					// 	moment(from).isSame(this.calendarFrom, "month") &&
	// 					// 	moment(from).isSame(this.calendarFrom, "year")

	// 					// let toIsSame =
	// 					// 	moment(to).isSame(this.calendarTo, "day") &&
	// 					// 	moment(to).isSame(this.calendarTo, "month") &&
	// 					// 	moment(to).isSame(this.calendarTo, "year")
	// 					// if (fromIsSame && toIsSame) {
	// 					this.orderedPatientsData = items
	// 					// }
	// 				} else if (UIStore.calendarTimePeriod === "week") {
	// 					localEvents.forEach((localEvent) => {
	// 						const localDate = localEvent.from.date()
	// 						const localHour = localEvent.from.hours()

	// 						let day = items[localDate] //items[+darr[2]]
	// 						if (isSafe(day)) {
	// 							let hour = day[localHour]
	// 							if (isSafe(hour)) {
	// 								hour.push(localEvent.event)
	// 							} else {
	// 								items[localDate][localHour] = [localEvent.event] //items[+darr[2]][+tarr[0]]
	// 							}
	// 						} else {
	// 							items[localDate] = {} //items[+darr[2]] = {}
	// 							items[localDate][localHour] = [localEvent.event] //items[+darr[2]][+tarr[0]] = [event]
	// 						}
	// 					})

	// 					this.countEvents = localEvents.length

	// 					let fromIsSame =
	// 						moment(from).isSame(this.calendarFrom, "day") &&
	// 						moment(from).isSame(this.calendarFrom, "month") &&
	// 						moment(from).isSame(this.calendarFrom, "year")

	// 					let toIsSame =
	// 						moment(to).isSame(this.calendarTo, "day") &&
	// 						moment(to).isSame(this.calendarTo, "month") &&
	// 						moment(to).isSame(this.calendarTo, "year")

	// 					if (fromIsSame && toIsSame) {
	// 						this.orderedPatientsData = items
	// 					}
	// 				} else {
	// 					let dayNum = moment(CalendarStore.selectedDay).day()
	// 					let from = undefined
	// 					let to = undefined

	// 					if (isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[dayNum])) {
	// 						from = +GlobalStore.openingHour[dayNum].from
	// 						to = +GlobalStore.openingHour[dayNum].to
	// 					}

	// 					let items = []

	// 					if (isSafe(localEvents)) {
	// 						if (localEvents.length > 0) {
	// 							if (isSafe(from) && isSafe(to)) {
	// 								for (let i = 0; i < localEvents.length; i++) {
	// 									let eventFrom = localEvents[i].from

	// 									if (
	// 										i === 0 &&
	// 										moment().isBefore(
	// 											moment(CalendarStore.selectedDay)
	// 												.set("hour", to)
	// 												.set("minute", 0)
	// 										) &&
	// 										(from < eventFrom.hours() || (from == eventFrom.hours() && 0 < eventFrom.minutes()))
	// 									) {
	// 										let freeTime = eventFrom.hours() * 60 + eventFrom.minutes() - from * 60
	// 										items.push({
	// 											timeFrom: moment(eventFrom)
	// 												.set("hour", from)
	// 												.set("minute", 0),
	// 											timeTo: eventFrom,
	// 											freeTime: freeTime
	// 										})
	// 									}

	// 									items.push({time: localEvents[i].from, event: localEvents[i].event})

	// 									if (
	// 										localEvents.length > 1 &&
	// 										i < localEvents.length - 1 &&
	// 										moment().isBefore(localEvents[i + 1].from)
	// 									) {
	// 										let currEvent = localEvents[i].event
	// 										let duration =
	// 											isSafe(currEvent.services) &&
	// 											currEvent.services.length > 0 &&
	// 											isSafe(currEvent.services[0]) &&
	// 											isSafe(currEvent.services[0].duration)
	// 												? +currEvent.services[0].duration
	// 												: 0
	// 										let currEventMinutes =
	// 											duration > 0
	// 												? localEvents[i].from.hours() * 60 + localEvents[i].from.minutes() + duration / 60
	// 												: localEvents[i].from.hours() * 60 + localEvents[i].from.minutes()
	// 										let nextEventMinutes = localEvents[i + 1].from.hours() * 60 + localEvents[i + 1].from.minutes()

	// 										if (currEventMinutes < nextEventMinutes) {
	// 											let dur = duration > 0 ? duration : 0
	// 											let freeTime = nextEventMinutes - currEventMinutes

	// 											items.push({
	// 												timeFrom: moment(localEvents[i].from).add(dur / 60, "minutes"),
	// 												timeTo: moment(localEvents[i + 1].from),
	// 												freeTime: freeTime
	// 											})
	// 										}
	// 									}

	// 									if (i === localEvents.length - 1) {
	// 										let index = localEvents.length - 1
	// 										let lastEvent = localEvents[index].event
	// 										let duration =
	// 											isSafe(lastEvent.services) &&
	// 											lastEvent.services.length > 0 &&
	// 											isSafe(lastEvent.services[0]) &&
	// 											isSafe(lastEvent.services[0].duration)
	// 												? +lastEvent.services[0].duration
	// 												: 0
	// 										let endOfEventTime =
	// 											duration > 0
	// 												? moment(localEvents[index].from).add(duration / 60, "minutes")
	// 												: moment(localEvents[index].from)

	// 										if (
	// 											to > endOfEventTime.hours() &&
	// 											moment().isBefore(
	// 												moment(CalendarStore.selectedDay)
	// 													.set("hour", to)
	// 													.set("minute", 0)
	// 											)
	// 										) {
	// 											let freeTime = to * 60 - (endOfEventTime.hours() * 60 + endOfEventTime.minutes())
	// 											items.push({
	// 												timeFrom: endOfEventTime,
	// 												timeTo: moment(endOfEventTime)
	// 													.set("hour", +to)
	// 													.set("minute", 0),
	// 												freeTime: freeTime
	// 											})
	// 										}
	// 									}
	// 								}
	// 							} else {
	// 								for (let i = 0; i < localEvents.length; i++) {
	// 									items.push({time: localEvents[i].from, event: localEvents[i].event})
	// 								}
	// 							}
	// 						} else {
	// 							if (
	// 								isSafe(from) &&
	// 								isSafe(to) &&
	// 								moment().isBefore(
	// 									moment(CalendarStore.selectedDay)
	// 										.set("hour", to)
	// 										.set("minute", 0)
	// 								)
	// 							) {
	// 								let freeTime = (to - from) * 60
	// 								items.push({
	// 									timeFrom: moment(CalendarStore.selectedDay)
	// 										.set("hour", from)
	// 										.set("minute", 0),
	// 									timeTo: moment(CalendarStore.selectedDay)
	// 										.set("hour", to)
	// 										.set("minute", 0),
	// 									freeTime: freeTime
	// 								})
	// 							}
	// 						}

	// 						this.countEvents = localEvents.length
	// 					} else {
	// 						this.countEvents = 0
	// 					}
	// 					this.orderedPatientsDataArr = items
	// 				}
	// 			}
	// 		})
	// }

	@action setServices() {
		const resourceId = getSelectedOrgUnitResourceID()

		api
			.loadServices(resourceId)
			.call()
			.then((resources) => {
				let items = []
				if (isSafe(resources) && resources.length > 0) {
					resources.forEach((resource) => {
						let duration = isSafe(resource.duration) ? +resource.duration / 60 : 0
						items.push({
							code: resource.provided_service_id,
							_id: resource.provided_service_id,
							name_ext: `${resource.name_ext} (${duration} min.)`,
							name: resource.name_name,
							duration: duration,
							item_order: isSafe(resource.item_order) ? resource.item_order : Number.MAX_VALUE,
							color: resource.color
						})
					})
				}

				// sort podla item_order v pripade rovnosti podla name_ext
				items = items.sort((a, b) => {
					return (
						(isSafe(a.item_order) ? +a.item_order : Number.MAX_VALUE) -
							(isSafe(b.item_order) ? +b.item_order : Number.MAX_VALUE) ||
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				})

				this.resourcesData = items
			})
	}

	@action patientAlertFormSaveChips(data, id) {
		if (isSafe(data) && data instanceof Array) {
			let dataForSave = []
			dataForSave = data.map((row) => {
				return {
					active: true,
					description: row.alert,
					severity: {_id: row.code, _type: "EHR.CL.Severity"},
					_ref: false,
					_type: "EHR.Data.Alert"
				}
			})
			api.saveAlerts(dataForSave, id).call()
		}
	}

	@action patientTagFormSaveChips(data, id) {
		if (isSafe(data) && data instanceof Array) {
			const saveRequest = data.map((row) => {
				return {
					_ref: false,
					_type: "IXS.Data.Tag",
					type: {
						_id: row.tagtype,
						_type: "IXS.CL.Data.TagType"
					}
				}
			})
			api.savePatientTags(saveRequest, id).call()
		}
	}

	@action exportPatients(typeValue) {
		let filterValue = []

		if (this.orgUnit_patientListFilter) {
			filterValue.push({
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnit()._id}]
			})
		} else {
			filterValue.push({
				associated_column: "company_id",
				values: [{id_value: getUserCompanyInfo().id}]
			})
		}

		if (isSafe(this.search_column_patientListFilter)) {
			filterValue.push({
				associated_column: "search_column",
				values: [{id_value: this.search_column_patientListFilter}]
			})
		}

		if (isSafe(this.insurer_patientListFilter)) {
			filterValue.push({
				associated_column: "insurer_id",
				values: [{id_value: this.insurer_patientListFilter}]
			})
		}

		if (isSafe(this.tags_patientListFilter)) {
			filterValue.push({
				associated_column: "tags",
				values: this.tags_patientListFilter
			})
		}

		if (isSafe(this.diagnoses_patientListFilter)) {
			// this.sendingPatientDiagnosesToAPI = isSafe(this.diagnoses_patientListFilter) && this.diagnoses_patientListFilter.length > 0
			filterValue.push({
				associated_column: "diagnoses",
				values: this.diagnoses_patientListFilter
			})
		}

		if (isSafe(this.all_patientListFilter) && isSafe(this.all_patientListFilter.associated_column)) {
			if (this.all_patientListFilter.associated_column === "is_vip") {
				filterValue.push({
					associated_column: "tags",
					values: [{id_value: "VIP"}]
				})
			} else {
				filterValue.push({
					associated_column: this.all_patientListFilter.associated_column,
					values: [{id_value: this.all_patientListFilter.id_value}]
				})
			}
		}

		let filter = {
			uri: `/amb/entity/companies/${getUserCompanyInfo().id}/patients?level_flag=CD`,
			// "uri": `/csp/servizzo/amb/entity/companies/${getUserCompanyInfo().id}/patients`,
			filters: filterValue
		}

		if (typeValue == "now") {
			if (isSafe(this.patientListTableRef)) {
				filter.row_count_show = this.patientListTableRef.state.rowsPerPage
				// filter.row_count_full = this.patientListTableRef.state.rowsPerPage
				filter.row_offset = this.patientListTableRef.state.page * this.patientListTableRef.state.rowsPerPage + 1
			} else {
				filter.row_offset = 1
				filter.row_count_show = 10
				// filter.row_count_full = 10
			}
		} else {
			filter.row_offset = 1
			filter.row_count_show = 100000
			filter.row_count_full = 100000
		}

		api
			.exportData(filter)
			.call()
			.then((response) => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
			.catch(() => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
			})
	}

	// @action getCapitationList() {
	//   const personnel_id = getUserPersonnelID();
	//   let capitationListFilter = getFilters([`personnel_id=${personnel_id}`],
	//     {
	//       "from": moment(1).format(config.DB_DATE_FORMAT),
	//       "to": moment().add(2, "years").format(config.DB_DATE_FORMAT)
	//     })

	//   api.loadCapitation(capitationListFilter).call().then(capitationList => {
	//     this.patientDTO.set("capitationsDTO", capitationList.rows)
	//   })
	// }

	/**
	 * Nastaví formuláru stav podľa vstupných parametrov
	 * @param formName Názov (identifikátor) formulára
	 * @param state Stav
	 */
	setFormDataState(formName, state) {
		if (this.formDataState && this.formDataState[formName]) {
			this.formDataState[formName] = state
			return true
		}
		return false
	}

	/**
	 * Získa stav formulára podľa jeho identifikátora
	 * @param formName Názov (identifikátor) formulára
	 */
	getFormDataState(formName) {
		return this.formDataState[formName]
	}

	/**
	 * Zmení stav na všetkých formulároch na EMPTY
	 */
	clearFormDataState() {
		Object.keys(this.formDataState).forEach((dataState) => {
			if (this.formDataState[dataState]) {
				this.formDataState[dataState] = formDataStateCL.EMPTY
			}
		})
	}

	/**
	 * Vráti či má žiadaný formulár žiadaný stav
	 * @param formName Názov (identifikátor) formulára
	 * @param state Stav
	 */
	checkFormDataState(formName, state) {
		if (formName && state) {
			return this.getFormDataState(formName) === formDataStateCL[state]
		}
		return false
	}
}

var singleton = new DataStore()
export default singleton
