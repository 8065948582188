"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import ProblemsStore from "./ProblemsStore"

class PatientProblemsDialogStore {
	@observable isOpen = false
	@observable isEditable = false
	@observable nameOfDeleteProblem = ""

	initData = null

	@action open(data) {
		this.isOpen = true
		if (isSafe(data)) {
			this.initData = data
		}
	}

	@action close() {
		this.isOpen = false
		this.nameOfDeleteProblem = ""
		ProblemsStore.symptomsChips = []
	}

	@action chechForInitData(form) {
		if (isSafe(this.initData)) {
			let data = this.initData
			form.$("name").set(data.name)
			// this.forms[index].form.$("startDate").set(data.interval_from)
			// this.forms[index].form.$("endDate").set(data.interval_to)
			isSafe(data.interval_from) && isNotEmpty(data.interval_from)
				? form.$("startDate").set(moment.utc(data.interval_from))
				: form.$("startDate").set("")
			isSafe(data.interval_to) && isNotEmpty(data.interval_to)
				? form.$("endDate").set(moment.utc(data.interval_to))
				: form.$("endDate").set("")
			form.$("_id").set(data.record_id)
			form.$("biohazard").set(data.biohazard)

			if (isSafe(data.problem_type) && isSafe(data.problem_type._id) && data.problem_type._id == "DISPENSARY") {
				form.$("problem_type").set(true)
			} else {
				form.$("problem_type").set(false)
			}

			form.$("expected_for_amount").set(isSafe(data.expected_for_amount) ? data.expected_for_amount : "")
			form
				.$("reexamination_every_amount")
				.set(isSafe(data.reexamination_every_amount) ? data.reexamination_every_amount : "")
			form
				.$("reexamination_every_unit")
				.set(
					isSafe(data.reexamination_every_unit) && isNotEmpty(data.reexamination_every_unit._id)
						? data.reexamination_every_unit._id
						: ""
				)
			form.$("end_reason").set(isSafe(data.end_reason) && isNotEmpty(data.end_reason._id) ? data.end_reason._id : "")

			form.$("diagnoses").set(data.diagnosis)
			form.$("diagnosis_desc").set(data.diagnosis_desc)

			if (isSafe(data.record_diagnoses) && data.record_diagnoses.length > 0) {
				const diagnosis = data.record_diagnoses.find((x) => x.active)

				if (isSafe(diagnosis)) {
					form.$("life_threatening").set(diagnosis.life_threatening)
				} else {
					form.$("life_threatening").set(false)
				}
			}

			if (isSafe(data.disease) && isNotEmpty(data.disease._id)) {
				form.$("disease").set(data.disease._id)
			}

			ProblemsStore.symptomsChips = data.symptoms.map((sym) => {
				return {
					text: sym.description,
					severity: isSafe(sym.severity) ? +sym.severity.code_ext : 3
				}
			})
			this.initData = null
		}
	}
}

var singleton = new PatientProblemsDialogStore()
export default singleton
