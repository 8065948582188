/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				examdate: {
					label: <FormattedMessage id="Patient.form.patientrecord.dateOfExam" />,
					type: "date",
					rules: "required"
				},
				anamnesis: {
					label: " ",
					rules: "required"
				},
				id: {},
				// closeForm: {}
			}
		}
	}
}
