import React from "react"
import {injectIntl} from "react-intl"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./nonCapitationFilterFields"

import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import NonCapitationStore from "../../../stores/NonCapitationStore"

@injectIntl
@observer
export default class NonCapitationFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				NonCapitationStore.getFilters(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		NonCapitationStore.currFilters = this.form.values()
		NonCapitationStore.formRef = this.form
		NonCapitationStore.getFilters(this.form)
	}

	render() {
		NonCapitationStore.isChangedFilter(this.form.values())

		return (
			<Grid container spacing={8} alignItems="center">
				<Grid item xs={3}>
					<XsPeriodPicker
						white
						onChange={(val) => {
							this.form.$("validity_from").value = val.from
							this.form.$("validity_to").value = val.to
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<XsInput white field={this.form.$("identifier")} />
				</Grid>
				<Grid item xs={2}>
					<XsSearchSelect white field={this.form.$("provider_id")} items={getUserPersonnelOrgUnits()} />
				</Grid>
				<Grid item className="pb-0">
					<XsIconButton
						rect
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						className="xs-default xs-outline"
						onClick={this.form.onSubmit}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
			</Grid>
		)
	}
}
