//@flow
import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import {withStyles} from "@material-ui/core/styles"
import {observer} from "mobx-react"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import {getSelectedOrgUnitID, printMultiReport, getUserCompanyInfo} from "../../../../../global/helpers/actions"
import Overview from "./overview"
import action from "../../../actions/patientCard"
import WarningStore from "../../../../../global/store/WarningStore"
import DataStore from "../../../stores/DataStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import CancelIcon from "@material-ui/icons/Clear"
import PatientClinicalValuesChart from "../patientClinicalValuesChart/patientClinicalValuesChart"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import PatientRecordDropzoneModal from "../../patientDetail/patientRecordForm/patientRecordDropzoneModal"
import PatientRecordDropzoneModalStore from "../../../stores/PatientRecordDropzoneModalStore"
import PatientRequestStore from "../../../stores/PatientRequestStore"
// import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import PatientSicknessAbsStore from "../../../stores/PatientSicknessAbsStore"
import OverPrescriptionDialogStore from "../../../stores/OverPrescriptionDialogStore"
import StornoPrescriptionDialog from "./stornoPrescriptionDialog"
import ConfirmStornoPrescriptionDialog from "./confirmStornoPrescriptionDialog"
import StornoRequestDialog from "./stornoRequestDialog"
import StornoRecordDialog from "./stornoRecordDialog"
import StornoOpProtocolDialog from "./stornoOpProtocolDialog"
import StornoRecordDialogStore from "../../../stores/StornoRecordDialogStore"
import StornoRequestDialogStore from "../../../stores/StornoRequestDialogStore"
import StornoOpProtocolDialogStore from "../../../stores/StornoOpProtocolDialogStore"
import PatientPrescriptionContainerStore from "../../../stores/PatientPrescriptionContainerStore"
import EHealthRecordDialogStore from "../../../stores/EHealthRecordDialogStore"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import PatientMedicationStore from "../../../stores/PatientMedicationStore"
import StornoDialog from "./stornoDialog"
import StornoDialogStore from "../../../stores/StornoDialogStore"
import MultiStornoDialog from "./multiStornoDialog"
import MultiStornoDialogStore from "../../../stores/MultiStornoDialogStore"
import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import ScoringStore from "../../../stores/ScoringStore"
import MoveRecordDialog from "./moveRecordDialog"
import MoveRecordDialogStore from "../../../stores/MoveRecordDialogStore"
import MedicationDistDialog from "../patientPrescriptionForm/medicationDistDialog"
import SearchExchangeTicketsStore from "../../../stores/SearchExchangeTicketsStore"
import SearchExchangeTicketsDialog from "../patientCard/searchExchangeTicketsDialog"
import VaccAllergyDialogStore from "../../../stores/VaccAllergyDialogStore"
import LabResultsStore from "../../../stores/LabResultsStore"
import VaccAllergyDialog from "./vaccAllergyDialog"
import LabResultDialog from "../labResults/labResultDialog"

import OverPrescriptionDialog from "../patientPrescriptionForm/overPrescriptionDialog"

import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"

import api from "../../../actions/api"
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {formDataStateCL} from "../../../../../global/config/codeLists"

const styles = () => ({
	cvchartDialog: {
		minWidth: "800px"
	}
})

@observer
export class OverviewContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			patientOverviewLoading: true,
			cvChartDialogOpen: false
		}

		PatientRecordStore.reset()
		PatientRequestStore.reset()
		this.fromPropsPatientID = this.props.patientID
		this.fromPropsID = this.props.id
		this.reloadOverview()
	}

	reloadOverview(filter) {
		let providerID = getSelectedOrgUnitID()
		const companyID = getUserCompanyInfo()._id
		const overviewFilter = isSafe(filter) ? filter : {time: {search_string: "thisYear"}}
		const sendingDiagnoses = isSafe(filter) && isSafe(filter.diagnoses) && filter.diagnoses.length > 0

		providerID = !UIStore.patientOverviewFilterCheckBox ? "" : providerID
		if (isSafe(this.fromPropsPatientID)) {
			if (!this.state.patientOverviewLoading) {
				this.setState({
					patientOverviewLoading: true
				})
			}
			action
				.loadPatientOverview(providerID, this.fromPropsPatientID, overviewFilter, companyID)
				.then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (!sendingDiagnoses && isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}

					this.setState({
						patientOverviewLoading: false
					})
				})
				.catch(() => {
					this.setState({
						patientOverviewLoading: false
					})
				})
		} else {
			let ixsRequestPatientId = null
			api
				.loadEntityInfo(this.fromPropsID)
				.call(null, this.fromPropsID, (id) => (ixsRequestPatientId = id))
				.then((response) => {
					//Len ak je ID pacienta zhodné s ID requestom
					if (this.fromPropsID === ixsRequestPatientId) {
						if (!this.state.patientOverviewLoading) {
							this.setState({
								patientOverviewLoading: true
							})
						}

						const client = response.specific_rels.find((sr) => sr._type === "Entity.Data.Client" && sr.active)

						action
							.loadPatientOverview(providerID, client._id, overviewFilter, companyID)
							.then((overview) => {
								DataStore.setPatientEHR(overview)

								let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
								if (!sendingDiagnoses && isSafe(filterDiag) && isSafe(filterDiag[0])) {
									DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
								}

								this.setState({
									patientOverviewLoading: false
								})
							})
							.catch(() => {
								this.setState({
									patientOverviewLoading: false
								})
							})
					}
				})
		}
	}

	getFilteredData(data, typeID) {
		return data.filter((record) => {
			if (Array.isArray(typeID)) {
				return typeID.includes(record.type_code.toUpperCase())
			} else {
				return record.type_code.toUpperCase() === typeID
			}
		})
	}

	getCheckedFilteredData(data) {
		return data.filter((record) => {
			return DataStore.isCheckedEHR(record)
		})
	}

	getNoDeliveriesFilteredData(data) {
		return data.filter(
			(record) =>
				record.has_deliveries === false && record.type_code.toUpperCase() === "DEK" && record.version == "final"
		)
	}

	toggleCVChartDialog = () => {
		this.setState({cvChartDialogOpen: !this.state.cvChartDialogOpen})
	}

	getClinicalValuesFilteredData(data) {
		return data
			.filter((record) => {
				return record.clinical_values && record.clinical_values.length > 0
			})
			.map((r) => {
				return {
					clinical_values: [...r.clinical_values],
					type_code: "KV",
					name: this.props.intl.formatMessage({id: "Patient.form.patientrecord.clinicalvalues"}),
					description: r.description,
					created_at: r.created_at
				}
			})
	}

	getDataCount(data) {
		return data.length > 0 ? data.length.toString() : ""
	}

	existsWrittingMessage(data) {
		return data.some((rec) => rec.version === "draft")
	}

	UNSAFE_componentWillUpdate() {
		if (isSafe(DataStore.patientDTO.get("patientEHR")) && isSafe(DataStore.patientDTO.get("patientEHR").rows)) {
			const patientOverviewData = DataStore.patientDTO.get("patientEHR").rows
			const patientAnamnesisData = this.getFilteredData(patientOverviewData, "ANA")
			patientAnamnesisData.forEach((anam) => {
				let anamSorted = JSON.parse(JSON.stringify(anam))
				if (isSafe(anamSorted.data) && anamSorted.data.length > 1) {
					anamSorted.data.sort((a, b) => {
						const first = isSafe(a.type) && isSafe(a.type.item_order) ? a.type.item_order : null
						const second = isSafe(b.type) && isSafe(b.type.item_order) ? b.type.item_order : null
						return first - second
					})
				}
				anam.data = anamSorted.data
			})
		}
	}

	printRequestAction(dateFormat, record) {
		//Direct print action add to selected
		if (record) {
			DataStore.addToCheckedEHR(record)
		}
		const checkedREQ = DataStore.checkedEHR.filter((e) => ["OV", "ZV"].includes(e.type_code))
		checkedREQ.forEach((e) => {
			PatientRequestStore.printRequest(e.record_id, DataStore.patientDTO.get("patient"), {
				dateFormat: dateFormat
			})
		})
		DataStore.clearCheckedEHR()
	}

	printPrescriptionAction(prescription) {
		if (prescription) {
			DataStore.addToCheckedEHR(prescription)
		}
		let multipleRx = []
		const checkedRX = DataStore.checkedEHR.filter((e) => e.type_code === "RX")
		checkedRX.forEach((e) => {
			multipleRx.push(
				PatientPrescriptionStore.printPrescription(e.record_id, DataStore.patientDTO.get("patient"), false)
			)
		})
		Promise.all(multipleRx).then((r) => {
			printMultiReport(r, null, true)
			DataStore.clearCheckedEHR()
		})
	}

	render() {
		const {intl} = this.props
		const {patientOverviewLoading} = this.state
		const providerID = getSelectedOrgUnitID()

		if (!patientOverviewLoading) {
			const checkedPrescriptions = this.getFilteredData(DataStore.checkedEHR, "RX")
			const checkedAmbulances = this.getFilteredData(DataStore.checkedEHR, "DEK")
			const checkedRequests = this.getFilteredData(DataStore.checkedEHR, ["OV", "ZV"])
			let patientPrescriptionAction = []
			let patientPrescriptionActionAMB = []
			let patientAmbulanceAction = []
			let patientRequestAction = []

			const patientOverviewData = isSafe(DataStore.patientDTO.get("patientEHR"))
				? DataStore.patientDTO.get("patientEHR").rows.filter((x) => x.type_code != "EHR.Data.DlvRecord") //EHR.Data.DlvRecord - nezobrazovat v EHR overview
				: []
			const dataWithSymptoms = patientOverviewData.filter((data) => {
				return data.symptoms && data.symptoms.length > 0
			})

			const dateFormat = intl.formatMessage({id: "Application.moment.dateformat"})

			const patientAnamnesisData = this.getFilteredData(patientOverviewData, "ANA")
			const patientMedicationData = this.getFilteredData(patientOverviewData, "RX")
			const patientLaboratoryData = this.getFilteredData(patientOverviewData, "LAB")
			const patientAmbulanceData = this.getFilteredData(patientOverviewData, "DEK")
			const patientHospitalizationData = this.getFilteredData(patientOverviewData, "HOS")
			const patientRequestData = this.getFilteredData(patientOverviewData, ["OV", "ZV"])
			const patientOperationProtocolData = this.getFilteredData(patientOverviewData, "OPP")
			const patientNZISExternalDocuments = this.getFilteredData(patientOverviewData, "NZIS")
			const patientSicknessAbsData = this.getFilteredData(patientOverviewData, "PN")
			const patientClinicalValues = this.getClinicalValuesFilteredData(patientOverviewData)
			const patientEHRCheckedData = this.getCheckedFilteredData(patientOverviewData)
			const patientNoDeliveriesData = this.getNoDeliveriesFilteredData(patientOverviewData)
			const patientMedicationDataAMB = this.getFilteredData(patientOverviewData, "POD")
			const printTemplatesData = this.getFilteredData(patientOverviewData, "REP")
			const printScoringData = this.getFilteredData(patientOverviewData, "SCR")

			//AKCIE PRE RECEPT
			const patientMedicationDataWithAction = patientMedicationData.map((med) => {
				let actions = []
				if (isSafe(med.version) && med.version == "final") {
					actions = [
						{
							// icon: <i className="fal fa-redo-alt fa-lg"></i>,
							label: intl.formatMessage({id: "Patient.form.patientPrescription.represcribe"}),
							onButtonClick: () => {
								this.props.actions.prescription.represcribe([
									...DataStore.checkedEHR.filter((obj) => obj.type_code == "RX"),
									med
								])
								// if (DataStore.checkFormDataState("PatientPrescriptionForm", formDataStateCL.MODIFIED)) {
								// 	GlobalStore.openConfirmationDialog("patientPrescriptionReprescribe", {medicationId: med})
								// } else {
								// 	if (UIStore.patientCardTab === "prescription") {
								// 		GlobalStore.handleClickFocus("patientPrescriptionForm")
								// 	}
								// 	UIStore.patientCardTab = "prescription"

								// 	PatientPrescriptionContainerStore.represcribe([
								// 		...DataStore.checkedEHR.filter((obj) => obj.type_code == "RX"),
								// 		med
								// 	])
								// }
								// PatientPrescriptionStore.represcribe(med.record_id)
							}
						}
					]

					if (
						(isSafe(med.prescription.repeats_number) && med.prescription.repeats_number <= 1) ||
						isNotSafe(med.prescription.repeats_number)
					) {
						actions.push({
							// icon: <i className="fal fa-print fa-lg"></i>,
							label: intl.formatMessage({id: "Common.label.print"}),
							onButtonClick: () => this.printPrescriptionAction(med)
						})
					}

					if (DataStore.checkedEHR.length < 2) {
						// single storno
						actions.push({
							label: intl.formatMessage({id: "Common.label.storno"}),
							id: "action-cancel",
							onButtonClick: () => {
								MultiStornoDialogStore.open([med])
							}
						})
					} else {
						// multi storno len ak som v liekoch
						if (UIStore.overviewTab == "medication") {
							actions.push({
								label: intl.formatMessage({id: "Common.label.storno"}),
								id: "action-cancel",
								onButtonClick: () => {
									MultiStornoDialogStore.open(DataStore.checkedEHR.filter((x) => x.type_code == "RX"))
								}
							})
						}
					}

					//Zneplatni
					const deactivateAction = {
						label: intl.formatMessage({id: "Patient.form.patientPrescription.deactivatePrescription"}),
						onButtonClick: () => {
							PatientPrescriptionStore.deactivate(
								med.record_id,
								intl.formatMessage({id: "Common.label.prescriptionDeactivated"})
							)
						}
					}

					//Over preskripciu
					const checkAction = {
						// icon:<i className="fal fa-check fa-lg"></i>,
						label: intl.formatMessage({id: "Common.label.checkPrescription"}),
						onButtonClick: () => {
							OverPrescriptionDialogStore.open(med.barcode)
						}
					}

					if (isSafe(med) && isSafe(med.prescription) && med.prescription.repeats_number > 1) {
						if (isSafe(med.barcode) && isNotEmpty(med.barcode)) {
							if (isSafe(med.prescription.validity) && med.prescription.validity.now) {
								actions.push(deactivateAction)
							}
							actions.push(checkAction)
						} else {
							actions.push(deactivateAction)
						}
					} else {
						if (isSafe(med.barcode) && isNotEmpty(med.barcode)) {
							actions.push(checkAction)
						}
					}

					actions.push({
						// icon: <i className="fal fa-exchange fa-lg"></i>,
						label: intl.formatMessage({id: "Common.label.move"}),
						onButtonClick: () => {
							MoveRecordDialogStore.open(med.record_id, DataStore.checkedEHR.map((x) => x.record_id))
						}
					})
				} else if (med.version && med.version === "draft") {
					actions = [
						{
							// icon: <i className="fab fa-firstdraft fa-lg"></i>,
							label: intl.formatMessage({id: "Patient.form.patientrecord.editdraft"}),
							onButtonClick: () => {
								this.props.actions.prescription.represcribe([
									...DataStore.checkedEHR.filter((obj) => obj.type_code == "RX"),
									med
								])
								// if (DataStore.checkFormDataState("PatientPrescriptionForm", formDataStateCL.MODIFIED)) {
								// 	GlobalStore.openConfirmationDialog("patientPrescriptionReprescribe", {medicationId: med})
								// } else {
								// 	if (UIStore.patientCardTab === "prescription") {
								// 		GlobalStore.handleClickFocus("patientPrescriptionForm")
								// 	}
								// 	UIStore.patientCardTab = "prescription"
								// 	PatientPrescriptionContainerStore.represcribe([...DataStore.checkedEHR, med])
								// 	DataStore.clearCheckedEHR()
								// }
							}
						}
					]
				}

				med.actions = actions

				return med
			})

			//AKCIA PRE VŠETKY RECEPTY
			if (checkedPrescriptions && checkedPrescriptions.length > 0) {
				patientPrescriptionAction = [
					{
						// icon: <i className="fal fa-print fa-lg"></i>,
						label: `${intl.formatMessage({id: "Patient.form.patientprescription.printselected"})} (${
							checkedPrescriptions.length
						})`,
						onButtonClick: () => this.printPrescriptionAction()
					}
				]
			}

			//AKCIA PRE VŠETKY DEKURZY
			if (checkedAmbulances && checkedAmbulances.length > 0) {
				patientAmbulanceAction = [
					{
						label: `${intl.formatMessage({id: "Patient.form.patientprescription.printselected"})} (${
							checkedAmbulances.length
						})`,
						onButtonClick: () => {
							const checkedAMB = DataStore.checkedEHR.filter((e) => e.type_code === "DEK")
							checkedAMB.forEach((e) => {
								PatientRecordStore.printRecord(e.record_id, DataStore.patientDTO.get("patient"))
							})
							DataStore.clearCheckedEHR()
						}
					}
				]
			}

			//AKCIA PRE VŠETKY ŽIADANKY
			if (checkedRequests && checkedRequests.length > 0) {
				patientRequestAction = [
					{
						label: `${intl.formatMessage({id: "Patient.form.patientprescription.printselected"})} (${
							checkedRequests.length
						})`,
						onButtonClick: () => this.printRequestAction(dateFormat)
					}
				]
			}

			//AKCIE PRE DEKURZ
			const patientAmbulanceDataWithAction = patientAmbulanceData.map((amb) => {
				//Iba ak je dekurz FINAL
				let actions = []
				if (amb.version && amb.version === "draft") {
					actions.push({
						label: intl.formatMessage({id: "Patient.form.patientrecord.editdraft"}),
						onButtonClick: () => {
							this.props.actions.record.editdraft(amb.record_id)
							// if (DataStore.checkFormDataState("PatientRecordForm", formDataStateCL.MODIFIED)) {
							// 	GlobalStore.openConfirmationDialog("patientRecordReexamination", {recordId: amb.record_id})
							// } else {
							// 	UIStore.patientCardTab = "record"
							// 	PatientRecordStore.recordLoaded = false
							// 	PatientRecordStore.loadRecordData(amb.record_id)
							// }
						}
					})
				} else {
					actions.push({
						label: intl.formatMessage({id: "Common.label.reexamination"}),
						onButtonClick: () => {
							this.props.actions.record.reexamination(amb.record_id)
							// if (DataStore.checkFormDataState("PatientRecordForm", formDataStateCL.MODIFIED)) {
							// 	GlobalStore.openConfirmationDialog("patientRecordReexamination", {recordId: amb.record_id})
							// } else {
							// 	UIStore.patientCardTab = "record"
							// 	PatientRecordStore.recordLoaded = false
							// 	PatientRecordStore.loadRecordData(amb.record_id)
							// }
						}
					})
				}
				actions = actions.concat([
					{
						label: intl.formatMessage({id: "Common.label.scoring"}),
						onButtonClick: () => {
							this.props.actions.record.scoring(amb.record_id)
							// if (DataStore.checkFormDataState("PatientRecordForm", formDataStateCL.MODIFIED)) {
							// 	GlobalStore.openConfirmationDialog("patientRecordReexamination", {
							// 		recordId: amb.record_id,
							// 		scoring: true
							// 	})
							// } else {
							// 	UIStore.patientCardTab = "record"
							// 	PatientRecordStore.recordLoaded = false
							// 	PatientRecordStore.loadRecordData(amb.record_id, true)
							// }
						}
					},
					{
						label: intl.formatMessage({id: "Common.label.patientSicknessAbs"}),
						onButtonClick: () => {
							this.props.actions.record.patientSicknessAbs(amb)
							// if (UIStore.patientCardTab === "sicknessabs") {
							// 	GlobalStore.handleClickFocus("patientSicknessAbs")
							// }
							// PatientSicknessAbsStore.recordLoaded = false
							// PatientSicknessAbsStore.loadRecord(amb)
							// UIStore.patientCardTab = "sicknessabs"
						}
					},
					{
						label: intl.formatMessage({id: "Common.label.print"}),
						onButtonClick: () => {
							this.props.actions.record.print(amb)
						}
					},
					{
						label: intl.formatMessage({id: "Common.label.request"}),
						onButtonClick: () => {
							this.props.actions.record.request(amb)
							// if (UIStore.patientCardTab === "request") {
							// 	GlobalStore.handleClickFocus("patientRequestForm")
							// }

							// if (!PatientRequestStore.isSelectedEHR(amb)) {
							// 	PatientRecordStore.recordLoaded = false
							// 	PatientRequestStore.recordLoaded = false
							// 	PatientRecordStore.loadRecord(amb)
							// } else {
							// 	WarningStore.open(intl.formatMessage({id: "Patient.form.patientRequest.masterrecordwarning"}))
							// }
							// UIStore.patientCardTab = "request"
						}
					},
					{
						label: intl.formatMessage({id: "Common.label.move"}),
						onButtonClick: () => {
							MoveRecordDialogStore.open(amb.record_id, DataStore.checkedEHR.map((x) => x.record_id))
						}
					},
					{
						label: intl.formatMessage({id: "Common.label.addAttachemnt"}),
						onButtonClick: () => {
							PatientRecordDropzoneModalStore.open(amb.record_id)
						}
					}
				])

				if (DataStore.checkedEHR.length < 2) {
					actions.push({
						label: intl.formatMessage({id: "Common.label.storno"}),
						id: "action-cancel",
						onButtonClick: () => {
							StornoRecordDialogStore.open(amb.record_id)
						}
					})
				}

				amb.actions = actions
				return amb
			})

			//AKCIE PRE ANAMNÉZU
			const patientAnamnesisDataWithAction = patientAnamnesisData.map((anam) => {
				// let anamSorted = JSON.parse(JSON.stringify(anam))
				// if (isSafe(anamSorted.data) && anamSorted.data.length > 1) {
				//   anamSorted.data.sort((a, b) => {
				//     const first = isSafe(a.type) && isSafe(a.type.item_order) ? a.type.item_order : null
				//     const second = isSafe(b.type) && isSafe(b.type.item_order) ? b.type.item_order : null
				//     return first - second
				//   })
				// }

				// anam.data = JSON.parse(JSON.stringify(anamSorted.data))

				//Akcia upraviť, len pre anamnézu z aktuálne zvoleného oddelenia
				if (
					anam &&
					anam.provider_card &&
					anam.provider_card.org_unit_id &&
					anam.provider_card.org_unit_id === providerID
				) {
					anam.actions = [
						{
							label:
								isSafe(anam.version) && anam.version == "draft"
									? intl.formatMessage({id: "Patient.form.patientrecord.editdraft"})
									: intl.formatMessage({id: "Common.label.edit"}),
							onButtonClick: () => {
								this.props.actions.anamnesis.edit()
								// if (UIStore.patientCardTab === "anamnesis") {
								// 	GlobalStore.handleClickFocus("patientAnamnesisForm", true)
								// 	// ReactDOM.findDOMNode(this.refs['UniqueElementIdentifier']).getBoundingClientRect();
								// }
								// window.scrollTo(0, 200)
								// // if (isSafe(UIStore.patientCardTab === "anamnesis") && isSafe(PatientAnamnesisStore.textAreaAnamnesisRef)) {
								// //   PatientAnamnesisStore.textAreaAnamnesisRef.reactQuillRef.focus()
								// // }
								// UIStore.patientCardTab = "anamnesis"
								// // anam.data.date = anam.created_at
								// PatientAnamnesisStore.loadData(anam)
							}
						}
					]
				}
				// anam = JSON.parse(JSON.stringify(anamSorted));
				return anam
			})

			//AKCIE PRE ŽIADANKU
			const patientRequestDataWithAction = patientRequestData.map((req) => {
				let actions = [
					{
						// icon: <i className="fal fa-redo-alt fa-lg"></i>,
						label: intl.formatMessage({id: "Patient.form.patientRequest.represcribe"}),
						onButtonClick: () => {
							this.props.actions.request.represcribe([
								...DataStore.checkedEHR.filter((obj) => obj.type_code == "OV" || obj.type_code == "ZV"),
								req
							])
							// if (DataStore.checkFormDataState("PatientRequestForm", formDataStateCL.MODIFIED)) {
							// 	GlobalStore.openConfirmationDialog("patientRequestReprescribe", {requestId: req})
							// } else {
							// 	//ak form request neexistuje(nie je vykresleny), nemoze to byt po priradeni patientCardTab,
							// 	//lebo uz podmienka bude platit, ale stale nebude este existovat referencia na XsInput, ktoru potrebujeme focusnut
							// 	if (UIStore.patientCardTab === "request") {
							// 		GlobalStore.handleClickFocus("patientRequestForm")
							// 	}
							// 	UIStore.patientCardTab = "request"
							// 	PatientRequestStore.represcribe([
							// 		...DataStore.checkedEHR.filter((obj) => obj.type_code == "OV" || obj.type_code == "ZV"),
							// 		req
							// 	])
							// }
						}
					},
					{
						// icon: <i className="fal fa-print fa-lg"></i>,
						label: intl.formatMessage({id: "Common.label.print"}),
						onButtonClick: () => this.printRequestAction(dateFormat, req)
					}
				]

				if (req.version == "draft") {
					actions[0] = {
						label: intl.formatMessage({id: "Patient.form.patientrecord.editdraft"}),
						onButtonClick: () => {
							this.props.actions.request.represcribe(
								[...DataStore.checkedEHR.filter((obj) => obj.type_code == "OV" || obj.type_code == "ZV"), req],
								true
							)
						}
					}
				}

				if (DataStore.checkedEHR.length < 2) {
					actions.push({
						label: intl.formatMessage({id: "Common.label.storno"}),
						id: "action-cancel",
						onButtonClick: () => {
							StornoRequestDialogStore.open(req.record_id)
						}
					})
				}

				if (isNotEmpty(req.ehealth_id)) {
					actions.push({
						// icon: <i className="fal fa-reply fa-lg"></i>,
						label: intl.formatMessage({id: "Patient.form.patientPrescription.getResponse"}),
						onButtonClick: () => {
							SearchExchangeTicketsStore.open(req.ehealth_id)
						}
					})
				}

				actions.push({
					label: intl.formatMessage({id: "Common.label.move"}),
					onButtonClick: () => {
						MoveRecordDialogStore.open(req.record_id, DataStore.checkedEHR.map((x) => x.record_id))
					}
				})

				req.actions = actions

				return req
			})

			//AKCIE PRE OPERACNY PROTOKOL
			const patientOperationProtocolDataWithAction = patientOperationProtocolData.map((op) => {
				op.actions = [
					{
						// icon: <i className="fal fa-trash-alt fa-lg"></i>,
						label: intl.formatMessage({id: "Common.label.storno"}),
						id: "action-cancel",
						onButtonClick: () => {
							StornoOpProtocolDialogStore.open(op.record_id)
						}
					}
				]
				return op
			})

			//AKCIA PRE LABAKY
			const patientLabsAction = patientLaboratoryData.map((lab) => {
				lab.actions = [
					{
						label: intl.formatMessage({id: "Common.label.print"}),
						onButtonClick: () => LabResultsStore.printLabResults(lab.record_id)
					}
				]
				return lab
			})

			//AKCIA PRE KLINICKÉ HODNOTY
			const patientClinicalValuesAction = [
				{
					// icon: <i className="fal fa-chart-line fa-lg"></i>,
					label: intl.formatMessage({id: "Patient.form.patientclinicalvalues.showchart"}),
					onButtonClick: this.toggleCVChartDialog
				}
			]

			//AKCIE PRE PN
			const patientSicknessAbsWithAction = patientSicknessAbsData.map((sa) => {
				// Upraviť môžeme len PN (Soc. poist) len z aktuálneho oddelenia
				if (sa && sa.provider_card && sa.provider_card.org_unit_id && sa.provider_card.org_unit_id === providerID) {
					let actions = [
						{
							// icon: <i className="fal fa-pencil-alt fa-lg"></i>,
							label: intl.formatMessage({id: "Common.label.edit"}),
							onButtonClick: () => {
								this.props.actions.sickness.edit(sa.record_id)
								// PatientSicknessAbsStore.set("sicknessAbsLoaded", false)
								// PatientSicknessAbsStore.set("sicknessAbsID", )
								// UIStore.patientCardTab = "sicknessabs"
							}
						}
					]

					//Iba ak ide o PN pridať akciu na Tlač TDPN
					if (sa.sickness_abs && sa.sickness_abs.sub_type && sa.sickness_abs.sub_type === "DPN") {
						actions.push({
							label: intl.formatMessage({id: "Patient.form.patientsicknessabs.printTDPN"}),
							onButtonClick: () => PatientSicknessAbsStore.printSicknessAbsTDPN(sa.record_id)
						})
					}

					// 11448 ak ide o PN alebo OCR pridať akciu na Tlač
					// if (
					// 	sa.sickness_abs &&
					// 	sa.sickness_abs.sub_type &&
					// 	(sa.sickness_abs.sub_type === "DPN" || sa.sickness_abs.sub_type === "OCR")
					// ) {
					// 	const translations = {dateFormat: this.props.intl.formatMessage({id: "Application.moment.dateformat"})}

					// 	actions.push({
					// 		label: intl.formatMessage({id: "Common.label.print"}),
					// 		onButtonClick: () =>
					// 			PatientSicknessAbsStore.printSicknessAbs(
					// 				sa.record_id,
					// 				DataStore.patientDTO.get("patient"),
					// 				translations
					// 			)
					// 	})
					// }

					sa.actions = actions
				}
				return sa
			})

			//AKCIE PRE NZIS
			const patientNZISExternalDocumentsWithAction = patientNZISExternalDocuments.map((ed) => {
				let edactions = [
					{
						label: intl.formatMessage({id: "Patient.form.patientrecord.nzisexternal.show"}),
						onButtonClick: () => {
							EHealthRecordDialogStore.open(ed.record_id)
						}
					}
				]

				if (ed.external && !ed.external.version) {
					edactions.push({
						label: intl.formatMessage({id: "Patient.form.patientrecord.nzisexternal.reply"}),
						onButtonClick: () => {
							PatientRecordStore.recordLoaded = false
							PatientRecordStore.masterRecord = ed
							UIStore.patientCardTab = "record"
						}
					})
				}

				ed.actions = edactions
				return ed
			})

			const symptoms = []

			dataWithSymptoms.forEach((data) => {
				data.symptoms.forEach((symptom) => {
					symptoms.push({
						label: symptom.type.name_ext,
						severity: symptom.severity.code
					})
				})
			})

			//AKCIE PRE MEDIKACIU
			const patientMedicationDataWithActionAMB = patientMedicationDataAMB.map((med) => {
				let actions = []
				if (isSafe(med.version) && med.version == "final") {
					actions = [
						{
							// icon: <i className="fal fa-redo-alt fa-lg"></i>,
							label: intl.formatMessage({id: "Patient.Overview.Medication.ReprescribeMedication"}),
							onButtonClick: () => {
								this.props.actions.medication.represcribe([
									...DataStore.checkedEHR.filter((obj) => obj.type_code == "POD"),
									med
								])

								// if (DataStore.checkFormDataState("PatientMedicationForm", formDataStateCL.MODIFIED)) {
								// 	GlobalStore.openConfirmationDialog("patientReprescribeMedication", {medicationObj: med})
								// } else {
								// 	if (UIStore.patientCardTab === "medication") {
								// 		GlobalStore.handleClickFocus("patientMedicationForm")
								// 	}
								// 	UIStore.patientCardTab = "medication"

								// PatientMedicationStore.represcribe([
								// 	...DataStore.checkedEHR.filter((obj) => obj.type_code == "POD"),
								// 	med
								// ])

								// 	if (
								// 		isSafe(med.medication) &&
								// 		med.medication.medication_type == "ADMVACC" &&
								// 		med.symptoms.length > 0
								// 	) {
								// 		const actualSymptoms = med.symptoms.map((x) => x.type.name_ext)

								// 		setTimeout(() => {
								// 			WarningStore.open(
								// 				`${this.props.intl.formatMessage({
								// 					id: "Common.label.medicationSymptomsWarning"
								// 				})}: ${actualSymptoms.join(", ")}`
								// 			)
								// 		}, 2000)
								// 	}
								// }
							}
						},
						{
							label: intl.formatMessage({id: "Common.label.move"}),
							onButtonClick: () => {
								MoveRecordDialogStore.open(med.record_id, DataStore.checkedEHR.map((x) => x.record_id))
							}
						}
					]

					if (DataStore.checkedEHR.length < 2) {
						actions.push({
							label: intl.formatMessage({id: "Common.label.storno"}),
							id: "action-cancel",
							onButtonClick: () => {
								StornoDialogStore.open(
									med.record_id,
									"Patient.Overview.Medication.CancelMedication",
									PatientMedicationStore.storno
								)
							}
						})
					}

					if (isSafe(med.medication) && med.medication.medication_type == "ADMVACC") {
						actions.push({
							// icon: <i className="fal fa-engine-warning fa-lg"></i>,
							label: intl.formatMessage({id: "Common.label.vaccAllergy"}),
							onButtonClick: () => {
								VaccAllergyDialogStore.open(med.record_id, med.symptoms)
							}
						})
					}
				}
				// else if (med.version && med.version === "draft") {
				//   actions = [
				//     {
				//       label: intl.formatMessage({ id: "Patient.form.patientrecord.editdraft" }),
				//       onButtonClick: () => {
				//         if (UIStore.patientCardTab === "medication") {
				//           GlobalStore.handleClickFocus("patientMedicationForm")
				//         }
				//         UIStore.patientCardTab = "medication"
				//         //PatientPrescriptionContainerStore.represcribe([...DataStore.checkedEHR, med])
				//         //DataStore.clearCheckedEHR();
				//       }
				//     }
				//   ];
				// }

				med.actions = actions

				return med
			})

			//AKCIE PRE SKÓRING
			const printScoringDataWithAction = printScoringData.map((scoring) => {
				scoring.actions = [
					{
						label: intl.formatMessage({id: "Common.label.print"}),
						onButtonClick: () => ScoringStore.printScoring(scoring.record_id)
					},
					{
						label: intl.formatMessage({id: "Common.label.storno"}),
						id: "action-cancel",
						onButtonClick: () => ScoringStore.storno(scoring.record_id)
					}
				]
				return scoring
			})

			//AKCIE PRE TLACIVA
			const patientPrintTemplatesDataWithAction = printTemplatesData.map((med) => {
				let actions = []

				let isPrazdnyDekurz = false
				let actionPrintToTemplate = null
				let isHidePrint = false

				if (med.data.length > 0) {
					if (isSafe(med.data[0].content)) {
						try {
							const content = base64DecodeUnicode(med.data[0].content)
							const contentJSON = JSON.parse(content)
							if (contentJSON.templateId == "header") {
								isPrazdnyDekurz = true
							}

							const templateObj = PrintTemplatesStore.printTemplates.find((x) => x.id == contentJSON.templateId)
							if (isSafe(templateObj) && templateObj.hidePrint) {
								isHidePrint = true
							}

							if (isSafe(contentJSON.paperTemplateId)) {
								actionPrintToTemplate = {
									label: intl.formatMessage({id: "Common.label.printToTemplate"}),
									onButtonClick: () => {
										PrintTemplatesStore.print(med.record_id, true)
									}
								}
							}
						} catch (e) {
							logger("OverviewContainer base64DecodeUnicode or parse content error", e)
						}
					}
				}

				actions = [
					{
						// icon: <i className="fal fa-pencil-alt fa-lg"></i>,
						label: intl.formatMessage({id: "Common.label.edit"}),
						onButtonClick: () => {
							if (isPrazdnyDekurz) {
								UIStore.isOverviewTemplateLoading = true
							}
							UIStore.patientCardTab = "templates"
							PrintTemplatesStore.edit(med.record_id)
						}
					}
				]

				if (!isHidePrint) {
					actions.push({
						label: intl.formatMessage({id: "Common.label.print"}),
						onButtonClick: () => {
							PrintTemplatesStore.print(med.record_id)
						}
					})
				}

				if (isSafe(actionPrintToTemplate)) {
					actions.push(actionPrintToTemplate)
				}

				actions.push({
					label: intl.formatMessage({id: "Common.label.storno"}),
					id: "action-cancel",
					onButtonClick: () => {
						GlobalStore.openConfirmationDialog("deletePrintTemplateConfirmation", {
							id: med.record_id
						})
						// StornoDialogStore.open(
						// 	med.record_id,
						// 	"Patient.Overview.PrintTemplates.CancelPrompt",
						// 	PrintTemplatesStore.storno
						// )
					}
				})

				med.actions = actions

				return med
			})

			const checkedCount = DataStore.checkedEHR ? DataStore.checkedEHR.length : 0

			let EHRContent = undefined
			switch (UIStore.EHRfilterValue) {
				case "anamnesis":
					EHRContent = (
						<Overview
							patientOverviewData={patientAnamnesisDataWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "laboratories":
					EHRContent = (
						<Overview
							patientOverviewData={patientLaboratoryData}
							actionButton={patientLabsAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "requests":
					EHRContent = (
						<Overview
							patientOverviewData={patientRequestDataWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "operation_protocol":
					EHRContent = (
						<Overview
							patientOverviewData={patientOperationProtocolDataWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "clinicalvalues":
					EHRContent = (
						<Overview
							patientOverviewData={patientClinicalValues}
							actionButton={patientClinicalValues.length > 0 ? patientClinicalValuesAction : null}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "overview":
					EHRContent = (
						<Overview
							patientOverviewData={patientOverviewData}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "hospitalization":
					EHRContent = (
						<Overview
							patientOverviewData={patientHospitalizationData}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "nczivalues":
					EHRContent = (
						<Overview
							patientOverviewData={patientNZISExternalDocumentsWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "medicationAMB":
					EHRContent = (
						<Overview
							patientOverviewData={patientMedicationDataWithActionAMB}
							actionButton={checkedPrescriptions.length > 0 ? patientPrescriptionActionAMB : null}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "printTemplates":
					EHRContent = (
						<Overview
							patientOverviewData={patientPrintTemplatesDataWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				case "scoring":
					EHRContent = (
						<Overview
							patientOverviewData={printScoringDataWithAction}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
					break
				default:
					EHRContent = (
						<Overview
							patientOverviewData={patientOverviewData}
							reloadOverview={this.reloadOverview.bind(this)}
							actions={this.props.actions}
						/>
					)
			}

			return (
				<div className="xs-overview-container">
					{UIStore.isFormSaving && <XsLoading overlay={true} />}
					{patientClinicalValues && patientClinicalValues.length > 0 && (
						<Dialog
							open={this.state.cvChartDialogOpen}
							onClose={this.toggleCVChartDialog}
							onEscapeKeyDown={this.toggleCVChartDialog}
							maxWidth={false}
							classes={{paper: this.props.classes.cvchartDialog}}
							className="xs-base-dialog"
						>
							<DialogTitle className="xs-header">
								<FormattedMessage id="Patient.form.patientclinicalvalues.title" />
							</DialogTitle>
							<DialogContent className="xs-content">
								<div className="clinicalValuesChart">
									<PatientClinicalValuesChart data={patientClinicalValues} />
								</div>
							</DialogContent>
							<DialogActions className="xs-footer">
								<XsButton
									className="xs-default xs-outline"
									icon={<CancelIcon />}
									text={<FormattedMessage id="Patient.form.patientrecord.close" />}
									onClick={this.toggleCVChartDialog}
								/>
							</DialogActions>
						</Dialog>
					)}
					<XsTabsIcon
						// defaultValue="overview"
						value={UIStore.overviewTab}
						onChangeTab={(value) => {
							UIStore.overviewTab = value
							UIStore.EHRfilterValue = value
						}}
						className="overviewTabs"
						mainClass="xs-ehr-overivew-tab"
						background="white"
						// scrollButtons={true}
						tabs={[
							{
								value: "overview",
								labelClassName: "button-overview",
								warning: this.existsWrittingMessage(patientOverviewData),
								icon: (
									<i
										data-tip
										data-for="conceptRecord"
										className={`${UIStore.overviewTab === "overview" ? "fas" : "fal"} fa-briefcase-medical fa-2x`}
									/>
								),
								label: (
									<div>
										<FormattedMessage id="PatientDetail.cards.overview" />
									</div>
								),
								count: this.getDataCount(patientOverviewData),
								content: EHRContent
							},
							{
								value: "checked",
								icon:
									UIStore.overviewTab === "checked" ? (
										<i className="fas fa-check-circle fa-2x" />
									) : (
										<i className="fal fa-check-circle fa-2x" />
									),
								label: (
									<div>
										<FormattedMessage id="PatientDetail.cards.checked" />
									</div>
								),
								labelClassName: "button-checked",
								count: checkedCount,
								hidden: checkedCount == 0,
								content: (
									<Overview
										patientOverviewData={patientEHRCheckedData}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "medication",
								warning: this.existsWrittingMessage(patientMedicationData),
								labelClassName: "button-medication",
								icon: (
									<i
										data-tip
										data-for="conceptRecord"
										className={`${UIStore.overviewTab === "medication" ? "fas" : "fal"} fa-pills fa-2x`}
									/>
								),
								label: (
									<div>
										<FormattedMessage id="Common.label.drugs" />
									</div>
								),
								count: this.getDataCount(patientMedicationData),
								content: (
									<Overview
										patientOverviewData={patientMedicationDataWithAction}
										actionButton={checkedPrescriptions.length > 0 ? patientPrescriptionAction : null}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "ambulance",
								labelClassName: "button-ambulance",
								warning: this.existsWrittingMessage(patientAmbulanceData),
								icon: (
									<i
										data-tip
										data-for="conceptRecord"
										className={`${UIStore.overviewTab === "ambulance" ? "fas" : "fal"} fa-notes-medical fa-2x`}
									/>
								),
								label: (
									<div>
										<FormattedMessage id="PatientDetail.cards.ambulance" />
									</div>
								),
								count: this.getDataCount(patientAmbulanceData),
								content: (
									<Overview
										patientOverviewData={patientAmbulanceDataWithAction}
										actionButton={checkedAmbulances.length > 0 ? patientAmbulanceAction : null}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "requests",
								labelClassName: "button-requests",
								icon:
									UIStore.overviewTab === "requests" ? (
										<i className="fas fa-file-contract fa-2x" />
									) : (
										<i className="fal fa-file-contract fa-2x" />
									),
								label: (
									<div>
										<FormattedMessage id="Common.label.requests" />
									</div>
								),
								count: this.getDataCount(patientRequestData),
								content: (
									<Overview
										patientOverviewData={patientRequestDataWithAction}
										actionButton={checkedRequests.length > 0 ? patientRequestAction : null}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "clinicalvalues",
								labelClassName: "button-clinicalvalues",
								icon:
									UIStore.overviewTab === "clinicalvalues" ? (
										<i className="fas fa-chart-line fa-2x" />
									) : (
										<i className="fal fa-chart-line fa-2x" />
									),
								label: (
									<div>
										<FormattedMessage id="Patient.form.patientrecord.clinicalvalues" />
									</div>
								),
								count: this.getDataCount(patientClinicalValues),
								content: (
									<Overview
										patientOverviewData={patientClinicalValues}
										actionButton={patientClinicalValues.length > 0 ? patientClinicalValuesAction : null}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "noDeliveries",
								labelClassName: "button-noDeliveries",
								danger: isSafe(patientNoDeliveriesData) && patientNoDeliveriesData.length > 0 ? true : false,
								icon:
									UIStore.overviewTab === "noDeliveries" ? (
										<i className="fas fa-funnel-dollar fa-2x" />
									) : (
										<i className="fal fa-funnel-dollar fa-2x" />
									),
								label: (
									<div>
										<FormattedMessage id="PatientDetail.cards.noDeliveries" />
									</div>
								),
								count: this.getDataCount(patientNoDeliveriesData),
								content: (
									<Overview
										patientOverviewData={patientNoDeliveriesData}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "sicknessabs",
								labelClassName: "button-sicknessabs",
								icon:
									UIStore.overviewTab === "sicknessabs" ? (
										<i className="fas fa-id-badge fa-2x" />
									) : (
										<i className="fal fa-id-badge fa-2x" />
									),
								label: (
									<div>
										<FormattedMessage id="Common.label.patientSicknessAbs" />
									</div>
								),
								hidden:
									isNotSafe(patientSicknessAbsData) ||
									(isNotEmpty(patientSicknessAbsData) && patientSicknessAbsData.length === 0),
								count: this.getDataCount(patientSicknessAbsData),
								content: (
									<Overview
										patientOverviewData={patientSicknessAbsWithAction}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							},
							{
								value: "laboratories",
								labelClassName: "button-laboratories",
								icon:
									UIStore.overviewTab === "laboratories" ? (
										<i className="fas fa-flask fa-2x"></i>
									) : (
										<i className="fal fa-flask fa-2x"></i>
									),
								label: (
									<div>
										<FormattedMessage id="Common.label.labs" />
									</div>
								),
								count: this.getDataCount(patientLaboratoryData),
								content: (
									<Overview
										patientOverviewData={patientLaboratoryData}
										reloadOverview={this.reloadOverview.bind(this)}
										actions={this.props.actions}
									/>
								)
							}
							// {
							//   value: "nczivalues",
							//   icon: UIStore.overviewTab === "nczivalues" ? <i className="fas fa-file-import fa-2x"></i> : <i className="fal fa-file-import fa-2x"></i>,
							//   label: <div><FormattedMessage id="Patient.form.patientrecord.nzisexternal" /></div>,
							//   count: this.getDataCount(patientNZISExternalDocuments),
							//   content: <Overview patientOverviewData={patientNZISExternalDocumentsWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "anamnesis",
							//   icon: UIStore.overviewTab === "anamnesis" ? <i className="fas fa-stethoscope fa-2x"></i> : <i className="fal fa-stethoscope fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.anamnesis" /></div>,
							//   count: this.getDataCount(patientAnamnesisData),
							//   content: <Overview patientOverviewData={patientAnamnesisDataWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "lab",
							//   icon: UIStore.overviewTab === "lab" ? <i className="fas fa-flask fa-2x"></i> : <i className="fal fa-flask fa-2x"></i>,
							//   label: <div><FormattedMessage id="Common.label.labs" /></div>,
							//   count: this.getDataCount(patientLaboratoryData),
							//   content: <Overview patientOverviewData={patientLaboratoryData} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "request",
							//   icon: UIStore.overviewTab === "request" ? <i className="fas fa-file-contract fa-2x"></i> : <i className="fal fa-file-contract fa-2x"></i>,
							//   label: <div><FormattedMessage id="Common.label.requests" /></div>,
							//   count: this.getDataCount(patientRequestData),
							//   content: <Overview patientOverviewData={patientRequestDataWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "sicknessabs",
							//   icon: UIStore.overviewTab === "sicknessabs" ? <i className="fas fa-id-badge fa-2x"></i> : <i className="fal fa-id-badge fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.patientsicknessabs" /></div>,
							//   count: this.getDataCount(patientSicknessAbsData),
							//   content: <Overview patientOverviewData={patientSicknessAbsWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "operation_protocol",
							//   icon: UIStore.overviewTab === "operation_protocol" ? <i className="fas fa-file-medical-alt fa-2x"></i> : <i className="fal fa-file-medical-alt fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.opProtocol" /></div>,
							//   count: this.getDataCount(patientOperationProtocolData),
							//   content: <Overview patientOverviewData={patientOperationProtocolDataWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "clinicalvalues",
							//   icon: UIStore.overviewTab === "clinicalvalues" ? <i className="fas fa-chart-line fa-2x"></i> : <i className="fal fa-chart-line fa-2x"></i>,
							//   label: <div><FormattedMessage id="Patient.form.patientrecord.clinicalvalues" /></div>,
							//   count: this.getDataCount(patientClinicalValues),
							//   content: <Overview patientOverviewData={patientClinicalValues} actionButton={patientClinicalValues.length > 0 ? patientClinicalValuesAction : null} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "hospitalization",
							//   icon: UIStore.overviewTab === "hospitalization" ? <i className="fas fa-hospital fa-2x"></i> : <i className="fal fa-hospital fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.hospitalization" /></div>,
							//   count: this.getDataCount(patientHospitalizationData),
							//   content: <Overview patientOverviewData={patientHospitalizationData} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "nczivalues",
							//   icon: UIStore.overviewTab === "nczivalues" ? <i className="fas fa-file-import fa-2x"></i> : <i className="fal fa-file-import fa-2x"></i>,
							//   label: <div><FormattedMessage id="Patient.form.patientrecord.nzisexternal" /></div>,
							//   count: this.getDataCount(patientNZISExternalDocuments),
							//   content: <Overview patientOverviewData={patientNZISExternalDocumentsWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "medicationAMB",
							//   icon: UIStore.overviewTab === "medicationAMB" ? <i className="fas fa-syringe fa-2x"></i> : <i className="fal fa-syringe fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.medication" /></div>,
							//   count: this.getDataCount(patientMedicationDataAMB),
							//   content: <Overview patientOverviewData={patientMedicationDataWithActionAMB} actionButton={checkedPrescriptions.length > 0 ? patientPrescriptionActionAMB : null} reloadOverview={this.reloadOverview.bind(this)} />
							// },
							// {
							//   value: "printTemplates",
							//   icon: UIStore.overviewTab === "printTemplates" ? <i className="fas fa-file-alt fa-2x"></i> : <i className="fal fa-file-alt fa-2x"></i>,
							//   label: <div><FormattedMessage id="PatientDetail.cards.patientTemplates" /></div>,
							//   count: this.getDataCount(printTemplatesData),
							//   content: <Overview patientOverviewData={patientPrintTemplatesDataWithAction} reloadOverview={this.reloadOverview.bind(this)} />
							// },
						]}
					/>
					<ReactTooltip id="conceptRecord">
						<FormattedMessage id="Common.label.conceptRecord" />
					</ReactTooltip>
					<XsConfirmationDialog
						type="danger"
						name="xsCancelPrescription"
						text={<FormattedMessage id="Patient.form.patientPrescription.cancelPrescriptionText" />}
						headerConfig={{
							text: "Common.label.warning",
							icon: <i className="fal fa-trash-alt fa-2x" />
						}}
						cancelBtn={{
							icon: <i className="fal fa-times fa-lg" />,
							text: "Common.btn.not.storno"
						}}
						confirmBtn={{
							icon: <i className="fal fa-trash-alt fa-lg" />,
							text: "Common.label.storno"
						}}
						onConfirmation={() => PatientPrescriptionStore.cancel(GlobalStore.confirmationDialogParams.prescriptionId)}
					/>
					<XsConfirmationDialog
						type="danger"
						name="deletePrintTemplateConfirmation"
						text={<FormattedMessage id="Patient.Overview.PrintTemplates.CancelPrompt" />}
						headerConfig={{
							text: "Common.label.warning",
							icon: <i className="fal fa-trash-alt fa-2x" />
						}}
						cancelBtn={{
							icon: <i className="fal fa-times fa-lg" />,
							text: "Common.btn.not.storno"
						}}
						confirmBtn={{
							icon: <i className="fal fa-trash-alt fa-lg" />,
							text: "Common.label.storno"
						}}
						onConfirmation={() => PrintTemplatesStore.storno(GlobalStore.confirmationDialogParams.id)}
					/>

					<StornoPrescriptionDialog />
					<ConfirmStornoPrescriptionDialog />
					<OverPrescriptionDialog />
					<StornoRecordDialog />
					<StornoRequestDialog />
					<StornoOpProtocolDialog />
					<StornoDialog />
					<MultiStornoDialog />
					<MoveRecordDialog reloadPatientOverview={this.reloadOverview.bind(this)} />
					<MedicationDistDialog />
					<SearchExchangeTicketsDialog />
					<VaccAllergyDialog />
					<LabResultDialog />
					<XsConfirmationDialog
						name={"patientRecordReexamination"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							UIStore.patientCardTab = "record"
							PatientRecordStore.recordLoaded = false
							DataStore.setFormDataState("PatientRecordForm", formDataStateCL.EMPTY)
							PatientRecordStore.loadRecordData(
								GlobalStore.confirmationDialogParams.recordId,
								GlobalStore.confirmationDialogParams.scoring
							)
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<XsConfirmationDialog
						name={"patientPrescriptionReprescribe"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							if (UIStore.patientCardTab === "prescription") {
								GlobalStore.handleClickFocus("patientPrescriptionForm")
							}
							UIStore.patientCardTab = "prescription"
							DataStore.setFormDataState("PatientPrescriptionForm", formDataStateCL.EMPTY)
							PatientPrescriptionContainerStore.represcribe([
								...DataStore.checkedEHR.filter((obj) => obj.type_code == "RX"),
								GlobalStore.confirmationDialogParams.medicationId
							])
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<XsConfirmationDialog
						name={"patientPrescriptionReprescribe"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							if (UIStore.patientCardTab === "prescription") {
								GlobalStore.handleClickFocus("patientPrescriptionForm")
							}
							UIStore.patientCardTab = "prescription"
							DataStore.setFormDataState("PatientPrescriptionForm", formDataStateCL.EMPTY)
							PatientPrescriptionContainerStore.represcribe([
								...DataStore.checkedEHR.filter((obj) => obj.type_code == "RX"),
								GlobalStore.confirmationDialogParams.medicationId
							])
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<XsConfirmationDialog
						name={"patientRequestReprescribe"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							if (UIStore.patientCardTab === "request") {
								GlobalStore.handleClickFocus("patientRequestForm")
							}
							UIStore.patientCardTab = "request"
							DataStore.setFormDataState("PatientRequestForm", formDataStateCL.EMPTY)
							PatientRequestStore.represcribe([
								...DataStore.checkedEHR.filter((obj) => obj.type_code == "OV" || obj.type_code == "ZV"),
								GlobalStore.confirmationDialogParams.requestId
							])
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<XsConfirmationDialog
						name={"patientReprescribeMedication"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							let med = GlobalStore.confirmationDialogParams.medicationObj
							if (UIStore.patientCardTab === "medication") {
								GlobalStore.handleClickFocus("patientMedicationForm")
							}
							UIStore.patientCardTab = "medication"

							PatientMedicationStore.represcribe([...DataStore.checkedEHR.filter((obj) => obj.type_code == "POD"), med])

							if (isSafe(med.medication) && med.medication.medication_type == "ADMVACC" && med.symptoms.length > 0) {
								const actualSymptoms = med.symptoms.map((x) => x.type.name_ext)

								setTimeout(() => {
									WarningStore.open(
										`${this.props.intl.formatMessage({
											id: "Common.label.medicationSymptomsWarning"
										})}: ${actualSymptoms.join(", ")}`
									)
								}, 2000)
							}
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<XsConfirmationDialog
						name={"patientRequestReprescribe"}
						// title={<FormattedMessage
						//   id="Patient.form.savedrecord.title"
						//   values={{datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}))}}
						// />}
						type="info"
						text={<FormattedMessage id="Patient.form.savedrecord.msg" />}
						onConfirmation={() => {
							if (UIStore.patientCardTab === "request") {
								GlobalStore.handleClickFocus("patientRequestForm")
							}
							UIStore.patientCardTab = "request"
							DataStore.setFormDataState("PatientRequestForm", formDataStateCL.EMPTY)
							PatientRequestStore.represcribe([
								...DataStore.checkedEHR.filter((obj) => obj.type_code == "OV" || obj.type_code == "ZV"),
								GlobalStore.confirmationDialogParams.requestId
							])
						}}
						onCancel={() => {}}
						// headerConfig={{
						// 	text: "Patient.form.savedrecord.title",
						// 	values: {
						// 		datetime: moment(DataStore.savedFormObject.created_at, config.DB_DATETIME_FORMAT).format(
						// 			this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
						// 		)
						// 	},
						// 	icon: <i className="fal fa-file-download fa-2x"></i>
						// }}
						// cancelBtn={{
						// 	icon: <i className="fal fa-times fa-lg"></i>,
						// 	text: "Common.btn.not.load"
						// }}
						// confirmBtn={{
						// 	icon: <i className="fal fa-file-download fa-lg"></i>,
						// 	text: "Common.btn.patientRecord.loadRecord"
						// }}
					/>
					<PatientRecordDropzoneModal
						reloadOverview={() => {
							this.reloadOverview.bind(this)
						}}
					/>
				</div>
			)
		}
		return <XsLoading />
	}
}

export default injectIntl(withStyles(styles)(OverviewContainer))
