import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import {getUserPersonnelID, getSelectedOrgUnitID} from "../../../global/helpers/actions"

import settings from "../../../global/config/settings"

class PatientsWithPPStore {
	@observable isOpen = false

	totalCount = 0
	@observable infoMessage = "Table.NoData"
	@observable patientWithData = []

	refreshInterval = null

	page = 0
	rowsPerPage = 10

	formRef = undefined

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.last_date_from, formValues.last_date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.last_date_to, formValues.last_date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.insurer != formValues.insurer ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.orgunit != formValues.orgunit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action prepareReport(state, filters, form) {
		api
			.prepareReport("SKPC1", {
				sync: false,
				provider_id: getSelectedOrgUnitID(),
				date: moment().format("YYYY-MM-DD"),
				accepted_stamp: moment().add(-4, "hours"),
				generate: true,
				force: false
			})
			.call(null, null, null, 300000)
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					if (res.status == "R") {
						api
							.listReport(res._id, {
								row_offset: state.page == 0 ? 1 : state.page * state.rowsPerPage + 1,
								row_count_show: state.rowsPerPage,
								filters: filters
							})
							.call()
							.then((list) => {
								this.isChangedFilter(form.values())
								this.infoMessage = "Table.NoData"

								this.totalCount = list.row_count_full
								this.patientWithData = list.rows

								this.page = state.page
								this.rowsPerPage = state.rowsPerPage

								if (isSafe(this.refreshInterval)) {
									clearInterval(this.refreshInterval)
								}
							})
					} else {
						this.isChangedFilter(form.values())
						if (res.status == "C" || res.status == "N") {
							this.infoMessage = "Common.label.processingReport"
						} else {
							this.infoMessage = "Common.label.processingReportError"
						}

						this.patientWithData = []
						this.totalCount = 0
					}
				}
			})
	}

	@action getFilters(form, state) {
		this.currFilters = form.values()
		this.formRef = form

		let filters = [
			{
				associated_column: "personnel_id",
				values: [
					{
						id_value: getUserPersonnelID()
					}
				]
			}
		]

		if (isNotEmpty(form.$("orgunit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("orgunit").value}]})
		}

		if (isNotEmpty(form.$("insurer").value)) {
			filters.push({associated_column: "insurer", values: [{id_value: form.$("insurer").value}]})
		}

		if (isNotEmpty(form.$("last_date_from").value)) {
			let lastDateFrom = moment(form.$("last_date_from").value)
				.endOf("day")
				.format(settings.DB_DATE_FORMAT)
			filters.push({associated_column: "last_date_from", values: [{id_value: lastDateFrom}]})
		}

		if (isNotEmpty(form.$("last_date_to").value)) {
			let lastDateTo = moment(form.$("last_date_to").value)
				.endOf("day")
				.format(settings.DB_DATE_FORMAT)
			filters.push({associated_column: "last_date_to", values: [{id_value: lastDateTo}]})
		}

		if (isNotEmpty(form.$("identifier").value)) {
			filters.push({associated_column: "identifier", values: [{id_value: form.$("identifier").value}]})
		}

		this.refreshInterval = setInterval(() => {
			this.prepareReport(state, filters, form)
		}, 30000)

		this.prepareReport(state, filters, form)
	}

	@action exportToXls(form) {
		let filters = []

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()

			if (isNotEmpty(refFormValues.orgunit)) {
				filters.push({
					associated_column: "provider_id",
					values: [{id_value: refFormValues.orgunit}]
				})
			}

			if (isNotEmpty(refFormValues.insurer)) {
				filters.push({
					associated_column: "insurer",
					values: [{id_value: refFormValues.insurer}]
				})
			}

			if (isNotEmpty(refFormValues.identifier)) {
				filters.push({
					associated_column: "identifier",
					values: [{id_value: refFormValues.identifier}]
				})
			}

			if (isNotEmpty(refFormValues.last_date_from)) {
				let lastDateFrom = moment(refFormValues.last_date_from)
					.endOf("day")
					.format(settings.DB_DATE_FORMAT)
				filters.push({associated_column: "last_date_from", values: [{id_value: lastDateFrom}]})
			}

			if (isNotEmpty(refFormValues.last_date_to)) {
				let lastDateTo = moment(refFormValues.last_date_to)
					.endOf("day")
					.format(settings.DB_DATE_FORMAT)
				filters.push({associated_column: "last_date_to", values: [{id_value: lastDateTo}]})
			}
		}

		let req = {
			filters: filters,
			uri: `/amb/report/generate/SKPC1`
		}

		if (form.values().exportType == "now") {
			req.row_count_show = this.rowsPerPage
			req.row_count_full = this.rowsPerPage
			req.row_offset = this.page * this.rowsPerPage + 1
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			req.row_count_full = 100000
		}

		api
			.exportData(req)
			.call()
			.then((response) => {
				this.close()
				form.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})

		this.close()
	}
}
var singleton = new PatientsWithPPStore()
export default singleton
