import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, Dialog, DialogContent, FormControlLabel, Checkbox, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import ExportBatchDialogStore from "../../stores/ExportBatchDialogStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class ExportBatchDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsExportBatchDialog"
				open={ExportBatchDialogStore.isOpen}
				onClose={() => ExportBatchDialogStore.close()}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => ExportBatchDialogStore.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-file-excel fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.export515" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column" className="xs-form">
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={ExportBatchDialogStore.onlyCapitations}
										onChange={(e) => (ExportBatchDialogStore.onlyCapitations = e.target.checked)}
									/>
								}
								label={<FormattedMessage id="Common.label.onlyCapitation" />}
							/>
						</Grid>
						<Grid item xs={12}>
							<SearchSelect
								items={RegistersCacheStore.insurersRegister.map((insurer) => {
									return {label: insurer.name_ext, value: insurer._id}
								})}
								label={<FormattedMessage id="Common.label.insurer" />}
								onChange={(obj) => (ExportBatchDialogStore.insurer = obj)}
								value={ExportBatchDialogStore.insurer}
								// hideLabel={true}
								// required
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => ExportBatchDialogStore.close()}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-file-excel" />}
						text={<FormattedMessage id="Common.label.exportBatch" />}
						onClick={() => ExportBatchDialogStore.ExportBatchToXls()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
