//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
// import moment from "moment"
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import DuplicityPersonStore from "../../../stores/DuplicityPersonModalStore"
import WarningStore from "../../../../../global/store/WarningStore"
import {injectIntl} from "react-intl"
import {getUserCompanyInfo} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class DuplicityPersonModal extends React.Component {
	constructor(props) {
		super(props)
	}
	insertDataToForm = (patient) => {
		let company = getUserCompanyInfo()
		let empData = patient.entity.specific_rels.find(
			(rel) => rel.active && rel._type === "Entity.Data.Employee" && rel.company._id == company._id
		)
		if (isSafe(empData)) {
			DuplicityPersonStore.close()
			WarningStore.open(`${this.props.intl.formatMessage({id: "EmployeeDetail.employeenew.alreadyExists"})}`)
			DuplicityPersonStore.form.$("identifier").value = ""
		} else {
			DuplicityPersonStore.form
				.$("title_before")
				.set(isSafe(patient.entity.title_before) ? patient.entity.title_before._id : "")
			DuplicityPersonStore.form
				.$("title_after")
				.set(isSafe(patient.entity.title_after) ? patient.entity.title_after._id : "")
			DuplicityPersonStore.form.$("firstname").set(patient.entity.first_name)
			DuplicityPersonStore.form.$("lastname").set(patient.entity.last_name)
			DuplicityPersonStore.form.$("birthname").set(patient.entity.birth_name)
			DuplicityPersonStore.form.$("dateOfBirth").set(patient.entity.birth_date)
			DuplicityPersonStore.form.$("gender").set(isSafe(patient.entity.gender) ? patient.entity.gender._id : "")
		}
	}

	render() {
		return (
			<Dialog
				id="xsDuplicityPersonDialog"
				open={DuplicityPersonStore.isOpen}
				onClose={() => DuplicityPersonStore.close()}
				onEscapeKeyDown={() => DuplicityPersonStore.close()}
				disableBackdropClick={true}
				scroll="body"
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.modal.duplicity.header" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isSafe(DuplicityPersonStore.patientData) &&
						DuplicityPersonStore.patientData.length > 0 &&
						DuplicityPersonStore.patientData.map((patient, idx) => {
							let genderClassName =
								isSafe(patient.entity.gender) && patient.entity.gender._id === "M" ? "xs-woman" : "xs-woman"
							return (
								<div
									key={idx}
									className="xs-patient-item"
									onClick={() => (
										DuplicityPersonStore.close(),
										this.insertDataToForm(patient) /*, RouterStore.push(`/patient?id=${patient.entity._id}`)*/
									)} /*(putDataToFormByScheme(DuplicityPersonStore.form, patientPutBinding, patient.entity), DataStore.patientIdReloaded = patient.entity._id, DuplicityPersonStore.close())}*/
								>
									<div className="xs-patientInfo">
										<div className="xs-avatar"></div>
										<div className="xs-personalInfo">
											<div className="xs-fullName">{patient.entity.full_name}</div>
											<div className="xs-gender">
												{isSafe(patient.entity.gender) && isSafe(patient.entity.gender.name_ext)
													? patient.entity.gender.name_ext
													: ""}
												, {patient.entity.age} rokov
											</div>
										</div>
									</div>
									<div className={`${genderClassName} xs-insurerId`}>
										<div className="xs-label">
											<FormattedMessage id="Patient.labels.insurerID" defaultMessage="Insurer ID" />
										</div>
										<div className="xs-identifier">{patient.entity.identifier}</div>
									</div>
								</div>
							)
						})}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times"></i>}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => DuplicityPersonStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
