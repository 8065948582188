"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"

import UIStore from "../stores/UIStore"

import api from "../actions/api"

class SmsNotificationStore {
	@observable isOpen = false
	@observable isOpenHealthCard = false
	@observable htmlContent = undefined

	patientId = undefined

	smsInput = ""

	@action open(patientId) {
		this.patientId = patientId
		this.isOpen = true
	}

	@action openHealthCard(html) {
		this.htmlContent = html
		this.isOpenHealthCard = true
	}

	@action closeHealthCard() {
		this.isOpenHealthCard = false
		this.htmlContent = undefined
	}

	@action handleInputChange(value) {
		this.smsInput = value
	}

	@action getHealthCard() {
		const request = {
			patient_id: this.patientId,
			provider_id: getSelectedOrgUnitID(),
			sms_kod: this.smsInput
		}

		UIStore.isFormSaving = true
		api
			.dajzdravotnukartu(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.html)) {
					UIStore.isFormSaving = false
					UIStore.patientDetailTab = "InsuranceServices"
					this.openHealthCard(response.html)
					this.close()
				} else {
					this.htmlContent = undefined
					UIStore.isFormSaving = false
				}
			})
			.catch(() => {
				this.htmlContent = undefined
				UIStore.isFormSaving = false
			})
	}

	@action close() {
		this.isOpen = false
		this.patientId = undefined
		this.smsInput = ""
	}
}

var singleton = new SmsNotificationStore()
export default singleton
