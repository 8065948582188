"use strict"

import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import classNames from "classnames"

import Joyride, {STATUS} from "react-joyride"
import {Grid} from "@material-ui/core"
import TourStore from "../../../../global/store/TourStore"

@observer
export default class CalendarTour extends React.Component {
	constructor(props) {
		super(props)
	}

	handleJoyrideCallback = (data) => {
		const {status} = data

		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

		if (finishedStatuses.includes(status)) {
			TourStore.runCalendar_tour = false
			TourStore.stepCalendarIndex = 0
		}

		if (data.action == "close") {
			TourStore.runCalendar_tour = false
			TourStore.stepCalendarIndex = 0
		}
	}

	getHelpers = (helpers) => {
		this.helpers = helpers
	}

	footerTour = () => (
		<Grid item container spacing={8} justify="center" alignItems="center" className="mt-3">
			<Grid item onClick={() => TourStore.handlePreviousCalendarTour()} className={classNames("mr-1 xs-pointer")}>
				<i className={classNames("far fa-chevron-left fa-lg greyDark mr-1")}></i>
			</Grid>
			{[0, 1, 2, 3, 4, 5, 6, 7].map((i, idx) => (
				<Grid item key={idx}>
					{TourStore.stepCalendarIndex == i ? (
						<i
							className="fas fa-circle xs-pointer greyDark fontSize12"
							onClick={() => TourStore.handleCircleCalendarTour(i)}
						></i>
					) : (
						<i
							className={classNames("far fa-circle xs-pointer greyDark fontSize12")}
							onClick={() => TourStore.handleCircleCalendarTour(i)}
						></i>
					)}
				</Grid>
			))}
			<Grid item onClick={() => TourStore.handleNextCalendarTour()} className={classNames("ml-1 xs-pointer")}>
				<i className={classNames("far fa-chevron-right fa-lg greyDark")}></i>
			</Grid>
		</Grid>
	)

	render() {
		let steps = [
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Ak chcete zobraziť v kalendári denný prehľad objednaných pacientov, kliknite na toto tlačidlo.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideFooter: true,
				placement: "bottom",
				// spotlightClicks: true,
				spotlightPadding: 5,
				target: ".today"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Ak chcete zobraziť v kalendári týždenný prehľad objednaných pacientov, kliknite na toto tlačidlo.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".week"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Ak chcete zobraziť v kalendári týždenný prehľad vrátane víkendu, kliknite na toto tlačidlo.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".weekend"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Ak chcete zobraziť v kalendári mesačný prehľad objednaných pacientov, kliknite na toto tlačidlo.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				// spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".month"
			},
			{
				content: (
					<Grid container direction="column" spacing={16}>
						<Grid item className="mb-3">
							Ak chcete pacientovi vytvoriť objednávku do kalendára, kliknite na toto tlačidlo.
						</Grid>
						<Grid item container justify="center">
							<Grid item>V prípade, že je v kalendári voľné miesto, môžete pridať udalosť aj týmto tlačidlom.</Grid>
							<Grid item>
								<img src="/public/images/freeDate.png" />
							</Grid>
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				// spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".tourAddEvent",
				styles: {
					options: {
						width: 800
					}
				}
			},
			{
				content: (
					<Grid container direction="column" spacing={16} alignItems="flex-start">
						<Grid item container alignItems="center">
							<Grid item className="mr-2">
								<img src="/public/images/previousDate.png" />
							</Grid>
							<Grid item>Pre zobrazenie predchádzajúcich dní v kalendári použite túto šípku.</Grid>
						</Grid>
						<Grid item container direction="column" alignItems="center">
							<Grid item className="mr-2">
								<img src="/public/images/actualDate.png" className="mr-2" />
							</Grid>
							<Grid item>
								Pre výber iného dňa v kalendári kliknite tu a vyberte konkrétny deň. Číselný údaj zobrazuje počet
								objednaných pacientov.
							</Grid>
						</Grid>
						<Grid item container alignItems="center">
							<Grid item className="mr-2">
								<img src="/public/images/nextDate.png" className="mr-2" />
							</Grid>
							<Grid item>Pre zobrazenie nasledujúcich dní v kalendári použite túto šípku.</Grid>
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				// spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".xs-select-day",
				styles: {
					options: {
						width: 750
					}
				}
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>Zobrazenie uloženej udalosti v dennom prehľade kalendára.</Grid>
						<Grid item>
							<img src="/public/images/event.png" />
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				// spotlightClicks: true,
				spotlightPadding: 5,
				placement: "center",
				hideFooter: true,
				target: "body",
				styles: {
					options: {
						width: 900
					}
				}
			},
			{
				content: (
					<Grid container direction="column" spacing={16}>
						<Grid item>
							<i className="fas fa-user fa-lg greyDefault mr-1" />
							Ak pacientovi v karte nebol v daný deň uložený žiadny záznam, pri objednávke v kalendári je zobrazený sivý
							panáčik. Táto farba sa zobrazuje, aj keď udalosť aktuálne prebieha v čase.
						</Grid>
						<Grid item>
							<i className="fas fa-user-cog fa-lg amberWarning mr-1" />
							Ak bol pacientovi v karte, v aktuálny deň, recept alebo dekurz uložený ako „uložiť rozpísané“, pri
							objednávke sa zobrazuje panáčik oranžovej farby.
						</Grid>
						<Grid item>
							<i className="fas fa-user-check fa-lg greenSuccess mr-1" />
							Ak bol pacientovi v aktuálny deň recept alebo dekurz uložený finálne alebo ak bola udalosť v kalendári
							ukončená manuálne, kliknutím na tlačidlo „Ukončiť udalosť“, pri objednávke sa zobrazuje panáčik zelenej
							farby.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				// spotlightClicks: true,
				spotlightPadding: 5,
				placement: "center",
				hideFooter: true,
				target: "body",
				styles: {
					options: {
						width: 600
					}
				}
			}
		]

		return (
			<Joyride
				callback={this.handleJoyrideCallback}
				continuous={true}
				getHelpers={this.getHelpers}
				run={TourStore.runCalendar_tour}
				stepIndex={TourStore.stepCalendarIndex}
				hideBackButton={true}
				disableScrolling={true}
				disableOverlayClose={true}
				showProgress={true}
				steps={steps}
				styles={{
					options: {
						zIndex: 10000
					}
				}}
			/>
		)
	}
}
