import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				identifier: {
					//definujeme len staticke props ako su label, placeholder, ktore sa nemenia za behu...
					label: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
					// rules: "required|string|between:9,10",
					rules: "required"
				},
				dateOfBirth: {
					label: <FormattedMessage id="Common.label.dateOfBirth" />,
					type: "date"
				},
				gender: {
					label: <FormattedMessage id="Common.label.gender" />,
					rules: "required|string"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					rules: "required|string"
				},
				insurerCode: {}, // for check insurance call -> hidden field
				title_before: {
					label: <FormattedMessage id="Common.label.titleBefore" />,
					rules: "string"
				},
				firstname: {
					label: <FormattedMessage id="Common.label.firstName" />,
					rules: "required|string"
				},
				lastname: {
					label: <FormattedMessage id="Common.label.lastName" />,
					rules: "required|string"
				},
				title_after: {
					label: <FormattedMessage id="Common.label.titleAfter" />,
					rules: "string"
				},
				street: {
					label: <FormattedMessage id="Common.label.street" />,
					rules: "string"
				},
				houseNumber: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "string"
				},
				city: {
					label: <FormattedMessage id="Common.label.city" />
					// rules: "string",
				},
				cityText: {},
				zip: {
					label: <FormattedMessage id="Common.label.zip" />
					// rules: "string",
				},
				zipText: {},
				country: {
					label: <FormattedMessage id="Patient.form.patientinfo.country" />,
					rules: "required",
					value: "SK"
				},
				region: {
					label: <FormattedMessage id="Common.label.region" />
					// rules: "required|string",
				},
				county: {
					label: <FormattedMessage id="Common.label.county" />
					// rules: "required|string",
					// value: "SK"
				},
				from: {
					value: moment()
						.startOf("month")
						.format("YYYY-MM-DD")
				},
				deathDate: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.datumUmrtia" />,
					type: "date"
				}
			}
		}
	}
}
