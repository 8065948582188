"use strict"

import {observable, action} from "mobx"
import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
// import WarningStore from "../../../global/store/WarningStore"

class SearchExchangeTicketsStore {
	htmlData = ""
	@observable isOpen = false
	@observable errorMessage = undefined
	@observable isFetching = true

	@observable notResponse = false

	@action open(ehealtId) {
		this.isOpen = true
		this.isFetching = true
		this.errorMessage = undefined
		this.notResponse = false

		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/SearchExchangeTickets`,
			body: JSON.stringify({
				ClassName: "VyhladajZaznamyOVysetreniPreZiadatela_v5",
				ExtId: ehealtId,
				Reply: true,
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((response) => {
				if (isSafe(response) && isNotEmpty(response.ErrorMessage)) {
					GlobalStore.checkEHGWError(response.ErrorCode, response.ErrorMessage, response.IsEhealthException)
					this.errorMessage =
						(response.IsEhealthException === true ? "Chyba z NZIS! " : "Chyba z ambee! ") + response.ErrorMessage
				} else {
					if (isSafe(response.HtmlData) && isNotEmpty(response.HtmlData)) {
						this.htmlData = b64DecodeUnicode(response.HtmlData)
					} else {
						this.notResponse = true
					}
				}

				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action close() {
		this.isOpen = false

		this.htmlData = ""
	}
}

var singleton = new SearchExchangeTicketsStore()
export default singleton
