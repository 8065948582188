"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import classNames from "classnames"

import AddIcon from "@material-ui/icons/Add"

import {FormattedMessage, injectIntl} from "react-intl"
import PatientRequestStore from "../../../stores/PatientRequestStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RouterStore from "../../../../../global/store/RouterStore"

import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import PrintIcon from "@material-ui/icons/Print"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import UIStore from "../../../stores/UIStore"
import api from "../../../actions/api"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import DDaction from "../../../actions/patientCard"
// import {formDataStateCL} from "../../../../../global/config/codeLists"

@injectIntl
@observer
export default class PatientRequestSaveButtons extends React.Component {
	constructor(props) {
		super(props)
	}

	requestShortcuts = (e) => {
		if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "request") {
			//ulozit a zavriet request Alt + U
			if (e.altKey && e.keyCode == 85) {
				e.preventDefault()
				this.validateForms(Object.keys(this.props.form), e)
			}

			//ulozit a vytlacit request Alt + P
			if (e.altKey && e.keyCode == 80) {
				e.preventDefault()
				this.obj.validateForms(Object.keys(this.props.form), e, true)
			}

			//pridat novy request Alt + A
			if (e.altKey && e.keyCode == 65) {
				e.preventDefault()
				this.props.addForm()
			}
		}
	}

	componentDidMount() {
		document.body.addEventListener("keydown", this.requestShortcuts)
	}

	componentWillUnmount() {
		document.body.removeEventListener("keydown", this.requestShortcuts)
	}

	validateForms(keys, e, print = false) {
		let index = keys[0]

		this.props.form[index].validate().then(({isValid}) => {
			UIStore.isFormSaving = true
			if (isValid && keys.length > 1) {
				keys.shift()
				this.validateForms(keys, e, print)
			} else if (isValid && keys.length == 1) {
				// VALID
				let reqests = []
				Object.keys(this.props.form).forEach((key) => {
					let obj = this.props.form[key]
					obj.$("print").value = print
					reqests.push(obj)
				})
				// DrugInteractionsDialogStore.checkOverinteraction(recipes)
				const translations = {dateFormat: this.props.intl.formatMessage({id: "Application.moment.dateformat"})}
				PatientRequestStore.saveForm(
					reqests,
					DataStore.patientDTO.get("patient"),
					PatientRecordStore.record ? PatientRecordStore.record : null,
					translations,
					() => {
						this.props.clearAllReqeust()
						this.props.options.insertRequestValsToRecordDeliveries(this.props.form)
						this.props.options.onClose("REQUEST", false, false)
						// DataStore.setFormDataState("PatientRequestForm", formDataStateCL.EMPTY)
					} /*, deleteFromSession*/
				)
				UIStore.overviewTab = "overview" //"request" - presmerovanie na prehlad
			} else {
				// NOTVALID
				UIStore.isFormSaving = false
				// this.props.invalidForm()
				GlobalStore.setNotificationMessage(this.props.intl.formatMessage({id: "Common.warning.message.emptyform"}))

				return false
			}
		})
	}

	checkPrescriptionsVersion = () => {
		let tmp = false
		Object.keys(this.props.form).forEach((name) => {
			if (this.props.form[name].$("version").get("value") == "draft") tmp = true
		})
		return tmp
	}

	stornoForms = () => {
		let deleteAll = true
		Object.keys(this.props.form).forEach((name) => {
			let data = this.props.form[name].get("value")
			if (!(data.version == "final" && isNotEmpty(data.recordId))) {
				deleteAll = false
			}
		})
		if (deleteAll) {
			GlobalStore.openConfirmationDialog("xsDeletePrescriptionsOnCancel")
		}
	}

	saveDrafts = () => {
		UIStore.isFormSaving = true
		let reqests = []
		const translations = {dateFormat: this.props.intl.formatMessage({id: "Application.moment.dateformat"})}
		const patientId = DataStore.patientDTO.get("patientID")
		Object.keys(this.props.form).forEach((key) => {
			let obj = this.props.form[key]
			obj.$("print").value = false
			reqests.push(obj)
		})
		const draftReq = PatientRequestStore.saveForm(
			reqests,
			DataStore.patientDTO.get("patient"),
			PatientRecordStore.record ? PatientRecordStore.record : null,
			translations,
			null,
			null,
			true
		)

		api
			.saveRecord(draftReq, patientId)
			.call()
			.then((draftResponse) => {
				let formKeys = Object.keys(this.props.form)
				for (var i = 0, len = formKeys.length; i < len; i++) {
					let name = formKeys[i]
					this.props.form[name].$("recordId").value = draftResponse[i]._id
				}
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
					(overview) => {
						DataStore.setPatientEHR(overview)
						GlobalStore.setNotificationMessage("Patient.form.patientrequest.draftsavesuccess")
						UIStore.overviewTab = "requests"
						window.scrollTo(0, 0)
						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
						this.props.options.deleteOnlyFromStorage("REQUEST")
						UIStore.isFormSaving = false
					}
				)
			})
	}

	render() {
		return (
			<div className={classNames("xs-patient-form-actions", {"xs-top": this.props.top})}>
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
					type="submit"
					onClick={(e) => {
						this.validateForms(Object.keys(this.props.form), e)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					ref={(btn) => {
						PatientRequestStore.saveAndPrintBtnRef = btn
					}}
					className="xs-success xs-outline"
					text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
					onClick={(e) => {
						this.validateForms(Object.keys(this.props.form), e, true)
					}}
					icon={<PrintIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Common.form.savedraft" />}
					onClick={() => {
						this.saveDrafts()
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					ref={(btn) => {
						PatientRequestStore.addNewBtnRef = btn
					}}
					className="xs-success xs-outline"
					text={<FormattedMessage id="Patient.form.patientRequest.addForm" />}
					onClick={() => {
						this.props.addForm()
					}}
					icon={<AddIcon />}
				/>

				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						// this.props.clearAllReqeust()
						this.props.options.onClose("REQUEST", false, true)
						// UIStore.patientCardTab = ""
					}}
					icon={<CancelIcon />}
				/>
			</div>
		)
	}
}
