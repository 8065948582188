import React, {Component} from "react"
import {observer} from "mobx-react"

import "./xsTableServerFilters.less"
import {Grid, TextField} from "@material-ui/core"
import XsButton from "../../xsButton/xsButton"

import DataStore from "../../../../modules/ambulance/stores/DataStore"

@observer
class XsTableServerFilters extends Component {
	constructor(props) {
		super(props)

		this.filterValues = {}

		this.state = {
			isATCFilter: false,
			isATCButton: false
		}
	}

	tableFilterItems
	tableFiltersJSX
	tableFilters() {
		const {columnDefs} = this.props.config
		const isFindSameATC = isSafe(this.props.config.options) && this.props.config.options.findSameATC

		this.tableFilterItems = []

		Object.keys(columnDefs).map((column) => {
			const columnDef = columnDefs[column]
			const dbColumnName = columnDef.dbName

			if (isSafe(columnDef.filter) && isSafe(columnDef.filter.renderElement)) {
				// TODO: optimalizacia: tu to uz nebude treba vsetko kontrolovat,
				// bude stacit to vytiahnut z this.props.defaultFilter
				const defaultValue = isSafe(this.props.defaultFilter[dbColumnName])
					? this.props.defaultFilter[dbColumnName]
					: isSafe(columnDef.filter.defaultValue)
					? columnDef.filter.defaultValue
					: ""

				this.tableFilterItems.push(
					<Grid item xs={columnDef.filter.gridWidth} key={column}>
						{// INPUT
						columnDef.filter.renderElement === "input" && (
							<TextField
								InputLabelProps={{
									shrink: true
								}}
								// defaultValue={this.state.isATCFilter == true ? "" : defaultValue}
								value={this.state.isATCFilter == true ? "" : defaultValue}
								className={`filter-${dbColumnName}`}
								autoComplete="off"
								autoFocus={false}
								label={isSafe(columnDef.filterLabel) ? columnDef.filterLabel : columnDef.title}
								onChange={(e) => {
									if (isFindSameATC) {
										const beforeSet = Object.keys(this.filterValues).every((x) => isEmpty(this.filterValues[x]))
										this.filterValues[dbColumnName] = e.target.value
										const afterSet = Object.keys(this.filterValues).every((x) => isEmpty(this.filterValues[x]))

										const set = {}
										if (!this.state.isATCButton && isNotEmpty(e.target.value)) {
											set.isATCButton = true
										} else if (!beforeSet && afterSet) {
											set.isATCButton = false
										}
										if (this.state.isATCFilter) {
											set.isATCFilter = false
											// DataStore.filterATCValue = null
										}
										this.setState(set)
									}
									this.props.onChange({[dbColumnName]: e.target.value})
								}}
							/>
						)}
					</Grid>
				)
			}
		})

		if (isFindSameATC && this.state.isATCButton) {
			this.tableFilterItems.push(
				<Grid item xs={2} key={14}>
					<XsButton
						text="Lieky s rovnakou ATC"
						className="xs-outline xs-success mt-3"
						onClick={() => {
							this.props.onChange(
								{
									atc: DataStore.filterATCValue
								},
								true
							)
							this.setState({
								isATCButton: false,
								isATCFilter: true
							})
						}}
					/>
				</Grid>
			)
		}
		if (isFindSameATC && this.state.isATCFilter) {
			this.tableFilterItems.push(
				<Grid item xs={2} key={14}>
					<TextField
						InputLabelProps={{
							shrink: true
						}}
						defaultValue={isSafe(DataStore.filterATCValue) ? DataStore.filterATCValue : ""}
						label="ATC"
						disabled={true}
					/>
				</Grid>
			)
		}

		this.tableFiltersJSX = (
			<Grid container direction="row" spacing={8}>
				{this.tableFilterItems}
			</Grid>
		)
	}

	render() {
		this.tableFilters()

		return <div className="xs-table-filters">{this.tableFiltersJSX}</div>
	}
}

export default XsTableServerFilters
