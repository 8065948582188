import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./socInsDeliveryFilterFields"
import classnames from "classnames"

import {Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import {getUserAccessRights, getSelectedOrgUnitID, getUserCompanyInfo} from "../../../../../global/helpers/actions"

import {XsDateTimePickerDirect} from "../../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"

import AddDeliveryStore from "../../../stores/AddDeliveryStore"
import ExportSocInsDeliveryDialogStore from "../../../stores/ExportSocInsDeliveryDialogStore"
import SocInsDeliveryStore from "../../../stores/SocInsDeliveryStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class SocInsDeliveryFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {
				SocInsDeliveryStore.getFilters(true)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		SocInsDeliveryStore.formFilterRef = this.form
		SocInsDeliveryStore.getFilters()
	}

	render() {
		SocInsDeliveryStore.isChangedFilter(this.form.values())

		return (
			<Grid item container alignItems="center">
				<Grid item xs container spacing={8} alignItems="center">
					<Grid item xs={4}>
						<XsPeriodPicker
							white
							ref={(picker) => {
								this.periodPickerRef = picker
							}}
							onChange={(val) => {
								this.form.$("time_from").value = val.from
								this.form.$("time_to").value = val.to
								this.form.$("from").value = moment(val.from, "YYYY.MM.DD")
								this.form.$("to").value = moment(val.to, "YYYY.MM.DD")
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsDateTimePickerDirect
							white
							field={this.form.$("from")}
							minDate={moment(this.form.$("time_from").value).startOf("month")}
							maxDate={moment(this.form.$("time_from").value).endOf("month")}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsDateTimePickerDirect
							white
							field={this.form.$("to")}
							minDate={moment(this.form.$("time_from").value).startOf("month")}
							maxDate={moment(this.form.$("time_from").value).endOf("month")}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsInput white field={this.form.$("identifier")} />
					</Grid>
					<Grid item className="pb-0">
						<XsIconButton
							rect
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							className="xs-default xs-outline"
							onClick={this.form.onSubmit}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
				</Grid>
				<Grid item xs container spacing={8} justify="flex-end">
					<Grid item>
						<XsButton
							disabled={SocInsDeliveryStore.isEditable}
							className={SocInsDeliveryStore.isEditable ? "xs-default xs-outline" : "xs-primary"}
							text={<FormattedMessage id="Common.label.exportToExcel" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
							onClick={() => {
								ExportSocInsDeliveryDialogStore.exportRecordToXls(
									SocInsDeliveryStore.exportFilters.time_from,
									SocInsDeliveryStore.exportFilters.time_to,
									isSafe(SocInsDeliveryStore.exportFilters.identifier)
										? SocInsDeliveryStore.exportFilters.identifier
										: null
								)
							}}
						/>
					</Grid>
					{isSafe(getUserAccessRights("/fin/invoices", "RESTP")) && (
						<Grid item>
							<XsButton
								disabled={SocInsDeliveryStore.isEditable}
								className={SocInsDeliveryStore.isEditable ? "xs-default xs-outline" : "xs-primary"}
								text={<FormattedMessage id="Capitation.form.ExportBatch" />}
								icon={<i className="fal fa-save" />}
								onClick={() => {
									UIStore.previousReportingZSDetailTab = "SocialInsuranceDeliveries"
									ExportSocInsDeliveryDialogStore.open({
										date_from: SocInsDeliveryStore.exportFilters.time_from,
										date_to: SocInsDeliveryStore.exportFilters.time_to,
										provider_id: +getSelectedOrgUnitID(),
										company_id: getUserCompanyInfo().id,
										translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
										translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
											id: "Capitation.form.GetSummaryDeliveriesError"
										}),
										translatedExportNoItems: this.props.intl.formatMessage({id: "Invoice.form.noItems"})
									})
								}}
							/>
						</Grid>
					)}
					{SocInsDeliveryStore.isEditable ? (
						<React.Fragment>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									icon={<i className="far fa-save-alt fa-lg"></i>}
									onClick={() => {
										if (SocInsDeliveryStore.isValidGrid) {
											SocInsDeliveryStore.saveChanges()
											SocInsDeliveryStore.isEditable = false
											SocInsDeliveryStore.changedData = {}
											SocInsDeliveryStore.changedKeysTest = {}
											SocInsDeliveryStore.removeDeliveryIds = []
										} else {
											GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
										}
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										SocInsDeliveryStore.resetGrid()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Grid item>
								<XsButton
									className={classnames("xs-outline", {
										"xs-primary": !SocInsDeliveryStore.changedFilters,
										"xs-default": SocInsDeliveryStore.changedFilters
									})}
									disabled={SocInsDeliveryStore.changedFilters}
									text={<FormattedMessage id="Common.label.edit" />}
									onClick={() => {
										SocInsDeliveryStore.isEditable = true
										SocInsDeliveryStore.idSubItemsRow = ""
									}}
									icon={<i className="far fa-pencil-alt fa-lg"></i>}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className={classnames({
										"xs-success": !SocInsDeliveryStore.changedFilters,
										"xs-default xs-outline": SocInsDeliveryStore.changedFilters
									})}
									disabled={SocInsDeliveryStore.changedFilters}
									onClick={() => AddDeliveryStore.open("socDelivery")}
									text={<FormattedMessage id="Common.label.addDelivery" />}
									icon={<i className="fal fa-plus" />}
								/>
							</Grid>
						</React.Fragment>
					)}
					{/* <Grid item>
						<XsButton
							className="xs-success"
							// className={classnames({
							// 	"xs-success": !OtherDeliveriesStore.changedFilters,
							// 	"xs-default xs-outline": OtherDeliveriesStore.changedFilters
							// })}
							// disabled={OtherDeliveriesStore.changedFilters}
							onClick={() => AddDeliveryStore.open("socDelivery")}
							text={<FormattedMessage id="Common.label.addDelivery" />}
							icon={<i className="fal fa-plus" />}
						/>
					</Grid> */}
				</Grid>
			</Grid>
		)
	}
}
