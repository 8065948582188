"use strict"

import {observable, action} from "mobx"
import DataStore from "./DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"
import {
	getSelectedOrgUnitID,
	printMultiReport,
	getUserPersonnelID,
	getUser,
	getSelectedOrgUnit
} from "../../../global/helpers/actions"
import DDaction from "../actions/patientCard"
import ConfirmInteractionDialogStore from "./ConfirmInteractionDialogStore"
import api from "../actions/api"
import PatientPrescriptionStore from "../stores/PatientPrescriptionStore"
import PatientRecordStore from "./PatientRecordStore"

import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"

class PatientCheckInsurerStore {
	@observable isOpen = false
	htmlContent = ""
	formData = undefined
	recipes = undefined

	@action open(recipes, htmlContent) {
		// this.formData = formData
		this.recipes = recipes
		this.htmlContent = htmlContent
		if (isNotEmpty(htmlContent)) {
			this.isOpen = true
		} else {
			this.close()
		}
	}

	@action cancel() {
		// PatientPrescriptionContainerStore.clearPrescriptions()

		if (isSafe(this.clearRecipes) && typeof this.clearRecipes == "function") {
			this.clearRecipes()
		}
		PatientPrescriptionStore.prohibitedChips = {}
		PatientPrescriptionStore.ingredientsChips = {}
		PatientPrescriptionStore.doctorOUPZSItems = {}
		PatientPrescriptionStore.substitutingDoctorOUPZSItems = {}
		this.isOpen = false
		UIStore.patientCardTab = ""
		UIStore.overviewTab = "medication"
		UIStore.isFormSaving = false
		UIStore.isFormSavingMessage = ""
		//Zkontroluje ci uz bol dnes zapisany dekurz ak nie opyta sa ci chces vygenerovat a vygeneruje
		PatientRecordStore.checkIfPatientHasRecordToday(this.recipes)
		window.scrollTo(0, 0)
		// DataStore.setFormDataState("PatientPrescriptionForm", formDataStateCL.EMPTY)
	}

	@action close() {
		UIStore.isFormSaving = true
		UIStore.isFormSavingMessage = ""
		ConfirmInteractionDialogStore.recordId = null

		api
			.savePrescription(this.recipes)
			.call()
			.then((response) => {
				UIStore.isFormSaving = false
				ConfirmInteractionDialogStore.close()
				let savedIds = ""
				// UIStore.isFormSavingMessage = "Čakám na zdravotnú poisťovňu"
				let xmlB64Data = []
				Object.keys(response).forEach((obj) => {
					let res = response[obj]
					xmlB64Data.push({
						RecordId: res._id,
						XmlData:
							isSafe(res.ixs_signer_data) &&
							isSafe(res.ixs_signer_data.payload) &&
							isSafe(res.ixs_signer_data.payload.text)
								? res.ixs_signer_data.payload.text
								: ""
					})
					savedIds += (isNotEmpty(savedIds) ? "," : "") + `RX-${res._id}`
				})
				GlobalStore.setNotificationMessage("Patient.form.patientprescription.savesuccess", savedIds)

				const providerID = getSelectedOrgUnitID()
				const key = `prescription#${xmlB64Data.reduce((a, n) => a + n.RecordId + ";", "")}`
				UIStore.commMsgQueue.set(key, {
					timestamp: Date.now(),
					text: `Zápis receptu ${DataStore.patientDTO.get("patient").identifier} ${
						DataStore.patientDTO.get("patient").full_name
					}`,
					state: 0,
					needApprove: true,
					errorCode: null,
					stateText: null,
					navigationIdentifier: {patientId: DataStore.patientDTO.get("patient")._id},
					identifier: {_id: "123", _type: "EHR.Data.Medication"}
				})
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
					(overview) => {
						DataStore.setPatientEHR(overview)
						UIStore.isFormSaving = false
						UIStore.isFormSavingMessage = ""
						window.scrollTo(0, 0)

						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					}
				)
				const selectedOrgUnit = getSelectedOrgUnit()
				const user = getUser()
				const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
				const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
				const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
				const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
				const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

				const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
					? DataStore.patientDTO.get("patient").validInfo.client
					: null
				const JRUZIDObj = isSafe(patientClientInfo.identifiers)
					? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
					: null

				GWApiCall({
					method: "POST",
					uri: `${config.GW_BASE_URL}/SignXmlData`,
					body: JSON.stringify({
						XmlDataItems: xmlB64Data,
						AmbeePatientId: DataStore.patientDTO.get("patientID"),
						PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
						PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: getUserPersonnelID(),
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
						let cardNotInserted = false
						let singnatureData = []
						if (isSafe(data) && isSafe(data.XmlDataItems) && data.XmlDataItems.length) {
							Object.keys(data.XmlDataItems).forEach((obj) => {
								if (isSafe(data.XmlDataItems[obj]) && isSafe(data.XmlDataItems)) {
									singnatureData.push({
										record_id: data.XmlDataItems[obj].RecordId,
										signature: data.XmlDataItems[obj].XmlData
									})
								}
							})
						} else {
							cardNotInserted = true
							Object.keys(response).forEach((obj) => {
								singnatureData.push({record_id: response[obj].record_id, signature: ""})
							})
						}

						api
							.signatureMultiplePrescriptions(singnatureData)
							.call()
							.then((response) => {
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									if (isSafe(response[obj])) {
										if (isSafe(response[obj].warning)) {
											text +=
												response[obj].warning.text +
												(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
											if (!confirm) confirm = response[obj].warning.confirm
										}
										ids.push(response[obj].record_id)
									}
								})
								if (cardNotInserted) {
									if (isNotEmpty(text)) {
										text += "<br/>"
									}
									text += "Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke." // Chcete napriek tomu pokračovať?
									confirm = true
								}
								if (confirm) {
									UIStore.commMsgQueue.get(key).state = 2
									// ConfirmInteractionDialogStore.confirm = true
									// ConfirmInteractionDialogStore.recordIds = ids
									// ConfirmInteractionDialogStore.close()
								} else {
									if (UIStore.commMsgQueue.get(key).needApprove && isNotEmpty(text)) {
										UIStore.commMsgQueue.get(key).state = 1
									} else {
										UIStore.commMsgQueue.delete(key)
									}

									// ConfirmInteractionDialogStore.confirm = false
									// ConfirmInteractionDialogStore.recordIds = ids
									// ConfirmInteractionDialogStore.close()
								}

								if (isSafe(UIStore.commMsgQueue.get(key))) {
									UIStore.commMsgQueue.get(key).stateText = text
								}

								if (this.recipes && this.recipes.length > 0 && this.recipes[0].print) {
									let multipleRx = []
									Object.keys(response).forEach((obj) => {
										multipleRx.push(
											PatientPrescriptionStore.printPrescription(
												response[obj].record_id,
												DataStore.patientDTO.get("patient"),
												false
											)
										)
									})
									Promise.all(multipleRx).then((r) => printMultiReport(r, null, true))
								}

								// DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								// 	UIStore.isFormSaving = false
								// 	UIStore.isFormSavingMessage = ""
								// 	window.scrollTo(0, 0)
								// })
							})
							.catch(() => {
								if (isSafe(UIStore.commMsgQueue.get(key))) {
									UIStore.commMsgQueue.get(key).state = 2
									UIStore.commMsgQueue.get(key).stateText = "Zlyhalo spojenie"
								}
							})
					})
					.catch(() => {
						let singnatureData = []
						Object.keys(response).forEach((obj) => {
							singnatureData.push({record_id: response[obj].record_id, signature: response[obj].signature})
						})
						api
							.signatureMultiplePrescriptions(singnatureData)
							.call()
							.then((response) => {
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									text +=
										response[obj].warning.text +
										(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
									if (!confirm) confirm = response[obj].warning.confirm
									ids.push(response[obj].record_id)
								})
								if (confirm) {
									UIStore.commMsgQueue.get(key).state = 2
									// ConfirmInteractionDialogStore.confirm = true
									// ConfirmInteractionDialogStore.recordIds = ids
									// ConfirmInteractionDialogStore.close()
								} else {
									if (UIStore.commMsgQueue.get(key).needApprove && isNotEmpty(text)) {
										UIStore.commMsgQueue.get(key).state = 1
									} else {
										UIStore.commMsgQueue.delete(key)
									}
									// ConfirmInteractionDialogStore.confirm = false
									// ConfirmInteractionDialogStore.recordIds = ids
									// ConfirmInteractionDialogStore.close()
								}
								if (isSafe(UIStore.commMsgQueue.get(key))) {
									UIStore.commMsgQueue.get(key).stateText = text
								}
								if (this.recipes && this.recipes.length > 0 && this.recipes[0].print) {
									let multipleRx = []
									Object.keys(response).forEach((obj) => {
										multipleRx.push(
											PatientPrescriptionStore.printPrescription(
												response[obj].record_id,
												DataStore.patientDTO.get("patient"),
												false
											)
										)
									})
									Promise.all(multipleRx).then((r) => printMultiReport(r, null, true))
								}

								// DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								// 	UIStore.isFormSaving = false
								// 	window.scrollTo(0, 0)
								// })
							})
							.catch(() => {
								if (isSafe(UIStore.commMsgQueue.get(key))) {
									UIStore.commMsgQueue.get(key).state = 2
									UIStore.commMsgQueue.get(key).stateText = "Zlyhalo spojenie"
								}
							})
					})
			})
		this.isOpen = false
	}

	@action
	checkOverinteraction(recipes, callback) {
		this.recipes = []
		this.clearRecipes = callback

		let req = {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID(),
			product_ids: recipes.filter((row) => row.recipe_type != "RINDIVID").map((i) => i.product_id)
		}
		UIStore.isFormSavingMessage = "Čakám na zdravotnú poisťovňu"
		if (req.product_ids.length == 0) {
			this.open(recipes, b64DecodeUnicode(""))
		} else {
			api
				.checkOverinterakcie(req)
				.call()
				.then((response) => this.open(recipes, b64DecodeUnicode(response.html)))
				.catch(() => {
					this.recipes = recipes
					// this.formData = form
					this.close()
				})
		}
	}
}

var singleton = new PatientCheckInsurerStore()
export default singleton
