import YearDropdown from "./YearDropdown"
import MonthDropdown from "./MonthDropdown"
import MonthYearDropdown from "./MonthYearDropdown"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Month from "./Month"
import Time from "./Time"
import React from "react"
import classnames from "classnames"
import {
	now,
	setMonth,
	getMonth,
	addMonths,
	subtractMonths,
	getStartOfWeek,
	getStartOfDate,
	addDays,
	cloneDate,
	formatDate,
	localizeDate,
	setYear,
	getYear,
	isBefore,
	isAfter,
	getLocaleData,
	getWeekdayShortInLocale,
	getWeekdayMinInLocale,
	allDaysDisabledBefore,
	allDaysDisabledAfter,
	getEffectiveMinDate,
	getEffectiveMaxDate
} from "./helpers"
// import {observer} from "mobx-react"

const DROPDOWN_FOCUS_CLASSNAMES = [
	"react-datepicker__year-select",
	"react-datepicker__month-select",
	"react-datepicker__month-year-select"
]

const isDropdownSelect = (element = {}) => {
	const classNames = (element.className || "").split(/\s+/)
	return DROPDOWN_FOCUS_CLASSNAMES.some((testClassname) => classNames.indexOf(testClassname) >= 0)
}

// @observer
export default class Calendar extends React.Component {
	date = null
	selectingDate = null
	monthContainer = null

	static get defaultProps() {
		return {
			onDropdownFocus: () => {},
			monthsShown: 1,
			forceShowMonthNavigation: false,
			timeCaption: "Time"
		}
	}

	constructor(props) {
		super(props)
		this.state = {
			date: this.localizeDate(this.getDateInView())
			// selectingDate: null,
			// monthContainer: this.monthContainer
		}
		//this.date = this.localizeDate(this.getDateInView());

		this.selectingDate = null
		this.monthContainer = this.monthContainerRef
	}

	componentDidMount() {
		if (this.props.showTimeSelect) {
			this.assignMonthContainer = (() => {
				//this.setState({monthContainer: this.monthContainer});
				this.monthContainer = this.monthContainerRef
			})()
		}
	}

	// shouldComponentUpdate(nextProps) {
	// 	// if (
	// 	// 	nextProps.preSelection &&
	// 	// 	isMoment(nextProps.preSelection) &&
	// 	// 	!isSameDay(nextProps.preSelection, this.props.preSelection)
	// 	// ) {
	// 	// 	this.setState({
	// 	// 		date: this.localizeDate(nextProps.preSelection)
	// 	// 	})
	// 	// 	// this.date = this.localizeDate(nextProps.preSelection);
	// 	// } else if (nextProps.openToDate && !isSameDay(nextProps.openToDate, this.props.openToDate)) {
	// 	// 	this.setState({
	// 	// 		date: this.localizeDate(nextProps.openToDate)
	// 	// 	})
	// 	// 	// this.date = this.localizeDate(nextProps.openToDate);
	// 	// }
	// 	if (!equals(this.state.date, nextProps.selected)) {
	// 		logger(this.state.date, nextProps.selected)
	// 		// this.setState({
	// 		// 	date: this.localizeDate(nextProps.selected)
	// 		// })
	// 	}
	// 	return true
	// }

	// componentDidUpdate(prevProps) {
	//   if (this.props.preSelection && !isSameDay(this.props.preSelection, prevProps.preSelection)) {
	//     /*this.setState({
	//       date: this.localizeDate(this.props.preSelection)
	//     });*/
	//     this.date = this.localizeDate(this.props.preSelection);
	//   } else if (this.props.openToDate && !isSameDay(this.props.openToDate, prevProps.openToDate)) {
	//     /*this.setState({
	//       date: this.localizeDate(this.props.openToDate)
	//     });*/
	//     this.date = this.localizeDate(this.props.openToDate);
	//   }
	// }

	handleClickOutside = (event) => {
		this.props.onClickOutside(event)
	}

	handleDropdownFocus = (event) => {
		if (isDropdownSelect(event.target)) {
			this.props.onDropdownFocus()
		}
	}

	getDateInView = () => {
		const {preSelection, selected, openToDate, utcOffset} = this.props
		const minDate = getEffectiveMinDate(this.props)
		const maxDate = getEffectiveMaxDate(this.props)
		const current = now(utcOffset)
		const initialDate = openToDate || selected || preSelection
		if (initialDate) {
			return initialDate
		} else {
			if (minDate && isBefore(current, minDate)) {
				return minDate
			} else if (maxDate && isAfter(current, maxDate)) {
				return maxDate
			}
		}
		return current
	}

	localizeDate = (date) => localizeDate(date, this.props.locale)

	increaseMonth = () => {
		this.setState(
			{
				date: addMonths(cloneDate(this.state.date), 1)
			},
			() => this.handleMonthChange(this.state.date)
		)
		// this.date = addMonths(cloneDate(this.date), 1);
		// this.handleMonthChange(this.date);
	}

	decreaseMonth = () => {
		this.setState(
			{
				date: subtractMonths(cloneDate(this.state.date), 1)
			},
			() => this.handleMonthChange(this.state.date)
		)
		// this.date = subtractMonths(cloneDate(this.date), 1);
		// this.handleMonthChange(this.date);
	}

	handleTimeClick = (day, event) => {
		this.props.onSelect(day, event)
		if (this.props.onTimeClick) {
			this.props.onTimeClick(day, event)
		}
	}

	handleDayClick = (day, event) => {
		this.props.onSelect(day, event)
		if (this.props.onDayClick) {
			this.props.onDayClick(day, event)
		}
	}

	//handleDayMouseEnter = day => this.setState({selectingDate: day});
	handleDayMouseEnter = (day) => (this.selectingDate = day)

	//handleMonthMouseLeave = () => this.setState({selectingDate: null});
	handleMonthMouseLeave = () => (this.selectingDate = null)

	handleYearChange = (date) => {
		if (this.props.onYearChange) {
			this.props.onYearChange(date)
		}
	}

	handleMonthChange = (date) => {
		if (this.props.onMonthChange) {
			this.props.onMonthChange(date)
		}
		if (this.props.adjustDateOnChange) {
			if (this.props.onSelect) {
				this.props.onSelect(date)
			}
			if (this.props.setOpen) {
				this.props.setOpen(true)
			}
		}
	}

	handleMonthYearChange = (date) => {
		this.handleYearChange(date)
		this.handleMonthChange(date)
	}

	changeYear = (year) => {
		this.setState(
			{
				date: setYear(cloneDate(this.state.date), year)
			},
			() => this.handleYearChange(this.state.date)
		)
		// this.date = setYear(cloneDate(this.date), year);
		// this.handleYearChange(this.date);
	}

	changeMonth = (month) => {
		this.setState(
			{
				date: setMonth(cloneDate(this.state.date), month)
			},
			() => this.handleMonthChange(this.state.date)
		)
		// this.date = setMonth(cloneDate(this.date), month);
		// this.handleMonthChange(this.date);
	}

	changeMonthYear = (monthYear) => {
		this.setState(
			{
				date: setYear(setMonth(cloneDate(this.state.date), getMonth(monthYear)), getYear(monthYear))
			},
			() => this.handleMonthYearChange(this.state.date)
		)
		// this.date = setYear(setMonth(cloneDate(this.date), getMonth(monthYear)), getYear(monthYear))
		// this.handleMonthYearChange(this.date);
	}

	header = (date = this.date) => {
		const startOfWeek = getStartOfWeek(cloneDate(date))
		const dayNames = []
		if (this.props.showWeekNumbers) {
			dayNames.push(
				<div key="W" className="react-datepicker__day-name">
					{this.props.weekLabel || "#"}
				</div>
			)
		}
		return dayNames.concat(
			[0, 1, 2, 3, 4, 5, 6].map((offset) => {
				const day = addDays(cloneDate(startOfWeek), offset)
				const localeData = getLocaleData(day)
				const weekDayName = this.props.useWeekdaysShort
					? getWeekdayShortInLocale(localeData, day)
					: getWeekdayMinInLocale(localeData, day)
				return (
					<div key={offset} className="react-datepicker__day-name">
						{weekDayName}
					</div>
				)
			})
		)
	}

	renderPreviousMonthButton = () => {
		const allPrevDaysDisabled = allDaysDisabledBefore(this.state.date, "month", this.props)

		if (!this.props.forceShowMonthNavigation && !this.props.showDisabledMonthNavigation && allPrevDaysDisabled) {
			return
		}

		const classes = ["xs-calendar-arrows", "react-datepicker__navigation", "react-datepicker__navigation--previous"]

		let clickHandler = this.decreaseMonth

		if (allPrevDaysDisabled && this.props.showDisabledMonthNavigation) {
			classes.push("react-datepicker__navigation--previous--disabled")
			clickHandler = null
		}

		if (this.props.showMonthDropdown || this.props.showYearDropdown) {
			classes.push("react-datepicker__navigation--dropdown")
		}

		return <ChevronLeft onClick={clickHandler} className={classes.join(" ")} />
	}

	renderNextMonthButton = () => {
		const allNextDaysDisabled = allDaysDisabledAfter(this.state.date, "month", this.props)

		if (!this.props.forceShowMonthNavigation && !this.props.showDisabledMonthNavigation && allNextDaysDisabled) {
			return
		}

		const classes = ["xs-calendar-arrows", "react-datepicker__navigation", "react-datepicker__navigation--next"]

		if (this.props.showTimeSelect) {
			classes.push("react-datepicker__navigation--next--with-time")
		}

		if (this.props.showMonthDropdown || this.props.showYearDropdown) {
			classes.push("react-datepicker__navigation--dropdown")
		}

		if (this.props.todayButton) {
			classes.push("react-datepicker__navigation--next--with-today-button")
		}

		let clickHandler = this.increaseMonth

		if (allNextDaysDisabled && this.props.showDisabledMonthNavigation) {
			classes.push("react-datepicker__navigation--next--disabled")
			clickHandler = null
		}

		return <ChevronRight onClick={clickHandler} className={classes.join(" ")} />
	}

	renderCurrentMonth = (date = this.state.date) => {
		const classes = ["react-datepicker__current-month"]

		if (this.props.showYearDropdown) {
			classes.push("react-datepicker__current-month--hasYearDropdown")
		}
		if (this.props.showMonthDropdown) {
			classes.push("react-datepicker__current-month--hasMonthDropdown")
		}
		if (this.props.showMonthYearDropdown) {
			classes.push("react-datepicker__current-month--hasMonthYearDropdown")
		}
		const monthYear = formatDate(date, this.props.dateFormatCalendar)
		const spl = monthYear.split(" ")
		const month = this.renderMonthDropdown()
		const year = this.renderYearDropdown()

		return (
			<div className={classes.join(" ")}>
				{spl[0]} {month} {spl[1]} {year}
			</div>
		)
	}

	renderYearDropdown = (overrideHide = false) => {
		if (!this.props.showYearDropdown || overrideHide) {
			return
		}
		return (
			<YearDropdown
				adjustDateOnChange={this.props.adjustDateOnChange}
				date={this.state.date}
				onSelect={this.props.onSelect}
				setOpen={this.props.setOpen}
				dropdownMode={this.props.dropdownMode}
				onChange={this.changeYear}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				year={getYear(this.state.date)}
				scrollableYearDropdown={this.props.scrollableYearDropdown}
				yearDropdownItemNumber={this.props.yearDropdownItemNumber}
			/>
		)
	}

	renderMonthDropdown = (overrideHide = false) => {
		if (!this.props.showMonthDropdown || overrideHide) {
			return
		}

		return (
			<MonthDropdown
				dropdownMode={this.props.dropdownMode}
				locale={this.props.locale}
				dateFormat={this.props.dateFormat}
				onChange={this.changeMonth}
				month={getMonth(this.state.date)}
				useShortMonthInDropdown={this.props.useShortMonthInDropdown}
			/>
		)
	}

	renderMonthYearDropdown = (overrideHide = false) => {
		if (!this.props.showMonthYearDropdown || overrideHide) {
			return
		}
		return (
			<MonthYearDropdown
				dropdownMode={this.props.dropdownMode}
				locale={this.props.locale}
				dateFormat={this.props.dateFormat}
				onChange={this.changeMonthYear}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				date={this.state.date}
				scrollableMonthYearDropdown={this.props.scrollableMonthYearDropdown}
			/>
		)
	}

	renderTodayButton = () => {
		if (!this.props.todayButton) {
			return
		}
		return (
			<div
				className="react-datepicker__today-button"
				onClick={(e) => this.props.onSelect(getStartOfDate(now(this.props.utcOffset)), e)}
			>
				{this.props.todayButton}
			</div>
		)
	}

	renderMonths = () => {
		var monthList = []
		for (var i = 0; i < this.props.monthsShown; ++i) {
			var monthDate = addMonths(cloneDate(this.state.date), i)
			var monthKey = `month-${i}`
			monthList.push(
				<div
					key={monthKey}
					ref={(div) => {
						this.monthContainerRef = div
					}}
					className={"react-datepicker__month-container" + (!this.props.showTimeSelect ? " fullWidth" : "")}
				>
					<div className="react-datepicker__header">
						<div
							className={`react-datepicker__header__dropdown react-datepicker__header__dropdown--${this.props.dropdownMode}`}
							onFocus={this.handleDropdownFocus}
						>
							{/* {this.renderMonthDropdown(i !== 0)} */}
							{/* {this.renderMonthYearDropdown(i !== 0)} */}
							{/* {this.renderYearDropdown(i !== 0)} */}
						</div>
						{this.renderCurrentMonth(monthDate)}
						<div className="react-datepicker__day-names">{this.header(monthDate)}</div>
					</div>
					<Month
						day={monthDate}
						dayClassName={this.props.dayClassName}
						onDayClick={this.handleDayClick}
						onDayMouseEnter={this.handleDayMouseEnter}
						onMouseLeave={this.handleMonthMouseLeave}
						onWeekSelect={this.props.onWeekSelect}
						formatWeekNumber={this.props.formatWeekNumber}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
						excludeDates={this.props.excludeDates}
						highlightDates={this.props.highlightDates}
						selectingDate={this.selectingDate}
						includeDates={this.props.includeDates}
						inline={this.props.inline}
						fixedHeight={this.props.fixedHeight}
						filterDate={this.props.filterDate}
						preSelection={this.props.preSelection}
						selected={this.props.selected}
						selectsStart={this.props.selectsStart}
						selectsEnd={this.props.selectsEnd}
						showWeekNumbers={this.props.showWeekNumbers}
						startDate={this.props.startDate}
						endDate={this.props.endDate}
						peekNextMonth={this.props.peekNextMonth}
						utcOffset={this.props.utcOffset}
					/>
				</div>
			)
		}
		return monthList
	}

	renderTimeSection = () => {
		if (this.props.showTimeSelect) {
			return (
				<Time
					onTimeClick={this.handleTimeClick}
					selected={this.props.selected}
					onChange={this.props.onTimeChange}
					format={this.props.timeFormat}
					includeTimes={this.props.includeTimes}
					intervals={this.props.timeIntervals}
					minTime={this.props.minTime}
					maxTime={this.props.maxTime}
					excludeTimes={this.props.excludeTimes}
					timeCaption={this.props.timeCaption}
					todayButton={this.props.todayButton}
					showMonthDropdown={this.props.showMonthDropdown}
					showMonthYearDropdown={this.props.showMonthYearDropdown}
					showYearDropdown={this.props.showYearDropdown}
					withPortal={this.props.withPortal}
					monthRef={this.monthContainer}
				/>
			)
		}
	}

	render() {
		return (
			<div className={classnames("react-datepicker", this.props.className)}>
				<div className="react-datepicker__triangle" />
				{this.renderPreviousMonthButton()}
				{this.renderNextMonthButton()}
				{this.renderMonths()}
				{this.renderTodayButton()}
				{this.renderTimeSection()}
				{this.props.children}
			</div>
		)
	}
}
