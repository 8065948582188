"use strict"
import api from "../actions/api"
// import moment from "moment"
import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"
import UIStore from "../stores/UIStore"

class RecordsWithoutDeliveriesStore {
	@observable patientsOverview = []
	@observable isFetching = false
	@observable isDownloading = false
	@observable isOpen = false

	formRef = undefined

	sicknessAbsReportListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (isChangeDateFrom || isChangeDateTo || this.currFilters.number != formValues.number) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action set(name, value) {
		this[name] = value
	}

	@action loadPatientsOverview(form) {
		this.currFilters = form.values()

		let filters = ["type_id=EHR.Data.Ambulance", `provider_id=${getSelectedOrgUnitID()}`, `full_patient_info=${1}`]

		if (isSafe(form) && isSafe(form.values())) {
			let formValues = form.values()
			if (isNotEmpty(formValues.number)) {
				filters.push(`number=${formValues.number}`)
			}
		}

		let searchRequest = getFilters(filters)

		if (isSafe(form) && isSafe(form.values())) {
			let formValues = form.values()
			if (formValues.datefrom) {
				searchRequest.time_from = formValues.datefrom.startOf("day").format(settings.DB_DATETIME_FORMAT)
			}
			if (formValues.dateto) {
				searchRequest.time_to = formValues.dateto.endOf("day").format(settings.DB_DATETIME_FORMAT)
			}
		}

		this.isFetching = true
		api
			.loadEHROverviewCDL4(searchRequest)
			.call()
			.then((response) => {
				this.patientsOverview = response.rows.filter((record) => record.has_deliveries === false && record.type_code.toUpperCase() === "DEK" && record.version == "final")
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action exportToXls(form) {
		UIStore.isFormSaving = true

		let filters = [
			{
				associated_column: "provider_id",
				values: [
					{
						id_value: getSelectedOrgUnitID()
					}
				]
			},
			{
				associated_column: "type_id",
				values: [
					{
						id_value: "EHR.Data.Ambulance"
					}
				]
			}
		]

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()
			if (isNotEmpty(refFormValues.number)) {
				filters.push({
					associated_column: "number",
					values: [
						{
							id_value: refFormValues.number
						}
					]
				})
			}
		}

		let req = {
			filters: filters,
			uri: `/amb/ehr/overview?level_flag=CDL3`
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()

			if (isNotEmpty(refFormValues.datefrom)) {
				req.time_from = refFormValues.datefrom.startOf("day").format(settings.DB_DATETIME_FORMAT)
			}

			if (isNotEmpty(refFormValues.dateto)) {
				req.time_to = refFormValues.dateto.endOf("day").format(settings.DB_DATETIME_FORMAT)
			}
		}

		if (form.values().exportType == "now") {
			if (isSafe(this.sicknessAbsReportListRef)) {
				req.row_count_show = this.sicknessAbsReportListRef.state.rowsPerPage
				// req.row_count_full = this.sicknessAbsReportListRef.state.rowsPerPage
				req.row_offset = this.sicknessAbsReportListRef.state.page * this.sicknessAbsReportListRef.state.rowsPerPage + 1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
				// req.row_count_full = 10
			}
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			req.row_count_full = 100000
		}

		api
			.exportData(req)
			.call()
			.then((response) => {
				UIStore.isFormSaving = false
				form.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
				this.close()
			})

		this.close()
	}
}
var singleton = new RecordsWithoutDeliveriesStore()
export default singleton
