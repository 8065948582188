"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import api from "../actions/api"
import VaccinationStore from "../stores/VaccinationListStore"

class ExportMedicationToXlsDialogStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action ExportToXls(form) {
		let filters = []

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isNotEmpty(VaccinationStore.formRef.$("org_unit").value)) {
			filters.push({
				associated_column: "provider_id",
				values: [{id_value: VaccinationStore.formRef.$("org_unit").value}]
			})
		}

		if (isNotEmpty(VaccinationStore.formRef.$("identifier").value)) {
			filters.push({
				associated_column: "identifier",
				values: [{id_value: VaccinationStore.formRef.$("identifier").value}]
			})
		}

		if (isNotEmpty(VaccinationStore.formRef.$("date_from").value)) {
			filters.push({
				associated_column: "date_from",
				values: [{id_value: moment(VaccinationStore.formRef.$("date_from").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(VaccinationStore.formRef.$("date_to").value)) {
			filters.push({
				associated_column: "date_to",
				values: [{id_value: moment(VaccinationStore.formRef.$("date_to").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(VaccinationStore.formRef.$("sub_type").value)) {
			filters.push({
				associated_column: "sub_type_id",
				values: [{id_value: VaccinationStore.formRef.$("sub_type").value}]
			})
		}

		let req = {
			filters: filters,
			uri: `/amb/report/generate/SKVACC`
		}

		if (isSafe(VaccinationStore.vaccinationReportListRef)) {
			req.orders = [
				{
					associated_column: "created_at",
					asc: false
				}
			]
		}

		if (form.values().exportType == "now") {
			if (isSafe(VaccinationStore.vaccinationReportListRef)) {
				req.row_count_show = VaccinationStore.vaccinationReportListRef.state.rowsPerPage
				// req.row_count_full = VaccinationStore.vaccinationReportListRef.state.rowsPerPage
				req.row_offset =
					VaccinationStore.vaccinationReportListRef.state.page *
						VaccinationStore.vaccinationReportListRef.state.rowsPerPage +
					1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
				// req.row_count_full = 10
			}
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			req.row_count_full = 100000
		}

		api
			.exportData(req)
			.call()
			.then((response) => {
				this.close()
				form.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
	}
}

var singleton = new ExportMedicationToXlsDialogStore()
export default singleton
