"use strict"

// default imports for all farms
import React, {useState, useEffect} from "react"
import api from "../../actions/api"
import XsInput from "../../../../global/ui/xsInput/xsInput"

function ComponentAsync(props) {
	// const greeting = "Hello Function Component!"

	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		logger("OPDAL EFECT")
		async function loadData() {
			let data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			data = await api.loadEntityInfo("63008", "").call()
			logger("DATA", data)
			props.form.$("name").value = data.first_name
			props.form.$("surname").value = data.last_name
			props.form.$("age").value = data.age
			props.form.$("identifier").value = data.identifier
			setIsLoaded(true)
		}

		if (!isLoaded) {
			loadData()
		}
	})

	return isLoaded ? (
		<div>
			<h1>fake form</h1>
			<XsInput field={props.form.$("name")} />
			<XsInput field={props.form.$("surname")} />
			<XsInput field={props.form.$("age")} />
			<XsInput field={props.form.$("identifier")} />
		</div>
	) : (
		<h1>loading</h1>
	)
}

export default ComponentAsync
