"use strict"

import {observable, action} from "mobx"

import {padStart} from "../../../global/helpers/functions"

import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"

import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "../stores/UIStore"

import DuplicityPersonModalStore from "./DuplicityPersonModalStore"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import RegistersCacheStore from "../../../global/store/RegistersCacheStore"

class NewPatientDialogStore {
	@observable isOpen = false

	newPatientFormRef = null

	@action open(patientIdentifier = null) {
		this.isOpen = true

		this.getPatientInfo(patientIdentifier)
	}

	@action getPatientInfo(patientIdentifier) {
		if (
			isSafe(patientIdentifier) &&
			isSafe(this.newPatientFormRef) &&
			isFinite(patientIdentifier) &&
			(patientIdentifier.length === 9 || patientIdentifier.length === 10) &&
			+patientIdentifier % 11 == 0
		) {
			const identifier = patientIdentifier.includes("/") ? patientIdentifier.replace("/", "") : patientIdentifier
			const idfYear = +identifier.substr(0, 2)
			const actualYear = new Date()
				.getFullYear()
				.toString()
				.substr(2, 2)

			const year = +idfYear + (+idfYear <= +actualYear && +idfYear < 54 && identifier.length === 10 ? 2000 : 1900)
			let month = +identifier.substr(2, 2) - (+identifier.substr(2, 2) > 12 ? 50 : 0)
			let day = +identifier.substr(4, 2)
			const gender = +identifier.substr(2, 2) > 12 ? "F" : "M"

			month = padStart(month, 2, "0")
			day = padStart(day, 2, "0")

			const testDate = new Date(`${year}-${month}-${day}`)

			if (testDate instanceof Date == false || isNaN(testDate) || testDate.getDate() !== +day) {
				GlobalStore.setNotificationMessage("Patient.form.patientinfo.invalidIdentifierWarning")
			} else {
				this.newPatientFormRef.$("dateOfBirth").set("value", `${year}-${month}-${day}`)
				this.newPatientFormRef.$("gender").set("value", gender)
			}

			this.newPatientFormRef.$("identifier").set("value", identifier)

			const selectedOrgUnit = getSelectedOrgUnit()
			const personnel_id = getUserPersonnelID()
			const user = getUser()
			const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
			const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
			const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
			const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
			const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

			GlobalStore.loadingMessage = "Načítavam údaje pacienta z NZIS, môže to chvíľu trvať..."
			UIStore.isFormSaving = true

			GWApiCall({
				method: "POST",
				uri: `${config.GW_BASE_URL}/GetPatientInfo`,
				body: JSON.stringify({
					ClassName: "DajPacientskySumarKontaktneUdaje_v3",
					AmbeePatientId: 0,
					PatientIdentificationNumber: identifier,
					PatientJRUZId: null,
					AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
					AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
					AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
					AmbeePersonnelId: personnel_id,
					OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
					PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
					PersonnelExpertiseJRUZVersion: specVer,
					PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
				})
			})
				.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
				.then((data) => {
					if (isSafe(data) && isNotSafe(data.ErrorMessage)) {
						this.newPatientFormRef.$("gender").set("value", isSafe(data.Gender) ? data.Gender : "")
						this.newPatientFormRef.$("firstname").set("value", isSafe(data.FirstName) ? data.FirstName : "")
						this.newPatientFormRef.$("lastname").set("value", isSafe(data.LastName) ? data.LastName : "")
						// this.newPatientFormRef.$("county").set("value", isSafe(data.Region) ? data.Region : "")
						// this.newPatientFormRef.$("region").set("value", isSafe(data.County) ? data.County : "")
						this.newPatientFormRef.$("city").set("value", isSafe(data.City) ? data.City : "")
						this.newPatientFormRef.$("cityText").set("value", isSafe(data.City) ? data.City : "")
						this.newPatientFormRef.$("street").set("value", isSafe(data.StreetName) ? data.StreetName : "")
						this.newPatientFormRef.$("houseNumber").set("value", isSafe(data.BuildingNumber) ? data.BuildingNumber : "")
						this.newPatientFormRef.$("street").set("value", isSafe(data.StreetName) ? data.StreetName : "")
						this.newPatientFormRef.$("dateOfBirth").set("value", isSafe(data.DateOfBirth) ? data.DateOfBirth : "")
						this.newPatientFormRef.$("zip").set("value", isSafe(data.Zip) ? data.Zip : "")
						this.newPatientFormRef.$("zipText").set("value", isSafe(data.Zip) ? data.Zip : "")

						if (isNotEmpty(data.KodZP)) {
							const insurer = RegistersCacheStore.insurersRegister.filter((x) => x.code_ext == data.KodZP)

							if (isSafe(insurer) && insurer.length > 0) {
								this.newPatientFormRef.$("insurer").set("value", insurer[0]._id)
							}
						}

						if (isNotEmpty(data.Region)) {
							const region = GlobalStore.CL["addressRegion"].filter((reg) => reg.name_ext == data.Region)

							if (isSafe(region) && region.length > 0) {
								this.newPatientFormRef.$("region").set("value", region[0]._id)
							}
						}

						if (isNotEmpty(data.County)) {
							if (data.County == "Zahraničie") {
								this.newPatientFormRef.$("county").set("value", "SKZZZ")
							} else {
								const county = GlobalStore.CL["addressCounty"].filter(
									(county) => county.name_ext == `Okres ${data.County}`
								)

								if (isSafe(county) && county.length > 0) {
									this.newPatientFormRef.$("county").set("value", county[0]._id)
								}
							}
						}
					} else {
						GlobalStore.setNotificationMessage("Common.label.cantFindOutInfoAboutPacientFromNZIS")
					}
					UIStore.isFormSaving = false
					GlobalStore.loadingMessage = ""
				})
				.catch(() => {
					GlobalStore.setNotificationMessage("Common.label.cantFindOutInfoAboutPacientFromNZIS")
					GlobalStore.loadingMessage = ""
					UIStore.isFormSaving = false
				})
		}
	}

	@action close() {
		this.isOpen = false
		DuplicityPersonModalStore.close()
	}
}

var singleton = new NewPatientDialogStore()
export default singleton
