import React from "react"

import {FormattedMessage, injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import Popover from "@material-ui/core/Popover"
import {toLatin} from "../../helpers/functions.js"

import "./xsSearchdropdown.less"
import {Checkbox, Typography} from "@material-ui/core"
import SearchFieldNoForm from "./xsSearchfieldNoForm"
import XsButton from "../xsButton/xsButton.js"

@injectIntl
export class SearchDropdownNoForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			openned: false,
			label: props.label,
			searchText: "",
			items: props.items,
			anchorEl: null,
			checkAll: false
		}

		this.filteredItems = props.items.map((x) => ({...x})) // shallow copy because of FUCKING MEMORY REFERENCIES!!!
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			items: nextProps.items
		})
		this.filteredItems = nextProps.items.map((x) => ({...x}))
	}

	// shouldComponentUpdate(nextProps) {
	//   return true
	// }
	// TODO: dorobit, ak pridu zmenene props.items voci this.state.items

	getDataSource = () => {
		{
			const retValue = this.filteredItems.map((field, idx) => {
				return (
					<div key={idx} className="listItem" onClick={() => this.updateItemState(field)}>
						{field.secondaryText && (
							<div className="secondaryText">
								{
									<div>
										<Checkbox value={field.id} checked={field.checkValue} className="searchDropdownCheck" />
									</div>
								}
								<div className="secondaryTextLabel">{field.secondaryText}</div>
							</div>
						)}
						<div className="itemValue">{field.primaryText}</div>
					</div>
				)
			})
			return retValue
		}
	}

	updateItemState = (updatedItem) => {
		const multiSelect = this.props.multiSelect
		if (!multiSelect) {
			let itemsTmp = this.state.items
			let id = updatedItem.id

			itemsTmp.forEach((i) => {
				if (i.id == id) {
					i.checkValue = !i.checkValue
				}
			})
			// TODO: skontrolovat pre single-select
			this.setState({
				items: itemsTmp
			})
		} else {
			const itemId = updatedItem.id

			const itemsTmp = this.state.items.map((i) => {
				let item = i
				if (item.id == itemId) {
					item.checkValue = !updatedItem.checkValue
				}
				return item
			})

			this.filteredItems = this.filteredItems.map((i) => {
				if (i.id == itemId) {
					i.checkValue = !updatedItem.checkValue
				}
				return i
			})

			this.setState({
				items: itemsTmp
			})
		}
	}

	togglePop = () => {
		this.setState({
			openned: !this.state.openned,
			anchorEl: document.getElementById(this.props.id)
		})
	}

	closePop = () => {
		this.setState(
			{
				openned: false
			},
			() => {
				this.props.onClose(this.state.items.filter((i) => i.checkValue == true))
				this.onSearchChange()
			}
		)
	}

	onSearchChange = (text) => {
		const regexText = toLatin(text)
		this.setState(
			{
				searchText: isEmpty(text) ? "" : text
			},
			() => {
				let filteredItems = this.state.items
				if (regexText.length >= 3) {
					filteredItems = this.state.items.filter(({search_string}) =>
						search_string.toLowerCase().includes(regexText.toLowerCase())
					)
				}

				this.filteredItems = filteredItems.map((x) => ({...x})) // shallow copy because of FUCKING MEMORY REFERENCIES!!!
				this.forceUpdate()
			}
		)
	}

	selectAll = (selectAll) => {
		const itemsTmp = this.state.items.map((i) => {
			i.checkValue = selectAll
			return i
		})

		this.filteredItems = this.filteredItems.map((i) => {
			i.checkValue = selectAll
			return i
		})

		this.setState({
			items: itemsTmp,
			checkAll: selectAll
		})
	}

	renderTooltipText = (defaultLabel) => {
		const splitted = this.state.items
			.filter((x) => x.checkValue === true)
			.map((x) => x.secondaryText)
			.join()
		if (isNotEmpty(splitted)) {
			return (
				<span data-tip data-for="searchDropdownTooltip">
					{splitted.length > 20 ? splitted.substr(0, 18) + ".." : splitted}
					<ReactTooltip id="searchDropdownTooltip">{splitted.replace(/,/g, ", ")}</ReactTooltip>
				</span>
			)
		} else {
			return defaultLabel
		}
	}

	render() {
		const {id, disabled, multiSelect, /*items,*/ hideSearch, style} = this.props
		const {openned, searchText, anchorEl, label} = this.state
		const labelClass = openned ? "title active" : "title"
		const headerClass = openned ? "header xs-active" : "header"
		const isSomeChecked = this.state.items.some((i) => i.checkValue == true)

		return (
			<div className={`dropdownAutoComplete${isSomeChecked ? " filtered" : ""}`} id={id} style={style}>
				{disabled ? (
					<div className="header disabled">
						<div className="title disabled">
							{/* <span>{label || items[0].primaryText}</span> */}
							<span>{label}</span>
						</div>
						<div className="arrow disabled" />
					</div>
				) : (
					<div>
						<div className={headerClass} onClick={this.togglePop}>
							<div className={labelClass}>
								<div>
									{// label || items[0].primaryText
									this.state.openned ? label : this.renderTooltipText(label)}
									{isSomeChecked && (
										<span className="titleBadge">{this.state.items.filter((i) => i.checkValue == true).length}</span>
									)}
								</div>
							</div>
							<div className="arrow" />
						</div>
						<div className="errorText">{}</div>
						<Popover
							open={openned}
							anchorEl={anchorEl}
							anchorOrigin={{horizontal: "left", vertical: "bottom"}}
							onClose={this.closePop}
						>
							<div className="searchListBody">
								{!hideSearch && (
									<SearchFieldNoForm
										onChange={this.onSearchChange}
										value={searchText}
										placeholder={this.props.intl.formatMessage({id: "Common.searchDropdown.search"})}
										onClear={() => {
											this.selectAll(false)
										}}
									/>
								)}
								<div className="searchListItems">{this.getDataSource()}</div>
								{multiSelect && (
									<div className="checkAllContainer">
										<Typography>
											<XsButton
												text={<FormattedMessage id="Common.searchDropdown.selectAll" />}
												className="checkAllFormControl"
												onClick={() => this.selectAll(true)}
											/>
										</Typography>
										<Typography>
											<XsButton
												text={<FormattedMessage id="Common.searchDropdown.deselectAll" />}
												className="checkAllFormControl"
												onClick={() => this.selectAll(false)}
											/>
										</Typography>
									</div>
								)}
							</div>
						</Popover>
					</div>
				)}
			</div>
		)
	}
}

export default SearchDropdownNoForm
