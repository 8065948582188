/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				print: {
					value: false,
					rules: "boolean"
				},
				prescriptionType: {
					label: (
						<FormattedMessage
							id="Patient.form.patientPrescription.prescriptiontype"
							defaultMessage="Prescription type"
						/>
					),
					value: "RBULK",
					rules: "required"
				},
				repeatType: {
					label: <FormattedMessage id="Patient.form.patientPrescription.repeattype" defaultMessage="Repeat type" />,
					value: "1",
					rules: "required"
				},
				repeatTypeSecondary: {
					label: <FormattedMessage id="Patient.form.patientPrescription.repeatTypeSecondary" />
					// required_if: ["repeatType", "0"]
				},
				maxRepeats: {
					label: <FormattedMessage id="Patient.form.patientPrescription.maxRepeats" />,
					// required_if: ["repeatType", "0"],
					value: "1"
				},
				drugs: {
					label: <FormattedMessage id="Common.label.drugs" />
					// required_if: ["prescriptionType", "RBULK"]
				},
				medEquip: {
					label: <FormattedMessage id="Patient.form.patientPrescription.medDev" />
					// rules: "string|required"
					// required_if: ["prescriptionType", "MEDEQUIP"]
				},
				medEquipDoseUnit: {},
				medFood: {
					label: <FormattedMessage id="Patient.form.patientPrescription.medFood" />
					// required_if: ["prescriptionType", "RDIETETIC"]
				},
				// IPLselect: {
				//   label: <FormattedMessage id="Patient.form.patientPrescription.IPLselect" />,
				//   rules: "string|required"
				// },
				supplement: {
					label: <FormattedMessage id="Patient.form.patientPrescription.supplement" />,
					rules: "string|required_if:prescriptionType,RINDIVID"
				},
				production: {
					label: <FormattedMessage id="Patient.form.patientrecord.makeGuide" />,
					rules: "string|required_if:prescriptionType,RINDIVID"
					// required_if: ["prescriptionType", "RINDIVID"]
				},
				useGuide: {
					label: <FormattedMessage id="Patient.form.patientrecord.useGuide" />,
					rules: "string|required_if:prescriptionType,RINDIVID"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
					rules: "required"
				},
				diagnosisCode: {},
				quantization: {
					label: <FormattedMessage id="Patient.form.patientPrescription.quantization" />,
					value: "FIX",
					rules: "required"
				},
				// variableDosage: {
				//   label: " " //<FormattedMessage id="Patient.form.patientPrescription.variabileDosage" />,
				// },
				packs: {
					label: <FormattedMessage id="Patient.form.patientPrescription.packs" />,
					rules: "required|numeric|min:0.01",
					//[0-9]+[\.|\,]+([0-9][0-9]?)?$
					value: 1
				},
				morning: {
					label: <FormattedMessage id="Patient.form.patientPrescription.morning" />,
					rules: "min:0"
				},
				lunch: {
					label: <FormattedMessage id="Patient.form.patientPrescription.lunch" />,
					rules: "min:0"
				},
				evening: {
					label: <FormattedMessage id="Patient.form.patientPrescription.evening" />,
					rules: "min:0"
				},
				sleep: {
					label: <FormattedMessage id="Patient.form.patientPrescription.sleep" />,
					rules: "min:0"
				},
				numberOfTablets: {
					label: <FormattedMessage id="Patient.form.patientPrescription.numberOfTablets" />
				},
				everyHour: {
					label: <FormattedMessage id="Patient.form.patientPrescription.everyHour" />,
					rules: "min:1"
				},
				unit: {
					label: <FormattedMessage id="Common.label.unit" />
				},
				medicationRoute: {
					label: <FormattedMessage id="Patient.form.patientPrescription.medicationRoute" />
				},
				repeatingMoment: {
					label: <FormattedMessage id="Patient.form.patientPrescription.repeatingMoment" />,
					value: "D1"
				},
				days: {
					label: <FormattedMessage id="Patient.form.patientPrescription.days" />,
					rules: "min:1"
				},
				note: {
					label: <FormattedMessage id="Patient.form.patientPrescription.note" />,
					rules: "string"
				},
				ds: {
					label: <FormattedMessage id="Patient.form.patientPrescription.ds" />
				},
				sendingDoctor: {},
				doctorDate: {
					label: <FormattedMessage id="Common.label.date" />,
					type: "date"
				},
				substitutingDoctor: {},
				substitutingDoctorId: {},
				urgent: {
					label: <FormattedMessage id="Patient.form.patientPrescription.urgent" />,
					type: "checkbox"
				},
				pacientPays: {
					label: <FormattedMessage id="Patient.form.patientPrescription.pacientPays" />,
					type: "checkbox"
				},
				opiate: {
					label: <FormattedMessage id="Patient.form.patientPrescription.opiate" />,
					type: "checkbox"
				},
				quantizationRadio: {value: "first"},
				dosage: {
					label: <FormattedMessage id="Patient.form.patientPrescription.numberOfTablets" />
				},
				dosage_type: {},
				flags: {},
				repeats_number: {},
				doctor_id: {},
				personnel_id: {},
				provider_id: {},
				productCode: {
					label: <FormattedMessage id="Patient.form.patientPrescription.IPLselect" />,
					rules: "required"
				},
				type_code: {
					value: "RX"
				},
				components: {},
				sendingDoctorId: {},
				doctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientPrescription.sendingOUPZS" />
				},
				doctorOUPZSId: {},
				substitutingDoctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientPrescription.substitutingDoctorOUPZS" />
				},
				substitutingDoctorOUPZSId: {},
				forcedSave: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.patientPrescription.forcedSave" />
				},
				forcedSaveReason: {
					label: <FormattedMessage id="Patient.form.patientPrescription.forcedSaveReason" />
				},
				replacementProhibited: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.patientPrescription.replacementProhibited" />
				},
				exeptionCheck: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.patientPrescription.exeptionCheck" />
				},
				pacientPaysReason: {
					label: <FormattedMessage id="Patient.form.patientPrescription.pacientPaysReason" />
				},
				sendingDoctorExpertiseId: {},
				sendingDoctorPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientPrescription.sendingDoctor" />
				},
				substitutingExpertiseId: {},
				substitutingPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientrecord.substitutingDoctor" />
				},
				prescriptionBySupplement: {},
				recordId: {},
				version: {},
				firstRadioValidator: {},
				secondRadioValidator: {},
				onlyCategorisedDrugs: {
					label: <FormattedMessage id="Patient.form.patientPrescription.onlyCategorisedDrugs" />,
					type: "checkbox",
					value: true
				},
				validity: {
					label: <FormattedMessage id="Common.label.validity" />,
					type: "date"
				},
				repeatPlain: {
					label: <FormattedMessage id="Common.label.numberOfDays" />
				},
				ingredientsChipsForm: {
					value: "{}"
				},
				prohibitedChipsForm: {
					value: "{}"
				},
				showArea: {
					value: false
				},
				disableRepeat: {
					value: false
				},
				disableOpiateCheckbox: {
					value: false
				},
				showDetails: {
					value: false
				},
				disableType: {
					value: false
				},
				showReplacementProhibited: {
					value: false
				},
				expertiseWarningText: {
					value: ""
				},
				recommendationDateValidity: {
					label: <FormattedMessage id="Common.label.recommendationDateValidity" />
				},
				isDrugCategorized: {
					type: "checkbox"
				},
				drugBaseDoseAmount: {},
				medDevDescription: {
					label: <FormattedMessage id="Common.label.medDevDescription" />
				},
				newBornOnParentIdentifier: {
					label: <FormattedMessage id="Common.label.newBornOnParentIdentifier" />,
					type: "checkbox",
					value: false
				},
				prescriptionReason: {
					label: <FormattedMessage id="Common.label.prescriptionReason" />
				},
				prescriptionNote: {
					label: <FormattedMessage id="Common.label.note" />
				},
				relatedEvents: {}
				// exeptionId:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionId" />,
				//   rules: "required"
				// },
				// exeptionNumber:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionNumber" />,
				//   rules: "required"
				// },
				// exeptionValidityFrom:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionValidityFrom" />,
				//   rules: "required"
				// },
				// exeptionValidityTo:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionValidityTo" />,
				// },
				// exeptionAprovedAmount:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionAprovedAmount" />,
				//   rules: "required"
				// },
				// exeptionUnspentAmount:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionUnspentAmount" />,
				//   rules: "required"
				// },
				// exeptionPrice:{
				//   label: <FormattedMessage id="Patient.form.patientPrescription.exeptionPrice" />,
				//   rules: "required"
				// },
			}
		}
	}
}
