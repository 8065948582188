"use strict"

import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientSicknessAbsStore from "../../../stores/PatientSicknessAbsStore"
import DataStore from "../../../stores/DataStore"

export default {
	load(props, clearStorage) {
		return {
			onSubmit(form) {
				UIStore.isFormSaving = true

				// ak nie je general, kontrola na dlzku cisla a vyplneny datum od
				if (
					form.$("sub_type").get("value") != "General" &&
					form.$("sub_type").get("value") != "OTH" &&
					form.$("number").get("value").length !== PatientSicknessAbsStore.nextNumberLength &&
					isNotSafe(form.$("from").get("value"))
				) {
					form.$("number").invalidate()
				}

				// kontrola deliveries
				let deliveriesChips = JSON.parse(form.$("deliveriesChipsForm").value)
				if (isSafe(deliveriesChips) && deliveriesChips.some((obj) => isEmpty(obj.item) || isEmpty(obj.diagnosis))) {
					form.$("deliveriesChipsForm").invalidate()
					GlobalStore.setNotificationMessage("Common.label.insertDeliveriesDiagnosis")
				}
			},
			onSuccess(form) {
				const translations = {dateFormat: props.intl.formatMessage({id: "Application.moment.dateformat"})}
				PatientSicknessAbsStore.saveForm(
					form,
					DataStore.patientDTO.get("patient"),
					translations /*, deleteFromSession*/,
					() => {
						clearStorage("SICKNESS", false, false)
					}
				)
				UIStore.overviewTab = "sicknessabs"
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
	}
}
