import {observer} from "mobx-react"
import React from "react"
import classNames from "classnames"
import MonthDropdownOptions from "./MonthDropdownOptions"
import {withStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import * as utils from "./helpers"
import {ClickAwayListener} from "@material-ui/core"

const WrappedMonthDropdownOptions = MonthDropdownOptions

const styles = () => ({
	transparent: {
		background: "transparent",
		minWidth: "10px",
		padding: "0px",
		margin: "0px",
		color: "#9ca0a6"
	},
	icon: {
		fontSize: "18px",
		width: "0.8em"
	}
})

@observer
class MonthDropdown extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null
		}
	}

	renderSelectOptions = (monthNames) =>
		monthNames.map((month, i) => (
			<MenuItem key={i} onClick={this.onChange.bind(this, i)}>
				{month}
			</MenuItem>
		))

	renderSelectMode = (monthNames) => (
		<div>
			<Button onClick={this.toggleDropdown} className={classNames(this.props.classes.transparent)}>
				<ArrowDropDown className={classNames(this.props.classes.icon)} />
			</Button>
			<Menu
				id="dropdownButton"
				style={{zIndex: 2001}}
				open={Boolean(this.state.anchorEl)}
				anchorEl={this.state.anchorEl}
				//onClose={this.toggleDropdown}
			>
				<ClickAwayListener onClickAway={() => this.toggleDropdown(null)}>
					<React.Fragment>{this.renderSelectOptions(monthNames)}</React.Fragment>
				</ClickAwayListener>
			</Menu>
		</div>
	)

	renderReadView = (visible, monthNames) => (
		<div
			key="read"
			style={{visibility: visible ? "visible" : "hidden"}}
			className="react-datepicker__month-read-view"
			onClick={this.toggleDropdown}
		>
			<span className="react-datepicker__month-read-view--down-arrow" />
			<span className="react-datepicker__month-read-view--selected-month">{monthNames[this.props.month]}</span>
		</div>
	)

	renderDropdown = (monthNames) => (
		<WrappedMonthDropdownOptions
			key="dropdown"
			//ref="options"
			month={this.props.month}
			monthNames={monthNames}
			onChange={this.onChange}
			onCancel={this.toggleDropdown}
		/>
	)

	renderScrollMode = (monthNames) => {
		let result = [this.renderReadView(!this.state.dropdownVisible, monthNames)]
		if (this.state.dropdownVisible) {
			result.unshift(this.renderDropdown(monthNames))
		}
		return result
	}

	onChange = (month) => {
		this.toggleDropdown()
		if (month !== this.props.month) {
			this.props.onChange(month)
		}
	}

	toggleDropdown = (event) => {
		this.setState({
			anchorEl: event ? event.currentTarget : null
		})
	}

	render() {
		const localeData = utils.getLocaleDataForLocale(this.props.locale)
		const monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
			this.props.useShortMonthInDropdown
				? (M) => utils.getMonthShortInLocale(localeData, utils.newDate({M}))
				: (M) => utils.getMonthInLocale(localeData, utils.newDate({M}), this.props.dateFormat)
		)

		let renderedDropdown
		switch (this.props.dropdownMode) {
			case "scroll":
				//renderedDropdown = this.renderScrollMode(monthNames);
				break
			case "select":
				renderedDropdown = this.renderSelectMode(monthNames)
				break
		}

		return (
			<div
				className={`react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--${this.props.dropdownMode}`}
			>
				{renderedDropdown}
			</div>
		)
	}
}

export default withStyles(styles)(MonthDropdown)
