import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./requestsAdditionalHealthcareFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class RequestsAdditionalHealthcareTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid item xs={12}>
						<XsInput field={this.form.$("nazovSidloLiecebnehoZariadenia")} />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("anamneza")} hideToolbar={true} className="xs-height-200" />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("objektivneVysetrenie")} hideToolbar={true} className="xs-height-200" />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("zaveryOdbornychVysetreni")} hideToolbar={true} className="xs-height-200" />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("epikriza")} hideToolbar={true} className="xs-height-200" />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("diagnostickyZaver")} />
						</Grid>

						<Grid item xs={6}>
							<XsAutocompleteLocal
								field={this.form.$("kodID")}
								minLengthForSearch="3"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kod").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("doterajsiaLiecba")} hideToolbar={true} className="xs-height-200" />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("udajOFajceni")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("poistenecChodiaci")} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("predpokladaneObdobieLiecby")} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("prilohy")} />
					</Grid>

					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datum")} showTimeSelect={false} />
					</Grid>
				</Grid>
			</div>
		)
	}
}
