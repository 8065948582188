"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import Tooltip from "@material-ui/core/Tooltip"
import classNames from "classnames"
import {getUserPersonnelOrgUnits /*, getSelectedOrgUnitResourceID*/} from "../../../../global/helpers/actions"

import PlusIcon from "@material-ui/icons/Add"

import XsButton from "../../../../global/ui/xsButton/xsButton"

import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import CalendarStore from "../../stores/CalendarStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import OtherDayDialogStore from "../../stores/OtherDayDialogStore"
import ExportEventsToXlsDialogStore from "../../stores/ExportEventsToXlsDialogStore"
import TourStore from "../../../../global/store/TourStore"

import ExportEventsToXlsDialog from "./exportEventsToXlsDialog"

import "./xs-calendar.less"

@injectIntl
@observer
export default class CalendarHeader extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {hideCalendarView, hideAddEvent, hideCounter} = this.props

		const previouDataFor =
			UIStore.calendarTimePeriod === "month"
				? "previousMonth"
				: UIStore.calendarTimePeriod === "week"
				? "previousWeek"
				: "previousDay"
		const nextDataFor =
			UIStore.calendarTimePeriod === "month"
				? "nextMonth"
				: UIStore.calendarTimePeriod === "week"
				? "nextWeek"
				: "nextDay"

		return (
			<div className="xs-calendar-header-new">
				<div className="xs-left-side">
					<div className="xs-departments">
						{isSafe(GlobalStore.orgunits) &&
							GlobalStore.orgunits.map((orgunit, idx) => {
								return (
									<div
										key={idx}
										className={`xs-department-tab ${GlobalStore.currentOrgName === orgunit.name ? "xs-active" : ""}`}
										onClick={() => {
											GlobalStore.changeOrgUnit(orgunit)
										}}
									>
										{isNotEmpty(orgunit.name) && orgunit.name.length > 20 ? (
											<Tooltip title={orgunit.name}>
												<div className="xs-cutText">{orgunit.name}</div>
											</Tooltip>
										) : (
											<div className="xs-cutText">{orgunit.name}</div>
										)}
									</div>
								)
							})}
					</div>
					<div className="xs-select-day">
						<div
							data-tip
							data-for={previouDataFor}
							className="xs-previous-day"
							onClick={() => {
								if (UIStore.calendarTimePeriod === "month") {
									CalendarStore.onLoadPeviousMonth()
								} else if (UIStore.calendarTimePeriod === "week") {
									CalendarStore.onLoadPeviousWeek()
								} else {
									OtherDayDialogStore.handleDateTimePickerChange(moment(CalendarStore.selectedDay).add(-1, "days"))
								}
							}}
						>
							<i className="fal fa-chevron-left"></i>
						</div>
						<div className="xs-other-day" onClick={() => OtherDayDialogStore.open()}>
							<span className="xs-day-title">{moment(CalendarStore.selectedDay).format("dddd")},</span>
							<span>{moment(CalendarStore.selectedDay).format("MMM DD, YYYY")}</span>
							<i className="xs-calendar-icon fal fa-calendar-alt" data-tip data-for="showCalendar"></i>
							{!hideCounter && (
								<div className="xs-badge" data-tip data-for="countOrderPatient">
									{CalendarStore.countEvents}
								</div>
							)}
						</div>
						<div
							data-tip
							data-for={nextDataFor}
							className="xs-next-day"
							onClick={() => {
								if (UIStore.calendarTimePeriod === "month") {
									CalendarStore.onLoadNextMonth()
								} else if (UIStore.calendarTimePeriod === "week") {
									CalendarStore.onLoadNextWeek()
								} else {
									OtherDayDialogStore.handleDateTimePickerChange(moment(CalendarStore.selectedDay).add(1, "days"))
								}
							}}
						>
							<i className="fal fa-chevron-right"></i>
						</div>
					</div>
				</div>
				<div className="xs-right-side">
					<div className="xs-export-to-excel mr-3">
						<XsButton
							className="xs-primary"
							icon={<i className="fal fa-file-excel fa-lg" />}
							text={<FormattedMessage id="Common.label.exportToExcel" />}
							onClick={() => ExportEventsToXlsDialogStore.open()}
						/>
					</div>
					{!hideCalendarView && (
						<div className="xs-calendar-view">
							<div
								className={classNames("today", UIStore.calendarTimePeriod === "today" && "xs-active")}
								onClick={() => CalendarStore.setCalendarRange("today", true)}
							>
								<FormattedMessage id="Common.label.today" defaultMessage="Today" />
							</div>
							<div
								className={classNames("week", UIStore.calendarTimePeriod === "week" && "xs-active")}
								onClick={() => {
									CalendarStore.setCalendarRange("week")
									UIStore.weekPlusWeekend = false
								}}
							>
								<FormattedMessage id="Calendar.tabs.week" defaultMessage="Week" />
							</div>
							<Tooltip
								title={this.props.intl.formatMessage({
									id: "Common.label.weekPlusWeekend"
								})}
							>
								<div
									style={UIStore.weekPlusWeekend ? {borderLeft: "1px solid #e5e7ea"} : {}}
									className={classNames(
										"week nowrap weekend",
										UIStore.calendarTimePeriod === "week" && UIStore.weekPlusWeekend && "xs-active"
									)}
									onClick={() => {
										CalendarStore.setCalendarRange("week")
										UIStore.weekPlusWeekend = true
									}}
								>
									<FormattedMessage id="Calendar.tabs.weekPlusWeekend" defaultMessage="Week" />
								</div>
							</Tooltip>
							<div
								className={classNames("month", UIStore.calendarTimePeriod === "month" && "xs-active")}
								onClick={() => CalendarStore.setCalendarRange("month")}
							>
								<FormattedMessage id="Calendar.tabs.month" defaultMessage="Month" />
							</div>
							{getUserPersonnelOrgUnits().length > 1 && (
								<div
									className={classNames("resources", UIStore.calendarTimePeriod === "resources" && "xs-active")}
									onClick={() => CalendarStore.setCalendarRange("resources")}
								>
									MULTI
								</div>
							)}
						</div>
					)}
					{!hideAddEvent && (
						<div className="xs-add-event tourAddEvent">
							<XsButton
								className={classNames({
									"xs-primary": UIStore.calendarTimePeriod != "resources",
									"xs-outline xs-default": UIStore.calendarTimePeriod == "resources"
								})}
								icon={<PlusIcon className="xs-icon" />}
								text={<FormattedMessage id="Calendar.header.addEvent" />}
								disabled={UIStore.calendarTimePeriod == "resources"}
								onClick={() => {
									AddEventDialogStore.open(true)
									if (TourStore.runSaveEvent_tour) {
										TourStore.handleNextSaveEventTour()
									}
									if (TourStore.runSaveNewEvent_tour) {
										TourStore.handleNextSaveNewEventTour()
									}
								}}
							/>
						</div>
					)}
				</div>
				<ReactTooltip id={nextDataFor}>
					<FormattedMessage id={`Common.label.${nextDataFor}`} />
				</ReactTooltip>
				<ReactTooltip id={previouDataFor}>
					<FormattedMessage id={`Common.label.${previouDataFor}`} />
				</ReactTooltip>
				<ReactTooltip id="showCalendar">
					<FormattedMessage id="Common.label.showCalendar" />
				</ReactTooltip>
				<ReactTooltip id="countOrderPatient">
					<FormattedMessage id="Common.label.countOrderPatient" />
				</ReactTooltip>
				<ExportEventsToXlsDialog />
			</div>
		)
	}
}
