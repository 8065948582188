"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"

// import AddIcon from "@material-ui/icons/Add"

import {FormattedMessage, injectIntl} from "react-intl"

import dataBindings from "./patientMedicationBindings"
// import PatientRequestStore from "../../../stores/PatientRequestStore"
import RouterStore from "../../../../../global/store/RouterStore"
import DataStore from "../../../stores/DataStore"
import DDaction from "../../../actions/patientCard"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import api from "../../../../ambulance/actions/api"
import {resetForm, insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../../../global/helpers/actions"

import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {formDataStateCL} from "../../../../../global/config/codeLists"

@injectIntl
@observer
export default class PatientMedicationSaveButtons extends React.Component {
	constructor(props) {
		super(props)
	}

	medicationShortcuts = (e) => {
		if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "medication") {
			//ulozit medication Alt + U
			if (e.altKey && e.keyCode == 85) {
				e.preventDefault()
				this.validateForms(Object.keys(this.props.form), e)
			}

			//pridat novu medikaciu Alt + A
			if (e.altKey && e.keyCode == 65) {
				e.preventDefault()
				this.props.addForm()
			}
		}
	}

	componentDidMount() {
		document.body.addEventListener("keydown", this.medicationShortcuts)
	}

	componentWillUnmount() {
		document.body.removeEventListener("keydown", this.medicationShortcuts)
	}

	validateForms(keys, e) {
		let index = keys[0]

		this.props.form[index].validate().then(({isValid}) => {
			UIStore.isFormSaving = true
			if (isValid && keys.length > 1) {
				keys.shift()
				this.validateForms(keys, e, print)
			} else if (isValid && keys.length == 1) {
				let medication = []

				Object.keys(this.props.form).forEach((key) => {
					let obj = this.props.form[key]
					medication.push(this.getJsonFromForm(obj))
				})

				this.saveMedication(medication)
			} else {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
				return false
			}
		})
	}

	validateForm(print = false) {
		this.form.validate().then(({isValid}) => {
			if (isValid) {
				UIStore.isFormSaving = true

				this.form.$("print").value = print

				let medicationJson = this.getJsonFromForm(this.form)

				this.saveMedication(medicationJson)
			} else {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
				this.invalidForm()
				return false
			}
		})
	}

	getJsonFromForm(form) {
		form.$("doctor_id").value = getUserPersonnelID()
		form.$("personnel_id").value = getUserPersonnelID()
		form.$("provider_id").value = getSelectedOrgUnitID()
		form.$("unit").value = form.$("unit").value ? form.$("unit").value : null
		form.$("medicationRoute").value = form.$("medicationRoute").value ? form.$("medicationRoute").value : "OTH"
		form.$("diagnosis").value =
			form.$("diagnosis").value == "" || isEmpty(form.$("diagnosis").value) ? null : form.$("diagnosis").value

		const medicationJson = insertFormDataByScheme(
			form,
			dataBindings,
			{
				patientId: DataStore.patientDTO.get("patientID"),
				print: form.$("print").value
			},
			() => resetForm(form, dataBindings),
			true
		)

		medicationJson["print"] = form.$("print").value
		medicationJson.version = {
			_id: "final",
			_type: "EHR.CL.Record.Version"
		}

		medicationJson["repeats_type"] = "1"
		medicationJson["instructions_for_preparation"] = null
		medicationJson["instructions_for_use"] = null
		medicationJson["supplement"] = null
		medicationJson["dosage_type"] = "FIX"
		medicationJson["dosage"] = null
		medicationJson["dose_periodicity"] = "1"
		medicationJson["duration_mins"] = null
		medicationJson["note"] = null
		medicationJson["transcription"] = null
		medicationJson["flags"] = null
		medicationJson["doctor_id"] = form.$("doctor_id").value
		medicationJson["provider_id"] = form.$("provider_id").value
		medicationJson["personnel_id"] = form.$("personnel_id").value
		medicationJson["repeats_number"] = null
		medicationJson["sending_doctor_id"] = null
		medicationJson["sent_at"] = null
		medicationJson["sending_provider_id"] = null
		medicationJson["substituting_doctor_id"] = null
		medicationJson["administered_at"] = form.$("dateOfDelivery").value

		return medicationJson
	}

	saveMedication(medications) {
		api
			.savePrescription(medications)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Common.label.medicationSaveSuccess")
				UIStore.patientCardTab = ""
				UIStore.isFormSaving = false
				UIStore.overviewTab = "overview"
				const providerID = getSelectedOrgUnitID()
				DataStore.setFormDataState("PatientRequestForm", formDataStateCL.EMPTY)
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
					(overview) => {
						DataStore.setPatientEHR(overview)
						window.scrollTo(0, 0)

						this.props.options.onClose("MEDICATION")
						// this.props.clearAllReqeust()

						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					}
				)
			})
	}

	checkMedicationVersion = () => {
		let tmp = false

		if (isSafe(this.form) && this.form.$("version").get("value") == "draft") tmp = true

		return tmp
	}

	render() {
		return (
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
					type="submit"
					onClick={(e) => {
						// this.validateForm()
						this.validateForms(Object.keys(this.props.form), e)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Common.label.addMedication" />}
					onClick={() => {
						this.props.addForm()
					}}
					icon={<AddIcon />}
				/>
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						this.props.options.onClose("MEDICATION", false, true)
						/*this.props.clearAllReqeust()
						UIStore.patientCardTab = ""*/
					}}
					icon={<CancelIcon />}
				/>
			</div>
		)
	}
}
