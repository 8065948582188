import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
// import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import ExportInvoicesDialogStore from "../../../stores/ExportInvoicesDialogStore"
import RouterStore from "../../../../../global/store/RouterStore"
import UIStore from "../../../stores/UIStore"

@injectIntl
@observer
export default class ExportInvoicesDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	getInsuranceType = (itId) => {
		let res = ""
		switch (itId) {
			case "GH":
				res = "VZP"
				break
			case "EU":
				res = "EU"
				break
			case "HMLS":
				res = "BEZD"
				break
			case "FRGN":
				res = "CUDZ"
				break
			case "OTH":
				res = "INE"
		}

		return res
	}

	renderInputs = () => {
		let inputs = []

		ExportInvoicesDialogStore.invoices.forEach((inv) => {
			let insType = ""
			if (isSafe(inv.org_units) && inv.org_units.length > 0) {
				insType = isSafe(inv.org_units[0].insurance_types)
					? inv.org_units[0].insurance_types.map((x) => this.getInsuranceType(x._id)).join(", ")
					: ""

				if (inv.org_units[0].include_capitations) {
					insType += isEmpty(insType) ? "KAP" : ", KAP"
				}
			}

			inputs.push(
				<Grid item xs={6} key={inv.item_order}>
					<TextField
						InputLabelProps={{shrink: true}}
						label={<span className="xs-bold">{`Fa ${inv.item_order} (${inv.insurer.name_ext} - ${insType})*`}</span>}
						value={
							isSafe(ExportInvoicesDialogStore.inputs[inv.item_order])
								? ExportInvoicesDialogStore.inputs[inv.item_order]
								: ""
						}
						onChange={(e) => (ExportInvoicesDialogStore.inputs[inv.item_order] = e.target.value)}
						InputProps={{
							classes: {
								root: isEmpty(ExportInvoicesDialogStore.inputs[inv.item_order]) ? "xs-input-error" : ""
							}
						}}
					/>
				</Grid>
			)
		})

		return inputs
	}

	render() {
		return (
			<div>
				<Dialog
					id="xsExportInvoicesDialog"
					open={ExportInvoicesDialogStore.isOpen}
					onClose={() => ExportInvoicesDialogStore.close()}
					className="xs-base-dialog"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-900"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div className="xs-absolute xs-close-btn" onClick={() => ExportInvoicesDialogStore.close()}>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-save fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.label.exportInvoices" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content overflowY">
						{ExportInvoicesDialogStore.invoices.length > 0 ? (
							<Grid container spacing={8}>
								<Grid item xs={12}>
									<XsPeriodPicker
										required
										onChange={(val) => {
											ExportInvoicesDialogStore.dateFrom = val.from
											ExportInvoicesDialogStore.getDrafts()
										}}
									/>
								</Grid>
								{this.renderInputs()}
								<Grid item container>
									<Grid item xs={6}>
										<TextField
											InputLabelProps={{shrink: true}}
											label={<FormattedMessage id="Login.label.pass" />}
											value={ExportInvoicesDialogStore.password}
											onChange={(e) => (ExportInvoicesDialogStore.password = e.target.value)}
										/>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid container direction="column" spacing={8}>
								<Grid item xs={12} className="xs-bold">
									Nemáte nastavenie pre automatické generovanie faktúr!
								</Grid>
								<Grid item>
									Nastavenie môžete vykonať v záložke <span className="xs-bold">Nastavenia</span> po kliku na tlačidlo{" "}
									<span className="xs-bold">Nastavenie faktúr a dávok</span>
								</Grid>
							</Grid>
						)}
					</DialogContent>
					<DialogActions className="xs-footer">
						<Grid container justify="space-between" alignItems="center">
							<Grid item xs={7}>
								{isNotEmpty(ExportInvoicesDialogStore.warningMessage) && (
									<Grid container wrap="nowrap" alignItems="center">
										<Grid item className="pr-2">
											<i className="fas fa-exclamation-triangle fa-2x amberWarning"></i>
										</Grid>
										<Grid item className="amberWarning">
											<FormattedMessage id={ExportInvoicesDialogStore.warningMessage} />
											<span
												className="pl-1 xs-pointer"
												onClick={() => {
													RouterStore.push("/reports")
													UIStore.reportDetailTab = "draftReport"
													ExportInvoicesDialogStore.close()
												}}
											>
												TU!
											</span>
										</Grid>
									</Grid>
								)}
							</Grid>
							<Grid item xs={5}>
								<Grid item container justify="flex-end">
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											onClick={() => {
												ExportInvoicesDialogStore.close()
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<i className="fal fa-times fa-lg" />}
										/>
									</Grid>
									{ExportInvoicesDialogStore.invoices.length > 0 && (
										<Grid item className="pl-2">
											<XsButton
												className="xs-success"
												type="submit"
												onClick={() => {
													ExportInvoicesDialogStore.exportInvoices()
												}}
												text={<FormattedMessage id="Common.label.exportData" />}
												icon={<i className="fal fa-save fa-lg" />}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
