"use strict"

import {observable, action} from "mobx"
import UIStore from "./UIStore"
import DrugInteractionDialogStore from "./DrugInteractionsDialogStore"
import PatientPrescriptionStore from "./PatientPrescriptionStore"
// import PatientPrescriptionContainerStore from "./PatientPrescriptionContainerStore"

class ConfirmInteractionDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable confirm = false

	formData = null
	recordIds = null

	@action open(warning) {
		if (isSafe(warning) && isNotEmpty(warning)) {
			this.isOpen = true
			this.warning = warning
		} else {
			DrugInteractionDialogStore.cancel()
			// PatientPrescriptionContainerStore.clearPrescriptions()
		}
	}

	@action cancel() {
		this.isOpen = false
		// PatientPrescriptionContainerStore.setFormIds(this.recordIds)
		// this.recordId = null
		// PatientPrescriptionContainerStore.clearPrescriptions()
	}

	@action close() {
		// PatientPrescriptionContainerStore.clearPrescriptions()
		this.recordId = null
		// this.formData.reset()
		// this.formData = null
		PatientPrescriptionStore.prohibitedChips = {}
		PatientPrescriptionStore.ingredientsChips = {}
		PatientPrescriptionStore.doctorOUPZSItems = {}
		PatientPrescriptionStore.substitutingDoctorOUPZSItems = {}
		this.isOpen = false
		logger("ZATVARAM INTERAKCIE")
		DrugInteractionDialogStore.cancel()
		UIStore.patientCardTab = ""
		UIStore.overviewTab = "medication"
	}
}

var singleton = new ConfirmInteractionDialogStore()
export default singleton
