import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../xsButton/xsButton"
import ErrorStore from "../../store/ErrorStore"

import "./xsDialog.less"

export default injectIntl(
	observer((props) => {
		return (
			<Dialog open={ErrorStore.isOpen} onEscapeKeyDown={() => ErrorStore.close()} className="xs-error-dialog">
				<DialogTitle className="xs-danger">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-exclamation-circle fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Application.errorText" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isSafe(ErrorStore.response) &&
						isSafe(ErrorStore.response.errors) &&
						ErrorStore.response.errors.length > 0 &&
						ErrorStore.response.errors.map((error, idx) => {
							const customErrorMsg = error.code ? props.intl.formatMessage({id: "Application.error." + error.code}) : ""
							if (isSafe(error.description) && isNotEmpty(error.description)) {
								return (
									<div key={idx}>
										<div className="xs-error-description">
											{customErrorMsg && <div className="translated-error">{customErrorMsg}</div>}
											{error.description}
										</div>
										{isSafe(ErrorStore.response.summary) && isNotEmpty(ErrorStore.response.summary) && (
											<div>({ErrorStore.response.summary})</div>
										)}
										<br />
									</div>
								)
							} else {
								if (isSafe(ErrorStore.response.summary) && isNotEmpty(ErrorStore.response.summary)) {
									return (
										<div key={idx}>
											{customErrorMsg && <div className="translated-error">{customErrorMsg}</div>}(
											{ErrorStore.response.summary})
											<br />
										</div>
									)
								}
							}
						})}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-danger"
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => ErrorStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	})
)
