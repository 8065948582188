export const codeListsConfig = [
	{
		uri: "/cl/Entity.CL.Country",
		name: "countries"
	},
	{
		uri: "/cl/Entity.CL.Title",
		name: "titles"
	},
	{
		uri: "/cl/CRM.CL.ConsentType",
		name: "consentTypes"
	},
	{
		uri: "/cl/EHR.CL.Severity",
		name: "severities"
	},
	{
		uri: "/cl/Entity.CL.Allergen",
		name: "allergens"
	},
	{
		uri: "/cl/EHR.CL.Entity.Symptom",
		name: "symptoms"
	},
	{
		uri: "/cl/Entity.CL.Gender",
		name: "genders"
	},
	{
		uri: "/cl/EHR.CL.Record.Unit",
		name: "units"
	},
	{
		uri: "/cl/Entity.CL.EmployeeCategory",
		name: "employeeCategories"
	},
	{
		uri: "/cl/EHR.CL.Record.DiagnosisType",
		name: "diagnoseTypes"
	},
	{
		uri: "/cl/EHR.CL.SicknessAbsence.Type",
		name: "sicknessAbsenceTypes"
	},
	{
		uri: "/cl/Entity.CL.ContactType",
		name: "contactTypes"
	},
	{
		uri: "/cl/EHR.CL.Medication.Unit",
		name: "medicationTypes"
	},
	{
		uri: "/cl/EHR.CL.Medication.Route",
		name: "medicationRoutes"
	},
	{
		uri: "/cl/EHR.CL.Patient.Location",
		name: "patientLocations"
	},
	{
		uri: "/amb/ehr/cl/repeatingmoment",
		name: "medicationRepeats"
	},
	{
		uri: "/cl/EHR.CL.Medication.DosageType",
		name: "medicationDosageTypes"
	},
	{
		uri: "/cl/EHR.CL.Medication.SubType",
		name: "medicationSubTypes"
	},
	{
		uri: "/cl/EHR.CL.Medication.DoseUnit",
		name: "medicationDoseUnits"
	},
	{
		uri: "/cl/EHR.CL.Medication.SK.Preparative",
		name: "IVLs"
	},
	{
		uri: "/cl/EHR.CL.Medication.Vaccination.Symptom",
		name: "vaccinationSymptoms"
	},
	{
		uri: "/cl/EHR.CL.Medication.Vaccination.Type",
		name: "vaccinationTypes"
	},
	{
		uri: "/cl/EHR.CL.Record.Priority",
		name: "priorities"
	},
	{
		uri: "/cl/EHR.CL.Patient.InsuranceType",
		name: "insuranceTypes"
	},
	{
		uri: "/cl/IXS.CL.Data.TagType||Patient",
		name: "patientTagTypes"
	},
	{
		uri: "/cl/IXS.CL.Data.TagGroup",
		name: "patientTagGroups"
	},
	{
		uri: "/cl/EHR.CL.Record.Measure",
		name: "recordMeasures" //AM;VITA
	},
	{
		uri: "/cl/EHR.CL.Entity.PersonnelCategory",
		name: "personnelCategory"
	},
	{
		uri: "/cl/Entity.CL.EmployeeCategory",
		name: "employeeCategory"
	},
	{
		uri: "/cl/EHR.CL.Contract.AttributeType",
		name: "attributeType"
	},
	{
		uri: "/cl/Fin.CL.InvoiceType",
		name: "invoiceType"
	},
	{
		uri: "/cl/IXS.CL.Data.CurrencyUnit",
		name: "currencyUnit"
	},
	{
		uri: "/cl/EHR.CL.Record.GeneralSubItemSubType",
		name: "generalSubItemType"
	},
	{
		uri: "/cl/EHR.CL.Record.Delivery.InsureeType",
		name: "insureeType"
	},
	{
		uri: "/cl/EHR.CL.Record.SummaryType",
		name: "summaryType"
	},
	{
		uri: "/cl/EHR.CL.Record.Delivery.RefundType",
		name: "refundType"
	},
	{
		uri: "/cl/IXS.CL.Data.Object.Owner",
		name: "externalSystems"
	},
	{
		uri: "/cl/Entity.CL.RelationshipType",
		name: "relationshipType"
	},
	{
		uri: "/cl/EHR.CL.Bill.Delivery.Extension",
		name: "deliveryExtension"
	},
	{
		uri: "/cl/Entity.CL.Address.County",
		name: "addressCounty"
	},
	{
		uri: "/cl/Entity.CL.Address.Region",
		name: "addressRegion"
	},
	{
		uri: "/cl/EHR.CL.Record.Delivery.SenderType",
		name: "senderType"
	},
	{
		uri: "/cl/Entity.CL.SpecificRelRelationship.EndReason",
		name: "endReason"
	},
	{
		uri: "/cl/EHR.CL.Medication.ImplantGroup",
		name: "implantGroup"
	},
	{
		uri: "/cl/EHR.CL.ATC.Classification",
		name: "classification"
	},
	{
		uri: "/cl/EHR.CL.ConfidentialityLevel",
		name: "confidentialityLevel"
	},
	{
		uri: "/cl/EHR.CL.Record.Version",
		name: "recordVersion"
	},
	{
		uri: "/cl/EHR.CL.Patient.BloodType",
		name: "bloodType"
	},
	{
		uri: "/cl/Entity.CL.OrgUnitParamType",
		name: "orgUnitParamType"
	},
	{
		uri: "/cl/EHR.CL.ProfessionalActivity",
		name: "professionalActivity"
	},
	{
		uri: "/cl/Fin.CL.BankAccountType",
		name: "bankAccountType"
	},
	{
		uri: "/cl/Entity.CL.AddressType",
		name: "addressType"
	},
	{
		uri: "/cl/Service.CL.EventStatus",
		name: "eventStatus"
	},
	{
		uri: "/amb/ehr/cl/deliveryitem",
		name: "deliveryItem"
	},
	{
		uri: "/cl/EHR.CL.Baths.IndicativeGroup",
		name: "indicativeGroup"
	},
	{
		uri: "/cl/EHR.CL.Record.Delivery.PayerType",
		name: "payerType"
	},
	{
		uri: "/amb/ehr/cl/administrationroute/prescradm",
		name: "medicationRoutesPrescription"
	},
	{
		uri: "/amb/ehr/cl/administrationroute/medadm",
		name: "medicationRoutesMedication"
	},
	{
		uri: "/amb/ehealth/cl/dzpelabexamtag",
		name: "dzpelabexamtag"
	},
	{
		uri: "/cl/EHR.CL.Patient.EndReason",
		name: "endReasonPat"
	},
	{
		uri: "/cl/EHR.CL.Record.Disease",
		name: "disease"
	}
]

//Číselník stavov dát formulára
export const formDataStateCL = {
	EMPTY: "EMPTY",
	WITHDEFS: "WITHDEFS",
	MODIFIED: "MODIFIED"
}
