"use strict"

import {observable} from "mobx"

class ContactFormStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new ContactFormStore()
export default singleton
