"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import Tooltip from "@material-ui/core/Tooltip"
import {observer} from "mobx-react"
import classnames from "classnames"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./deliveryListFilterFormFields"
import DoneIcon from "@material-ui/icons/Done"
import EditIcon from "@material-ui/icons/Create"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import {
	TableHead,
	TableSortLabel,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	InputAdornment,
	TextField,
	Grid
} from "@material-ui/core"

import api from "../../../actions/api"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DeliveryStore from "../../../stores/DeliveryStore"
import WarningStore from "../../../../../global/store/WarningStore"
import UIStore from "../../../stores/UIStore"
import RouterStore from "../../../../../global/store/RouterStore"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
// import {padStart} from "../../../../../global/helpers/functions"
import EnterInvoiceNumberDialog from "./enterInvoiceNumberDialog"
import InvoiceEnterDialogStore from "../../../stores/InvoiceEnterDialogStore"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUserAccessRights} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import TablePaginationActions from "../../../../../global/ui/xsTableServer/xsTablePaginationActions"
import {StorageBase} from "../../../../../global/storage/storageEx"
import GenerateInvoiceDialogStore from "../../../stores/GenerateInvoiceDialogStore"
import ExportDeliveriesDataStore from "../../../stores/ExportDeliveriesDataStore"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import ExportDeliveriesToXlsDialog from "./exportDeliveriesToXlsDialog"
import ExportToXlsDialogStore from "../../../stores/ExportToXlsDialogStore"
import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import AddDeliveryStore from "../../../stores/AddDeliveryStore"
import AddDeliveryDialog from "../../patientDetail/patientDelivery/addDeliveryDialog"
import ExportRepairBatchDialog from "./exportRepairBatchDialog"
import ExportRepairBatchStore from "../../../stores/ExportRepairBatchStore"

@injectIntl
@observer
export default class DeliveryList extends React.Component {
	constructor(props) {
		super(props)

		this.reloadGrid = true

		this.state = {
			page: 0,
			rowsPerPage: 10,
			order: false,
			orderBy: null,
			sortType: ""
		}

		const hooks = {
			onSubmit(form) {
				logger("submit", form.values())
			},
			onSuccess(form) {
				logger("success", form.values())
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		DeliveryStore.currFilters = this.form.values()
		DeliveryStore.filterRef = this.form
	}

	componentDidUpdate() {
		this.reloadGrid = false
	}

	handleStorageLoad = (storageFilters) => {
		this.form.$("person_identifier").value = storageFilters.person_identifier
		this.form.$("diagnosis").value = storageFilters.diagnosis
		this.form.$("insurer").value = storageFilters.insurer
		this.form.$("insurence_type").value = storageFilters.insurance_type

		DeliveryStore.getFilters()

		if (isNotEmpty(storageFilters.time_from)) {
			let year = +storageFilters.time_from.split("-")[0]
			let month = +storageFilters.time_from.split("-")[1] - 1
			this.periodPickerRef.refs.wrappedInstance.setValues(year, month)
		}
		if (isNotEmpty(storageFilters.diagnosis)) {
			DeliveryStore.loadSpecificDiagnosis(storageFilters.diagnosis, (data) => {
				this.form.$("diagnosis").value = data._id
			})
		}
	}

	componentDidMount() {
		let storageFilters = StorageBase.getObjectByKey("deliveryListFilters")
		if (isSafe(storageFilters)) {
			this.handleStorageLoad(storageFilters)
		} else {
			DeliveryStore.resetGrid()
		}
	}

	UNSAFE_componentWillReceiveProps() {
		this.reloadGrid = true
		this.setState({page: 0})
	}

	handleChangePage = (event, page) => {
		if (!DeliveryStore.isEditable) {
			this.setState({
				page
			})
		}
	}

	handleChangeRowsPerPage = (event) => {
		if (!DeliveryStore.isEditable) {
			this.setState({
				rowsPerPage: event.target.value
			})
		}
	}

	createSortHandler = (property) => (event) => {
		this.handleRequestSort(event, property.columnName, property.type)
	}

	handleRequestSort = (event, property, type) => {
		this.reloadGrid = true
		const orderBy = property
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		this.setState({order, orderBy, sortType: type})
	}

	stringComparer = (a, b, direction) => {
		let result
		switch (direction) {
			case "asc":
				result = (isSafe(a) ? a : "").localeCompare(isSafe(b) ? b : "")
				break
			case "desc":
				result = (isSafe(b) ? b : "").localeCompare(isSafe(a) ? a : "")
				break
			default:
				result = 0
		}
		return result
	}

	getInsuranceTooltip = (insurance_type_id) => {
		if (insurance_type_id == "GH") {
			return "Common.label.insuranceVZP"
		}
		if (insurance_type_id == "EU") {
			return "Common.label.insuranceEU"
		}
		if (insurance_type_id == "FRGN") {
			return "Common.label.foreigner"
		}
	}

	getInsuranceCodeExt = (insuranceTypeId) => {
		let insType = GlobalStore.CL["insuranceTypes"].filter((x) => x.code == insuranceTypeId)

		if (isSafe(insType) && insType.length > 0) {
			return ` (${insType[0].code_ext})`
		} else {
			return ""
		}
	}

	tableBodyRows
	tableBody() {
		const {page, rowsPerPage, order, orderBy, sortType} = this.state

		let dataSource = DeliveryStore.deliveryList

		if (isSafe(orderBy)) {
			switch (sortType) {
				case "string":
					dataSource = dataSource.slice().sort((a, b) => this.stringComparer(a[orderBy], b[orderBy], order))
					break
				case "number":
					dataSource = dataSource.slice().sort((a, b) => this.numberComparer(a[orderBy], b[orderBy], order))
					break
				case "datetime":
					dataSource = dataSource.slice().sort((a, b) => this.dateComparer(a[orderBy], b[orderBy], order))
					break
			}
		}

		DeliveryStore.isValidGrid = true

		this.tableBodyRows = []
		{
			if (isSafe(dataSource) && dataSource.length > 0) {
				dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((currRow, idx) => {
					if (!DeliveryStore.removeDeliveryIds.includes(currRow._id)) {
						let row = currRow
						if (DeliveryStore.changedData[currRow._id]) {
							row = Object.assign({}, currRow, DeliveryStore.changedData[row._id])
						}
						let countSubItemsPerRow = 0
						let colorSubItemsPerRow = "#E0E5EF"

						if (DeliveryStore.changedKeysTest.hasOwnProperty(row._id)) {
							colorSubItemsPerRow = "#8BC34A"
							let isValid = true

							if (!isNumber(row.count) || isEmpty(row.diagnosis_code) || isEmpty(row.item_code_ext)) {
								isValid = false
							}
							Object.keys(DeliveryStore.changedKeysTest[row._id]).forEach((ppId) => {
								if (
									!isPrice(DeliveryStore.subItems[row._id][ppId].unit_price) ||
									!isNumber(DeliveryStore.subItems[row._id][ppId].quantity)
								) {
									isValid = false
								}

								if (
									isSafe(DeliveryStore.subItems[row._id][ppId].sub_type) &&
									(DeliveryStore.subItems[row._id][ppId].sub_type === "adidrug" ||
										DeliveryStore.subItems[row._id][ppId].sub_type === "adidev" ||
										DeliveryStore.subItems[row._id][ppId].sub_type === "adicntr")
								) {
									if (isEmpty(DeliveryStore.subItems[row._id][ppId].drugs)) {
										isValid = false
									}
								} else {
									if (isEmpty(DeliveryStore.subItems[row._id][ppId].subItemType)) {
										isValid = false
									}
								}
							})
							if (!isValid) {
								colorSubItemsPerRow = "#E91E63"
								DeliveryStore.isValidGrid = false
							}
							if (DeliveryStore.subItems.hasOwnProperty(row._id)) {
								countSubItemsPerRow = Object.keys(DeliveryStore.subItems[row._id]).length
							}
						} else {
							countSubItemsPerRow = row.sub_items.length
						}
						// }
						// mapper flat napisat, osetrit nulovanie pri cancel a save a pri  search dat flag na renderovanie kvoli novemu riadku
						this.tableBodyRows.push(
							<TableRow key={idx}>
								{!DeliveryStore.isEditable && (
									<TableCell width="50px">
										<Checkbox
											checked={ExportRepairBatchStore.selectedIds.includes(row._id)}
											// classes={{
											// 	root: "checkboxInTable"
											// }}
											className="checkboxInTable"
											onChange={(e) => {
												if (e.target.checked) {
													ExportRepairBatchStore.selectedIds.push(row._id)
												} else {
													ExportRepairBatchStore.selectedIds = ExportRepairBatchStore.selectedIds.filter(
														(x) => x != row._id
													)
												}
											}}
										/>
									</TableCell>
								)}
								<TableCell width="100px">
									{isSafe(row.created_at) ? moment(row.created_at).format("DD.MM.YYYY") : ""}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<React.Fragment>
											<div>{row.person_name + " " + this.getInsuranceCodeExt(row.insurance_type_id)}</div>
											<div>{row.person_identifier}</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div
												className="blueDark xs-bold xs-pointer"
												onClick={() => RouterStore.push(`/patient?id=${row.person_id}`)}
											>
												<div>
													{row.person_name}
													{row.insurance_type_id == "GH" ||
													row.insurance_type_id == "EU" ||
													row.insurance_type_id == "FRGN" ? (
														<Tooltip
															title={this.props.intl.formatMessage({
																id: this.getInsuranceTooltip(row.insurance_type_id)
															})}
														>
															<span className="xs-insurer">{this.getInsuranceCodeExt(row.insurance_type_id)}</span>
														</Tooltip>
													) : (
														<span className="xs-insurer"> {this.getInsuranceCodeExt(row.insurance_type_id)}</span>
													)}
												</div>
												<div>{row.person_identifier}</div>
											</div>
										</React.Fragment>
									)}
								</TableCell>
								<TableCell>{row.person_age}</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsInputSearchFilter
											api={api.loadDiagnosisCl}
											minLengthForSearch={3}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) => delayedCallback(500, () => DeliveryStore.searchDiagnosis(value, idx))}
											data={DeliveryStore.diagnosis[idx]}
											onSearchClose={() => (DeliveryStore.diagnosis[idx] = [])}
											defaultValue={row.diagnosis_code + " " + row.diagnosis_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											chooseItem={(value) => DeliveryStore.changeDelivery("diagnosis_name_ext", row._id, value._id)}
											modalTitle={<FormattedMessage id="Common.label.diagnosis" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										row.diagnosis_code + " " + row.diagnosis_name_ext
									) //row.diagnosis_name_ext
									}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsInputSearchFilter
											api={api.loadDeliveries}
											minLengthForSearch={1}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) => delayedCallback(500, () => DeliveryStore.searchDeliveries(value, idx))}
											data={DeliveryStore.deliveries[idx]}
											onSearchClose={() => (DeliveryStore.deliveries[idx] = [])}
											defaultValue={row.item_code_ext + " " + row.item_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.name_ext
											}}
											chooseItem={(value) => DeliveryStore.changeDelivery("item_code_ext", row._id, value._id)}
											modalTitle={<FormattedMessage id="Delivery.list.modal.delivery.title" />}
											modalConfig={(textValue, clickHandler) => {
												// logger("textValue: ", textValue)
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										row.item_code_ext + " " + row.item_name_ext
									)}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={DeliveryStore.senderTypeCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={DeliveryStore.senderTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													DeliveryStore.changeDelivery("sender_type", row._id, item._id)
													DeliveryStore.senderTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{DeliveryStore.senderJSON[row.sender_type]}</span>
									)}
								</TableCell>
								<TableCell width="75px">
									{DeliveryStore.isEditable ? (
										<TextField
											className="xs-count"
											InputProps={{
												classes: {
													root: !isNumber(row.count) ? "xs-input-error xs-input" : "xs-input"
												}
											}}
											value={row.count}
											onChange={(e) => DeliveryStore.changeDelivery("count", row._id, e.target.value)}
										/>
									) : (
										<div>{row.count}</div>
									)}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={DeliveryStore.payerTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={DeliveryStore.payerTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													DeliveryStore.changeDelivery("payer_type", row._id, item._id)
													DeliveryStore.payerTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<div>{DeliveryStore.payerJSON[row.payer_type]}</div>
									)}
								</TableCell>
								<TableCell width="100px">{row.price_in_points}</TableCell>
								<TableCell width="100px">{row.price_in_currency}</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={DeliveryStore.insureeTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={DeliveryStore.insureeTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													DeliveryStore.changeDelivery("insuree_type", row._id, item._id)
													DeliveryStore.insureeTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{DeliveryStore.insureeJSON[row.insuree_type]}</span>
									)}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={DeliveryStore.refundTypesCL}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={DeliveryStore.refundTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													DeliveryStore.changeDelivery("refund_type", row._id, item._id)
													DeliveryStore.refundTypesJSON[row._id] = item._id
												}
											}}
										/>
									) : (
										<span className="xs-no-wrap">{DeliveryStore.refundJSON[row.refund_type]}</span>
									)}
								</TableCell>
								<TableCell>
									{DeliveryStore.isEditable ? (
										<TextField
											className="xs-movement"
											InputProps={{
												classes: {
													root: "xs-input"
												}
											}}
											value={row.movement}
											onChange={(e) => DeliveryStore.changeDelivery("movement", row._id, e.target.value)}
										/>
									) : (
										<div>{row.movement}</div>
									)}
								</TableCell>
								{DeliveryStore.isEditable && (
									<TableCell width="40px" className="xs-align-text">
										<Tooltip
											title={this.props.intl.formatMessage({
												id:
													countSubItemsPerRow > 0
														? "Common.label.removeDeliveryContainsPP"
														: "Common.label.removeDelivery"
											})}
										>
											<i
												className={`fal fa-trash-alt fa-lg ${countSubItemsPerRow == 0 && "xs-redDanger"}`}
												onClick={() => (countSubItemsPerRow > 0 ? null : DeliveryStore.removeDelivery(row._id))}
											/>
										</Tooltip>
									</TableCell>
								)}
								<TableCell width="40px" className="xs-align-text">
									<XsIconButton
										className="xs-default xs-outline"
										style={{borderColor: colorSubItemsPerRow, backgroundColor: colorSubItemsPerRow, color: "#21212F"}}
										icon={countSubItemsPerRow}
										onClick={() => {
											DeliveryStore.createSubItemPP(row)
										}}
									/>
								</TableCell>
							</TableRow>
						)
						// pridavanie riadku do gridy vo vynimocnych pripadoch!!!!, ak sa vyberie vykon ktoremu treba doplnujuce udaje
						if (
							DeliveryStore.idSubItemsRow === row._id &&
							isSafe(DeliveryStore.subItems) &&
							isSafe(DeliveryStore.subItems[row._id])
						) {
							const countSubItems = Object.keys(DeliveryStore.subItems[row._id]).length
							this.tableBodyRows.push(
								<TableRow key={idx + 10000}>
									<TableCell colSpan={12}>
										<Grid container direction="column">
											{Object.keys(DeliveryStore.subItems[row._id]).map((key, index) => {
												return (
													<Grid item xs={12} key={`${idx}_${index}`}>
														<Grid container direction="row" spacing={8} alignItems="center">
															<Grid item xs={2}>
																<XsDropdownList
																	disabled={!DeliveryStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Type" />*
																		</span>
																	}
																	items={DeliveryStore.subItemSubTypesCL}
																	renderer={(item) => {
																		return <span>{item.name_ext}</span>
																	}}
																	column="_id"
																	value={
																		isSafe(DeliveryStore.subItems[row._id][key].sub_type)
																			? DeliveryStore.subItems[row._id][key].sub_type
																			: "adigen"
																	}
																	chooseItem={(item) => {
																		{
																			DeliveryStore.changeSubItem("sub_type", row._id, key, item._id)
																		}
																	}}
																	onChange={() => {
																		if (isSafe(DeliveryStore.subItems[row._id][key].unit_price)) {
																			DeliveryStore.subItems[row._id][key].unit_price = ""
																		}
																		if (isSafe(DeliveryStore.subItems[row._id][key].quantity)) {
																			DeliveryStore.subItems[row._id][key].quantity = 1
																		}
																		if (isSafe(DeliveryStore.subItems[row._id][key].drugs)) {
																			DeliveryStore.subItems[row._id][key].drugs = ""
																		}
																		if (isSafe(DeliveryStore.subItems[row._id][key].drugsName)) {
																			DeliveryStore.subItems[row._id][key].drugsName = ""
																		}
																		if (isSafe(DeliveryStore.subItems[row._id][key].description)) {
																			DeliveryStore.subItems[row._id][key].description = ""
																		}
																		if (isSafe(DeliveryStore.subItems[row._id][key].subItemType)) {
																			DeliveryStore.subItems[row._id][key].subItemType = ""
																		}
																	}}
																/>
															</Grid>
															{isSafe(DeliveryStore.subItems[row._id][key].sub_type) &&
																DeliveryStore.subItems[row._id][key].sub_type === "adidrug" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadDrug}
																			disabled={!DeliveryStore.isEditable}
																			isValid={isEmpty(DeliveryStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => DeliveryStore.searchDrugs(value, key))
																			}
																			data={DeliveryStore.drugs[key]}
																			onSearchClose={() => (DeliveryStore.drugs[key] = [])}
																			defaultValue={
																				isSafe(DeliveryStore.subItems[row._id][key].drugs)
																					? DeliveryStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			// inputRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			inputRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			// valueRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			valueRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			chooseItem={(value) => {
																				DeliveryStore.changeSubItem("drugs", row._id, key, value._id)
																				if (isNotEmpty(value.insurer_bs_unit_price)) {
																					DeliveryStore.subItems[row._id][key].unit_price = value.insurer_bs_unit_price
																					DeliveryStore.subItems[row._id][key].adorment = value.bs_unit
																				} else {
																					DeliveryStore.subItems[row._id][key].unit_price = ""
																				}
																			}}
																			onClear={() => {
																				DeliveryStore.subItems[row._id][key].unit_price = ""
																				DeliveryStore.subItems[row._id][key].drugsName = ""
																				DeliveryStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.drugs" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "90px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "150px"
																							}
																						},
																						patient_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
																							),
																							type: "string",
																							dbName: "patient_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						insurer_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
																							),
																							type: "string",
																							dbName: "insurer_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						atc: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />
																							),
																							type: "string",
																							dbName: "atc",
																							design: {
																								width: "90px"
																							}
																						}
																					},
																					filterValue: [
																						{
																							associated_column: "only_bsu",
																							predicate: "=",
																							values: [
																								{
																									id_value: 1
																								}
																							]
																						}
																					],
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						},
																						renderHeaderAsFirstRow: true
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(DeliveryStore.subItems[row._id][key].sub_type) &&
																DeliveryStore.subItems[row._id][key].sub_type === "adidev" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadMedDev}
																			disabled={!DeliveryStore.isEditable}
																			isValid={isEmpty(DeliveryStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => DeliveryStore.searchMedicalDevices(value, key))
																			}
																			data={DeliveryStore.medicalDevices[key]}
																			onSearchClose={() => (DeliveryStore.medicalDevices[key] = [])}
																			defaultValue={
																				isSafe(DeliveryStore.subItems[row._id][key].drugs)
																					? DeliveryStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			chooseItem={(value) => {
																				DeliveryStore.changeSubItem("drugs", row._id, key, value._id)
																			}}
																			onClear={() => {
																				DeliveryStore.subItems[row._id][key].drugsName = ""
																				DeliveryStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Delivery.list.modal.help.title" />}
																			modalConfig={(textValue, clickHandler) => {
																				// logger("textValue: ", textValue)
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "225px"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(DeliveryStore.subItems[row._id][key].sub_type) &&
																DeliveryStore.subItems[row._id][key].sub_type === "adicntr" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadCustomItem}
																			disabled={!DeliveryStore.isEditable}
																			isValid={isEmpty(DeliveryStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => DeliveryStore.searchCustomItems(value, key))
																			}
																			data={DeliveryStore.customItems[key]}
																			onSearchClose={() => (DeliveryStore.customItems[key] = [])}
																			defaultValue={
																				isSafe(DeliveryStore.subItems[row._id][key].drugs)
																					? DeliveryStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			chooseItem={(value) => {
																				DeliveryStore.changeSubItem("drugs", row._id, key, value._id)
																				api
																					.getDeliveryItemPrice(value._id, {
																						org_unit_id: getSelectedOrgUnitID(),
																						insurer_id: row.insurerId,
																						date: isNotEmpty(row.delivery_date_time)
																							? moment(row.delivery_date_time).format("YYYY-MM-DD")
																							: moment().format("YYYY-MM-DD")
																					})
																					.call()
																					.then((res) => {
																						if (isSafe(res) && isNotEmpty(res.price_in_currency)) {
																							DeliveryStore.subItems[row._id][key].unit_price = res.price_in_currency
																						} else {
																							if (isNotEmpty(value.price_in_currency)) {
																								DeliveryStore.subItems[row._id][key].unit_price =
																									value.price_in_currency
																							} else {
																								DeliveryStore.subItems[row._id][key].unit_price = ""
																							}
																						}
																					})
																			}}
																			onClear={() => {
																				DeliveryStore.subItems[row._id][key].unit_price = ""
																				DeliveryStore.subItems[row._id][key].drugsName = ""
																				DeliveryStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.addItems" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Common.label.code" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Common.label.name" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{((isSafe(DeliveryStore.subItems[row._id][key].sub_type) &&
																(DeliveryStore.subItems[row._id][key].sub_type === "adigen" ||
																	DeliveryStore.subItems[row._id][key].sub_type === "drg")) ||
																isEmpty(DeliveryStore.subItems[row._id][key].sub_type)) && (
																<Grid item xs={2}>
																	<TextField
																		disabled={!DeliveryStore.isEditable}
																		label={
																			<span className="xs-bold">
																				<FormattedMessage id="Delivery.List.Item" />*
																			</span>
																		}
																		value={
																			isSafe(DeliveryStore.subItems[row._id][key].subItemType)
																				? DeliveryStore.subItems[row._id][key].subItemType
																				: ""
																		}
																		onChange={(e) => {
																			DeliveryStore.changeSubItem("subItemType", row._id, key, e.target.value)
																			// DeliveryStore.changeSubItem("sub_type", row._id, key, "adigen") //TODO: davat bacha, teraz to je jedno lebo adigen a drg su tie iste podmienky, ale ked bude ine, treba upravit!
																		}}
																		InputLabelProps={{
																			shrink: true
																		}}
																		InputProps={{
																			classes: {
																				root: isEmpty(DeliveryStore.subItems[row._id][key].subItemType)
																					? "xs-input-error"
																					: ""
																			}
																		}}
																	/>
																</Grid>
															)}
															<Grid item xs={1}>
																<TextField
																	disabled={!DeliveryStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Quantity" />*
																		</span>
																	}
																	value={
																		isSafe(DeliveryStore.subItems[row._id][key].quantity)
																			? DeliveryStore.subItems[row._id][key].quantity
																			: ""
																	}
																	onChange={(e) =>
																		DeliveryStore.changeSubItem("quantity", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isNumber(DeliveryStore.subItems[row._id][key].quantity)
																				? "xs-input-error"
																				: ""
																		}
																	}}
																/>
															</Grid>
															<Grid item xs={2}>
																<TextField
																	disabled={!DeliveryStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.UnitPrice" />*
																		</span>
																	}
																	value={
																		isSafe(DeliveryStore.subItems[row._id][key].unit_price)
																			? DeliveryStore.subItems[row._id][key].unit_price
																			: ""
																	}
																	onChange={(e) =>
																		DeliveryStore.changeSubItem("unit_price", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isPrice(DeliveryStore.subItems[row._id][key].unit_price)
																				? "xs-input-error"
																				: ""
																		},
																		endAdornment:
																			isNotEmpty(DeliveryStore.subItems[row._id][key].unit_price) &&
																			isNotEmpty(DeliveryStore.subItems[row._id][key].adorment) ? (
																				<InputAdornment
																					position="end"
																					variant="filled"
																					className="xs-unit-price-adorment"
																				>
																					{DeliveryStore.subItems[row._id][key].adorment}
																				</InputAdornment>
																			) : null
																	}}
																/>
															</Grid>
															<Grid item xs={4}>
																{!(
																	(isSafe(DeliveryStore.subItems[row._id][key].sub_type) &&
																		(DeliveryStore.subItems[row._id][key].sub_type === "adigen" ||
																			DeliveryStore.subItems[row._id][key].sub_type === "drg")) ||
																	isEmpty(DeliveryStore.subItems[row._id][key].sub_type)
																) && (
																	<TextField
																		disabled={!DeliveryStore.isEditable}
																		label={<FormattedMessage id="Common.label.description" />}
																		defaultValue={
																			isSafe(DeliveryStore.subItems[row._id][key].description)
																				? DeliveryStore.subItems[row._id][key].description
																				: ""
																		}
																		onChange={(e) =>
																			DeliveryStore.changeSubItem("description", row._id, key, e.target.value)
																		}
																		InputLabelProps={{
																			shrink: true
																		}}
																	/>
																)}
															</Grid>
															{DeliveryStore.isEditable && (
																<Grid item xs={1}>
																	<Grid container direction="row" justify="flex-end">
																		<Grid item>
																			{countSubItems === index + 1 && (
																				<XsIconButton
																					className="xs-success xs-outline"
																					icon={<i className="fal fa-plus" />}
																					onClick={() => DeliveryStore.addSubItem(row._id)}
																				/>
																			)}
																			<XsIconButton
																				className="xs-danger xs-outline"
																				icon={<i className="fal fa-times" />}
																				onClick={() => DeliveryStore.deleteSubItem(row._id, key)}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
															)}
														</Grid>
													</Grid>
												)
											})}
										</Grid>
									</TableCell>
								</TableRow>
							)
						}
					}
				})
			} else {
				this.tableBodyRows.push(
					<TableRow key={1}>
						<TableCell className="textCenter xs-bold" colSpan={14}>
							<FormattedMessage id="Table.NoData" />
						</TableCell>
					</TableRow>
				)
			}
		}
	}

	render() {
		DeliveryStore.isChangedFilter(this.form.values())

		const {order, orderBy} = this.state

		if (this.reloadGrid) {
			this.data = DeliveryStore.deliveryList
		}

		const provider_id = getSelectedOrgUnitID()
		const selectedOrgUnit = getSelectedOrgUnit()
		const personnel_expertise_id = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_id : null

		this.tableBody()

		return (
			<React.Fragment>
				<Grid container spacing={16}>
					<Grid item container justify="flex-end" spacing={8}>
						{isSafe(getUserAccessRights("/fin/invoices", "RESTP")) && (
							<Grid item>
								<XsButton
									disabled={
										DeliveryStore.isEditable || !DeliveryStore.isInsurerSelected || DeliveryStore.changedFilters
									}
									className={
										DeliveryStore.isEditable || !DeliveryStore.isInsurerSelected || DeliveryStore.changedFilters
											? "xs-default xs-outline"
											: "xs-primary"
									}
									text={<FormattedMessage id="Capitation.form.ExportInvoice" />}
									icon={<i className="fal fa-save fa-lg" />}
									onClick={() => {
										UIStore.previousReportingZSDetailTab = "Deliveries"
										GenerateInvoiceDialogStore.open(
											{
												doctor_expertise_id: +personnel_expertise_id,
												date_from: this.form.$("time_from").value,
												date_to: this.form.$("time_to").value,
												insurer_id: +this.form.$("insurer").value,
												org_unit_id: +provider_id,
												// personnel_id: personnel_id,
												translatedExportBatchError: this.props.intl.formatMessage({
													id: "Capitation.form.ExportBatchError"
												}),
												translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
													id: "Capitation.form.GetSummaryDeliveriesError"
												})
											},
											this.form.values(),
											"deliveryListFilters"
										)
									}}
								/>
							</Grid>
						)}
						<Grid item>
							<XsButton
								// disabled={DeliveryStore.isEditable || isEmpty(this.form.$("insurer").value)}
								disabled={DeliveryStore.isEditable}
								className={DeliveryStore.isEditable ? "xs-default xs-outline" : "xs-primary"}
								text={<FormattedMessage id="Common.label.exportToExcel" />}
								icon={<i className="fal fa-file-excel fa-lg" />}
								onClick={() => {
									ExportToXlsDialogStore.open()
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className={DeliveryStore.isEditable ? "xs-default xs-outline" : "xs-primary"}
								disabled={DeliveryStore.isEditable}
								text={<FormattedMessage id="Capitation.form.ExportBatch" />}
								icon={<i className="fal fa-save fa-lg" />}
								onClick={() => {
									InvoiceEnterDialogStore.open({
										doctor_expertise_id: +personnel_expertise_id,
										insurer_id: +this.form.$("insurer").value,
										provider_id: +provider_id,
										date_from: this.form.$("time_to").value,
										date_to: this.form.$("time_from").value,
										translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
										translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
											id: "Capitation.form.GetSummaryDeliveriesError"
										})
									})
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className={
									DeliveryStore.isEditable || ExportRepairBatchStore.selectedIds.length == 0
										? "xs-default xs-outline"
										: "xs-primary"
								}
								disabled={DeliveryStore.isEditable || ExportRepairBatchStore.selectedIds.length == 0}
								text={<FormattedMessage id="Common.label.ExportRepairBatch" />}
								icon={<i className="fal fa-save fa-lg" />}
								onClick={() => {
									ExportRepairBatchStore.open({
										doctor_expertise_id: +personnel_expertise_id,
										insurer_id: +this.form.$("insurer").value,
										provider_id: +provider_id,
										date_from: this.form.$("time_to").value,
										date_to: this.form.$("time_from").value,
										translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
										translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
											id: "Capitation.form.GetSummaryDeliveriesError"
										})
									})
								}}
							/>
						</Grid>
						{DeliveryStore.isEditable && (
							<React.Fragment>
								<Grid item>
									<XsButton
										className="xs-success"
										text={<FormattedMessage id="Common.label.save" />}
										icon={<DoneIcon />}
										onClick={() => {
											if (DeliveryStore.isValidGrid) {
												DeliveryStore.saveChanges()
												DeliveryStore.isEditable = false
												DeliveryStore.changedData = {}
												DeliveryStore.changedKeysTest = {}
												DeliveryStore.removeDeliveryIds = []
											} else {
												WarningStore.open(
													`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
												)
											}
										}}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className="xs-danger xs-outline"
										onClick={() => {
											DeliveryStore.resetGrid()
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<i className="fal fa-times" />}
									/>
								</Grid>
							</React.Fragment>
						)}
						{!DeliveryStore.isEditable && (
							<React.Fragment>
								<Grid item>
									<XsButton
										className={classnames("xs-outline", {
											"xs-primary": !DeliveryStore.changedFilters,
											"xs-default": DeliveryStore.changedFilters
										})}
										disabled={DeliveryStore.changedFilters}
										text={<FormattedMessage id="Common.label.edit" />}
										onClick={() => {
											DeliveryStore.isEditable = true
											DeliveryStore.idSubItemsRow = ""
											ExportRepairBatchStore.selectedIds = []
										}}
										icon={<EditIcon />}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className={classnames({
											"xs-success": !DeliveryStore.changedFilters,
											"xs-default xs-outline": DeliveryStore.changedFilters
										})}
										disabled={DeliveryStore.changedFilters}
										onClick={() => AddDeliveryStore.open("delivery")}
										text={<FormattedMessage id="Common.label.addDelivery" />}
										icon={<i className="fal fa-plus" />}
									/>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
					<Grid container item direction="row" alignItems="center" spacing={8}>
						<Grid item xs={2}>
							<XsPeriodPicker
								white
								ref={(picker) => {
									this.periodPickerRef = picker
								}}
								disabled={DeliveryStore.isEditable}
								onChange={(val) => {
									this.form.$("time_from").value = val.from
									this.form.$("time_to").value = val.to
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								disabled={DeliveryStore.isEditable}
								field={this.form.$("from")}
								showTimeSelect={false}
								minDate={moment(this.form.$("time_from").value).startOf("month")}
								maxDate={moment(this.form.$("time_from").value).endOf("month")}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								disabled={DeliveryStore.isEditable}
								field={this.form.$("to")}
								showTimeSelect={false}
								minDate={moment(this.form.$("time_from").value).startOf("month")}
								maxDate={moment(this.form.$("time_from").value).endOf("month")}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsSearchSelect
								white
								disabled={DeliveryStore.isEditable}
								field={this.form.$("insurer")}
								items={RegistersCacheStore.insurersRegister}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsInput disabled={DeliveryStore.isEditable} white field={this.form.$("person_identifier")} />
						</Grid>
						<Grid item xs={2}>
							<XsAutocompleteLocal
								white
								disabled={DeliveryStore.isEditable}
								field={this.form.$("diagnosis")}
								minLengthForSearch="3"
								clearText
								data={RegistersCacheStore.diagnosisRegister}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								// postAction={(obj, isLoaded) => {
								// 	if (isLoaded) {
								// 		GlobalStore.handleClickFocus("patientPrescriptionForm")
								// 	}
								// }}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsSearchSelect
								disabled={DeliveryStore.isEditable}
								white
								field={this.form.$("insurence_type")}
								items={GlobalStore.CL["insuranceTypes"]}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput disabled={DeliveryStore.isEditable} white field={this.form.$("deliveries")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								disabled={DeliveryStore.isEditable}
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={() => {
									DeliveryStore.getFilters()
									this.setState({page: 0})
								}}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={8} alignItems="center">
						<Grid item>
							<FormattedMessage id="Common.label.countSelectedItem" />:
						</Grid>
						<Grid item>{ExportRepairBatchStore.selectedIds.length}</Grid>
						<Grid item className="ml-3">
							<Tooltip
								title={this.props.intl.formatMessage({
									id: "Common.label cancelSelected"
								})}
							>
								<i
									className={classnames("fal fa-times fontSize21 xs-pointer", {
										greyDefault: ExportRepairBatchStore.selectedIds.length == 0,
										blueDark: ExportRepairBatchStore.selectedIds.length > 0
									})}
									onClick={() => (ExportRepairBatchStore.selectedIds = [])}
								></i>
							</Tooltip>
						</Grid>
					</Grid>
					{DeliveryStore.isLoadingData ? (
						<XsLoading />
					) : DeliveryStore.changedFilters ? (
						<Grid item xs={12} className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 xs-pointer"
								onClick={() => {
									DeliveryStore.getFilters()

									this.setState({page: 0})
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12}>
								<Table className="xs-customTable">
									<TableHead>
										<TableRow>
											{!DeliveryStore.isEditable && <TableCell></TableCell>}
											<TableCell>
												<FormattedMessage id="Common.label.date" />
											</TableCell>
											<TableCell
												sortDirection={order}
												onClick={
													!DeliveryStore.isEditable
														? this.createSortHandler({columnName: "person_name", type: "string"})
														: null
												}
											>
												{order === "asc" || order === "desc" ? (
													<TableSortLabel active={orderBy === "person_name"} direction={order}>
														<FormattedMessage id="Delivery.List.Person" />
													</TableSortLabel>
												) : (
													<FormattedMessage id="Delivery.List.Person" />
												)}
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Age" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Diagnosis" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.delivery" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.SenderType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Count" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.payerType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Points" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Currency" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.InsureeType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.RefundType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Movement" />
											</TableCell>
											{DeliveryStore.isEditable && <TableCell width="40px" className="xs-align-text"></TableCell>}
											<TableCell className="xs-align-text">
												<FormattedMessage id="Delivery.List.PP" />
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>{this.tableBodyRows}</TableBody>
								</Table>
							</Grid>
							<Grid item xs={12}>
								<div className="xs-grid-footer">
									<TablePagination
										component="div"
										className="xs-table-pagination"
										// count={isSafe(this.data) ? this.data.length : 0}
										count={isSafe(DeliveryStore.deliveryList) ? DeliveryStore.deliveryList.length : 0}
										rowsPerPage={this.state.rowsPerPage}
										rowsPerPageOptions={[5, 10, 25, 50]}
										labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
										labelDisplayedRows={(pager) =>
											`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${
												pager.count
											}`
										}
										page={this.state.page}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
				<EnterInvoiceNumberDialog insurers={RegistersCacheStore.insurersRegister} />
				<ExportDeliveriesToXlsDialog
					onSuccess={(type, batchType, disposition) => {
						ExportDeliveriesDataStore.export(
							personnel_expertise_id,
							isNotEmpty(this.form.$("insurer")) && isNotEmpty(this.form.$("insurer").value)
								? this.form.$("insurer").value
								: null,
							+provider_id,
							this.form.$("time_from").value,
							this.state.page,
							this.state.rowsPerPage,
							type,
							batchType,
							disposition
						)
					}}
				/>
				<AddDeliveryDialog payerType="Z" />
				<ExportRepairBatchDialog insurers={RegistersCacheStore.insurersRegister} />
			</React.Fragment>
		)
	}
}
