import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import UsersStore from "../../../stores/UsersStore"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import MobxReactForm from "mobx-react-form"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import validatorjs from "validatorjs"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import UIStore from "../../../stores/UIStore"

@injectIntl
@observer
export default class ResourcesContainer extends React.Component {
	constructor(props) {
		super(props)

		const filterHooks = {
			onSubmit() {},
			onSuccess(form) {
				UsersStore.loadResources(form)
			},
			onError() {}
		}

		this.filterForm = new MobxReactForm(
			{
				fields: {
					name: {
						label: <FormattedMessage id="Common.label.name" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks: filterHooks, bindings}
		)

		UsersStore.loadResources(this.filterForm)
	}

	onHandleRowClick = () => {
		logger("click")
	}

	render() {
		UsersStore.isChangedResourcesFilter(this.filterForm.values())
		return (
			<Grid container>
				<Grid container spacing={8} alignItems="center">
					<Grid item xs={2}>
						<XsInput white field={this.filterForm.$("name")} />
					</Grid>
					<Grid item xs={1} className="pb-0">
						<XsIconButton
							rect
							className="xs-default xs-outline"
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							onClick={(e) => {
								this.filterForm.onSubmit(e)
							}}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : UsersStore.changedResourcesFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.filterForm.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									name_ext: {
										title: <FormattedMessage id="Common.label.name" />,
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									},
									description: {
										title: <FormattedMessage id="Common.label.description" />,
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											name_ext: dataRow.name,
											description: dataRow.description
										}
									}
								},
								dataSource: UsersStore.resourcesList
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
