"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import UIStore from "../stores/UIStore"
import EHGWStore from "./EHGWStore"

class EHGWUploadDialogStore {
	@observable isOpen = false
	@observable dropedFiles = []

	@action open() {
		this.isOpen = true
	}

	@action save(form) {
		const formValues = form.values()
		const closeFn = () => {
			this.close()
			this.dropedFiles = []
		}

		if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
			let req = {}
			let files = this.dropedFiles
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result

					req["_type"] = "EZdravie.Data.Signer.InstallFile"
					req["file_name"] = formValues.file_name
					req["version"] = formValues.version
					req["sub_version_1"] = formValues.sub_version_1
					req["sub_version_2"] = formValues.sub_version_2
					req["sub_version_3"] = formValues.sub_version_3
					req["os"] = "windows" // TODO: zatial fixne lebo nie su dalsie

					api
						.uploadInstallFile(req)
						.call()
						.then((data) => {
							UIStore.isFormSaving = false
							form.reset()
							EHGWStore.loadInstallFiles()
							closeFn()

							api
								.saveEHGWAttachmentContent(data._id, binaryData)
								.call()
								.catch((err) => {
									logger("chyba pri uploadovani suboru", err)
								})
						})
						.catch((err) => {
							logger("chyba pri posielani info o subore", err)
							UIStore.isFormSaving = false
							form.reset()
							EHGWStore.loadInstallFiles()
							closeFn()
						})
				}
			}
			getdata(0)
		}
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new EHGWUploadDialogStore()
export default singleton
