"use strict"
import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import settings from "../../../global/config/settings"
// import {getFilters} from "../../../global/helpers/api"
// import {getSelectedOrgUnitID} from "../../../global/helpers/actions"

class DailyReportListStore {
	@observable patientsOverview = []
	@observable isFetching = false
	@observable isOpen = false
	@observable diagnosisFilter = []
	@observable diagnoses_patientListFilter = null

	formRef = undefined

	dailyReportListRef = null

	@observable changedFilters = false
	currFilters = {}
	currDiagnosis = []

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		let formDiagnosis = []

		if (isSafe(this.diagnosisFilter.diagnoses) && this.diagnosisFilter.diagnoses.length > 0) {
			this.diagnosisFilter.diagnoses.forEach((diag) => {
				formDiagnosis.push(diag.id_value)
			})
		}

		let currentDiagnosis = []

		if (isSafe(this.currDiagnosis.diagnoses) && this.currDiagnosis.diagnoses.length > 0) {
			this.currDiagnosis.diagnoses.forEach((diag) => {
				currentDiagnosis.push(diag.id_value)
			})
		}

		let isChangeDiagnosis = equalsArr(currentDiagnosis, formDiagnosis)

		if (isChangeDateFrom || isChangeDateTo || this.currFilters.orgunit != formValues.orgunit || !isChangeDiagnosis) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action set(name, value) {
		this[name] = value
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadPatientsOverview(form) {
		let formValues = form.values()
		this.currFilters = form.values()
		this.currDiagnosis = this.diagnosisFilter

		// this.isChangedFilter(form.values())

		let filters = [{associated_column: "provider_id", values: [{id_value: formValues.orgunit}]}]

		if (isSafe(this.diagnosisFilter.diagnoses) && this.diagnosisFilter.diagnoses.length > 0) {
			let filter = this.diagnosisFilter.diagnoses.map((diag) => {
				return {
					id_value: diag.id_value
				}
			})
			filters.push({associated_column: "diagnosis_id", values: filter})
		}

		const searchRequest = {
			filters: filters
		}

		if (isNotEmpty(formValues.datefrom)) {
			searchRequest["time_from"] = moment(formValues.datefrom)
				.add(-1, "days")
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(formValues.dateto)) {
			searchRequest["time_to"] = moment(formValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		this.isFetching = true
		api
			.loadPatientsOverview(searchRequest)
			.call()
			.then((response) => {
				this.patientsOverview = response.rows
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action exportToXls(form) {
		let filters = []

		let req = {
			uri: `/amb/entity/patients/overview`
		}

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()

			if (isNotEmpty(refFormValues.orgunit)) {
				filters.push({
					associated_column: "provider_id",
					values: [{id_value: refFormValues.orgunit}]
				})
			}

			if (isSafe(this.diagnosisFilter.diagnoses) && this.diagnosisFilter.diagnoses.length > 0) {
				let filter = this.diagnosisFilter.diagnoses.map((diag) => {
					return {
						id_value: diag.id_value
					}
				})
				filters.push({associated_column: "diagnosis_id", values: filter})
			}

			if (isNotEmpty(refFormValues.datefrom)) {
				req.time_from = moment(refFormValues.datefrom)
					.add(-1, "days")
					.endOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			}

			if (isNotEmpty(refFormValues.dateto)) {
				req.time_to = moment(refFormValues.dateto)
					.endOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			}
		}

		req.filters = filters

		if (form.values().exportType == "now") {
			if (isSafe(this.dailyReportListRef)) {
				req.row_count_show = this.dailyReportListRef.state.rowsPerPage
				// req.row_count_full = this.dailyReportListRef.state.rowsPerPage
				req.row_offset = this.dailyReportListRef.state.page * this.dailyReportListRef.state.rowsPerPage + 1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
				// req.row_count_full = 10
			}
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			req.row_count_full = 100000
		}

		api
			.exportData(req)
			.call()
			.then((response) => {
				this.close()
				form.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})

		this.close()
	}
}
var singleton = new DailyReportListStore()
export default singleton
