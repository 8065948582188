import React from "react"
import ReactDOM from "react-dom"

import {SnackbarProvider} from "notistack"
import {IntlProvider} from "react-intl"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import {addLocaleData} from "react-intl"
import enLocaleData from "react-intl/locale-data/en"
import skLocaleData from "react-intl/locale-data/sk"
import config from "./global/config/settings"
import muiTheme from "./global/theme/muiTheme"
import localeDataEN from "./global/i18n/dictionary.en.json"
import localeDataSK from "./global/i18n/dictionary.sk.json"
import createBrowserHistory from "history/createBrowserHistory"
import {Provider} from "mobx-react"
import {syncHistoryWithStore} from "mobx-react-router"
import RouterStore from "./global/store/RouterStore"
import {StorageBase} from "./global/storage/storageEx"
import {Router} from "react-router-dom"
import App from "./ui/App"
import moment from "moment"
import "moment/locale/sk"
import {configure} from "mobx"

import UIStore from "./modules/ambulance/stores/UIStore"
import DataStore from "./modules/ambulance/stores/DataStore"
import PatientDetailActionsStore from "./modules/ambulance/stores/PatientDetailActionsStore"
import AddEventDialogStore from "./modules/ambulance/stores/AddEventDialogStore"
import NewPatientDialogStore from "./modules/ambulance/stores/NewPatientDialogStore"

moment.locale("sk")
configure({isolateGlobalState: true})

import WindowsExtensions from "./global/helpers/window" // eslint-disable-line no-unused-vars
import {LANGUAGE} from "./global/config/constants"

addLocaleData([...enLocaleData, ...skLocaleData])

const localeData = {en: localeDataEN, sk: localeDataSK}

//const selectedLanguage = Storage.getItem(LANGUAGE)
const selectedLanguage = StorageBase.getByKey(LANGUAGE)
const languageWithoutRegionCode = selectedLanguage || "sk" //config.BROWSER_LOCALE.toLowerCase().split(/[_-]+/)[0]
// const messages = localeData[languageWithoutRegionCode] || localeData[config.BROWSER_LOCALE] || localeData.sk
const messages = localeData[languageWithoutRegionCode] || localeData.sk

const browserHistory = createBrowserHistory()
const stores = {
	routing: RouterStore
}

document.body.addEventListener("keydown", function generics(e) {
	if (RouterStore.location.pathname === "/patients") {
		if (e.altKey && e.keyCode == 78) {
			e.preventDefault()
			DataStore.nameNewPatient = ""
			DataStore.isCreatedFromEvent = false
			NewPatientDialogStore.open()
		}

		//nova udalost Alt + K
		if (e.altKey && e.keyCode == 75) {
			e.preventDefault()
			AddEventDialogStore.open(true)
		}
	}

	if (RouterStore.location.pathname === "/patient") {
		//novy liek Alt + L
		if (e.altKey && e.keyCode == 76) {
			e.preventDefault()
			UIStore.onHandleTabs("prescription")
			PatientDetailActionsStore.close()
		}

		//novy dekurz Alt + D
		if (e.altKey && e.keyCode == 68) {
			e.preventDefault()
			UIStore.onHandleTabs("record")
			PatientDetailActionsStore.close()
		}

		//nova ziadost Alt + Z
		if (e.altKey && e.keyCode == 90) {
			e.preventDefault()
			UIStore.onHandleTabs("request")
			PatientDetailActionsStore.close()
		}

		//nova soc. poist Alt + S
		if (e.altKey && e.keyCode == 83) {
			e.preventDefault()
			UIStore.onHandleTabs("sicknessabs")
			PatientDetailActionsStore.close()
		}

		//novy medikacia Alt + M
		if (e.altKey && e.keyCode == 77) {
			e.preventDefault()
			UIStore.onHandleTabs("medication")
			PatientDetailActionsStore.close()
		}
	}
})

const history = syncHistoryWithStore(browserHistory, RouterStore)
ReactDOM.render(
	<SnackbarProvider maxSnack={5}>
		<MuiThemeProvider theme={muiTheme}>
			<IntlProvider locale={config.BROWSER_LOCALE} messages={messages}>
				<Provider {...stores}>
					<Router history={history}>
						<App />
					</Router>
				</Provider>
			</IntlProvider>
		</MuiThemeProvider>
	</SnackbarProvider>,
	document.getElementById("root")
)
