import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import RouterStore from "../../../../../global/store/RouterStore"
import DailyReportListStore from "../../../stores/DailyReportListStore"
import WarningStore from "../../../../../global/store/WarningStore"

import ExportDailyReportToXlsDialog from "./exportDailyReportToXlsDialog"

// definitions of form fields
import fields from "./dailyReportListFields"

import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
// import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import DiagnosesSearch from "./DiagnosesFilter"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class DailyReportList extends React.Component {
	constructor(props) {
		super(props)
		// DailyReportListStore.loadPatientsOverview()

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					DailyReportListStore.loadPatientsOverview(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		DailyReportListStore.formRef = this.form
		DailyReportListStore.diagnosisFilter = []
		DailyReportListStore.diagnoses_patientListFilter = null
		DailyReportListStore.currDiagnosis = DailyReportListStore.diagnosisFilter

		DailyReportListStore.currFilters = this.form.values()
	}

	componentWillUnmount() {
		DailyReportListStore.patientsOverview = []
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	}

	render() {
		DailyReportListStore.isChangedFilter(this.form.values())
		const dateTimeFormat = this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2} className="pb-0">
							<DiagnosesSearch />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={5}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										disabled={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value) ? true : false
										}
										className={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value)
												? "xs-default xs-outline"
												: "xs-primary"
										}
										text={<FormattedMessage id="Common.label.exportToExcel" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											DailyReportListStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{DailyReportListStore.isFetching ? (
						<XsLoading />
					) : DailyReportListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (DailyReportListStore.dailyReportListRef = table)}
							config={{
								columnDefs: {
									stamp: {
										title: <FormattedMessage id="Common.label.date" />,
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateTimeFormat) : ""
												}
											}
										},
										sortable: true
									},
									full_name: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "250px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									dg: {
										title: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
										type: "string",
										sortable: true
									},
									sending_doctor: {
										title: <FormattedMessage id="Patient.form.patientrecord.sendingDoctor" />,
										design: {
											width: "250px"
										},
										type: "string"
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											stamp: isSafe(dataRow.stamp) ? dataRow.stamp : "",
											full_name: isSafe(dataRow.client_name) ? dataRow.client_name : "",
											identifier: isSafe(dataRow.client_identifier) ? dataRow.client_identifier : "",
											insurer: isSafe(dataRow.insurer_id)
												? `${dataRow.insurer_code_ext} - ${dataRow.insurer_name_ext}`
												: "",
											dg: isSafe(dataRow.diagnosis_id)
												? `${dataRow.diagnosis_code_ext} - ${dataRow.diagnosis_name_ext}`
												: "",
											sending_doctor: isSafe(dataRow.sending_doctor_id) ? dataRow.sending_doctor_name : "",
											person_id: dataRow.person_id
										}
									}
								},
								dataSource: DailyReportListStore.patientsOverview
							}}
						/>
					)}
				</Grid>
				<ExportDailyReportToXlsDialog />
			</Grid>
		)
	}
}
