import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./capitationListFilterFields"

import {
	getUserPersonnelID,
	getSelectedOrgUnitID,
	getSelectedOrgUnit,
	getUserAccessRights
} from "../../../../../global/helpers/actions"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import ExportCapitationDialogStore from "../../../stores/ExportCapitationDialogStore"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import ExportToXlsDialog from "../../exportToXlsDialog/exportToXlsDialog"
import ExportCapitationDialog from "./exportCapitationDialog"

import GenerateInvoiceDialogStore from "../../../stores/GenerateInvoiceDialogStore"
import ExportToXlsDialogStore from "../../../stores/ExportToXlsDialogStore"
import CapitationListStore from "../../../stores/CapitationListStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import UIStore from "../../../stores/UIStore"
// import moment from "moment"

@injectIntl
@observer
export default class CapitationList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				CapitationListStore.getFilters(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		CapitationListStore.formRef = this.form

		CapitationListStore.getFilters(this.form)
	}

	render() {
		CapitationListStore.isChangedFilter(this.form.values())

		return (
			<Grid item container justify="space-between" alignItems="center">
				<Grid item xs container spacing={8} alignItems="center">
					<Grid item xs={4}>
						<XsPeriodPicker
							white
							onChange={(val) => {
								this.form.$("validity_from").value = val.from
								this.form.$("validity_to").value = val.to
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsSearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
					</Grid>
					<Grid item xs={3}>
						<XsInput white field={this.form.$("identifier")} />
					</Grid>
					<Grid item className="pb-0">
						<XsIconButton
							rect
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							className="xs-default xs-outline"
							onClick={this.form.onSubmit}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
				</Grid>
				<Grid item xs container justify="flex-end" spacing={8}>
					{isSafe(getUserAccessRights("/fin/invoices", "RESTP")) && (
						<Grid item>
							<XsButton
								disabled={
									isEmpty(CapitationListStore.exportFilters) || isEmpty(CapitationListStore.exportFilters.insurer)
								}
								className={
									isEmpty(CapitationListStore.exportFilters) || isEmpty(CapitationListStore.exportFilters.insurer)
										? "xs-default xs-outline"
										: "xs-primary"
								}
								text={<FormattedMessage id="Capitation.form.ExportInvoice" />}
								icon={<i className="fal fa-save fa-lg" />}
								onClick={() => {
									UIStore.previousReportingZSDetailTab = "Capitations"
									GenerateInvoiceDialogStore.open(
										{
											doctor_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
											date_from: CapitationListStore.exportFilters.validity_from,
											date_to: CapitationListStore.exportFilters.validity_to,
											insurer_id: isNotEmpty(CapitationListStore.exportFilters.insurer)
												? +CapitationListStore.exportFilters.insurer
												: null,
											org_unit_id: +getSelectedOrgUnitID(),
											personnel_id: getUserPersonnelID(),
											translatedExportBatchError: this.props.intl.formatMessage({
												id: "Capitation.form.ExportBatchError"
											}),
											translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
												id: "Capitation.form.GetSummaryDeliveriesError"
											})
										},
										CapitationListStore.exportFilters,
										"capitationListFilters"
									)
								}}
							/>
						</Grid>
					)}
					<Grid item>
						<XsButton
							className="xs-primary"
							text={<FormattedMessage id="Common.label.exportToExcel" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
							onClick={() => {
								ExportToXlsDialogStore.open()
							}}
						/>
					</Grid>
					<Grid item>
						<XsButton
							className="xs-primary"
							text={<FormattedMessage id="Capitation.form.ExportBatch" />}
							icon={<i className="fal fa-save" />}
							onClick={() => {
								ExportCapitationDialogStore.open({
									doctor_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
									date_from: CapitationListStore.exportFilters.validity_from,
									date_to: CapitationListStore.exportFilters.validity_to,
									insurer_id: isNotEmpty(CapitationListStore.exportFilters.insurer)
										? +CapitationListStore.exportFilters.insurer
										: null,
									org_unit_id: +getSelectedOrgUnitID(),
									personnel_id: getUserPersonnelID(),
									translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
									translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
										id: "Capitation.form.GetSummaryDeliveriesError"
									})
								})
							}}
						/>
					</Grid>
				</Grid>
				<ExportCapitationDialog insurers={RegistersCacheStore.insurersRegister} />
				<ExportToXlsDialog
					onSuccess={(type) => {
						ExportCapitationDialogStore.exportToXls(
							type,
							getUserPersonnelID(),
							isNotEmpty(this.form.$("insurer").value) ? +this.form.$("insurer").value : null,
							+getSelectedOrgUnitID(),
							this.form.$("validity_from").value,
							this.form.$("identifier").value
						)
					}}
				/>
			</Grid>
		)
	}
}
