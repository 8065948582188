import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsSearchdropdownNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"

import fields from "./requestsSVLZInsurerFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class RequestsSVLZInsurerDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				RequestsSVLZInsurerStore.filterDajZiadankySVLZPoistenca(form, props.patientId)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		GlobalStore.refreshCodeListsSync(["dzpelabexamtag"])

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	getExaminationTags = () => {
		if (
			isSafe(GlobalStore.CL["dzpelabexamtag"]) &&
			isSafe(this.form.$("examination_type")) &&
			isNotEmpty(this.form.$("examination_type").value)
		) {
			let filterTag = GlobalStore.CL["dzpelabexamtag"].filter(
				(x) => x.exam_type == this.form.$("examination_type").value
			)

			return filterTag.map((tag) => {
				return {
					id: tag._id,
					search_string: tag.name_ext,
					// primaryText: "",
					secondaryText: tag.name_ext,
					code: tag.code,
					checkValue: RequestsSVLZInsurerStore.tagsCodeActual.includes(tag.code_ext) ? true : false
				}
			})
		} else {
			return []
		}
	}

	render() {
		return (
			<Dialog
				id="xsRequestSVLZInsurerDialog"
				open={RequestsSVLZInsurerStore.isOpen}
				onClose={() => {
					RequestsSVLZInsurerStore.close(true)
					this.form.reset()
				}}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-book-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.requestsSVLZInsurer" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item container spacing={8}>
							{RequestsSVLZInsurerStore.showSMSField && (
								<Grid item xs={6}>
									<XsInput field={this.form.$("sms_code")} />
								</Grid>
							)}
							<Grid item xs={6}>
								<XsSearchSelect
									field={this.form.$("period_type")}
									items={[
										{_id: "T", code: "T", name_ext: "Týždeň"},
										{_id: "M", code: "M", name_ext: "Mesiac"},
										{_id: "Q", code: "Q", name_ext: "Kvartál"},
										{_id: "P", code: "P", name_ext: "Polrok"},
										{_id: "R", code: "R", name_ext: "Rok"},
										{_id: "2R", code: "2R", name_ext: "Dva roky"},
										{_id: "V", code: "V", name_ext: "Celá história"}
									]}
								/>
							</Grid>
						</Grid>
						<Grid item container spacing={8}>
							<Grid item xs={6}>
								<XsInput field={this.form.$("period_from")} />
							</Grid>
							<Grid item xs={6}>
								<XsInput field={this.form.$("period_to")} />
							</Grid>
						</Grid>
						<Grid item container spacing={8}>
							<Grid item xs={6}>
								<XsSearchSelect
									field={this.form.$("examination_type")}
									items={[
										{_id: "LAB", code: "LAB", name_ext: "Laboratórne vyšetrenia"},
										{_id: "ZOB", code: "ZOB", name_ext: "Zobrazovacie vyšetrenia"}
									]}
									required
								/>
							</Grid>
							<Grid container item xs={6} direction="column">
								{/* <XsInput field={this.form.$("examination_tag")} /> */}
								<Grid item style={{fontSize: "13px", paddingBottom: "1px", color: "#60606F", paddingLeft: "7px"}}>
									<FormattedMessage id="Common.label.examinationTag" />
								</Grid>
								<Grid item>
									<XsSearchdropdownNoForm
										id="examinationTag"
										style={{background: "rgb(241, 243, 248)", borderColor: "rgb(192, 197, 207)"}}
										field={this.form.$("examination_tag")}
										items={this.getExaminationTags()}
										multiSelect={true}
										onClose={(data) => {
											this.form.$("examination_tag").set(data)
										}}
									/>
								</Grid>
								{/* <SearchDropdownNoForm
									items={
										isSafe(GlobalStore.CL["patientTagTypes"])
											? GlobalStore.CL["patientTagTypes"].map((tag) => {
													return {
														id: tag._id,
														search_string: tag.search_column,
														// primaryText: tag.name_ext,
														secondaryText: tag.name_ext,
														checkValue: isSafe(tagChecked) && tagChecked.includes(tag._id)
													}
											  })
											: []
									}
									multiSelect={true}
									id="patientListContainer_tags"
									label={<FormattedMessage id="Common.label.tags" />}
									onClose={(data) => {
										const filters =
											data.length > 0
												? data.map((x) => {
														return {id_value: x.id}
												  })
												: null

										DataStore.tags_patientListFilter = filters
									}}
								/> */}
								{/* <XsSearchSelect
									field={this.form.$("examination_tag")}
									items={GlobalStore.CL["dzpelabexamtag"]}
									getOptionLabel={(row) => `${row.code_ext} - ${row.name_ext}`}
							/> */}
							</Grid>
						</Grid>
						<Grid item container spacing={8}>
							<Grid item xs={12}>
								<XsAutocompleteLocal
									field={this.form.$("diagnosis")}
									minLengthForSearch="3"
									clearText
									data={RegisterCacheStore.diagnosisRegister}
									inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
									saveValue={(obj) => obj.code_ext}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px"
													},
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
													type: "string",
													dbName: "search_column",
													filter: {
														gridWidth: 9,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container spacing={8}>
							<Grid item xs={12}>
								<XsInput field={this.form.$("search")} />
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (RequestsSVLZInsurerStore.close(true), this.form.reset())}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-book-alt" />}
						text={<FormattedMessage id="Common.label.find" />}
						onClick={(e) => {
							this.form.onSubmit(e)
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
