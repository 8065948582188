"use strict"

import React from "react"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./contactBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"
import {FormattedMessage} from "react-intl"
// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
// definitions of form fields
import fields from "./contactFields"
import {isEmailValid, isPhoneNumberValid} from "../../../../../global/helpers/functions"
// actions
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import WarningStore from "../../../../../global/store/WarningStore"
import DataStore from "../../../stores/DataStore"
import ContactFormStore from "../../../stores/ContactFormStore"
import {injectIntl} from "react-intl"

import InvitePatientDialogStore from "../../../stores/InvitePatientDialogStore"

@injectIntl
@observer
export default class ContactTableEx extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				UIStore.isFormSaving = true
				if (isEmpty(form.$("phone").value) && isEmpty(form.$("mobile").value) && isEmpty(form.$("email").value)) {
					// WarningStore.open("Aspon jeden udaj je potrebny (Mobile, Phone, Email)")
					WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactForm.emptyFormat"}))
					// form.invalidate()
					form.$("phone").invalidate()
					form.$("mobile").invalidate()
					form.$("email").invalidate()
				} else {
					if (isNotEmpty(form.$("phone").value) && !isPhoneNumberValid(form.$("phone").value)) {
						WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactForm.phoneFormat"}))
						// form.invalidate()
						form.$("phone").invalidate()
					} else if (isNotEmpty(form.$("mobile").value) && !isPhoneNumberValid(form.$("mobile").value)) {
						WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactForm.mobileFormat"}))
						// form.invalidate()
						form.$("mobile").invalidate()
					} else if (isNotEmpty(form.$("email").value) && !isEmailValid(form.$("email").value)) {
						WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactForm.emailFormat"}))
						// form.invalidate()
						form.$("email").invalidate()
					}
				}
			},
			onSuccess(form) {
				saveFormDataByScheme(
					form,
					dataBindings,
					{
						specrel: {
							_type: "Entity.Data.Client",
							_id: props.clientId
						}
					},
					(res) => {
						if (isSafe(res)) {
							form.reset()
							ContactFormStore.isAdd = false
						}
						DataStore.getPatientContacts(props.id)
						UIStore.isFormSaving = false
					},
					false,
					ContactFormStore.editable ? "Patient.form.contactForm.updateMessage" : "Patient.form.contactForm.saveMessage"
				)

				ContactFormStore.editable = false
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.initForm()
	}

	initForm() {
		resetForm(this.form, dataBindings)
	}

	form

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {contactId: dataRow.id})

		ContactFormStore.editable = true
		ContactFormStore.isAdd = true
	}

	deleteContact = () => {
		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.contactId,
			() => DataStore.getPatientContacts(this.props.id),
			"Patient.form.contactForm.deleteMessage"
		)
		ContactFormStore.editable = false
		this.initForm()
	}

	render() {
		return (
			<div className="xs-contact-table-ex">
				<div className="xs-table">
					<XsTable
						config={{
							columnDefs: {
								contact_type: {
									title: <FormattedMessage id="Patient.form.contactForm.typ" />,
									type: "string",
									design: {
										width: "150px",
										body: {
											className: (row) => (row.primary ? "xs-bold" : null)
										}
									}
								},
								mobile: {
									title: <FormattedMessage id="Patient.form.contactForm.mobile" />,
									type: "string",
									design: {
										width: "175px",
										body: {
											className: (row) => (row.primary ? "xs-bold" : null)
										}
									}
								},
								email: {
									title: <FormattedMessage id="Common.label.email" />,
									type: "string",
									design: {
										width: "300px",
										body: {
											className: (row) => (row.primary ? "xs-bold" : null)
										}
									},
									sortable: true
								},
								phone: {
									title: <FormattedMessage id="Patient.form.contactForm.phone" />,
									design: {
										width: "175px"
									},
									type: "string"
								},
								description: {
									title: <FormattedMessage id="Common.label.description" />,
									type: "string"
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "2px",
										body: {
											renderer: (props) => {
												return (
													<Grid container wrap="nowrap" justify="flex-end">
														{isNotEmpty(props.value.email) && (
															<Grid item>
																<XsIconButton
																	className="action-delete"
																	icon={
																		<span>
																			<i
																				className="fal fa-envelope fa-lg xs-greyDefault"
																				data-tip
																				data-for="invite"
																			></i>
																			<ReactTooltip id="invite" effect="solid">
																				<FormattedMessage id="Common.label.invite" />
																			</ReactTooltip>
																		</span>
																	}
																	onClick={() =>
																		InvitePatientDialogStore.open(props.value.email)
																	} /*deleteDataByScheme(dataBindings, props.value, () => DataStore.getPatientContacts(this.props.id), "Patient.form.contactForm.deleteMessage")}*/
																/>
															</Grid>
														)}
														<Grid item>
															<XsIconButton
																className="action-delete"
																icon={
																	<span>
																		<i
																			className="fal fa-trash-alt fa-lg xs-greyDefault"
																			data-tip
																			data-for="removeContact"
																		/>
																		<ReactTooltip id="removeContact" effect="solid">
																			<FormattedMessage id="Common.label.removeContact" />
																		</ReactTooltip>
																	</span>
																}
																onClick={() =>
																	GlobalStore.openConfirmationDialog("xsDeletePatientContact", {
																		contactId: props.value.id
																	})
																} /*deleteDataByScheme(dataBindings, props.value, () => DataStore.getPatientContacts(this.props.id), "Patient.form.contactForm.deleteMessage")}*/
															/>
														</Grid>
													</Grid>
												)
											}
										}
									}
								}
							},
							options: {
								// hideHeader: true,
								// selectRow: true,
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										contact_type: isSafe(dataRow.contact_type) ? dataRow.contact_type.description : "",
										contact_type_code: isSafe(dataRow.contact_type) ? dataRow.contact_type.code : "",
										mobile: dataRow.mobile,
										description: dataRow.description,
										email: dataRow.email,
										phone: dataRow.phone,
										delete: {id: dataRow._id, email: dataRow.email},
										primary: dataRow.primary,
										id: dataRow._id
									}
								}
							},
							dataSource: DataStore.patientDTO.get("contacts")
						}}
					/>
				</div>
				<div className="xs-form">
					{ContactFormStore.isAdd ? (
						<form>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={2}>
									<SearchSelect
										white={true}
										field={this.form.$("type")}
										items={GlobalStore.CL["contactTypes"]}
										required
									/>
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("mobile")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("email")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("phone")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("description")} />
								</Grid>
								<Grid item xs={2} className="xs-contact-checkbox">
									<XsCheckbox field={this.form.$("check")} />
								</Grid>
								<Grid item xs={12} className="xs-form-actions-newline">
									{ContactFormStore.editable ? (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.save" />}
											icon={<SaveIcon />}
										/>
									) : (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.add" />}
											icon={<SaveIcon />}
										/>
									)}
									<XsButton
										className="xs-default xs-outline"
										onClick={() => {
											this.initForm()
											ContactFormStore.isAdd = false
											ContactFormStore.editable = false
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
									/>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								ContactFormStore.isAdd = true
								ContactFormStore.editable = false
								this.initForm()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					type="danger"
					name="xsDeletePatientContact"
					text={<FormattedMessage id="Patient.form.contactForm.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.contact.delete"
					}}
					onConfirmation={() => this.deleteContact()}
				/>
			</div>
		)
	}
}
