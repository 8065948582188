"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {getHour, getMinute, getDate, getMonth, getYear} from "../../../global/ui/xsDateTimePicker/helpers"

import CalendarStore from "./CalendarStore"
import UIStore from "./UIStore"

class OtherDayDialogStore {
	@observable isOpen = false

	@action handleDateTimePickerChange(selectedDateTime) {
		let date = getDate(selectedDateTime)
		let month = getMonth(selectedDateTime)
		let year = getYear(selectedDateTime)
		let hour = getHour(selectedDateTime)
		let minute = getMinute(selectedDateTime)

		const datetime = moment().set({date, month, year, hour, minute})

		this.loadSelectedDay(datetime)
	}

	@action loadSelectedDay(datetime) {
		CalendarStore.selectedDay = moment(datetime).set("minute", CalendarStore.getRoundMinutes(moment(datetime))) //moment(datetime).local()
		CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(moment(datetime))
		if (UIStore.calendarTimePeriod == "resources") {
			CalendarStore.setCalendarRange("resources")
		} else {
			CalendarStore.setCalendarRange("otherDay", true)
		}
		this.close()
	}

	@action open() {
		CalendarStore.handleEventsByDay(moment())
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new OtherDayDialogStore()
export default singleton
