"use strict"

// default imports for all farms
import React from "react"
import ComponentAsync from "./componentAsync"
import validatorjs from "validatorjs"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import {XsDateTimePicker} from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import {XsDateTimePickerDirect} from "../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"
import {Grid} from "@material-ui/core"
import moment from "moment"

// const ComponentAsync = React.lazy(() => import("./componentAsync"))

export default class container extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			multiForms: [],
			selectedDate: null
		}

		this.fields = {
			fields: {
				identifier: {
					label: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
					rules: "required"
				},
				name: {
					label: <FormattedMessage id="Common.label.firstName" />,
					rules: "required|string"
				},
				surname: {
					label: <FormattedMessage id="Common.label.lastName" />,
					rules: "required|string"
				},
				age: {
					label: "vek"
				},
				defaultDTP: {
					label: "Dátum a čas"
				},
				defaultDP: {
					label: "Dátum"
				},
				valuedDTP: {
					label: "Dátum a čas predplnený",
					value: moment()
				},
				valuedDP: {
					label: "Dátum predplnený",
					value: moment()
				},
				valuedDPinline: {
					value: moment().add(2, "days"),
					extra: {inline: true}
				}
			}
		}

		this.hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		const hooks = this.hooks
		this.componentForm = new MobxReactForm(this.fields, {plugins: {dvr: validatorjs}, hooks})
	}

	addForm = () => {
		let forms = this.state.multiForms
		let hooks = this.hooks
		forms.push(new MobxReactForm(this.fields, {plugins: {dvr: validatorjs}, hooks}))
		this.setState({multiForms: forms})
	}

	changeDate = () => {
		this.componentForm.$("valuedDTP").set(
			"value",
			moment()
				.add(2, "days")
				.subtract(5, "hours")
		)
		// this.componentForm.$("valuedDP").set("value", moment().add(2, "days"))
		this.componentForm.$("valuedDPinline").set(
			"value",
			moment()
				.add(4, "days")
				.add(2, "hours")
		)
	}

	renderMultiForm = () => {
		let formsToRender = []
		this.state.multiForms.forEach((form, index) => {
			formsToRender.push(<ComponentAsync form={form} key={index} />)
			formsToRender.push(<button onClick={() => this.removeForm(index)}>vymazat FORM</button>)
		})

		return formsToRender
	}

	removeForm = (index) => {
		let forms = this.state.multiForms
		forms.splice(index, 1)
		this.setState({multiForms: forms})
	}

	render() {
		return (
			<div>
				<ComponentAsync form={this.componentForm} />

				<h1>MULTIFORM POC</h1>

				{this.renderMultiForm()}

				{/* <button onClick={() => this.forceUpdate()}>aaa</button> */}
				<button onClick={() => this.addForm()}>PRIDAT FORM</button>

				<div className="datetimepicker-tester">
					<Grid container direction="row" spacing={8}>
						DATETIMEPICKER FIELD
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.componentForm.$("defaultDTP")}
								onSelect={(date) => {
									logger("DTP SELECT", date.format())
								}}
								showTimeSelect={true}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.componentForm.$("defaultDP")}
								onSelect={(date) => {
									//console.trace() //eslint-disable-line
									logger("DTP SELECT", date.format())
								}}
								showTimeSelect={false}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.componentForm.$("valuedDTP")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.componentForm.$("valuedDP")} showTimeSelect={false} />
						</Grid>
						<XsDateTimePicker field={this.componentForm.$("valuedDPinline")} showTimeSelect={true} inline={true} />
					</Grid>
					{/*<Grid container direction="row" spacing={8}>
						DATETIMEPICKER DIRECT
						<Grid item xs={3}>
							<XsDateTimePickerDirect
								selected={this.state.selectedDate}
								onChange={(sDateTime) => this.setState({selectedDate: sDateTime})}
								showTimeSelect={true}
							/>
						</Grid>
						 <Grid item xs={3}>
							<XsDateTimePickerDirect
								selected={this.state.selectedDate}
								onChange={(sDateTime) => this.setState({selectedDate: sDateTime})}
								showTimeSelect={false}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePickerDirect
								selected={this.state.selectedDate}
								onChange={(sDateTime) => this.setState({selectedDate: sDateTime})}
								showTimeSelect={true}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePickerDirect
								selected={this.state.selectedDate}
								onChange={(sDateTime) => this.setState({selectedDate: sDateTime})}
								showTimeSelect={false}
							/>
						</Grid>
						<XsDateTimePickerDirect
							selected={this.state.selectedDate}
							onChange={(sDateTime) => this.setState({selectedDate: sDateTime})}
							showTimeSelect={true}
							inline={true}
						/> 
					</Grid>*/}
					<button onClick={() => this.changeDate()}>NASTAV DATUM</button>
				</div>
			</div>
		)
		// <ComponentAsync />
	}
}
