import React, {Component} from "react"
import * as utils from "../xsDateTimePicker/helpers"
import {injectIntl, FormattedMessage} from "react-intl"

import "./xsPeriodPicker.less"
import {TextField, MenuItem, FormControl, InputLabel} from "@material-ui/core"
import {padStart} from "../../helpers/functions"

// @injectIntl
class XsPeriodPicker extends Component {
	constructor(props) {
		super(props)

		const localeData = utils.getLocaleDataForLocale(
			this.props.intl.formatMessage({id: "Application.moment.locale", defaultMessage: "sk-SK"})
		)
		this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((M) =>
			utils.getMonthShortInLocale(localeData, utils.newDate({M}))
		)

		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()

		// zobrazit poslednych 5 rokov
		this.years = ((a, b) => {
			while (a--) {
				b[a] = a + currentYear - 4
			}
			return b
		})(5, [])

		this.state = {
			year: this.years[this.years.length - 1],
			month: this.months[currentMonth]
		}
	}

	handleYearChange = (val) => {
		this.setState({year: val})

		const dateRange = this.getDateRange(new Date(val, this.months.indexOf(this.state.month), 1))
		this.props.onChange(dateRange)
	}

	handleMonthChange = (val) => {
		this.setState({month: val})

		const dateRange = this.getDateRange(new Date(this.state.year, this.months.indexOf(val), 1))
		this.props.onChange(dateRange)
	}

	getCurrentValue = () => {
		return this.getDateRange(new Date(this.state.year, this.months.indexOf(this.state.month), 1))
	}

	getDateRange = (date) => {
		let dateTo = new Date(date)
		dateTo.setMonth(dateTo.getMonth() + 1)
		dateTo.setDate(dateTo.getDate() - 1)

		return {
			from: `${date.getFullYear()}-${padStart(date.getMonth() + 1, 2, "0")}-${padStart(date.getDate(), 2, "0")}`,
			to: `${dateTo.getFullYear()}-${padStart(dateTo.getMonth() + 1, 2, "0")}-${padStart(dateTo.getDate(), 2, "0")}`
		}
	}

	setValues(year, month) {
		this.setState({
			year: year,
			month: this.months[month]
		})
	}

	render() {
		const months = this.months.map((m, idx) => {
			return (
				<MenuItem key={idx} value={m}>
					{m}
				</MenuItem>
			)
		})

		const years = this.years.map((y, idx) => {
			return (
				<MenuItem key={idx} value={y}>
					{y}
				</MenuItem>
			)
		})

		return (
			<FormControl className="xs-period-picker">
				<InputLabel focused={false} shrink={true} htmlFor="xs-period-content">
					{this.props.required ? (
						<span className="xs-bold">
							<FormattedMessage id="Patient.labels.period" /> *
						</span>
					) : (
						<FormattedMessage id="Patient.labels.period" />
					)}
				</InputLabel>

				<div className={`xs-period-content ${this.props.white ? "bgSnowWhite" : ""}`} id="xs-period-content">
					<TextField
						className="xs-period-year"
						value={this.state.year}
						InputLabelProps={{shrink: true}}
						InputProps={{
							className: `xs-period-year-input ${this.props.white ? "bgSnowWhite" : ""}`
						}}
						select
						onChange={(e) => this.handleYearChange(e.target.value)}
						disabled={this.props.disabled}
					>
						{years}
					</TextField>

					<TextField
						className="xs-period-month"
						value={this.state.month}
						InputLabelProps={{shrink: true}}
						InputProps={{
							className: `xs-period-month-input ${this.props.white ? "bgSnowWhite" : ""}`
						}}
						select
						onChange={(e) => this.handleMonthChange(e.target.value)}
						disabled={this.props.disabled}
					>
						{months}
					</TextField>
				</div>
			</FormControl>
		)
	}
}

export default injectIntl(XsPeriodPicker, {
	withRef: true
})
