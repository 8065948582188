import api from "../../../modules/ambulance/actions/api"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import {observable, action} from "mobx"
import DataStore from "../stores/DataStore"
import DDaction from "../actions/patientCard"
import moment from "moment"
import {loadDataToFormBySchemeSync} from "../../../global/helpers/bindings"
import bindings from "../components/patientDetail/patientMedicationForm/patientMedicationBindings"

class PatientMedicationStore {
	// @observable medicationID = null
	// @observable recordLoaded = false
	@observable represcribeID = null
	// @observable represcribeLoaded = false

	@observable validationSnackbar = null

	//flags
	@observable represcribeFlag = false

	medicationRef = null
	ids = []

	@action represcribe(medications) {
		this.ids = medications.map((i) => {
			return i.record_id
		})
		//znovupredpisat pri uz otvorenom formulari
		if (isSafe(this.medicationRef)) {
			let ids = this.ids.slice()
			this.medicationRef.clearAllReqeust(() => {
				this.medicationRef.addForm(medications.length - 1, ids)
			})
			this.ids = []
		}
		DataStore.clearCheckedEHR()
	}

	//znovupredpisat pri uz zatvorenom formulari teda caka kym sa otvori ziska ref a naplni formulare
	@action represcribeIdsToForms() {
		if (isSafe(this.ids) && this.ids.length) {
			let ids = this.ids.slice()
			this.medicationRef.clearAllReqeust(() => {
				this.medicationRef.addForm(ids.length - 1, ids)
			})
		}
		this.ids = []
	}

	@action isGoingToReprescribe() {
		if (isSafe(this.ids) && this.ids.length) {
			return true
		} else {
			return false
		}
	}

	@action storno(id, reason) {
		let requestJSON = {
			_id: id,
			active: false,
			reason: reason,
			_type: "EHR.Data.Medication"
		}

		api
			.stornoRecords(requestJSON)
			.call()
			.then(() => {
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
			})
	}

	@action async loadDefaultDiagnosis(form) {
		const providerId = getSelectedOrgUnitID()
		const patientId = DataStore.patientDTO.get("patientID")
		const filter = {
			filters: [
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: providerId
						}
					]
				},
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: patientId
						}
					]
				},
				{
					associated_column: "type_id",
					values: [
						{
							id_value: "EHR.Data.Ambulance"
						}
					]
				}
			],
			time_from: moment
				.utc()
				.startOf("day")
				.format(),
			time_to: moment
				.utc()
				.endOf("day")
				.format()
		}

		const response = await api.loadEHROverview(filter).call()
		if (isSafe(response) && isSafe(response.rows) && isSafe(response.rows[0]) && isSafe(response.rows[0].diagnosis)) {
			form.$("diagnosis").value = response.rows[0].diagnosis
		}
	}

	@action async loadFormData(form, id) {
		await loadDataToFormBySchemeSync(form, bindings, {prescriptionId: id}, (data) => {
			form.$("medicationType").set("value", data.medication_type)
			form.$("drugs").set("value", data.product_id)
			form.$("unit").set("value", data.dose_unit)
			form.$("medicationRoute").set("value", data.route)
			form.$("batchNumber").set("value", "")
			form.$("expiration").set("value", "")
			form.$("barcode").set("value", "")
			form
				.$("vaccinationType")
				.set(
					"value",
					isSafe(data.vaccination_type) && isSafe(data.vaccination_type._id) ? data.vaccination_type._id : ""
				)
			form
				.$("medicationLocation")
				.set("value", isSafe(data.location) && isSafe(data.location._id) ? data.location._id : "")
			form.$("expiration").set("rules", form.$("medicationType").value === "ADMVACC" ? "required" : "")
		})
	}
}

var singleton = new PatientMedicationStore()
export default singleton
