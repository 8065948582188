import React from "react"
import {MenuItem, Select} from "@material-ui/core"

import "./xsDropDownAction.less"
import {FormattedMessage} from "react-intl"

export default class XsDropDownAction extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false
		}

		this.canClose = true
	}

	handleChange = () => {
		if (this.canClose == false) {
			this.setState({open: true})
		}
	}

	handleClose = () => {
		this.setState({open: false})
	}

	handleOpen = () => {
		this.setState({open: true})
	}

	render() {
		const {items, actionIconClassName, className = "", onSelect} = this.props

		return (
			<div>
				<Select
					open={this.state.open}
					onClose={this.handleClose}
					onOpen={this.handleOpen}
					displayEmpty
					value={""}
					onChange={this.handleChange}
					className={className}
				>
					{
						<MenuItem value="" key={0} onClick={null} disabled>
							<div className="xs-menuItemAction">
								{isSafe(items) && items.length > 0 ? (
									<FormattedMessage id="Patient.form.template.templates" />
								) : (
									<FormattedMessage id="Patient.form.template.noTemplates" />
								)}
							</div>
						</MenuItem>
					}
					{items.map((item, idx) => {
						return (
							<MenuItem value={idx + 1} key={idx + 1} onClick={null}>
								<div
									className="xs-menuItemAction"
									onClick={() => {
										if (isSafe(item.itemData) && isSafe(onSelect) && typeof onSelect == "function") {
											onSelect(item.itemData)
										}
										this.canClose = true
									}}
								>
									{item.label}
								</div>
								<i
									className={actionIconClassName}
									onClick={(e) => {
										this.canClose = false
										item.action(e)
									}}
								/>
							</MenuItem>
						)
					})}
				</Select>
			</div>
		)
	}
}
