import React from "react"

import {FormattedMessage, injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import Popover from "@material-ui/core/Popover"
import {toLatin} from "../../helpers/functions.js"

import "./xsSearchdropdown.less"
import {Checkbox, Typography} from "@material-ui/core"
import SearchFieldNoForm from "./xsSearchfieldNoForm"
import XsButton from "../xsButton/xsButton.js"

@injectIntl
export class SearchDropdownCache extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			openned: false,
			label: props.label,
			selectedItems: isSafe(props.selectedItems) ? props.selectedItems : [],
			anchorEl: null
		}

		this.filteredItems = []
		this.searchText = ""
		this.checkALl = false
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			selectedItems: nextProps.selectedItems
		})
	}

	getDataSource = () => {
		const firstCheckAll = [
			<div key={-1} className="listItem xs-justify-between pr-4">
				<div
					className="secondaryText"
					onClick={() => {
						if (this.checkALl) {
							this.removeAllSelected(this.filteredItems)
						} else {
							this.addAllToSelect(this.filteredItems)
						}
					}}
				>
					{
						<div>
							<Checkbox value={this.checkALl} checked={this.checkALl} className="searchDropdownCheck" />
						</div>
					}
					<div className="secondaryTextLabel">{!this.checkALl ? "Označiť všetko" : "Zrušiť všetko"}</div>
				</div>
				<div
					className="itemValue greenSuccess"
					onClick={() => {
						this.closePop()
					}}
				>
					{<i className="fal fa-check fa-lg " />}
				</div>
			</div>
		]

		const selectedRows =
			this.searchText.length > 0
				? []
				: this.props.sourceItems
						.filter(({_id}) => this.state.selectedItems.includes(_id))
						.map((item, idx) => {
							const checked = this.state.selectedItems.includes(item._id)

							return (
								<div key={idx} className="listItem" onClick={() => this.removeFromSelect(item._id)}>
									{item.code_ext && (
										<div className="secondaryText">
											{
												<div>
													<Checkbox value={item._id} checked={checked} className="searchDropdownCheck" />
												</div>
											}
											<div className="secondaryTextLabel">{item.code_ext}</div>
										</div>
									)}
									<div className="itemValue">{item.name_ext}</div>
								</div>
							)
						})

		const filteredRows = this.filteredItems.map((item, idx) => {
			const checked = this.state.selectedItems.includes(item._id)

			return (
				<div key={idx} className="listItem" onClick={() => this.addToSelect(item._id)}>
					{item.code_ext && (
						<div className="secondaryText">
							{
								<div>
									<Checkbox value={item._id} checked={checked} className="searchDropdownCheck" />
								</div>
							}
							<div className="secondaryTextLabel">{item.code_ext}</div>
						</div>
					)}
					<div className="itemValue">{item.name_ext}</div>
				</div>
			)
		})

		if (selectedRows.length || filteredRows.length) {
			return firstCheckAll.concat(selectedRows, filteredRows)
		} else {
			return selectedRows.concat(filteredRows, [
				<div key={0} className="listItem pa-2">
					Nenašli sa žiadne záznamy
				</div>
			])
		}
	}

	removeFromSelect = (itemId) => {
		this.setState({
			selectedItems: this.state.selectedItems.filter((x) => x != itemId)
		})
	}

	addToSelect = (itemId) => {
		if (this.state.selectedItems.includes(itemId)) {
			this.setState({
				selectedItems: this.state.selectedItems.filter((x) => x != itemId)
			})
		} else {
			let items = this.state.selectedItems.map((x) => x)
			items.push(itemId)

			this.setState({
				selectedItems: items.sort()
			})
		}
	}

	addAllToSelect = (itemIds) => {
		itemIds = itemIds.map((x) => x._id)
		let items = this.state.selectedItems.map((x) => x)
		items.push(...itemIds)
		this.checkALl = true
		this.setState({
			selectedItems: items.sort()
		})
	}

	removeAllSelected = () => {
		this.checkALl = false
		this.setState({
			selectedItems: []
		})
	}

	togglePop = () => {
		this.setState({
			openned: !this.state.openned,
			anchorEl: document.getElementById(this.props.id)
		})
	}

	closePop = () => {
		this.setState(
			{
				openned: false
			},
			() => {
				this.props.onClose(this.state.selectedItems)
				this.filteredItems = []
				this.searchText = ""
			}
		)
	}

	onSearchChange = (text) => {
		delayedCallback(300, () => {
			const regexText = toLatin(text)

			let filteredItems = []
			if (regexText.length > 0 && regexText.length < 3) {
				filteredItems = this.props.sourceItems.filter(({code_ext}) =>
					code_ext.toLowerCase().startsWith(regexText.toLowerCase())
				)
			}
			if (regexText.length >= 3 || (filteredItems.length == 0 && regexText.length > 0)) {
				filteredItems = this.props.sourceItems.filter(({search_column}) =>
					search_column.toLowerCase().includes(regexText.toLowerCase())
				)
				filteredItems = filteredItems.slice(0, 100)
			}

			this.filteredItems = filteredItems
			this.searchText = regexText
			this.forceUpdate()
		})
	}

	renderTooltipText = (defaultLabel) => {
		const splitted = this.props.sourceItems
			.filter(({_id}) => this.state.selectedItems.includes(_id))
			.map((x) => x.code_ext)
			.join()
		// const splitted = Object.values(this.selectedItemsData).join()

		if (isNotEmpty(splitted)) {
			return (
				<span data-tip data-for="searchDropdownTooltip">
					{splitted.length > 20 ? splitted.substr(0, 18) + ".." : splitted}
					<ReactTooltip id="searchDropdownTooltip">{splitted.replace(/,/g, ", ")}</ReactTooltip>
				</span>
			)
		} else {
			return defaultLabel
		}
	}

	render() {
		const {id, disabled, multiSelect, hideSearch, style} = this.props
		const {openned, anchorEl, label} = this.state
		const labelClass = openned ? "title active" : "title"
		const headerClass = openned ? "header xs-active" : "header"
		const isSomeChecked = this.state.selectedItems.length > 0

		return (
			<div className={`dropdownAutoComplete${isSomeChecked ? " filtered" : ""}`} id={id} style={style}>
				{disabled ? (
					<div className="header disabled">
						<div className="title disabled">
							<span>{label}</span>
						</div>
						<div className="arrow disabled" />
					</div>
				) : (
					<div>
						<div className={headerClass} onClick={this.togglePop}>
							<div className={labelClass}>
								<div>
									{this.state.openned ? label : this.renderTooltipText(label)}
									{isSomeChecked && <span className="titleBadge">{this.state.selectedItems.length}</span>}
								</div>
							</div>
							<div className="arrow" />
						</div>
						<div className="errorText">{}</div>
						<Popover
							open={openned}
							anchorEl={anchorEl}
							anchorOrigin={{horizontal: "left", vertical: "bottom"}}
							onClose={this.closePop}
						>
							<div className="searchListBody">
								{!hideSearch && (
									<SearchFieldNoForm
										onChange={this.onSearchChange}
										placeholder={this.props.intl.formatMessage({id: "Common.searchDropdown.search"})}
									/>
								)}
								<div className="searchListItems">{this.getDataSource()}</div>
								{multiSelect && (
									<div className="checkAllContainer">
										{/* <Typography>
											<XsButton
												text={<FormattedMessage id="Common.searchDropdown.selectAll" />}
												className="checkAllFormControl"
												onClick={() => this.selectAll(true)}
											/>
										</Typography> */}
										<Typography>
											<XsButton
												text={<FormattedMessage id="Common.searchDropdown.deselectAll" />}
												className="checkAllFormControl"
												onClick={() => {
													this.checkALl = false
													this.setState({
														selectedItems: []
													})
												}}
											/>
										</Typography>
									</div>
								)}
							</div>
						</Popover>
					</div>
				)}
			</div>
		)
	}
}

export default SearchDropdownCache
