"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import settings from "../../../global/config/settings"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"

class EHealthRecordDialogStore {
	htmlData = ""
	@observable isOpen = false
	@observable isFetching = false

	@action open(recordId) {
		this.isOpen = true

		this.isFetching = true

		api
			.loadRecord(recordId, "L4")
			.call()
			.then((data) => {
				if (isSafe(data.identifiers) && isSafe(data.identifiers.find((obj) => obj.type === "NCZIRECIDENT"))) {
					let identifier = data.identifiers.find((obj) => obj.type === "NCZIRECIDENT")
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					fetch(`${settings.GW_BASE_URL}/TransformXmlToHtml`, {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							XmlData: data.xml,
							ExtId: identifier.value,
							AmbeePatientId: isSafe(DataStore.patientDTO.get("patientID"))
								? DataStore.patientDTO.get("patientID")
								: undefined,
							PatientIdentificationNumber: isSafe(DataStore.patientDTO.get("patient"))
								? DataStore.patientDTO.get("patient").identifier
								: undefined,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
						})
					})
						.then((resp) => resp.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((data) => {
							if (this.isOpen) {
								this.htmlData = b64DecodeUnicode(data.HtmlData)
							}
							this.isFetching = false
						})
						.catch(() => {
							this.isFetching = false
						})
				}
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action close() {
		this.isOpen = false

		this.htmlData = ""
	}
}

var singleton = new EHealthRecordDialogStore()
export default singleton
