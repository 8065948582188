import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import {FormattedMessage, injectIntl} from "react-intl"
import validatorjs from "validatorjs"
// import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import "./exportDeliveries.less"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import SearchSelectNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"

import fields from "./exportDeliveriesFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import InvoiceEnterDialogStore from "../../../stores/InvoiceEnterDialogStore"
import {Grid} from "@material-ui/core"
// import api from "../../../actions/api"

@injectIntl
@observer
export default class EnterInvoiceNumberDialog extends React.Component {
	constructor(props) {
		super(props)

		// InvoiceEnterDialogStore.insurers;
		// InvoiceEnterDialogStore.disposition = [
		//   { secondaryText: "N", primaryText: "nová (SK poistenci)", search_string: "N - nová (SK poistenci)", id: "N", checkValue: true },
		//   { secondaryText: "E", primaryText: "nová (EU poistenci)", search_string: "E - nová (EU poistenci)", id: "E", checkValue: true },
		//   { secondaryText: "I", primaryText: "nová (Cudzinci a bezdomovci)", search_string: "I - nová (Cudzinci a bezdomovci)", id: "I", checkValue: true },
		// ];
		const hooks = {
			onSubmit() {
				// special validation here
			},
			onSuccess(form) {
				InvoiceEnterDialogStore.downloadMultipleDeliveries(form)
			},
			onError() {}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		InvoiceEnterDialogStore.formRef = this.form

		if (props.isOtherDeliveries) {
			this.form.$("payerType").set("rules", "required")
		}
		// default value
		// api
		// 	.getDefaultBatchType({org_unit: getSelectedOrgUnitID()})
		// 	// .call()
		// 	.then((res) => {
		// 		this.form.$("batchType").set("value", res.code_ext)
		// 	})
		// this.form.$("batchType").set("value", "751a")
	}

	onSubmitClick = () => {
		Object.entries(this.form.values()).forEach((obj) => {
			InvoiceEnterDialogStore.handleChange(obj[0], obj[1])
		})

		InvoiceEnterDialogStore.getSummaryDeliveries()
	}

	getInsurersForDropdown = () => {
		if (isSafe(this.props.insurers) && !InvoiceEnterDialogStore.insurersData.length) {
			this.props.insurers.forEach((row) => {
				InvoiceEnterDialogStore.insurersData.push({
					id: row._id,
					secondaryText: isNotEmpty(row.company_short_name) ? row.company_short_name : "  ",
					primaryText: row.description,
					search_string: row.search_column,
					checkValue: false
				})
			})
		}
		return InvoiceEnterDialogStore.insurersData
	}

	handleRows = () => {
		this.form.update({rows: []})
		InvoiceEnterDialogStore.insurers.forEach((ins) => {
			InvoiceEnterDialogStore.disposition.forEach((disp) => {
				this.form.$("rows").add(
					{
						key: ins.id + disp.id,
						label: `${this.props.intl.formatMessage({id: "Invoice.form.number"})} ${ins.secondaryText} ${
							disp.primaryText
						}`,
						type: "text",
						rules: "required"
					},
					true
				)
			})
		})
		// logger(InvoiceEnterDialogStore.disposition)
	}

	componentDidMount() {
		InvoiceEnterDialogStore.formRef = this.form
	}

	render() {
		return (
			<Dialog
				id="xsExportDeliveries"
				open={InvoiceEnterDialogStore.isOpen}
				onClose={() => InvoiceEnterDialogStore.close()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-save fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Capitation.form.ExportBatch" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row">
								<Grid item xs={12}>
									<SearchSelect
										field={this.form.$("batchType")}
										items={[
											{name_ext: "751a", _id: "751a"},
											{name_ext: "753a", _id: "753a"}
										]}
									/>
								</Grid>
							</Grid>
							{this.props.isOtherDeliveries && (
								<Grid container direction="row">
									<Grid item xs={12}>
										<SearchSelect
											field={this.form.$("payerType")}
											items={isSafe(this.props.payers) ? this.props.payers : []}
										/>
									</Grid>
								</Grid>
							)}
							<Grid container direction="row">
								<Grid item xs={12}>
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.insurers" />}*</div>
										<SearchSelectNoForm
											id="insurers"
											// field={this.form.$("insurers")}
											items={this.getInsurersForDropdown()}
											multiSelect={true}
											onClose={(data) => {
												// this.form.$("insurers").set(data)
												InvoiceEnterDialogStore.insurers = data
												this.handleRows()
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.ReportType" />}*</div>
										<SearchSelectNoForm
											id="ReportType"
											items={InvoiceEnterDialogStore.dispositionData}
											multiSelect={true}
											onClose={(data) => {
												InvoiceEnterDialogStore.disposition = data
												this.handleRows()
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									{this.form.$("rows").map((fieldRow) => {
										return <XsInput field={fieldRow} key={fieldRow.key} />
									})}
									{/* <XsInput field={this.form.$("invoiceNumber")}
                    placeholder={this.props.intl.formatMessage({ id: "Invoice.form.optionalInvoiceNumber" })}
                  /> */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (InvoiceEnterDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						className={
							InvoiceEnterDialogStore.insurers.length == 0 ||
							InvoiceEnterDialogStore.disposition.length == 0 ||
							!this.form.isValid
								? "xs-default"
								: "xs-success"
						}
						disabled={
							InvoiceEnterDialogStore.insurers.length == 0 ||
							InvoiceEnterDialogStore.disposition.length == 0 ||
							!this.form.isValid
						}
						icon={<i className="fal fa-save" />}
						text={<FormattedMessage id="Capitation.form.ExportBatch" />}
						onClick={(e) => this.form.onSubmit(e) /* this.onSubmitClick()*/}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
