import React, {useState, useEffect /*useRef , Suspense, lazy*/} from "react"
// import V from './V'

// const V1 = lazy(() => import('./V1'))
// const V2 = lazy(() => import('./V2'))
// const Long = lazy(() => import('./L'))

import V1 from "./V1"
import V2 from "./V2"
import Long from "./L"

function C() {
	const [data1, setData1] = useState(null)
	const [data2, setData2] = useState(null)
	const [longData, setLongData] = useState(null)

	// const [lastdata1, setlastData1] = useState(null)
	// const [lastdata2, setlastData2] = useState(null)

	useEffect(() => {
		logger("v2 start fetching data")
		async function fetchData() {
			const response = await fetch("https://api.github.com/search/users?q=yomete")
			const data = await response.json()
			logger("v2 data arrived", data.items)
			setData2(data.items)
		}
		fetchData()
	}, [])

	useEffect(() => {
		logger("v1 start fetching data")
		async function fetchData() {
			const response = await fetch("https://jsonplaceholder.typicode.com/users")
			const data = await response.json()
			logger("v1 data arrived", data)
			setData1(data)
		}
		fetchData()
	}, [])

	useEffect(() => {
		logger("start loooong fetching data")
		async function fetchData() {
			const response = await new Promise((resolve) => setTimeout(resolve, 10000))
			const data = await response
			logger("loooong data arrived", data)
			setLongData({data})
		}
		fetchData()
	}, [])

	// useInterval(() => {
	//     if (data1 && data2) {
	//         const actualData1 = JSON.stringify(data1)
	//         const actualData2 = JSON.stringify(data2)
	//         logger('MAIN tick tock')
	//         if (lastdata1 !== actualData1) {
	//             logger('MAIN v1 change occured')
	//             setlastData1(actualData1)
	//             // SetData(actualData)
	//             // SetModified(true)
	//         }
	//         if (lastdata2 !== actualData2) {
	//             logger('MAIN v2 change occured')
	//             setlastData2(actualData2)
	//             // SetData(actualData)
	//             // SetModified(true)
	//         }
	//     }
	// }, 200)

	return (
		<div>
			{/* <Suspense fallback={<p>loading....</p>}> */}
			{data1 && data2 ? (
				<div>
					<V1 data={data1} />
					<V2 data={data2} />
					<p></p>
					<button onClick={() => logger(data1, data2)}>data to console</button>
				</div>
			) : (
				<p>loading....</p>
			)}
			{/* </Suspense> */}

			{/* <Suspense fallback={<p>Loading loooong data ...............</p>}> */}
			{longData ? <Long data={longData} /> : <p>Loading loooong data ...............</p>}
			{/* </Suspense> */}

			<p>iam here</p>
		</div>
	)
}

export default C
