import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"

import api from "../../../actions/api"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"

import NonCapitationFilter from "./nonCapitationFilter"

import RouterStore from "../../../../../global/store/RouterStore"
import NonCapitationStore from "../../../stores/NonCapitationStore"

@injectIntl
@observer
export default class NonCapitationList extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	}

	render() {
		return (
			<Grid container>
				<Grid item xs={12}>
					<NonCapitationFilter />
				</Grid>
				{NonCapitationStore.changedFilters ? (
					<Grid item xs={12} className="xs-table-no-data pa-3 borderRadius-1 mt-8">
						<i
							className="far fa-search fa-lg mr-3 xs-pointer"
							onClick={() => NonCapitationStore.getFilters(NonCapitationStore.formRef)}
						></i>
						<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
					</Grid>
				) : (
					<Grid item xs={12}>
						<XsTableServer
							uri={`${api.loadNonCapitation().getPath()}?level_flag=CD`}
							filterValue={NonCapitationStore.filters}
							config={{
								columnDefs: {
									full_name: {
										title: <FormattedMessage id="Common.label.firstName" />,
										type: "string",
										design: {
											width: "40%",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true,
										dbName: "full_name"
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "number",
										design: {
											width: "15%"
										},
										sortable: true,
										dbName: "identifier"
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "15%"
										},
										sortable: false
									}
								},
								options: {
									showCursor: true,
									mapper: (dataRow) => {
										return {
											person_id: dataRow.person_id,
											full_name: dataRow.full_name,
											identifier: dataRow.identifier,
											insurer: isSafe(dataRow.insurer) ? dataRow.insurer.name_ext : ""
										}
									},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									paging: {
										rowsPerPageOptions: [10, 15]
									}
								}
							}}
						/>
					</Grid>
				)}
			</Grid>
		)
	}
}
