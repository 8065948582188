"use strict"
import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"
import WarningStore from "../../../global/store/WarningStore"
import UIStore from "./UIStore"

class DrugReportStore {
	@observable drugList = []
	@observable isOpen = false

	drugReportListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.registrator != formValues.registrator ||
			this.currFilters.productFilter != formValues.productFilter ||
			this.currFilters.orgUnit != formValues.orgUnit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadDrugReport(form, errorText) {
		this.currFilters = form.values()

		let filters = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				}
			]
		}

		UIStore.isFormSaving = true
		let refFormValues = form.values()
		if (isNotEmpty(refFormValues.datefrom)) {
			filters["time_from"] = moment(refFormValues.datefrom)
				.startOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.dateto)) {
			filters["time_to"] = moment(refFormValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		} else {
			WarningStore.open(errorText)
			UIStore.isFormSaving = false
			return
		}

		if (isNotEmpty(refFormValues.productFilter)) {
			filters.filters.push({
				associated_column: "product_id",
				values: [
					{
						id_value: refFormValues.productFilter
					}
				]
			})
		}

		if (isNotEmpty(refFormValues.registrator)) {
			filters.filters.push({
				associated_column: "registrator",
				values: [
					{
						id_value: refFormValues.registrator
					}
				]
			})
		}

		if (isNotEmpty(refFormValues.orgUnit)) {
			filters.filters.push({
				associated_column: "org_unit_id",
				values: [
					{
						id_value: refFormValues.orgUnit
					}
				]
			})
		}

		api
			.loadDrugReport(filters)
			.call()
			.then((response) => {
				this.drugList = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action exportToXls(dialogForm, filterForm) {
		let req = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				}
			],
			uri: `/amb/report/drugs`
		}

		if (dialogForm.values().exportType == "now") {
			if (isSafe(this.drugReportListRef)) {
				req.row_count_show = this.drugReportListRef.state.rowsPerPage
				// req.row_count_full = this.drugReportListRef.state.rowsPerPage
				req.row_offset = this.drugReportListRef.state.page * this.drugReportListRef.state.rowsPerPage + 1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
				// req.row_count_full = 10
			}
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			req.row_count_full = 100000
		}

		let refFormValues = filterForm.values()
		if (isNotEmpty(refFormValues.datefrom)) {
			req["time_from"] = moment(refFormValues.datefrom)
				.startOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(refFormValues.dateto)) {
			req["time_to"] = moment(refFormValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(refFormValues.orgUnit)) {
			req.filters.push({
				associated_column: "org_unit_id",
				values: [
					{
						id_value: refFormValues.orgUnit
					}
				]
			})
		}


		if (isNotEmpty(dialogForm.values().exportType) && dialogForm.values().exportType != "all") {
			if (isNotEmpty(refFormValues.productFilter)) {
				req.filters.push({
					associated_column: "product_id",
					values: [
						{
							id_value: refFormValues.productFilter
						}
					]
				})
			}

			if (isNotEmpty(refFormValues.registrator)) {
				req.filters.push({
					associated_column: "registrator",
					values: [
						{
							id_value: refFormValues.registrator
						}
					]
				})
			}
		}

		api
			.exportData(req)
			.call()
			.then((response) => {
				this.close()
				dialogForm.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})

		this.close()
	}
}

var singleton = new DrugReportStore()
export default singleton
