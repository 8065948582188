"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../global/helpers/actions"
import DDaction from "../actions/patientCard"
import DataStore from "./DataStore"
import api from "../actions/api"
import settings from "../../../global/config/settings"
import WarningStore from "../../../global/store/WarningStore"

class StornoRequestDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable reason = ""

	@action open(id) {
		this.isOpen = true
		this.recordId = id
	}

	@action onHandleChange(value) {
		this.reason = value
	}

	@action storno() {
		let request = {
			_id: this.recordId,
			active: false,
			reason: this.reason,
			_type: "EHR.Data.Record"
		}
		api
			.stornoRecords(request)
			.call()
			.then((res) => {
				//Zrušíme označenie záznamu EHR
				DataStore.removeFromCheckedEHR({record_id: this.recordId})

				if (
					isSafe(res) &&
					isSafe(res.ixs_signer_data) &&
					isSafe(res.ixs_signer_data.payload) &&
					isNotEmpty(res.ixs_signer_data.payload.text)
				) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							ClassName: "StornujZaznamOVysetreni_v5",
							ExtId: res.record_external_id,
							EHRExtract: res.ixs_signer_data.payload.text,
							TimeCommitted: res.actual_time,
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(res.record_signer_data)
								? res.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((res) => {
							api.setSent(res.ixs_signer_data.ext_id).call()
							if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
								WarningStore.open(res.ErrorMessage)
							}
						})
						.catch(() => {})
				}

				this.close()
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
			})
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.reason = ""
	}
}

var singleton = new StornoRequestDialogStore()
export default singleton
