import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Grid} from "@material-ui/core"

import TablePaginationActions from "../../../../../global/ui/xsTableServer/xsTablePaginationActions"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import ExportPatientWithPPToXlsDialog from "./exportPatientWithPPToXlsDialog"
import PatientsWithPPStore from "../../../stores/PatientsWithPPStore"
import RouterStore from "../../../../../global/store/RouterStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

// definitions of form fields
import fields from "./patientsWithPPListFields"

import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class PatientsWithPPList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			page: 0,
			rowsPerPage: 10,
			order: false,
			orderBy: null,
			sortType: "",
			filters: {
				time_from: moment()
					.startOf("month")
					.format("YYYY-MM-DD"),
				time_to: moment()
					.endOf("month")
					.format("YYYY-MM-DD"),
				insurance_type_id: null
			},
			loadingInsurers: true
		}
		PatientsWithPPStore.page = 0
		PatientsWithPPStore.rowsPerPage = 10

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		PatientsWithPPStore.getFilters(this.form, this.state)
	}

	componentWillUnmount() {
		if (isSafe(PatientsWithPPStore.refreshInterval)) {
			clearInterval(PatientsWithPPStore.refreshInterval)
		}
	}

	onRowClick = (dataRow) => {
		if (dataRow.person_id) {
			RouterStore.push(`/patient?id=${dataRow.person_id}`)
		}
	}

	handleChangePage = (event, page) => {
		this.setState(
			{
				page
			},
			PatientsWithPPStore.prepareReport({rowsPerPage: this.state.rowsPerPage, page: page})
		)
	}

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				rowsPerPage: event.target.value
			},
			PatientsWithPPStore.prepareReport(this.state)
		)
	}

	render() {
		PatientsWithPPStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("last_date_from")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("last_date_to")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("identifier")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={() => PatientsWithPPStore.getFilters(this.form, this.state)}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className={"xs-primary"}
										text={<FormattedMessage id="Common.label.exportToExcel" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											PatientsWithPPStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{PatientsWithPPStore.changedFilters ? (
						<Grid container direction="column">
							<Grid item>
								<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i
										className="far fa-search fa-lg mr-3 xs-pointer"
										onClick={() => PatientsWithPPStore.getFilters(this.form, this.state)}
									></i>
									<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
								</div>
							</Grid>
						</Grid>
					) : (
						<Grid container direction="column">
							<Grid item>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>
												<FormattedMessage id="Common.label.fullName" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Patient.labels.IDNumber" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.insurer" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.dateOfLastPP" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.dateOfNextPP" />
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{isSafe(PatientsWithPPStore.patientWithData) && PatientsWithPPStore.patientWithData.length > 0 ? (
											PatientsWithPPStore.patientWithData.map((currRow, idx) => {
												return (
													<TableRow key={idx}>
														<TableCell className="blueDark xs-pointer xs-bold" onClick={() => this.onRowClick(currRow)}>
															{currRow.full_name}
														</TableCell>
														<TableCell>{isSafe(currRow.identifier) ? currRow.identifier : ""}</TableCell>
														<TableCell>{isSafe(currRow.insurer) ? currRow.insurer.name_ext : ""}</TableCell>
														<TableCell>
															{isSafe(currRow.last_date) ? moment(currRow.last_date).format("DD.MM.YYYY") : ""}
														</TableCell>
														<TableCell>
															{isSafe(currRow.next_date) ? moment(currRow.next_date).format("DD.MM.YYYY") : ""}
														</TableCell>
													</TableRow>
												)
											})
										) : (
											<TableRow key={1}>
												<TableCell className="xs-table-no-data" colSpan={5}>
													<FormattedMessage id={PatientsWithPPStore.infoMessage} />
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Grid>
							<Grid item>
								<div className="xs-grid-footer">
									<TablePagination
										component="div"
										className="xs-table-pagination"
										count={PatientsWithPPStore.totalCount}
										// disabled={this.state.loadingInsurers}
										rowsPerPage={this.state.rowsPerPage}
										rowsPerPageOptions={[5, 10, 25, 50]}
										labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
										labelDisplayedRows={(pager) =>
											`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${
												pager.count
											}`
										}
										page={this.state.page}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
				<ExportPatientWithPPToXlsDialog />
			</Grid>
		)
	}
}
