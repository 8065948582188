import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./preventiveExaminationAdultsFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

@injectIntl
@observer
export default class PreventiveExaminationAdultsTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.form.$("ovHabitus").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovHabitusDV"
		})
		this.form.$("ovHlava").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovHlavaDV"
		})
		this.form.$("ovKrk").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovKrkDV"
		})
		this.form.$("ovHrudnik").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovHrudnikDV"
		})
		this.form.$("ovPulmo").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovPulmoDV"
		})
		this.form.$("ovCor").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovCorDV"
		})
		this.form.$("ovAbdomen").value = this.props.intl.formatMessage({
			id: "PrintTemplate.PreventiveExaminationAdults.ovAbdomenDV"
		})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datumPoslednejPP")} showTimeSelect={false} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("anamPopisTazkosti")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamPrekonaneChoroby")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamSkodliveNavyky")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamKontrolaOckovania")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamRA")} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("ovHabitus")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovHlava")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovKrk")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovHrudnik")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovPulmo")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovCor")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovAbdomen")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovUrogenitalnyAparat")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovPohybovyAparat")} />
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovVyska")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovHmotnost")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovTeplota")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovFW")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovTK")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovPF")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovMocChem")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovEKG")} />
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovCelkovyCholesterol")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ovTriaglyceridy")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovVysetrenieStolice")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("ovInePotrebneVysetrenie")} />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("zaverPP")} hideToolbar={true} className="xs-height-200" />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("odporucenie")} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("miesto")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datum")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
