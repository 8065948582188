"use strict"
import api from "../../modules/ambulance/actions/api"
import codeListApi from "../../global/config/api"
import {observable, action} from "mobx"
import {get, set, clear} from "idb-keyval"
// import {getUser} from "../helpers/actions"
import {codeListsConfig} from "../../global/config/codeLists"
import GlobaStore from "../../global/store/GlobalStore"
import {toJS} from "mobx"

class RegistersCacheStore {
	@observable registersLoaded = true
	registersLoading = false
	diagnosisRegister = []
	deliveriesRegister = []
	insurersRegister = []

	@action async loadDiagnoses() {
		const val = await get("diagnosis")
		this.diagnosisRegister = val
		if (isNotSafe(this.diagnosisRegister)) {
			let diagnosisRegister = null
			try {
				diagnosisRegister = await api
					.loadDiagnosis({row_count_show: 1000000, row_offset: 1, row_count_full: 1000000})
					.call()
			} catch (error) {
				diagnosisRegister = null
			}
			if (isSafe(diagnosisRegister) && isSafe(diagnosisRegister.rows)) {
				this.diagnosisRegister = diagnosisRegister.rows
				await set("diagnosis", diagnosisRegister.rows)
			}
		}
		// this.registersLoaded = true
		// this.loadingRegisters = false
		// this.logLength()
	}

	@action async loadDeliveries() {
		const val = await get("deliveries")
		this.deliveriesRegister = val
		if (isNotSafe(this.deliveriesRegister)) {
			let deliveriesRegister = null
			try {
				deliveriesRegister = await api
					.loadSpecificDelivery({row_count_show: 1000000, row_offset: 1, row_count_full: 1000000})
					.call()
			} catch (error) {
				deliveriesRegister = null
			}
			if (isSafe(deliveriesRegister) && isSafe(deliveriesRegister.rows)) {
				this.deliveriesRegister = deliveriesRegister.rows
				await set("deliveries", deliveriesRegister.rows)
			}
		}
		// this.registersLoaded = true
		// this.loadingRegisters = false
		// this.logLength()
	}

	@action async loadInsurers() {
		const val = await get("insurers")
		this.insurersRegister = val
		if (isNotSafe(this.insurersRegister)) {
			let insurersRegister = null
			try {
				insurersRegister = await api
					.loadInsurers({row_count_show: 1000000, row_offset: 1, row_count_full: 1000000})
					.call()
			} catch (error) {
				insurersRegister = null
			}
			if (isSafe(insurersRegister) && isSafe(insurersRegister.rows)) {
				this.insurersRegister = insurersRegister.rows
				await set("insurers", insurersRegister.rows)
			}
		}
	}

	@action async loadCodelists() {
		const val = await get("codeLists")
		if (isSafe(val)) {
			GlobaStore.CL = val
		} else {
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let val = await codeListApi.loadCodeList(cl.uri)
				if (cl.name == "allergens") {
					GlobaStore.CL[cl.name] = val.rows.sort((a, b) =>
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				} else {
					GlobaStore.CL[cl.name] = val.rows
				}
			}
			await set("codeLists", toJS(GlobaStore.CL))
		}
	}

	@action async checkRegistersTest() {
		let promise = new Promise((resolve) => {
			setTimeout(() => resolve(true), 2000)
		})
		let result = await promise
		return result
	}

	//Kontrola ci su loadnute v store data, v pripade ze je uzivatel na login obrazovke vymaze sa obsah IndexedDB a po logine zbehne kontrola a loadnu sa data z BE
	@action async checkRegisters(route) {
		if (route != "/" && route != "/login") {
			if (!this.registersLoading && (Object.keys(GlobaStore.CL).length == 0 || this.diagnosisRegister.length == 0)) {
				this.registersLoading = true
				this.registersLoaded = false
				if (Object.keys(GlobaStore.CL).length == 0) {
					//Load vsetkych CL do IndexedDB
					await this.loadCodelists()
				}
				if (this.diagnosisRegister.length == 0) {
					//Load diagnoz
					await this.loadDiagnoses()
				}
				if (this.insurersRegister.length == 0) {
					//load poistovni
					await this.loadInsurers()
				}

				// if (this.deliveriesRegister.length == 0) {
				// 	//Load vykonov
				// 	await this.loadDeliveries()
				// }

				this.registersLoading = false
				this.registersLoaded = true
			}
		} else {
			this.clearRegisters()
		}
	}

	//Len na kontrolu pri debugovani
	@action logLength() {
		setTimeout(() => {
			logger("LENGHTS", this.diagnosisRegister.length)
		}, 100)
	}

	//Vymaze cely DB storage
	@action clearRegisters() {
		this.registersLoading = true
		clear()
		this.diagnosisRegister = []
		this.deliveriesRegister = []
		this.insurersRegister = []
		this.registersLoading = false
	}
}
var singleton = new RegistersCacheStore()
export default singleton
