import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./coronaFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import GlobalStore from "../../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class CoronaTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		this.sortedCountries = isSafe(GlobalStore.CL["countries"])
			? GlobalStore.CL["countries"].slice().sort((a, b) => a.item_order - b.item_order)
			: []

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("emailForm").value) && isNotEmpty(this.form.$("email").value)) {
			this.form.$("emailForm").value = this.form.$("email").value
		}
		if (isEmpty(this.form.$("phoneForm").value) && isNotEmpty(this.form.$("phone").value)) {
			this.form.$("phoneForm").value = this.form.$("phone").value
		}
		if (isEmpty(this.form.$("cityForm").value) && isNotEmpty(this.form.$("city").value)) {
			this.form.$("cityForm").value = this.form.$("city").value
		}
		if (isEmpty(this.form.$("streetForm").value) && isNotEmpty(this.form.$("street").value)) {
			this.form.$("streetForm").value = this.form.$("street").value
		}
		if (isEmpty(this.form.$("house_numberForm").value) && isNotEmpty(this.form.$("house_number").value)) {
			this.form.$("house_numberForm").value = this.form.$("house_number").value
		}
		if (isEmpty(this.form.$("postcodeForm").value) && isNotEmpty(this.form.$("postcode").value)) {
			this.form.$("postcodeForm").value = this.form.$("postcode").value
		}

		if (
			isEmpty(this.form.$("vOrderingHealthProfessionalPhone").value) &&
			isNotEmpty(this.form.$("orgunitmobile").value)
		) {
			this.form.$("vOrderingHealthProfessionalPhone").value = this.form.$("orgunitmobile").value
		}
		if (
			isEmpty(this.form.$("vOrderingHealthProfessionalEmail").value) &&
			isNotEmpty(this.form.$("orgunitemail").value)
		) {
			this.form.$("vOrderingHealthProfessionalEmail").value = this.form.$("orgunitemail").value
		}

		// premapovanie
		if (isEmpty(this.form.$("vNationality").value) && isNotEmpty(this.form.$("citizenshipCode").value)) {
			this.form.$("vNationality").value = this.form.$("citizenshipCode").value
		}
		if (isEmpty(this.form.$("vSex").value) && isNotEmpty(this.form.$("sex_default").value)) {
			this.form.$("vSex").value = this.form.$("sex_default").value
		}
		if (isEmpty(this.form.$("dDateOfBirth").value) && isNotEmpty(this.form.$("birthdateISO").value)) {
			this.form.$("dDateOfBirth").value = this.form.$("birthdateISO").value
		}
		if (isEmpty(this.form.$("vOrderingHealthProfessionalCode").value) && isNotEmpty(this.form.$("doctorcode").value)) {
			this.form.$("vOrderingHealthProfessionalCode").value = this.form.$("doctorcode").value
		}
		if (isEmpty(this.form.$("vOrderingCareProviderCode").value) && isNotEmpty(this.form.$("orgunitcode").value)) {
			this.form.$("vOrderingCareProviderCode").value = this.form.$("orgunitcode").value
		}
		if (
			isEmpty(this.form.$("vOrderingHealthProfessionalAddress").value) &&
			isNotEmpty(this.form.$("orgunitaddress").value)
		) {
			this.form.$("vOrderingHealthProfessionalAddress").value = this.form.$("orgunitaddress").value
		}
		if (
			isEmpty(this.form.$("vOrderingHealthProfessionalSurname").value) &&
			isNotEmpty(this.form.$("doctor_last_name").value)
		) {
			this.form.$("vOrderingHealthProfessionalSurname").value = this.form.$("doctor_last_name").value
		}
		if (
			isEmpty(this.form.$("vOrderingHealthProfessionalGivenName").value) &&
			isNotEmpty(this.form.$("doctor_first_name").value)
		) {
			this.form.$("vOrderingHealthProfessionalGivenName").value = this.form.$("doctor_first_name").value
		}
		if (isEmpty(this.form.$("vOrderingCareProviderName").value) && isNotEmpty(this.form.$("companyName").value)) {
			this.form.$("vOrderingCareProviderName").value = this.form.$("companyName").value
		}
		if (isEmpty(this.form.$("vOrderingCareProviderCIN").value) && isNotEmpty(this.form.$("companyIdentifier").value)) {
			this.form.$("vOrderingCareProviderCIN").value = this.form.$("companyIdentifier").value
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<div style={{fontWeight: "bold", paddingBottom: "20px"}}>Údaje o lekárovi</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("vOrderingHealthProfessionalPhone")} />
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("vOrderingHealthProfessionalEmail")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("vOrderingHealthProfessionalAddress")} />
					</Grid>

					<div style={{marginTop: "20px", marginBottom: "20px", fontWeight: "bold", fontSize: "17px"}}>
						Osobné údaje
					</div>

					<div style={{marginTop: "20px", fontWeight: "bold"}}>Údaje žiadateľa o vyšetrenie</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("phoneForm")} />
						</Grid>
						<Grid item xs={5} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("emailForm")} />
						</Grid>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("vIDCardNumber")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<SearchSelect
								field={this.form.$("vOrderDiagnose")}
								items={[
									{
										code: "112330",
										code_ext: "U07.1",
										description: "",
										item_order: 18130,
										life_threatening: false,
										name_ext: "Potvrdená infekcia COVID-19",
										parent_code: "U07",
										search_column: "u07.1 potvrdena infekcia covid-19 u071",
										supplementary_sign: "",
										_id: "112330"
									},
									{
										code: "112331",
										code_ext: "U07.2",
										description: "",
										item_order: 18131,
										life_threatening: false,
										name_ext: "Podozrenie z infekcie COVID-19",
										parent_code: "U07",
										search_column: "u07.2 podozrenie z infekcie covid-19 u072",
										supplementary_sign: "",
										_id: "112331"
									}
								]}
							/>
						</Grid>
						<Grid item xs={6}>
							<SearchSelect field={this.form.$("vNationality")} items={this.sortedCountries} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", fontWeight: "bold"}}>
						Údaje osoby, ktorá bude v prípade potreby kontaktovaná (ICE kontakt)
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("iceFirstName")} />
						</Grid>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("iceLastName")} />
						</Grid>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("iceEmail")} />
						</Grid>
						<Grid item xs={3} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("icePhone")} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>Adresa trvalého pobytu</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("cityForm")} />
						</Grid>
						<Grid item xs={4} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("streetForm")} />
						</Grid>
						<Grid item xs={2} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("house_numberForm")} />
						</Grid>
						<Grid item xs={2} style={{paddingTop: "30px"}}>
							<XsInput field={this.form.$("postcodeForm")} />
						</Grid>
					</Grid>

					<XsCheckbox field={this.form.$("homePlaced")} />

					{this.form.$("homePlaced").value == false && (
						<React.Fragment>
							<div style={{marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>Prechodný pobyt</div>
							<div style={{marginTop: "20px", fontWeight: "bold"}}>
								Adresu prechodného pobytu vyplňte v prípade, že sa zdržiavate inde ako v mieste trvalého bydliska. Ak
								ste v karanténe, vyplňte adresu miesta, kde sa zdržiavate.
							</div>
							<Grid container direction="row" align="left" spacing={8}>
								<Grid item xs={4} style={{paddingTop: "30px"}}>
									<XsInput field={this.form.$("other_city")} />
								</Grid>
								<Grid item xs={4} style={{paddingTop: "30px"}}>
									<XsInput field={this.form.$("other_street")} />
								</Grid>
								<Grid item xs={2} style={{paddingTop: "30px"}}>
									<XsInput field={this.form.$("other_house_number")} />
								</Grid>
								<Grid item xs={2} style={{paddingTop: "30px"}}>
									<XsInput field={this.form.$("other_postcode")} />
								</Grid>
							</Grid>
						</React.Fragment>
					)}

					<div style={{marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>Informácie o mieste pobytu</div>
					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("housePerson")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("houseRoom")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("startQuarantine")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("endQuarantine")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("reasonQuarantine")} multiple rows={3} />
					</Grid>

					<div style={{marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>Ktoré príznaky ochorenia máte?</div>
					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox
								field={this.form.$("hasTemperature")}
								onChange={(target) => {
									if (!target.value) {
										this.form.$("dFeverStartedAt").value = null
										this.form.$("dFeverEndedAt").value = null
									}
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("dFeverStartedAt")}
								showTimeSelect={false}
								disabled={!this.form.$("hasTemperature").value}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("dFeverEndedAt")}
								showTimeSelect={false}
								disabled={!this.form.$("hasTemperature").value}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasFajciar")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasSuchyKasel")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasMalatnost")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasBolestHlavy")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasBolestKlbovASvalov")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasNadcha")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasZvracanie")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasBusenieSrdca")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasHnacka")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasTriaska")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasVykasliavamHlien")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasStazeneDychanie")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasStrataCuchu")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("hasStrataChuti")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("hasPrisielZoZahranicia")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={3}>
							<XsCheckbox
								field={this.form.$("hasOckovanie")}
								onChange={(target) => {
									if (!target.value) {
										this.form.$("dInfluenzaVaccineDate").value = null
										this.form.$("vInfluenzaVaccineName").value = ""
									}
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("dInfluenzaVaccineDate")}
								showTimeSelect={false}
								disabled={!this.form.$("hasOckovanie").value}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("vInfluenzaVaccineName")} disabled={!this.form.$("hasOckovanie").value} />
						</Grid>
					</Grid>

					<Grid item xs={12} style={{marginTop: "20px"}}>
						<XsInput field={this.form.$("vDrugsUsed")} multiple rows={3} />
					</Grid>
					<Grid item xs={12} className="corona_multiple">
						<XsInput field={this.form.$("vOtherSymptoms")} multiple rows={3} />
					</Grid>

					<div style={{marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>
						Tento formulár je možné do eZdravie pre pacienta odoslať iba raz. Pred uložením skontrolujte vyplnené údaje.
					</div>
				</Grid>
			</div>
		)
	}
}
