import React from "react"
import MonthYearDropdownOptions from "./MonthYearDropdownOptions"
import {
	addMonths,
	formatDate,
	getStartOfMonth,
	isAfter,
	isSameMonth,
	isSameYear,
	localizeDate,
	newDate
} from "./helpers"
import {observer} from "mobx-react"

const WrappedMonthYearDropdownOptions = MonthYearDropdownOptions

@observer
export default class MonthYearDropdown extends React.Component {
	dropdownVisible = false

	renderSelectOptions = () => {
		const currDate = getStartOfMonth(localizeDate(this.props.minDate, this.props.locale))
		const lastDate = getStartOfMonth(localizeDate(this.props.maxDate, this.props.locale))

		const options = []

		while (!isAfter(currDate, lastDate)) {
			const timepoint = currDate.valueOf()
			options.push(
				<option key={timepoint} value={timepoint}>
					{formatDate(currDate, this.props.dateFormat)}
				</option>
			)
			addMonths(currDate, 1)
		}
		return options
	}

	onSelectChange = (e) => {
		this.onChange(e.target.value)
	}

	renderSelectMode = () => (
		<select
			value={getStartOfMonth(this.props.date).valueOf()}
			className="react-datepicker__month-year-select"
			onBlur={this.onSelectChange}
		>
			{this.renderSelectOptions()}
		</select>
	)

	renderReadView = (visible) => {
		const yearMonth = formatDate(localizeDate(newDate(this.props.date), this.props.locale), this.props.dateFormat)

		return (
			<div
				key="read"
				style={{visibility: visible ? "visible" : "hidden"}}
				className="react-datepicker__month-year-read-view"
				onClick={(event) => this.toggleDropdown(event)}
			>
				<span className="react-datepicker__month-year-read-view--down-arrow" />
				<span className="react-datepicker__month-year-read-view--selected-month-year">{yearMonth}</span>
			</div>
		)
	}

	renderDropdown = () => (
		<WrappedMonthYearDropdownOptions
			key="dropdown"
			//ref="options"
			date={this.props.date}
			dateFormat={this.props.dateFormat}
			onChange={this.onChange}
			onCancel={this.toggleDropdown}
			minDate={localizeDate(this.props.minDate, this.props.locale)}
			maxDate={localizeDate(this.props.maxDate, this.props.locale)}
			scrollableMonthYearDropdown={this.props.scrollableMonthYearDropdown}
		/>
	)

	renderScrollMode = () => {
		let result = [this.renderReadView(!this.dropdownVisible)]
		if (this.dropdownVisible) {
			result.unshift(this.renderDropdown())
		}
		return result
	}

	onChange = (monthYearPoint) => {
		this.toggleDropdown()
		const changedDate = newDate(parseInt(monthYearPoint))
		if (isSameYear(this.props.date, changedDate) && isSameMonth(this.props.date, changedDate)) {
			return
		}
		this.props.onChange(changedDate)
	}

	toggleDropdown = () => (this.dropdownVisible = !this.dropdownVisible)

	render() {
		let renderedDropdown = ""
		switch (this.props.dropdownMode) {
			case "scroll":
				renderedDropdown = this.renderScrollMode()
				break
			case "select":
				renderedDropdown = this.renderSelectMode()
				break
		}

		return (
			<div
				className={`react-datepicker__month-year-dropdown-container react-datepicker__month-year-dropdown-container--${this.props.dropdownMode}`}
			>
				{renderedDropdown}
			</div>
		)
	}
}
