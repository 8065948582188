import React from "react"
import classnames from "classnames"
import {
	getDay,
	getMonth,
	getDate,
	now,
	newDate,
	isSameDay,
	isDayDisabled,
	isDayInRange,
	getDayOfWeekCode
} from "./helpers"

export default class Day extends React.Component {
	handleClick = (event) => {
		if (!this.isDisabled() && this.props.onClick) {
			this.props.onClick(event)
		}
	}

	handleMouseEnter = (event) => {
		if (!this.isDisabled() && this.props.onMouseEnter) {
			this.props.onMouseEnter(event)
		}
	}

	isSameDay = (other) => isSameDay(this.props.day, other)

	isKeyboardSelected = () =>
		!this.props.inline && !this.isSameDay(this.props.selected) && this.isSameDay(this.props.preSelection)

	isDisabled = () => isDayDisabled(this.props.day, this.props)

	getHighLightedStyle = () => {
		const {day, highlightDates, selected} = this.props

		if (!highlightDates || highlightDates.length === 0 || day.isSame(newDate(), "day") || day.isSame(selected, "day")) {
			return null
		}

		const dayStr = highlightDates.find((d) => day.isSame(newDate(d.date), "day"))
		if (dayStr && dayStr.color) {
			return {
				backgroundColor: dayStr.color,
				borderRadius: "12.8px",
				color: "white"
			}
		}
	}

	isInRange = () => {
		const {day, startDate, endDate} = this.props
		if (!startDate || !endDate) {
			return false
		}
		return isDayInRange(day, startDate, endDate)
	}

	isInSelectingRange = () => {
		const {day, selectsStart, selectsEnd, selectingDate, startDate, endDate} = this.props

		if (!(selectsStart || selectsEnd) || !selectingDate || this.isDisabled()) {
			return false
		}

		if (selectsStart && endDate && selectingDate.isSameOrBefore(endDate)) {
			return isDayInRange(day, selectingDate, endDate)
		}

		if (selectsEnd && startDate && selectingDate.isSameOrAfter(startDate)) {
			return isDayInRange(day, startDate, selectingDate)
		}

		return false
	}

	isSelectingRangeStart = () => {
		if (!this.isInSelectingRange()) {
			return false
		}

		const {day, selectingDate, startDate, selectsStart} = this.props

		if (selectsStart) {
			return isSameDay(day, selectingDate)
		} else {
			return isSameDay(day, startDate)
		}
	}

	isSelectingRangeEnd = () => {
		if (!this.isInSelectingRange()) {
			return false
		}

		const {day, selectingDate, endDate, selectsEnd} = this.props

		if (selectsEnd) {
			return isSameDay(day, selectingDate)
		} else {
			return isSameDay(day, endDate)
		}
	}

	isRangeStart = () => {
		const {day, startDate, endDate} = this.props
		if (!startDate || !endDate) {
			return false
		}
		return isSameDay(startDate, day)
	}

	isRangeEnd = () => {
		const {day, startDate, endDate} = this.props
		if (!startDate || !endDate) {
			return false
		}
		return isSameDay(endDate, day)
	}

	isWeekend = () => {
		const weekday = getDay(this.props.day)
		return weekday === 0 || weekday === 6
	}

	isOutsideMonth = () => {
		return this.props.month !== undefined && this.props.month !== getMonth(this.props.day)
	}

	getClassNames = (date) => {
		const dayClassName = this.props.dayClassName ? this.props.dayClassName(date) : undefined
		return classnames(
			"react-datepicker__day",
			dayClassName,
			"react-datepicker__day--" + getDayOfWeekCode(this.props.day),
			{
				"react-datepicker__day--disabled": this.isDisabled(),
				"react-datepicker__day--selected": this.isSameDay(this.props.selected),
				"react-datepicker__day--keyboard-selected": this.isKeyboardSelected(),
				"react-datepicker__day--range-start": this.isRangeStart(),
				"react-datepicker__day--range-end": this.isRangeEnd(),
				"react-datepicker__day--in-range": this.isInRange(),
				"react-datepicker__day--in-selecting-range": this.isInSelectingRange(),
				"react-datepicker__day--selecting-range-start": this.isSelectingRangeStart(),
				"react-datepicker__day--selecting-range-end": this.isSelectingRangeEnd(),
				"react-datepicker__day--today": this.isSameDay(now(this.props.utcOffset)),
				"react-datepicker__day--weekend": this.isWeekend(),
				"react-datepicker__day--outside-month": this.isOutsideMonth()
			}
		)
	}

	render() {
		return (
			<div
				tabIndex="0"
				className={this.getClassNames(this.props.day)}
				onClick={this.handleClick}
				onMouseEnter={this.handleMouseEnter}
				aria-label={`day-${getDate(this.props.day)}`}
				role="option"
				style={this.getHighLightedStyle()}
			>
				{getDate(this.props.day)}
			</div>
		)
	}
}
