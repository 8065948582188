import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import GlobalStore from "../../../../../../global/store/GlobalStore"
import fields from "./spaFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import api from "../../../../actions/api"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class SpaTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		this.state = {
			CL_indicativeGroup: GlobalStore.CL["indicativeGroup"].sort((a, b) =>
				(isSafe(a.code_ext) ? a.code_ext : "").localeCompare(isSafe(b.code_ext) ? b.code_ext : "")
			)
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid item xs={12}>
						<XsInput field={this.form.$("pacientZamestnavatel")} />
					</Grid>

					<div style={{marginTop: "40px"}} />
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("sprievodcaMeno")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("sprievodcaRodneCislo")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("sprievodcaTelefon")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={9}>
							<XsInput field={this.form.$("sprievodcaAdresa")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("sprievodcaPSC")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("sprievodcaZamestnavatel")} />
					</Grid>

					<div style={{marginTop: "40px"}} />
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6} className="xs-autocomplete-element">
							<XsAutocompleteLocal
								field={this.form.$("kodID")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kod").value = obj.code_ext
									this.form.$("diagnoza").value = obj.name_ext

									const kodSplit = obj.code_ext.split(".").length > 0 ? obj.code_ext.split(".")[0] : null
									this.form.$("kod1").value = kodSplit ? kodSplit[0] : ""
									this.form.$("kod2").value = kodSplit ? kodSplit[1] : ""
									this.form.$("kod3").value = kodSplit ? kodSplit[2] : ""
									return obj.code_ext + " " + obj.name_ext
								}}
								postAction={(option) => {
									const filter = {
										filters: [
											{
												associated_column: "diagnosis_id",
												values: [{id_value: option.code}]
											}
										]
									}

									api
										.loadCLIndicativeGroupWithFilter(filter)
										.call()
										.then((resp) => {
											this.setState({
												CL_indicativeGroup: resp.rows.sort((a, b) =>
													(isSafe(a.code_ext) ? a.code_ext : "").localeCompare(isSafe(b.code_ext) ? b.code_ext : "")
												)
											})
										})
										.catch((err) => logger("err filter skupin:", err))
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={12}>
							<SearchSelect
								field={this.form.$("indikacnaSkupina")}
								items={this.state.CL_indicativeGroup}
								getOptionLabel={(obj) => `${obj.code_ext} ${obj.refund_type} - ${obj.name_ext}`}
								onChange={(val) => {
									const indSk = this.state.CL_indicativeGroup.filter((x) => x.code == val)
									if (indSk.length == 1) {
										const sk = indSk[0].code_ext
										this.form.$("identifikacnaSkupina1").value = sk.split("/")[0]
										this.form.$("identifikacnaSkupina2").value = sk.split("/")[1]
									}
								}}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<XsInput field={this.form.$("identifikacnaSkupina1")} labelStyle={{display: "none"}} />
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("identifikacnaSkupina2")} labelStyle={{display: "none"}} />
						</Grid> */}
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("vedlajsieDiagnozy")} />
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "14px"}}>
								<FormattedMessage id="PrintTemplate.Spa.FormLabel2" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("kupelnaStarostlivostUstavnaCheckbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("kupelnaStarostlivostAmbulantnaCheckbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("kupelnaStarostlivostDetskaCheckbox")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "14px"}}>
								<FormattedMessage id="PrintTemplate.Spa.FormLabel3" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("stupenNaliehavosti1Checkbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("stupenNaliehavosti2Checkbox")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "14px"}}>
								<FormattedMessage id="PrintTemplate.Spa.FormLabel4" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("sprievodcaPrePobytAnoCheckbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("sprievodcaPrePobytNieCheckbox")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("navrhol")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("overil")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left">
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datumOverenia")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px", fontWeight: "bold"}}>
						<FormattedMessage id="PrintTemplate.Spa.FormLabel10" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<div style={{paddingTop: "14px"}}>
								<FormattedMessage id="PrintTemplate.Spa.FormLabel11" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("lsPacientChodiaciCheckbox")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("lsPacientBarlyCheckbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("lsPacientVozikCheckbox")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsVyska")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsHmotnost")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsTK1")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsTK2")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsP")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsTextArea
							field={this.form.$("lsAnamneza")}
							hideToolbar={true}
							className="xs-height-200"
							maxLength={500}
						/>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.Spa.FormLabel12" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={1}>
							<div style={{paddingTop: "20px"}}>
								<FormattedMessage id="PrintTemplate.Spa.FormLabel13" />
							</div>
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("lsFW1")} labelStyle={{display: "none"}} />
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("lsFW2")} labelStyle={{display: "none"}} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("lsDatum")} showTimeSelect={false} />
						</Grid>
						{/* <Grid item xs={2}><XsInput field={this.form.$("lsFW1")} /></Grid>
            <Grid item xs={2}><XsInput field={this.form.$("lsFW2")} /></Grid> */}
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsMoc")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("lsKO")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left">
						<Grid item xs={12}>
							<XsInput field={this.form.$("lsRtgHrudnika")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left">
						<Grid item xs={12} className="spa_ekg">
							<XsInput field={this.form.$("lsEKG")} multiple rows={6} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("lsIne")} hideToolbar={true} className="xs-height-200" maxLength={500} />
					</Grid>

					<Grid item xs={12}>
						<XsTextArea
							field={this.form.$("lsObjektivnyNalez")}
							hideToolbar={true}
							className="xs-height-200"
							maxLength={500}
						/>
					</Grid>
					<Grid item xs={12}>
						<XsTextArea
							field={this.form.$("lsZdovodnenieSprievodcu")}
							hideToolbar={true}
							className="xs-height-200"
							maxLength={500}
						/>
					</Grid>

					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datum")} showTimeSelect={false} maxLength={500} />
					</Grid>
				</Grid>
			</div>
		)
	}
}
