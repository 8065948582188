import React, {useState, useEffect, useRef} from "react"

function useInterval(callback, delay) {
	const savedCallback = useRef()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

function useMergeState(initialState) {
	const [state, setState] = useState(initialState)
	const setMergedState = (newState) => setState((prevState) => Object.assign({}, prevState, newState))
	return [state, setMergedState]
}

function V1(props) {
	logger("v1 data", props.data)

	const [state, SetState] = useMergeState({
		modified: false,
		data: JSON.stringify(props.data)
	})
	// const [modified, SetModified] = useState(false)
	// const [data, SetData] = useState(JSON.stringify(props.data))

	useInterval(() => {
		const actualData = JSON.stringify(props.data)
		logger("1 - tick tock")
		if (state.data !== actualData) {
			logger("v1 change occured")
			SetState({modified: true, data: actualData})
			// SetData(actualData)
			// SetModified(true)
		}
	}, 200)

	const change1 = () => {
		props.data[0].id = -1
	}

	const change2 = () => {
		props.data[0].id = -2
		// props.data = [{a:1}]
	}

	return (
		props.data && (
			<div>
				<p>{`V1 arrived ${props.data.length} entities ${state.modified ? "[MODIFIED]" : ""}`}</p>
				<button onClick={change1}>change 1</button>
				<button onClick={change2}>change 2</button>
			</div>
		)
	)
}

export default V1
