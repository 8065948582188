/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				recordId: {},
				version: {
					rules: "string"
				},
				print: {
					value: false,
					rules: "boolean"
				},
				examinationName: {
					label: <FormattedMessage id="Patient.form.patientrecord.examinationName" />,
					rules: ""
				},
				OF: {
					label: <FormattedMessage id="Patient.form.patientrecord.ST" />
					// rules: "required"
				},
				record: {
					label: <FormattedMessage id="Patient.form.problemForm.OF" />,
					rules: "required"
				},
				iexam: {
					label: <FormattedMessage id="Common.label.iexam" />
					// rules: "required"
				},
				diagnosisSummary: {
					label: <FormattedMessage id="Patient.form.patientrecord.DiagnosisSummary" />
				},
				Recomm: {
					label: <FormattedMessage id="Patient.form.problemForm.Recomm" />
				},
				doctorPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientrecord.doctor" />
				},
				doctorPersonnelId: {},
				doctorExpertiseId: {},
				examdate: {
					label: <FormattedMessage id="Patient.form.patientrecord.dateOfExam" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				requestdate: {
					label: <FormattedMessage id="Patient.form.patientrecord.dateOfRequest" />,
					type: "date"
				},
				doctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientrecord.doctorOUPZ" />
				},
				doctorOUPZSId: {},
				note: {
					label: <FormattedMessage id="Patient.form.patientrecord.note" />
				},
				dispensarisation: {
					label: <FormattedMessage id="Patient.form.patientrecord.dispensarisation" />,
					type: "checkbox"
				},
				recordPriority: {
					label: <FormattedMessage id="Patient.form.patientrecord.recordPriority" />,
					rules: "required",
					value: "1"
				},
				confidentialityLevel: {
					label: <FormattedMessage id="Patient.form.patientrecord.confidentialityLevel" />,
					rules: "required",
					value: "common"
				},
				confidentialityLevelDisable: {
					type: "checkbox",
					value: false
				},
				substitutingExpertiseId: {},
				substitutingPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientrecord.substitutingDoctor" />
				},
				substitutingDoctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientPrescription.substitutingDoctorOUPZS" />
				},
				substitutingDoctorOUPZSId: {},
				substitutingDoctor: {},
				substitutingDoctorId: {},
				diagnosisChipsForm: {
					value: "[]"
				},
				clinicalValuesChipsForm: {
					value: "[]"
				},
				deliveriesChipsForm: {
					value: "[]"
				},
				deliveriesFormAddItems: {
					value: ""
				},
				clinicalValuesSets: {
					label: <FormattedMessage id="Common.label.clinicalSetTitle" />,
					value: ""
				},
				hasDispensaryDiagnosis:{
					value: false
				},
				personnelSpecificRelId: {},
				orgunitSpecificRelId: {},
				clientSpecificRelId: {}
				// drugs: {
				//   label: <FormattedMessage id="Common.label.drugs" />,
				//   rules: "string"
				// },
			}
		}
	}
}
