"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import {getFilters} from "../../../global/helpers/api"
import {getUserCompanyInfo, getSelectedOrgUnitID, getFullDataFromOptimized} from "../../../global/helpers/actions"

import api from "../actions/api"

class SocInsDeliveryStore {
	@observable insureeTypesCL = []
	@observable insureeTypesJSON = {}
	@observable insureeJSON = {}

	@observable senderTypeCL = []
	@observable senderTypesJSON = {}
	@observable senderJSON = {}

	@observable refundTypesCL = []
	@observable refundTypesJSON = {}
	@observable refundJSON = {}

	@observable payerTypesCL = []
	@observable payerTypesJSON = {}
	@observable payerJSON = {}
	@observable selectedPayerType = null

	@observable filters = []

	@observable socInsDeliveryList = []

	@observable subItemSubTypesCL = []

	formFilterRef = null

	@observable rowCountFull = 0
	@observable page = 0
	@observable rowsPerPage = 10

	exportFilters = {}

	@observable changedFilters = false
	currFilters = {}

	@observable isEditable = false
	@observable isLoadingData = false

	@observable changedKeysTest = {}
	@observable changedData = {}
	@observable removeDeliveryIds = []
	@observable subItems = {}
	@observable idSubItemsRow = ""

	@observable drugs = {}
	@observable medicalDevices = {}
	@observable customItems = {}
	@observable diagnosis = {}
	@observable diagnosisFilterData = []
	@observable diagnosisFilterValue = ""
	@observable deliveries = {}

	isValidGrid = true

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(moment(this.currFilters.time_from), moment(formValues.time_from))
		let isChangeDateTo = compareMomentDate(moment(this.currFilters.time_to), moment(formValues.time_to))
		let isChangeFrom = compareMomentDate(this.currFilters.from, formValues.from)
		let isChangeTo = compareMomentDate(this.currFilters.to, formValues.to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			isChangeFrom ||
			isChangeTo ||
			this.currFilters.identifier != formValues.identifier
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(fromFilter = false) {
		const formValues = this.formFilterRef.values()
		this.currFilters = this.formFilterRef.values()

		this.exportFilters = {}
		let filters = [
			{
				associated_column: "payer_type",
				values: [{id_value: "S"}]
			},
			{
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnitID()}]
			}
		]

		if (isNotEmpty(formValues.time_from) && isSafe(formValues.from) && moment.isMoment(formValues.from)) {
			if (formValues.from.isAfter(moment(formValues.time_from, "YYYY-MM-DD"))) {
				filters.push({associated_column: "time_from", values: [{id_value: formValues.from.format("YYYY-MM-DD")}]})
				this.exportFilters["time_from"] = formValues.from.format("YYYY-MM-DD")
			} else {
				filters.push({associated_column: "time_from", values: [{id_value: formValues.time_from}]})
				this.exportFilters["time_from"] = formValues.time_from
			}
		} else {
			filters.push({associated_column: "time_from", values: [{id_value: formValues.time_from}]})
			this.exportFilters["time_from"] = formValues.time_from
		}

		if (isNotEmpty(formValues.time_to) && isSafe(formValues.to) && moment.isMoment(formValues.to)) {
			if (formValues.to.isBefore(moment(formValues.time_to, "YYYY-MM-DD"))) {
				filters.push({associated_column: "time_to", values: [{id_value: formValues.to.format("YYYY-MM-DD")}]})
				this.exportFilters["time_to"] = formValues.to.format("YYYY-MM-DD")
			} else {
				filters.push({associated_column: "time_to", values: [{id_value: formValues.time_to}]})
				this.exportFilters["time_to"] = formValues.time_to
			}
		} else {
			filters.push({associated_column: "time_to", values: [{id_value: formValues.time_to}]})
			this.exportFilters["time_to"] = formValues.time_to
		}

		if (isNotEmpty(formValues.identifier)) {
			filters.push({associated_column: "person_identifier", values: [{id_value: formValues.identifier.trim()}]})
			this.exportFilters["identifier"] = formValues.identifier.trim()
		}

		this.isChangedFilter(this.formFilterRef.values())

		this.loadCompanyDeliveries({
			row_offset: fromFilter ? 1 : this.page == 0 ? 1 : this.page * this.rowsPerPage + 1,
			row_count_show: this.rowsPerPage,
			filters: filters
		})
		// this.filters = filters
	}

	@action loadCompanyDeliveries(req) {
		this.isLoadingData = true

		api
			.loadCompanyDeliveries(getUserCompanyInfo().id, req, "P")
			.call()
			.then((response) => {
				let insureeTypesJSON = {}
				let senderTypesJSON = {}
				let refundTypesJSON = {}
				let payerTypesJSON = {}
				let subItems = {}
				let rowCountFull = 0

				let items = []
				if (isSafe(response) && isSafe(response.rows)) {
					response.rows = getFullDataFromOptimized(response.rows)
					response.rows.forEach((delivery) => {
						items.push({
							_id: delivery._id,
							created_at: delivery.created_at,
							person_name: delivery.person_name,
							person_id: delivery.person_id,
							person_age: delivery.person_age,
							diagnosis_code:
								isSafe(delivery.diagnosis) && isSafe(delivery.diagnosis.code) ? delivery.diagnosis.code : "",
							diagnosis_name_ext:
								isSafe(delivery.diagnosis) && isSafe(delivery.diagnosis.name_ext) ? delivery.diagnosis.name_ext : "",
							insuree_type: isSafe(delivery.insuree_type) ? delivery.insuree_type : "",
							insurerId: delivery.insurer_id,
							insurer_name_ext: isSafe(delivery.insurer_name_ext) ? delivery.insurer_name_ext : "",
							insurance_type_id: isNotEmpty(delivery.insurance_type_id) ? delivery.insurance_type_id : "",
							refund_type: isSafe(delivery.refund_type) ? delivery.refund_type : "",
							movement: delivery.movement,
							item_code_ext: delivery.item_code_ext,
							payer_type: isSafe(delivery.payer_type) ? delivery.payer_type : "",
							item_name_ext: delivery.item_name_ext,
							price_in_currency: delivery.price_in_currency,
							price_in_points: delivery.price_in_points,
							count: delivery.count,
							person_identifier: delivery.person_identifier,
							sub_items: delivery.sub_items,
							sender_type: delivery.sender_type
						})

						insureeTypesJSON[delivery._id] = isSafe(delivery.insuree_type) ? delivery.insuree_type : ""
						senderTypesJSON[delivery._id] = isSafe(delivery.sender_type) ? delivery.sender_type : ""
						refundTypesJSON[delivery._id] = isSafe(delivery.refund_type) ? delivery.refund_type : ""
						payerTypesJSON[delivery._id] = isSafe(delivery.payer_type) ? delivery.payer_type : ""

						if (delivery.sub_items.length > 0) {
							subItems[delivery._id] = {}
							delivery.sub_items.forEach((subItem) => {
								let item = {
									sub_type: subItem.sub_type,
									quantity: subItem.quantity,
									unit_price: subItem.unit_price
								}

								if (subItem.sub_type === "adidrug") {
									item["description"] = subItem.description
									item["drugs"] = isSafe(subItem.product) && isSafe(subItem.product._id) ? subItem.product._id : ""
									item["drugsName"] =
										isSafe(subItem.product) && isSafe(subItem.product.code_ext) && isSafe(subItem.product.name_ext)
											? subItem.product.code_ext + " | " + subItem.product.name_ext + " | " + subItem.product.supplement
											: ""
									item["adorment"] =
										isSafe(subItem.product) && isNotEmpty(subItem.product.basic_statement_unit)
											? subItem.product.basic_statement_unit
											: ""
								}

								if (subItem.sub_type === "adicntr") {
									item["description"] = subItem.description
									item["drugs"] =
										isSafe(subItem.delivery_item) && isSafe(subItem.delivery_item._id) ? subItem.delivery_item._id : ""
									item["drugsName"] =
										isSafe(subItem.delivery_item) &&
										isSafe(subItem.delivery_item.code_ext) &&
										isSafe(subItem.delivery_item.name_ext)
											? subItem.delivery_item.code_ext + " | " + subItem.delivery_item.name_ext
											: ""
								}

								if (subItem.sub_type === "adidev") {
									item["description"] = subItem.description
									item["drugs"] = isSafe(subItem.product) && isSafe(subItem.product._id) ? subItem.product._id : ""
									item["drugsName"] =
										isSafe(subItem.product) && isSafe(subItem.product.name_ext)
											? subItem.product.code_ext + " | " + subItem.product.name_ext + " | " + subItem.product.supplement
											: ""
								}

								if (subItem.sub_type === "adigen" || subItem.sub_type === "drg") {
									item["subItemType"] = subItem.description
								}

								subItems[delivery._id][subItem._id] = item
							})
						}

						rowCountFull = response.row_count_full
					})
				}

				this.rowCountFull = rowCountFull
				this.subItems = subItems
				this.refundTypesJSON = refundTypesJSON
				this.insureeTypesJSON = insureeTypesJSON
				this.senderTypesJSON = senderTypesJSON
				this.payerTypesJSON = payerTypesJSON
				this.socInsDeliveryList = items
				this.isLoadingData = false
			})
			.catch(() => {
				this.isLoadingData = false
			})
	}

	@action resetGrid() {
		this.changedKeysTest = {}
		this.changedData = {}
		this.removeDeliveryIds = []
		this.isEditable = false
		this.isValidGrid = true
		this.idSubItemsRow = ""
		this.getFilters()
	}

	@action saveChanges() {
		let request = []

		if (Object.keys(this.changedKeysTest).length > 0) {
			Object.keys(this.changedKeysTest).forEach((key) => {
				let item = {
					_id: +key,
					active: this.removeDeliveryIds.includes(key) ? false : true,
					_ref: false,
					_type: "EHR.Data.Record.SK.Delivery",
					type: "dlv"
				}

				if (isSafe(this.changedData) && isSafe(this.changedData[key])) {
					if (isSafe(this.changedData[key].item_code_ext)) {
						item["item"] = {
							_id: this.changedData[key].item_code_ext,
							_type: "EHR.CL.Bill.SK.Delivery"
						}
					}

					if (isSafe(this.changedData[key].diagnosis_name_ext)) {
						item["diagnosis"] = {
							_id: this.changedData[key].diagnosis_name_ext,
							_type: "EHR.CL.Record.Diagnosis"
						}
					}

					if (isSafe(this.changedData[key].insuree_type)) {
						item["insuree_type"] = isEmpty(this.changedData[key].insuree_type)
							? null
							: this.changedData[key].insuree_type
					}

					if (isSafe(this.changedData[key].sender_type)) {
						item["sender_type"] = isEmpty(this.changedData[key].sender_type) ? null : this.changedData[key].sender_type
					}

					if (isSafe(this.changedData[key].payer_type)) {
						item["payer_type"] = isEmpty(this.changedData[key].payer_type) ? null : this.changedData[key].payer_type
					}

					if (isSafe(this.changedData[key].refund_type)) {
						item["refund_type"] = isEmpty(this.changedData[key].refund_type) ? null : this.changedData[key].refund_type
					}

					if (isSafe(this.changedData[key].count)) {
						item["count"] = isEmpty(this.changedData[key].count) ? null : +this.changedData[key].count
					}

					if (isSafe(this.changedData[key].movement)) {
						item["movement"] = isEmpty(this.changedData[key].movement) ? null : this.changedData[key].movement
					}
				}

				if (
					isSafe(this.subItems) &&
					isSafe(Object.keys(this.subItems)) &&
					Object.keys(this.subItems).length > 0 &&
					this.subItems.hasOwnProperty(key)
				) {
					let subItems = []
					Object.keys(this.subItems[key]).forEach((subItemKey) => {
						if (
							isSafe(this.subItems[key][subItemKey]) &&
							isSafe(this.subItems[key][subItemKey].quantity) &&
							isSafe(this.subItems[key][subItemKey].unit_price)
						) {
							let unitPrice = `${this.subItems[key][subItemKey].unit_price}`

							if (unitPrice.includes(",")) {
								unitPrice = unitPrice.replace(",", ".")
							}

							let subItem = {
								_id: "", //+subItemKey < 0 ? "" : subItemKey,
								_ref: false,
								active: true,
								currency: {
									_id: "EUR",
									_type: "IXS.CL.Data.CurrencyUnit"
								},
								// "description": isSafe(this.subItems[key][subItemKey].description) ? this.subItems[key][subItemKey].description : "",
								quantity: this.subItems[key][subItemKey].quantity,
								type: "adi",
								_type: "EHR.Data.Record.SK.Delivery.Product",
								unit: {
									_id: "pc",
									_type: "IXS.CL.Data.Unit"
								},
								unit_price: unitPrice
							}

							if (
								isEmpty(this.subItems[key][subItemKey].sub_type) ||
								this.subItems[key][subItemKey].sub_type === "adigen" ||
								this.subItems[key][subItemKey].sub_type === "drg"
							) {
								subItem["description"] = isSafe(this.subItems[key][subItemKey].subItemType)
									? this.subItems[key][subItemKey].subItemType
									: ""
								// subItem["_type"] = "EHR.Data.Record.DeliveryItem"
								// subItem["_type"] = "EHR.Data.Record.SK.Delivery.Product"
							}

							if (
								isEmpty(this.subItems[key][subItemKey].sub_type) ||
								this.subItems[key][subItemKey].sub_type === "adigen"
							) {
								subItem["sub_type"] = "adigen"
							}

							if (this.subItems[key][subItemKey].sub_type === "drg") {
								subItem["sub_type"] = "drg"
							}

							if (this.subItems[key][subItemKey].sub_type === "adidrug") {
								subItem["description"] = isSafe(this.subItems[key][subItemKey].description)
									? this.subItems[key][subItemKey].description
									: ""
								// subItem["_type"] = "EHR.Data.Record.SK.Delivery.Product"
								subItem["sub_type"] = "adidrug"
								subItem["product"] = {
									_id: this.subItems[key][subItemKey].drugs,
									_type: "EHR.CL.Medication.SK.Drug "
								}
							}

							if (this.subItems[key][subItemKey].sub_type === "adicntr") {
								subItem["description"] = isSafe(this.subItems[key][subItemKey].description)
									? this.subItems[key][subItemKey].description
									: ""
								subItem["sub_type"] = "adicntr"
								subItem["_type"] = "EHR.Data.Record.Delivery.CustomItem"
								subItem["delivery_item"] = {
									_id: this.subItems[key][subItemKey].drugs,
									_type: "EHR.CL.Bill.SK.DeliveryItem"
								}
							}

							if (this.subItems[key][subItemKey].sub_type === "adidev") {
								subItem["description"] = isSafe(this.subItems[key][subItemKey].description)
									? this.subItems[key][subItemKey].description
									: ""
								// subItem["_type"] = "EHR.Data.Record.SK.Delivery.Product"
								subItem["sub_type"] = "adidev"
								subItem["product"] = {
									_id: this.subItems[key][subItemKey].drugs,
									_type: "EHR.CL.Medication.SK.MedicalDevice "
								}
							}

							subItems.push(subItem)
						}
					})
					item["sub_items"] = subItems
				}
				request.push(item)
			})
		}

		api
			.saveDelivery(request)
			.call()
			.then(() => {
				this.idSubItemsRow = ""
				this.getFilters()
			})
	}

	@action createSubItemPP(row) {
		this.idSubItemsRow = row._id
		let diagnosis_code = row.item_code_ext

		if (this.isEditable && isSafe(this.subItems) && isNotSafe(this.subItems[-1])) {
			if (!this.subItems.hasOwnProperty(row._id)) {
				if (!this.changedKeysTest.hasOwnProperty(row._id)) {
					this.changedKeysTest[row._id] = {}
					this.changedKeysTest[row._id][-1] = {}
				}
				this.subItems[row._id] = {}
				this.subItems[row._id][-1] = {
					quantity: 1
				}

				if (diagnosis_code === "H0006") {
					this.subItems[row._id][-1] = {
						sub_type: "adigen",
						quantity: 1,
						unit_price: 0,
						subItemType: "SKOR"
					}
				}
			} else {
				if (this.changedKeysTest.hasOwnProperty(row._id) && Object.keys(this.changedKeysTest[row._id]).length === 0) {
					this.changedKeysTest[row._id][-1] = {}
					this.subItems[row._id][-1] = {
						quantity: 1
					}

					if (diagnosis_code === "H0006") {
						this.subItems[row._id][-1] = {
							sub_type: "adigen",
							quantity: 1,
							unit_price: 0,
							subItemType: "SKOR"
						}
					}
				}
			}
		}
	}

	@action removeDelivery(deliveryId) {
		this.removeDeliveryIds.push(deliveryId)
		this.changedKeysTest[deliveryId] = {}
	}

	@action deleteSubItem(deliveryId, subItemId) {
		if (isSafe(this.subItems[deliveryId]) && isSafe(this.subItems[deliveryId][subItemId])) {
			if (Object.keys(this.subItems[deliveryId]).length === 1) {
				delete this.subItems[deliveryId][subItemId]
				if (isSafe(this.changedKeysTest[deliveryId]) && isSafe(this.changedKeysTest[deliveryId][subItemId])) {
					delete this.changedKeysTest[deliveryId][subItemId]
				}
				this.idSubItemsRow = ""
			} else {
				delete this.subItems[deliveryId][subItemId]
				if (isSafe(this.changedKeysTest[deliveryId]) && isSafe(this.changedKeysTest[deliveryId][subItemId])) {
					delete this.changedKeysTest[deliveryId][subItemId]
				}
			}

			if (!this.changedKeysTest.hasOwnProperty(deliveryId)) {
				this.changedKeysTest[deliveryId] = {}
				// this.changedKeysTest[deliveryId][subItemId] = {}
			}
		}
	}

	@action addSubItem(deliveryId) {
		if (!this.changedKeysTest.hasOwnProperty(deliveryId)) {
			this.changedKeysTest[deliveryId] = {}
		}

		if (!this.subItems.hasOwnProperty(deliveryId)) {
			this.subItems[deliveryId] = {}
		}

		let minId = Math.min(...Object.keys(this.subItems[deliveryId]).map((x) => +x))
		let newId = minId < 0 ? minId - 1 : -1

		this.subItems[deliveryId][newId] = {
			quantity: 1
		}
		this.changedKeysTest[deliveryId][newId] = {}
	}

	@action changeSubItem(colName, deliveryId, subItemId, value) {
		if (!this.changedKeysTest.hasOwnProperty(deliveryId)) {
			this.changedKeysTest[deliveryId] = {}
		}

		if (!this.subItems.hasOwnProperty(deliveryId)) {
			this.subItems[deliveryId] = {}

			this.subItems[deliveryId][subItemId] = {}
		}

		this.changedKeysTest[deliveryId][subItemId] = {}
		this.subItems[deliveryId][subItemId][colName] = value
	}

	@action changeDelivery(colName, deliveryId, value) {
		if (!this.changedData.hasOwnProperty(deliveryId)) {
			this.changedData[deliveryId] = {}
		}

		if (!this.changedKeysTest.hasOwnProperty(deliveryId)) {
			this.changedKeysTest[deliveryId] = {}
		}

		this.changedData[deliveryId][colName] = value
	}

	@action searchFilterDiagnosis(value) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.loadDiagnosisCl(searchRequest)
			.call()
			.then((response) => {
				this.diagnosisFilterData = response.rows
			})
	}

	@action loadSpecificDiagnosis(value, callback) {
		const searchRequest = getFilters([`code=${value}`])
		api
			.loadDiagnosisCl(searchRequest)
			.call()
			.then((response) => {
				if (isSafe(response.rows[0]) && isSafe(callback)) {
					callback(response.rows[0])
				}
			})
	}

	@action searchDiagnosis(value, key) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.loadDiagnosisCl(searchRequest)
			.call()
			.then((response) => {
				this.diagnosis[key] = response.rows
			})
	}

	@action searchDeliveries(value, key) {
		const searchRequest = getFilters([`code_ext=${value}`])
		api
			.loadDeliveries(searchRequest)
			.call()
			.then((response) => {
				this.deliveries[key] = response.rows
			})
	}

	@action searchDrugs(value, key) {
		const searchRequest = getFilters([`search_column=${value}`])

		searchRequest.filters.push({
			associated_column: "only_bsu",
			predicate: "=",
			values: [
				{
					id_value: 1
				}
			]
		})

		api
			.loadDrug(searchRequest)
			.call()
			.then((response) => {
				this.drugs[key] = response.rows
			})
	}

	@action searchMedicalDevices(value, key) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.loadMedDev(searchRequest)
			.call()
			.then((response) => {
				this.medicalDevices[key] = response.rows
			})
	}

	@action searchCustomItems(value, key) {
		const searchReq = getFilters([`search_column=${value}`])
		api
			.loadCustomItem(searchReq)
			.call()
			.then((res) => {
				this.customItems[key] = res.rows
			})
	}

	@action loadPayerTypes() {
		api
			.loadPayerTypes()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.payerTypesCL = response.rows

					let items = {}

					response.rows.forEach((payer) => {
						items[payer._id] = payer.name_ext
					})

					this.payerJSON = items
				}
			})
	}

	@action loadInsureeTypes() {
		api
			.loadInsureeTypes()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.insureeTypesCL = response.rows

					let items = {}

					response.rows.forEach((insuree) => {
						items[insuree._id] = insuree.name_ext
					})

					this.insureeJSON = items
				}
			})
	}

	@action loadSenderType() {
		api
			.loadSenderType()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.senderTypeCL = response.rows

					let items = {}

					response.rows.forEach((sender) => {
						items[sender._id] = sender.name_ext
					})

					this.senderJSON = items
				}
			})
	}

	@action loadRefundTypes() {
		api
			.loadRefundTypes()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.refundTypesCL = response.rows

					let items = {}

					response.rows.forEach((refund) => {
						items[refund._id] = refund.name_ext
					})

					this.refundJSON = items
				}
			})
	}

	@action loadSubItemSubTypes() {
		api
			.loadSubItemSubTypes()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.subItemSubTypesCL = response.rows
				}
			})
	}
}

var singleton = new SocInsDeliveryStore()
export default singleton
