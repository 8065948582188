import React from "react"
import {observer} from "mobx-react"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"

import {
	loadDataToFormByScheme,
	insertFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"

import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./orgunitsDetailAddressesBindings"
import fields from "./orgunitsDetailAddressesFields"

import api from "../../../actions/api"

import GlobalStore from "../../../../../global/store/GlobalStore"
import OrgunitDetailStore from "../../../stores/OrgunitDetailStore"
import OrgunitDetailAddressesStore from "../../../stores/OrgunitDetailAddressesStore"

@injectIntl
@observer
export default class OrgunitsDetailAddresses extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			refreshComponent: false
		}

		const hooks = {
			onSubmit(form) {
				if (
					(isEmpty(form.$("city").value) && isEmpty(form.$("cityText").value)) ||
					(isEmpty(form.$("zip").value) && isEmpty(form.$("zipText").value))
				) {
					form.invalidate()
				}
			},
			onSuccess(form) {
				form.$("city").value = form.$("city").value === "" ? null : form.$("city").value
				form.$("zip").value = form.$("zip").value === "" ? null : form.$("zip").value
				insertFormDataByScheme(
					form,
					dataBindings,
					{
						addressId: isNotEmpty(form.$("addressId").get("value")) ? form.$("addressId").get("value") : null,
						specrel: {
							_type: "Entity.Data.OrgUnit",
							_id: props.orgunitId
						},
						city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
						zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
					},
					() => {
						OrgunitDetailStore.loadOrgunitData(props.orgunitId)
						OrgunitDetailAddressesStore.editable = false
					},
					false,
					"Patient.form.contactAddress.saveMessage"
				)
				form.reset()
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		GlobalStore.refreshCodeLists(["addressType", "addressRegion", "addressCounty"])

		this.initForm()
	}

	initForm() {
		resetForm(this.form, dataBindings)
	}

	form

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {orgunitAddressId: dataRow.delete}, (data) => {
			this.form.$("addressId").value = dataRow.delete
			if (isSafe(data.type) && isSafe(data.type._id)) {
				this.form.$("type").set(data.type._id)
			}

			if (isSafe(data.obj_city)) {
				this.form.$("city").value = data.obj_city._id
			} else if (isNotEmpty(data.city_plain)) {
				this.refreshAutocomplete()
				this.form.$("cityText").value = data.city_plain
			}
			if (isSafe(data.obj_zip)) {
				this.form.$("zip").value = data.obj_zip._id
			} else if (isNotEmpty(data.zip_plain)) {
				this.form.$("zipText").set(data.zip_plain)
				this.refreshAutocomplete()
			}
		})

		OrgunitDetailAddressesStore.editable = true
		OrgunitDetailAddressesStore.isAdd = true
	}

	deleteContactAddress = () => {
		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.orgunitAddressId,
			() => OrgunitDetailStore.loadOrgunitData(this.props.orgunitId),
			"Patient.form.contactAddress.deleteMessage"
		)
		OrgunitDetailAddressesStore.editable = false
		this.initForm()
	}

	refreshAutocomplete() {
		this.setState({refreshComponent: !this.state.refreshComponent})
	}

	render() {
		return (
			<React.Fragment>
				<Grid container className="pa-8">
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									type: {
										title: <FormattedMessage id="Common.label.region" />,
										type: "string",
										design: {
											width: "175px"
										}
									},
									region: {
										title: <FormattedMessage id="Common.label.region" />,
										type: "string",
										design: {
											width: "175px"
										}
									},
									county: {
										title: <FormattedMessage id="Common.label.county" />,
										type: "string",
										design: {
											width: "150px"
										}
									},
									street: {
										title: <FormattedMessage id="Common.label.street" />,
										type: "string"
										// design: {
										//   width: "175px",
										// },
									},
									house_number: {
										title: <FormattedMessage id="Common.label.number" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									city: {
										title: <FormattedMessage id="Common.label.city" />,
										design: {
											width: "300px"
										},
										type: "string"
									},
									zip: {
										title: <FormattedMessage id="Common.label.zip" />,
										type: "string",
										design: {
											width: "125px"
										}
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "42px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeleteOrgunitContactAddress", {
																	orgunitAddressId: props.value
																})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									reverseColor: true,
									showCursor: true,
									hidePager: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											type:
												isNotEmpty(dataRow.type) && isNotEmpty(dataRow.type.description)
													? dataRow.type.description
													: "",
											region:
												isNotEmpty(dataRow.region) && isNotEmpty(dataRow.region.name_ext)
													? dataRow.region.name_ext
													: "",
											county:
												isNotEmpty(dataRow.county) && isNotEmpty(dataRow.county.name_ext)
													? dataRow.county.name_ext
													: "",
											street: isNotEmpty(dataRow.street) ? dataRow.street : "",
											house_number: isNotEmpty(dataRow.house_number) ? dataRow.house_number : "",
											city:
												isNotEmpty(dataRow.obj_city) && isNotEmpty(dataRow.obj_city.name_ext)
													? dataRow.obj_city.name_ext
													: isNotEmpty(dataRow.city_plain)
													? dataRow.city_plain
													: "",
											zip:
												isNotEmpty(dataRow.obj_zip) && isNotEmpty(dataRow.obj_zip.name_ext)
													? dataRow.obj_zip.name_ext
													: isNotEmpty(dataRow.zip_plain)
													? dataRow.zip_plain
													: "",
											delete: dataRow._id
										}
									}
								},
								dataSource: OrgunitDetailStore.orgunitDTO.get("addresses")
							}}
						/>
					</Grid>
					<Grid item xs={12} className="pt-6">
						{OrgunitDetailAddressesStore.isAdd ? (
							<form>
								<Grid container direction="row" align="center" spacing={8}>
									<Grid item xs={1}>
										<SearchSelect field={this.form.$("type")} items={GlobalStore.CL["addressType"]} />
									</Grid>
									<Grid item xs={1}>
										<SearchSelect field={this.form.$("region")} items={GlobalStore.CL["addressRegion"]} />
									</Grid>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("county")} items={GlobalStore.CL["addressCounty"]} />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("street")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput field={this.form.$("house_number")} />
									</Grid>
									<Grid item xs={1}>
										<XsAutocomplete
											field={this.form.$("zip")}
											minLengthForSearch="3"
											api={api.loadEntityZip}
											inputRenderer={(obj) => obj.name_ext}
											saveValue={(obj) => obj.code}
											freeText={true}
											freeTextField={this.form.$("zipText")}
											postAction={(obj) => {
												this.form.$("zipText").value = obj.name_ext
											}}
											onClear={() => {
												this.form.$("zipText").value = ""
											}}
											refreshComponent={this.state.refreshComponent}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsAutocomplete
											field={this.form.$("city")}
											minLengthForSearch="3"
											api={api.loadEntityCity}
											inputRenderer={(obj) => obj.name_ext}
											saveValue={(obj) => obj.code}
											freeText={true}
											freeTextField={this.form.$("cityText")}
											postAction={(obj) => {
												this.form.$("cityText").set("value", obj.name_ext)
											}}
											onClear={() => {
												this.form.$("cityText").value = ""
											}}
											refreshComponent={this.state.refreshComponent}
										/>
									</Grid>
									<Grid item container justify="flex-end" alignItems="center" xs={2} spacing={8} className="mt-0">
										{OrgunitDetailAddressesStore.editable ? (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.save" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										) : (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.add" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										)}
										<Grid item>
											<XsButton
												className="xs-danger xs-outline"
												onClick={() => {
													this.initForm()
													OrgunitDetailAddressesStore.isAdd = false
													OrgunitDetailAddressesStore.editable = false
													this.refreshAutocomplete()
												}}
												text={<FormattedMessage id="Common.label.cancel" />}
												icon={<i className="fal fa-times fa-lg"></i>}
											/>
										</Grid>
									</Grid>
								</Grid>
							</form>
						) : (
							<Grid item className="pb-2 pt-2">
								<XsButton
									className="xs-primary xs-outline"
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Common.button.addAnother" />}
									onClick={() => {
										OrgunitDetailAddressesStore.isAdd = true
										OrgunitDetailAddressesStore.editable = false
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<XsConfirmationDialog
					name="xsDeleteOrgunitContactAddress"
					text={<FormattedMessage id="Patient.form.contactForm.confirmationMessage" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.address.delete"
					}}
					onConfirmation={() => this.deleteContactAddress()}
				/>
			</React.Fragment>
		)
	}
}
