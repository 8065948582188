//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {FormControlLabel, TextField, Checkbox, IconButton, InputAdornment} from "@material-ui/core"

import DeleteIcon from "@material-ui/icons/Clear"

// import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import TourStore from "../../../../global/store/TourStore"

@injectIntl
@observer
export default class AddSearchPatient extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isSaving: false
		}
	}

	render() {
		return (
			<div className="xs-dialog-content xs-first-step xs-column">
				<div className="xs-header">
					<i className="xs-plus-icon fal fa-calendar-plus" />
					<XsIconButton className="xs-close-btn" icon={<DeleteIcon onClick={() => AddEventDialogStore.close()} />} />
				</div>
				<div className="xs-title">
					<FormattedMessage id="Calendar.addEvent.dialog.title" />
				</div>
				<div className="xs-subtitle">
					<FormattedMessage id="Calendar.addEvent.dialog.subtitle" />
				</div>
				<div className="xs-curr-orgunit">
					<FormControlLabel
						control={
							<Checkbox
								className="xs-orgunit-checkbox"
								checked={AddEventDialogStore.orgUnit_addEventFilter}
								onChange={() => {
									AddEventDialogStore.orgUnit_addEventFilter = !AddEventDialogStore.orgUnit_addEventFilter
									AddEventDialogStore.searchPatient()
								}}
							/>
						}
						label={
							<span className="xs-orgunit-label">
								<FormattedMessage id="Patient.PatientList.Filter.Orgunit" />
							</span>
						}
					/>
				</div>
				<div className="xs-search">
					<TextField
						placeholder={this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.searchPlaceholder"})}
						autoFocus={true}
						value={AddEventDialogStore.searchValue}
						onChange={(e) => {
							AddEventDialogStore.searchValue = e.target.value
							AddEventDialogStore.searchPatient()
						}}
						InputProps={{
							className: "xs-search-input tourSearchPatient",
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										disabled={isEmpty(AddEventDialogStore.searchValue) ? true : false}
										onClick={() => {
											AddEventDialogStore.onHandleUnknowPatient()
											this.setState({isSaving: false})
											if (TourStore.runSaveNewEvent_tour) {
												TourStore.handleNextSaveNewEventTour()
											}
										}}
									>
										<i className="xs-plus-btn fal fa-plus-square tourSearchPlus" />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</div>
				{isSafe(AddEventDialogStore.patientList) && AddEventDialogStore.patientList.length > 0 && (
					<div className="xs-patient-list-scrollbar">
						<div className="xs-patient-list">
							{AddEventDialogStore.patientList.map((patient, idx) => {
								let lastName = isNotEmpty(patient.last_name) ? patient.last_name.toUpperCase() : ""

								return (
									<div
										key={idx}
										className={`xs-patient tourPatient${idx}`}
										onClick={() => {
											AddEventDialogStore.onHandleSelectPatient(patient)
											this.setState({isSaving: false})
											if (TourStore.runSaveEvent_tour) {
												TourStore.runSaveEvent_tour = false
												TourStore.handleNextSaveEventTour()
											}
										}}
									>
										<div className="xs-patient-info">
											<div className="xs-full-name">
												{isEmpty(patient.last_name) && isEmpty(patient.first_name)
													? patient.client_text
													: lastName + " " + patient.first_name}
											</div>
											<div className="xs-gender">
												{patient.gender}, {patient.age} <FormattedMessage id="Common.label.years" />
											</div>
										</div>
										<div className="xs-insurer">
											<div className="xs-identifier">{patient.identifier}</div>
											<div className="xs-insurer-name">{patient.insurer_short_name}</div>
											{/* <div className="xs-insurer-name">{patient.insurer_name}</div> */}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
		)
	}
}
