"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"
import moment from "moment"
// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage} from "react-intl"
// import TextField from "@material-ui/core/TextField"
// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import Grid from "@material-ui/core/Grid"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// definitions of form fields
import fields from "./patientInfoFields"
// actions
import api from "../../../actions/api"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientCheckInsurerDialogStore from "../../../stores/PatientCheckInsurerDialogStore"
import EditEventDialogStore from "../../../stores/EditEventDialogStore"
import {padStart} from "../../../../../global/helpers/functions"
import {patientBinding, portfolioBinding} from "./patientInfoBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	insertFormDataByScheme
} from "../../../../../global/helpers/bindings"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import EditIcon from "@material-ui/icons/Create"
import {observable} from "mobx"
import ContactTableEx from "./contactTableEx"
import InsuranceTableEx from "./insuranceTableEx"
import CapitationTableEx from "./capitationTableEx"
import ConsentTableEx from "./consentTableEx"
import PatientCheckInsurerDialog from "./patientCheckInsurerDialog"
import DuplicityPersonModal from "./duplicityPersonModal"
import DuplicityPersonModalStore from "../../../stores/DuplicityPersonModalStore"
import RouterStore from "../../../../../global/store/RouterStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import WarningStore from "../../../../../global/store/WarningStore"
import {injectIntl} from "react-intl"
import ContactAddressTableEx from "./contactAddressTableEx"
import PatientInfoFormStore from "../../../stores/PatientInfoFormStore"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import ContactPersonTableEx from "./contactPersonTableEx"
import ContactFormStore from "../../../stores/ContactFormStore"
import CapitationFormStore from "../../../stores/CapitationFormStore"
import InsuranceFormStore from "../../../stores/InsuranceFormStore"
import ConsentFormStore from "../../../stores/ConsentFormStore"
import ContactPersonFormStore from "../../../stores/ContactPersonFormStore"
import ContactAddressFormStore from "../../../stores/ContactAddressFormStore"
import ContractTableEx from "./contractTableEx"
import InvitePatientDialog from "./invitePatientDialog"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

const initFormData = (form, id) => {
	GlobalStore.refreshCodeLists([
		"endReason",
		"genders",
		"titles",
		"countries",
		"consentTypes",
		"contactTypes",
		"insuranceTypes",
		"relationshipType",
		"addressCounty",
		"addressRegion"
	])

	if (isSafe(id)) {
		loadDataToFormByScheme(form, patientBinding, {personId: id}, (data) => {
			if (isSafe(data) && isSafe(data.specific_rels)) {
				let client = data.specific_rels.find((i) => i._type == "Entity.Data.Client")
				if (isSafe(client) && isSafe(client.addresses)) {
					let address = client.addresses.find((i) => i.active == true)
					if (isSafe(address)) {
						form.$("region").value = isSafe(address.region) ? address.region._id : ""
						form.$("county").value = isSafe(address.county) ? address.county._id : ""
						if (isSafe(address.obj_city)) {
							form.$("city").value = address.obj_city._id
						}
						if (isSafe(address.obj_zip)) {
							form.$("zip").value = address.obj_zip._id
						}
					}
				}
				DataStore.setPatientInfo(data)
			}
		})
	}
}

@injectIntl
@observer
export default class PatientInfoForm extends React.Component {
	// here write your event behaviours

	@observable testValue = null
	constructor(props) {
		super(props)

		PatientInfoFormStore.warningText = props.intl.formatMessage({id: "Common.label.infoNoCriteria"})

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				form.$("region").value = form.$("region").value === "" ? null : form.$("region").value
				form.$("county").value = form.$("county").value === "" ? null : form.$("county").value
				form.$("city").value = form.$("city").value === "" ? null : form.$("city").value
				form.$("zip").value = form.$("zip").value === "" ? null : form.$("zip").value
				form.$("identifier").value = form.$("identifier").value.includes("/")
					? form.$("identifier").value.replace("/", "")
					: form.$("identifier").value

				if (isNotSafe(props.id) && isNotSafe(DataStore.patientIdReloaded)) {
					if (DataStore.isCreatedFromEvent) {
						insertFormDataByScheme(
							form,
							patientBinding,
							{
								city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
								region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
								county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
								zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
							},
							(response) => {
								insertFormDataByScheme(
									form,
									portfolioBinding,
									{
										providerId: getSelectedOrgUnitID(),
										clientId: response.specific_rels[0]._id
									},
									() => (
										RouterStore.push(`/patient?id=${response._id}`),
										EditEventDialogStore.updatedIdEvent(response),
										(DataStore.isCreatedFromEvent = false)
									),
									false,
									"Patient.form.patientinfo.saveMessage"
								)
							}
						)
					} else {
						insertFormDataByScheme(
							form,
							patientBinding,
							{
								city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
								region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
								county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
								zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
							},
							(response) => {
								insertFormDataByScheme(
									form,
									portfolioBinding,
									{
										providerId: getSelectedOrgUnitID(),
										clientId: response.specific_rels[0]._id
									},
									() => RouterStore.push(`/patient?id=${response._id}`),
									false,
									"Patient.form.patientinfo.saveMessage"
								)
							}
						)
					}
				} else {
					const personId = isSafe(DataStore.patientIdReloaded) ? DataStore.patientIdReloaded : props.id
					if (isSafe(DataStore.patientIdReloaded)) {
						saveFormDataByScheme(
							form,
							patientBinding,
							{
								city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
								region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
								county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
								zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
							},
							(response) => {
								insertFormDataByScheme(
									form,
									portfolioBinding,
									{
										providerId: getSelectedOrgUnitID(),
										clientId: response.specific_rels[0]._id
									},
									() => {
										RouterStore.push(`/patient?id=${response._id}`)
										DataStore.loadEntity(personId)
									}
								)
							}
						)
					} else {
						saveFormDataByScheme(
							form,
							patientBinding,
							{
								city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
								region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
								county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
								zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
							},
							() => {
								loadDataToFormByScheme(form, patientBinding, {personId: personId})
								DataStore.loadEntity(personId)
							}
						)
					}
					DataStore.patientIdReloaded = undefined
					//updateFormDataByScheme(form, patientBinding, {personId:props.id}, () => loadDataToFormByScheme(form, patientBinding, { personId: props.id }))
				}

				// TODO doplnit este dalsiu schemu na doplnenie portfolie lekara
				//         {_ref: false, _type: "Entity.Data.SpecificRelRelationship",…}
				// rel_type
				// :
				// {_type: "Entity.CL.RelationshipType", _id: "General"}
				// specific_rel_1
				// :
				// {_type: "Entity.Data.OrgUnit", _id: "97"}
				// specific_rel_2
				// :
				// {_type: "Entity.Data.Client", _id: "72772"}
				// validity
				// :
				// {_ref: false, _type: "IXS.Entity.Data.Validity", from: "", to: "", zone: "L"}
				// _ref
				// :
				// false
				// _type
				// :
				// "Entity.Data.SpecificRelRelationship"
				UIStore.patientInfoFormDisabled = true
				//form.init(get(DataStore.patientDTO.get("patient"), dataBindings))
				// action.updateEntityInfo(DataStore.patientDTO.get("patient")).then(() => {
				//   action.releaseLock(DataStore.patientDTO.get("patient")._id)
				//   form.init(get(DataStore.patientDTO.get("patient"), dataBindings))
				// })
			},
			onError() {
				// special error notification here
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.loadedData = initFormData(this.form, this.props.id)

		if (isSafe(this.props.id)) {
			DataStore.getEmployeeContracts(this.props.id)
			DataStore.getPatientCapitations()
			DataStore.getPatientConsents()
			DataStore.getPatientContactPersons()
		} else {
			this.form.$("country").value = "SK"
		}

		PatientInfoFormStore.formRef = this.form
	}

	getDTValue(value) {
		let date = value
		if (value !== undefined && value !== null && value !== "" && typeof value === "string") {
			date = moment(value, "YYYY-M-D")
		}
		return date
	}

	checkInsurance() {
		const ZPCode = isNotEmpty(this.form.$("insurerCode").value)
			? this.form.$("insurerCode").value
			: this.form.$("insurer").value
		PatientCheckInsurerDialogStore.checkInsurance(ZPCode, this.form.$("identifier").value)
	}

	stopRefresh = false

	changeIdentifier(field) {
		const identifier = field.value.includes("/") ? field.value.replace("/", "") : field.value
		if (!this.stopRefresh && field.changed && !this.form.$("dateOfBirth").changed) {
			if (isFinite(identifier)) {
				const idLength = identifier.length
				let isInvalidIdentifier = false

				if (idLength === 9 || idLength === 10) {
					if (idLength === 10 && +identifier % 11 !== 0) {
						isInvalidIdentifier = true
					}

					const idfYear = +identifier.substr(0, 2)
					const actualYear = new Date()
						.getFullYear()
						.toString()
						.substr(2, 2)

					const year = +idfYear + (+idfYear <= +actualYear && +idfYear < 54 && idLength === 10 ? 2000 : 1900)
					let month = +identifier.substr(2, 2) - (+identifier.substr(2, 2) > 12 ? 50 : 0)
					let day = +identifier.substr(4, 2)
					const gender = +identifier.substr(2, 2) > 12 ? "F" : "M"

					month = padStart(month, 2, "0")
					day = padStart(day, 2, "0")

					if (idLength === 9 && year > 1953) {
						isInvalidIdentifier = true
					}

					const testDate = new Date(`${year}-${month}-${day}`)

					if (testDate instanceof Date == false || isNaN(testDate) || testDate.getDate() !== +day) {
						WarningStore.open(
							`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
						)
					} else {
						this.form.$("dateOfBirth").set("value", `${year}-${month}-${day}`)
						this.form.$("gender").set("value", gender)

						// aj keby nebolo rodne cislo validne, aj tak urob kontrolu diplicity pacientov, lebo uz moze existovat
						let request = {
							filters: [
								{
									associated_column: "identifier",
									predicate: "=",
									values: [{id_value: identifier}]
								}
							]
						}

						api
							.getDuplicityPerson(request)
							.call()
							.then((response) => {
								if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
									DuplicityPersonModalStore.patientData = response.rows
									DuplicityPersonModalStore.open(this.form)
								}
							})
							.then(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
							.catch(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
					}
				} else {
					WarningStore.open(
						`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
					)
				}
			}
		}
	}

	// updatedDataForm = () => {
	//   this.stopRefresh = true
	//   DataStore.patientIdReloaded = GlobalStore.confirmationDialogParams.personId
	//   loadDataToFormByScheme(this.form, patientSearchBinding, { personId: GlobalStore.confirmationDialogParams.personId }, () => this.stopRefresh = false)
	//   GlobalStore.closeConfirmationDialog()
	// }

	render() {
		// const newPatient = isNotSafe(this.props.id) ? true : false
		const disabled = UIStore.patientInfoFormDisabled
		const sortedCountries = isSafe(GlobalStore.CL["countries"])
			? GlobalStore.CL["countries"].slice().sort((a, b) => a.item_order - b.item_order)
			: []
		//const sortedCountries = isSafe(GlobalStore.CL["countries"]) ? GlobalStore.CL["countries"].slice().sort((a,b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())) : []

		return (
			<div className="xs-patient-info-form">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container direction="column">
					<Grid item xs={12} className="xs-fullWidth">
						<XsTabsIcon
							value="personalInfo"
							// onChangeTab={(value) => logger("..")}
							horizontal={true}
							mainClass="xs-personal-info-tab"
							tabs={[
								{
									label: <FormattedMessage id="PatientDetail.cards.patientInfoForm" />,
									value: "personalInfo",
									content: (
										<form>
											<div className={`xs-info-form ${UIStore.patientInfoFormDisabled ? "" : "xs-editable"}`}>
												<Grid container direction="row" spacing={8}>
													<Grid item xs={3}>
														<XsInput
															inputWhite={true}
															field={this.form.$("identifier")}
															disabled={disabled}
															onBlur={(field) => this.changeIdentifier(field)}
														/>
													</Grid>
													<Grid item xs={3}>
														<XsDateTimePicker
															className="xs-white-datetimepicker"
															field={this.form.$("dateOfBirth")}
															showTimeSelect={false}
															disabled={disabled}
														/>
													</Grid>
													<Grid item xs={3}>
														<SearchSelect
															inputWhite={true}
															field={this.form.$("gender")}
															disabled={disabled}
															items={GlobalStore.CL["genders"]}
															required
														/>
													</Grid>
													<Grid item xs={3} className="xs-row">
														<SearchSelect
															inputWhite={true}
															field={this.form.$("insurer")}
															disabled={disabled || isNotEmpty(this.props.id)}
															items={RegistersCacheStore.insurersRegister}
															required
														/>
														<div data-tip data-for="checkInsurer" className="xs-row xs-align">
															<XsIconButton
																icon={<SaveIcon className="xs-check-icon" />}
																disabled={
																	isEmpty(this.form.$("identifier").value) || isEmpty(this.form.$("insurer").value)
																}
																onClick={this.checkInsurance.bind(this)}
															/>
														</div>
														<ReactTooltip id="checkInsurer">
															<FormattedMessage id="Patient.checkInsurer.dialog.headerMessage" />
														</ReactTooltip>
													</Grid>
												</Grid>
												{!disabled && isNotEmpty(this.props.id) && (
													<Grid container direction="row-reverse" spacing={8}>
														<Grid item xs={3}>
															<div className="insuranceInfo">
																<FormattedMessage id="Common.label.changeInsurerInfo" />
															</div>
														</Grid>
													</Grid>
												)}
												<Grid container direction="row" spacing={8}>
													<Grid item xs={3}>
														<SearchSelect
															field={this.form.$("title_before")}
															getOptionLabel={(row) => row.code_ext}
															disabled={disabled}
															items={
																isSafe(GlobalStore.CL["titles"])
																	? GlobalStore.CL["titles"].filter((obj) => obj.before)
																	: []
															}
														/>
													</Grid>
													<Grid item xs={3}>
														<XsInput field={this.form.$("firstname")} disabled={disabled} />
													</Grid>
													<Grid item xs={3}>
														<XsInput field={this.form.$("lastname")} disabled={disabled} />
													</Grid>
													<Grid item xs={3}>
														<SearchSelect
															field={this.form.$("title_after")}
															disabled={disabled}
															getOptionLabel={(row) => row.code_ext}
															items={
																isSafe(GlobalStore.CL["titles"])
																	? GlobalStore.CL["titles"].filter((obj) => !obj.before)
																	: []
															}
														/>
													</Grid>
												</Grid>
												<Grid container direction="row" spacing={8}>
													<Grid item xs={3}>
														<SearchSelect
															field={this.form.$("country")}
															disabled={disabled}
															items={sortedCountries}
															required
														/>
													</Grid>
													<Grid item xs={3}>
														<SearchSelect
															field={this.form.$("region")}
															items={GlobalStore.CL["addressRegion"]}
															disabled={disabled}
														/>
													</Grid>
													<Grid item xs={3}>
														<SearchSelect
															field={this.form.$("county")}
															items={GlobalStore.CL["addressCounty"]}
															disabled={disabled}
														/>
													</Grid>
													<Grid item xs={3}>
														<XsAutocomplete
															field={this.form.$("city")}
															minLengthForSearch="3"
															disabled={disabled}
															api={api.loadEntityCity}
															inputRenderer={(obj) => obj.name_ext}
															saveValue={(obj) => obj.code}
															freeText={true}
															freeTextField={this.form.$("cityText")}
															postAction={(obj) => {
																this.form.$("cityText").set("value", obj.name_ext)
															}}
															onClear={() => {
																this.form.$("cityText").value = ""
															}}
														/>
													</Grid>
													<Grid item container justify="space-between" spacing={8}>
														<Grid item container xs={9} spacing={8}>
															<Grid item xs={4}>
																<XsInput field={this.form.$("street")} disabled={disabled} />
															</Grid>
															<Grid item xs={2}>
																<XsInput field={this.form.$("houseNumber")} disabled={disabled} />
															</Grid>
															<Grid item xs={3}>
																<XsAutocomplete
																	field={this.form.$("zip")}
																	minLengthForSearch="3"
																	disabled={disabled}
																	api={api.loadEntityZip}
																	inputRenderer={(obj) => obj.name_ext}
																	saveValue={(obj) => obj.code}
																	freeText={true}
																	freeTextField={this.form.$("zipText")}
																	postAction={(obj) => {
																		this.form.$("zipText").value = obj.name_ext
																	}}
																	onClear={() => {
																		this.form.$("zipText").value = ""
																	}}
																/>
															</Grid>
														</Grid>
														<Grid item xs={3}>
															<Grid item>
																<XsDateTimePicker
																	className="xs-white-datetimepicker"
																	field={this.form.$("deathDate")}
																	showTimeSelect={false}
																	disabled={disabled}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</div>
											<Grid container direction="row">
												<Grid item xs={12} className="xs-patient-info-btn">
													{UIStore.patientInfoFormDisabled && (
														<XsButton
															className="xs-primary xs-outline mr-3"
															text={<FormattedMessage id="Common.label.edit" />}
															onClick={() => {
																UIStore.patientInfoFormDisabled = false
																// action.acquireLock(DataStore.patientDTO.get("patient")._id).then(response => {
																//   if (response) { //TODO, potrebujem zistit ze sa lock nevytvoril, teraz to nejde!
																//     UIStore.patientInfoFormDisabled = false
																//   }
																// })
															}}
															icon={<EditIcon />}
														/>
													)}
													{UIStore.patientInfoFormDisabled && (
														<XsButton
															className="xs-default xs-outline mr-3"
															text={<FormattedMessage id="Patient.form.patientinfo.getPatientSummaryContact" />}
															onClick={() => {
																PatientInfoFormStore.loadDataFromEhealth(this.props.id)
															}}
														/>
													)}
													{UIStore.patientInfoFormDisabled && (
														<XsButton
															className="xs-default xs-outline"
															text={<FormattedMessage id="Patient.form.patientinfo.updatePatientSummaryContact" />}
															onClick={() => {
																PatientInfoFormStore.updateDataInEhealth()
															}}
														/>
													)}
													{!UIStore.patientInfoFormDisabled && (
														<XsButton
															className="xs-success mr-3"
															text={<FormattedMessage id="Common.label.save" />}
															type="submit"
															onClick={this.form.onSubmit}
															icon={<SaveIcon />}
														/>
													)}
													{!UIStore.patientInfoFormDisabled && (
														<XsButton
															className="xs-default xs-outline"
															text={<FormattedMessage id="Patient.form.patientinfo.unsave" />}
															onClick={() => {
																if (isNotSafe(this.props.id)) {
																	RouterStore.push("/patients")
																} else {
																	loadDataToFormByScheme(this.form, patientBinding, {personId: this.props.id})
																	UIStore.patientInfoFormDisabled = true
																}
																// action.releaseLock(DataStore.patientDTO.get("patient")._id).then(() => {
																//   this.form.reset()
																//   UIStore.patientInfoFormDisabled = true
																// })
															}}
															icon={<CancelIcon />}
														/>
													)}
												</Grid>
											</Grid>
										</form>
									)
								}
							]}
						/>
					</Grid>
					<Grid item xs={12} className="xs-fullWidth">
						<XsTabsIcon
							value={UIStore.patientInfoTab}
							onChangeTab={(value) => {
								UIStore.patientInfoTab = value

								switch (value) {
									case "contacts":
										ContactFormStore.isAdd = false
										break
									case "capitations":
										CapitationFormStore.isAdd = false
										break
									case "insurers":
										InsuranceFormStore.isAdd = false
										break
									case "consents":
										ConsentFormStore.isAdd = false
										break
									case "contactpersons":
										ContactPersonFormStore.isAdd = false
										break
									case "contactaddress":
										ContactAddressFormStore.isAdd = false
										break
								}
							}}
							horizontal={true}
							// scrollButtons={true}
							//defaultValue="contacts"
							mainClass="xs-patient-info-tab"
							tabs={[
								{
									label: <FormattedMessage id="PatientDetail.cards.contacts" />,
									value: "contacts",
									count: isSafe(DataStore.patientDTO.get("contacts")) ? DataStore.patientDTO.get("contacts").length : 0,
									icon:
										UIStore.patientInfoTab === "contacts" ? (
											<i className="fas fa-address-book fa-lg" />
										) : (
											<i className="fal fa-address-book fa-lg" />
										), //<ContactsIcon />,
									content: <ContactTableEx id={this.props.id} clientId={this.props.clientId} />
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.capitations" />,
									value: "capitations",
									count: isSafe(DataStore.patientDTO.get("capitations"))
										? DataStore.patientDTO.get("capitations").length
										: 0,
									icon:
										UIStore.patientInfoTab === "capitations" ? (
											<i className="fas fa-euro-sign fa-lg" />
										) : (
											<i className="fal fa-euro-sign fa-lg" />
										), //<CapitationsIcon />,
									content: <CapitationTableEx />
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.insurers" />,
									value: "insurers",
									count: isSafe(DataStore.patientDTO.get("insurances"))
										? DataStore.patientDTO.get("insurances").length
										: 0,
									icon:
										UIStore.patientInfoTab === "insurers" ? (
											<i className="fas fa-suitcase fa-lg" />
										) : (
											<i className="fal fa-suitcase fa-lg" />
										), //<ContactsIcon />,
									// icon: UIStore.patientInfoTab === "insurers" ? <i className="fas fa-book-medical fa-lg"></i> : <i className="fal fa-book-medical fa-lg"></i>,
									content: (
										<InsuranceTableEx id={this.props.id} clientId={this.props.clientId} patientInfoForm={this.form} />
									)
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.consents" />,
									value: "consents",
									count: isSafe(DataStore.patientDTO.get("consents")) ? DataStore.patientDTO.get("consents").length : 0,
									icon:
										UIStore.patientInfoTab === "consents" ? (
											<i className="fas fa-check-double fa-lg" />
										) : (
											<i className="fal fa-check-double fa-lg" />
										), //<ConsentsIcon />,
									content: <ConsentTableEx id={this.props.id} clientId={this.props.clientId} />
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.contactpersons" />,
									value: "contactpersons",
									count: isSafe(DataStore.patientDTO.get("contactpersons"))
										? DataStore.patientDTO.get("contactpersons").length
										: 0,
									icon:
										UIStore.patientInfoTab === "contactpersons" ? (
											<i className="fas fa-users fa-lg" />
										) : (
											<i className="fal fa-users fa-lg" />
										),
									content: <ContactPersonTableEx id={this.props.id} clientId={this.props.clientId} />
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.contactadresses" />,
									value: "contactaddress",
									count: isSafe(DataStore.patientDTO.get("addresses"))
										? DataStore.patientDTO.get("addresses").length
										: 0,
									icon:
										UIStore.patientInfoTab === "contactaddress" ? (
											<i className="fas fa-address-card fa-lg" />
										) : (
											<i className="fal fa-address-card fa-lg" />
										),
									content: <ContactAddressTableEx id={this.props.id} clientId={this.props.clientId} />
								},
								{
									label: <FormattedMessage id="PatientDetail.cards.contracts" />,
									value: "contracts",
									count: isSafe(DataStore.patientDTO.get("contracts"))
										? DataStore.patientDTO.get("contracts").length
										: 0,
									icon:
										UIStore.patientInfoTab === "contracts" ? (
											<i className="fas fa-briefcase fa-lg" />
										) : (
											<i className="fal fa-briefcase fa-lg" />
										),
									content: <ContractTableEx id={this.props.id} clientId={this.props.clientId} />
								}
							]}
						/>
					</Grid>
				</Grid>
				<PatientCheckInsurerDialog />
				<DuplicityPersonModal />
				<InvitePatientDialog />
			</div>
		)
	}
}
