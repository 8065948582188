//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import DuplicityPersonStore from "../../../stores/DuplicityPersonModalStore"
import NewPatientDialogStore from "../../../stores/NewPatientDialogStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import RouterStore from "../../../../../global/store/RouterStore"
import api from "../../../actions/api"

@observer
export default class DuplicityPersonModal extends React.Component {
	constructor(props) {
		super(props)
	}

	createClientRel = (patient) => {
		const clientRequest = {
			_id: "",
			_ref: false,
			_type: "Entity.Data.Client",
			active: true,
			entity: {
				_type: "Entity.Data.Person",
				_id: patient._id
			}
		}

		api
			.saveSpecificRel(clientRequest)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					this.createPortfolio(patient._id, response._id)
				}
			})
	}

	createPortfolio = (patientId, clientId) => {
		const portRequest = {
			filters: [
				{
					associated_column: "client_id",
					values: [
						{
							id_value: clientId
						}
					]
				}
			]
		}

		api
			.checkPortfolio(portRequest, getSelectedOrgUnitID())
			.call()
			.then((response) => {
				if (isNotSafe(response) || isNotSafe(response.rows) || response.rows.length === 0) {
					const request = {
						_id: "",
						_ref: false,
						_tags: [
							{
								_id: "",
								_ref: false,
								_type: "IXS.Data.Tag",
								public: true,
								type: {
									_type: "IXS.CL.Data.TagType",
									_id: "VIP"
								},
								validity: {
									_ref: false,
									_type: "IXS.Entity.Data.ValidityInterval",
									from: "",
									to: ""
								},
								value: "1"
							}
						],
						_type: "Entity.Data.SpecificRelRelationship",
						rel_type: {
							_type: "Entity.CL.RelationshipType",
							_id: "General"
						},
						specific_rel_1: {
							_type: "Entity.Data.OrgUnit",
							_id: getSelectedOrgUnitID()
						},
						specific_rel_2: {
							_type: "Entity.Data.Client",
							_id: clientId
						},
						validity: {
							_ref: false,
							_type: "IXS.Entity.Data.Validity",
							from: "",
							to: "",
							zone: "L"
						}
					}

					api
						.saveSpecRelToSpecRel(request)
						.call()
						.then((response) => {
							if (isSafe(response) && isSafe(response._id)) {
								RouterStore.push(`/patient?id=${patientId}`)
							}
						})
				} else {
					RouterStore.push(`/patient?id=${patientId}`)
				}
			})
	}

	actualizePortfolioAndRedirect = (patient) => {
		if (isSafe(patient.specific_rels) && patient.specific_rels.length > 0) {
			const clients = patient.specific_rels.filter((x) => x._type === "Entity.Data.Client")
			if (isSafe(clients) && clients.length > 0) {
				this.createPortfolio(patient._id, clients[0]._id)
			} else {
				this.createClientRel(patient)
			}
		} else {
			this.createClientRel(patient)
		}
	}

	render() {
		return (
			<Dialog
				id="xsDuplicityPersonDialog"
				open={DuplicityPersonStore.isOpen}
				onClose={() => DuplicityPersonStore.close()}
				onEscapeKeyDown={() => DuplicityPersonStore.close()}
				disableBackdropClick={true}
				scroll="body"
				aria-labelledby="scroll-dialog-title"
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.modal.duplicity.header" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isSafe(DuplicityPersonStore.patientData) &&
						DuplicityPersonStore.patientData.length > 0 &&
						DuplicityPersonStore.patientData.map((patient, idx) => {
							let genderClassName =
								isSafe(patient.entity.gender) && patient.entity.gender._id === "M" ? "xs-woman" : "xs-woman"
							return (
								<div
									key={idx}
									className="xs-patient-item"
									onClick={() => (
										DuplicityPersonStore.close(),
										NewPatientDialogStore.close(),
										this.actualizePortfolioAndRedirect(
											patient.entity
										) /*, RouterStore.push(`/patient?id=${patient.entity._id}`)*/
									)} /*(putDataToFormByScheme(DuplicityPersonStore.form, patientPutBinding, patient.entity), DataStore.patientIdReloaded = patient.entity._id, DuplicityPersonStore.close())}*/
								>
									<div className="xs-patientInfo">
										<div className="xs-avatar"></div>
										<div className="xs-personalInfo">
											<div className="xs-fullName">{patient.entity.full_name}</div>
											<div className="xs-gender">
												{isSafe(patient.entity.gender) && isSafe(patient.entity.gender.name_ext)
													? patient.entity.gender.name_ext
													: ""}
												, {patient.entity.age} rokov
											</div>
										</div>
									</div>
									<div className={`${genderClassName} xs-insurerId`}>
										<div className="xs-label">
											<FormattedMessage id="Patient.labels.insurerID" defaultMessage="Insurer ID" />
										</div>
										<div className="xs-identifier">{patient.entity.identifier}</div>
									</div>
								</div>
							)
						})}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times"></i>}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => DuplicityPersonStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
