//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
//import moment from "moment"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
// import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import "./printTemplatesDialog.less"

@injectIntl
@observer
export default class PrintTemplatesDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["indicativeGroup"])
	}

	// onSave() {
	// 	if (PrintTemplatesStore.templateId === "transport") {
	// 		let repeatEvery = PrintTemplatesStore.templateForm.$("opakovatKazdy").value
	// 		let repeatUntil = PrintTemplatesStore.templateForm.$("opakovatDo").value

	// 		if (repeatEvery > 0 && isSafe(repeatUntil)) {
	// 			repeatUntil = repeatUntil.toDate()
	// 			repeatUntil.setDate(repeatUntil.getDate() + 1)

	// 			for (var date = new Date(); date < repeatUntil; date.setDate(date.getDate() + +repeatEvery)) {
	// 				logger("REPEAT", date)

	// 				let casPristavenia = PrintTemplatesStore.templateForm.$("datumCasPristaveniaVozidla").value.toDate()
	// 				PrintTemplatesStore.templateForm.$("datumCasPristaveniaVozidla").value = moment(
	// 					casPristavenia.setDate(date.getDate())
	// 				)
	// 				PrintTemplatesStore.templateForm.$("datum").value = moment(date)

	// 				logger(
	// 					"datumCasPristaveniaVozidla",
	// 					PrintTemplatesStore.templateForm.$("datumCasPristaveniaVozidla").value.toDate()
	// 				)
	// 				logger("datum", PrintTemplatesStore.templateForm.$("datum").value.toDate())

	// 				PrintTemplatesStore.onSave()
	// 			}
	// 		}
	// 	} else {
	// 		PrintTemplatesStore.onSave()
	// 	}
	// }

	// onSaveAndPrint() {
	// 	if (PrintTemplatesStore.templateId === "transport") {
	// 		logger("Opakovat kazdy", PrintTemplatesStore.templateForm.$("opakovatKazdy").value)
	// 		logger("Opakovat do", PrintTemplatesStore.templateForm.$("opakovatDo").value)
	// 	} else {
	// 		PrintTemplatesStore.onSaveAndPrint()
	// 	}
	// }

	render() {
		const templateObj = isSafe(PrintTemplatesStore.templateId)
			? PrintTemplatesStore.printTemplates.find((x) => x.id === PrintTemplatesStore.templateId)
			: null

		const isPrintablePaper = isSafe(templateObj) ? templateObj.isPrintable : false

		const hidePrint = isSafe(templateObj) ? templateObj.hidePrint : false

		// const isMultiPrintablePaper = isSafe(PrintTemplatesStore.templateId)
		// 	? PrintTemplatesStore.printTemplates.find((x) => x.id === PrintTemplatesStore.templateId).isMultiPrintable
		// 	: false

		return (
			<Dialog
				id="xsPatientPrintTemplates"
				open={PrintTemplatesStore.isOpen}
				onClose={() => PrintTemplatesStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog dialog-printtemplates"
			>
				<DialogTitle>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-file-alt fa-2x" />
						</div>
						<div className="xs-header-title">{PrintTemplatesStore.title}</div>
					</div>
				</DialogTitle>

				<DialogContent className="xs-content">{PrintTemplatesStore.templateContent}</DialogContent>

				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times fa-lg" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => PrintTemplatesStore.close()}
					/>
					<div>
						{(PrintTemplatesStore.templateId != "corona" || !isSafe(PrintTemplatesStore.editedtemplateId)) && (
							<XsButton
								className="xs-success xs-outline"
								icon={<i className="fal fa-save fa-lg" />}
								text={<FormattedMessage id="Common.label.save" />}
								onClick={() => {
									//this.onSave()
									PrintTemplatesStore.onSave(
										this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}),
										this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
									)
								}}
							/>
						)}
						{!hidePrint && (
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-save fa-lg" />}
								text={<FormattedMessage id="Common.dialog.SaveAndPrint" />}
								onClick={() => {
									PrintTemplatesStore.onSaveAndPrint(
										this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})
									)
								}}
							/>
						)}
						{isPrintablePaper && (
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-save fa-lg" />}
								text={<FormattedMessage id="Common.dialog.SaveAndPrintToTemplate" />}
								onClick={() => {
									PrintTemplatesStore.onSaveAndPrint(
										this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}),
										true
									)
								}}
							/>
						)}
						{/* ==> toto bude pre tlaciva s viacerymi verziami papierov <==
						{isMultiPrintablePaper && (
							<SearchSelect
								className="xs-success ml-3 labSelect"
								label="Laboratórium"
								// items={[
								// 	{label: "ALPHA MEDICAL", value: "alphamedical"},
								// 	{label: "MEDIREX", value: "medirex"},
								// 	{label: "SYNLAB", value: "synlab"}
								// ]}
								onChange={(val) => this.setState({labSelect: val})}
								value={this.state.labSelect}
								required={true}
							/>
						)} */}
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
