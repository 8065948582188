"use strict"

// default imports for all farms
import React, {useEffect} from "react"
import {observer} from "mobx-react"
// import UI controls here
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import Grid from "@material-ui/core/Grid"
// definitions of form fields
import {FormattedMessage, injectIntl} from "react-intl"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import RouterStore from "../../../../../global/store/RouterStore"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {handleInsertHelpersIntoTextArea} from "../../../../../global/helpers/actions"
import XsSearchDropDownAction from "../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import TemplateForm from "../patientRecordForm/templateForm"
// import StorageEx from "../../../../../global/storage/storageEx"

function PatientOperationForm(props) {
	// DataStore.loadTemplates("OP") !!!!!!!!!!!!

	let dropdownRecordTemplates =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["OP"]) &&
		isSafe(DataStore.templatesAll["OP"].records) &&
		DataStore.templatesAll["OP"].records.length > 0
			? DataStore.templatesAll["OP"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r)
						}
					})
			: []

	// logger(`onsubmit: ${props.form.onSubmit}`)

	useEffect(() => {
		const handler = function operation(e) {
			if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "operation") {
				//ulozit a zavriet request Alt + U
				if (e.altKey && e.keyCode == 85) {
					e.preventDefault()
					props.form.onSubmit(e)
				}
			}
		}

		document.body.addEventListener("keydown", handler)

		return function cleanUp() {
			document.body.removeEventListener("keydown", handler)
		}
	}, [])

	return (
		<section className="xs-patient-card-form xs-column">
			{/* {this.renderAutoSaveConfirmDialog()} */}
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left xs-flex">
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<XsDateTimePicker field={props.form.$("examdate")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								field={props.form.$("anamnesis")}
								showNoLabel={true}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("anamnesis").value) &&
														PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"anamnesis",
															PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis.cursor,
															data.itemData
														)
													} else {
														props.form.$("anamnesis").value += data.itemData
													}
													delete PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis) ||
											PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis.cursor != data.cursor)
									) {
										PatientAnamnesisStore.operationForm_textAreaCursors.anamnesis = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<TemplateForm type="OP" />
					</Grid>
				</div>
				{/* <div className="xs-patient-form-right">
          </div> */}
			</div>
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={props.form.onSubmit}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						props.options.onClose("OPERATION", false, true)
						// UIStore.patientCardTab = ""
						// StorageEx.deleteByKey(getKeyInfo("OPERATION"))
						// props.form.reset()
						// this.deleteFromSession()
					}}
					icon={<CancelIcon />}
				/>
			</div>
		</section>
	)
}

export default injectIntl(observer(PatientOperationForm))
