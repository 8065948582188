"use strict"

import {observable, action} from "mobx"
import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"
import {getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"

class GetPatientSummaryStore {
	htmlData = ""
	@observable step = 1
	@observable isOpen = false
	@observable errorMessage = undefined
	@observable isFetching = true
	@observable notResponse = false
	@observable selectedValue = "ZdravotneProblemyZavazneDiagnozy"

	@observable nextButton = false
	indexZaznamu = 1

	@action open() {
		this.isOpen = true
	}

	@action handleChange(event) {
		this.selectedValue = event.target.value
	}

	@action search() {
		this.isFetching = true
		this.errorMessage = undefined
		this.notResponse = false
		this.step = 2

		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetPatientSummary`,
			body: JSON.stringify({
				ClassName: "DajPacientskySumar_v2",
				[this.selectedValue]: true,
				IndexZaznamu: this.indexZaznamu,
				VelkostStranky: 10,
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((response) => {
				if (isSafe(response) && isNotEmpty(response.ErrorMessage)) {
					GlobalStore.checkEHGWError(response.ErrorCode, response.ErrorMessage, response.IsEhealthException)
					this.errorMessage =
						(response.IsEhealthException === true ? "Chyba z NZIS! " : "Chyba z ambee! ") + response.ErrorMessage
				} else {
					if (isSafe(response.HtmlData) && isNotEmpty(response.HtmlData)) {
						if (
							isNotEmpty(response.ResultCount) &&
							+response.ResultCount > 0 &&
							+response.ResultCount + 1 < this.indexZaznamu
						) {
							this.nextButton = true
							this.indexZaznamu++
						} else {
							this.nextButton = false
						}

						this.htmlData = b64DecodeUnicode(response.HtmlData)
					} else {
						this.notResponse = true
					}
				}

				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action close() {
		this.isOpen = false
		this.step = 1
		this.selectedValue = "ZdravotneProblemyZavazneDiagnozy"
		this.indexZaznamu = 1
		this.nextButton = false
		this.errorMessage = undefined

		this.htmlData = ""
	}
}

var singleton = new GetPatientSummaryStore()
export default singleton
