"use strict"

import {observable, action} from "mobx"
import DDaction from "../actions/patientCard"
import UIStore from "../stores/UIStore"
import api from "../actions/api"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"

// import WarningStore from "../../../global/store/WarningStore"

class VaccAllergyDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable vaccAllergy = null
	defaultVacc = []

	@action open(id, symptoms) {
		this.isOpen = true
		this.recordId = id
		this.vaccAllergy = isSafe(symptoms) && symptoms.length > 0 ? symptoms.map((x) => x.type) : []
	}

	@action onHandleChange(value) {
		this.vaccAllergy = value
	}

	@action save() {
		UIStore.isFormSaving = true
		let postObj = {
			_ref: false,
			symptoms: []
		}

		this.vaccAllergy.forEach((x) => {
			postObj.symptoms.push({
				_ref: false,
				_type: "EHR.Data.Medication.Symptom",
				active: true,
				severity: "Medium",
				type: {
					_id: x._id,
					_type: "EHR.CL.Medication.Vaccination.Symptom",
					_ref: true
				}
			})
		})

		api
			.saveSymptom(postObj, this.recordId)
			.call()
			.then(() => {
				let providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
					UIStore.isFormSaving = false
				})
			})
			.catch((err) => {
				logger("saveSymptom err:", err)
				UIStore.isFormSaving = false
			})

		this.close()
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.vaccAllergy = null
	}
}

var singleton = new VaccAllergyDialogStore()
export default singleton
