import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import ProblemListFilter from "./problemListFilter"

// import NonCapitationFilter from "./nonCapitationFilter"

import RouterStore from "../../../../../global/store/RouterStore"
import ProblemListStore from "../../../stores/ProblemListStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class ProblemList extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	}

	render() {
		return (
			<Grid container>
				<ProblemListFilter />
				<Grid item xs={12}>
					{ProblemListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 xs-pointer"
								onClick={() => ProblemListStore.getFilters(ProblemListStore.formRef)}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							loading={ProblemListStore.loading}
							config={{
								columnDefs: {
									full_name: {
										title: <FormattedMessage id="Common.label.firstName" />,
										type: "string",
										design: {
											width: "20%",
											body: {
												className: "blueDark xs-bold"
											}
										}
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "number",
										design: {
											width: "10%"
										}
									},
									diagnosis: {
										title: <FormattedMessage id="Common.label.diagnose" />,
										type: "number",
										design: {
											width: "35%"
										}
									},
									from: {
										title: <FormattedMessage id="Common.label.from" />,
										type: "string",
										design: {
											width: "10%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
									},
									to: {
										title: <FormattedMessage id="Common.label.to" />,
										type: "string",
										design: {
											width: "10%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "15%"
										}
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										const entity =
											isSafe(dataRow.full_patient) && isSafe(dataRow.full_patient.entity)
												? dataRow.full_patient.entity
												: null

										const insurance = dataRow.full_patient.insurances.find((x) => x.active)

										let insurer = null

										if (isSafe(insurance) && isSafe(insurance.insurer) && isNotEmpty(insurance.insurer._id)) {
											insurer = RegistersCacheStore.insurersRegister.find((x) => x._id == insurance.insurer._id)
										}

										let diagnosis = ""

										if (isNotEmpty(dataRow.diagnosis_code_ext)) diagnosis += `${dataRow.diagnosis_code_ext} `
										if (isNotEmpty(dataRow.diagnosis_name_ext)) diagnosis += `${dataRow.diagnosis_name_ext}`

										return {
											person_id: isSafe(entity) ? entity._id : "",
											full_name: isSafe(entity) ? entity.full_name : "",
											identifier: isSafe(entity) ? entity.identifier : "",
											diagnosis: diagnosis,
											from: isSafe(dataRow.interval_from) ? dataRow.interval_from : "",
											to: isSafe(dataRow.interval_to) ? dataRow.interval_to : "",
											insurer: isSafe(insurer) && isNotEmpty(insurer.name_ext) ? insurer.name_ext : ""
										}
									}
								},
								dataSource: ProblemListStore.problemData
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
