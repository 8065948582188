import React from "react"
import {observer} from "mobx-react"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import {injectIntl} from "react-intl"

import UIStore from "../../../../stores/UIStore"

import "./operationRow.less"

const OperationRow = observer(({item}) => {
	return (
		<XsExpansionPanel maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
			<div className="operationRow">
				<div dangerouslySetInnerHTML={{__html: item.data ? item.data.content : ""}} />
			</div>
		</XsExpansionPanel>
	)
})

export default injectIntl(OperationRow)
