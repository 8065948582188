"use strict"

// default imports for all farms
import React, {useEffect} from "react"
import {observer} from "mobx-react"
// import MobxReactForm from "mobx-react-form"
// import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
// import bindings from "../../../../../global/ui/globalUISchemeBindings"

// import UI controls here
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import Grid from "@material-ui/core/Grid"
import AddIcon from "@material-ui/icons/Add"

// definitions of form fields
// import fields from "./patientAnamnesisFields"

import {FormattedMessage, injectIntl} from "react-intl"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RouterStore from "../../../../../global/store/RouterStore"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import TemplateForm from "../patientRecordForm/templateForm"

import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import {
	// getSelectedOrgUnitID,
	// getUserPersonnelID,
	handleInsertHelpersIntoTextArea
} from "../../../../../global/helpers/actions"
// import {SaveableForm} from "../saveableForm/saveableForm"
import XsSearchDropDownAction from "../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
// import {formDataStateCL} from "../../../../../global/config/codeLists"

const styles = () => ({
	title: {
		marginBottom: "10px",
		fontWeight: 300,
		fontSize: "20px"
	},
	paddingRightSide: {
		paddingLeft: "20px"
	},
	marginBottomRightSide: {
		marginBottom: "20px"
	}
})

function PatientAnamnesisForm(props) {
	// constructor(props) {
	// super(props)
	// props.formName = "PatientAnamnesisForm"
	// const deleteFromSession = () => {
	// 	this.deleteFromSession()
	// 	DataStore.setFormDataState(props.formName, formDataStateCL.EMPTY)
	// }

	// DataStore.loadTemplates("ANA") !!!!!!!!!!!!!!!!!!!!!!

	// const hooks = {
	// 	onSubmit() {
	// 		// special validation here
	// 		UIStore.isFormSaving = true
	// 	},
	// 	onSuccess(form) {
	// 		PatientAnamnesisStore.saveForm(
	// 			form,
	// 			DataStore.patientDTO.get("patientID"),
	// 			getSelectedOrgUnitID(),
	// 			getUserPersonnelID(),
	// 			"EHR.Data.Anamnesis",
	// 			"ANA",
	// 			deleteFromSession
	// 		)
	// 		UIStore.overviewTab = "overview" //"anamnesis" - presmerovanie na prehlad
	// 	},
	// 	onError() {
	// 		UIStore.isFormSaving = false
	// 		GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
	// 	}
	// }
	// props.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

	//Loadujeme savnutý form
	//Rovno nastavujeme WITHDEFS, pretože nemáme žiadne async volanie
	// this.loadFormFromSession(null, 0, DataStore.checkFormDataState(props.formName, formDataStateCL.MODIFIED))
	// if (!DataStore.savedFormObject) {
	// 	if (PatientAnamnesisStore.dataState && !PatientAnamnesisStore.dataLoaded) {
	// 		PatientAnamnesisStore.insertFormData(props.form)
	// 		DataStore.setFormDataState(props.formName, formDataStateCL.WITHDEFS)
	// 	}
	// }

	// PatientAnamnesisStore.anamnesisForm_textAreaCursors = {}
	// DataStore.setFormDataState(props.formName, formDataStateCL.WITHDEFS)
	// }

	// componentWillMount() {
	// PatientAnamnesisStore.setAnamnesisData()
	// }
	useEffect(() => {
		const handler = function anamnesis(e) {
			if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "anamnesis") {
				//ulozit anamnezu Alt + U
				if (e.altKey && e.keyCode == 85 && UIStore.patientCardTab == "anamnesis") {
					e.preventDefault()
					props.form.$("closeForm").value = true
					props.form.onSubmit(e)
				}
			}
		}

		document.body.addEventListener("keydown", handler)

		return function cleanUp() {
			document.body.removeEventListener("keydown", handler)
		}
	}, [])

	const isWoman =
		DataStore.patientDTO.get("patient") && DataStore.patientDTO.get("patient").gender
			? DataStore.patientDTO.get("patient").gender._id === "F"
			: false

	const dropdownRecordTemplates =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["ANA"]) &&
		isSafe(DataStore.templatesAll["ANA"].records) &&
		DataStore.templatesAll["ANA"].records.length > 0
			? DataStore.templatesAll["ANA"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r)
						}
					})
			: []

	return (
		<section className="xs-patient-card-form xs-column">
			{/* {this.renderAutoSaveConfirmDialog()} */}
			{UIStore.isFormSaving && <XsLoading overlay={true} />}
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={(e) => {
						props.form.$("closeForm").value = false
						props.form.onSubmit(e) //, (props.form.$("closeForm").value = false)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.saveAndClose" />}
					type="submit"
					onClick={(e) => {
						props.form.$("closeForm").value = true
						props.form.onSubmit(e) //, (props.form.$("closeForm").value = true)
					}}
					icon={<SaveIcon />}
				/>
				{/* <XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Common.form.savedraft" />}
					type="submit"
					onClick={() => {
						PatientAnamnesisStore.saveForm(
							props.form,
							DataStore.patientDTO.get("patientID"),
							getSelectedOrgUnitID(),
							getUserPersonnelID(),
							"EHR.Data.Anamnesis",
							"ANA",
							() => {},
							true
						)
					}}
					icon={<SaveIcon />}
				/> */}
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						PatientAnamnesisStore.dataState = PatientAnamnesisStore.dataLoaded = false
						props.options.onClose("ANAMNESIS")
						// props.form.reset()
						// this.deleteFromSession()
						// UIStore.patientCardTab = ""
					}}
					icon={<CancelIcon />}
				/>
			</div>
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left xs-flex">
					<Grid container direction="row">
						<Grid item xs={12}>
							<XsTextArea
								maxLength={6000}
								/*ref={textAreaRef => PatientAnamnesisStore.textAreaAnamnesisRef = textAreaRef}*/
								ref={(input) => (GlobalStore.defaultFocusFields["patientAnamnesisForm"] = input)}
								field={props.form.$("anamnesis")}
								autofocus={true}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("anamnesis").value) &&
														PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"anamnesis",
															PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis.cursor,
															data.itemData
														)
													} else {
														props.form.$("anamnesis").value += data.itemData
													}
													delete PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis) ||
											PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis.cursor != data.cursor)
									) {
										PatientAnamnesisStore.anamnesisForm_textAreaCursors.anamnesis = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								field={props.form.$("socialAnamnesis")}
								maxLength={6000}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("socialAnamnesis").value) &&
														PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"socialAnamnesis",
															PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis.cursor,
															data.itemData
														)
													} else {
														props.form.$("socialAnamnesis").value += data.itemData
													}
													delete PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis) ||
											PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis.cursor != data.cursor)
									) {
										PatientAnamnesisStore.anamnesisForm_textAreaCursors.socialAnamnesis = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						{isWoman && (
							<React.Fragment>
								<Grid item xs={12}>
									<XsTextArea
										field={props.form.$("deliveryAnamnesis")}
										maxLength={6000}
										customToolbarHelpers={
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplates}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															if (
																isNotEmpty(props.form.$("deliveryAnamnesis").value) &&
																PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis
															) {
																handleInsertHelpersIntoTextArea(
																	props.form,
																	"deliveryAnamnesis",
																	PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis.cursor,
																	data.itemData
																)
															} else {
																props.form.$("deliveryAnamnesis").value += data.itemData
															}
															delete PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis
														}
													}}
												/>
											</div>
										}
										customToolbar={
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm()
													}}
												/>
											</div>
										}
										onFocus={(data) => {
											if (
												data &&
												(isNotSafe(PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis) ||
													PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis.cursor != data.cursor)
											) {
												PatientAnamnesisStore.anamnesisForm_textAreaCursors.deliveryAnamnesis = {
													cursor: data.cursor
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<XsDateTimePicker field={props.form.$("deliveryDate")} showTimeSelect={false} />
								</Grid>
							</React.Fragment>
						)}
						<TemplateForm type="ANA" />
					</Grid>
				</div>
				{/* <div className="xs-patient-form-right">
          </div> */}
			</div>
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={(e) => {
						props.form.$("closeForm").value = false
						props.form.onSubmit(e) //, (props.form.$("closeForm").value = false)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.saveAndClose" />}
					type="submit"
					onClick={(e) => {
						props.form.$("closeForm").value = true
						props.form.onSubmit(e) //, (props.form.$("closeForm").value = true)
					}}
					icon={<SaveIcon />}
				/>
				{/* <XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Common.form.savedraft" />}
					type="submit"
					onClick={() => {
						PatientAnamnesisStore.saveForm(
							props.form,
							DataStore.patientDTO.get("patientID"),
							getSelectedOrgUnitID(),
							getUserPersonnelID(),
							"EHR.Data.Anamnesis",
							"ANA",
							() => {},
							true
						)
					}}
					icon={<SaveIcon />}
				/> */}
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						PatientAnamnesisStore.dataState = PatientAnamnesisStore.dataLoaded = false
						props.options.onClose("ANAMNESIS")
						// props.form.reset()
						// this.deleteFromSession()
						// UIStore.patientCardTab = ""
					}}
					icon={<CancelIcon />}
				/>
			</div>
		</section>
	)
}

export default injectIntl(withStyles(styles)(observer(PatientAnamnesisForm)))
