import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import settings from "../../../../../../global/config/settings"
import moment from "moment"

import "./sicknessAbsRow.less"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

import UIStore from "../../../../stores/UIStore"

const SicknessAbsRow = observer(({item, intl}) => {
	const dateFrom =
		item.sickness_abs && item.sickness_abs.validity && item.sickness_abs.validity.from
			? moment(item.sickness_abs.validity.from, settings.DB_DATETIME_FORMAT).format(
					intl.formatMessage({id: "Application.moment.dateformat"})
			  )
			: ""
	const dateTo =
		item.sickness_abs && item.sickness_abs.validity && item.sickness_abs.validity.to
			? moment(item.sickness_abs.validity.to, settings.DB_DATETIME_FORMAT).format(
					intl.formatMessage({id: "Application.moment.dateformat"})
			  )
			: ""

	return (
		<XsExpansionPanel glOpen={UIStore.isOpenAllRecordsInEHROverview}>
			<div className="sickessAbsRow">
				{item.sickness_abs && item.sickness_abs.validity && (
					<div>{`${dateFrom} ${dateFrom && dateTo ? "-" : ""} ${dateTo}`}</div>
				)}
				{item.sickness_abs && item.sickness_abs.number && (
					<div>
						<span className="title">
							<FormattedMessage id="Common.label.number" />:
						</span>{" "}
						<b>{item.sickness_abs.number}</b>
					</div>
				)}
				{item.diagnosis && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
						</span>{" "}
						<b>{`${item.diagnosis_code_ext} - ${item.diagnosis_name_ext}`}</b>
					</div>
				)}
				{item.sickness_abs && item.sickness_abs.description_text && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientPrescription.note" />:
						</span>{" "}
						<b>{item.sickness_abs.description_text}</b>
					</div>
				)}
			</div>
		</XsExpansionPanel>
	)
})

export default injectIntl(SicknessAbsRow)
