// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import {withStyles} from "@material-ui/core/styles"

import "./xsDropDownButton.less"

const styles = () => ({
	button: {
		color: "#39B0DD !important",
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		border: "2px solid #39B0DD",
		textTransform: "initial",
		fontSize: "14px"
	},
	buttonOne: {
		borderBottomRightRadius: 10,
		borderTopRightRadius: 10
	},
	extrabutton: {
		color: "#39B0DD !important",
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		border: "none",
		borderTop: "2px solid #39B0DD",
		borderBottom: "2px solid #39B0DD",
		borderRight: "2px solid #39B0DD",
		margin: 0,
		padding: 0,
		minWidth: "5px"
	},
	input: {
		display: "none"
	}
})

class DropDownButton extends React.Component {
	state = {
		anchorEl: null
	}

	handleClick = (event) => {
		this.setState({anchorEl: event.currentTarget})
	}

	handleClose = () => {
		this.setState({anchorEl: null})
	}

	render() {
		const {buttons, classes} = this.props
		const {anchorEl} = this.state

		return (
			<div className="dropdownbutton">
				{buttons && buttons.length === 1 && (
					<Button
						variant="outlined"
						onClick={buttons[0].onButtonClick}
						className={[classes.button, classes.buttonOne].join(" ")}
					>
						{buttons && buttons[0].label}
					</Button>
				)}
				{buttons && buttons.length > 1 && (
					<div className="xs-dropdown-ehr">
						<Button
							variant="outlined"
							label={buttons[0].label}
							onClick={buttons[0].onButtonClick}
							className={[classes.button, buttons[0].id].join(" ")}
						>
							{buttons && buttons[0].label}
						</Button>
						<Button className={classes.extrabutton + " expand"} onClick={this.handleClick}>
							<ArrowDropDown color="primary" />
						</Button>
						<Menu id="dropdownButton" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
							{buttons.slice(1).map((action, index) => {
								return (
									<MenuItem
										key={index}
										className={action.id}
										onClick={() => {
											this.handleClose()
											setTimeout(() => action.onButtonClick(), 500)
										}}
									>
										{action.label}
									</MenuItem>
								)
							})}
						</Menu>
					</div>
				)}
			</div>
		)
	}
}

export default withStyles(styles)(DropDownButton)
