import React, {useState} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import PatientMedicationForm from "./patientMedicationForm"
// import PatientRequestSaveButtons from "../patientRequestForm/patientRequestSaveButtons"
import PatientMedicationSaveButtons from "./patientMedicationSaveButtons"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import CancelIcon from "@material-ui/icons/Clear"
// import PatientMedicationStore from "../../../stores/PatientMedicationStore"
// import Storage from "../../../../../global/storage/storage"
// import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

// @observer
// export class PatientMedicationContainer extends React.Component {
function PatientMedicationContainer(props) {
	// constructor(props) {
	// 	super(props)

	// 	this.state = {
	// 		patientOverviewLoading: true,
	// 		forms: {},
	// 		maxId: 0
	// 	}
	// 	this.formRefs = {}
	// }

	const [maxFormKey, setMaxFormKey] = useState(Math.max(...Object.keys(props.forms)))

	const addForm = () => {
		const firstForm = props.forms[Math.min(...Object.keys(props.forms))]
		props.forms[+maxFormKey + 1] = props.options.createFormFunction()
		props.forms[+maxFormKey + 1].$("diagnosis").value = firstForm.$("diagnosis").value
		setMaxFormKey(+maxFormKey + 1)
	}

	// const clearAllFromStorage = () => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).filter(
	// 		(keyVal) => keyVal.includes("PatientMedicationForm") && keyVal.includes(DataStore.patientDTO.get("patientID"))
	// 	)
	// 	storageKey.forEach((key) => {
	// 		Storage.removeItem(key)
	// 	})
	// }

	// const deleteFromStorage = (key) => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).find(
	// 		(keyVal) =>
	// 			keyVal.includes("PatientMedicationForm") &&
	// 			keyVal.includes(DataStore.patientDTO.get("patientID")) &&
	// 			keyVal.includes(key)
	// 	)
	// 	Storage.removeItem(storageKey)
	// }

	// const loadFromStorageCancel = () => {
	// 	this.canceledSessionLoad = true
	// }

	// const addFormsFromStorage = () => {
	// 	this.clearAllReqeust(this.insertFormsFromStorage)
	// }

	// const insertFormsFromStorage = () => {
	// 	let dataFromDataStore = null
	// 	let multiFormData = JSON.parse(JSON.stringify(DataStore.multiFormsFromStorage["PatientMedicationForm"]))
	// 	if (isSafe(multiFormData) && isSafe(multiFormData[Object.keys(multiFormData)[0]])) {
	// 		dataFromDataStore = multiFormData[Object.keys(multiFormData)[0]].object
	// 	}
	// 	let prescriptionKeys = Object.keys(multiFormData)
	// 	let maxKey = 0
	// 	let formKeys = []
	// 	prescriptionKeys.forEach((key) => {
	// 		let obj = JSON.parse(key)
	// 		maxKey = obj.key > maxKey ? obj.key : maxKey
	// 		formKeys.push(+obj.key)
	// 	})

	// 	let formsToRender = null

	// 	for (let i = 1; i <= maxKey; i++) {
	// 		const maxId = i
	// 		const formJSX = (
	// 			<PatientMedicationForm
	// 				key={maxId}
	// 				getFormHandler={this.getForm.bind(this)}
	// 				keyVal={maxId}
	// 				clearAllPrescriptions={() => this.clearAllPrescriptions()}
	// 				setDefaultDiagnosis={(form, state) => {
	// 					this.setDefaultDiagnosis(form, state)
	// 				}}
	// 				dataFromDataStore={i == 1 ? dataFromDataStore : null}
	// 				exact={true}
	// 			/>
	// 		)
	// 		if (formKeys.includes(+i)) {
	// 			formsToRender = Object.assign({}, formsToRender, {[maxId]: {/*ref: refVal,*/ jsx: formJSX}})
	// 		}
	// 	}

	// 	if (isSafe(formsToRender)) {
	// 		this.setState({
	// 			maxId: maxKey,
	// 			forms: formsToRender
	// 		})
	// 	} else {
	// 		this.addForm()
	// 	}
	// }

	// const setDefaultDiagnosis = (form) => {
	// 	if (isSafe(this.formRefs) && isSafe(this.formRefs[1]) && isSafe(this.formRefs[1].$("diagnosis"))) {
	// 		form.$("diagnosis").set("value", this.formRefs[1].$("diagnosis").value)
	// 	}
	// }

	// const getForm = (key, form) => {
	// 	if (isSafe(this.formRefs)) this.formRefs[key] = form
	// }

	// const clearAllReqeust = (callback) => {
	// 	this.setState({maxId: 0, forms: {}}, () => {
	// 		if (typeof callback == "function") {
	// 			callback()
	// 		}
	// 	})
	// }

	// invalidForm() {
	// 	PatientMedicationStore.validationSnackbar = true
	// }

	// componentDidMount() {
	// 	PatientMedicationStore.medicationRef = this

	// 	if (PatientMedicationStore.isGoingToReprescribe()) {
	// 		PatientMedicationStore.represcribeIdsToForms()
	// 	}
	// }

	// componentWillUnmount() {
	// 	PatientMedicationStore.medicationRef = null
	// }

	const renderMultipleMedication = () => {
		const medicationForms = []
		if (isSafe(props.forms)) {
			//Pozor na poradie v property pri objekte napr. pri 10 sa to moze posnut
			Object.keys(props.forms).forEach((formKey, idx) => {
				medicationForms.push(
					<PatientMedicationForm
						key={idx}
						keyVal={idx}
						form={props.forms[formKey]}
						// getFormHandler={this.getForm.bind(this)}
						// clearAllReqeust={() => this.clearAllReqeust()}
						// multiLoad={() => this.addFormsFromStorage()}
						// onLoadCancel={() => {
						// 	this.loadFromStorageCancel()
						// }}
					/>
				)
				if (Object.keys(props.forms).length > 1) {
					medicationForms.push(
						<div className="xs-cancel-btn">
							<XsButton
								className="xs-danger xs-outline pullLeft"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									const handler = () => {
										delete props.forms[formKey]
										setMaxFormKey(maxFormKey + 1)
									}
									GlobalStore.openConfirmationDialog("patientCardAction", {
										onConfirm: handler,
										text: "Common.label.cancelForm"
									})
									// let forms = this.state.forms
									// delete this.formRefs[key]
									// delete forms[key]
									// this.deleteFromStorage(key)
									// this.setState({forms: forms})
								}}
								icon={<CancelIcon />}
							/>
						</div>
					)
				}
			})
		}
		// if (Object.keys(this.state.forms).length === 0) {
		// 	this.formRefs = {}
		// 	const jsx = (
		// 		<PatientMedicationForm
		// 			key={1}
		// 			form={this.props.form}
		// 			getFormHandler={this.getForm.bind(this)}
		// 			clearAllReqeust={() => this.clearAllReqeust()}
		// 			keyVal={1}
		// 			multiLoad={() => this.addFormsFromStorage()}
		// 			onLoadCancel={() => {
		// 				this.loadFromStorageCancel()
		// 			}}
		// 		/>
		// 	)
		// 	this.setState({maxId: 1, forms: {1: {ref: this.formRefs[1], jsx: jsx}}})
		// } else {
		// 	Object.keys(this.state.forms).forEach((key) => {
		// 		if (key != 1) {
		// 			medicationForms.push(
		// 				<div className="xs-cancel-btn">
		// 					<XsButton
		// 						className="xs-danger xs-outline pullLeft"
		// 						text={<FormattedMessage id="Common.label.cancel" />}
		// 						onClick={() => {
		// 							let forms = this.state.forms
		// 							delete this.formRefs[key]
		// 							delete forms[key]
		// 							this.deleteFromStorage(key)
		// 							this.setState({forms: forms})
		// 						}}
		// 						icon={<CancelIcon />}
		// 					/>
		// 				</div>
		// 			)
		// 		}
		// 		medicationForms.push(this.state.forms[key].jsx)
		// 	})
		// }

		return medicationForms
	}

	return (
		<div>
			{renderMultipleMedication()}
			<PatientMedicationSaveButtons
				form={props.forms}
				options={props.options}
				// invalidForm={this.invalidForm}
				addForm={() => {
					addForm()
				}}
				/*clearAllReqeust={() => {
				//vsetky zavriet a vymazat zo storagu
				// this.clearAllReqeust(() => {
				// 	this.clearAllFromStorage()
				// })
			}}*/
			/>
		</div>
	)
}
export default injectIntl(observer(PatientMedicationContainer))
