import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
// import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
// import api from "../../../actions/api"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
// import RouterStore from "../../../../../global/store/RouterStore"
// import DrugReportStore from "../../../stores/DrugReportStore"
import NcziA01ReportStore from "../../../stores/NcziA01ReportStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// import ExportDrugReportDialog from "./exportDrugReportDialog"
// import moment from "moment"

@injectIntl
@observer
export default class NcziA01ReportList extends React.Component {
	constructor(props) {
		super(props)

		NcziA01ReportStore.reportList = []

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				NcziA01ReportStore.loadNcziA01Report(form, props.intl.formatMessage({id: "Common.label.yearFilterRequired"}))
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					year: {
						label: <FormattedMessage id="Common.label.year" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		NcziA01ReportStore.currFilters = this.form.values()
		// DrugReportStore.loadDrugReport(this.form)
	}

	// onHandleRowClick = (dataRow) => {
	// 	if (dataRow.person_id) {
	// 		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	// 	}
	// }

	render() {
		NcziA01ReportStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-ncziA01-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsInput white field={this.form.$("year")} placeholder={"napr. 2019"} />
						</Grid>
						<Grid item xs={7} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
							{isEmpty(this.form.$("year").value) && (
								<span className="xs-table-no-data ml-6 pa-2">
									<FormattedMessage id="Common.label.yearFilterRequired" />
								</span>
							)}
						</Grid>
						<Grid item xs={3}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-primary"
										text={<FormattedMessage id="Common.label.ExportXML" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											// DrugReportStore.open()
											NcziA01ReportStore.loadNcziA01Report(
												this.form,
												this.props.intl.formatMessage({id: "Common.label.yearFilterRequired"}),
												true
											)
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : NcziA01ReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									ProviderCode: {
										title: <FormattedMessage id="Common.label.oupzsCode" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									AgeGroup: {
										title: <FormattedMessage id="Common.label.ageGroup" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									Type: {
										title: <FormattedMessage id="Common.label.visiteType" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									Cnt: {
										title: <FormattedMessage id="Common.label.visiteCount" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									// defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: () => {
										// this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										// const lastInsurance =
										// 	isSafe(dataRow.full_patient) && dataRow.full_patient.insurances.length > 0
										// 		? dataRow.full_patient.insurances.find((i) => i.last)
										// : null
										return dataRow
									}
								},
								dataSource: NcziA01ReportStore.reportList
							}}
						/>
					)}
				</Grid>
				{/* <ExportDrugReportDialog form={this.form} /> */}
			</Grid>
		)
	}
}
