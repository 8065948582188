import React, {useState} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import PatientRequestForm from "../patientRequestForm/patientRequestForm"
import PatientRequestStore from "../../../stores/PatientRequestStore"
import PatientRequestSaveButtons from "../patientRequestForm/patientRequestSaveButtons"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import CancelIcon from "@material-ui/icons/Clear"
import GlobalStore from "../../../../../global/store/GlobalStore"
// import PatientRequestStore from "../../../stores/PatientRequestStore"
// import DataStore from "../../../stores/DataStore"
// import Storage from "../../../../../global/storage/storage"

// @observer
// export class PatientRequestContainer extends React.Component {
function PatientRequestContainer(props) {
	const [maxFormKey, setMaxFormKey] = useState(Math.max(...Object.keys(props.forms)))

	const addForm = () => {
		const firstForm = props.forms[Math.min(...Object.keys(props.forms))]
		props.forms[+maxFormKey + 1] = props.options.createFormFunction()
		props.forms[+maxFormKey + 1].$("diagnosis").value = firstForm.$("diagnosis").value
		props.forms[+maxFormKey + 1].$("professional_activity").value = PatientRequestStore.paAmbDefaultID
		setMaxFormKey(+maxFormKey + 1)
	}
	// constructor(props) {
	// 	super(props)

	// 	this.state = {
	// 		patientOverviewLoading: true,
	// 		forms: {},
	// 		maxId: 0
	// 	}
	// 	this.formRefs = {}
	// }

	// const addForm = (numberOfForms = 1, requestIds = [])=> {
	// 	const maxId = this.state.maxId + 1

	// const formJSX = (
	// 	<PatientRequestForm
	// 		key={maxId}
	// 		getFormHandler={this.getForm.bind(this)}
	// 		keyVal={maxId}
	// 		requestId={requestIds[0]}
	// 		clearAllReqeust={() => this.clearAllReqeust()} /*ref={(ref) => refVal = ref}*/
	// 		canceledSessionLoad={this.canceledSessionLoad}
	// 		setDefaultDiagnosis={(form) => {
	// 			this.setDefaultDiagnosis(form)
	// 		}}
	// 		exact={true}
	// 	/>
	// )

	// this.setState({maxId: maxId, forms: Object.assign({}, this.state.forms, {[maxId]: {jsx: formJSX}})}, () => {
	// 	numberOfForms > 1 ? (requestIds.shift(), this.addForm(numberOfForms - 1, requestIds)) : null
	// })
	// }

	// clearAllFromStorage = () => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).filter(
	// 		(keyVal) => keyVal.includes("PatientRequestForm") && keyVal.includes(DataStore.patientDTO.get("patientID"))
	// 	)
	// 	storageKey.forEach((key) => {
	// 		Storage.removeItem(key)
	// 	})
	// }

	// deleteFromStorage = (key) => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).find(
	// 		(keyVal) =>
	// 			keyVal.includes("PatientRequestForm") &&
	// 			keyVal.includes(DataStore.patientDTO.get("patientID")) &&
	// 			keyVal.includes(key)
	// 	)
	// 	Storage.removeItem(storageKey)
	// }

	// loadFromStorageCancel = () => {
	// 	this.canceledSessionLoad = true
	// }

	// addFormsFromStorage = () => {
	// 	this.clearAllReqeust(this.insertFormsFromStorage)
	// 	// this.insertFormsFromStorage()
	// }

	// clearAllReqeust(callback) {
	// 	this.setState({maxId: 0, forms: {}}, () => {
	// 		if (typeof callback == "function") {
	// 			callback()
	// 		}
	// 	})
	// }

	// invalidForm() {
	// 	PatientRequestStore.validationSnackbar = true
	// }

	// componentDidMount() {
	// 	PatientRequestStore.requestRef = this

	// 	if (PatientRequestStore.isGoingToReprescribe()) {
	// 		PatientRequestStore.represcribeIdsToForms()
	// 	}
	// }

	// componentWillUnmount() {
	// 	PatientRequestStore.requestRef = null
	// }

	const renderMultipleRequest = () => {
		const requestForms = []
		if (isSafe(props.forms)) {
			Object.keys(props.forms).forEach((formKey, idx) => {
				requestForms.push(
					<PatientRequestForm
						key={idx}
						keyVal={idx}
						form={props.forms[formKey]}
						options={props.options}
						// getFormHandler={this.getForm.bind(this)}
						// clearAllReqeust={() => this.clearAllReqeust()}
						// multiLoad={() => this.addFormsFromStorage()}
						// onLoadCancel={() => {
						// 	this.loadFromStorageCancel()
						// }}
					/>
				)
				if (Object.keys(props.forms).length > 1) {
					requestForms.push(
						<div className="xs-cancel-btn">
							<XsButton
								className="xs-danger xs-outline pullLeft"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									const handler = () => {
										delete props.forms[formKey]
										setMaxFormKey(maxFormKey + 1)
									}
									GlobalStore.openConfirmationDialog("patientCardAction", {
										onConfirm: handler,
										text: "Common.label.cancelForm"
									})
								}}
								icon={<CancelIcon />}
							/>
						</div>
					)
				}
			})
		}
		return requestForms
	}

	return (
		<div>
			<PatientRequestSaveButtons
				top
				form={props.forms}
				options={props.options}
				addForm={() => {
					addForm()
				}}
				clearAllReqeust={() => {
					// this.clearAllReqeust()
					// this.clearAllFromStorage()
				}}
			/>
			{renderMultipleRequest()}
			<PatientRequestSaveButtons
				form={props.forms}
				options={props.options}
				addForm={() => {
					addForm()
				}}
				clearAllReqeust={() => {
					// this.clearAllReqeust()
					// this.clearAllFromStorage()
				}}
			/>
		</div>
		// <PatientRequestForm />
	)
}

export default injectIntl(observer(PatientRequestContainer))
