"use strict"

import {observable, action} from "mobx"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"
import {StorageBase} from "../../../global/storage/storageEx"
import InvoiceFormStore from "./InvoiceFormStore"
import api from "../actions/api"
import moment from "moment"

class GenerateInvoiceDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable isWarning = false

	doctor_expertise_id = undefined
	insurer_id = undefined
	provider_id = undefined
	personnel_id = undefined
	date_from = undefined
	date_to = undefined
	translatedExportBatchError = undefined
	translatedGetSummaryDeliveriesError = undefined
	invoiceData = []

	@action open(obj, filterObj, filterName) {
		this.isOpen = true
		this.doctor_expertise_id = obj.doctor_expertise_id
		this.insurer_id = obj.insurer_id
		this.provider_id = obj.org_unit_id
		this.personnel_id = obj.personnel_id
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
		if (isSafe(filterObj)) {
			// Storage.setItem(filterName, filterObj)
			StorageBase.updateByKey(filterName, filterObj)
		}
	}

	@action close() {
		this.isOpen = false
		this.invoiceData = []
	}

	@action generateInvoiceAndOpen(form, index) {
		UIStore.isFormSaving = true
		let formVals = form.values()

		let nameLength = 0
		//Prejdem array chechboxov pre vykony po 3 a ak je aspon jeden z 3 zachecknuty tak tak si to priratam a ak su zachecknute checboxi aspon z 2 oddeleni tak potom zobrazujem code_ext oddeleni v polozkach fakturi
		for (let i = 0; i < formVals.deliveriesChecboxes.length; i += 3) {
			nameLength += formVals.deliveriesChecboxes.slice(i, i + 3).filter((val) => val).length >= 1 ? 1 : 0
		}

		let showOrgUnitName = formVals.capitationsChecboxes.filter((val) => val).length > 1 || nameLength >= 2
		let capitations = formVals.capitationsChecboxes[index]
		let periodText = ""
		if (this.date_from) {
			periodText = moment(this.date_from).format("MM/YYYY")
		}
		//Ak su zachecknute kapitacie volam api pre kapitacie a v thene pre vykony inak len pre vykony
		if (capitations) {
			api
				.loadSummaryCapitations({
					from: this.date_from,
					to: this.date_to,
					insurer_id: this.insurer_id,
					org_unit_id: GlobalStore.orgunits[index]._id,
					patient_id: null,
					personnel_id: this.personnel_id
				})
				.call()
				.then((data) => {
					if (isSafe(data.sub_totals) && data.sub_totals.length > 0) {
						data.sub_totals.forEach((x) => {
							if (isSafe(x.sum) && x.sum > 0) {
								this.invoiceData.push({
									itemName:
										(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") +
										`Kapitácia veková skupina od ${x.age_from} do ${x.age_to}`,
									itemUnitPrice: "",
									itemPrice: x.sum,
									itemQuantity: ""
								})
							}
						})
					}
					if (isSafe(data.sum_additional) && data.sum_additional > 0) {
						this.invoiceData.push({
							itemName:
								(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") + "Cena za dodatkovú kapitáciu",
							itemUnitPrice: "",
							itemQuantity: "",
							itemPrice: data.sum_additional
						})
					}
					//Array povolenych hodnot pre filtrovanie podla checkboxov
					let invoiceFilters = []
					if (formVals.deliveriesChecboxes[+index * 3]) invoiceFilters.push("GH")
					if (formVals.deliveriesChecboxes[+index * 3 + 1]) invoiceFilters.push("EU")
					if (formVals.deliveriesChecboxes[+index * 3 + 2]) invoiceFilters.push("HMLS")
					api
						.loadSummaryDeliveries({
							from: this.date_from,
							to: this.date_to,
							insurer_id: this.insurer_id,
							org_unit_id: GlobalStore.orgunits[index]._id
						})
						.call()
						.then((data) => {
							if (isSafe(data.sub_totals) && data.sub_totals.length > 0) {
								//Vyfiltrujem data podla toho ci code nachadza v invoiceFilters
								let selectedData = data.sub_totals.filter((obj) => {
									return invoiceFilters.includes(obj.insurance_type.code)
								})
								selectedData.forEach((row) => {
									if (isSafe(row.sum) && row.sum > 0) {
										this.invoiceData.push({
											itemName:
												(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") +
												row.delivery_group.name_ext +
												" (" +
												row.insurance_type.name_ext +
												")",
											itemUnitPrice: "",
											itemQuantity: "",
											itemPrice: row.sum
										})
									}
									if (isSafe(row.sum_sub) && row.sum_sub > 0) {
										this.invoiceData.push({
											itemName:
												(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") +
												"Pripočítateľné položky (" +
												row.delivery_group.name_ext +
												" - " +
												row.insurance_type.name_ext +
												")",
											itemUnitPrice: "",
											itemQuantity: "",
											itemPrice: row.sum_sub
										})
									}
								})
							}
							//Ak sa index+1 zhodje s dlzkou ourgunits tak sme presli vsetky orgunits a data sa poslu do faktury inak sa index posunie a znova sa rekurzivne ziskaju data
							if (index + 1 == GlobalStore.orgunits.length) {
								InvoiceFormStore.setNewInvoiceData({items: this.invoiceData, period: periodText})
								this.close()
							} else this.generateInvoiceAndOpen(form, index + 1)
						})
				})
		} else {
			let invoiceFilters = []
			if (formVals.deliveriesChecboxes[+index * 3]) invoiceFilters.push("GH")
			if (formVals.deliveriesChecboxes[+index * 3 + 1]) invoiceFilters.push("EU")
			if (formVals.deliveriesChecboxes[+index * 3 + 2]) invoiceFilters.push("HMLS")
			api
				.loadSummaryDeliveries({
					from: this.date_from,
					to: this.date_to,
					insurer_id: this.insurer_id,
					org_unit_id: GlobalStore.orgunits[index]._id
				})
				.call()
				.then((data) => {
					if (isSafe(data.sub_totals) && data.sub_totals.length > 0) {
						let selectedData = data.sub_totals.filter((obj) => {
							return invoiceFilters.includes(obj.insurance_type.code)
						})
						selectedData.forEach((row) => {
							if (isSafe(row.sum) && row.sum > 0) {
								this.invoiceData.push({
									itemName:
										(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") +
										"Výkony (" +
										row.delivery_group.name_ext +
										(row.insurance_type._id == "GH" ? " " : ", ") +
										row.insurance_type.name_ext +
										")",
									itemUnitPrice: "",
									itemQuantity: "",
									itemPrice: row.sum
								})
							}
							if (isSafe(row.sum_sub) && row.sum_sub > 0) {
								this.invoiceData.push({
									itemName:
										(showOrgUnitName ? GlobalStore.orgunits[index].code_ext + " - " : "") +
										"Pripočítateľné položky (" +
										row.delivery_group.name_ext +
										(row.insurance_type._id == "GH" ? " " : ", ") +
										row.insurance_type.name_ext +
										")",
									itemUnitPrice: "",
									itemQuantity: "",
									itemPrice: row.sum_sub
								})
							}
						})
					}
					if (index + 1 == GlobalStore.orgunits.length) {
						InvoiceFormStore.setNewInvoiceData({items: this.invoiceData, period: periodText})
						this.close()
					} else this.generateInvoiceAndOpen(form, index + 1)
				})
		}
	}

	@action generateInvoiceOtherDeliveries(request) {
		UIStore.isFormSaving = true
		api
			.paySummary(request)
			.call()
			.then((data) => {
				const invoiceData = [
					{
						itemName: "Výkony",
						itemUnitPrice: "",
						itemQuantity: "",
						itemPrice: isNotEmpty(data.sum) ? data.sum : 0
					},
					{
						itemName: "Pripočítateľné položky",
						itemUnitPrice: "",
						itemQuantity: "",
						itemPrice: isNotEmpty(data.sum_sub) ? data.sum_sub : 0
					}
				]

				let periodText = moment(request.from).format("MM/YYYY")

				InvoiceFormStore.setNewInvoiceData({items: invoiceData, period: periodText})
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new GenerateInvoiceDialogStore()
export default singleton
