import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import api from "../../../actions/api"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import RouterStore from "../../../../../global/store/RouterStore"
import DrugReportStore from "../../../stores/DrugReportStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import ExportDrugReportDialog from "./exportDrugReportDialog"
import moment from "moment"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class DrugReportList extends React.Component {
	constructor(props) {
		super(props)
		DrugReportStore.drugList = []

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				DrugReportStore.loadDrugReport(form, props.intl.formatMessage({id: "Common.label.dateFilterRequired"}))
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					datefrom: {
						label: <FormattedMessage id="Capitation.List.From" />,
						type: "date"
					},
					dateto: {
						label: <FormattedMessage id="Capitation.List.To" />,
						type: "date"
					},
					productFilter: {
						label: <FormattedMessage id="Common.label.drugs" />
					},
					registrator: {
						label: <FormattedMessage id="Common.label.registratorName" />
					},
					orgUnit: {
						label: <FormattedMessage id="Common.label.orgunit" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
		// DrugReportStore.loadDrugReport(this.form)
		DrugReportStore.currFilters = this.form.values()
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.person_id) {
			RouterStore.push(`/patient?id=${dataRow.person_id}`)
		}
	}

	render() {
		DrugReportStore.isChangedFilter(this.form.values())
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<Grid container className="xs-drugReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsAutocomplete
								field={this.form.$("productFilter")}
								white
								minLengthForSearch="3"
								api={api.loadDrugs}
								removePunctuation={true}
								clearText
								inputRenderer={(obj, menuItem) =>
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
										: obj.code_ext) +
									" | " +
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
										: obj.name_ext) +
									" | " +
									obj.supplement +
									" | " +
									(obj.patient_payment
										? (menuItem ? "<span style=color:#01579b>" : "") +
										  obj.patient_payment +
										  (menuItem ? "</span>" : "") +
										  " | "
										: "") +
									(obj.insurer_payment
										? (menuItem ? "<span style=color:#00A787>" : "") +
										  obj.insurer_payment +
										  (menuItem ? "</span>" : "") +
										  " | "
										: "") +
									(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "") +
									obj.atc +
									" | " +
									obj.active_ingredient +
									" | " +
									obj.registrator_name
								}
								saveValue={(obj) => obj._id}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
												type: "string",
												dbName: "code_ext"
											},
											name_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
												type: "string",
												dbName: "search_column",
												filterLabel: <FormattedMessage id="Common.label.search" />,
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											},
											//Virtulany stlpec ktory sa nezobrazuje sluzi len na naviazanie filtra
											name_extFilter: {
												title: <FormattedMessage id="Delivery.list.modal.drugName" />,
												type: "string",
												dbName: "name_ext",
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												},
												design: {
													width: "0px",
													body: {
														className: "hide"
													},
													header: {
														className: "hide"
													}
												}
											},
											supplement: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
												type: "string",
												dbName: "supplement"
											},
											patient_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
												type: "string",
												dbName: "patient_payment"
											},
											insurer_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
												type: "string",
												dbName: "insurer_payment"
											},
											atc: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
												type: "string",
												dbName: "atc"
											},
											active_ingredient: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />,
												type: "string",
												dbName: "active_ingredient"
											},
											registrator_name: {
												title: <FormattedMessage id="Common.label.registratorName" />,
												type: "string",
												dbName: "registrator_name"
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											renderHeaderAsFirstRow: true,
											row_count_full: 100
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("registrator")} />
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect white field={this.form.$("orgUnit")} items={GlobalStore.orgunits} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={1}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-primary"
										text={<FormattedMessage id="Common.label.exportToExcel" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											DrugReportStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : DrugReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (DrugReportStore.drugReportListRef = table)}
							config={{
								columnDefs: {
									created_at: {
										title: <FormattedMessage id="Capitation.List.From" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									diagnose_code: {
										title: <FormattedMessage id="Common.label.diagnosesCode" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									diagnose_name: {
										title: <FormattedMessage id="Common.label.diagnose" />,
										type: "string",
										design: {
											width: "150px"
										},
										sortable: true
									},
									drug_code_ext: {
										title: <FormattedMessage id="Common.label.drugCode" />,
										type: "string",
										design: {
											width: "75px"
										},
										sortable: true
									},
									drug_name: {
										title: <FormattedMessage id="Common.label.drugName" />,
										type: "string",
										design: {
											width: "150px"
										},
										sortable: true
									},
									end_price: {
										title: <FormattedMessage id="Invoice.form.totalcost" />,
										type: "string",
										design: {
											width: "75px"
										}
									},
									max_insuree_price: {
										title: <FormattedMessage id="Common.label.patientPayment" />,
										type: "string",
										design: {
											width: "75px"
										}
									},
									max_insurer_price: {
										title: <FormattedMessage id="Common.label.insurerPayment" />,
										type: "string",
										design: {
											width: "75px"
										}
									},
									org_unit_code_ext: {
										title: <FormattedMessage id="Common.label.orgUnitCode" />,
										type: "string",
										design: {
											width: "100px"
										}
									},
									patient_identifier: {
										title: <FormattedMessage id="Patient.labels.IDNumber" />,
										type: "string",
										design: {
											width: "100px"
										}
									},
									period: {
										title: <FormattedMessage id="Common.label.period" />,
										type: "string",
										design: {
											width: "100px"
										}
									},
									provider_code: {
										title: <FormattedMessage id="Common.label.provider" />,
										type: "string",
										design: {
											width: "100px"
										}
									},
									registrator_name: {
										title: <FormattedMessage id="Common.label.registratorName" />,
										type: "string",
										design: {
											width: "100px"
										}
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: () => {
										// this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										// const lastInsurance =
										// 	isSafe(dataRow.full_patient) && dataRow.full_patient.insurances.length > 0
										// 		? dataRow.full_patient.insurances.find((i) => i.last)
										// : null
										return dataRow
									}
								},
								dataSource: DrugReportStore.drugList
							}}
						/>
					)}
				</Grid>
				<ExportDrugReportDialog form={this.form} />
			</Grid>
		)
	}
}
