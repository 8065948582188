import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./controlExaminationAdultsFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

import api from "../../../../actions/api"
import {getSelectedOrgUnitID} from "../../../../../../global/helpers/actions"
import DataStore from "../../../../stores/DataStore"
// import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import Button from "@material-ui/core/Button"

@injectIntl
@observer
export default class ControlExaminationAdultsTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}

		// predplnanie anamnezy
		if (isEmpty(this.form.$("anamnesisPersonal").value) || isEmpty(this.form.$("anamnesisWorkSocial").value)) {
			api
				.loadAnnamnesis(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
				.call()
				.then((resp) => {
					if (!(resp instanceof Response)) {
						const personalAnaHtml =
							isSafe(resp) && isSafe(resp.data.find((i) => i.type._id == "ANA"))
								? resp.data.find((i) => i.type._id == "ANA").content
								: null
						const workSocAnaHtml =
							isSafe(resp) && isSafe(resp.data.find((i) => i.type._id == "ANASOCIAL"))
								? resp.data.find((i) => i.type._id == "ANASOCIAL").content
								: null

						if (isEmpty(this.form.$("anamnesisPersonal").value) && isNotEmpty(htmlToPlain(personalAnaHtml))) {
							this.form.$("anamnesisPersonal").value = htmlToPlain(personalAnaHtml)
						}
						if (isEmpty(this.form.$("anamnesisWorkSocial").value) && isNotEmpty(htmlToPlain(workSocAnaHtml))) {
							this.form.$("anamnesisWorkSocial").value = htmlToPlain(workSocAnaHtml)
						}
					} else {
						logger("pacient nema anamnezu")
					}
				})
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={8}>
							<XsInput field={this.form.$("socInsOffice")} />
						</Grid>
						<Grid item xs={4}>
							<Button
								className="xs-primary"
								onClick={() => {
									this.form.$("socInsOffice").value = "Bratislava, Záhradnícka 31, Bratislava"
								}}
								size="medium"
								style={{height: "44px", marginTop: "10px"}}
							>
								<span>
									<React.Fragment>
										<span>Predplniť pobočku</span>
										<br />
										<span>Bratislava, Záhradnícka 31, Bratislava</span>
									</React.Fragment>
								</span>
							</Button>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={5} style={{paddingTop: "30px"}}>
							Žiadosť o dávku sociálneho poistenia, dňa
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("socInsDosageDate")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<Grid item xs={12} style={{marginTop: "20px"}}>
						<XsInput field={this.form.$("addressForm")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("employer")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("job")} />
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>Dočasne práceneschopný:</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("pnFromDate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("pnToDate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("pnReasonSickness")}
								onChange={(target) => {
									if (target.value == true) {
										// INV false = preskrtnute
										this.form.$("pnReasonInjury").value = false
										this.form.$("pnReasonWorkInjury").value = false
										this.form.$("pnReasonWorkSickness").value = false

										this.form.$("pnReasonSicknessInv").value = true
										this.form.$("pnReasonInjuryInv").value = false
										this.form.$("pnReasonWorkInjuryInv").value = false
										this.form.$("pnReasonWorkSicknessInv").value = false
									} else {
										//  ak odskrtnem vsetky, nezaskrtavam vo formulari nic
										if (
											this.form.$("pnReasonInjury").value == false &&
											this.form.$("pnReasonWorkInjury").value == false &&
											this.form.$("pnReasonWorkSickness").value == false
										) {
											this.form.$("pnReasonSicknessInv").value = true
											this.form.$("pnReasonInjuryInv").value = true
											this.form.$("pnReasonWorkInjuryInv").value = true
											this.form.$("pnReasonWorkSicknessInv").value = true
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("pnReasonInjury")}
								onChange={(target) => {
									if (target.value == true) {
										this.form.$("pnReasonSickness").value = false
										this.form.$("pnReasonWorkInjury").value = false
										this.form.$("pnReasonWorkSickness").value = false

										this.form.$("pnReasonSicknessInv").value = false
										this.form.$("pnReasonInjuryInv").value = true
										this.form.$("pnReasonWorkInjuryInv").value = false
										this.form.$("pnReasonWorkSicknessInv").value = false
									} else {
										if (
											this.form.$("pnReasonSickness").value == false &&
											this.form.$("pnReasonWorkInjury").value == false &&
											this.form.$("pnReasonWorkSickness").value == false
										) {
											this.form.$("pnReasonSicknessInv").value = true
											this.form.$("pnReasonInjuryInv").value = true
											this.form.$("pnReasonWorkInjuryInv").value = true
											this.form.$("pnReasonWorkSicknessInv").value = true
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("pnReasonWorkInjury")}
								onChange={(target) => {
									if (target.value == true) {
										this.form.$("pnReasonInjury").value = false
										this.form.$("pnReasonSickness").value = false
										this.form.$("pnReasonWorkSickness").value = false

										this.form.$("pnReasonSicknessInv").value = false
										this.form.$("pnReasonInjuryInv").value = false
										this.form.$("pnReasonWorkInjuryInv").value = true
										this.form.$("pnReasonWorkSicknessInv").value = false
									} else {
										if (
											this.form.$("pnReasonInjury").value == false &&
											this.form.$("pnReasonSickness").value == false &&
											this.form.$("pnReasonWorkSickness").value == false
										) {
											this.form.$("pnReasonSicknessInv").value = true
											this.form.$("pnReasonInjuryInv").value = true
											this.form.$("pnReasonWorkInjuryInv").value = true
											this.form.$("pnReasonWorkSicknessInv").value = true
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("pnReasonWorkSickness")}
								onChange={(target) => {
									if (target.value == true) {
										this.form.$("pnReasonInjury").value = false
										this.form.$("pnReasonWorkInjury").value = false
										this.form.$("pnReasonSickness").value = false

										this.form.$("pnReasonSicknessInv").value = false
										this.form.$("pnReasonInjuryInv").value = false
										this.form.$("pnReasonWorkInjuryInv").value = false
										this.form.$("pnReasonWorkSicknessInv").value = true
									} else {
										if (
											this.form.$("pnReasonInjury").value == false &&
											this.form.$("pnReasonWorkInjury").value == false &&
											this.form.$("pnReasonSickness").value == false
										) {
											this.form.$("pnReasonSicknessInv").value = true
											this.form.$("pnReasonInjuryInv").value = true
											this.form.$("pnReasonWorkInjuryInv").value = true
											this.form.$("pnReasonWorkSicknessInv").value = true
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} style={{marginTop: "10px"}}>
						<XsInput field={this.form.$("dosageType")} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold", fontSize: "17px"}}>
						Lekársky nález
					</div>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>A. Anamnéza</div>

					<Grid item xs={12}>
						<XsInput field={this.form.$("anamnesisWorkSocial")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamnesisFamily")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamnesisPersonal")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("currentHealth")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("currentTherapy")} multiple rows={3} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>
						B. Výsledky lekárskych nálezov
					</div>

					<Grid item xs={12}>
						<XsInput field={this.form.$("objectiveFound")} multiple rows={3} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("height")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("weight")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("bp")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("pulse")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>B. (pokračovanie)</div>

					<Grid item xs={12}>
						<XsInput field={this.form.$("funcExamResults")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("disabilityDiagnosis")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("otherDiagnosis")} multiple rows={3} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
