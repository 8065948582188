"use strict"

import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {toJS} from "mobx"

// special import for Material-UI binding
import bindings from "../globalUISchemeBindings"
import {FormattedMessage} from "react-intl"

// import UI controls here
import XsButton from "../xsButton/xsButton"
import XsIconButton from "../xsButton/xsIconButton"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Modal from "@material-ui/core/Modal"

import GlobalStore from "../../store/GlobalStore"

import CancelIcon from "@material-ui/icons/Clear"
import UIStore from "../../../modules/ambulance/stores/UIStore"
import AddIcon from "@material-ui/icons/Add"
import DoneIcon from "@material-ui/icons/Done"
import {observable} from "mobx"

// import PatientNewTagTypeDialog from "../../../modules/ambulance/components/patientDetail/patientHeader/patientNewTagTypeDialog"
// import "../../../modules/ambulance/components/patientDetail/patientHeader/patientNewTagTypeDialog.less"
import {ixsIsObject} from "../../helpers/functions"
import {getFieldIdentifier} from "../../helpers/actions"

const initFormData = (configuration) => {
	let {data} = configuration
	const hooks = {
		onSuccess() {
			//todo otestovat validaciu
			// form.$("_visibleRows").set("value",false)
			// if (isSafe(configuration) && isSafe(configuration.options) && isSafe(configuration.options.onFormSave)
			//   && typeof configuration.options.onFormSave === "function") {
			//   configuration.options.onFormSave(form.$("rows").values())
			// }
		},
		onSubmit(form) {
			form.$("_visibleRows").set("value", false)
			if (
				isSafe(configuration) &&
				isSafe(configuration.options) &&
				isSafe(configuration.options.onFormSave) &&
				typeof configuration.options.onFormSave === "function"
			) {
				configuration.options.onFormSave(form.$("rows").values())
			}
		}
	}

	let fields = []
	let labels = {}
	let types = {}
	let rules = {}
	if (isSafe(configuration) && isSafe(configuration.columns)) {
		fields.push("_visibleRows")
		fields.push("rows")

		configuration.columns.forEach((col) => {
			fields.push(`rows[].${col.name}`)
			labels[`rows[].${col.name}`] = col.label
			rules[`rows[].${col.name}`] = col.validationRule
			types[`rows[].${col.name}`] = col.type
		})
	}

	//V pripade ze niesu data takdo rows treba vlozit prazdny objekt nie prazdny []
	//[mobx.array] Attempt to read an array index (NaN) that is out of bounds (0). Please check length first. Out of bound indices will not be tracked by MobX
	if ((Array.isArray(data) && data.length == 0) || isNotSafe(data)) {
		data = {}
	}
	// V pripade ze data su [] treba ich transformovat na objekt
	//mobx.module.js:3002 [mobx.array] Attempt to read an array index (NaN) that is out of bounds (1). Please check length first. Out of bound indices will not be tracked by MobX
	else if (Array.isArray(toJS(data))) {
		data = Object.assign({}, data)
	}
	const values = Object.assign(
		{},
		{rows: data},
		{_visibleRows: isSafe(configuration) && isSafe(configuration.options) && configuration.options.isFormMode}
	)
	return new MobxReactForm({fields, values, labels, rules, types}, {plugins: {dvr: validatorjs}, hooks, bindings})
}

@observer
export default class xsChipForm extends React.Component {
	constructor(props) {
		super(props)
		if (isSafe(props.configuration)) {
			this.form = initFormData(props.configuration)
			this.form.$("rows").observe(() => {
				if (isSafe(this.props.field)) {
					this.props.field.value = JSON.stringify(this.form.$("rows").value)
				}
			})
			//Prečo je tu tuto? Nefunguje vôbec isFormMode, zakomentujem.
			//this.form.$("_visibleRows").value = false
		}

		this.state = {
			changed: false
		}

		this.focusedRowKey
		this.focusRef

		if (isSafe(props.field)) {
			if (props.field.value != JSON.stringify(this.form.$("rows").value)) {
				this.bindData(JSON.parse(props.field.value))
			}
		}
	}

	isChanged() {
		return this.state.changed
	}

	setChanged(value = true) {
		if (this.state.changed != value) {
			this.setState({changed: value})
		}
	}

	@observable openNewTagTypeDialog = false
	@observable openDeleteTagTypeDialog = false

	bindData(data) {
		if (isSafe(data)) {
			this.form.update({rows: data})
			this.setChanged(false)
		}
	}

	getData() {
		const skuska = {}
		const formObj = this.form.$("rows").get()
		Object.keys(formObj).forEach((field) => {
			if (ixsIsObject(formObj[field].value)) {
				skuska[field] = deepCopy(formObj[field].value)
			} else {
				skuska[field] = formObj[field].value
			}
		})
		return skuska
	}

	// mobxForm property
	form

	handleClick(addRow = false, event, focusKey) {
		if (this.props.configuration.options.showLoadingOnOpening) {
			UIStore.isFormSaving = true
		}
		this.focusedRowKey = focusKey
		this.modalPositionX = event.pageX
		this.modalPositionY = event.pageY
		const {configuration} = this.props

		setTimeout(() => {
			if (
				isSafe(configuration) &&
				isSafe(configuration.options) &&
				isSafe(configuration.options.onFormOpen) &&
				typeof configuration.options.onFormOpen === "function"
			) {
				configuration.options.onFormOpen(this.form.$("rows"), this.form)
			}

			this.openData = isSafe(this.props.field) ? JSON.parse(this.props.field.value) : this.form.$("rows").get("value")

			if (addRow) {
				let defaultValues = {}
				configuration.columns.map((col) => {
					const defVal =
						isSafe(col.defaultValue) && typeof col.defaultValue === "function"
							? col.defaultValue(col, this.form.$("rows").value.length)
							: col.defaultValue
					defaultValues[col.name] = defVal
				})
				this.form.$("rows").add({fields: defaultValues})
				this.handleFocus()
			}
			this.form.$("_visibleRows").value = true

			if (this.props.configuration.options.showLoadingOnOpening) {
				UIStore.isFormSaving = false
			}

			// set focus pre jednoduche polia ktore netreba hadovat specialne
			let focusFieldName = configuration.columns[0].name
			const defaultFocusField = configuration.columns.find((col) => col.defaultFocus)
			if (isSafe(defaultFocusField)) {
				focusFieldName = defaultFocusField.name
			}
			isSafe(focusKey)
				? this.form.$(`rows[${focusKey}].${focusFieldName}`).focus()
				: this.form.$(`rows[${parseInt(this.form.$("rows").size) - 1}].${focusFieldName}`).focus()
		}, 0)
	}

	//Volat v po loade vo xsAutocomplete vo forme pre znovunastavenie focusu
	handleFocus = () => {
		const {configuration} = this.props
		let focusFieldName = configuration.columns[0].name
		const defaultFocusField = configuration.columns.find((col) => col.defaultFocus)
		if (isSafe(defaultFocusField)) {
			focusFieldName = defaultFocusField.name
		}
		//focus na kliknuty chip
		if (isSafe(this.focusedRowKey)) {
			if (isSafe(this.focusRef)) {
				this.focusRef.focus()
			}
		} else {
			//focus na novy chip
			this.form.$(`rows[${parseInt(this.form.$("rows").size) - 1}].${focusFieldName}`).focus()
			if (isSafe(this.focusRef)) {
				this.focusRef.focus()
			}
		}
	}

	//ziska ref pre funkciu handleFocus()
	handleRef = (ref, index) => {
		if (index == this.focusedRowKey) {
			this.focusRef = ref
		}
		if (index == this.form.$("rows").size - 1) {
			this.focusRef = ref
		}
	}

	handleOnChange = (idx) => {
		this.setChanged()
		return this.form.$(`rows[${idx}]`)
	}

	returnFormData = (idx) => {
		return this.form.$(`rows[${idx}]`)
	}

	componentDidMount() {
		if (isSafe(this.props.onMount) && typeof this.props.onMount === "function") this.props.onMount(this)
		if (isSafe(this.props.field)) {
			if (this.props.field.value != JSON.stringify(this.form.$("rows").value)) {
				this.bindData(JSON.parse(this.props.field.value))
			}
		}
	}

	getModalStyle = () => {
		const showInCenter = this.props.configuration.options.showInCenter
		const maxWidth = this.props.configuration.options.maxWidth
		let style = {
			position: "absolute",
			left: showInCenter ? "0" : this.modalPositionX != 0 ? `${this.modalPositionX - 20}px` : "130px",
			top: showInCenter ? "40%" : this.modalPositionY != 0 ? `${this.modalPositionY - 20}px` : "380px",
			overflow: showInCenter ? "auto" : "",
			maxWidth: maxWidth ? "" : "960px", //TODO: dorobit vyratavat sirku
			margin: showInCenter ? (maxWidth ? "3.33333%" : "auto") : ""
		}
		if (showInCenter && maxWidth) {
			delete style.position
			delete style.left
			delete style.top
		}
		return style
	}

	onNewTagTypeButtonClick(newTagTypeDialog, fields) {
		if (newTagTypeDialog === "PatientNewTagTypeDialog") {
			this.openNewTagTypeDialog = true
		}

		this.fieldsForNewTagType = fields
	}

	onNewTagTypeDialogClose() {
		this.openNewTagTypeDialog = false
	}

	onNewTagTypeAdded = (id) => {
		this.setState({state: this.state})

		this.fieldsForNewTagType.each((field) => {
			if (field.name === "tagtype") {
				field.set("value", id)
			}
		})

		GlobalStore.setNotificationMessage("Patient.Header.Tags.NewTagType.Success")
	}

	onDeleteTagTypeButtonClick(deleteTagTypeDialog) {
		if (deleteTagTypeDialog === "PatientDeleteTagTypeDialog") {
			this.openDeleteTagTypeDialog = true
		}
	}

	onDeleteTagTypeDialogClose() {
		this.openDeleteTagTypeDialog = false
	}

	reloadFromData = () => {
		this.bindData(this.props.configuration.data || [])
	}

	componentDidUpdate() {
		if (isSafe(this.props.field)) {
			if (this.props.field.value != JSON.stringify(this.form.$("rows").value)) {
				this.bindData(JSON.parse(this.props.field.value))
			}
		}
	}

	showForm = () => {
		if (isSafe(this.form.$("rows").value) && this.form.$("rows").value.length) {
			const {configuration} = this.props
			let focusFieldName = configuration.columns[0].name
			const defaultFocusField = configuration.columns.find((col) => col.defaultFocus)
			if (isSafe(defaultFocusField)) {
				focusFieldName = defaultFocusField.name
			}
			if (isSafe(this.form.$(`rows[0].${focusFieldName}`))) {
				this.form.$(`rows[0].${focusFieldName}`).focus()
			}
		}

		this.form.$("_visibleRows").value = true
	}

	render() {
		const {configuration} = this.props
		let onRemoveRow = null

		const isDisabled =
			isSafe(configuration) &&
			isSafe(configuration.options) &&
			(isNotSafe(configuration.options.disabled) || !configuration.options.disabled)

		const hideButtonsOnly =
			isSafe(configuration) && isSafe(configuration.options) && isSafe(configuration.options.hideButtons)
				? configuration.options.hideButtons
				: false

		const showCancelButton =
			isSafe(configuration) && isSafe(configuration.options) && isSafe(configuration.options.showCancelButton)
				? configuration.options.showCancelButton
				: false
		let headerAddButtonJSX = (
			<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={(e) => this.handleClick(true, e)} />
		)

		if (
			isSafe(configuration) &&
			isSafe(configuration.options) &&
			isSafe(configuration.options.onRemoveRow) &&
			typeof configuration.options.onRemoveRow == "function"
		) {
			onRemoveRow = configuration.options.onRemoveRow
		}

		if (
			isSafe(configuration) &&
			isSafe(configuration.header) &&
			isSafe(configuration.header.addButtonRenderer) &&
			typeof configuration.header.addButtonRenderer == "function"
		) {
			if (isSafe(configuration.header.chipWidth) && isFinite(configuration.header.chipWidth)) {
				if (isSafe(configuration.header.headerWidth) && isFinite(configuration.header.headerWidth)) {
					const chipWidth = configuration.header.chipWidth + 10
					const headerWidth = configuration.header.headerWidth - 100

					let chipCount = Math.floor(headerWidth / (chipWidth + 10))
					let all = this.form.$("rows").value.length

					let count = ""

					if (all > chipCount) {
						count = all - chipCount
					}

					headerAddButtonJSX = configuration.header.addButtonRenderer(
						((e) => this.handleClick(true, e)).bind(this),
						`+${count}`
					)
				} else {
					headerAddButtonJSX = null
				}
			} else {
				headerAddButtonJSX = configuration.header.addButtonRenderer(((e) => this.handleClick(true, e)).bind(this))
			}
		}

		const content = (
			<Paper
				elevation={configuration.options.isModalMode !== false ? 2 : 0}
				style={{padding: configuration.options.isModalMode !== false ? "15px" : "0px"}}
			>
				<form>
					{this.form.$("_visibleRows").value &&
						this.form.$("rows").map((fields, index) => {
							let totalWidth = 0
							let cols = []
							configuration.columns.forEach((columnDef, colIdx) => {
								if (isSafe(columnDef["name"]) && fields.has(columnDef["name"])) {
									const field = fields.$(columnDef["name"])
									const colWidth =
										typeof columnDef.width === "function"
											? columnDef.width(field, colIdx, index, fields)
											: columnDef.width
									totalWidth += colWidth

									if (isSafe(columnDef.newTagTypeDialog) || isSafe(columnDef.deleteTagTypeDialog)) {
										if (isSafe(columnDef.newTagTypeDialog)) {
											cols.push(
												<Grid key={colIdx} item xs={colWidth}>
													<XsButton
														id="xsPatientTagFormNewTagTypeButton"
														className="xs-success"
														text={<FormattedMessage id="Patient.Header.Tags.NewTagType" />}
														onClick={() => this.onNewTagTypeButtonClick(columnDef.newTagTypeDialog, fields)}
													/>
												</Grid>
											)
										}

										if (isSafe(columnDef.deleteTagTypeDialog)) {
											cols.push(
												<Grid key={colIdx} item xs={colWidth}>
													<XsButton
														id="xsPatientTagFormNewTagTypeButton"
														className="xs-success"
														text={<FormattedMessage id="Common.label.delete" />}
														onClick={() => this.onDeleteTagTypeButtonClick(columnDef.deleteTagTypeDialog, fields)}
													/>
												</Grid>
											)
										}
									} else if (
										isSafe(columnDef.renderer(field, index, fields.key, this.handleOnChange, this.returnFormData))
									) {
										cols.push(
											<Grid key={colIdx} item xs={colWidth}>
												{columnDef.renderer(
													field,
													index,
													fields.key,
													this.handleOnChange,
													this.returnFormData,
													fields,
													this.handleRef
												)}
											</Grid>
										)
									}
								}
							})
							return (
								<Grid key={index} container direction="row" alignItems="flex-end" spacing={8}>
									{cols}
									<Grid item xs={12 - totalWidth} className="xs-chip-buttons">
										{isDisabled && index + 1 === this.form.$("rows").value.length && (
											<XsIconButton
												className="xs-success xs-outline"
												icon={<AddIcon />}
												tabIndex={
													isSafe(this.props.configuration.options.plusButtonTabindex)
														? this.props.configuration.options.plusButtonTabindex(index)
														: ""
												}
												onClick={() => {
													let defaultValues = {}
													this.setChanged()
													configuration.columns.map((col) => {
														const defVal =
															isSafe(col.defaultValue) && typeof col.defaultValue === "function"
																? col.defaultValue(col, this.form.$("rows").value.length)
																: col.defaultValue
														defaultValues[col.name] = defVal
													})
													this.form.$("rows").add({fields: defaultValues})
													this.handleFocus()
												}}
											/>
										)}
										{isDisabled && !(configuration.options.disableFirstRowDelete && index == 0) && (
											<XsIconButton
												className="xs-danger xs-outline"
												icon={<CancelIcon />}
												onClick={() => {
													this.form.$("rows").del(fields.key)
													this.setChanged()
													if (onRemoveRow) {
														onRemoveRow(index, fields.key)
													}
												}}
											/>
										)}
									</Grid>
									{isSafe(configuration.extraRow) ? (
										<Grid item xs={12} className="xs-chip-extra-row">
											{configuration.extraRow.renderer(index, fields.key, this.handleOnChange)}
										</Grid>
									) : null}
								</Grid>
							)
						})}
					<Grid container direction="row">
						<Grid item xs={12}>
							{showCancelButton && (
								<XsButton
									className="xs-danger xs-outline"
									icon={<CancelIcon />}
									text={<FormattedMessage id="Common.form.close" />}
									onClick={() => {
										const values = Object.assign({}, {rows: this.form.$("rows").value}, {_visibleRows: true})
										let lastKey = Object.keys(values.rows).pop()
										if (isSafe(lastKey)) {
											let lastRow = values.rows[lastKey]
											if (isSafe(lastRow)) {
												if (Object.keys(lastRow).every((col) => isEmpty(lastRow[col]))) {
													this.form.$("rows").del(lastKey)
												}
											}
										}
										this.form.$("_visibleRows").set("value", false)
									}}
								/>
							)}
							{isDisabled && !hideButtonsOnly && this.form.$("_visibleRows").value && (
								<XsButton
									className="xs-danger xs-outline action-chipform-cancel"
									icon={<CancelIcon />}
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										const values = Object.assign({}, {rows: this.openData}, {_visibleRows: true})
										this.form.update(values)
										if (
											isSafe(configuration) &&
											isSafe(configuration.options) &&
											isSafe(configuration.options.onFormClose) &&
											typeof configuration.options.onFormClose === "function"
										) {
											configuration.options.onFormClose()
										}
										this.form.$("_visibleRows").set("value", false)
									}}
								/>
							)}
							{isDisabled && !hideButtonsOnly && this.form.$("_visibleRows").value && (
								<XsButton
									className="xs-success action-chipform-confirm"
									text={<FormattedMessage id="Common.label.ok" />}
									icon={<DoneIcon />}
									type="submit"
									onClick={(e) => {
										this.form.validate().then(({isValid}) => {
											if (isValid) {
												if (
													isSafe(configuration.options.onSubmit) &&
													typeof configuration.options.onSubmit === "function"
												) {
													configuration.options.onSubmit(() => this.form.onSubmit(e))
												} else {
													this.form.onSubmit(e)
												}
											} else {
												GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
											}
										})
									}}
								/>
							)}
						</Grid>
					</Grid>
				</form>
			</Paper>
		)

		let chipFormClassName = ""
		if (isSafe(this.props.field)) {
			chipFormClassName = getFieldIdentifier(this.props.field)
		}

		return (
			<div className={chipFormClassName}>
				<Grid container direction="row">
					<div className={"xs-input-label"} dangerouslySetInnerHTML={{__html: this.props.label}} />
				</Grid>
				<Grid container direction="row">
					<Grid item xs={12} className="xs-row xs-align xs-wrap">
						{this.form.$("rows").map((fields, idx) => {
							if (
								isSafe(configuration) &&
								isSafe(configuration.header) &&
								isSafe(configuration.header.chipsRenderer) &&
								typeof configuration.header.chipsRenderer == "function"
							) {
								if (isSafe(configuration.header.chipWidth) && isFinite(configuration.header.chipWidth)) {
									if (isSafe(configuration.header.headerWidth) && isFinite(configuration.header.headerWidth)) {
										const chipWidth = configuration.header.chipWidth
										const headerWidth = configuration.header.headerWidth
										let chipCount = Math.floor(headerWidth / (chipWidth + 20))
										if (idx < chipCount) {
											return configuration.header.chipsRenderer(
												idx,
												fields.get(),
												this.handleClick.bind(this),
												chipWidth
											)
										}
									} else {
										return null
									}
								} else {
									return configuration.header.chipsRenderer(idx, fields.get(), this.handleClick.bind(this))
								}
							} else {
								return (
									<span onClick={(e) => this.handleClick(false, e)} key={idx}>
										{fields[0].get("value")}&nbsp;&nbsp;&nbsp;
									</span>
								)
							}
						})}
						{isDisabled && headerAddButtonJSX}
					</Grid>
				</Grid>
				{configuration.options.isModalMode !== false ? (
					<Modal
						open={this.form.$("_visibleRows").value}
						onClose={this.form.onSubmit}
						style={this.getModalStyle()}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
					>
						{content}
					</Modal>
				) : (
					this.form.$("_visibleRows").value && content
				)}

				{/* <PatientNewTagTypeDialog
					isOpen={this.openNewTagTypeDialog}
					onClose={() => this.onNewTagTypeDialogClose()}
					onNewTagTypeAdded={(id) => this.onNewTagTypeAdded(id)}
					modalTitle={<FormattedMessage id="Patient.Header.Tags.NewTagTypeDialog.Title" />}
				/> */}
			</div>
		)
	}
}
