"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import GlobalStore from "../../../global/store/GlobalStore"
import api from "../actions/api"
import settings from "../../../global/config/settings"
import AgreementsStore from "./AgreementsStore"
import AgreementAttributeStore from "./AgreementAttributeStore"

import UIStore from "../stores/UIStore"

class AgreementPartCopyStore {
	@observable isOpen = false

	agreementPartCopyFormRef = null

	@observable idPart = ""

	part = {}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action copyPart(form) {
		if (isSafe(this.part) && isNotEmpty(this.part._id)) {
			UIStore.isFormSaving = true
			api
				.loadPart(this.part._id)
				.call()
				.then((response) => {
					let oldAttr = []
					let oldPart = response

					if (isSafe(response) && isSafe(response.attributes) && response.attributes.length > 0) {
						response.attributes.forEach((attr) => {
							if (isSafe(attr.validity) && isEmpty(attr.validity.to)) {
								attr.validity.to = moment(form.$("from").value)
									.add(-1, "day")
									.format(settings.DB_DATE_FORMAT)
							}
							oldAttr.push(attr)
						})

						oldPart.attributes = oldAttr

						if (isSafe(response.validity) && isEmpty(response.validity.to)) {
							oldPart.validity.to = moment(form.$("from").value)
								.add(-1, "day")
								.format(settings.DB_DATE_FORMAT)
						}
					}

					if (form.$("end_part").value) {
						api
							.savePart(oldPart)
							.call()
							.then(() => {
								this.createCopy(response, form)
							})
					} else {
						this.createCopy(response, form)
					}
				})
		}
	}

	@action createCopy(response, form) {
		let newAttr = []
		let newPart = response

		if (isSafe(response)) {
			if (isSafe(response.attributes) && response.attributes.length > 0) {
				response.attributes.forEach((attr) => {
					if (isSafe(attr.validity)) {
						attr._id = ""
						attr.validity.to = ""
						attr.validity.from = moment(form.$("from").value).format(settings.DB_DATE_FORMAT)
					}
					newAttr.push(attr)
				})

				newPart.attributes = newAttr
				newPart._id = ""

				if (isSafe(response.validity)) {
					newPart.validity.to = ""
					newPart.validity.from = moment(form.$("from").value).format(settings.DB_DATE_FORMAT)
				}
			}

			if (isSafe(response.specific_rels) && response.specific_rels.length > 0) {
				response.specific_rels.forEach((specRels) => {
					specRels._id = ""
				})
			}

			if (isSafe(newPart.attributes) && newPart.attributes.length > 0) {
				api
					.savePart(newPart)
					.call()
					.then((respon) => {
						if (isSafe(respon) && isSafe(respon._id)) {
							this.close()
							UIStore.isFormSaving = false
							GlobalStore.setNotificationMessage("Agreement.CopyPart.Copy.Notification")
							AgreementsStore.loadParts(AgreementAttributeStore.contractId)
						}
					})
			} else {
				GlobalStore.setNotificationMessage("Common.label.partNotHaveAttributes")
				UIStore.isFormSaving = false
				this.close()
			}
		} else {
			UIStore.isFormSaving = false
		}
	}

	@action open(part) {
		this.agreementPartCopyFormRef.$("end_part").value = false

		if (isSafe(part) && isSafe(part.validity) && isNotEmpty(part.validity.to)) {
			this.agreementPartCopyFormRef.$("from").value = moment(part.validity.to).add(1, "day")
		} else {
			this.agreementPartCopyFormRef.$("from").value = moment()
				.add(1, "month")
				.startOf("month")
		}
		this.part = part
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new AgreementPartCopyStore()
export default singleton
