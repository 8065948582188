"use strict"

import {action} from "mobx"
import api from "../actions/api"
import ExportToXlsDialogStore from "./ExportToXlsDialogStore"
import UIStore from "./UIStore"

class ExportDeliveriesDataStore {
	@action export(doctorExpertiseId, insurerId, provider_id, date, page, rowsPerPage, type, batchType, disposition) {
		let filters = [
			{
				associated_column: "doctor_expertise_id",
				values: [
					{
						id_value: doctorExpertiseId
					}
				]
			},
			{
				associated_column: "provider_id",
				values: [
					{
						id_value: provider_id
					}
				]
			},
			{
				associated_column: "disposition",
				values: [
					{
						id_value: isNotEmpty(disposition) ? disposition : "N"
					}
				]
			},
			{
				associated_column: "date",
				values: [
					{
						id_value: date
					}
				]
			}
		]

		if (isSafe(insurerId)) {
			filters.push({
				associated_column: "insurer_id",
				values: [
					{
						id_value: insurerId
					}
				]
			})
		}

		let req = {
			uri: `/amb/batch/list/SK${isNotEmpty(batchType) ? batchType : "753a"}`,
			filters: filters
		}

		if (type == "now") {
			req.row_offset = +page * +rowsPerPage + 1
			req.row_count_show = +rowsPerPage
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
		}

		api
			.exporDeliveriesData(req)
			.call()
			.then((response) => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
			.catch(() => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new ExportDeliveriesDataStore()
export default singleton
