import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {/*Grid,*/ Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import RecordRePrintDialogStore from "../../../stores/RecordRePrintDialogStore"
import Reprint from "../../../../../global/ui/xsReprint/xsReprint"
import ambulanceApi from "../../../../ambulance/actions/api"
import {printMPDF} from "../../../../../global/helpers/actions"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class RecordRePrintDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showContent: false
		}
	}

	render() {
		const cfg = RecordRePrintDialogStore.config

		return (
			<React.Fragment>
				<Dialog
					id="xsRecordRePrintDialog"
					open={RecordRePrintDialogStore.isOpen}
					onClose={() => {
						this.setState({showContent: false})
						RecordRePrintDialogStore.close()
					}}
					className="xs-base-dialog dialog-patientbloodtype"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-450"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => {
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
								}}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-print fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.lable.recordPrint" />
							</div>
							<div className="xs-header-subtitle">
								{/* <FormattedMessage id="Patient.sidebar.bloodType.subtitle" /> */}
							</div>
						</div>
					</DialogTitle>
					{this.state.showContent && (
						<DialogContent className="xs-overflow-visible">
							<div>
								<Reprint
									paper={{width: 210, height: 297, margins: {left: 10, top: 10}}}
									maxPrintHeight={250}
									suggestPosition={cfg.requestData["_paddingTop"]}
									width={500}
									height={400}
									onChange={(val) => (cfg.requestData["_paddingTop"] = val)}
								/>
							</div>
						</DialogContent>
					)}
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-success mr-3"
							text={<FormattedMessage id="Common.lable.recordRePrint" />}
							onClick={() => {
								if (!this.state.showContent) {
									cfg.printIdentifier["template"] += "_plain"

									ambulanceApi
										.getPrintPosition(cfg.printIdentifier)
										.call()
										.then((data) => {
											cfg.requestData["_reprint"] = "T"
											//cfg.requestData["_withHeader"] = isNotSafe((data || {}).print_position_approved) ? "T" : "F"
											cfg.requestData["_paddingTop"] = (data || {}).print_position_approved || 0
											cfg.requestData["_template"] += "_plain"

											this.setState({showContent: true})
										})
								} else {
									printMPDF(cfg.streamCallback, cfg.requestData)
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
									GlobalStore.openConfirmationDialog("reprintSuccessConfirmation")
								}
							}}
						/>
						{!this.state.showContent && (
							<XsButton
								className="xs-success mr-3"
								text={<FormattedMessage id="Common.lable.print" />}
								onClick={() => {
									printMPDF(cfg.streamCallback, cfg.requestData)
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
								}}
							/>
						)}
					</DialogActions>
				</Dialog>
				<XsConfirmationDialog
					name={"reprintSuccessConfirmation"}
					text={<FormattedMessage id={"Common.lable.printSuccQ"} />}
					onConfirmation={() => ambulanceApi.aprovePrintPosition(cfg.printIdentifier).call()}
				/>
			</React.Fragment>
		)
	}
}
