import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import Tooltip from "@material-ui/core/Tooltip"
import classnames from "classnames"

import EditEventDialogStore from "../../stores/EditEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
// import DataStore from "../../stores/DataStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import {FormattedMessage, injectIntl} from "react-intl"
import {isInThePast} from "../../../../global/ui/xsDateTimePicker/helpers"

@injectIntl
@observer
export default class DailyView extends React.Component {
	constructor(props) {
		super(props)

		CalendarStore.dailyViewRef = this
	}

	getHourLabelClass = (hour) => {
		return moment()
			.local()
			.get("hours") == hour
			? "xs-daily-hour xs-hour-active"
			: "xs-daily-hour"
	}

	componentDidUpdate() {
		// const minutes = moment().local().get("minutes");
		// const percentage = (minutes / 60) * 100;
		// const timeline = Math.round(percentage);
		// this.setPos()
	}

	componentDidMount() {
		this.refreshInterval = setInterval(() => {
			if (!AddEventDialogStore.isOpen && moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
				CalendarStore.setCalendarRange("today")
			}
		}, 10000)
		this.scrollInterval = setInterval(() => {
			if (!AddEventDialogStore.isOpen && moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
				this.setPos()
			}
		}, 1800000)
	}

	componentWillUnmount() {
		clearInterval(this.refreshInterval)
		clearInterval(this.scrollInterval)
	}

	setPos = () => {
		// const progressb = this.timeline
		if (isSafe(this.inRow) && !CalendarStore.calendarToggledFull) {
			let scrollY = this.inRow <= 3 ? 0 : this.inRow * 55 - 175

			if (isSafe(this.dailyViewRef) && scrollY > 0) {
				this.dailyViewRef.scrollTop = scrollY
			}
		}
		return 0
	}

	openAddEventDialog = (hourFrom, hourTo) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (CalendarStore.selectedDay.isBefore(hourFrom)) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hourFrom.hours())
					.set("minute", hourFrom.minutes())
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hourFrom.hours())
					.set("minute", hourFrom.minutes())
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
			} else if (CalendarStore.selectedDay.isAfter(hourTo)) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hourTo.hours())
					.set("minute", hourTo.minutes())
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hourTo.hours())
					.set("minute", hourTo.minutes())
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
			} else {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay).set(
					"minute",
					CalendarStore.getRoundMinutes(moment(CalendarStore.selectedDay))
				)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("minute", CalendarStore.getRoundMinutes(moment(CalendarStore.selectedDay)))
					.format("DD.MM.YYYY HH:mm")
			}

			AddEventDialogStore.open()
		}
	}

	getEventClass = (originalTime, duration) => {
		const time = JSON.parse(JSON.stringify(originalTime))
		if (
			moment()
				.local()
				.get("hours") ===
			moment(time)
				.local()
				.get("hours")
		) {
			if (
				moment(time)
					.local()
					.get("minutes") <=
					moment()
						.local()
						.get("minutes") &&
				moment()
					.local()
					.get("minutes") <=
					moment(time)
						.local()
						.get("minutes") +
						duration / 60
			) {
				return true
			}
		}

		return false
	}

	getEventStatus = (isEventEnd, clientId, order) => {
		if (isEventEnd) {
			return (
				<div className="xs-state-icon">
					{/* <i className="fas fa-user-check fa-lg greenSuccess" data-tip data-for="eventEnd" />
					<ReactTooltip id="eventEnd">
						<FormattedMessage id="Common.label.eventEnd" />
					</ReactTooltip> */}
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.eventEnd"
						})}
						placement="left"
					>
						<i className="fas fa-user-check fa-lg greenSuccess" />
					</Tooltip>
				</div>
			)
		}

		if (moment(order.time).isBefore(moment())) {
			if (CalendarStore.patientDraftIds.includes(clientId)) {
				return (
					<div className="xs-state-icon">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.inProgress"
							})}
							placement="left"
						>
							<i className="fas fa-user-cog fa-lg amberWarning" />
						</Tooltip>
						{/* <i className="fas fa-user-cog fa-lg amberWarning" data-tip data-for="inProgress" />
						<ReactTooltip id="inProgress">
							<FormattedMessage id="Common.label.inProgress" />
						</ReactTooltip> */}
					</div>
				)
			} else {
				return (
					<div className="xs-state-icon">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.notEventEnd"
							})}
							placement="left"
						>
							<i className="fas fa-user fa-lg greyDefault" />
						</Tooltip>
						{/* <i className="fas fa-user fa-lg greyDefault" data-tip data-for="notEventEnd" />
						<ReactTooltip id="notEventEnd">
							<FormattedMessage id="Common.label.notEventEnd" />
						</ReactTooltip> */}
					</div>
				)
			}
		}
	}

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		return (
			<div className="xs-daily-view">
				<div className="xs-orders-scrollbar">
					<div
						className={classnames("xs-orders", {
							"pb-3": CalendarStore.eventsData.length <= 5,
							"xs-fix": !CalendarStore.calendarToggledFull && CalendarStore.eventsData.length > 5
						})}
						ref={(div) => (this.dailyViewRef = div)}
					>
						{isSafe(CalendarStore.eventsData) &&
							CalendarStore.eventsData.length > 0 &&
							CalendarStore.eventsData.map((order, i) => {
								if (isSafe(order) && isSafe(order.event)) {
									const {client, status, event_note_text, services} = order.event

									const service = isSafe(services) && services.length > 0 && isSafe(services[0]) ? services[0] : null
									let bgColor = isSafe(service) && isNotEmpty(service.back_color) ? service.back_color : "#808080"
									let fontColor = isSafe(service) && isNotEmpty(service.fore_color) ? service.fore_color : undefined
									let isEventEnd = isSafe(status) && status._id === "F" ? true : false
									let actualEvent = isSafe(service) ? this.getEventClass(order.time, service.duration) : false
									let inThePast = isSafe(service) ? isInThePast(order.time, service.duration) : false
									let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd, inThePast, true)

									let lastName = isNotEmpty(client.last_name) ? client.last_name.toUpperCase() : ""

									let eventNote = isNotEmpty(event_note_text) ? " (" + event_note_text + ")" : ""

									let subInfo = isNotEmpty(client.identifier)
										? client.identifier + ", " + service.service_name_ext + eventNote
										: isNotEmpty(eventNote)
										? service.service_name_ext + eventNote
										: service.service_name_ext

									let inThePastClass = moment().isAfter(moment(order.time)) ? " xs-in-the-past" : ""

									if (actualEvent) {
										this.inRow = i + 1
									}

									return (
										<div
											key={i}
											className={`${actualEvent ? "xs-event xs-active" : "xs-event"} ${inThePastClass}`}
											onClick={() => {
												EditEventDialogStore.isClickedEdit = true
												EditEventDialogStore.open(order.event)
											}}
										>
											<div className="xs-time" /*onClick={() => this.openAddEventDialog(hour)}*/>
												{moment(order.time).format("HH:mm")}
											</div>
											<div
												className={`xs-service-info ${inThePastClass}`}
												style={inThePast ? {} : {backgroundColor: colors.backgroundColor, color: colors.color}}
											>
												{isSafe(service) && isNotEmpty(service.duration) ? Math.ceil(+service.duration / 60) : 0}
											</div>
											<div className="xs-patient-info">
												<div className={`xs-patient-name ${inThePastClass}`}>
													{isEmpty(client.last_name) && isEmpty(client.first_name) ? (
														client.client_text
													) : (
														<span>
															<span className="xs-lastname">{lastName}</span>
															{" " + client.first_name}
															{isNotEmpty(client.phone) && ", " + client.phone}
														</span>
													)}
												</div>
												<div className="xs-patient-service">{subInfo}</div>
											</div>
											<div className="xs-patient-actions">
												{this.getEventStatus(isEventEnd, client.client_id, order)}
											</div>
										</div>
									)
								} else {
									if (isSafe(order) && isSafe(order.timeFrom) && isSafe(order.timeTo)) {
										let currHour = moment().hours()
										let currMinutes = moment().minutes()
										let fromHour = order.timeFrom.hours()
										let fromMinutes = order.timeFrom.minutes()
										let toHour = order.timeTo.hours()
										let toMinutes = order.timeTo.minutes()

										if (
											((fromHour < currHour || (fromHour == currHour && fromMinutes < currMinutes)) &&
												currHour < toHour) ||
											(currHour == toHour && currMinutes < toMinutes)
										) {
											this.inRow = i + 1
										}

										return (
											<div
												key={i}
												className={`xs-free-time ${moment(order.timeTo).isBefore(moment()) ? "" : "in-the-future"}`}
												onClick={() => this.openAddEventDialog(order.timeFrom, order.timeTo)}
											>
												<div className="xs-time-text">
													<div className="xs-time">
														{moment(order.timeFrom).format("HH:mm")} - {moment(order.timeTo).format("HH:mm")}
													</div>
													<div className="xs-text">
														<FormattedMessage id="Calendar.dailyView.freeTime" /> {order.freeTime}{" "}
														<FormattedMessage id="Calendar.dailyView.minute" />
													</div>
												</div>
												<div className="xs-add-event">
													<i className="xs-thumb-btn fas fa-thumbtack" />
													<FormattedMessage id="Calendar.dailyView.addEvent" />
												</div>
											</div>
										)
									}
								}
							})}
					</div>
				</div>
				{isSafe(CalendarStore.eventsData) && CalendarStore.eventsData.length > 5 && (
					<div
						data-tip
						data-for={expandTooltip}
						className="xs-full-screen"
						onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
					>
						<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
						<ReactTooltip id={expandTooltip}>
							<FormattedMessage id={`Common.label.${expandTooltip}`} />
						</ReactTooltip>
					</div>
				)}
			</div>
		)
	}
}
