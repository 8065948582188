import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import {TableHead, Table, TableBody, TableCell, TableRow, Checkbox} from "@material-ui/core"

import PricePerDeliveryForm from "./pricePerDeliveryForm"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {TextField} from "@material-ui/core"

@injectIntl
@observer
export default class PricePerDeliveryGrid extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			// page: 0,
			// rowsPerPage: 10,
			order: false,
			orderBy: null,
			sortType: ""
		}
	}

	// getData = () => {
	//   let items = []

	//   if (isNotEmpty(AgreementAttributeStore.typeFilter)) {
	//     items = AgreementAttributeStore.agreementPartAttributes.filter(attr => attr.type === AgreementAttributeStore.typeFilter)
	//   }

	//   return items
	// }

	// handleChangePage = (event, page) => {
	//   if (!AgreementAttributeStore.isEditAttributeGrid) {
	//     this.setState({
	//       page
	//     })
	//   }
	// }

	// handleChangeRowsPerPage = event => {
	//   if (!AgreementAttributeStore.isEditAttributeGrid) {
	//     this.setState({
	//       rowsPerPage: event.target.value
	//     })
	//   }
	// }
	sortable = []

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			// let sortable = []

			if (!AgreementAttributeStore.isEditAttributeGrid) {
				this.sortable = []
				for (let key in AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]) {
					this.sortable.push([key, AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][key]])
				}

				this.sortable.sort(function(a, b) {
					if (a[1].delivery.code < b[1].delivery.code) {
						return -1
					}
					if (a[1].delivery.code > b[1].delivery.code) {
						return 1
					}
					if (a[1].in_capitation.toString() < b[1].in_capitation.toString()) {
						return 1
					}
					if (a[1].in_capitation.toString() > b[1].in_capitation.toString()) {
						return -1
					}
					if (a[1].insurance_type > b[1].insurance_type) {
						return -1
					}
					if (a[1].insurance_type < b[1].insurance_type) {
						return 1
					}
					return 0
				})
			}

			this.sortable.forEach((row, idx) => {
				let rowId = row[0]
				if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
					this.tableBodyRows.push(
						<TableRow key={idx}>
							<TableCell>
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<XsInputSearchFilter
										api={api.loadDeliveries}
										minLengthForSearch={3}
										// isValid={isEmpty(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].delivery)}
										onButtonClick={(value) =>
											delayedCallback(500, () => AgreementAttributeStore.searchDeliveries(value, rowId))
										}
										data={AgreementAttributeStore.deliveriesGrid[rowId]}
										onSearchClose={() => (AgreementAttributeStore.deliveriesGrid[rowId] = [])}
										defaultValue={AgreementAttributeStore.deliveryPricePerDeliveryGridJSON[rowId]}
										className="xs-input"
										inputRenderer={(dataRow) => {
											return dataRow.code_ext + " " + dataRow.name_ext
										}}
										valueRenderer={(dataRow) => {
											return dataRow.code_ext + " " + dataRow.name_ext
										}}
										chooseItem={(value) => {
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"delivery",
												value._id
											)
										}}
										modalTitle={<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryCode" />,
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px"
														},
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryName" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 9,
															defaultValue: textValue,
															renderElement: "input"
														}
														// sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								) : (
									AgreementAttributeStore.deliveryPricePerDeliveryGridJSON[rowId]
								)}
							</TableCell>
							<TableCell>
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<XsInputSearchFilter
										api={api.loadDiagnosisCl}
										minLengthForSearch={3}
										onButtonClick={(value) =>
											delayedCallback(500, () => AgreementAttributeStore.searchDiagnosis(value, rowId))
										}
										data={AgreementAttributeStore.diagnosisGrid[rowId]}
										onSearchClose={() => (AgreementAttributeStore.diagnosisGrid[rowId] = [])}
										defaultValue={AgreementAttributeStore.diagnosisGridJSON[rowId]}
										className="xs-input"
										inputRenderer={(dataRow) => {
											return dataRow.code_ext + " " + dataRow.name_ext
										}}
										valueRenderer={(dataRow) => {
											return dataRow.code_ext + " " + dataRow.name_ext
										}}
										chooseItem={(value) => {
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"diagnosis",
												value._id
											)
										}}
										onBlur={(e) => {
											if (isEmpty(e.target.value)) {
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"diagnosis",
													""
												)
											}
										}}
										modalTitle={<FormattedMessage id="Common.label.diagnosis" />}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px"
														},
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 9,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								) : (
									AgreementAttributeStore.diagnosisGridJSON[rowId]
								)}
							</TableCell>
							<TableCell className="xs-width-125">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<XsDropdownList
										emptyValue
										className="xs-dropdown"
										items={GlobalStore.CL["insuranceTypes"]}
										// className={isEmpty(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].insurance_type) ? "xs-input-error" : ""}
										renderer={(item) => {
											return item.name_ext
										}}
										column="_id"
										value={
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.insurance_type
										}
										chooseItem={(item) => {
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"insurance_type",
												item._id
											)
											// AgreementAttributeStore.agreementAttributeInsuranceType = item._id
										}}
									/>
								) : (
									AgreementAttributeStore.insuranceGridJSON[rowId]
								)}
							</TableCell>
							<TableCell className="xs-text-center xs-width-100">
								<Checkbox
									disabled={!AgreementAttributeStore.isEditAttributeGrid}
									checked={
										AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].in_capitation
									}
									onChange={(e) =>
										AgreementAttributeStore.changedAttribute(
											AgreementAttributeStore.typeFilter,
											rowId,
											"in_capitation",
											e.target.checked
										)
									}
								/>
							</TableCell>
							<TableCell className="xs-width-150 xs-text-right">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<TextField
										value={
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										}
										onChange={(e) =>
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"price_in_currency",
												e.target.value
											)
										}
										InputProps={{
											className: "xs-input",
											classes: {
												root: !isPrice(
													AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
														.price_in_currency
												)
													? "xs-input-error"
													: ""
											}
										}}
									/>
								) : (
									priceFormat(
										AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
											.price_in_currency
									)
								)}
							</TableCell>
							{AgreementAttributeStore.isEditAttributeGrid && (
								<TableCell className="xs-text-center xs-delete-action">
									<XsIconButton
										icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
										onClick={
											() =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"active",
													false
												) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
										}
									/>
								</TableCell>
							)}
						</TableRow>
					)
				}
			})
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={4}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />
								</TableCell>
								<TableCell>
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Diagnosis" />
								</TableCell>
								<TableCell>
									<FormattedMessage id="Agreement.Container.Detail.Attribute.InsuranceType" />
								</TableCell>
								<TableCell className="xs-text-center">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Capitation" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerDeliveryForm />
				</div>
			</div>
		)
	}
}
