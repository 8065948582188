"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {getUserPersonnelID, getUserCompanyInfo} from "../../../global/helpers/actions"

class NonCapitationStore {
	@observable isOpen = false
	@observable filters = []

	formRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(moment(this.currFilters.validity_from), moment(formValues.validity_from))
		let isChangeDateTo = compareMomentDate(moment(this.currFilters.validity_to), moment(formValues.validity_to))

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.provider_id != formValues.provider_id
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(form) {
		const formValues = form.values()
		this.currFilters = form.values()

		let filters = [
			{
				associated_column: "company_id",
				values: [{id_value: getUserCompanyInfo().id}]
			},
			{
				associated_column: "personnel_id",
				values: [{id_value: getUserPersonnelID()}]
			},
			{
				associated_column: "validity_from",
				values: [{id_value: formValues.validity_from}]
			},
			{
				associated_column: "validity_to",
				values: [{id_value: formValues.validity_to}]
			}
		]

		if (isNotEmpty(formValues.provider_id)) {
			filters.push({
				associated_column: "provider_id",
				values: [{id_value: formValues.provider_id}]
			})
		}

		if (isNotEmpty(formValues.identifier)) {
			filters.push({
				associated_column: "identifier",
				values: [{id_value: formValues.identifier}]
			})
		}

		this.isChangedFilter(form.values())
		this.filters = filters
	}
}

var singleton = new NonCapitationStore()
export default singleton
