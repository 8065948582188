"use strict"

import React from "react"
import {FormattedMessage} from "react-intl"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import moment from "moment"

import {getUserCompanyInfo, getSelectedOrgUnit} from "../../../../global/helpers/actions"
import {getFilters} from "../../../../global/helpers/api"

// import NxTable from "../../../../global/ui/xsTable/xsTable"
import XsTableServer from "../../../../global/ui/xsTableServer/xsTableServer"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import Grid from "@material-ui/core/Grid"

import {StorageBase} from "../../../../global/storage/storageEx"
import {LAST_PATIENTS, LAST_PATIENTS_COUNT} from "../../../../global/config/constants"
import RouterStore from "../../../../global/store/RouterStore"
import UIStore from "../../../../modules/ambulance/stores/UIStore"
import DataStore from "../../../../modules/ambulance/stores/DataStore"
import DeletePatientDialog from "./deletePatientDialog"
import DeletePatientDialogStore from "../../stores/DeletePatientDialogStore"
import NewPatientDialogStore from "../../stores/NewPatientDialogStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import api from "../../actions/api"

@observer
export default class PatientList extends React.Component {
	// here write your event behaviours
	constructor(props) {
		super(props)
	}

	onHandleRowClick = (dataRow) => {
		UIStore.patientDetailTab = "EHR"
		UIStore.patientCardTab = ""
		UIStore.overviewTab = "overview"
		UIStore.patientInfoTab = "contacts"
		UIStore.mainMenuTab = "mainmenu"

		DataStore.patientDTO.set("lastVisit", dataRow.contact.last_visit)
		DataStore.patientDTO.set("nextVisit", dataRow.contact.next_event_from)
		DataStore.patientDTO.set("lastPreventiveExamination", dataRow.prevention.last_preventive_examination)

		RouterStore.push(`/patient?id=${dataRow.person_id}`)

		// let lastPatients = isSafe(Storage.getItem(LAST_PATIENTS)) ? Storage.getItem(LAST_PATIENTS) : []
		let lastPatients = StorageBase.getObjectByKey(LAST_PATIENTS) || []
		if (lastPatients.length > 0) {
			lastPatients = lastPatients.filter((search) => {
				return search.client_id !== dataRow.client_id
			})
			lastPatients = lastPatients.slice(1 - LAST_PATIENTS_COUNT)
		}

		let newLastPatient = {
			dataRow: dataRow,
			client_id: dataRow.client_id,
			name: dataRow.profile.full_name,
			person_id: dataRow.profile.person_id,
			avatar_id: dataRow.profile.avatar_id
		}

		lastPatients.push(newLastPatient)

		// Storage.setItem(LAST_PATIENTS, lastPatients)
		StorageBase.updateByKey(LAST_PATIENTS, lastPatients)
	}

	getGenderColor(dataRow, isLine = false) {
		return (
			(dataRow.gender_id === "M" ? "xs-provider-male" : dataRow.gender_id === "F" ? "xs-provider-female" : "") +
			(isLine ? " xs-line" : "")
		)
	}

	render() {
		let filterValue = []

		if (DataStore.orgUnit_patientListFilter) {
			filterValue.push({
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnit()._id}]
			})
		}

		if (isSafe(DataStore.search_column_patientListFilter)) {
			filterValue.push({
				associated_column: "search_column",
				values: [{id_value: DataStore.search_column_patientListFilter}]
			})
		}

		if (isSafe(DataStore.insurer_patientListFilter)) {
			filterValue.push({
				associated_column: "insurer_id",
				values: [{id_value: DataStore.insurer_patientListFilter}]
			})
		}

		if (isSafe(DataStore.tags_patientListFilter)) {
			filterValue.push({
				associated_column: "tags",
				values: DataStore.tags_patientListFilter
			})
		}

		if (isSafe(DataStore.diagnoses_patientListFilter)) {
			// DataStore.sendingPatientDiagnosesToAPI = isSafe(DataStore.diagnoses_patientListFilter) && DataStore.diagnoses_patientListFilter.length > 0
			filterValue.push({
				associated_column: "diagnoses",
				values: DataStore.diagnoses_patientListFilter
			})
		}

		if (isSafe(DataStore.all_patientListFilter) && isSafe(DataStore.all_patientListFilter.associated_column)) {
			if (DataStore.all_patientListFilter.associated_column === "is_vip") {
				filterValue.push({
					associated_column: "tags",
					values: [{id_value: "VIP"}]
				})
			} else {
				filterValue.push({
					associated_column: DataStore.all_patientListFilter.associated_column,
					values: [{id_value: DataStore.all_patientListFilter.id_value}]
				})
			}
		}

		let patientFilters = {
			filters: []
		}

		if (DataStore.orgUnit_patientListFilter) {
			patientFilters.filters.push({
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnit()._id}]
			})
		} else {
			patientFilters.filters.push({
				associated_column: "company_id",
				values: [{id_value: getUserCompanyInfo().id}]
			})
		}

		// api
		// 	.loadPatientsFilters(patientFilters)
		// 	.call()
		// 	.then((data) => {
		// 		let filterDiag = data.filters.filter((x) => x.associated_column === "diagnoses")
		// 		if (/*!DataStore.sendingPatientDiagnosesToAPI &&*/ isSafe(filterDiag) && isSafe(filterDiag[0])) {
		// 			DataStore.patientsDiagnoses = filterDiag[0].values
		// 			// DataStore.sendingPatientDiagnosesToAPI = false
		// 		}
		// 	})
		// 	.catch(() => {
		// 		logger("Chyba pri ziskavani zoznamu diagnoz pacientov")
		// 	})

		return (
			<Grid container>
				<Grid item xs={12} className="xs-patient-list">
					<XsTableServer
						setRef={(table) => (DataStore.patientListTableRef = table)}
						getData={(data) => {
							const value = DataStore.search_column_patientListFilter

							if (isSafe(value)) {
								const identifier = value.includes("/") ? value.replace("/", "") : value

								if (
									isFinite(identifier) &&
									(identifier.length === 9 || identifier.length === 10) &&
									+identifier % 11 == 0
								) {
									if (isSafe(data) && data.length == 0) {
										GlobalStore.openConfirmationDialog("xsOpenNewPatient")
									}
								}
							}
						}}
						optimizedData={true}
						uri={`${api.loadCompanyPatients(getUserCompanyInfo().id).getPath()}?level_flag=CDP`}
						filterValue={filterValue}
						postAction={(response) => {
							isSafe(response.tags) ? (DataStore.patientsTagsData = response.tags) : (DataStore.patientsTagsData = [])

							// let filterDiag = response.filters.filter(x => x.associated_column === "diagnoses")
							// if (!DataStore.sendingPatientDiagnosesToAPI && isSafe(filterDiag) && isSafe(filterDiag[0])) {
							//   DataStore.patientsDiagnoses = filterDiag[0].values
							//   DataStore.sendingPatientDiagnosesToAPI = false
							// }
						}}
						config={{
							columnDefs: {
								profile: {
									title: <FormattedMessage id="Patient.labels.patient" />,
									type: "string",
									design: {
										width: "28%",
										body: {
											renderer: (props) => {
												let gender = props.value.gender_name.toLowerCase()
												let lastName = isSafe(props.value.last_name) ? props.value.last_name.toUpperCase() : ""
												let genderAge = isEmpty(props.value.age) ? gender : `${gender}, ${props.value.age} rokov`
												return (
													<Grid container direction="row">
														<Grid item>
															<Grid container direction="column" spacing={8}>
																<Grid item xs={12} className="xs-bold">
																	{lastName + " " + props.value.first_name}
																</Grid>
																<Grid item xs={12} className="xs-greyDefault">
																	{genderAge}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												)
											}
										}
									}
								},
								identifier: {
									title: <FormattedMessage id="Patient.labels.IDNumber" />,
									type: "action",
									design: {
										width: "10%",
										body: {
											className: (dataRow) => {
												return `${this.getGenderColor(dataRow, true)}`
											},
											renderer: (props) => {
												return <Grid container>{props.value}</Grid>
											}
										}
									}
								},
								insuredFrom: {
									title: <FormattedMessage id="Patient.labels.insuredFrom" />,
									type: "action",
									design: {
										width: "10%",
										body: {
											className: (dataRow) => {
												return `${this.getGenderColor(dataRow)}`
											},
											renderer: (props) => {
												return (
													<Grid container direction="column" spacing={8}>
														<Grid item xs={12}>
															{props.value.insurer_name}
														</Grid>
														<Grid item xs={12}>
															{isNotEmpty(props.value.insured_from)
																? moment(props.value.insured_from).format("DD.MM.YYYY")
																: ""}
														</Grid>
													</Grid>
												)
											}
										}
									}
								},
								contact: {
									title: <FormattedMessage id="Patient.labels.contact" />,
									type: "action",
									design: {
										width: "29%",
										body: {
											renderer: (props) => {
												return (
													<Grid container direction="column" spacing={8} className="xs-bold">
														<Grid item xs={12}>
															{isNotEmpty(props.value.contact)
																? props.value.contact
																: isNotEmpty(props.value.email)
																? props.value.email
																: ""}
														</Grid>
														<Grid item xs={12}>
															{props.value.primary_address}
														</Grid>
													</Grid>
												)
											}
										}
									}
								},
								events: {
									title: <FormattedMessage id="Patient.labels.events" />,
									type: "action",
									design: {
										width: "13%",
										body: {
											renderer: (props) => {
												return (
													<Grid container direction="column" spacing={8}>
														{isSafe(props.value) &&
															isSafe(props.value.last_visit) &&
															isNotEmpty(props.value.last_visit) && (
																<Grid item xs={12} className="xs-greyDefault">
																	<Grid container direction="row" alignItems="center" wrap="nowrap">
																		<i
																			className="fal fa-bell fa-lg"
																			data-tip
																			data-for={`lastVisit_${props.value.person_id}`}
																		/>
																		<ReactTooltip id={`lastVisit_${props.value.person_id}`}>
																			<FormattedMessage id="Patient.labels.lastVisit" defaultMessage="Last visit" />
																		</ReactTooltip>
																		<div className="xs-last-visit">
																			{moment(props.value.last_visit).format("DD.MM.YYYY")}
																		</div>
																	</Grid>
																</Grid>
															)}
														{isSafe(props.value) &&
															isSafe(props.value.next_event_from) &&
															isNotEmpty(props.value.next_event_from) && (
																<Grid
																	className="xs-pointer"
																	item
																	xs={12}
																	onClick={() => {
																		if (
																			isSafe(props.value.next_event_id) &&
																			isNotEmpty(props.value.person_id) &&
																			isNotEmpty(props.value.next_event_service_name)
																		) {
																			const searchRequest = getFilters([`event_id=${props.value.next_event_id}`])
																			searchRequest.row_count_show = 1000

																			DataStore.providedServicesNames[props.value.person_id] =
																				props.value.next_event_service_name
																			api
																				.loadEventsRelateds(searchRequest)
																				.call()
																				.then((response) => {
																					DataStore.providedServices[props.value.person_id] = response.rows
																					RouterStore.push(`/patient?id=${props.value.person_id}`)
																				})
																		}
																	}}
																>
																	<Grid container direction="row" alignItems="center" wrap="nowrap">
																		<i
																			className="fas fa-bell fa-lg"
																			data-tip
																			data-for={`nextVisit_${props.value.person_id}`}
																		/>
																		<ReactTooltip id={`nextVisit_${props.value.person_id}`}>
																			<FormattedMessage id="Patient.labels.nextVisit" defaultMessage="Next visit" />:{" "}
																			{props.value.next_event_service_name}
																		</ReactTooltip>
																		<div className="xs-next-event-from">
																			{moment(props.value.next_event_from).format("DD.MM.YYYY")}
																		</div>
																	</Grid>
																</Grid>
															)}
													</Grid>
												)
											}
										}
									}
								},
								prevention: {
									title: <FormattedMessage id="Patient.labels.prevention" />,
									type: "action",
									design: {
										width: "13%",
										body: {
											renderer: (props) => {
												return (
													<Grid container direction="column" spacing={8}>
														{isSafe(props.value) &&
															isSafe(props.value.last_preventive_examination) &&
															isNotEmpty(props.value.last_preventive_examination) && (
																<Grid item xs={12} className="xs-greyDefault">
																	<Grid container direction="row" alignItems="center" wrap="nowrap">
																		<span data-tip data-for={`lastExamination${props.value.person_id}`}>
																			PP:
																		</span>
																		<ReactTooltip id={`lastExamination${props.value.person_id}`}>
																			<FormattedMessage
																				id="Patient.labels.lastPreventive"
																				defaultMessage="Last visit"
																			/>
																		</ReactTooltip>
																		<div className="xs-last-preventive-examination">
																			{moment(props.value.last_preventive_examination).format("DD.MM.YYYY")}
																		</div>
																	</Grid>
																</Grid>
															)}
													</Grid>
												)
											}
										}
									}
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "42px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i
																	className="fal fa-trash-alt fa-lg xs-greyDefault"
																	data-tip
																	data-for="removePatient"
																/>
																<ReactTooltip id="removePatient" effect="solid">
																	<FormattedMessage id="Common.label.removePatient" />
																</ReactTooltip>
															</span>
														}
														onClick={() => DeletePatientDialogStore.open(props.value)}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								paging: {
									rowsPerPageOptions: [10, 15]
								},
								mapper: (dataRow) => {
									return {
										profile: {
											person_id: dataRow.person_id,
											avatar_id: dataRow.avatar_id,
											first_name: dataRow.first_name,
											last_name: dataRow.last_name,
											gender_id: dataRow.gender_id,
											gender_name: dataRow.gender,
											age: dataRow.age
										},
										insuredFrom: {
											insured_from: dataRow.insured_from,
											insurer_name: dataRow.insurer_name
										},
										identifier: dataRow.identifier,
										contact: {
											email: dataRow.email,
											contact: dataRow.contact,
											primary_address: dataRow.primary_address
										},
										events: {
											last_visit: dataRow.last_visit,
											next_event_from: dataRow.next_event_from,
											person_id: dataRow.person_id,
											next_event_id: dataRow.next_event_id,
											next_event_service_name: dataRow.next_event_service_name
										},
										prevention: {
											last_preventive_examination: dataRow.last_preventive_examination,
											person_id: dataRow.person_id
										},
										gender_id: dataRow.gender_id,
										person_id: dataRow.person_id,
										client_id: dataRow.client_id,
										delete: dataRow.client_id
									}
								}
							}
						}}
					/>
				</Grid>
				<DeletePatientDialog routeToPatient={this.onHandleRowClick} />
				<XsConfirmationDialog
					name="xsOpenNewPatient"
					text={<FormattedMessage id="Common.label.createNewPatient" />}
					type="info"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-user-plus fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.dialog.No"
					}}
					confirmBtn={{
						icon: <i className="fal fa-user-plus fa-lg" />,
						text: "Common.dialog.Yes"
					}}
					onConfirmation={() => NewPatientDialogStore.open(DataStore.search_column_patientListFilter)}
				/>
			</Grid>
		)
	}
}
