"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"
// import Tooltip from "@material-ui/core/Tooltip"
// import {Menu, MenuItem} from "@material-ui/core"

// import UI controls here
import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"
import {LANGUAGE} from "../../global/config/constants"
import {StorageBase} from "../../global/storage/storageEx"

// definitions of form fields
import fields from "./loginFormFields"

import {FormattedMessage} from "react-intl"

import {login} from "../../global/helpers/actions"
import GlobalStore from "../../global/store/GlobalStore"
import XsLoading from "../../global/ui/xsLoading/xsLoading"

import "./lx-login.less"

@observer
export default class LoginForm extends React.Component {
	state = {
		// anchorEl: null
		// language: Storage.getItem(LANGUAGE)
		language: StorageBase.getByKey(LANGUAGE)
	}

	// here write your event behaviours
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				// special validation here
				GlobalStore.authentificationInProgress = true
			},
			onSuccess(form) {
				// bussiness call here
				const {loginName, password} = form.values()
				GlobalStore.authentificationInProgress = true

				login(loginName, password)
			},
			onError() {
				GlobalStore.authentificationInProgress = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		this.inProgress = GlobalStore.authentificationInProgress
		this.loginFailed = GlobalStore.authentificationFailed
	}

	handleLanguageClick = (event) => {
		this.setState({anchorEl: event.currentTarget})
	}

	// handleLanguageClose = () => {
	// 	this.setState({anchorEl: null})
	// }

	renderLanguagesMenu = () => {
		// const {anchorEl} = this.state
		// const languages = [{id: "en", name: "English"}, {id: "sk", name: "Slovenčina"}]
		return (
			// <div className="xs-language-select">
			// 	<Tooltip title={<FormattedMessage id="Login.label.changeLanguage" />}>
			// 		<i className="far fa-language fa-2x" onClick={this.handleLanguageClick} />
			// 	</Tooltip>
			// 	<Menu id="language-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleLanguageClose}>
			// 		{languages.map((l, idx) => (
			// 			<MenuItem key={idx} onClick={() => this.changeLanguage(l.id)}>
			// 				{l.name}
			// 			</MenuItem>
			// 		))}
			// 	</Menu>
			// </div>
			<div className="xs-languages">
				<span
					className={this.state.language === "sk" ? "xs-active xs-bold xs-item pr-2" : "xs-bold xs-item pr-2"}
					onClick={() => this.changeLanguage("sk")}
				>
					<FormattedMessage id="Common.label.slovak" />
				</span>
				|
				<span
					className={this.state.language === "en" ? "xs-active xs-bold xs-item pl-2" : "xs-bold xs-item pl-2"}
					onClick={() => this.changeLanguage("en")}
				>
					<FormattedMessage id="Common.label.english" />
				</span>
			</div>
		)
	}

	changeLanguage = (languageCode) => {
		if (languageCode) {
			//Storage.setItem(LANGUAGE, languageCode)
			StorageBase.updateByKey(LANGUAGE, languageCode)
			window.location.reload()
		}
	}

	render() {
		return (
			<Grid container direction="column" alignItems="center">
				{GlobalStore.authentificationInProgress && <XsLoading overlay={true} />}
				<div className="xs-login-site">
					<div className="xs-basic-logo">
						<img src="/public/images/ambee-logo-color-transparent.png" />
					</div>
					<div className="xs-login-form">
						<form>
							<Grid container direction="column">
								<XsInput
									field={this.form.$("loginName")}
									fullWidth
									autoFocus={true}
									submitAction={(e) => this.form.onSubmit(e)}
								/>
								<XsInput
									field={this.form.$("password")}
									type="password"
									fullWidth
									submitAction={(e) => this.form.onSubmit(e)}
								/>

								{this.loginFailed && (
									<div className="xs-errorMessage" style={{opacity: this.loginFailed ? 1 : 0}}>
										<FormattedMessage id="Login.error.badUserOrPassword" />
									</div>
								)}
								<div className="xs-sign-language xs-row xs-align xs-justify-between">
									<XsButton
										className="xs-info"
										icon={<i className="fal fa-lock-open-alt"></i>}
										type="submit"
										onClick={this.form.onSubmit}
										text={
											this.inProgress ? (
												<FormattedMessage id="Login.label.signingInProgress" />
											) : (
												<FormattedMessage id="Login.label.signIn" />
											)
										}
										disabled={this.inProgress}
									/>
									{this.renderLanguagesMenu()}
								</div>
								<div className="xs-footer">
									<div className="xs-forgot-password">
										<FormattedMessage id="Login.label.forgotenPass" />
									</div>
								</div>
							</Grid>
						</form>
					</div>
					<div className="xs-consent">
						<FormattedMessage id="Login.label.agreement" />{" "}
						<a href="https://ambee.sk/dokumentacia-podmienky-pouzivania.html" target="_blank" rel="noopener noreferrer">
							<FormattedMessage id="Login.label.agreementLink" />
						</a>
						.
					</div>
				</div>
			</Grid>
		)
	}
}
