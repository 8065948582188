"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"

class EHGWStore {
	@observable installFiles = []

	@action loadInstallFiles() {
		api
			.loadInstallFiles()
			.call()
			.then((res) => {
				this.installFiles = res.rows
			})
			.catch((err) => {
				logger("loadInstallFiles failed:", err)
			})
	}

	@action deleteInstallFile(id) {
		api
			.deleteInstallFile(id)
			.call()
			.then(() => {
				this.loadInstallFiles()
			})
			.catch((err) => {
				logger("chyba pri mazani suboru", err)
				this.loadInstallFiles()
			})
	}
}
var singleton = new EHGWStore()
export default singleton
