import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./sicknessAbsTempFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

@injectIntl
@observer
export default class SicknessAbsTempTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("cisloPotvrdenia")} />
						</Grid>
						<Grid item xs={6}>
							<XsDateTimePicker field={this.form.$("praceneschopnyKuDnu")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel1" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("chorobaCheckbox")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("karantenneOpatrenieCheckbox")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("urazCheckbox")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("chorobaZPovolaniaCheckbox")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("pracovnyUrazCheckbox")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("urazZavinenyInouOsobouCheckbox")} />
						</Grid>
					</Grid>
					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("zmenaDiagnozyOdDatum")} showTimeSelect={false} />
					</Grid>

					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datum1Datum")} showTimeSelect={false} />
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel2" />
					</div>
					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("datum2Datum")} showTimeSelect={false} />
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel3" />
					</div>
					<Grid item xs={12}>
						<XsInput field={this.form.$("dennyVymeriavaciZaklad")} />
					</Grid>
					<Grid item xs={2}>
						<XsDateTimePicker
							field={this.form.$("zaciatokDocasnejPracovnejNeschopnostiDatum")}
							showTimeSelect={false}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={5}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel4" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("znizenieSumyUrazovehoPriplatkuAnoCheckbox")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("znizenieSumyUrazovehoPriplatkuNieCheckbox")} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("poznamka")} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={1}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel5" />
							</div>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_55PercentOdDo")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_55PercentPocetDniDPN")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_55PercentSumaUrazovehoPriplatku")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={1}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel6" />
							</div>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_25PercentOdDo")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_25PercentPocetDniDPN")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("_25PercentSumaUrazovehoPriplatku")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={1}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.FormLabel7" />
							</div>
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("spoluSumaUrazovehoPriplatku")} />
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("znizenaSumaUrazovehoPriplatku")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("datum3Datum")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
