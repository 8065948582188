import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import StorageManagementDialogStore from "./stores/StorageManagementDialogStore"
import RouterStore from "../../global/store/RouterStore"
import UIStore from "./stores/UIStore"
import {Grid} from "@material-ui/core"
// import renderHTML from "react-render-html"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
// import moment from "moment"
import moment from "moment"

// import RouterStore from "../../global/store/RouterStore"
// import {toJS} from "mobx"
// import GWUpdatingDialogStore from "./stores/GWUpdatingDialogStore"
// import XsLoading from "../../global/ui/xsLoading/xsLoading"

/*
Ocakavany objekt:{
	timestamp: Date.now(),
	text: "Podpisujem recept(y) a posielame ich do poistovne",
	state: 0, //0 - progress 1-success 2-error
	needApprove: true,
	errorCode: null,
	stateText: null,
	navigationIdentifier: {},
	identifier: {}
}
*/
@injectIntl
@observer
export default class StorageManagementDialog extends React.Component {
	componentDidMount() {
		StorageManagementDialogStore.checkUnsavedData()
		setInterval(() => StorageManagementDialogStore.checkUnsavedData(), 1000)
	}

	handleFooterTextClick = () => {
		UIStore.reportDetailTab = "draftReport"
		RouterStore.push("/reports")
		StorageManagementDialogStore.close()
	}

	render() {
		const mapType = {
			PRESCRIPTION: {text: "Common.label.drugs", tab: "prescription"},
			RECORD: {text: "Common.label.dekurz", tab: "record"},
			ANAMNESIS: {text: "Common.label.anamnes", tab: "anamnesis"},
			REQUEST: {text: "Common.label.requests", tab: "request"},
			SICKNESS: {text: "PatientDetail.cards.PN", tab: "sicknessabs"},
			OPERATION: {text: "PatientDetail.cards.opProtocol", tab: "operation"},
			MEDICATION: {text: "PatientDetail.cards.medication", tab: "medication"}
		}

		let unsavedItems = []
		StorageManagementDialogStore.unsavedData
			.slice()
			.sort((a, b) => b.lastModified - a.lastModified)
			.forEach((obj, idx) => {
				unsavedItems.push(
					<Grid item container alignItems="center" key={idx} onClick={() => {}}>
						<Grid item xs={2}>
							{moment(obj.lastModified).format("DD.MM.YYYY HH:mm")}
						</Grid>
						<Grid item xs={6} className="xs-bold">
							{obj.patientFullName}
						</Grid>
						<Grid item xs={3} className="textRight">
							{this.props.intl.formatMessage({id: mapType[obj.type].text})}
						</Grid>
						<Grid item xs={1}>
							<span className="pullRight iconSpan">
								<XsIconButton
									icon={<i className="fal fa-external-link-square fa-2x"></i>}
									disabled={RouterStore.location.pathname === "/patient"}
									onClick={() => {
										RouterStore.push(`/patient?id=${obj.patientId}`)
										UIStore.patientCardTab = mapType[obj.type].tab
										// window.open(`/patient?id=${obj.patientId}`, "_self")
										StorageManagementDialogStore.close()
									}}
								/>
							</span>
						</Grid>
					</Grid>
					/* <Grid container direction="row">
						{this.state.openedMessageKey == key && (
							<Grid item xs={12} className="stateText">
								{isNotEmpty(value.stateText) ? renderHTML(value.stateText) : ""}
							</Grid>
						)}
					</Grid> */
				)
			})
		return (
			<Dialog
				open={StorageManagementDialogStore.isOpen}
				disableBackdropClick={true}
				maxWidth="md"
				id="StorageManagementDialog"
				onClose={() => {
					StorageManagementDialogStore.close()
					// this.setState({openedMessageKey: null})
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-width-paper-900"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-tasks fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.notSavedHealthRecords" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Common.label.storageManagmentSubtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column" spacing={8}>
						<Grid item container className="fontSize13">
							<Grid item xs={2}>
								<FormattedMessage id="Common.label.lastDateChange" />
							</Grid>
							<Grid item xs={6}>
								<FormattedMessage id="Patient.labels.patient" />
							</Grid>
							<Grid item xs={3} className="textRight">
								<FormattedMessage id="Common.label.recordType" />
							</Grid>
							<Grid item xs={1}></Grid>
						</Grid>
						{unsavedItems}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<div
						className="footerText"
						onClick={() => {
							this.handleFooterTextClick()
						}}
					>
						<i className="fal fa-copy fa-lg mr-1"></i>
						<FormattedMessage id="Common.label.checkDraftReportList" />
					</div>
					<XsButton
						className="xs-info"
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => {
							StorageManagementDialogStore.close()
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
