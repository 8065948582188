import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"
import api from "../../../actions/api"
// import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"

import fields from "./problemFormFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./problemFormBinding"

import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientProblemsDialogStore from "../../../stores/PatientProblemsDialogStore"
import ProblemsStore from "../../../stores/ProblemsStore"
import Problem from "./problem"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import {injectIntl} from "react-intl"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../../../global/helpers/actions"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class PatientProblemsDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			disabledCheckbox: false
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				UIStore.isFormSaving = true
				const personnelID = getUserPersonnelID()
				const providerID = getSelectedOrgUnitID()

				const specRels = [
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: DataStore.patientDTO.get("patientID"),
							_type: "EHR.Data.Entity.Patient"
						},
						type: "pat"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: personnelID,
							_type: "EHR.Data.Entity.Personnel"
						},
						type: "phy"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: providerID,
							_type: "Entity.Data.OrgUnit"
						},
						type: "ou"
					}
				]

				isSafe(form.$("startDate").value) && isNotEmpty(form.$("startDate").value)
					? (form.$("startDate").value = moment(form.$("startDate").value).utc())
					: ""
				isSafe(form.$("endDate").value) && isNotEmpty(form.$("endDate").value)
					? (form.$("endDate").value = moment(form.$("endDate").value).utc())
					: ""

				form.$("problem_type").value
					? (form.$("problem_type").value = "DISPENSARY")
					: (form.$("problem_type").value = "General")

				insertFormDataByScheme(
					form,
					dataBindings,
					{
						active: !ProblemsStore.isDelete,
						_id: form.$("_id").value,
						specRels: specRels
					},
					(response) => {
						ProblemsStore.saveSymptoms(ProblemsStore.symptomsChips, response._id)
						// else ProblemsStore.loadPatientProblems()
						form.reset()
						ProblemsStore.isDelete = false
						UIStore.isFormSaving = false
						ProblemsStore.writeEHR(response)
					},
					false,
					ProblemsStore.isDelete ? "PatientDetail.problems.deleteMessage" : "PatientDetail.problems.saveSuccess"
				)
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		GlobalStore.refreshCodeLists(["severities", "medicationRepeats", "endReasonPat"])
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	form

	render() {
		PatientProblemsDialogStore.chechForInitData(this.form)
		let levelJSON = {
			0: {level: 0, color: "#39B0DD"},
			1: {level: 1, color: "#01579b"},
			2: {level: 2, color: "#FFB900"},
			3: {level: 3, color: "#FF8F00"},
			4: {level: 4, color: "#C6184E"}
		}

		return (
			<div>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}

				<Dialog
					id="xsPatientProblemDialog"
					open={PatientProblemsDialogStore.isOpen}
					onClose={() => (this.form.reset(), PatientProblemsDialogStore.close())}
					className="xs-base-dialog dialog-patientproblems"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-1050"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => (this.form.reset(), PatientProblemsDialogStore.close())}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-clipboard-check fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Patient.form.problemForm.dialogTitle" />
							</div>
							<div className="xs-header-subtitle">
								<FormattedMessage id="Patient.form.problemForm.dialogSubTitle" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-overflow-visible">
						<Grid container direction="column">
							{/* <Grid item xs={12}>
							<div className="xs-bold xs-form-title">
								{isNotEmpty(this.form.$("_id").value) ? (
									<FormattedMessage id="PatientDetail.problems.dialogTitleEdit" />
								) : (
									<FormattedMessage id="PatientDetail.problems.dialogTitle" />
								)}
							</div>
						</Grid> */}
							<Grid item xs={12} className="xs-form">
								<Grid container direction="row" spacing={8}>
									<Grid item xs={5}>
										<XsInput field={this.form.$("name")} />
									</Grid>
									{/* <Grid item xs={2}>
                  <SearchSelect field={this.form.$("severity")} items={GlobalStore.CL["severities"]} required />
                </Grid> */}
									<Grid item xs={2}>
										<XsDateTimePicker field={this.form.$("startDate")} showTimeSelect={false} />
									</Grid>
									<Grid item xs={2}>
										<XsDateTimePicker
											field={this.form.$("endDate")}
											showTimeSelect={false}
											// onChange={(val) => {
											// 	logger("val", val)
											// 	this.form.$("end_reason").value = ""
											// }}
										/>
									</Grid>
									<Grid item xs={3}>
										<XsSearchSelect
											field={this.form.$("end_reason")}
											items={GlobalStore.CL["endReasonPat"]}
											// disabled={isEmpty(this.form.$("endDate").value) ? true : false}
										/>
									</Grid>
									<Grid item xs={5}>
										<XsAutocompleteLocal
											field={this.form.$("diagnoses")}
											minLengthForSearch="1"
											data={RegisterCacheStore.diagnosisRegister}
											clearText
											inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
											saveValue={(obj) => obj.code}
											specialDataFilter={(data) =>
												data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
											}
											postAction={(data, loaded) => {
												if (!loaded) {
													if (data.life_threatening) {
														this.setState({disabledCheckbox: true})
														this.form.$("life_threatening").set(true)
													} else {
														this.setState({disabledCheckbox: false})
														this.form.$("life_threatening").set(false)
													}
												}
											}}
											onClear={() => {
												this.setState({disabledCheckbox: false})
												this.form.$("life_threatening").set(false)
											}}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
														}
													},
													options: {
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														},
														paging: {
															rowsPerPage: 100,
															rowsPerPageOptions: [5, 10, 25, 50, 100]
														}
													}
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<XsInput field={this.form.$("diagnosis_desc")} />
									</Grid>
									<Grid item xs={2} style={{paddingTop: "14px"}}>
										<XsCheckbox field={this.form.$("life_threatening")} disabled={this.state.disabledCheckbox} />
									</Grid>
									<Grid item xs={2} style={{paddingTop: "14px"}}>
										<XsCheckbox field={this.form.$("biohazard")} />
									</Grid>
									<Grid item xs={2} style={{paddingTop: "14px"}}>
										<XsCheckbox
											field={this.form.$("problem_type")}
											onChange={(field) => {
												if (!field.value) {
													GlobalStore.openConfirmationDialog("xsCancelDispensarisation")
												}
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsInput
											type="number"
											field={this.form.$("reexamination_every_amount")}
											disabled={!this.form.$("problem_type").value}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsSearchSelect
											field={this.form.$("reexamination_every_unit")}
											items={GlobalStore.CL["medicationRepeats"].filter(
												(x) => x._id == "Y" || x._id == "W" || x._id == "M"
											)}
											disabled={!this.form.$("problem_type").value}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsInput
											type="number"
											field={this.form.$("expected_for_amount")}
											disabled={!this.form.$("problem_type").value}
										/>
									</Grid>
									<Grid item xs={4}>
										<XsSearchSelect
											field={this.form.$("disease")}
											items={GlobalStore.CL["disease"]}
											disabled={!this.form.$("problem_type").value}
										/>
									</Grid>
									<Grid item xs={12}>
										<XsAutocomplete
											field={this.form.$("symptom")}
											minLengthForSearch="1"
											api={api.loadSymptoms}
											autoFocus={true}
											inputRenderer={(obj) => obj.name_ext}
											saveValue={(obj) => obj.code}
											postAction={(value, loaded, forceRefresh, endDots) => {
												ProblemsStore.symptomsChips.push({
													text: value.name_ext,
													severity: isSafe(endDots) ? endDots : 0
													// code: value.code
												})
												this.form.$("symptom").value = ""
											}}
											placeholder={this.props.intl.formatMessage({id: "Patient.form.problemForm.symptomPlaceholder"})}
											endDots={true}
										/>
									</Grid>
									<Grid item xs={12}>
										{ProblemsStore.symptomsChips.map((problem, idx) => {
											return (
												<XsChip
													key={idx}
													label={problem.text}
													circleStyle={{
														backgroundColor:
															levelJSON[
																isNotEmpty(problem.severity) && problem.severity <= 4 && problem.severity >= 0
																	? problem.severity
																	: 2
															].color
													}}
													onDelete={() => {
														ProblemsStore.symptomsChips.splice(idx, 1)
													}}
													deleteIcon={<i className="fal fa-times-circle" />}
												/>
											)
										})}
									</Grid>
								</Grid>
								{isNotEmpty(this.form.$("_id").value) ? (
									<Grid item xs={12} className="xs-action">
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={(e) => {
												this.form.onSubmit(e)
											}}
											text={<FormattedMessage id="Patient.form.problemForm.saveChanges" />}
											icon={<i className="fal fa-plus fa-lg" />}
										/>
										<XsButton
											className="xs-danger xs-outline"
											onClick={() => {
												this.form.reset()
												ProblemsStore.symptomsChips = []
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<i className="fal fa-times fa-lg" />}
										/>
									</Grid>
								) : (
									<Grid item xs={12} className="xs-action">
										<XsButton
											className="xs-success"
											type="submit"
											onClick={(e) => {
												this.form.onSubmit(e)
											}}
											text={<FormattedMessage id="Patient.form.problemForm.addProblem" />}
											icon={<i className="far fa-plus fa-lg" />}
										/>
									</Grid>
								)}
							</Grid>
							<Grid item xs={12} className="xs-items-scrollbar">
								<div className="xs-bold xs-items-title xs-row">
									<FormattedMessage id="PatientDetail.problems.dialogProblems" />
									<div className="xs-count-items">
										{isSafe(ProblemsStore.problemsDTO) && ProblemsStore.problemsDTO.length > 0
											? ProblemsStore.problemsDTO.length
											: 0}
									</div>
								</div>
								<div className="xs-items">
									{isSafe(ProblemsStore.problemsDTO) &&
										ProblemsStore.problemsDTO.length > 0 &&
										ProblemsStore.problemsDTO.map((problem, idx) => {
											const firstProblem = ProblemsStore.problemsDTO[0]
											const lastProblem = ProblemsStore.problemsDTO[ProblemsStore.problemsDTO.length - 1]
											const lastProblemAgeTo = lastProblem.age_to
												? lastProblem.age_to
												: moment(new Date()).diff(moment(lastProblem.birth_date), "years")
											const difference = lastProblemAgeTo - firstProblem.age_from
											// ProblemsStore.symptomChipsForm[idx + 1] = {}
											return (
												<Problem
													key={idx + 1}
													problem={problem}
													index={idx + 1}
													totalLength={difference}
													firstAge={firstProblem.age_from}
													form={this.form}
													onClick={() => ProblemsStore.formEditData(this.form, problem)}
													isDeletable={true}
												/>
											)
										})}
								</div>
							</Grid>
							<XsConfirmationDialog
								type="danger"
								name="xsDeletePatientProblem"
								text={
									<span className="xs-greyDefault fontSize13">
										<FormattedMessage id="Delete.problem.text" />
									</span>
								}
								subtext={<span className="xs-bold fontSize15">{PatientProblemsDialogStore.nameOfDeleteProblem}</span>}
								headerConfig={{
									text: "Delete.problem.header.text",
									subtext: "Delete.problem.header.subtext",
									icon: <i className="fal fa-trash-alt fa-2x" />
								}}
								cancelBtn={{
									icon: <i className="fal fa-times fa-lg" />,
									text: "Common.btn.not.delete"
								}}
								confirmBtn={{
									icon: <i className="fal fa-trash-alt fa-lg" />,
									text: "Patient.form.problemForm.deleteWarningConfirm"
								}}
								onConfirmation={() => {
									// ProblemsStore.isDelete = true
									// ProblemsStore.formEditData(this.form, GlobalStore.confirmationDialogParams.problem)
									// this.form.submit(e)
									ProblemsStore.deleteProblem(this.form, this.form.$("_id").value)
								}}
							/>
						</Grid>
					</DialogContent>
				</Dialog>
				<XsConfirmationDialog
					name="xsCancelDispensarisation"
					text={<FormattedMessage id="Common.label.dispensarisationMessage" />}
					type="warning"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-exclamation-triangle fa-2x"></i>
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg"></i>,
						text: "Common.dialog.No"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg"></i>,
						text: "Common.dialog.Yes"
					}}
					onConfirmation={() => {
						this.form.$("reexamination_every_amount").value = ""
						this.form.$("reexamination_every_unit").value = ""
						this.form.$("expected_for_amount").value = ""
					}}
					onCancel={() => {
						this.form.$("problem_type").value = true
					}}
				/>
			</div>
		)
	}
}
