import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				datefrom: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date"
				},
				dateto: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date"
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />,
					type: "string"
				}
			}
		}
	}
}
