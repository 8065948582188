import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
// import MobxReactForm from "mobx-react-form"
// import validatorjs from "validatorjs"
// import moment from "moment"

// import SaveIcon from "@material-ui/icons/Done"
// import CancelIcon from "@material-ui/icons/Clear"
// import TrashIcon from "@material-ui/icons/DeleteForever"
import Grid from "@material-ui/core/Grid"

// import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

// import bindings from "../../../../../global/ui/globalUISchemeBindings"
import api from "../../../actions/api"
// import UIStore from "../../../stores/UIStore"
// import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
// import {loadDataToFormByScheme} from "../../../../../global/helpers/bindings"
// import DataStore from "../../../stores/DataStore"
// import DDaction from "../../../actions/patientCard"
// import dataBindings from "./patientMedicationBindings"
// import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
// import PatientMedicationStore from "../../../stores/PatientMedicationStore"
// import {SaveableForm} from "../saveableForm/saveableForm"
// import fields from "./patientMedicationFields"
// import {formDataStateCL} from "../../../../../global/config/codeLists"

// @injectIntl
// @observer
// export default class PatientMedicationForm extends SaveableForm {
function PatientMedicationForm(props) {
	// constructor(props) {
	// 	super(props)
	// 	props.formName = "PatientMedicationForm"
	// 	const hooks = {
	// 		onSubmit() {},
	// 		onSuccess() {},
	// 		onError() {}
	// 	}

	// 	GlobalStore.refreshCodeLists(["medicationSubTypes", "medicationRoutes", "medicationDoseUnits"])
	// 	props.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	// 	props.getFormHandler(props.keyVal, props.form)

	// 	this.isReprescription = false
	// 	this.xsDateTimePickerRef

	// 	// Znovupredpisat
	// 	if (isSafe(props.medicationId) && isNotEmpty(props.medicationId)) {
	// 		this.initLoad(props.medicationId)
	// 	} else {
	// 		if (!props.canceledSessionLoad) {
	// 			this.loadFormFromSession(
	// 				PatientMedicationStore,
	// 				this.props.keyVal,
	// 				isNotSafe(props.multiLoad) || DataStore.checkFormDataState(props.formName, formDataStateCL.MODIFIED),
	// 				props.dataFromDataStore,
	// 				props.exact
	// 			)
	// 			if (isSafe(props.multiLoad) && DataStore.checkFormDataState(props.formName, formDataStateCL.MODIFIED)) {
	// 				props.multiLoad()
	// 			}
	// 		}
	// 		//Ak máme novú medikáciu predplníme diagnózu z dnešhého dekurzu
	// 		if (DataStore.patientDTO) {
	// 			const lastRecord = DataStore.patientDTO.get("lastRecord")
	// 			if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
	// 				// dgvalue = lastRecord.diagnosis
	// 				props.form.$("diagnosis").set("value", lastRecord.diagnosis)
	// 			}
	// 		}

	// 		if (props.keyVal > 1) {
	// 			props.setDefaultDiagnosis(props.form)
	// 		}
	// 	}
	// }

	// initLoad = (id) => {
	// 	this.isReprescription = true

	// 	loadDataToFormByScheme(props.form, dataBindings, {prescriptionId: id}, (data) => {
	// 		props.form.$("medicationType").set("value", data.medication_type)
	// 		props.form.$("drugs").set("value", data.product_id)
	// 		props.form.$("unit").set("value", data.dose_unit)
	// 		props.form.$("medicationRoute").set("value", data.route)
	// 		props.form.$("batchNumber").set("value", "")
	// 		props.form.$("expiration").set("value", "")
	// 		props.form.$("barcode").set("value", "")

	// 		this.onMedicationTypeChange()

	// 		setTimeout(function() {
	// 			GlobalStore.handleClickFocus("patientMedicationForm")
	// 		}, 1000)
	// 		PatientMedicationStore.represcribeFlag = true
	// 	})
	// }

	// componentWillUnmount() {
	// 	PatientMedicationStore.medicationId = null
	// }

	// const saveMedication = (medicationJson) => {
	// 	api
	// 		.savePrescription(medicationJson)
	// 		.call()
	// 		.then((response) => {
	// 			const providerID = getSelectedOrgUnitID()

	// 			if (medicationJson.print) {
	// 				PatientPrescriptionStore.printPrescription(response._id, DataStore.patientDTO.get("patient"), true)
	// 			}

	// 			DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
	// 				DataStore.setPatientEHR(overview)
	// 				UIStore.isFormSaving = false
	// 				props.form.reset()
	// 				UIStore.patientCardTab = ""
	// 				UIStore.overviewTab = "overview" //"medicationAMB" - presmerovanie na prehlad
	// 				//PatientMedicationStore.represcribeId = null

	// 				let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
	// 				if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
	// 					DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
	// 				}
	// 			})
	// 		})
	// }

	const onMedicationTypeChange = () => {
		// if (isSafe(this.xsDateTimePickerRef)) {
		if (props.form.$("medicationType").value === "ADMVACC") {
			// props.form.$("batchNumber").set("rules", "required")
			props.form.$("expiration").set("rules", "required")
		} else {
			// props.form.$("batchNumber").set("rules", "")
			props.form.$("expiration").set("rules", "")
		}

		// this.xsDateTimePickerRef.refs.wrappedInstance.checkValidation()
		// }
	}

	// render() {
	// 	if (PatientMedicationStore.represcribeFlag) {
	// 		DataStore.setFormDataState(props.formName, formDataStateCL.MODIFIED)
	// 	} else if (DataStore.getFormDataState(props.formName) != formDataStateCL.MODIFIED) {
	// 		DataStore.setFormDataState(props.formName, formDataStateCL.WITHDEFS)
	// 	}

	return (
		<section className="xs-patient-card-form xs-column">
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left xs-flex">
					{/* {((DataStore.savedFormObject &&
						(DataStore.checkFormDataState(props.formName, formDataStateCL.WITHDEFS) ||
							DataStore.checkFormDataState(props.formName, formDataStateCL.EMPTY))) ||
						GlobalStore.confirmationDialogOpened == props.formName) &&
						this.renderConfirmDialog(
							() => DataStore.setFormDataState(props.formName, formDataStateCL.MODIFIED),
							() => {}
						)} */}
					<Grid container spacing={8}>
						<Grid item xs={4}>
							<SearchSelect
								field={props.form.$("medicationType")}
								items={
									isSafe(GlobalStore.CL["medicationSubTypes"])
										? GlobalStore.CL["medicationSubTypes"].filter(
												(data) => data.code == "ADMDRUG" || data.code == "ADMVACC"
										  )
										: []
								}
								onChange={() => {
									onMedicationTypeChange()
								}}
								required
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocomplete
								field={props.form.$("drugs")}
								minLengthForSearch="3"
								clearText
								api={api.loadDrugs}
								autoFocus={true}
								ref={(input) => (GlobalStore.defaultFocusFields["patientMedicationForm"] = input)}
								inputRenderer={(obj) =>
									obj.code_ext +
									" | " +
									obj.name_ext +
									" | " +
									obj.supplement +
									" | " +
									(obj.patient_payment ? obj.patient_payment + " | " : "") +
									(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
									obj.atc +
									" | " +
									obj.active_ingredient
								}
								saveValue={(obj) => obj.code}
								postAction={(value, isLoaded) => {
									if (isLoaded == false) {
										props.form.$("unit").value = value.dose_unit
										props.form.$("medicationRoute").value = value.route
									}
									props.form.$("productCode").value = "" + value.code
								}}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
												type: "string",
												dbName: "code_ext"
											},
											name_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											},
											supplement: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
												type: "string",
												dbName: "supplement"
											},
											patient_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
												type: "string",
												dbName: "patient_payment"
											},
											insurer_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
												type: "string",
												dbName: "insurer_payment"
											},
											atc: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
												type: "string",
												dbName: "atc"
											},
											active_ingredient: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />,
												type: "string",
												dbName: "active_ingredient"
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											renderHeaderAsFirstRow: true,
											findSameATC: true,
											paging: {
												rowsPerPage: 100,
												rowsPerPageOptions: [5, 10, 25, 50, 100]
											}
										}
									}
								}}
								onClear={() => {
									props.form.$("unit").value = ""
									props.form.$("medicationRoute").value = ""
									props.form.$("productCode").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={props.form.$("diagnosis")}
								minLengthForSearch="3"
								data={RegisterCacheStore.diagnosisRegister}
								clearText
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								specialDataFilter={(data) =>
									data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
								}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											paging: {
												rowsPerPage: 100,
												rowsPerPageOptions: [5, 10, 25, 50, 100]
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<SearchSelect
								field={props.form.$("unit")}
								items={GlobalStore.CL["medicationDoseUnits"]}
								// onChange={setDosageText}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsInput field={props.form.$("packs")} type={"number"} min={"1"} />
						</Grid>
						<Grid item xs={4}>
							<SearchSelect
								field={props.form.$("medicationRoute")}
								items={GlobalStore.CL["medicationRoutesMedication"]}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsInput field={props.form.$("batchNumber")} min={"1"} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker
								// ref={(ref) => (this.xsDateTimePickerRef = ref)}
								field={props.form.$("expiration")}
								showTimeSelect={false}
								placeholderText="Zadajte posledný deň mesiaca exspirácie"
							/>
						</Grid>
						<Grid item xs={4}>
							<XsInput field={props.form.$("barcode")} min={"1"} />
						</Grid>
						{props.form.$("medicationType").value === "ADMVACC" && (
							<React.Fragment>
								<Grid item xs={4}>
									<SearchSelect field={props.form.$("vaccinationType")} items={GlobalStore.CL["vaccinationTypes"]} />
								</Grid>
								<Grid item xs={4}>
									<SearchSelect field={props.form.$("medicationLocation")} items={GlobalStore.CL["patientLocations"]} />
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={4}>
							<XsDateTimePicker field={props.form.$("dateOfDelivery")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</div>
			</div>

			<XsConfirmationDialog
				name="xsDeleteMedicationDraft"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientPrescription.deleteConfirmationMessage" />}
				//onConfirmation={() => PatientPrescriptionStore.deleteDraft(this.props.form)}
				onConfirmation={() => {}}
			/>
			<XsConfirmationDialog
				name="xsDeleteMedicationOnCancel"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientPrescription.deletePrescriptionsOnCancel" />}
				//onConfirmation={() => PatientPrescriptionStore.deletePrescriptionsOnCancel(this.props.form)}
				onConfirmation={() => {}}
			/>
		</section>
	)
	// }
}

export default injectIntl(observer(PatientMedicationForm))
