import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import Grid from "@material-ui/core/Grid"

import {printReport} from "../../../../../global/helpers/actions"

import TextField from "@material-ui/core/TextField"
import XsButton from "../../../../../global/ui/xsButton/xsButton"

import Dialog from "@material-ui/core/Dialog"

import PatientDetailActionsStore from "../../../stores/PatientDetailActionsStore"
import PatientTemplatesDialogStore from "../../../stores/PatientTemplatesDialogStore"
import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"
import DataStore from "../../../stores/DataStore"

import "./patientActions.less"

@injectIntl
@observer
export default class PatientTemplatesDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	onBtnClick = (item) => {
		let printValues = PrintTemplatesStore.getCommonValues()

		if (
			item.id === "transport" ||
			item.id === "header" ||
			item.id === "requests_additional_healthcare" ||
			item.id === "preventive_examination_adults" ||
			item.id === "spa" ||
			item.id === "sickness_abs_temp_template" ||
			item.id === "dead_report" ||
			item.id === "angiologie_questionnaire" ||
			item.id === "medical_opinion" ||
			item.id === "doctors_opinion_guns" ||
			item.id === "medical_examination_driver_printable" ||
			item.id === "medical_examination_driver" ||
			item.id === "lab_Request" ||
			item.id === "assessment_social_service" ||
			item.id === "protocol_enteral_nutrition" ||
			item.id === "request_CT" ||
			item.id === "request_MR" ||
			item.id === "control_examination_adults" ||
			item.id === "agreement_health_care" ||
			item.id === "corona" ||
			item.id === "request_dispensary_care"
		) {
			PatientTemplatesDialogStore.close()
			PrintTemplatesStore.open(item.id, printValues)
		} else {
			printReport(item.id, printValues)
		}
	}

	render() {
		return (
			<Dialog
				fullScreen
				id="xsPatientTemplatesDialog"
				open={PatientTemplatesDialogStore.isOpen}
				onClose={() => PatientTemplatesDialogStore.close()}
				disableBackdropClick={true}
				className="xs-actions-modal"
				PaperProps={{
					style: {backgroundColor: "transparent", border: "none"}
				}}
			>
				<div className="xs-actions-body xs-column xs-flex">
					<Grid container className="mb-3 header">
						<Grid container item xs={10} alignItems="center">
							<Grid item xs={4} container direction="column">
								<Grid item className="xs-title">
									<FormattedMessage id="Common.label.chooseTemplate" />
								</Grid>
								<Grid item>
									<XsButton
										className="xs-back-btn"
										icon={<i className="fal fa-chevron-left fa-lg" />}
										text={<FormattedMessage id="Common.label.backToAction" />}
										onClick={() => {
											PatientTemplatesDialogStore.close()
											PatientDetailActionsStore.open()
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={6} container>
								<Grid item xs={9}>
									<TextField
										placeholder={`${this.props.intl.formatMessage({
											id: "Common.label.find"
										})}...`}
										value={PrintTemplatesStore.filterValue}
										onChange={(e) => {
											PrintTemplatesStore.filterValue = e.target.value
											PrintTemplatesStore.filteredPrintTemplates(e.target.value)
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={2} container justify="flex-end">
							<Grid item>
								<XsButton
									className="xs-close-btn"
									icon={<i className="fal fa-times fa-lg" />}
									text={<FormattedMessage id="Common.form.closeWindow" />}
									onClick={() => PatientTemplatesDialogStore.close()}
								/>
							</Grid>
						</Grid>
					</Grid>
					<section>
						<Grid container spacing={8}>
							{isSafe(PrintTemplatesStore.filteredTemplates) &&
								PrintTemplatesStore.filteredTemplates.length > 0 &&
								PrintTemplatesStore.filteredTemplates.map((template, idx) => {
									return (
										<Grid item xs={12} md={6} lg={4} key={idx}>
											<XsButton
												className="xs-action xs-templatebutton"
												text={<FormattedMessage id={`${template.name.props.id}`} />}
												icon={<i className="fal fa-file-invoice fa-lg" />}
												onClick={() => {
													if (template.id == "lab_Request" && DataStore.patientDTO.get("insurerCodeExt") == "24") {
														// RequestsSVLZInsurerStore.open("lab_Request")
														RequestsSVLZInsurerStore.dajZiadankySVLZPoistenca(this.props.patientId, "lab_Request")
														PatientTemplatesDialogStore.close()
													} else {
														this.onBtnClick(template)
													}
												}}
											/>
										</Grid>
									)
								})}
						</Grid>
					</section>
				</div>
			</Dialog>
		)
	}
}
