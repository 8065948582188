"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../../global/ui/globalUISchemeBindings"

// import UI controls here
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import api from "../../../../actions/api"
import AddIcon from "@material-ui/icons/Add"

// definitions of form fields
import fields from "./invoiceFormFields"

import {FormattedMessage, injectIntl} from "react-intl"
import UIStore from "../../../../stores/UIStore"
import GlobalStore from "../../../../../../global/store/GlobalStore"
import InvoiceListStore from "../../../../stores/InvoiceListStore"
import InvoiceFormStore from "../../../../stores/InvoiceFormStore"

import SaveIcon from "@material-ui/icons/Done"
import PrintIcon from "@material-ui/icons/Print"
import CancelIcon from "@material-ui/icons/Clear"
import TrashIcon from "@material-ui/icons/DeleteForever"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../../global/ui/xsLoading/xsLoading"
import XsChipForm from "../../../../../../global/ui/xsChipForm/xsChipForm"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import {ixsIsObject, ixsIsEmpty, ixsIsFunction, ixsIsNull} from "../../../../../../global/helpers/functions"
import {withStyles} from "@material-ui/core/styles"

const styles = () => ({
	alignRight: {
		textAlign: "right"
	},
	verticalSpace: {
		marginTop: "30px"
	},
	padRight: {
		paddingRight: "10px !important"
	},
	padLeft: {
		paddingLeft: "10px !important"
	}
})

@observer
export class InvoiceForm extends React.Component {
	constructor(props) {
		super(props)

		//Load dodávateľov
		InvoiceFormStore.loadCustomers()

		//Predplnená faktúra
		// const newData = InvoiceFormStore.newInvoiceData
		// const invoice_number = newData && newData.invoice_number ? newData.invoice_number : null

		const hooks = {
			onSubmit(form) {
				//Overenie či je vyplnená aspoň jedna položka faktúry
				if (!InvoiceFormStore.itemsChipsForm.form.isValid) {
					form.invalidate()
				} else if (
					InvoiceFormStore.itemsChipsForm &&
					Object.keys(InvoiceFormStore.itemsChipsForm.getData()).length === 0
				) {
					InvoiceFormStore.set("itemsChipsError", props.intl.formatMessage({id: "Invoice.form.minInvoiceItems"}))
					form.invalidate()
				} else {
					UIStore.isFormSaving = true
				}

				if (!form.isValid) {
					GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
				}
			},
			onSuccess(form) {
				if (InvoiceFormStore.itemsChipsForm.form.isValid) {
					InvoiceFormStore.saveForm(form)
				} else {
					UIStore.isFormSaving = false
				}
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		GlobalStore.refreshCodeLists(["invoiceType", "currencyUnit"])
		this.form = new MobxReactForm(fields.load(props.invoiceID), {plugins: {dvr: validatorjs}, hooks, bindings})

		//Load číslo faktúry ak je new!
		//EMA-9279 rušíme generovanie
		/*if (!props.invoiceID && !invoice_number) {
			InvoiceFormStore.getInvoiceNumber(() => this.setVariableSymbol(this.form))
    }*/
	}

	/**
	 * Set Variable and Specific symbol according to invoice number
	 * Only on new
	 */
	setVariableAndSpecificSymbol = (field) => {
		if (
			field &&
			field.value &&
			ixsIsEmpty(this.form.$("variable_symbol").get("value")) &&
			ixsIsEmpty(this.form.$("specific_symbol").get("value"))
		) {
			const invNum = field.value.replace(/\D/g, "")
			this.form.$("variable_symbol").set("value", invNum)
			this.form.$("specific_symbol").set("value", invNum)
		}
	}

	componentWillUnmount() {
		//Ak máme callback tak ho zavoláme
		if (InvoiceFormStore.onUnmount && ixsIsFunction(InvoiceFormStore.onUnmount)) {
			InvoiceFormStore.onUnmount(this.form)
		}

		//Vyresetujeme store
		InvoiceFormStore.reset()
	}

	initNewLoad = (invData) => {
		const fieldsObj = fields.load().fields

		//Základné údaje
		Object.keys(invData).forEach((key) => {
			if (fieldsObj.hasOwnProperty(key)) {
				this.form.$(key).set("value", invData[key])
			}
		})

		//Items
		if (invData.items) {
			const items = invData.items
			InvoiceFormStore.set("itemsChips", items)
			if (isSafe(InvoiceFormStore.itemsChipsForm)) {
				InvoiceFormStore.itemsChipsForm.bindData(items)
			}
			this.setTotalCost()
		}

		//Ak máme odberateľa a chceme triggernúť onChange
		if (invData.customer && invData.customer._id && invData._triggerCustomOnChange) {
			InvoiceFormStore.loadInvoiceTemplate(invData.customer)
		}
	}

	showSaveCustomerButton = () => {
		return (
			isNotEmpty(this.form.$("customer_dataName").value) &&
			isNotEmpty(this.form.$("customer_dataIdentifier").value) &&
			isNotEmpty(this.form.$("customer_dataTax_identification_number").value)
		)
	}

	initLoad = () => {
		if (!InvoiceFormStore.invoiceLoaded) {
			api
				.loadInvoice(this.props.invoiceID)
				.call()
				.then((inv) => {
					this.form.$("_id").set("value", this.props.invoiceID)
					const fieldsObj = fields.load().fields
					Object.keys(fieldsObj).forEach((key) => {
						if (inv[key]) {
							let newValue = ixsIsObject(inv[key]) ? inv[key]._id : inv[key]
							if (!["invoice_number", "variable_symbol", "constant_symbol", "specific_symbol"].includes(key)) {
								newValue = isNaN(newValue) ? newValue : parseFloat(newValue)
							}
							this.form.$(key).set("value", newValue.toString())
						}
					})

					//Customer data values
					if (inv.customer_data) {
						this.form.$("customer_dataName").set("value", inv.customer_data.name)
						this.form.$("customer_dataBank_account_plain").set("value", inv.customer_data.bank_account_plain)
						this.form.$("customer_dataCompany_description").set("value", inv.customer_data.company_description)
						this.form.$("customer_dataIdentifier").set("value", inv.customer_data.identifier)
						this.form
							.$("customer_dataTax_identification_number")
							.set("value", inv.customer_data.tax_identification_number)
						this.form
							.$("customer_dataVat_identification_number")
							.set("value", inv.customer_data.vat_identification_number)
					}

					//Customer address values
					if (inv.customer_address) {
						this.form.$("customer_addressStreet_plain").set("value", inv.customer_address.street_plain)
						this.form.$("customer_addressHouse_number").set("value", inv.customer_address.house_number)
						this.form.$("customer_addressZip_plain").set("value", inv.customer_address.zip_plain)
						this.form.$("customer_addressCity_plain").set("value", inv.customer_address.city_plain)
					}

					//Supplier data values
					if (inv.supplier_data) {
						this.form.$("supplier_dataName").set("value", inv.supplier_data.name)
						this.form.$("supplier_dataBank_account_plain").set("value", inv.supplier_data.bank_account_plain)
						this.form.$("supplier_dataCompany_description").set("value", inv.supplier_data.company_description)
						this.form.$("supplier_dataIdentifier").set("value", inv.supplier_data.identifier)
						this.form
							.$("supplier_dataTax_identification_number")
							.set("value", inv.supplier_data.tax_identification_number)
						this.form
							.$("supplier_dataVat_identification_number")
							.set("value", inv.supplier_data.vat_identification_number)
					}

					//Supplier address values
					if (inv.supplier_address) {
						this.form.$("supplier_addressStreet_plain").set("value", inv.supplier_address.street_plain)
						this.form.$("supplier_addressHouse_number").set("value", inv.supplier_address.house_number)
						this.form.$("supplier_addressZip_plain").set("value", inv.supplier_address.zip_plain)
						this.form.$("supplier_addressCity_plain").set("value", inv.supplier_address.city_plain)
					}

					//Items
					const items = inv.items
						.sort((a, b) => a.item_order - b.item_order)
						.map((i) => {
							return {
								itemName: isSafe(i.description) ? i.description : "",
								itemQuantity: isSafe(i.quantity) ? i.quantity : "",
								itemUnitPrice: isSafe(i.unit_price) ? i.unit_price : "",
								itemPrice: isSafe(i.price) ? i.price : ""
							}
						})

					InvoiceFormStore.set("itemsChips", items)
					if (isSafe(InvoiceFormStore.itemsChipsForm)) {
						InvoiceFormStore.itemsChipsForm.bindData(items)
					}
				})
		}
	}

	validateItemsChips = () => {
		let showError = false
		if (InvoiceFormStore.itemsChipsForm) {
			const values = InvoiceFormStore.itemsChipsForm.form.values()
			if (values.rows.length < 1) {
				// values.rows.map(row => {
				//   const quantity = parseFloat(row.itemQuantity);
				//   const price = parseFloat(row.itemUnitPrice);
				//   const roundTotalPrice = this.roundTotalCost(quantity * price);
				//   const name = row.itemName;
				//   if (roundTotalPrice === 0 || ixsIsEmpty(name)) {
				//     showError = true;
				//   }
				// });
				showError = true
			}
		} else {
			showError = true
		}
		InvoiceFormStore.set(
			"itemsChipsError",
			showError ? this.props.intl.formatMessage({id: "Invoice.form.minInvoiceItems"}) : null
		)
	}

	/**
	 * Načítame customer šablónu do formulára
	 */
	loadInvoiceTemplate = () => {
		const template = InvoiceFormStore.customerTemplate
		if (template) {
			Object.keys(template).forEach((key) => {
				if (
					!["_id", "_ref", "_type", "payment_method", "org_unit", "customer"].includes(key) &&
					template[key] !== null
				) {
					let newValue = ixsIsObject(template[key]) ? template[key]._id : template[key]
					if (!["invoice_number", "variable_symbol", "constant_symbol", "specific_symbol"].includes(key)) {
						newValue = isNaN(newValue) ? newValue : parseFloat(newValue)
					}
					if (this.form.has(key)) {
						this.form.$(key).set("value", newValue.toString())
					}
				}
			})
		}
	}

	setItemTotalPrice = () => {
		if (InvoiceFormStore.itemsChipsForm) {
			const values = InvoiceFormStore.itemsChipsForm.form.values()
			InvoiceFormStore.itemsChipsForm.form.$("rows").map((field) => {
				let row = field.values()
				// values.rows.map((row, idx) => {
				const quantity = parseFloat(row.itemQuantity)
				const unitprice = parseFloat(row.itemUnitPrice)
				const roundTotalPrice = !isNaN(quantity) && !isNaN(unitprice) ? this.roundTotalCost(quantity * unitprice) : null
				const rowFields = InvoiceFormStore.itemsChipsForm.returnFormData(field.key)

				if (
					rowFields &&
					rowFields.has("itemPrice") &&
					!isNaN(roundTotalPrice) &&
					!ixsIsEmpty(roundTotalPrice) &&
					!ixsIsNull(roundTotalPrice)
				) {
					row.itemPrice = roundTotalPrice
					rowFields.$("itemPrice").resetValidation()
					rowFields.$("itemPrice").set("value", roundTotalPrice)
				}
			})
			this.setTotalCost()
			InvoiceFormStore.itemsChips = values
		}
		this.validateItemsChips()
	}

	disableInputs = (field, idx) => {
		const rowFields = InvoiceFormStore.itemsChipsForm.returnFormData(idx)
		//Test pre disabled
		if (rowFields && rowFields.values()) {
			const rowValues = rowFields.values()
			const itemPrice = rowValues.itemPrice
			const itemQuantity = rowValues.itemQuantity
			const itemUnitPrice = rowValues.itemUnitPrice

			if (
				(field === "itemQuantity" || field === "itemUnitPrice") &&
				(!ixsIsEmpty(itemQuantity) || !ixsIsEmpty(itemUnitPrice))
			) {
				rowFields.$("itemPrice").clear()
				if (ixsIsEmpty(rowFields.$("itemPrice").get("value"))) {
					rowFields.$("itemPrice").invalidate()
				}
				rowFields.$("itemPrice").set("disabled", true)
			}

			if (
				(field === "itemQuantity" || field === "itemUnitPrice") &&
				ixsIsEmpty(itemQuantity) &&
				ixsIsEmpty(itemUnitPrice)
			) {
				rowFields.$("itemPrice").set("disabled", false)
			}

			if (field === "itemPrice") {
				if (ixsIsEmpty(itemPrice)) {
					rowFields.$("itemQuantity").set("disabled", false)
					rowFields.$("itemUnitPrice").set("disabled", false)
				} else {
					rowFields.$("itemQuantity").set("disabled", true)
					rowFields.$("itemUnitPrice").set("disabled", true)
				}
			}
		}
	}

	setTotalCost = () => {
		let newTotalCost = 0
		if (InvoiceFormStore.itemsChipsForm) {
			const values = InvoiceFormStore.itemsChipsForm.form.values()
			values.rows.map((row) => {
				const roundTotalPrice = this.roundTotalCost(row.itemPrice)
				if (!isNaN(roundTotalPrice)) {
					newTotalCost += parseFloat(roundTotalPrice)
				}
			})
		}
		this.form.$("total_cost").set("value", this.roundTotalCost(newTotalCost))
	}

	roundTotalCost = (number) => {
		return number === 0 || isNaN(number) ? 0 : parseFloat(number).toFixed(2)
	}

	customerChange = (value) => {
		const selectedCustomer = InvoiceFormStore.customers.find((c) => c._id === value)
		if (selectedCustomer) {
			// Customer info
			let name = selectedCustomer.name_ext
			let identifier = ""
			let tax_identification_number = ""
			let vat_identification_number = ""
			let street = ""
			let house_number = ""
			let zip_plain = ""
			let city_plain = ""
			let description = ""
			let iban = ""
			let address = null

			// Entity
			if (selectedCustomer.entity) {
				identifier = selectedCustomer.entity.identifier
				tax_identification_number = selectedCustomer.entity.tax_identification_number
				vat_identification_number = selectedCustomer.entity.vat_identification_number
				description = selectedCustomer.entity.description
			}

			//Invoice type address (BA) or primary address
			if (selectedCustomer.addresses) {
				address = selectedCustomer.addresses.find((a) => a.active && a.type && a.type.code === "BA")
				if (!address) {
					address = selectedCustomer.addresses.find((a) => a.active && a.primary)
				}

				if (address) {
					street = address.street_plain
					house_number = address.house_number
					zip_plain = address.zip_plain
					city_plain = address.city_plain
				}
			}

			//Invoice bank accounts
			if (selectedCustomer.accounts && selectedCustomer.accounts.length > 0) {
				const bankAccount = selectedCustomer.accounts.find((a) => a.active && a.primary)
				if (bankAccount) {
					iban = bankAccount.number
				}
			}

			this.form.$("customer_dataName").set("value", name)
			this.form.$("customer_dataIdentifier").set("value", identifier)
			this.form.$("customer_dataTax_identification_number").set("value", tax_identification_number)
			this.form.$("customer_dataVat_identification_number").set("value", vat_identification_number)
			this.form.$("customer_addressStreet_plain").set("value", street)
			this.form.$("customer_addressHouse_number").set("value", house_number)
			this.form.$("customer_addressZip_plain").set("value", zip_plain)
			this.form.$("customer_addressCity_plain").set("value", city_plain)
			this.form.$("customer_dataBank_account_plain").set("value", iban)
			this.form.$("customer_dataCompany_description").set("value", description)

			//Load Template
			InvoiceFormStore.loadInvoiceTemplate(selectedCustomer)
		}
	}

	render() {
		const customers = InvoiceFormStore.customers
		const currencies = GlobalStore.CL["currencyUnit"]
		const invTypes = GlobalStore.CL["invoiceType"]
		const isReadOnly = Boolean(this.props.invoiceID)

		//Ak máme číslo faktúry tak robíme load
		if (isReadOnly) {
			this.initLoad()
			InvoiceFormStore.set("invoiceLoaded", true)
		}

		//Ak ide o novú tak nastavíme vygenerované číslo faktúry
		// if (ixsIsEmpty(this.form.$("invoice_number").get("value"))) {
		// 	this.form.$("invoice_number").set("value", InvoiceFormStore.invoiceNumber)
		// }

		return (
			<Paper elevation={0} className="pa-4">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container direction="row">
					<Grid item xs={12}>
						<Grid container direction="row" spacing={8}>
							<Grid item xs={4}>
								<XsInput
									field={this.form.$("invoice_number")}
									disabled={isReadOnly}
									onBlur={this.setVariableAndSpecificSymbol}
								/>
							</Grid>
							<Grid item xs={4}>
								<SearchSelect field={this.form.$("customer")} items={customers} onChange={this.customerChange} />
							</Grid>
							<Grid item xs={4}>
								<SearchSelect field={this.form.$("type")} items={invTypes} />
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={8}>
							<Grid item xs={3}>
								<SearchSelect field={this.form.$("currency_unit")} items={currencies} />
							</Grid>
							<Grid item xs={3}>
								<XsDateTimePicker field={this.form.$("issue_date")} />
							</Grid>
							<Grid item xs={3}>
								<XsDateTimePicker field={this.form.$("due_date")} />
							</Grid>
							<Grid item xs={3}>
								<XsDateTimePicker field={this.form.$("delivery_date")} />
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={8}>
							<Grid item xs={3}>
								<XsInput field={this.form.$("variable_symbol")} />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("constant_symbol")} />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("specific_symbol")} />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("period")} />
							</Grid>
						</Grid>

						<Grid container direction="row" spacing={8} className={this.props.classes.verticalSpace}>
							<Grid item xs={6} className={this.props.classes.padRight}>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={12}>
										<FormattedMessage id="Invoice.form.customer" />
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_dataName")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_dataIdentifier")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_dataTax_identification_number")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_dataVat_identification_number")} />
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_addressCity_plain")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_addressStreet_plain")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_addressHouse_number")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_addressZip_plain")} />
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={6}>
											<XsInput field={this.form.$("customer_dataBank_account_plain")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("customer_dataCompany_description")} />
										</Grid>
										<Grid item xs={3}>
											<Grid container direction="row-reverse" spacing={8}>
												{isEmpty(this.form.$("customer").value) && (
													<XsIconButton
														rect
														icon={<i className="fal fa-save fa-lg white" />}
														className={`${this.showSaveCustomerButton() ? "xs-success" : "xs-default"}  mt-4`}
														tooltip={<FormattedMessage id="Invoice.form.savePartner" />}
														onClick={() => {
															InvoiceFormStore.saveCustomer(this.form)
														}}
														disabled={!this.showSaveCustomerButton()}
													/>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6} className={this.props.classes.padLeft}>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={12}>
										<FormattedMessage id="Invoice.form.supplier" />
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_dataName")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_dataIdentifier")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_dataTax_identification_number")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_dataVat_identification_number")} />
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_addressCity_plain")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_addressStreet_plain")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_addressHouse_number")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput field={this.form.$("supplier_addressZip_plain")} />
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={8}>
										<Grid item xs={6}>
											<XsInput field={this.form.$("supplier_dataBank_account_plain")} />
										</Grid>
										<Grid item xs={6}>
											<XsInput field={this.form.$("supplier_dataCompany_description")} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container direction="row" className={this.props.classes.verticalSpace}>
							<Grid item xs={12}>
								<XsChipForm
									ref={(chipForm) => {
										InvoiceFormStore.itemsChipsForm = chipForm
										//Predplnenie údajov ak sú dostupné
										if (!InvoiceFormStore.invoiceNewLoaded && InvoiceFormStore.newInvoiceData) {
											this.initNewLoad(InvoiceFormStore.newInvoiceData)
											InvoiceFormStore.set("invoiceNewLoaded", true)
										}
									}}
									label={this.props.intl.formatMessage({id: "Invoice.form.items"})}
									configuration={{
										header: {
											chipsRenderer: () => null,
											addButtonRenderer: (clickHandler) => (
												<XsIconButton
													className="xs-default xs-outline"
													icon={<AddIcon />}
													onClick={clickHandler}
													id={"invoiceItem"}
												/>
											)
										},
										columns: [
											{
												name: "itemName",
												label: <FormattedMessage id="Invoice.form.item" />,
												width: 4,
												validationRule: "required|string",
												renderer: (field) => <XsInput field={field} onBlur={this.validateItemsChips} required />
											},
											{
												name: "itemQuantity",
												label: <FormattedMessage id="Patient.form.patientPrescription.packs" />,
												width: 2,
												validationRule: "numeric|min:1",
												renderer: (field, idx, fieldKey) => (
													<XsInput
														field={field}
														onBlur={() => {
															this.disableInputs("itemQuantity", fieldKey)
															this.setItemTotalPrice()
														}}
														type={"number"}
													/>
												)
											},
											{
												name: "itemUnitPrice",
												label: <FormattedMessage id="Delivery.List.UnitPrice" />,
												width: 2,
												validationRule: "numeric",
												renderer: (field, idx, fieldKey) => (
													<XsInput
														field={field}
														onBlur={() => {
															this.disableInputs("itemUnitPrice", fieldKey)
															this.setItemTotalPrice()
														}}
														type={"number"}
													/>
												)
											},
											{
												name: "itemPrice",
												label: <FormattedMessage id="Delivery.List.TotalItemPrice" />,
												width: 2,
												validationRule: "required|numeric",
												renderer: (field, idx, fieldKey) => (
													<XsInput
														field={field}
														onBlur={() => {
															this.disableInputs("itemPrice", fieldKey)
															this.setItemTotalPrice()
														}}
														type={"number"}
													/>
												)
											}
										],
										options: {
											formOnly: true,
											isFormMode: true,
											isModalMode: false,
											hideButtons: true,
											onRemoveRow: this.setTotalCost,
											onFormOpen: () => {},
											onFormClose: () => {},
											onFormSave: (values) => {
												InvoiceFormStore.itemsChips = values
											}
										},
										data: InvoiceFormStore.itemsChips
									}}
								/>
							</Grid>
							{InvoiceFormStore.itemsChipsError && (
								<div className="xs-error-text">{InvoiceFormStore.itemsChipsError}</div>
							)}
						</Grid>

						<Grid container direction="row" spacing={16} className={this.props.classes.verticalSpace}>
							<Grid item xs={12}>
								<XsTextArea field={this.form.$("description")} hideToolbar={true} className="xs-height-200" />
							</Grid>
						</Grid>

						<Grid container direction="row" className={this.props.classes.alignRight} spacing={16}>
							<Grid item xs={12}>
								<h3>
									<FormattedMessage id="Invoice.form.totalcost" />: <b>{this.form.$("total_cost").value}</b> &euro;
								</h3>
							</Grid>
						</Grid>

						<Grid container direction="row">
							<Grid item xs={12}>
								<React.Fragment>
									<XsButton
										className="xs-success mr-3"
										text={<FormattedMessage id="Common.label.save" />}
										type="submit"
										onClick={(e) => {
											this.form.$("print").value = false
											this.form.onSubmit(e)
										}}
										icon={<SaveIcon />}
									/>
									<XsButton
										className="xs-success xs-outline mr-3"
										text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
										onClick={(e) => {
											this.form.$("print").value = true
											this.form.onSubmit(e)
										}}
										icon={<PrintIcon />}
									/>
								</React.Fragment>
								{isReadOnly && (
									<React.Fragment>
										<XsButton
											className="xs-success xs-outline mr-3"
											text={<FormattedMessage id="Patient.payDept.dialog.print" />}
											onClick={() => {
												InvoiceFormStore.printInvoice(this.props.invoiceID)
											}}
											icon={<PrintIcon />}
										/>
										<XsButton
											className="xs-danger xs-outline mr-3"
											text={<FormattedMessage id="Common.label.delete" />}
											onClick={() => {
												GlobalStore.openConfirmationDialog("xsDeleteInvoice", {invoiceID: this.props.invoiceID})
											}}
											icon={<TrashIcon />}
										/>
									</React.Fragment>
								)}
								<XsButton
									className="xs-danger xs-outline mr-3"
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										InvoiceListStore.set("showForm", false)
										InvoiceListStore.set("selectedInvoiceID", null)
										if (isNotEmpty(UIStore.previousReportingZSDetailTab)) {
											UIStore.reportingZSDetailTab = UIStore.previousReportingZSDetailTab
											UIStore.previousReportingZSDetailTab = ""
										}
									}}
									icon={<CancelIcon />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<XsConfirmationDialog
					name="xsDeleteInvoice"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Invoice.form.deleteConfirmationMessage" />}
					onConfirmation={() => InvoiceListStore.deleteInvoice(this.props.invoiceID)}
				/>
				<XsConfirmationDialog
					name="xsLoadTemplate"
					title={<FormattedMessage id="Common.label.info" />}
					text={<FormattedMessage id="Invoice.form.loadTemplateConfirmationMessage" />}
					onConfirmation={() => this.loadInvoiceTemplate()}
				/>
			</Paper>
		)
	}
}

export default injectIntl(withStyles(styles)(InvoiceForm))
