import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import AdminStore from "../../../stores/AdminStore"
import UIStore from "../../../stores/UIStore"
import RouterStore from "../../../../../global/store/RouterStore"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import MobxReactForm from "mobx-react-form"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import validatorjs from "validatorjs"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class UsersList extends React.Component {
	constructor(props) {
		super(props)

		const filterHooks = {
			onSubmit() {},
			onSuccess(form) {
				AdminStore.loadUsers(form)
			},
			onError() {}
		}

		this.filterForm = new MobxReactForm(
			{
				fields: {
					login: {
						label: <FormattedMessage id="Common.label.name" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks: filterHooks, bindings}
		)

		AdminStore.loadUsers(this.filterForm)
		AdminStore.userInfo = {}
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/user?id=${dataRow.userId}`)
		AdminStore.userInfo.full_name = dataRow.full_name
	}

	handleAddClick = () => {
		RouterStore.push(`/user?id=new`)
	}

	render() {
		AdminStore.isChangedFilter(this.filterForm.values())
		return (
			<Grid container>
				<Grid item container alignItems="center" spacing={8}>
					<Grid item xs={2}>
						<XsInput white field={this.filterForm.$("login")} />
					</Grid>
					<Grid item xs className="pb-0">
						<XsIconButton
							rect
							className="xs-default xs-outline"
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							onClick={(e) => {
								this.filterForm.onSubmit(e)
							}}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
					<Grid item>
						<XsButton
							className="xs-primary addUser"
							text={<FormattedMessage id="Admin.users.addUser" />}
							type="submit"
							onClick={this.handleAddClick}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : AdminStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 xs-pointer" onClick={this.filterForm.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									full_name: {
										title: <FormattedMessage id="AttendeeListContainer.nameCol" />,
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									},
									login: {
										title: "Login",
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											full_name: isSafe(dataRow.entity) ? dataRow.entity.person_display : "",
											login: isSafe(dataRow.user) ? dataRow.user.login : "",
											userId: isSafe(dataRow.user) ? dataRow.user._id : ""
										}
									}
								},
								dataSource: AdminStore.users
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
