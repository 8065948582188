import React from "react"
import {observer} from "mobx-react"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import moment from "moment"

import {FormattedMessage} from "react-intl"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import XsTable from "../../../../../global/ui/xsTable/xsTable"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"

import fields from "./userFormFields"
import bindings from "./userFormBindings"
import {insertFormDataByScheme, loadDataToFormByScheme} from "../../../../../global/helpers/bindings"
import RouterStore from "../../../../../global/store/RouterStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {injectIntl} from "react-intl"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import UsersStore from "../../../stores/UsersStore"
import UIStore from "../../../../ambulance/stores/UIStore.js"
import AddIcon from "@material-ui/icons/Add"
import XsDropDownButton from "../../../../../global/ui/xsDropDownButton/xsDropDownButton"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"

@injectIntl
@observer
export default class userForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (form.$("password").value != form.$("password2").value) {
					form.$("password").value = form.$("password2").value = ""
					GlobalStore.setNotificationMessage("Admin.users.wrongPass")
					form.invalidate()
				}
			},
			onSuccess(form) {
				insertFormDataByScheme(form, bindings, {userId: props.id != "new" ? props.id : ""}, () => {}, false)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		if (isNotEmpty(props.id) && props.id != "new") loadDataToFormByScheme(this.form, bindings, {userId: props.id})
		UsersStore.loadRolesForUser(props.id)
		UsersStore.loadRoles()

		UsersStore.showResourceForRoleForm = false
		this.checkedRolesIds = []
	}

	handleCancel = () => {
		RouterStore.push(`/users`)
	}

	tabContent = () => {
		const actions = [
			{
				label: <FormattedMessage id="Common.label.addResources" />,
				icon: <AddIcon />,
				onButtonClick: () => {
					UsersStore.showResourceForRoleForm = true
				}
			},
			{
				label: <FormattedMessage id="Common.label.removeResources" />,
				icon: <AddIcon />,
				onButtonClick: () => {
					UsersStore.assignRoleToUser(this.checkedRolesIds, this.props.id, true)
				}
			}
		]
		return (
			<React.Fragment>
				{!UsersStore.showResourceForRoleForm && (
					<Grid container direction="row-reverse" spacing={8}>
						<Grid item xs={2}>
							<XsDropDownButton buttons={actions} className="pullRight" />
						</Grid>
					</Grid>
				)}
				{!UsersStore.showResourceForRoleForm ? (
					<XsTable
						config={{
							columnDefs: {
								name_ext: {
									title: <FormattedMessage id="Common.label.name" />,
									type: "string",
									sortable: true,
									design: {
										width: "50%"
									}
								},
								description: {
									title: <FormattedMessage id="Common.label.description" />,
									type: "string",
									sortable: true,
									design: {
										width: "50%"
									}
								}
							},
							options: {
								// showCursor: true,
								// selectRow: true,
								checkboxes: true,
								checkboxColumn: "_id",
								checkAllDataColumn: "role_id",
								onClickCheckbox: (data) => {
									this.checkedRolesIds = data
								},
								onRowClick: () => {
									// this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										name_ext: dataRow.name,
										description: dataRow.description,
										action: dataRow.name,
										_id: dataRow.role_id
									}
								}
							},
							dataSource: UsersStore.userRoles
						}}
					/>
				) : (
					<Grid container direction="row" spacing={8}>
						<Grid item xs={12}>
							<XsButton
								className="xs-success pullRight"
								text={<FormattedMessage id="Common.label.save" />}
								icon={<SaveIcon />}
								onClick={() => {
									UIStore.isFormSaving = true
									UsersStore.assignRoleToUser(this.checkedRolesIds, this.props.id)
								}}
							/>
							<XsButton
								className="xs-danger pullRight mr10"
								text={<FormattedMessage id="Common.label.cancel" />}
								icon={<CancelIcon />}
								onClick={() => {
									UsersStore.showResourceForRoleForm = false
									// this.form.reset()
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTable
								config={{
									columnDefs: {
										name_ext: {
											title: <FormattedMessage id="Common.label.name" />,
											type: "string",
											sortable: true,
											design: {
												width: "50%"
											}
										},
										description: {
											title: <FormattedMessage id="Common.label.description" />,
											type: "string",
											sortable: true,
											design: {
												width: "50%"
											}
										}
									},
									options: {
										// showCursor: true,
										checkboxes: true,
										checkboxColumn: "_id",
										checkAllDataColumn: "role_id",
										onClickCheckbox: (data) => {
											this.checkedRolesIds = data
										},
										// selectRow: true,
										// onRowClick: (dataRow) => {},
										mapper: (dataRow) => {
											logger("DATAROW", dataRow)
											return {
												name_ext: dataRow.name,
												description: dataRow.description,
												_id: dataRow.role_id
											}
										}
									},
									dataSource: UsersStore.rolesList
								}}
							/>
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		)
	}

	render() {
		logger("aaaa", UsersStore.userRoles)
		return (
			<div className="userForm">
				<Paper style={{padding: "20px"}}>
					<Grid container direction="row">
						<Grid container direction="row-reverse">
							<XsButton
								className="xs-success ml-3"
								text={<FormattedMessage id="Common.label.save" />}
								type="submit"
								onClick={this.form.onSubmit}
								icon={<SaveIcon />}
							/>
							<XsButton
								className="xs-danger xs-outline"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									this.form.reset()
									this.handleCancel()
								}}
								icon={<CancelIcon />}
							/>
						</Grid>
						<Grid container direction="row" spacing={8}>
							<Grid item xs={3}>
								<XsInput field={this.form.$("login")} />
							</Grid>
							<Grid item xs={2}>
								<XsInput field={this.form.$("password")} type="password" />
							</Grid>
							<Grid item xs={2}>
								<XsInput field={this.form.$("password2")} type="password" />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("email")} />
							</Grid>
							<Grid item xs={1}>
								<XsCheckbox field={this.form.$("enabled")} />
							</Grid>
							<Grid item xs={1}>
								<XsCheckbox field={this.form.$("confirmed")} />
							</Grid>
						</Grid>
					</Grid>
				</Paper>

				<Grid container direction="row" align="left" spacing={8}>
					<Grid item xs={12}>
						<div className="rolesForUser">
							<XsTabsIcon
								value={"UserRoles"}
								onChangeTab={() => {}}
								mainClass="xs-patient-card-tab"
								background="white"
								tabs={[
									{
										label: this.props.intl.formatMessage({id: "Common.label.listOfRolesForUser"}),
										icon: <i className="fas fa-users fa-2x" />,
										value: "UserRoles",
										content: this.tabContent()
									}
								]}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}
}
