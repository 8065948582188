import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import DDaction from "../../../actions/patientCard"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import VaccAllergyDialogStore from "../../../stores/VaccAllergyDialogStore"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

@observer
export default class VaccAllergyDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["vaccinationSymptoms"])
	}

	render() {
		const providerID = getSelectedOrgUnitID()

		return (
			<Dialog
				id="xsVaccAllergyDialog"
				open={VaccAllergyDialogStore.isOpen}
				onClose={() => VaccAllergyDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				classes={{
					paper: "xs-paper-dialog"
				}}
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-danger">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-syringe fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.vaccAllergy" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{maxWidth: 450}}>
					<SearchSelect
						label={<FormattedMessage id="Common.label.vaccAllergy" />}
						items={GlobalStore.CL["vaccinationSymptoms"]}
						value={VaccAllergyDialogStore.vaccAllergy}
						getOptionLabel={(obj) => obj.name_ext}
						getOptionValue={(obj) => obj._id}
						isMulti={true}
						onChange={(item) => {
							VaccAllergyDialogStore.onHandleChange(item)
						}}
					/>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => VaccAllergyDialogStore.close()}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-syringe" />}
						text={<FormattedMessage id="Common.label.save" />}
						onClick={() => {
							VaccAllergyDialogStore.save()
							DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								DataStore.setPatientEHR(overview)
							})
						}}
						disabled={isNotSafe(VaccAllergyDialogStore.vaccAllergy) || VaccAllergyDialogStore.vaccAllergy.length == 0}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
