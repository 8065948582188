"use strict"

// default imports for all farms
import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
// import MobxReactForm from "mobx-react-form"
// import validatorjs from "validatorjs"
import Chip from "@material-ui/core/Chip"
import moment from "moment"

// special import for Material-UI binding
// import bindings from "../../../../../global/ui/globalUISchemeBindings"

// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import Grid from "@material-ui/core/Grid"
import api from "../../../actions/api"
import AddIcon from "@material-ui/icons/Add"
import CancelIcon from "@material-ui/icons/Clear"

// definitions of form fields
// import fields from "./patientRequestFields"

import {FormattedMessage, injectIntl} from "react-intl"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import PatientRequestStore from "../../../stores/PatientRequestStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import ShowSelectedDekurzDialogStore from "../../../stores/ShowSelectedDekurzDialogStore"
import ShowSelectedDekurzDialog from "./showSelectedDekurzDialog"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {withStyles} from "@material-ui/core/styles"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import TemplateForm from "../patientRecordForm/templateForm"
import XsSearchDropDownAction from "../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import {handleInsertHelpersIntoTextArea} from "../../../../../global/helpers/actions"
// import {SaveableForm} from "../saveableForm/saveableForm"
// import {formDataStateCL} from "../../../../../global/config/codeLists"

const styles = () => ({
	title: {
		marginBottom: "10px",
		fontWeight: 300,
		fontSize: "20px"
	},
	marginBottomRightSide: {
		marginBottom: "20px"
	},
	chipStyle: {
		height: "auto",
		minHeight: "32px",
		whiteSpace: "normal"
	},
	chipLabel: {
		whiteSpace: "normal"
	}
})

// @observer
// export class  extends SaveableForm {
function PatientRequestForm(props) {
	// constructor(props) {
	// 	super(props)
	// 	props.formName = "PatientRequestForm"
	// 	DataStore.loadTemplates("REQ")
	// 	PatientRequestStore.setter("todayRecordsChecked", false)

	// 	const hooks = {
	// 		onSubmit() {},
	// 		onSuccess() {},
	// 		onError() {}
	// 	}

	// 	GlobalStore.refreshCodeLists(["diagnoseTypes", "priorities", "professionalActivity"])
	// 	props.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	// 	props.getFormHandler(props.keyVal, props.form)

	// 	// Znovupredpisat
	// 	if (isSafe(props.requestId) && isNotEmpty(props.requestId)) {
	// 		initLoad(props.form, props.requestId)
	// 	} else {
	// 		if (!props.canceledSessionLoad) {
	// 			loadFormFromSession(
	// 				PatientRequestStore,
	// 				props.keyVal,
	// 				isNotSafe(props.multiLoad) || DataStore.checkFormDataState(props.formName, formDataStateCL.MODIFIED),
	// 				props.dataFromDataStore,
	// 				props.exact
	// 			)
	// 			if (isSafe(props.multiLoad) && DataStore.checkFormDataState(props.formName, formDataStateCL.MODIFIED)) {
	// 				props.multiLoad()
	// 			}
	// 		}

	// 		//Ak máme naviazaný dekurz predplníme diagnózu z neho
	// 		if (PatientRecordStore.record && PatientRecordStore.record.diagnosis) {
	// 			props.form.$("diagnosis").value = PatientRecordStore.record.diagnosis
	// 		} else {
	// 			//Získame dg. z dnešného dekurzu
	// 			if (DataStore.patientDTO) {
	// 				const lastRecord = DataStore.patientDTO.get("lastRecord")
	// 				if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
	// 					props.form.$("diagnosis").value = lastRecord.diagnosis
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// componentWillUnmount() {
	// 	PatientRequestStore.reset()
	// 	PatientRecordStore.reset()
	// }

	//Ak máme načítaný CL professionalActivity a nepredpisujeme znovu žiadanku, tak defaultne naplníme z číselníka code_ext 2 hodnotu (mala by to byť ambulancia)
	// setProfessionalActivity = () => {
	// 	if (!this.props.requestId && this.form) {
	// 		if (GlobalStore.CL["professionalActivity"] && !PatientRequestStore.profActivitySet) {
	// 			const ambPA = GlobalStore.CL["professionalActivity"].find((pa) => pa.code_ext === "2")
	// 			if (ambPA) {
	// 				this.form.$("professional_activity").set("value", ambPA._id)
	// 			}
	// 			PatientRequestStore.profActivitySet = true
	// 		}
	// 	}
	// }

	// componentWillUnmount() {
	// 	props.form.reset()
	// 	PatientRequestStore.requestId = null
	// }

	// initLoadRecord = (form) => {
	// 	PatientRequestStore.setter("recordLoaded", true)
	// 	if (PatientRecordStore.record.diagnosis) {
	// 		form.$("diagnosis").value = PatientRecordStore.record.diagnosis
	// 	}
	// }

	// render() {
	// logger("ZIADANKA STATE")
	//Znovu predpísať žiadanku
	// if (PatientRequestStore.represcribeID !== null && !PatientRequestStore.represcribeLoaded) {
	// 	initLoad(props.form, PatientRequestStore.represcribeID)
	// }
	//Žiadanka ku dekurzu
	// if (PatientRecordStore.record && !PatientRequestStore.recordLoaded) {
	// 	initLoadRecord(props.form)
	// }

	let [personAna, setPersonAna] = useState(null)
	let [socialAna, setSocialAna] = useState(null)
	let [obstetricAna, setObstetricAna] = useState(null)

	useEffect(() => {
		// if (
		// 	isSafe(DataStore.patientDTO) &&
		// 	isSafe(DataStore.patientDTO.get("patientEHR")) &&
		// 	isSafe(DataStore.patientDTO.get("patientEHR").rows)
		// ) {
		// 	const anamnesis = DataStore.patientDTO
		// 		.get("patientEHR")
		// 		.rows.filter((record) => record.type_code.toUpperCase() === "ANA")

		// 	if (isSafe(anamnesis) && isSafe(anamnesis[0])) {
		// 		let tempPersonAna = anamnesis[0].data.find((i) => i.type._id == "ANA")
		// 		let tempSocialAna = anamnesis[0].data.find((i) => i.type._id == "ANASOCIAL")
		// 		let tempObstetricAna = anamnesis[0].data.find((i) => i.type._id == "ANAOBSTETRIC")
		// 		if (tempPersonAna) {
		// 			setPersonAna(htmlToPlain(tempPersonAna.content))
		// 		}
		// 		if (tempSocialAna) {
		// 			setSocialAna(htmlToPlain(tempSocialAna.content))
		// 		}
		// 		if (tempObstetricAna) {
		// 			setObstetricAna(htmlToPlain(tempObstetricAna.content))
		// 		}
		// 	}
		// }

		if (isSafe(props.options) && isSafe(props.options.anamnesisForm)) {
			let anamnesisForm = props.options.anamnesisForm

			setPersonAna(htmlToPlain(anamnesisForm.$("anamnesis").value))
			setSocialAna(htmlToPlain(anamnesisForm.$("socialAnamnesis").value))
			setObstetricAna(htmlToPlain(anamnesisForm.$("deliveryAnamnesis").value))
		}
	})

	// if (
	// 	isSafe(DataStore.patientDTO) &&
	// 	isSafe(DataStore.patientDTO.get("patientEHR")) &&
	// 	isSafe(DataStore.patientDTO.get("patientEHR").rows)
	// ) {
	// 	const anamnesis = DataStore.patientDTO
	// 		.get("patientEHR")
	// 		.rows.filter((record) => record.type_code.toUpperCase() === "ANA")

	// 	if (isSafe(anamnesis) && isSafe(anamnesis[0])) {
	// 		personAna = anamnesis[0].data.find((i) => i.type._id == "ANA")
	// 		socialAna = anamnesis[0].data.find((i) => i.type._id == "ANASOCIAL")
	// 		obstetricAna = anamnesis[0].data.find((i) => i.type._id == "ANAOBSTETRIC")
	// 		if (personAna) {
	// 			personAna = htmlToPlain(personAna.content)
	// 		}
	// 		if (socialAna) {
	// 			socialAna = htmlToPlain(socialAna.content)
	// 		}
	// 		if (obstetricAna) {
	// 			obstetricAna = htmlToPlain(obstetricAna.content)
	// 		}
	// 	}
	// }

	const dropdownRecordTemplates =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["REQ"]) &&
		isSafe(DataStore.templatesAll["REQ"].records) &&
		DataStore.templatesAll["REQ"].records.length > 0
			? DataStore.templatesAll["REQ"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r, null, props.keyVal)
						}
					})
			: []

	const profActivity = GlobalStore.CL["professionalActivity"]
	const dgTypes = GlobalStore.CL["diagnoseTypes"]
	const reqTypes = PatientRequestStore.getTypeData([
		<FormattedMessage key="0" id="Patient.form.patientrequest.requesttype.specialist" />,
		<FormattedMessage key="1" id="Patient.form.patientrequest.requesttype.radiology" />
	])

	// if (PatientRequestStore.represcribeFlag) {
	// 	DataStore.setFormDataState(props.formName, formDataStateCL.MODIFIED)
	// } else if (DataStore.getFormDataState(props.formName) != formDataStateCL.MODIFIED) {
	// 	DataStore.setFormDataState(props.formName, formDataStateCL.WITHDEFS)
	// }

	return (
		<section className="xs-patient-card-form xs-column">
			{UIStore.isFormSaving && <XsLoading overlay={true} />}
			{/* {((DataStore.savedFormObject &&
					(DataStore.checkFormDataState(props.formName, formDataStateCL.WITHDEFS) ||
						DataStore.checkFormDataState(props.formName, formDataStateCL.EMPTY))) ||
					GlobalStore.confirmationDialogOpened == props.formName) &&
					renderConfirmDialog(() => DataStore.setFormDataState(props.formName, formDataStateCL.MODIFIED), () => {})} */}

			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left xs-flex">
					<Grid container spacing={8}>
						<Grid item xs={4}>
							<SearchSelect field={props.form.$("request_type")} items={reqTypes} />
						</Grid>
						<Grid item xs={4}>
							<XsAutocomplete
								field={props.form.$("expertise")}
								minLengthForSearch="1"
								clearText
								api={api.loadExpertise}
								apiCallType="Pr"
								autoFocus={true}
								ref={(field) => (GlobalStore.defaultFocusFields["patientRequestForm"] = field)}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								postAction={(value) => {
									props.form.$("expertise_code").value = value.code
								}}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: (
													<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
												),
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px",
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												}
											},
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												dbName: "search_column",
												design: {
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<SearchSelect field={props.form.$("priority")} items={GlobalStore.CL["priorities"]} />
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={props.form.$("diagnosis")}
								minLengthForSearch="1"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								specialDataFilter={(data) =>
									data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
								}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
												// sortable: true
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											paging: {
												rowsPerPage: 100,
												rowsPerPageOptions: [5, 10, 25, 50, 100]
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<SearchSelect
								field={props.form.$("diagnosis_type")}
								items={dgTypes && dgTypes.filter((dg) => dg.code === "PRIMARY")}
							/>
						</Grid>
						<Grid item xs={4}>
							{props.form.$("showSecondaryDiagnosis").value == false && (
								<XsIconButton
									className="xs-success xs-outline addSecondaryDiagnosis"
									icon={<AddIcon />}
									onClick={() => {
										props.form.$("showSecondaryDiagnosis").value = !props.form.$("showSecondaryDiagnosis").value
									}}
								/>
							)}
						</Grid>
						{props.form.$("showSecondaryDiagnosis").value && (
							<React.Fragment>
								<Grid item xs={4}>
									<XsAutocompleteLocal
										field={props.form.$("diagnosis_secondary")}
										minLengthForSearch="1"
										clearText
										data={RegisterCacheStore.diagnosisRegister}
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj.code}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px"
														},
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 9,
															defaultValue: textValue,
															renderElement: "input"
														}
														// sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<SearchSelect
										field={props.form.$("diagnosis_type_secondary")}
										items={dgTypes && dgTypes.filter((dg) => dg.code === "SECONDARY")}
									/>
								</Grid>
								<Grid item xs={4}>
									{props.form.$("showSecondaryDiagnosis").value && (
										<XsIconButton
											className="xs-danger xs-outline addSecondaryDiagnosis"
											icon={<CancelIcon />}
											onClick={() => {
												props.form.$("diagnosis_secondary").value = ""
												props.form.$("showSecondaryDiagnosis").value = !props.form.$("showSecondaryDiagnosis").value
											}}
										/>
									)}
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={4}>
							<XsInput
								/*autoFocus={true} ref={field => GlobalStore.defaultFocusFields["patientRequestForm"] = field }*/ field={props.form.$(
									"clarification"
								)}
							/>
						</Grid>
						{props.form.$("request_type").value === "EHR.Data.Specialist.Request" && (
							<Grid item xs={6}>
								<SearchSelect field={props.form.$("professional_activity")} items={profActivity} />
							</Grid>
						)}
						{props.form.$("request_type").value === "EHR.Data.Radiology.Request" && (
							<Grid container direction="row" spacing={8}>
								<Grid item xs={4}>
									<XsInput field={props.form.$("modality")} />
								</Grid>
								<Grid item xs={4}>
									<XsInput field={props.form.$("organ_to_exam")} />
								</Grid>
								<Grid item xs={4}>
									<XsCheckbox field={props.form.$("sedation_needed")} />
								</Grid>
							</Grid>
						)}
						<Grid item xs={12}>
							<XsTextArea
								keyVal={props.keyVal}
								field={props.form.$("request")}
								maxLength={6000}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("request").value) &&
														PatientRequestStore.textAreaCursors.request
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"request",
															PatientRequestStore.textAreaCursors.request.cursor,
															data.itemData
														)
													} else {
														props.form.$("request").value += data.itemData
													}
													delete PatientRequestStore.textAreaCursors.request
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.templateDataKeyVal = props.keyVal
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientRequestStore.textAreaCursors.request) ||
											PatientRequestStore.textAreaCursors.request.cursor != data.cursor)
									) {
										PatientRequestStore.textAreaCursors.request = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								keyVal={props.keyVal}
								field={props.form.$("patientnote")}
								maxLength={2000}
								className="xs-height-100"
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													if (
														isNotEmpty(props.form.$("patientnote").value) &&
														PatientRequestStore.textAreaCursors.patientnote
													) {
														handleInsertHelpersIntoTextArea(
															props.form,
															"patientnote",
															PatientRequestStore.textAreaCursors.patientnote.cursor,
															data.itemData
														)
													} else {
														props.form.$("patientnote").value += data.itemData
													}
													delete PatientRequestStore.textAreaCursors.patientnote
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.templateDataKeyVal = props.keyVal
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PatientRequestStore.textAreaCursors.patientnote) ||
											PatientRequestStore.textAreaCursors.patientnote.cursor != data.cursor)
									) {
										PatientRequestStore.textAreaCursors.patientnote = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<TemplateForm type="REQ" keyVal={props.keyVal} />
					</Grid>
				</div>
				<div className="xs-patient-form-right">
					<Grid item xs={12} className={props.classes.marginBottomRightSide}>
						{PatientRecordStore.record && (
							<React.Fragment>
								<div className={props.classes.title}>
									<FormattedMessage id="Patient.form.patientrequest.parentrecord" />
								</div>
								<Chip
									label={`${moment(PatientRecordStore.record.created_at).format(
										props.intl.formatMessage({id: "Application.moment.datetimeformat"})
									)} ${PatientRecordStore.record.name}`}
									onDelete={() => PatientRecordStore.loadRecord(null)}
									color="primary"
									classes={{
										root: props.classes.chipStyle,
										label: props.classes.chipLabel
									}}
								/>
							</React.Fragment>
						)}
						<div className="mt15">
							<div className={props.classes.title}>
								<FormattedMessage id="Common.label.copy" />
							</div>
							<XsButton
								className="xs-default xs-outline mt10"
								text={<FormattedMessage id="Common.label.subjectiveDifficulties" />}
								onClick={() => {
									let of = ""

									if (isSafe(PatientRecordStore.record) && isSafe(PatientRecordStore.record.data)) {
										if (isSafe(PatientRecordStore.record.data.find((x) => x.type._id === "OF"))) {
											of = PatientRecordStore.record.data.find((x) => x.type._id === "OF").content
										}
									} else {
										if (
											isSafe(props.options) &&
											isSafe(props.options.recordForm) &&
											isSafe(props.options.recordForm.$("OF"))
										) {
											of = props.options.recordForm.$("OF").value
										}
									}

									props.form.$("request").value += of
								}}
							/>
							<XsButton
								className="xs-default xs-outline mt10"
								text={<FormattedMessage id="Common.label.objectiveFinding" />}
								onClick={() => {
									let amb = ""
									if (isSafe(PatientRecordStore.record) && isSafe(PatientRecordStore.record.data)) {
										if (isSafe(PatientRecordStore.record.data.find((x) => x.type._id === "AMB"))) {
											amb = PatientRecordStore.record.data.find((x) => x.type._id === "AMB").content
										}
									} else {
										if (
											isSafe(props.options) &&
											isSafe(props.options.recordForm) &&
											isSafe(props.options.recordForm.$("record"))
										) {
											amb = props.options.recordForm.$("record").value
										}
									}

									props.form.$("request").value += amb
								}}
							/>
							<XsButton
								className="xs-default xs-outline mt10"
								text={<FormattedMessage id="Common.label.diagnosisSummary" />}
								onClick={() => {
									let dc = ""
									if (isSafe(PatientRecordStore.record) && isSafe(PatientRecordStore.record.data)) {
										if (isSafe(PatientRecordStore.record.data.find((x) => x.type._id === "DC"))) {
											dc = PatientRecordStore.record.data.find((x) => x.type._id === "DC").content
										}
									} else {
										if (
											isSafe(props.options) &&
											isSafe(props.options.recordForm) &&
											isSafe(props.options.recordForm.$("diagnosisSummary"))
										) {
											dc = props.options.recordForm.$("diagnosisSummary").value
										}
									}

									props.form.$("request").value += dc
								}}
							/>
							{isNotEmpty(personAna) && (
								<XsButton
									className="xs-default xs-outline mt10"
									text={<FormattedMessage id="Common.label.personalAnamnesis" />}
									onClick={() => {
										props.form.$("request").value += personAna
									}}
								/>
							)}
							{isNotEmpty(socialAna) && (
								<XsButton
									className="xs-default xs-outline mt10"
									text={<FormattedMessage id="Common.label.socialAnamnesis" />}
									onClick={() => {
										props.form.$("request").value += socialAna
									}}
								/>
							)}
							{isNotEmpty(obstetricAna) && (
								<XsButton
									className="xs-default xs-outline mt10"
									text={<FormattedMessage id="Common.label.obstetricAnamnesis" />}
									onClick={() => {
										props.form.$("request").value += obstetricAna
									}}
								/>
							)}
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={props.classes.title}>
							<FormattedMessage id="Patient.form.patientrequest.linkedrecords" />
						</div>
						<XsButton
							className="xs-default xs-outline"
							size="small"
							text={<FormattedMessage id="Patient.form.patientinfo.addchecked" />}
							onClick={() => {
								PatientRequestStore.addSelectedEHR(
									props.intl.formatMessage({id: "Patient.form.patientRequest.selectedrecordwarning"})
								)
							}}
							icon={<AddIcon />}
						/>
						{PatientRequestStore.selectedEHR &&
							PatientRequestStore.selectedEHR.map((checked, ix) => {
								if (checked.type_code === "DEK") {
									return (
										<div key={ix}>
											<Chip
												label={`${moment(checked.created_at).format(
													props.intl.formatMessage({id: "Application.moment.datetimeformat"})
												)} ${checked.name}`}
												onDelete={() => PatientRequestStore.removeSelectedEHR(ix)}
												color="primary"
												onClick={() => ShowSelectedDekurzDialogStore.open(checked.record_id)}
												classes={{
													root: props.classes.chipStyle,
													label: props.classes.chipLabel
												}}
											/>
										</div>
									)
								}
							})}
					</Grid>
				</div>
			</div>
			<ShowSelectedDekurzDialog keyVal={props.keyVal} />
		</section>
	)
	// }
}

export default injectIntl(withStyles(styles)(observer(PatientRequestForm)))
