import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"

import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import UIStore from "../../../stores/UIStore"
import fields from "./EHGWUploadFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import GlobalStore from "../../../../../global/store/GlobalStore"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import EHGWUploadDialogStore from "../../../stores/EHGWUploadDialogStore"
import Dropzone from "react-dropzone"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import {Grid} from "@material-ui/core"
import XsInput from "../../../../../global/ui/xsInput/xsInput"

@observer
export default class EHGWUploadDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				EHGWUploadDialogStore.save(form)
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	handleDrop = (files) => {
		EHGWUploadDialogStore.dropedFiles.push(...files)
		logger("uploadol si:", files)
		this.form.$("file_name").set(files[0].name)
	}

	handleDropzoneDelete = (index) => {
		EHGWUploadDialogStore.dropedFiles.splice(index, 1)
	}

	form

	render() {
		return (
			<Dialog
				id="xsEHGWUploadDialog"
				open={EHGWUploadDialogStore.isOpen}
				onClose={() => {
					EHGWUploadDialogStore.close()
					this.form.reset()
				}}
				disableBackdropClick={true}
				maxWidth="md"
				classes={{
					paper: "xs-paper-dialog"
				}}
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-file-upload fa-2x" />
						</div>
						<div className="xs-header-title">Upload novej verzie</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{maxWidth: 450}}>
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid container spacing={8}>
								<Grid item xs={3}>
									<XsInput field={this.form.$("version")} />
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("sub_version_1")} />
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("sub_version_2")} />
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("sub_version_3")} />
								</Grid>

								<Grid item xs={12}>
									<Dropzone onDrop={this.handleDrop} id="dropzone">
										{isSafe(EHGWUploadDialogStore.dropedFiles) && EHGWUploadDialogStore.dropedFiles.length ? (
											EHGWUploadDialogStore.dropedFiles.map((file, index) => (
												<div key={index} className={"dropedFile"}>
													<i
														className="fal fa-file icon"
														onClick={(e) => {
															e.stopPropagation()
															// this.handleDropzonePreview(index)
														}}
													/>
													<i
														className="far fa-trash-alt deleteIcon"
														onClick={(e) => {
															e.stopPropagation()
															GlobalStore.openConfirmationDialog("xsDeleteEHGWAttachmentFromDropzone", {
																fileIndex: index
															})
														}}
													/>
													<br />
													<div
														className={"mac_ellipsis"}
														data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
														data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
													/>
												</div>
											))
										) : (
											<div className={"dropzonePlaceholder"}>
												<FormattedMessage id="Common.form.attachment" />
											</div>
										)}
									</Dropzone>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => EHGWUploadDialogStore.close()}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-file-upload" />}
						text={<FormattedMessage id="Common.label.save" />}
						onClick={this.form.onSubmit}
						disabled={!this.form.isValid}
					/>
				</DialogActions>
				<XsConfirmationDialog
					name="xsDeleteEHGWAttachmentFromDropzone"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
					onConfirmation={() => this.handleDropzoneDelete(GlobalStore.confirmationDialogParams.fileIndex)}
				/>
			</Dialog>
		)
	}
}
