import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../global/ui/globalUISchemeBindings"
import fields from "../patientDetail/patientInfo/patientInfoFields"
import {patientDialogBinding, portfolioBinding} from "../patientDetail/patientInfo/patientInfoBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import {insertFormDataByScheme} from "../../../../global/helpers/bindings"
import {getSelectedOrgUnitID} from "../../../../global/helpers/actions"

import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../global/ui/xsInput/xsAutocomplete"
import DuplicityPersonModal from "../patientDetail/patientInfo/duplicityPersonModal"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import PatientCheckInsurerDialog from "../patientDetail/patientInfo/patientCheckInsurerDialog"

import NewPatientDialogStore from "../../stores/NewPatientDialogStore"
import SaveIcon from "@material-ui/icons/Done"
import {padStart} from "../../../../global/helpers/functions"

import DataStore from "../../stores/DataStore"
import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import RouterStore from "../../../../global/store/RouterStore"
import WarningStore from "../../../../global/store/WarningStore"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import DuplicityPersonModalStore from "../../stores/DuplicityPersonModalStore"
import PatientCheckInsurerDialogStore from "../../stores/PatientCheckInsurerDialogStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"

import api from "../../actions/api"

// const initFormData = (form, id) => {
// 	if (isSafe(id)) {
// 		loadDataToFormByScheme(form, patientDialogBinding, {personId: id}, (data) => {
// 			if (isSafe(data) && isSafe(data.specific_rels)) {
// 				let client = data.specific_rels.find((i) => i._type == "Entity.Data.Client")
// 				if (isSafe(client) && isSafe(client.addresses)) {
// 					let address = client.addresses.find((i) => i.active == true)
// 					if (isSafe(address)) {
// 						form.$("region").value = isSafe(address.region) ? address.region._id : ""
// 						form.$("county").value = isSafe(address.county) ? address.county._id : ""
// 						if (isSafe(address.obj_city)) {
// 							form.$("city").value = address.obj_city._id
// 						}
// 						if (isSafe(address.obj_zip)) {
// 							form.$("zip").value = address.obj_zip._id
// 						}
// 					}
// 				}
// 				DataStore.setPatientInfo(data)
// 			}
// 		})
// 	}
// }

@injectIntl
@observer
export default class NewPatientDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists([
			"endReason",
			"genders",
			"titles",
			"countries",
			"consentTypes",
			"contactTypes",
			"insuranceTypes",
			"relationshipType",
			"addressCounty",
			"addressRegion"
		])

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				form.$("region").value = form.$("region").value === "" ? null : form.$("region").value
				form.$("county").value = form.$("county").value === "" ? null : form.$("county").value
				form.$("city").value = form.$("city").value === "" ? null : form.$("city").value
				form.$("zip").value = form.$("zip").value === "" ? null : form.$("zip").value
				form.$("identifier").value = form.$("identifier").value.includes("/")
					? form.$("identifier").value.replace("/", "")
					: form.$("identifier").value

				if (DataStore.isCreatedFromEvent) {
					insertFormDataByScheme(
						form,
						patientDialogBinding,
						{
							city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
							region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
							county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
							zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
						},
						(response) => {
							insertFormDataByScheme(
								form,
								portfolioBinding,
								{
									providerId: getSelectedOrgUnitID(),
									clientId: response.specific_rels[0]._id
								},
								() => (
									RouterStore.push(`/patient?id=${response._id}`),
									EditEventDialogStore.updatedIdEvent(response),
									(DataStore.isCreatedFromEvent = false)
								),
								false,
								"Patient.form.patientinfo.saveMessage"
							)
							UIStore.isFormSaving = false
						}
					)
				} else {
					insertFormDataByScheme(
						form,
						patientDialogBinding,
						{
							city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
							region: isSafe(form.$("region").value) ? {_id: form.$("region").value} : null,
							county: isSafe(form.$("county").value) ? {_id: form.$("county").value} : null,
							zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
						},
						(response) => {
							insertFormDataByScheme(
								form,
								portfolioBinding,
								{
									providerId: getSelectedOrgUnitID(),
									clientId: response.specific_rels[0]._id
								},
								() => RouterStore.push(`/patient?id=${response._id}`),
								false,
								"Patient.form.patientinfo.saveMessage"
							)
							UIStore.isFormSaving = false
						}
					)
				}

				NewPatientDialogStore.close()
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		NewPatientDialogStore.newPatientFormRef = this.form
		// this.loadedData = initFormData(this.form, this.props.id)
	}

	form

	checkInsurance() {
		const insurer = RegistersCacheStore.insurersRegister.filter((x) => x._id == this.form.$("insurer").value)

		if (isSafe(insurer) && isSafe(insurer[0])) {
			const ZPCode = insurer[0].code_ext

			PatientCheckInsurerDialogStore.checkInsurance(ZPCode, this.form.$("identifier").value)
		}
	}

	changeIdentifier(field) {
		const identifier = field.value.includes("/") ? field.value.replace("/", "") : field.value
		if (!this.stopRefresh && field.changed && !this.form.$("dateOfBirth").changed) {
			if (isFinite(identifier)) {
				const idLength = identifier.length
				let isInvalidIdentifier = false

				if (!(idLength === 9 || idLength === 10)) {
					isInvalidIdentifier = true
				}

				if (idLength === 9 || idLength === 10) {
					const idfYear = +identifier.substr(0, 2)
					const actualYear = new Date()
						.getFullYear()
						.toString()
						.substr(2, 2)

					if (idLength === 10 && +identifier % 11 !== 0) {
						isInvalidIdentifier = true
					}

					const year = +idfYear + (+idfYear <= +actualYear && +idfYear < 54 && idLength === 10 ? 2000 : 1900)
					let month = +identifier.substr(2, 2) - (+identifier.substr(2, 2) > 12 ? 50 : 0)
					let day = +identifier.substr(4, 2)
					const gender = +identifier.substr(2, 2) > 12 ? "F" : "M"

					month = padStart(month, 2, "0")
					day = padStart(day, 2, "0")

					const testDate = new Date(`${year}-${month}-${day}`)

					if (testDate instanceof Date == false || isNaN(testDate) || testDate.getDate() !== +day) {
						WarningStore.open(
							`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
						)
					} else {
						this.form.$("dateOfBirth").set("value", `${year}-${month}-${day}`)
						this.form.$("gender").set("value", gender)
					}

					if (idLength === 9 && year > 1953) {
						isInvalidIdentifier = true
					}
				}

				// aj keby nebolo rodne cislo validne, aj tak urob kontrolu diplicity pacientov, lebo uz moze existovat
				let request = {
					filters: [
						{
							associated_column: "identifier",
							predicate: "=",
							values: [{id_value: identifier}]
						}
					]
				}

				api
					.getDuplicityPerson(request)
					.call()
					.then((response) => {
						if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
							DuplicityPersonModalStore.patientData = response.rows
							DuplicityPersonModalStore.open(this.form)
						}
					})
					.then(() => {
						if (isInvalidIdentifier)
							WarningStore.open(
								`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
							)
					})
					.catch(() => {
						if (isInvalidIdentifier)
							WarningStore.open(
								`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
							)
					})
			}
		}
	}

	render() {
		const sortedCountries = isSafe(GlobalStore.CL["countries"])
			? GlobalStore.CL["countries"].slice().sort((a, b) => a.item_order - b.item_order)
			: []

		return (
			<Dialog
				id="xsNewPatientDialog"
				open={NewPatientDialogStore.isOpen}
				onClose={() => {
					this.form.reset()
					NewPatientDialogStore.close()
				}}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								this.form.reset()
								NewPatientDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-user-plus fa-2x" />
						</div>
						<div className="xs-header-title">
							{isNotEmpty(DataStore.nameNewPatient) ? (
								<span>{DataStore.nameNewPatient}</span>
							) : (
								<FormattedMessage id="Patient.newPatient" />
							)}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<XsInput
										inputWhite={true}
										field={this.form.$("identifier")}
										onBlur={(field) => this.changeIdentifier(field)}
									/>
								</Grid>
								<Grid item xs={3}>
									<XsDateTimePicker
										className="xs-white-datetimepicker"
										field={this.form.$("dateOfBirth")}
										showTimeSelect={false}
									/>
								</Grid>
								<Grid item xs={3}>
									<SearchSelect
										inputWhite={true}
										field={this.form.$("gender")}
										items={GlobalStore.CL["genders"]}
										required
									/>
								</Grid>
								<Grid item xs={3} className="xs-row">
									<SearchSelect
										inputWhite={true}
										field={this.form.$("insurer")}
										items={RegistersCacheStore.insurersRegister}
										required
									/>
									<div data-tip data-for="checkInsurer" className="xs-row xs-align">
										<XsIconButton
											icon={<SaveIcon className="xs-check-icon" />}
											disabled={isEmpty(this.form.$("identifier").value) || isEmpty(this.form.$("insurer").value)}
											onClick={this.checkInsurance.bind(this)}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<SearchSelect
										field={this.form.$("title_before")}
										getOptionLabel={(row) => row.code_ext}
										items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => obj.before) : []}
									/>
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("firstname")} />
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("lastname")} />
								</Grid>
								<Grid item xs={3}>
									<SearchSelect
										field={this.form.$("title_after")}
										getOptionLabel={(row) => row.code_ext}
										items={
											isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => !obj.before) : []
										}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<SearchSelect field={this.form.$("country")} items={sortedCountries} required />
								</Grid>
								<Grid item xs={3}>
									<SearchSelect field={this.form.$("region")} items={GlobalStore.CL["addressRegion"]} />
								</Grid>
								<Grid item xs={3}>
									<SearchSelect field={this.form.$("county")} items={GlobalStore.CL["addressCounty"]} />
								</Grid>
								<Grid item xs={3}>
									<XsAutocomplete
										field={this.form.$("city")}
										minLengthForSearch="3"
										api={api.loadEntityCity}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										freeText={true}
										freeTextField={this.form.$("cityText")}
										postAction={(obj) => {
											this.form.$("cityText").set("value", obj.name_ext)
										}}
										onClear={() => {
											this.form.$("cityText").value = ""
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<XsInput field={this.form.$("street")} />
								</Grid>
								<Grid item xs={1}>
									<XsInput field={this.form.$("houseNumber")} />
								</Grid>
								<Grid item xs={3}>
									<XsAutocomplete
										field={this.form.$("zip")}
										minLengthForSearch="3"
										api={api.loadEntityZip}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										freeText={true}
										freeTextField={this.form.$("zipText")}
										postAction={(obj) => {
											this.form.$("zipText").value = obj.name_ext
										}}
										onClear={() => {
											this.form.$("zipText").value = ""
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => {
									this.form.reset()
									NewPatientDialogStore.close()
								}}
							/>
						</Grid>
						<Grid>
							<XsButton
								className="xs-outline xs-success"
								icon={<i className="fal fa-address-card"></i>}
								text={<FormattedMessage id="Common.label.loadDataFromNZIS" />}
								onClick={() => {
									let id = this.form.$("identifier").value
									if (isSafe(id) && isFinite(id) && (id.length === 9 || id.length === 10) && +id % 11 == 0) {
										NewPatientDialogStore.getPatientInfo(this.form.$("identifier").value)
									} else {
										GlobalStore.setNotificationMessage("Patient.form.patientinfo.invalidIdentifierWarning")
									}
								}}
							/>
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-user-plus" />}
								text={<FormattedMessage id="Common.btn.addPatient" />}
								onClick={(e) => {
									UIStore.isFormSaving = true
									this.form.onSubmit(e)
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
				<DuplicityPersonModal />
				<PatientCheckInsurerDialog />
			</Dialog>
		)
	}
}
