import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import settings from "../../../../../../global/config/settings"
import Barcode from "../../../../../../global/ui/xsBarcode/xsBarcode"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import UIStore from "../../../../stores/UIStore"
import moment from "moment"

import "./prescriptionRow.less"

const PrescriptionRow = observer(
	({
		diagnosisCode,
		medication,
		sendingDoctorName,
		substitutingDoctorCard,
		diagnosisName,
		atcCode,
		atcName,
		productCode,
		supplement,
		note,
		repeatsType,
		repeatsNumber,
		barcode,
		validityTo,
		intl,
		lifeThreatening,
		barcodeFormat
	}) => {
		const prescriptionValidTo = validityTo
			? moment(validityTo, settings.DB_DATETIME_FORMAT).format(
					intl.formatMessage({id: "Application.moment.datetimeformat"})
			  )
			: ""
		return (
			<XsExpansionPanel glOpen={UIStore.isOpenAllRecordsInEHROverview} maxHeight={20}>
				<div className="prescriptionRow">
					{supplement && <div className="subtitle">{supplement}</div>}
					{diagnosisCode && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
							</span>{" "}
							<b>
								{diagnosisCode} - {diagnosisName}{" "}
							</b>
							{lifeThreatening ? (
								<span>
									<i className="fas fa-exclamation-triangle xs-redDanger" data-tip data-for="lifethreading" />
									<ReactTooltip id="lifethreading">
										<FormattedMessage id="Common.label.lifethreading" />
									</ReactTooltip>
								</span>
							) : (
								""
							)}
						</div>
					)}
					{sendingDoctorName && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientPrescription.sendingDoctor" />:
							</span>{" "}
							<b>{sendingDoctorName}</b>
						</div>
					)}
					{substitutingDoctorCard && substitutingDoctorCard.full_name && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientrecord.substitutingDoctor" />:
							</span>{" "}
							<b>{substitutingDoctorCard.full_name}</b>
						</div>
					)}
					{(repeatsNumber || repeatsType) && (
						<div>
							<span className="title">
								<FormattedMessage id="PatientDetail.overview.prescription.repeats" />:
							</span>{" "}
							{repeatsNumber && <b>{repeatsNumber}</b>} / {repeatsType && <b>{repeatsType}</b>}
						</div>
					)}
					{atcCode && (
						<div>
							<span className="title">
								<FormattedMessage id="PatientDetail.overview.prescription.atc" />:
							</span>{" "}
							<b>{atcCode}</b> - {atcName}
						</div>
					)}
					{medication && medication.administered_at && (
						<div>
							<span className="title">
								<FormattedMessage id="Common.label.administeredAt" />:
							</span>{" "}
							<b>
								{moment(medication.administered_at, settings.DB_DATETIME_FORMAT).format(
									intl.formatMessage({id: "Application.moment.dateformat"})
								)}
							</b>
						</div>
					)}
					{productCode && (
						<div>
							<span className="title">
								<FormattedMessage id="PatientDetail.overview.prescription.sukl" />:
							</span>{" "}
							<b>{productCode}</b>
						</div>
					)}
					{prescriptionValidTo && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientPrescription.validityTo" />:
							</span>{" "}
							<b>{prescriptionValidTo}</b>
						</div>
					)}
					{medication && medication.expiration_date && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.Form.Medication.Expiration" />:
							</span>{" "}
							<b>{moment(medication.expiration_date, settings.DB_DATETIME_FORMAT).format("M/Y")}</b>
						</div>
					)}
					{medication && medication.batch_number && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.Form.Medication.BatchNumber" />:
							</span>{" "}
							<b>{medication.batch_number}</b>
						</div>
					)}
					{note && <div className="pr-note">{note}</div>}
					{barcode && (
						<div className="pr-barcode">
							<Barcode
								value={barcode}
								format={barcodeFormat ? barcodeFormat : "CODE128C"}
								ean128={true}
								displayValue={true}
								height={50}
							/>
						</div>
					)}
				</div>
			</XsExpansionPanel>
		)
	}
)

export default injectIntl(PrescriptionRow)
