import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./addDeliveryFields"
import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
// import {getUserCompanyInfo} from "../../../../../global/helpers/actions"

import AddDeliveryStore from "../../../stores/AddDeliveryStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import UIStore from "../../../stores/UIStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class AddDeliveryDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				AddDeliveryStore.saveDelivery(form)
			},
			onError() {
				GlobalStore.setNotificationMessage("Common.warning.message.emptyform")
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	close = () => {
		AddDeliveryStore.close()
		this.form.reset()
	}

	render() {
		if (isSafe(this.props.payerType)) {
			if (this.props.payerType == "Z") {
				this.form.$("payer_type").set("value", "Z")
			}
			if (this.props.payerType == "S") {
				this.form.$("payer_type").set("value", "S")
			}
		}

		return (
			<Dialog
				id="xsAddDeliveryDialog"
				open={AddDeliveryStore.isOpen}
				onClose={() => this.close()}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => this.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-notes-medical fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.addDelivery" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container className="xs-form" spacing={8}>
						<Grid item xs={12}>
							<XsAutocomplete
								field={this.form.$("patientId")}
								minLengthForSearch="1"
								clearText
								api={api.loadCompanyAutocompletePatients}
								addSpecialFilter={() => [
									{
										associated_column: "provider_id",
										predicate: "=",
										values: [
											{
												id_value: getSelectedOrgUnit()._id
											}
										]
									}
								]}
								searchFilter={"search_column"}
								inputRenderer={(obj) =>
									`${obj.last_name} ${obj.first_name} ${isNotEmpty(obj.identifier) ? `(${obj.identifier})` : ""}`
								}
								saveValue={(obj) => obj.client_id}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											first_name: {
												title: <FormattedMessage id="Common.label.firstName" />,
												design: {
													width: "30%"
												},
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												}
											},
											last_name: {
												title: <FormattedMessage id="Common.label.lastName" />,
												type: "string",
												dbName: "last_name",
												design: {
													width: "40%"
												}
											},
											identifier: {
												title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
												type: "string",
												dbName: "last_name",
												design: {
													width: "30%"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsAutocomplete
								field={this.form.$("delivery")}
								minLengthForSearch="1"
								clearText
								api={api.loadDeliveries}
								searchFilter={"code_ext"}
								// loadApi={api.loadSpecificDelivery}
								inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
												design: {
													width: "10%"
												},
												type: "string",
												dbName: "code_ext",
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
												type: "string",
												dbName: "search_column",
												design: {
													width: "90%"
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsAutocompleteLocal
								field={this.form.$("diagnosis")}
								minLengthForSearch="3"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("number")} min="1" type="number" />
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect
								required
								disabled={isSafe(this.props.payerType)}
								field={this.form.$("payer_type")}
								items={GlobalStore.CL["payerType"]}
								getOptionLabel={(row) => `${row.code_ext} - ${row.name_ext}`}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect
								field={this.form.$("delivery_extension")}
								tooltipProp="description"
								items={GlobalStore.CL["deliveryExtension"]}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect field={this.form.$("insuree_type")} items={GlobalStore.CL["insureeType"]} />
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect field={this.form.$("sender_type")} items={GlobalStore.CL["senderType"]} />
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect field={this.form.$("refund_type")} items={GlobalStore.CL["refundType"]} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("movement")} />
						</Grid>
						<Grid item xs={6}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => this.close()}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-plus" />}
						text={<FormattedMessage id="Common.label.addDelivery" />}
						onClick={(e) => {
							UIStore.isFormSaving = true
							this.form.onSubmit(e)
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
