import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import {Document, Page} from "react-pdf"
import fields from "./acceptOfTheTermFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Dialog, DialogContent, DialogTitle, DialogActions, Grid, FormControlLabel, Checkbox} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"

import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class AcceptOfTheTermDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			numPages: null,
			pageNumber: 1
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				logger("form: ", form)
				RequestsSVLZInsurerStore.dajPrihlasovacieUdaje(props.patientId, form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		RequestsSVLZInsurerStore.acceptFormRef = this.form
	}

	onDocumentLoadSuccess = ({numPages}) => {
		this.setState({numPages})
	}

	render() {
		return (
			<Dialog
				id="xsAcceptOfTheTermDialog"
				open={RequestsSVLZInsurerStore.isOpenAcceptOfTheTerm}
				onClose={() => RequestsSVLZInsurerStore.closeAcceptOfTheTerm()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch xs-width-paper-600"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-book-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.requestsSVLZInsurer" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						{isSafe(RequestsSVLZInsurerStore.acceptOfTheTermData) && (
							<React.Fragment>
								<Grid container item spacing={8}>
									<Grid item xs={6}>
										<XsInput disabled={true} field={this.form.$("ico")} />
									</Grid>
									<Grid item xs={6}>
										<XsInput disabled={true} field={this.form.$("name")} />
									</Grid>
								</Grid>
								<Grid container item spacing={8}>
									<Grid item xs={6}>
										<XsInput disabled={true} field={this.form.$("codeNZ")} />
									</Grid>
									<Grid item xs={6}>
										<XsInput disabled={true} field={this.form.$("doctorCode")} />
									</Grid>
								</Grid>
								<Grid container item>
									<Grid item xs={12}>
										<XsInput disabled={true} field={this.form.$("doctorName")} />
									</Grid>
								</Grid>
								<Grid container item>
									<Grid item xs={12}>
										<XsInput disabled={true} field={this.form.$("address")} />
									</Grid>
								</Grid>
								<Grid container item spacing={8}>
									<Grid item xs={6}>
										<XsInput field={this.form.$("mobile")} />
									</Grid>
									<Grid item xs={6}>
										<XsInput field={this.form.$("email")} />
									</Grid>
								</Grid>
								<Grid item container spacing={8}>
									<Grid item xs={6}>
										<FormControlLabel
											control={
												<Checkbox
													checked={RequestsSVLZInsurerStore.confirmTerm}
													onChange={(e) => (RequestsSVLZInsurerStore.confirmTerm = e.target.checked)}
												/>
											}
											label={<FormattedMessage id="Common.label.agreeWithTerm" />}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormControlLabel
											control={
												<Checkbox
													checked={RequestsSVLZInsurerStore.confirmRegistration}
													onChange={(e) => (RequestsSVLZInsurerStore.confirmRegistration = e.target.checked)}
												/>
											}
											label={<FormattedMessage id="Common.label.agreeWithRegistration" />}
										/>
									</Grid>
								</Grid>
								<Grid item className="pt-3">
									<FormattedMessage id="Common.label.termsFind" />
									<span
										className="xs-pointer blueDark"
										onClick={() => {
											RequestsSVLZInsurerStore.fileName = "Podmienky"
											RequestsSVLZInsurerStore.openShowPDF(
												RequestsSVLZInsurerStore.acceptOfTheTermData.podmienky,
												"Common.label.terms"
											)
										}}
									>
										tu!
									</span>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => RequestsSVLZInsurerStore.closeAcceptOfTheTerm()}
					/>
					<XsButton
						className={
							!RequestsSVLZInsurerStore.confirmTerm || !RequestsSVLZInsurerStore.confirmRegistration
								? "xs-default xs-outline"
								: "xs-info"
						}
						disabled={!RequestsSVLZInsurerStore.confirmTerm || !RequestsSVLZInsurerStore.confirmRegistration}
						icon={<i className="fal fa-book-alt" />}
						text={<FormattedMessage id="Common.label.agree" />}
						onClick={this.form.onSubmit}
						// onClick={() => {
						// 	RequestsSVLZInsurerStore.dajPrihlasovacieUdaje(this.props.patientId)
						// 	// RequestsSVLZInsurerStore.dajVysledkySVLZPoistenca(this.props.patientId)
						// 	RequestsSVLZInsurerStore.closeAcceptOfTheTerm()
						// }}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
