import React from "react"
import {observable, action} from "mobx"
import moment from "moment"
import {FormattedMessage} from "react-intl"
import {toLatin} from "../../../global/helpers/functions.js"

import {printReport, getUserCompanyInfo} from "../../../global/helpers/actions"
import api from "../actions/api"
import config from "../../../global/config/settings"
import {
	getSelectedOrgUnitID,
	getUserPersonnelID,
	getUserDoctorInfo,
	getSelectedOrgUnit
} from "../../../global/helpers/actions"
import DataStore from "./DataStore"
import DDaction from "../actions/patientCard"
import GlobalStore from "../../../global/store/GlobalStore"

import TransportPrintTemplate from "../components/patientDetail/printTemplates/templates/transportPrintTemplate"
import PrazdnyDekurzTemplate from "../components/patientDetail/printTemplates/templates/prazdnyDekurzTemplate"
import RequestsAdditionalHealthcareTemplate from "../components/patientDetail/printTemplates/templates/requestsAdditionalHealthcareTemplate"
import PreventiveExaminationAdultsTemplate from "../components/patientDetail/printTemplates/templates/preventiveExaminationAdultsTemplate"
import SpaTemplate from "../components/patientDetail/printTemplates/templates/spaTemplate"
import SicknessAbsTempTemplate from "../components/patientDetail/printTemplates/templates/sicknessAbsTempTemplate"
import DeadReportTemplate from "../components/patientDetail/printTemplates/templates/deadReportTemplate"
import AngiologieQuestionnaireTemplate from "../components/patientDetail/printTemplates/templates/angiologieQuestionnaireTemplate"
import MedicalOpinionTemplate from "../components/patientDetail/printTemplates/templates/medicalOpinionTemplate"
import MedicalOpinionWeaponTemplate from "../components/patientDetail/printTemplates/templates/medicalOpinionWeaponTemplate"
import MedicalExaminationDriverTemplate from "../components/patientDetail/printTemplates/templates/medicalExaminationDriverTemplate"
import LabRequestTemplate from "../components/patientDetail/printTemplates/templates/labRequestTemplate"
import MedicalExaminationDriverCardTemplate from "../components/patientDetail/printTemplates/templates/medicalExaminationDriverCardTemplate.js"
import AssessmentSocialServiceTemplate from "../components/patientDetail/printTemplates/templates/assessmentSocialServiceTemplate"
import ProtocolEnteralNutritionTemplate from "../components/patientDetail/printTemplates/templates/protocolEnteralNutritionTemplate"
import RequestCTTemplate from "../components/patientDetail/printTemplates/templates/requestCTTemplate"
import RequestMRTemplate from "../components/patientDetail/printTemplates/templates/requestMRTemplate"
import ControlExaminationAdultsTemplate from "../components/patientDetail/printTemplates/templates/controlExaminationAdultsTemplate"
import AgreementHealthCareTemplate from "../components/patientDetail/printTemplates/templates/agreementHealthCareTemplate"
import CoronaTemplate from "../components/patientDetail/printTemplates/templates/coronaTemplate"
import DispensaryCareTemplate from "../components/patientDetail/printTemplates/templates/dispensaryCareTemplate"

class PrintTemplatesStore {
	@observable isOpen = false
	templateId
	summariesId
	@observable templateContent
	templateForm
	templateFields
	templateValues
	title = ""
	editedtemplateId = null
	textAreaCursors = {}
	@observable isHeaderTemplateLoaded = false

	labTemplates = [
		{id: "alphamedical", paperTemplate: "lab_req_aplhaMedical", label: "ALPHA MEDICAL"},
		{id: "medirex", paperTemplate: "lab_req_medirex", label: "MEDIREX"},
		{id: "synlab", paperTemplate: "lab_req_synlab", label: "SYNLAB"},
		{id: "bpbmed", paperTemplate: "lab_req_BPB_MED", label: "BPB MED"}
	]

	@observable filteredTemplates = []
	@observable filterValue = ""

	@action filteredPrintTemplates(value = "") {
		if (value.length >= 3) {
			this.filteredTemplates = this.printTemplates.filter((temp) =>
				toLatin(temp.search_column.toLowerCase()).includes(toLatin(value.toLowerCase()))
			)
		} else {
			this.filteredTemplates = this.printTemplates
		}
	}

	printTemplates = [
		{
			id: "all_day_exam",
			name: <FormattedMessage id="PrintTemplate.AllDayExam.Title" />,
			search_column: "Celodenné ošetrenie"
		},
		{
			id: "all_day_exam_more",
			name: <FormattedMessage id="PrintTemplate.AllDayExamMore.Title" />,
			search_column: "Celodenné ošetrenie - bez údajov"
		},
		{id: "header", name: <FormattedMessage id="PrintTemplate.Header.Title" />, search_column: "Všeobecný dokument"},
		{id: "pass", name: <FormattedMessage id="PrintTemplate.Pass.Title" />, search_column: "Priepustka"},
		{
			id: "pass_more",
			name: <FormattedMessage id="PrintTemplate.PassMore.Title" />,
			search_column: "Priepustka - bez údajov"
		},
		{
			id: "confirmation",
			name: <FormattedMessage id="PrintTemplate.Confirmation.Title" />,
			search_column: "Potvrdenie o účasti na lekárskom ošetrení/vyšetrení"
		},
		{
			id: "confirmation_more",
			name: <FormattedMessage id="PrintTemplate.ConfirmationMore.Title" />,
			search_column: "Potvrdenie o účasti na lekárskom ošetrení/vyšetrení - bez údajov"
		},
		{
			id: "cash_receipt",
			name: <FormattedMessage id="PrintTemplate.CashReceipt.Title" />,
			search_column: "Potvrdenie o zaplatení"
		},
		{
			id: "cash_receipt_more",
			name: <FormattedMessage id="PrintTemplate.CashReceiptMore.Title" />,
			search_column: "Potvrdenie o zaplatení - bez údajov"
		},
		{
			id: "accident_report",
			name: <FormattedMessage id="PrintTemplate.AccidentReport.Title" />,
			search_column: "Hlásenie úrazu na účely nemocenského"
		},
		{
			id: "dead_report",
			name: <FormattedMessage id="PrintTemplate.DeadReport.Title" />,
			search_column: "List o prehliadke mŕtveho"
		},
		{
			id: "spa",
			name: <FormattedMessage id="PrintTemplate.Spa.Title" />,
			isPrintable: true,
			search_column: "Návrh na kúpeľnú liečbu"
		},
		{
			id: "waiver",
			name: <FormattedMessage id="PrintTemplate.Waiver.Title" />,
			search_column: "Odstúpenie od dohody o poskytovaní zdravotnej starostlivosti"
		},
		{
			id: "sickness_abs_temp_for_job_seeker",
			name: <FormattedMessage id="PrintTemplate.SicknessAbsTempForJobSeeker.Title" />,
			search_column: "Potvrdenie o dočasnej pracovnej neschopnosti občana/uchádzača o zamestnanie"
		},
		{
			id: "sickness_abs_temp",
			name: <FormattedMessage id="PrintTemplate.SicknessAbsTemp.Title" />,
			search_column: "Preukaz o trvaní dočasnej pracovnej neschopnosti"
		},
		{
			id: "sickness_abs_temp_template",
			name: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.Title" />,
			search_column: "Preukaz o trvaní dočasnej pracovnej neschopnosti - tlačivo"
		},
		{
			id: "transport",
			name: <FormattedMessage id="PrintTemplate.Transport.Title" />,
			search_column: "Príkaz na dopravu osoby zo zdravotných dôvodov"
		},
		{
			id: "requests_additional_healthcare",
			name: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.Title" />,
			search_column: "Žiadosť o ďalšie poskytovanie zdravotnej starostlivosti"
		},
		{
			id: "control_examination_adults_template",
			name: <FormattedMessage id="PrintTemplate.ControlExaminationAdults.Template.Title" />,
			search_column: "Prehliadka zisťovacia / kontrolná - tlačivo"
		},
		{
			id: "control_examination_adults",
			name: <FormattedMessage id="PrintTemplate.ControlExaminationAdults.Title" />,
			search_column: "Prehliadka zisťovacia / kontrolná",
			isPrintable: true
		},
		{
			id: "preventive_examination_adults",
			name: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.Title" />,
			search_column: "Preventívna prehliadka pre dospelých"
		},
		{
			id: "angiologie_questionnaire",
			name: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.Title" />,
			search_column: "Dotazník pre pacienta pred vyšetrením na Angiologickej ambulancii"
		},
		{
			id: "medical_opinion",
			name: <FormattedMessage id="PrintTemplate.MedicalOpinion.Title" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti na prácu"
		},
		{
			id: "doctors_opinion",
			name: <FormattedMessage id="PrintTemplate.DoctorsOpinion.Title" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti zamestnanca na prácu"
		},
		{
			id: "doctors_opinion_A",
			name: <FormattedMessage id="PrintTemplate.DoctorsOpinion.A" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti - Vzor A"
		},
		{
			id: "doctors_opinion_B",
			name: <FormattedMessage id="PrintTemplate.DoctorsOpinion.B" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti - Vzor B"
		},
		{
			id: "doctors_opinion_C",
			name: <FormattedMessage id="PrintTemplate.DoctorsOpinion.C" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti - Vzor C"
		},
		{
			id: "doctors_opinion_D",
			name: <FormattedMessage id="PrintTemplate.DoctorsOpinion.D" />,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti - Vzor D"
		},
		{
			id: "medical_examination_driver",
			name: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver" />,
			search_column: "Doklad o zdravotnej spôsobilosti vodiča - kartička"
		},
		{
			id: "medical_examination_driver_Sevt",
			name: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.Sevt" />,
			search_column: "Doklad o zdravotnej spôsobilosti vodiča - Ševt"
		},
		{
			id: "medical_examination_driver_verification",
			name: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.Verification" />,
			search_column: "Doklad o zdravotnej spôsobilosti žiadateľa o udelenie vodičského oprávnenia / vodiča"
		},
		{
			id: "statement_competence_guns",
			name: <FormattedMessage id="PrintTemplate.StatementCompetence.Guns" />,
			search_column: "Čestné vyhlásenie žiadateľa o vydanie zbrojného pasu"
		},
		{
			id: "statement_competence_motor_vehicle",
			name: <FormattedMessage id="PrintTemplate.StatementCompetence.MotorVehicle" />,
			search_column:
				"Čestné vyhlásenie posudzovanej osoby vo vztahu k jej zdravotnej spôsobilosti na vedenie motorových vozidiel"
		},
		{
			id: "statement_competence_motor_vehicle_diabetes",
			name: <FormattedMessage id="PrintTemplate.StatementCompetence.Diabetes" />,
			search_column:
				"Čestné vyhlásenie posudzovanej osoby vo vztahu k jej zdravotnej spôsobilosti na vedenie motorových vozidiel diabetes mellitus"
		},
		{
			id: "doctors_opinion_guns",
			name: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.Title" />,
			isPrintable: true,
			search_column: "Lekársky posudok o zdravotnej spôsobilosti na držanie alebo nosenie strelnej zbrane a streliva"
		},
		{
			id: "medical_examination_driver_printable",
			name: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.Title" />,
			isPrintable: true,
			search_column:
				"Doklad o zdravotnej spôsobilosti žiadateľa o udelenie vodičského oprávnenia / vodiča, ktorý sa podrobuje preskúmaniu zdravotnej spôsobilosti"
		},
		{
			id: "lab_Request",
			name: <FormattedMessage id="PrintTemplate.LabRequest.Title" />,
			isPrintable: true,
			hidePrint: true,
			search_column: "Laboratórne žiadanky (ALPHA MEDICAL, MEDIREX, SYNLAB, ...)"
		},
		{
			id: "assessment_social_service",
			name: <FormattedMessage id="PrintTemplate.AssessmentSocialService.Title" />,
			search_column: "Lekársky nález na účely posúdenia odkázanosti na sociálnu službu"
		},
		{
			id: "protocol_enteral_nutrition",
			name: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.Title" />,
			search_column: "Protokol iniciálnej indikácie ambulantnej enterálnej výživy"
		},
		{
			id: "agreement_health_care",
			name: <FormattedMessage id="PrintTemplate.AgreementHealthCare.Title" />,
			search_column: "Zmena poskytovateľa ZS"
		},
		{
			id: "request_CT",
			name: <FormattedMessage id="PrintTemplate.RequestCT.Title" />,
			search_column: "Žiadanka o vyšetrenie počítačovou tomografiou (CT)"
		},
		{
			id: "request_MR",
			name: <FormattedMessage id="PrintTemplate.RequestMR.Title" />,
			search_column: "Žiadanka o vyšetrenie magnetickou rezonanciou (MR)"
		},
		{
			id: "corona",
			name: <FormattedMessage id="PrintTemplate.Corona.Title" />,
			search_column: "Žiadanka o vyšetrenie na COVID-19",
			hidePrint: true
		},
		{
			id: "request_osteodenzinometry",
			name: <FormattedMessage id="PrintTemplate.Osteodenzinometry.Title" />,
			search_column: "Žiadanka na osteodenzitometrické vyšetrenie"
		},
		{
			id: "request_dispensary_care",
			name: <FormattedMessage id="PrintTemplate.DispensaryCare.Title" />,
			search_column: "Návrh na dispenzárnu starostlivosť"
		}
	]

	@action open(id, values, editedtemplateId = null, summariesId = null) {
		this.isOpen = true
		this.templateId = id
		this.summariesId = summariesId
		this.templateValues = values
		this.editedtemplateId = editedtemplateId

		switch (id) {
			case "transport":
				this.title = <FormattedMessage id="PrintTemplate.Transport.Title" />
				this.templateContent = <TransportPrintTemplate values={values} />
				break
			case "header":
				this.title = <FormattedMessage id="PrintTemplate.Header.Title" />
				this.templateContent = <PrazdnyDekurzTemplate values={values} />
				break
			case "requests_additional_healthcare":
				this.title = <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.Title" />
				this.templateContent = <RequestsAdditionalHealthcareTemplate values={values} />
				break
			case "preventive_examination_adults":
				this.title = <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.Title" />
				this.templateContent = <PreventiveExaminationAdultsTemplate values={values} />
				break
			case "spa":
				this.title = <FormattedMessage id="PrintTemplate.Spa.Title" />
				this.templateContent = <SpaTemplate values={values} />
				break
			case "sickness_abs_temp_template":
				this.title = <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.Title" />
				this.templateContent = <SicknessAbsTempTemplate values={values} />
				break
			case "dead_report":
				this.title = <FormattedMessage id="PrintTemplate.DeadReport.Title" />
				this.templateContent = <DeadReportTemplate values={values} />
				break
			case "angiologie_questionnaire":
				this.title = <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.Title" />
				this.templateContent = <AngiologieQuestionnaireTemplate values={values} />
				break
			case "medical_opinion":
				this.title = <FormattedMessage id="PrintTemplate.MedicalOpinion.Title" />
				this.templateContent = <MedicalOpinionTemplate values={values} />
				break
			case "doctors_opinion_guns":
				this.title = <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.Title" />
				this.templateContent = <MedicalOpinionWeaponTemplate values={values} />
				break
			case "medical_examination_driver_printable":
				this.title = <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.Title" />
				this.templateContent = <MedicalExaminationDriverTemplate values={values} />
				break
			case "medical_examination_driver":
				this.title = <FormattedMessage id="PrintTemplate.MedicalExaminationDriver" />
				this.templateContent = <MedicalExaminationDriverCardTemplate values={values} />
				break
			case "lab_Request":
				this.title = <FormattedMessage id="PrintTemplate.LabRequest.Title" />
				this.templateContent = <LabRequestTemplate values={values} />
				break
			case "assessment_social_service":
				this.title = <FormattedMessage id="PrintTemplate.AssessmentSocialService.Title" />
				this.templateContent = <AssessmentSocialServiceTemplate values={values} />
				break
			case "protocol_enteral_nutrition":
				this.title = <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.Title" />
				this.templateContent = <ProtocolEnteralNutritionTemplate values={values} />
				break
			case "agreement_health_care":
				this.title = <FormattedMessage id="PrintTemplate.AgreementHealthCare.Title" />
				this.templateContent = <AgreementHealthCareTemplate values={values} />
				break
			case "request_CT":
				this.title = <FormattedMessage id="PrintTemplate.RequestCT.Title" />
				this.templateContent = <RequestCTTemplate values={values} />
				break
			case "request_MR":
				this.title = <FormattedMessage id="PrintTemplate.RequestMR.Title" />
				this.templateContent = <RequestMRTemplate values={values} />
				break
			case "control_examination_adults":
				this.title = <FormattedMessage id="PrintTemplate.ControlExaminationAdults.Title" />
				this.templateContent = <ControlExaminationAdultsTemplate values={values} />
				break
			case "corona":
				this.title = <FormattedMessage id="PrintTemplate.Corona.Title" />
				this.templateContent = <CoronaTemplate values={values} />
				break
			case "request_dispensary_care":
				this.title = <FormattedMessage id="PrintTemplate.DispensaryCare.Title" />
				this.templateContent = <DispensaryCareTemplate values={values} />
				break
		}
	}

	@action edit(recordID) {
		api
			.loadPrintTemplate(recordID)
			.call()
			.then((response) => {
				let content
				let summariesId

				try {
					summariesId = response.summaries[0]._id
					content = base64DecodeUnicode(response.summaries[0].content)
				} catch (e) {
					logger("PrintTemplatesStore Edit base64DecodeUnicode content error", e)
				}

				let contentJSON = JSON.parse(content)

				Object.keys(contentJSON).forEach((key) => {
					if (contentJSON[key] !== null && typeof contentJSON[key] === "object") {
						contentJSON[key] = contentJSON[key].value
					}
				})

				let commonValues = this.getCommonValues()

				let values = Object.assign({}, contentJSON, commonValues)
				// let values = Object.assign({}, commonValues, contentJSON) // vymenene kvoli prepisovaniu default hodnotami

				this.open(contentJSON.templateId, values, recordID, summariesId)
			})
	}

	@action storno(recordID) {
		api
			.deleteRecord(recordID)
			.call()
			.then(() => {
				const providerID = getSelectedOrgUnitID()

				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
				})
			})

		// api
		// 	.loadPrintTemplate(recordID)
		// 	.call()
		// 	.then((response) => {
		// 		response["active"] = false

		// 		api
		// 			.savePrintTemplate(response)
		// 			.call()
		// 			.then(() => {
		// 				const providerID = getSelectedOrgUnitID()

		// 				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
		// 					DataStore.setPatientEHR(overview)
		// 				})
		// 			})
		// 	})
	}

	@action close() {
		this.isOpen = false

		if (isSafe(this.templateForm)) {
			this.templateForm.reset()
		}
	}

	@action onSave(datetimeFormat, validationEmptyFormMsg) {
		if (isSafe(this.templateForm)) {
			this.templateForm.validate().then(({isValid}) => {
				if (isValid) {
					const fields = this.templateFields.load().fields
					const variableDate = isSafe(fields.variableDate) ? this.templateForm.$("variableDate").value : null
					const opakovatKazdy = isSafe(fields.opakovatKazdy) ? this.templateForm.$("opakovatKazdy").value : null
					const opakovatDo = isSafe(fields.opakovatDo) ? this.templateForm.$("opakovatDo").value : null

					let repeatCount = 0
					let repeatDates = []

					if (isSafe(opakovatKazdy) && isSafe(opakovatDo) && isSafe(variableDate) && opakovatKazdy > 0) {
						let datumOD = null
						let datumDo = null
						if (!moment.isMoment(variableDate)) {
							datumOD = moment(variableDate)
						} else {
							datumOD = variableDate
						}
						if (!moment.isMoment(opakovatDo)) {
							datumDo = moment(opakovatDo).startOf("day")
						} else {
							datumDo = opakovatDo.startOf("day")
						}
						const hours = datumOD.hours()
						const minutes = datumOD.minutes()

						while (datumOD.startOf("day").isSameOrBefore(datumDo)) {
							repeatCount++
							repeatDates.push(
								datumOD
									.hours(hours)
									.minutes(minutes)
									.format(datetimeFormat)
							)
							datumOD = moment(datumOD).add(opakovatKazdy, "days")
						}
					}

					let contentJSON = {}

					Object.keys(fields).forEach((formField) => {
						if (this.templateForm.$(formField).type === "date") {
							contentJSON[formField] = {type: "date", value: this.templateForm.$(formField).value}
						} else if (this.templateForm.$(formField).type === "dateSplit") {
							contentJSON[formField] = {type: "dateSplit", value: this.templateForm.$(formField).value}
						} else if (this.templateForm.$(formField).type === "dateTime") {
							contentJSON[formField] = {type: "dateTime", value: this.templateForm.$(formField).value}
						} else if (this.templateForm.$(formField).type === "checkbox") {
							contentJSON[formField] = {type: "checkbox", value: this.templateForm.$(formField).value}
							if (
								isNotEmpty(this.templateForm.$(formField).options) &&
								isNotEmpty(this.templateForm.$(formField).options.html) &&
								this.templateForm.$(formField).options.html === "visibility"
							) {
								contentJSON[formField]["visibility"] = true
							}
						} else if (this.templateForm.$(formField).type === "split") {
							contentJSON[formField] = {type: "split", value: this.templateForm.$(formField).value}
						} else {
							contentJSON[formField] = this.templateForm.$(formField).value
						}
					})

					let requestJSON = null
					let customTemplateName =
						isSafe(contentJSON.customTemplateName) && isNotEmpty(contentJSON.customTemplateName)
							? contentJSON.customTemplateName
							: null
					let customRecordDateTime = isSafe(contentJSON.date) ? contentJSON.date.value : null

					// ak sa negeneruje viac tlaciv (bezny stav)
					if (repeatCount == 0) {
						let contentStringified = JSON.stringify(contentJSON)
						let base64Content = base64EncodeUnicode(contentStringified)
						requestJSON = this.getJSON(base64Content, this.editedtemplateId, customTemplateName, customRecordDateTime)
					} else {
						// ak sa generuje viac tlaciv
						requestJSON = []

						for (let i = 0; i < repeatCount; i++) {
							contentJSON["variableDate"] = repeatDates[i]
							let contentStringified = JSON.stringify(contentJSON)
							let base64Content = base64EncodeUnicode(contentStringified)
							requestJSON.push(
								this.getJSON(base64Content, this.editedtemplateId, customTemplateName, customRecordDateTime)
							)
						}
					}

					api
						.savePrintTemplate(requestJSON)
						.call()
						.then(() => {
							const providerID = getSelectedOrgUnitID()

							DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								DataStore.setPatientEHR(overview)
							})

							this.close()
						})
				} else {
					GlobalStore.setNotificationMessage(validationEmptyFormMsg)
				}
			})
		}
	}

	@action onSaveAndPrint(datetimeFormat, isPrintablePaper) {
		if (isSafe(this.templateForm)) {
			this.templateForm.validate().then(({isValid}) => {
				if (isValid) {
					const fields = this.templateFields.load().fields
					const variableDate = isSafe(fields.variableDate) ? this.templateForm.$("variableDate").value : null
					const opakovatKazdy = isSafe(fields.opakovatKazdy) ? this.templateForm.$("opakovatKazdy").value : null
					const opakovatDo = isSafe(fields.opakovatDo) ? this.templateForm.$("opakovatDo").value : null

					let repeatCount = 0
					let repeatDates = []

					if (isSafe(opakovatKazdy) && isSafe(opakovatDo) && isSafe(variableDate) && opakovatKazdy > 0) {
						let datumOD = null
						let datumDo = null
						if (!moment.isMoment(variableDate)) {
							datumOD = moment(variableDate)
						} else {
							datumOD = variableDate
						}
						if (!moment.isMoment(opakovatDo)) {
							datumDo = moment(opakovatDo).startOf("day")
						} else {
							datumDo = opakovatDo.startOf("day")
						}
						const hours = datumOD.hours()
						const minutes = datumOD.minutes()

						while (datumOD.startOf("day").isSameOrBefore(datumDo)) {
							repeatCount++
							repeatDates.push(
								datumOD
									.hours(hours)
									.minutes(minutes)
									.format(datetimeFormat)
							)
							datumOD = moment(datumOD).add(opakovatKazdy, "days")
						}
					}

					let contentJSON = {}

					Object.keys(fields).forEach((formField) => {
						// Date
						if (this.templateForm.$(formField).type === "date" && isNotEmpty(this.templateForm.$(formField).value)) {
							contentJSON[formField] = {type: "date", value: this.templateForm.$(formField).value}

							if (
								this.templateForm.$(formField).value instanceof moment ||
								this.templateForm.$(formField).value.indexOf("T") != -1
							) {
								this.templateValues[formField] = moment(this.templateForm.$(formField).value).format("DD.MM.YYYY")
							} else {
								this.templateValues[formField] = moment(this.templateForm.$(formField).value, "DD.MM.YYYY").format(
									"DD.MM.YYYY"
								)
							}
						}
						// DateSplit
						else if (
							this.templateForm.$(formField).type === "dateSplit" &&
							isNotEmpty(this.templateForm.$(formField).value)
						) {
							contentJSON[formField] = {type: "dateSplit", value: this.templateForm.$(formField).value}

							moment(this.templateForm.$(formField).value)
								.format("DD.MM.YYYY")
								.split("")
								.map((char, index) => {
									this.templateValues[formField + index] = char
								})
						}
						// DateTime
						else if (
							this.templateForm.$(formField).type === "dateTime" &&
							isNotEmpty(this.templateForm.$(formField).value)
						) {
							contentJSON[formField] = {type: "dateTime", value: this.templateForm.$(formField).value}
							this.templateValues[formField] = moment(this.templateForm.$(formField).value).format("DD.MM.YYYY HH:mm")
						}
						// Checkbox
						else if (
							this.templateForm.$(formField).type === "checkbox" &&
							isNotEmpty(this.templateForm.$(formField).value)
						) {
							contentJSON[formField] = {type: "checkbox", value: this.templateForm.$(formField).value}

							let tempValue = this.templateForm.$(formField).value

							if (formField.endsWith("Inv")) {
								tempValue = !tempValue
							}

							if (
								isNotEmpty(this.templateForm.$(formField).options) &&
								isNotEmpty(this.templateForm.$(formField).options.html) &&
								this.templateForm.$(formField).options.html === "visibility"
							) {
								// Checkbox - pouzity CSS style visibility namiesto display
								this.templateValues[formField] = tempValue === true ? "initial" : "hidden"
								contentJSON[formField]["visibility"] = true
							} else {
								this.templateValues[formField] = tempValue === true ? "initial" : "none"
							}
						}
						// Split
						else if (
							this.templateForm.$(formField).type === "split" &&
							isNotEmpty(this.templateForm.$(formField).value)
						) {
							contentJSON[formField] = {type: "split", value: this.templateForm.$(formField).value}

							this.templateForm
								.$(formField)
								.value.toString()
								.split("")
								.map((char, index) => {
									this.templateValues[formField + index] = char
								})
						} else {
							contentJSON[formField] = this.templateForm.$(formField).value
							this.templateValues[formField] = this.templateForm.$(formField).value
						}
					})

					let requestJSON = null
					const isLandscape = isSafe(this.templateValues.lab) && this.templateValues.lab == "synlab"
					let customTemplateName =
						isSafe(contentJSON.customTemplateName) && isNotEmpty(contentJSON.customTemplateName)
							? contentJSON.customTemplateName
							: null
					let customRecordDateTime = isSafe(contentJSON.date) ? contentJSON.date.value : null

					// ak sa negeneruje viac tlaciv (bezny stav)
					if (repeatCount == 0) {
						let contentStringified = JSON.stringify(contentJSON)
						let base64Content = base64EncodeUnicode(contentStringified)
						requestJSON = this.getJSON(base64Content, this.editedtemplateId, customTemplateName, customRecordDateTime)

						if (isSafe(this.templateValues.paperTemplateId) && isPrintablePaper) {
							printReport(
								this.templateValues.paperTemplateId,
								this.templateValues,
								null,
								null,
								isLandscape ? "-L" : null
							)
						} else {
							printReport(this.templateValues.templateId, this.templateValues, null, null, isLandscape ? "-L" : null)
						}
					} else {
						// ak sa generuje viac tlaciv
						let base64Contents = []
						requestJSON = []

						for (let i = 0; i < repeatCount; i++) {
							contentJSON["variableDate"] = repeatDates[i]
							this.templateValues["variableDate"] = repeatDates[i]

							let contentStringified = JSON.stringify(contentJSON)
							let base64Content = base64EncodeUnicode(contentStringified)
							base64Contents.push(base64Content)
							requestJSON.push(
								this.getJSON(base64Content, this.editedtemplateId, customTemplateName, customRecordDateTime)
							)

							if (isSafe(this.templateValues.paperTemplateId) && isPrintablePaper) {
								printReport(
									this.templateValues.paperTemplateId,
									this.templateValues,
									null,
									null,
									isLandscape ? "-L" : null
								)
							} else {
								printReport(this.templateValues.templateId, this.templateValues, null, null, isLandscape ? "-L" : null)
							}
						}
					}

					api
						.savePrintTemplate(requestJSON)
						.call()
						.then(() => {
							const providerID = getSelectedOrgUnitID()

							DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								DataStore.setPatientEHR(overview)
							})
						})

					this.close()
				}
			})
		}
	}

	@action print(recordID, isPrintablePaper) {
		api
			.loadPrintTemplate(recordID)
			.call()
			.then((response) => {
				let content = base64DecodeUnicode(response.summaries[0].content)

				let contentJSON = JSON.parse(content)

				Object.keys(contentJSON).forEach((key) => {
					// Date
					if (contentJSON[key] !== null && typeof contentJSON[key] === "object" && contentJSON[key].type === "date") {
						if (contentJSON[key].value.indexOf("T") != -1) {
							contentJSON[key] = moment(contentJSON[key].value).format("DD.MM.YYYY")
						} else {
							contentJSON[key] = moment(contentJSON[key].value, "DD.MM.YYYY").format("DD.MM.YYYY")
						}
					}
					// DateSplit
					else if (
						contentJSON[key] !== null &&
						typeof contentJSON[key] === "object" &&
						contentJSON[key].type === "dateSplit"
					) {
						moment(contentJSON[key].value)
							.format("DD.MM.YYYY")
							.split("")
							.map((char, index) => {
								contentJSON[key + index] = char
							})
					}
					// DateTime
					else if (
						contentJSON[key] !== null &&
						typeof contentJSON[key] === "object" &&
						contentJSON[key].type === "dateTime"
					) {
						contentJSON[key] = moment(contentJSON[key].value).format("DD.MM.YYYY HH:mm")
					}
					// Checkbox
					else if (
						contentJSON[key] !== null &&
						typeof contentJSON[key] === "object" &&
						contentJSON[key].type === "checkbox"
					) {
						if (key.endsWith("Inv")) {
							contentJSON[key].value = !contentJSON[key].value
						}

						if (isNotEmpty(contentJSON[key].visibility) && contentJSON[key].visibility) {
							// Checkbox - pouzity CSS style visibility namiesto display
							contentJSON[key] = contentJSON[key].value === true ? "initial" : "hidden"
						} else {
							contentJSON[key] = contentJSON[key].value === true ? "initial" : "none"
						}
					}
					// Split
					else if (
						contentJSON[key] !== null &&
						typeof contentJSON[key] === "object" &&
						contentJSON[key].type === "split"
					) {
						contentJSON[key].value
							.toString()
							.split("")
							.map((char, index) => {
								contentJSON[key + index] = char
							})
					}
				})

				let commonValues = this.getCommonValues()

				let values = Object.assign({}, contentJSON, commonValues)
				// let values = Object.assign({}, commonValues, contentJSON) // vymenene kvoli prepisovaniu default hodnotami

				// printReport(contentJSON.templateId, values)
				const isLandscape = isSafe(contentJSON.lab) && contentJSON.lab == "synlab"

				if (isSafe(contentJSON.paperTemplateId) && isPrintablePaper) {
					printReport(contentJSON.paperTemplateId, values, null, null, isLandscape ? "-L" : null)
				} else {
					printReport(contentJSON.templateId, values, null, null, isLandscape ? "-L" : null)
				}
			})
	}

	@action getJSON(content, editedtemplateId = null, customTemplateName = null, customRecordDateTime = null) {
		let customRecordTime = undefined
		if (this.templateId == "header" && isSafe(customRecordDateTime)) {
			customRecordTime = customRecordDateTime
		}

		let requestJSON = {
			_ref: false,
			_type: "EHR.Data.Report",
			type:
				this.templateId == "corona"
					? "ezcovidform"
					: {
							_id: "General",
							_type: "EHR.CL.Report.Type"
					  },
			label: isSafe(customTemplateName) ? customTemplateName : this.templateId,
			active: true,
			recorded_events: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Event",
					active: true,
					recorded_at: moment(customRecordTime).format(config.DB_DATETIME_FORMAT),
					type: {
						_id: "WRT",
						_type: "EHR.CL.Record.EventType"
					}
				}
			],
			general_items: [],
			specific_rels: [
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: DataStore.patientDTO.get("patientID"),
						_type: "EHR.Data.Entity.Patient"
					},
					type: "pat"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: getUserPersonnelID(),
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "phy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: getSelectedOrgUnitID(),
						_type: "Entity.Data.OrgUnit"
					},
					type: "ou"
				}
			],
			summaries: {
				_id: isNotEmpty(this.summariesId) ? this.summariesId : null,
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: content,
				type: "JSONHEADER",
				content_type: "application/json"
			}
		}

		if (isSafe(editedtemplateId)) {
			requestJSON["_id"] = editedtemplateId
		}

		return requestJSON
	}

	getTemplateName = (id) => {
		return this.printTemplates.find((x) => x.id === id).name
	}

	getCommonValues() {
		const pacInfo = DataStore.patientDTO.get("patient")
		const doctorInfo = getUserDoctorInfo()
		const orgUnit = getSelectedOrgUnit()
		const companyInfo = getUserCompanyInfo()
		const dateFormat = "DD.MM.YYYY"
		//const providerCode = orgUnit.identifiers.find(i => i.type === "PROVIDERCODE");

		let printValues = {
			name: `${pacInfo.full_name}`,
			first_name: `${pacInfo.first_name}`,
			last_name: `${pacInfo.last_name}`,
			name_reversed: `${pacInfo.full_name_reversed_stripped}`,
			identifier: pacInfo.identifier,
			now: moment().format(dateFormat),
			sick_date:
				moment().format("D") <= 8
					? moment()
							.subtract(1, "month")
							.endOf("month")
							.format(dateFormat)
					: moment()
							.endOf("month")
							.format(dateFormat)
		}

		//Poskytovatel ZS
		if (companyInfo) {
			printValues["companyIdentifier"] = companyInfo.identifier ? companyInfo.identifier : null
			printValues["companyName"] = companyInfo.name
		}

		//Lekár
		if (doctorInfo) {
			printValues["doctorname"] = doctorInfo.name
			printValues["doctor_first_name"] = doctorInfo.first_name
			printValues["doctor_last_name"] = doctorInfo.last_name
			printValues["doctorcode"] = doctorInfo.code
			printValues["doctoraddress"] = doctorInfo.address ? doctorInfo.address.full_address : ""

			doctorInfo.code
				.toString()
				.split("")
				.forEach((char, index) => {
					printValues["doctorcode" + index] = char
				})
		}

		//OrgUnit
		if (orgUnit) {
			const primaryAddress = doctorInfo.address
			const businessContact = doctorInfo.contact
			const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
			printValues["orgunitname"] = orgUnit.name_ext
			printValues["orgunitaddress"] = primaryAddress ? primaryAddress.full_address : ""
			if (businessContact) {
				printValues["orgunitemail"] = businessContact.email ? businessContact.email : ""
				printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
					businessContact.phone ? businessContact.phone : ""
				}`
				printValues["orgunitmobile"] = businessContact.mobile
			}
			printValues["orgunitcode"] = providerCode ? providerCode.value : ""

			if (providerCode.value) {
				providerCode.value
					.toString()
					.split("")
					.map((char, index) => {
						printValues["orgunitcode" + index] = char
					})
			}
		}

		//Adresa
		if (pacInfo.validInfo && pacInfo.validInfo.address) {
			printValues["address"] = pacInfo.validInfo.address.full_address
			printValues["postcode"] = pacInfo.validInfo.address.zip
			printValues["street"] = pacInfo.validInfo.address.street
			printValues["house_number"] = pacInfo.validInfo.address.house_number
			printValues["city"] = pacInfo.validInfo.address.city
			printValues["county"] = isSafe(pacInfo.validInfo.address.county)
				? pacInfo.validInfo.address.county.description
				: ""
			printValues["country"] = isSafe(pacInfo.validInfo.address.region)
				? pacInfo.validInfo.address.region.country.description
				: ""
		}

		//Poistenie
		if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
			const icode =
				pacInfo.validInfo.insurance.insurer.code_ext.toString().length === 2
					? String(pacInfo.validInfo.insurance.insurer.code_ext + "00")
					: pacInfo.validInfo.insurance.insurer.code_ext
			icode
				.toString()
				.split("")
				.map((char, index) => {
					printValues["insurer" + index] = char
				})
			printValues["insurerCode"] = icode
			printValues[
				"insurer"
			] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
			if (pacInfo.validInfo.insurance.type) {
				printValues["insuranceTypeCode"] = pacInfo.validInfo.insurance.type.code_ext
			}
		}

		//Kontakt
		if (pacInfo.validInfo && pacInfo.validInfo.contact) {
			printValues["phone"] = pacInfo.validInfo.contact.mobile
				? pacInfo.validInfo.contact.mobile
				: pacInfo.validInfo.contact.phone
			if (pacInfo.validInfo.contact.email) {
				printValues["email"] = pacInfo.validInfo.contact.email
			}
		}

		//Národnosť
		if (pacInfo.validInfo && pacInfo.validInfo.citizenship) {
			printValues["citizenship"] = pacInfo.validInfo.citizenship.country
				? pacInfo.validInfo.citizenship.country.name_ext
				: ""
			printValues["citizenshipCode"] = pacInfo.validInfo.citizenship.country
				? pacInfo.validInfo.citizenship.country.code_ext
				: ""
		}

		//Identifier
		pacInfo.identifier
			.toString()
			.split("")
			.map((char, index) => {
				printValues["identifier" + index] = char
			})

		//Birthdate
		if (pacInfo.birth_date) {
			printValues["birthdate"] = moment(pacInfo.birth_date).format("DD.MM.YYYY")
			printValues["birthdateISO"] = moment(pacInfo.birth_date).format("YYYY-MM-DD")
			pacInfo.birth_date
				.toString()
				.split("")
				.map((char, index) => {
					printValues["birthdate" + index] = char
				})
		}

		if (/*item.id === "dead_report" &&*/ pacInfo.gender) {
			//printValues["sex"] = pacInfo.gender._id === "M" ? 1 : 2;

			if (pacInfo.gender._id === "M") printValues["sex"] = 1
			else if (pacInfo.gender._id === "F") printValues["sex"] = 2
			else printValues["sex"] = ""
			printValues["sex_default"] = pacInfo.gender._id

			printValues["sex_verbal"] = pacInfo.gender.name_ext.toLowerCase()
		}

		return printValues
	}
}

var singleton = new PrintTemplatesStore()
export default singleton
