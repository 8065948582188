//@flow
import React from "react"
import ReactTooltip from "react-tooltip"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Timeline from "../timeline/timeline"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import UIStore from "../../../stores/UIStore"
import config from "../../../../../global/config/settings"

import EHealthSearchDialog from "./eHealthSearchDialog"
import EHealthGetRequestsDialog from "./eHealthGetRequestsDialog"
// import WarningStore from "../../../../../global/store/WarningStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import InfoDialogStore from "../../../../../global/store/InfoDialogStore"

import EHealthSearchDialogStore from "../../../stores/EHealthSearchDialogStore"
import EHealthGetRequestsDialogStore from "../../../stores/EHealthGetRequestsDialogStore"
import RequestsFromLabsDialogStore from "../../../stores/RequestsFromLabsDialogStore"
import RequestsFromLabsDialog from "../timeline/requestsFromLabsDialog"
import SignInLabDialogStore from "../../../stores/SignInLabDialogStore"
import SignInLabDialog from "../timeline/signInLabDialog"
import GetPatientSummaryEDSStore from "../../../stores/GetPatientSummaryEDSStore"
import GetPatientSummaryEDSDialog from "../patientCard/GetPatientSummaryEDSDialog"
import GetPatientSummaryStore from "../../../stores/GetPatientSummaryStore"
import GetPatientSummaryDialog from "../patientCard/GetPatientSummaryDialog"
import {GWApiCall} from "../../../../../global/helpers/api"
import {getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export class Overview extends React.Component {
	filter = {
		time: {
			search_string: "pastTwoYears"
		}
		// diagnoses: []
	}

	generateTimeFilter = () => {
		const values = [
			"Common.timeFilter.all",
			"Common.timeFilter.pastTwoYears",
			// "Common.timeFilter.lastYear",
			"Common.timeFilter.thisYear",
			"Common.timeFilter.lastMonth",
			"Common.timeFilter.thisMonth",
			"Common.timeFilter.lastWeek",
			"Common.timeFilter.thisWeek"
		]

		return {
			items: values.map((value) => {
				return {
					primaryText: this.props.intl.formatMessage({id: value}),
					secondaryText: this.props.intl.formatMessage({id: value}),
					search_string: value.split(".")[2],
					checkValue: false,
					id: value.split(".")[2]
				}
			})
		}
	}

	render() {
		const {patientOverviewData, actionButton} = this.props
		const {filter} = this
		const time_string = filter.time.search_string
		const hideToday = time_string.indexOf("last") !== -1

		// let res = {
		// 	Result: [
		// 		{
		// 			LastChanged: "2015-12-12",
		// 			Name: "Druh zdravotníckych pomôcok a implantátov",
		// 			Oid: "1.3.158.00165387.100.10.126",
		// 			ValidFrom: "2015-01-01",
		// 			ValidTo: "9999-12-31",
		// 			Version: "2"
		// 		},
		// 		{
		// 			LastChanged: "2016-05-23",
		// 			Name: "PSČ",
		// 			Oid: "1.3.158.00165387.100.10.150",
		// 			ValidFrom: "2016-06-01",
		// 			ValidTo: "9999-12-31",
		// 			Version: "4"
		// 		}
		// 	]
		// }
		return (
			<div>
				{UIStore.EHRfilterValue === "nczivalues" && (
					<div className="xs-action-btn">
						<XsButton
							className="xs-nczi-search"
							icon={<i className="fal fa-search" />}
							onClick={() => EHealthSearchDialogStore.open()}
							text={<FormattedMessage id="PatientDetail.overview.eHealth.searching" />}
						/>
						<XsButton
							className="xs-nczi-search"
							onClick={() => EHealthGetRequestsDialogStore.open()}
							text={<FormattedMessage id="PatientDetail.overview.searchIneHealth" />}
						/>
						<XsButton
							className="xs-nczi-search"
							onClick={() => GetPatientSummaryEDSStore.open()}
							text={<FormattedMessage id="Common.label.summaryEDS" />}
						/>
						<XsButton
							className="xs-nczi-search"
							onClick={() => GetPatientSummaryStore.open()}
							text={<FormattedMessage id="Common.label.patientSummary" />}
						/>
						<XsButton
							className="xs-nczi-search"
							onClick={() => {
								const user = getUser()
								const selectedOrgUnit = getSelectedOrgUnit()
								const OUPZS = isSafe(selectedOrgUnit)
									? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID")
									: null
								const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
								const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
								const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
								const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

								const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
									? DataStore.patientDTO.get("patient").validInfo.client
									: null
								const JRUZIDObj = isSafe(patientClientInfo.identifiers)
									? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
									: null

								GWApiCall({
									method: "POST",
									uri: `${config.GW_BASE_URL}/CheckCodeListVersion`,
									body: JSON.stringify({
										ClassName: "OverVerziuCiselnikov_v1",
										Oids: [
											"1.3.158.00165387.100.10.126",
											"1.3.158.00165387.100.10.150",
											"1.3.158.00165387.100.10.158",
											"1.3.158.00165387.100.10.159",
											"1.3.158.00165387.100.10.25",
											"1.3.158.00165387.100.10.34",
											"1.3.158.00165387.100.10.37",
											"1.3.158.00165387.100.10.39",
											"1.3.158.00165387.100.10.43",
											"1.3.158.00165387.100.10.44",
											"1.3.158.00165387.100.10.45",
											"1.3.158.00165387.100.10.51",
											"1.3.158.00165387.100.10.54",
											"1.3.158.00165387.100.10.59",
											"1.3.158.00165387.100.10.66",
											"1.3.158.00165387.100.10.83",
											"1.3.158.00165387.100.10.86"
										],
										AmbeePatientId: DataStore.patientDTO.get("patientID"),
										PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
										PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
										AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
										AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
										AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
										AmbeePersonnelId: getUserPersonnelID(),
										OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
										PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
										PersonnelExpertiseJRUZVersion: specVer,
										PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
									})
								})
									.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
									.then((res) => {
										if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
											// WarningStore.generateErrorMessage(res.IsEhealthException, res.ErrorMessage)
											GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
										} else {
											InfoDialogStore.open({
												header: {
													icon: <i className="fal fa-abacus fa-2x" />,
													text: <FormattedMessage id="Common.label.listVersions" />
												},
												content: () => (
													<XsTable
														config={{
															columnDefs: {
																Name: {
																	title: <FormattedMessage id="Common.label.name" />,
																	type: "string"
																},
																LastChanged: {
																	title: <FormattedMessage id="Common.label.lastChanged" />,
																	type: "string",
																	design: {
																		width: "90px"
																	}
																},
																Oid: {
																	title: <FormattedMessage id="Common.label.oid" />,
																	type: "string"
																},
																ValidFrom: {
																	title: <FormattedMessage id="Common.label.validFrom" />,
																	type: "string",
																	design: {
																		width: "90px"
																	}
																},
																ValidTo: {
																	title: <FormattedMessage id="Common.label.validTo" />,
																	type: "string",
																	design: {
																		width: "90px"
																	}
																},
																Version: {
																	title: <FormattedMessage id="Common.label.version" />,
																	type: "string",
																	design: {
																		width: "60px"
																	}
																}
															},
															options: {},
															dataSource: res.Result
														}}
													/>
												)
											})
										}
									})
							}}
							text={<FormattedMessage id="Common.label.checkCodeListVersion" />}
						/>
					</div>
				)}
				{UIStore.EHRfilterValue === "laboratories" && (
					<div className="xs-action-btn">
						<XsButton
							className="xs-default xs-outline"
							text={<FormattedMessage id="PatientDetail.overview.signToLaboratory" />}
							onClick={() => SignInLabDialogStore.open()}
						/>
					</div>
				)}
				{UIStore.EHRfilterValue === "requests" && (
					<div className="xs-action-btn">
						<XsButton
							className="xs-default xs-outline"
							text={<FormattedMessage id="PatientDetail.overview.RequestsFromLaboratories" />}
							onClick={() => RequestsFromLabsDialogStore.open()}
						/>
					</div>
				)}
				{UIStore.EHRfilterValue === "medicationAMB" && (
					<div className="xs-action-btn">
						<span data-tip data-for="epis">
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.epis" />}
								onClick={() => window.open("https://www.epis.sk/WebLogon.aspx?returnurl=/HlavnaStranka.aspx", "_blank")}
							/>
						</span>
						<ReactTooltip id="epis">
							<FormattedMessage id="Common.label.episDescription" />
						</ReactTooltip>
					</div>
				)}
				<Timeline
					items={patientOverviewData}
					hideToday={hideToday}
					timeFilterValues={this.generateTimeFilter()}
					reloadOverview={this.props.reloadOverview}
					actionButton={actionButton}
					actions={this.props.actions}
					// onTimeFilterChange={updateTimeFilter}
					// onDiagnosisFilterChange={updateDiagnosisFilter}
				/>
				<EHealthSearchDialog />
				<EHealthGetRequestsDialog />
				<SignInLabDialog />
				<RequestsFromLabsDialog />
				<GetPatientSummaryEDSDialog />
				<GetPatientSummaryDialog />
				{UIStore.isOverviewTemplateLoading && <XsLoading overlay />}
			</div>
		)
	}
}

export default injectIntl(Overview)
