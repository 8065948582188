"use strict"

import {observable, action} from "mobx"
// import UIStore from "./UIStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import config from "../../../global/config/settings"
import api from "../actions/api"
// import WarningStore from "../../../global/store/WarningStore"
// import InvoiceFormStore from "../../ambulance/stores/InvoiceFormStore"
// import GlobalStore from "../../../global/store/GlobalStore"

class ExportToXlsDialogStore {
	@observable isOpen = false
	// formRef = null

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		// UIStore.isFormSaving = false
		// if (isSafe(this.formRef)) {
		// 	this.formRef.reset()
		// }
	}

	@action ExportToXls(form) {
		let formValues = form.values()

		let req = {
			uri: `/amb/entity/providers/${getSelectedOrgUnitID()}/events`,
			filters: [
				{
					associated_column: "time_from",
					values: [
						{
							id_value: formValues.from
								.add(-1, "days")
								.endOf("day")
								.format(config.DB_DATETIME_FORMAT)
						}
					]
				},
				{
					associated_column: "time_to",
					values: [
						{
							id_value: formValues.to.format(config.DB_DATETIME_FORMAT)
						}
					]
				}
			]
		}

		if (isNotEmpty(formValues.status_id)) {
			req.filters.push({
				associated_column: "status_id",
				values: [
					{
						id_value: formValues.status_id
					}
				]
			})
		}

		if (isNotEmpty(formValues.provided_service) && formValues.provided_service.length > 0) {
			let values = []

			formValues.provided_service.forEach((ser) => {
				values.push({
					id_value: ser.id
				})
			})

			req.filters.push({
				associated_column: "provided_service_id",
				values: values
			})
		}

		req.row_count_show = 100000
		req.row_count_full = 100000
		req.row_offset = 1

		api
			.exportData(req)
			.call()
			.then((response) => {
				this.close()
				form.reset()
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
	}
}

var singleton = new ExportToXlsDialogStore()
export default singleton
