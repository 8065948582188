import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Grid from "@material-ui/core/Grid"
import CancelIcon from "@material-ui/icons/Clear"

import UIStore from "../../../stores/UIStore"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import ScoringDialog from "./scoringDialog"
import ScoringStore from "../../../stores/ScoringStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export default class ScoringList extends React.Component {
	constructor(props) {
		super(props)

		//Load Scoring List
		ScoringStore.loadList()
	}

	onRowClick = (item) => {
		ScoringStore.open(item)
	}

	render() {
		if (ScoringStore.scoringList) {
			return (
				<section className="xs-patient-card-form xs-column">
					{/* {this.renderAutoSaveConfirmDialog()} */}
					<div className="xs-patient-form xs-row">
						<div className="xs-patient-form-left xs-flex">
							<Grid container>
								<Grid item xs={12}>
									<XsTable
										config={{
											columnDefs: {
												name_ext: {type: "string"}
											},
											options: {
												showCursor: true,
												selectRow: true,
												hideHeader: true,
												paging: {
													rowsPerPage: 25
												},
												onRowClick: this.onRowClick,
												mapper: (dataRow) => {
													return {
														name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
														id: isSafe(dataRow._id) ? dataRow._id : ""
													}
												}
											},
											dataSource: ScoringStore.scoringList
										}}
									/>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className="xs-patient-form-actions">
						<XsButton
							className="xs-danger xs-outline"
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => {
								UIStore.patientCardTab = ""
							}}
							icon={<CancelIcon />}
						/>
					</div>
					{ScoringStore.isOpen && (
						<ScoringDialog
							options={{
								insertScoringToRecord: this.props.options.insertScoringToRecord
							}}
						/>
					)}
				</section>
			)
		}
		return <XsLoading />
	}
}
