"use strict"

import {observable} from "mobx"

class PriceListFormStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new PriceListFormStore()
export default singleton
