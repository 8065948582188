import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"
// import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"

import fields from "./patientAlertsFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientAlertsDialogStore from "../../../stores/PatientAlertsDialogStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PatientAlertsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				let request = []
				let tempAlerts = []

				if (PatientAlertsDialogStore.isEditable && isSafe(PatientAlertsDialogStore.alertId)) {
					tempAlerts = DataStore.patientDTO
						.get("alertsChips")
						.filter((alert) => alert._id !== PatientAlertsDialogStore.alertId)
				} else {
					if (isSafe(DataStore.patientDTO.get("alertsChips"))) {
						tempAlerts = isSafe(DataStore.patientDTO.get("alertsChips")) ? DataStore.patientDTO.get("alertsChips") : []
					}
				}

				tempAlerts.forEach((alert) => {
					request.push({
						_ref: false,
						active: true,
						description: alert.alert,
						severity: {_id: alert.code, _type: "EHR.CL.Severity"},
						_type: "EHR.Data.Alert"
					})
				})

				request.push({
					_ref: false,
					active: true,
					description: form.values().alert,
					severity: {_id: form.values().severity, _type: "EHR.CL.Severity"},
					_type: "EHR.Data.Alert"
				})

				api
					.saveAlerts(request, DataStore.patientDTO.get("patientID"))
					.call()
					.then(() => {
						api
							.loadAlerts(DataStore.patientDTO.get("patientID"))
							.call()
							.then((alerts) => {
								let alertsRecords = []

								alerts.records.forEach((rec) => {
									alertsRecords.push({
										_id: rec._id,
										alert: rec.description,
										severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
										code: isSafe(rec.severity) ? rec.severity.code : ""
									})
								})

								DataStore.patientDTO.set("alertsChips", alertsRecords)
							})

						form.reset()
						PatientAlertsDialogStore.isEditable = false
					})
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		GlobalStore.refreshCodeLists(["severities"])
	}

	form

	onRowClick = (alert) => {
		PatientAlertsDialogStore.isEditable = true
		PatientAlertsDialogStore.alertId = alert._id

		this.form.$("alert").set("value", alert.alert)
		this.form.$("severity").set("value", alert.code)
	}

	deletePatientAlert = () => {
		if (isSafe(DataStore.patientDTO.get("alertsChips"))) {
			let alertsRecords = DataStore.patientDTO
				.get("alertsChips")
				.filter((alert) => alert._id != GlobalStore.confirmationDialogParams.alertId)

			let request = []

			alertsRecords.forEach((alert) => {
				request.push({
					_ref: false,
					active: true,
					description: alert.alert,
					severity: {_id: alert.code, _type: "EHR.CL.Severity"},
					_type: "EHR.Data.Alert"
				})
			})

			api
				.saveAlerts(request, DataStore.patientDTO.get("patientID"))
				.call()
				.then(() => {
					api
						.loadAlerts(DataStore.patientDTO.get("patientID"))
						.call()
						.then((alerts) => {
							let alertsRecords = []

							alerts.records.forEach((rec) => {
								alertsRecords.push({
									_id: rec._id,
									alert: rec.description,
									severity: isSafe(rec.severity) ? rec.severity.name_ext : "",
									code: isSafe(rec.severity) ? rec.severity.code : ""
								})
							})

							DataStore.patientDTO.set("alertsChips", alertsRecords)

							this.form.reset()
						})
				})
		}
	}

	UNSAFE_componentWillUpdate() {
		if (isSafe(PatientAlertsDialogStore.alertObj)) {
			PatientAlertsDialogStore.insertFormData(this.form)
		}
	}

	render() {
		return (
			<Dialog
				id="xsPatientAlertsDialog"
				open={PatientAlertsDialogStore.isOpen}
				onClose={() => {
					PatientAlertsDialogStore.close()
					PatientAlertsDialogStore.isEditable = false
					this.form.reset()
				}}
				className="xs-base-dialog dialog-patientalerts"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info xs-relative">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientAlertsDialogStore.close()
								PatientAlertsDialogStore.isEditable = false
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-exclamation-triangle fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.alerts.title" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.alerts.subtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<XsInput field={this.form.$("alert")} />
							<SearchSelect
								field={this.form.$("severity")}
								items={GlobalStore.CL["severities"]}
								getOptionLabel={(obj) => (
									<div className="xs-row xs-flex xs-align">
										<i
											className="fas fa-circle pr-2 fontSize12"
											style={
												obj.item_order == 1
													? {color: "#39B0DD"}
													: obj.item_order == 2
													? {color: "#FFB900"}
													: obj.item_order == 3
													? {color: "#FF623F"}
													: obj.item_order == 4
													? {color: "#C6184E"}
													: {color: "#01579b"}
											}
										/>
										<span>{obj.name_ext}</span>
									</div>
								)}
								required
							/>
						</Grid>
						{PatientAlertsDialogStore.isEditable ? (
							<Grid item xs={12} className="xs-action xs-row xs-justify-between">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.alerts.saveAlert" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										PatientAlertsDialogStore.isEditable = false
										this.form.reset()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						) : (
							<Grid item xs={12} className="xs-action">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.alerts.addAlert" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
							</Grid>
						)}
						<Grid item xs={12} className="xs-items-scrollbar">
							<div className="xs-bold xs-items-title xs-row">
								<FormattedMessage id="Patient.sidebar.alerts.allAlerts" />
								<div className="xs-count-items">
									{isSafe(DataStore.patientDTO.get("alertsChips")) && DataStore.patientDTO.get("alertsChips").length > 0
										? DataStore.patientDTO.get("alertsChips").length
										: 0}
								</div>
							</div>
							<div className="xs-items">
								{isSafe(DataStore.patientDTO.get("alertsChips")) &&
									DataStore.patientDTO.get("alertsChips").map((alert, idx) => {
										let levelJSON = {level: 1, color: "#01579b"}
										if (alert.code === "Low") {
											levelJSON = {level: 2, color: "#39B0DD"}
										} else if (alert.code === "Medium") {
											levelJSON = {level: 3, color: "#FFB900"}
										} else if (alert.code === "High") {
											levelJSON = {level: 4, color: "#FF623F"}
										} else if (alert.code === "Critical") {
											levelJSON = {level: 5, color: "#C6184E"}
										} else {
											levelJSON = {level: 1, color: "#01579b"}
										}

										return (
											<div key={idx} className="xs-item xs-row xs-flex">
												<div className="xs-item-content" onClick={() => this.onRowClick(alert)}>
													<div className="xs-column xs-flex xs-justify">
														<div className="xs-primary-info xs-blueInfo">{alert.alert.shortTo(25)}</div>
														{/* <div className="xs-secondary-info">{alert.severity.shortTo(30)}</div> */}
													</div>
													<div className="xs-column xs-flex xs-justify">
														<div className="xs-primary-info xs-severity">
															{[1, 2, 3, 4, 5].map((item, idx) => {
																if (idx < levelJSON.level) {
																	return <i key={idx} className="fas fa-circle" style={{color: levelJSON.color}} />
																} else {
																	return <i key={idx} className="far fa-circle" style={{color: "#78849E"}} />
																}
															})}
														</div>
														<div className="xs-secondary-info xs-severity">{alert.severity}</div>
													</div>
												</div>
												<div
													className="xs-trash"
													data-tip
													data-for="deleteAlert"
													onClick={() => {
														GlobalStore.openConfirmationDialog("xsDeletePatientAlert", {alertId: alert._id}),
															(PatientAlertsDialogStore.nameOfDeleteAlert = alert.alert)
													}}
												>
													<i className="fal fa-trash-alt fa-lg" />
												</div>
												<ReactTooltip id="deleteAlert">
													<FormattedMessage id="Common.label.deleteAlert" />
												</ReactTooltip>
											</div>
										)
									})}
							</div>
						</Grid>
						<XsConfirmationDialog
							type="danger"
							name="xsDeletePatientAlert"
							text={
								<span className="xs-greyDefault fontSize13">
									<FormattedMessage id="Delete.alert.text" />
								</span>
							}
							subtext={<span className="xs-bold fontSize15">{PatientAlertsDialogStore.nameOfDeleteAlert}</span>}
							headerConfig={{
								text: "Delete.alert.header.text",
								subtext: "Delete.alert.header.subtext",
								icon: <i className="fal fa-trash-alt fa-2x" />
							}}
							cancelBtn={{
								icon: <i className="fal fa-times fa-lg" />,
								text: "Common.btn.not.delete"
							}}
							confirmBtn={{
								icon: <i className="fal fa-trash-alt fa-lg" />,
								text: "Common.label.deleteAlert"
							}}
							onConfirmation={() => this.deletePatientAlert()}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
