"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import moment from "moment"
import WarningStore from "../../../global/store/WarningStore"
import InvoiceFormStore from "../../ambulance/stores/InvoiceFormStore"
import {StorageBase} from "../../../global/storage/storageEx"

import ExportToXlsDialogStore from "./ExportToXlsDialogStore"
import UIStore from "./UIStore"

class ExportCapitationDialogStore {
	capitationListRef = null
	@observable isOpen = false

	doctor_expertise_id = undefined
	insurer_id = undefined
	provider_id = undefined
	personnel_id = undefined
	date_from = undefined
	date_to = undefined
	translatedExportBatchError = undefined
	translatedGetSummaryDeliveriesError = undefined

	invoiceNumber = ""
	report_type = undefined
	insurers = []

	@action open(obj) {
		this.doctor_expertise_id = obj.doctor_expertise_id
		this.insurer_id = obj.insurer_id
		this.provider_id = obj.org_unit_id
		this.personnel_id = obj.personnel_id
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.isOpen = true
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
	}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action openInvoiceOnly(obj, filterObj) {
		this.doctor_expertise_id = obj.doctor_expertise_id
		this.insurer_id = obj.insurer_id
		this.provider_id = obj.org_unit_id
		this.personnel_id = obj.personnel_id
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
		this.getSummaryCapitations()
		if (isSafe(filterObj)) {
			// Storage.setItem("capitationListFilters", filterObj)
			StorageBase.updateByKey("capitationListFilters", filterObj)
		}
	}

	@action getSummaryCapitations() {
		api
			.loadSummaryCapitations({
				from: this.date_from,
				to: this.date_to,
				insurer_id: this.insurer_id,
				org_unit_id: this.provider_id,
				patient_id: null,
				personnel_id: this.personnel_id
			})
			.call()
			.then((data) => {
				let invoiceData = {
					items: []
				}

				if (isSafe(data.sub_totals) && data.sub_totals.length > 0) {
					data.sub_totals.forEach((x) => {
						if (isSafe(x.sum) && x.sum > 0) {
							invoiceData.items.push({
								itemName: `Kapitácia veková skupina od ${x.age_from} do ${x.age_to}`,
								itemUnitPrice: x.sum,
								itemPrice: x.sum,
								itemQuantity: 1
							})
						}
					})
				}
				if (isSafe(data.sum_additional) && data.sum_additional > 0) {
					invoiceData.items.push({
						itemName: "Cena za dodatkovú kapitáciu",
						itemUnitPrice: data.sum_additional,
						itemQuantity: 1,
						itemPrice: data.sum_additional
					})
				}
				// if (isNotEmpty(this.invoiceNumber)) {
				// 	invoiceData["invoice_number"] = this.invoiceNumber
				// }

				//invoiceData["customer"]: this.insurer_id,
				//TODO: Doriešiť predplňanie customera!

				InvoiceFormStore.setNewInvoiceData(invoiceData, this.callbackFn)
			})
			.catch(() => {
				WarningStore.open(this.translatedGetSummaryDeliveriesError)
			})

		this.isOpen = false
	}

	callbackFn = () => {
		// logger("cislo fa", formData.$("invoice_number").get("value"))
		// const invoiceNumberFromInvoice = isSafe(formData) ? formData.$("invoice_number").get("value") : null
		// this.download(invoiceNumberFromInvoice)
	}

	@action download(invoiceNumber) {
		api
			.downloadCapitation({
				doctor_expertise_id: this.doctor_expertise_id,
				insurer_id: this.insurer_id,
				provider_id: this.provider_id,
				date: this.date_from,
				report_type: this.report_type,
				disposition: "N",
				invoice_number: isSafe(invoiceNumber) ? invoiceNumber : this.invoiceNumber
			})
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "davka.001"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				} else {
					WarningStore.open(response.statusText)
				}
			})
			.catch(() => {
				WarningStore.open(this.translatedExportBatchError)
			})

		this.close()
	}

	@action downloadMultipleCapitations(form) {
		logger("INS:", form.$("insurer").value)
		logger("REP:", form.$("report_type").value)

		form.$("insurer").value.forEach((ins) => {
			form.$("report_type").value.forEach((report) => {
				api
					.downloadCapitation({
						doctor_expertise_id: this.doctor_expertise_id,
						insurer_id: ins.id,
						provider_id: this.provider_id,
						date: this.date_from,
						report_type: report.id,
						disposition: "N"
					})
					.call()
					.then((response) => {
						if (response.ok == true) {
							let fileName = isSafe(response.headers.get("content-disposition"))
								? response.headers.get("content-disposition").split("filename=")[1]
								: "davka.001"
							response.blob().then((blob) => {
								let FileSaver = require("file-saver") // eslint-disable-line no-undef
								FileSaver.saveAs(blob, fileName)
							})
						} else {
							WarningStore.open(response.statusText)
						}
					})
					.catch(() => {
						WarningStore.open(this.translatedExportBatchError)
					})
			})
		})
		this.close()
	}

	@action exportToXls(type, personnelId, insurerId, providerId, date, identifier) {
		// let inputSearchValue = document.getElementById("PatientListSearchInput").value
		let filter = {
			uri: `/amb/entity/capitations?level_flag=CD`,
			filters: [
				{
					associated_column: "personnel_id",
					values: [
						{
							id_value: personnelId
						}
					]
				},
				{
					associated_column: "insurer",
					values: [
						{
							id_value: insurerId
						}
					]
				},
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: providerId
						}
					]
				},
				{
					associated_column: "validity_from",
					values: [
						{
							id_value: date
						}
					]
				},
				{
					associated_column: "validity_to",
					values: [
						{
							id_value: moment(date)
								.endOf("month")
								.format("YYYY-MM-DD")
						}
					]
				}
			]
		}

		if (isSafe(identifier)) {
			filter.filters.push({
				associated_column: "identifier",
				values: [
					{
						id_value: identifier
					}
				]
			})
		}

		if (type == "now") {
			if (isSafe(this.capitationListRef)) {
				filter.row_count_show = this.capitationListRef.state.rowsPerPage
				// filter.row_count_full = this.capitationListRef.state.rowsPerPage
				filter.row_offset = this.capitationListRef.state.page * this.capitationListRef.state.rowsPerPage + 1
			} else {
				filter.row_offset = 1
				filter.row_count_show = 10
				// filter.row_count_full = 10
			}
		} else {
			filter.row_offset = 1
			filter.row_count_show = 100000
			filter.row_count_full = 100000
		}

		api
			.exportData(filter)
			.call()
			.then((response) => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
			.catch(() => {
				ExportToXlsDialogStore.close()
				UIStore.isFormSaving = false
			})
	}

	@action close() {
		this.isOpen = false
		this.doctor_expertise_id = undefined
		this.insurer_id = undefined
		this.provider_id = undefined
		this.personnel_id = undefined
		this.date_from = undefined
		this.date_to = undefined

		this.invoiceNumber = ""
		this.report_type = ""

		this.insurers = []
	}
}

var singleton = new ExportCapitationDialogStore()
export default singleton
