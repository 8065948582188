"use strict"

import {observable, action} from "mobx"

import api from "../actions/api"
import {getFilters} from "../../../global/helpers/api"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import moment from "moment"
import UIStore from "./UIStore"
import DataStore from "./DataStore"
import CalendarStore from "./CalendarStore"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"
import WarningStore from "../../../global/store/WarningStore"
// import {getSelectedOrgUnitID, getSelectedOrgUnitResourceID} from "../../../global/helpers/actions"

import NewPatientDialogStore from "./NewPatientDialogStore"

class EditEventDialogStore {
	@observable isOpen = false
	@observable step = 1
	@observable serviceId = ""
	isEditService = false

	@observable serviceObj = {}
	@observable inputDateTimeIsValid = moment().format("DD.MM.YYYY HH:mm")
	@observable selectedDateTime = moment()
	@observable slotsData = []
	@observable note = ""
	/* week view (ak kliknes na edit event, aby zaroven nevybehlo modal add event, 2 klikatelne div na sebe */
	@observable isClickedEdit = false
	isNextEvent = false
	event = {}

	@observable serviceData = []

	@observable showPatientEvents = false
	@observable patientEventsData = []

	changeServiceTxt = ""

	@observable headerMessage = "Calendar.addEvent.dialog.headerMessage"
	@observable isWarningMessage = false

	resourceObj = false

	@action patientEvents() {
		api
			.loadClientAgenda(
				this.event.client_id,
				moment()
					.startOf("day")
					.utc(),
				moment()
					.add(12, "months")
					.utc()
			)
			.call()
			.then((res) => (this.patientEventsData = isSafe(res) ? res : []))

		this.showPatientEvents = true
	}

	@action handleChangeResource() {
		this.serviceObj = GlobalStore.confirmationDialogParams.serviceObj
		this.serviceId = this.serviceObj.value

		if (DataStore.resourcesData.length > 0) {
			const service = DataStore.resourcesData.find((x) => x.code == this.serviceId)
			if (service) {
				this.serviceDuration = service.duration
			}
		}

		this.isEditService = true
		UIStore.isFormSaving = true
		this.setSlots()
	}

	@action getServices() {
		UIStore.isFormSaving = true
		let filters = [
			{
				associated_column: "client_id",
				values: [{id_value: this.event.client.client_id}]
			}
		]

		api
			.loadServices(this.event.primary_resource._id, {filters: filters})
			.call()
			.then((res) => {
				let items = []
				if (isSafe(res) && res.length > 0) {
					res.forEach((service) => {
						let duration = isSafe(service.duration) ? +service.duration / 60 : 0
						items.push({
							code: service.provided_service_id,
							name_ext: `${service.name_ext} (${duration} min.)`,
							duration: duration,
							item_order: isSafe(service.item_order) ? service.item_order : Number.MAX_VALUE,
							color: service.color
						})
					})
				}

				// sort podla item_order v pripade rovnosti podla name_ext
				items = items.sort((a, b) => {
					return (
						(isSafe(a.item_order) ? +a.item_order : Number.MAX_VALUE) -
							(isSafe(b.item_order) ? +b.item_order : Number.MAX_VALUE) ||
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				})

				this.serviceData = items
				UIStore.isFormSaving = false
			})
	}

	@action setSlots() {
		const providerID = this.event.primary_resource.object_id
		const providerResourceID = this.event.primary_resource._id

		const slotRequest = {
			events: [
				{
					_ref: false,
					_type: "SRVZO.API.REST.Proxy.Event",
					services: [{_ref: false, _type: "SRVZO.API.REST.Proxy.ScheduledService", service_id: this.serviceId}]
				}
			],
			interval_from: this.selectedDateTime.toISOString(),
			primary_resource_id: providerResourceID,
			max_slots: 100,
			services: [{_ref: false, _type: "SRVZO.API.REST.Proxy.OrderedService", service_id: this.serviceId}],
			_type: "SRVZO.API.REST.Proxy.Order"
		}

		api
			.loadSlots(providerID, slotRequest)
			.call()
			.then((slots) => {
				if (isSafe(slots) && isSafe(slots.messages) && slots.messages.length > 0 && isSafe(slots.messages[0].value)) {
					if (slots.messages[0].value === "OH") {
						this.headerMessage = "Calendar.addEvent.dialog.OH"
						this.isWarningMessage = true
					} else if (slots.messages[0].value === "OVERBOOKING") {
						this.headerMessage = "Calendar.addEvent.dialog.OVERBOOKING"
						this.isWarningMessage = true
					}
				} else {
					this.headerMessage = "Calendar.addEvent.dialog.headerMessage"
					this.isWarningMessage = false
				}

				this.slotsData = slots.slots
				UIStore.isFormSaving = false
			})
	}

	@action editEvent() {
		UIStore.isFormSaving = true
		if (this.isEditService) {
			if (isSafe(this.slotsData[0])) {
				api
					.deleteEvent(this.event.event_id)
					.call()
					.then(() => {
						let request = this.slotsData[0]

						let diffInMinutes = moment(this.event.interval_to).diff(this.event.interval_from, "minutes")

						const utcFrom = moment(this.selectedDateTime)
							.set("second", 0)
							.set("millisecond", 0)
							.utc()
							.format()

						const utcDiff = moment(this.selectedDateTime)
							.set("second", 0)
							.set("millisecond", 0)
							.utc()
							.add(diffInMinutes, "minutes")
							.format()

						request.interval_from = utcFrom
						request.interval_to = utcDiff
						request.events[0].interval.from = utcFrom
						request.events[0].interval.to = utcDiff

						if (isEmpty(this.event.client.client_id) && isNotEmpty(this.event.client.client_text)) {
							request.client = this.event.client.client_text
						} else {
							request.client_id = this.event.client.client_id
						}

						request.primary_resource_id = this.event.primary_resource._id
						request.events[0].note = this.note
						request.force_overbooking = true

						request["services"] = [
							{
								service_id: this.serviceId,
								_type: "SRVZO.API.REST.Proxy.OrderedService",
								_ref: false
							}
						]

						api
							.createEvent([request])
							.call()
							.then((response) => {
								if (this.isOpenedNextVisitDialog) {
									const searchRequest = getFilters([`client_id=${response[0].client_id}`])
									api
										.loadCompanyPatients(getUserCompanyInfo().id, searchRequest)
										.call()
										.then((patients) => {
											if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
												DataStore.patientDTO.set("nextVisit", patients.rows[0].next_event_from)
												DataStore.patientDTO.set("nextEventId", patients.rows[0].next_event_id)
												DataStore.patientDTO.set("nextEventName", patients.rows[0].next_event_service_name)
											}
										})
								} else {
									CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
									// api
									// 	.loadCompanyPatients(getUserCompanyInfo().id)
									// 	.call()
									// 	.then((patients) => {
									// 		if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
									// 			// DataStore.setPatientsData()
									// 			CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
									// 		}
									// 	})
								}

								if (isSafe(response) && response.length > 0) {
									GlobalStore.setNotificationMessage("Calendar.addEvent.dialog.saveSuccess")
								}

								this.isOpenedNextVisitDialog = false
								this.isEditService = false

								this.close()
							})
							.catch(() => {
								UIStore.isFormSaving = false
							})
					})
			} else {
				WarningStore.open(this.changeServiceTxt)
				UIStore.isFormSaving = false
			}
		} else {
			let updateRequest = {}

			updateRequest = {
				_id: this.event.event_id,
				_type: "Service.Data.Event",
				_ref: false,
				interval: {
					from: this.event.interval_from,
					to: this.event.interval_to
				},
				_notes: [
					{
						_id: this.event.event_note_id,
						_type: "IXS.Data.Note",
						_ref: false,
						value: this.note
					}
				]
			}

			let utcFrom = moment(this.selectedDateTime)
				.set("second", 0)
				.set("millisecond", 0)
				.utc()

			let diffInMinutes = moment(updateRequest.interval.to).diff(updateRequest.interval.from, "minutes")

			updateRequest.interval.from = utcFrom.format()
			updateRequest.interval.to = utcFrom.add(diffInMinutes, "minutes").format()

			api
				.updateEvent(updateRequest, this.event.event_id)
				.call()
				.then((response) => {
					if (isSafe(response) && response.statusText === "OK") {
						GlobalStore.setNotificationMessage("Calendar.addEvent.dialog.editSuccess")
						if (this.isNextEvent) {
							DataStore.patientDTO.set("nextVisit", this.selectedDateTime.toISOString())
						}
						this.nextEvent = false
					}

					this.close()
				})
				.catch(() => {
					UIStore.isFormSaving = false
				})
		}
	}

	@action updatedIdEvent(person) {
		if (
			isSafe(person) &&
			isSafe(person.specific_rels) &&
			person.specific_rels.length > 0 &&
			isSafe(person.specific_rels.find((x) => x._type == "Entity.Data.Client" && x.active))
		) {
			const clientId = person.specific_rels.find((x) => x._type == "Entity.Data.Client" && x.active)._id
			let updateRequest = {
				_id: this.event.event_id,
				client_id: clientId,
				_type: "Service.Data.Event",
				_ref: false,
				interval: {
					from: this.event.interval_from,
					to: this.event.interval_to
				}
			}

			let utcFrom = moment(this.selectedDateTime).utc()

			let diffInMinutes = moment(updateRequest.interval_to).diff(updateRequest.interval_from, "minutes")

			updateRequest.interval.from = utcFrom.format()
			updateRequest.interval.to = utcFrom.add(diffInMinutes, "minutes").format()

			api
				.updateEvent(updateRequest, this.event.event_id)
				.call()
				.then(() => {
					CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
					api
						.loadCompanyPatients(getUserCompanyInfo().id)
						.call()
						.then((patients) => {
							if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
								DataStore.patientsData = patients.rows
							}
						})
				})

			this.close()
		}
	}

	@action completeEvent() {
		api
			.completeEvent(this.event.event_id)
			.call()
			.then(() => {
				CalendarStore.getEvents()
			})

		this.close()
	}

	@action moveEvent(event, providerID, providerResourceID, timeFrom) {
		let serviceReq = {
			filters: [
				{
					associated_column: "service_id",
					values: [{id_value: event.services[0].service_id}]
				}
			]
		}

		UIStore.isFormSaving = true

		api
			.loadServices(providerResourceID, serviceReq)
			.call()
			.then((service) => {
				if (isSafe(service) && service.length == 1) {
					const slotRequest = {
						events: [
							{
								_ref: false,
								_type: "SRVZO.API.REST.Proxy.Event",
								services: [
									{
										_ref: false,
										_type: "SRVZO.API.REST.Proxy.ScheduledService",
										service_id: event.services[0].provided_service_id
									}
								]
							}
						],
						interval_from: moment(event.interval_from).toISOString(),
						primary_resource_id: providerResourceID,
						max_slots: 10,
						services: [
							{
								_ref: false,
								_type: "SRVZO.API.REST.Proxy.OrderedService",
								service_id: event.services[0].provided_service_id
							}
						],
						_type: "SRVZO.API.REST.Proxy.Order"
					}

					api
						.loadSlots(providerID, slotRequest)
						.call()
						.then((res) => {
							if (isSafe(res.slots) && res.slots.length > 0) {
								let request = res.slots[0]

								request.interval_from = moment(event.interval_from)
									.set("hour", timeFrom)
									.utc()
									.format()
								request.interval_to = moment(event.interval_from)
									.set("hour", timeFrom)
									.add(event.services[0].duration, "seconds")
									.utc()
									.format()
								request.events[0].interval.from = moment(event.interval_from)
									.set("hour", timeFrom)
									.utc()
									.format()
								request.events[0].interval.to = moment(event.interval_from)
									.set("hour", timeFrom)
									.add(event.services[0].duration, "seconds")
									.utc()
									.format()

								request.events[0].note = event.event_note_text

								if (isEmpty(event.client.client_id)) {
									request.client = event.client.client_text
								} else {
									request.client_id = event.client.client_id
								}

								request.primary_resource_id = providerResourceID

								request.force_overbooking = true

								request["services"] = [
									{
										service_id: event.services[0].service_id,
										_type: "SRVZO.API.REST.Proxy.OrderedService",
										_ref: false
									}
								]

								api
									.createEvent([request])
									.call()
									.then(() => {
										api
											.deleteEvent(event.event_id)
											.call()
											.then(() => {
												CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
												GlobalStore.setNotificationMessage("Udalosť úspešne presunutá")
												UIStore.isFormSaving = false
												// api
												// 	.loadCompanyPatients(getUserCompanyInfo().id)
												// 	.call()
												// 	.then((patients) => {
												// 		if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
												// 			CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
												// 			GlobalStore.setNotificationMessage("Udalosť úspešne presunutá")
												// 		}
												// 		UIStore.isFormSaving = false
												// 	})
											})
									})
							} else {
								GlobalStore.setNotificationMessage("Neexistujú voľné sloty pre danú službu!", "", "error")
								UIStore.isFormSaving = false
							}
						})
				} else {
					GlobalStore.setNotificationMessage("Common.label.selectedServiceNotProvidedInThisOrgunit", "", "error")
					UIStore.isFormSaving = false
				}
			})
	}

	@action deleteEvent() {
		api
			.deleteEvent(this.event.event_id)
			.call()
			.then(() => {
				CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)

				api
					.loadCompanyPatients(getUserCompanyInfo().id)
					.call()
					.then((patients) => {
						if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
							DataStore.patientsData = patients.rows
						}

						const currPatient = patients.rows.filter((x) => x.client_id === DataStore.patientDTO.get("patientID"))[0]
						if (isSafe(currPatient)) {
							DataStore.patientDTO.set("lastVisit", currPatient.last_visit)
							DataStore.patientDTO.set("nextVisit", currPatient.next_event_from)
							DataStore.patientDTO.set("lastPreventiveExamination", currPatient.last_preventive_examination)
							DataStore.patientDTO.set("nextEventId", currPatient.next_event_id)
							DataStore.patientDTO.set("nextEventName", currPatient.next_event_service_name)
						}
					})
			})
		this.close()
	}

	@action showPatientDetail() {
		const searchRequest = getFilters([`event_id=${this.event.event_id}`])
		searchRequest.row_count_show = 1000

		DataStore.providedServicesNames[this.event.client.person_id] = this.event.services[0].service_name_ext
		api
			.loadEventsRelateds(searchRequest)
			.call()
			.then((response) => {
				DataStore.providedServices[this.event.client.person_id] = response.rows
				RouterStore.push(`/patient?id=${this.event.client.person_id}`)
				this.close()
			})
	}

	@action createNewPatient() {
		DataStore.isCreatedFromEvent = true
		DataStore.nameNewPatient = this.event.client.client_text
		this.close()
		NewPatientDialogStore.open()
	}

	@action open(event) {
		const eventService =
			isSafe(event.services) && event.services.length > 0 && isSafe(event.services[0]) ? event.services[0] : null

		this.step = 1
		this.isOpenedNextVisitDialog = false
		this.event = event
		this.getServices()

		CalendarStore.handleEventsByDay(event.interval_from)
		this.selectedDateTime = moment(event.interval_from)
		this.inputDateTimeIsValid = moment(event.interval_from).format("DD.MM.YYYY HH:mm")

		this.serviceId = eventService.provided_service_id
		this.serviceObj = {label: eventService.service_name_ext, value: eventService.provided_service_id}

		this.note = isNotEmpty(event.event_note_text) ? event.event_note_text : ""

		this.isOpen = true

		this.setSlots()

		CalendarStore.loadPatientsOrder(
			moment(event.interval_from)
				.startOf("day")
				.utc()
				.format(),
			moment(event.interval_from)
				.endOf("day")
				.utc()
				.format()
		)
	}

	@action planNextEvent(nextEventId) {
		logger("nextEventId", nextEventId)
		// const providerID = this.event.primary_resource.object_id

		this.isOpenedNextVisitDialog = true

		const req = {
			filters: [
				{
					associated_column: "event_id",
					values: [
						{
							id_value: nextEventId
						}
					]
				}
			]
		}

		api
			.getServiceEventList(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					const event = res.rows[0]

					this.event = event

					this.step = 1

					const eventService =
						isSafe(event.services) && event.services.length > 0 && isSafe(event.services[0]) ? event.services[0] : null

					this.selectedDateTime = moment(event.interval_from)
					this.inputDateTimeIsValid = moment(event.interval_from).format("DD.MM.YYYY HH:mm")

					this.serviceId = eventService.provided_service_id
					this.serviceObj = {label: eventService.service_name_ext, value: eventService.provided_service_id}

					this.note = isNotEmpty(event.event_note_text) ? event.event_note_text : ""

					CalendarStore.handleEventsByDay(event.interval_from)
					this.getServices()
					this.setSlots()

					this.isOpen = true
					this.isNextEvent = true
				}
			})
	}

	@action close() {
		this.isOpen = false
		this.showPatientEvents = false
		this.patientEventsData = []
		UIStore.isFormSaving = false

		if (
			UIStore.calendarTimePeriod === "today" ||
			UIStore.calendarTimePeriod === "tomorrow" ||
			UIStore.calendarTimePeriod === "otherDay"
		) {
			if (moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
				UIStore.calendarTimePeriod = "today"
			} else if (moment(CalendarStore.selectedDay).isSame(moment().add(1, "day"), "day")) {
				UIStore.calendarTimePeriod = "tomorrow"
			} else {
				UIStore.calendarTimePeriod = "otherDay"
			}
		}

		CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)

		this.serviceObj = {}
		this.serviceId = ""
		this.serviceDuration = ""
	}
}

var singleton = new EditEventDialogStore()
export default singleton
