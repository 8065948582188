"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import UIStore from "./UIStore"
import WarningStore from "../../../global/store/WarningStore"
import InvoiceFormStore from "./InvoiceFormStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import config from "../../../global/config/settings"

class GenerateInvoiceFromServiceDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable isWarning = false

	invoiceData = []

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		this.invoiceData = []
	}

	roundTotalCost = (number) => {
		return number === 0 || isNaN(number) ? 0 : parseFloat(number).toFixed(2)
	}

	@action generateInvoiceAndOpen(form, noEventsText) {
		UIStore.isFormSaving = true
		const items = form.values()
		const request = {
			time_from: items.from.format(config.DB_DATETIME_FORMAT),
			time_to: items.to.format(config.DB_DATETIME_FORMAT)
		}

		if (items) {
			api
				.loadPatientsAgenda(getSelectedOrgUnitID(), request)
				.call()
				.then((events) => {
					//Vyfiltrujeme len udalosti kde sa zhoduje stav udalosti a poskytovanej služby
					const validEvents = events.filter(
						(e) => e.event_status_id === items.status_id && items.provided_service.includes(e.service_ids)
					)

					let invItems = {}
					if (validEvents) {
						//Prejdeme jednotlivé events
						validEvents.map((e) => {
							//Prejdeme jednotlivé services v evente
							if (e.services) {
								e.services.map((s) => {
									const serviceID = s.service_id

									//Napočítame služby, sčítame ceny a získame názov
									if (invItems.hasOwnProperty(serviceID)) {
										invItems[serviceID] = {
											...invItems[serviceID],
											quantity: invItems[serviceID].quantity + 1
										}
									} else {
										invItems[serviceID] = {
											price: s.price,
											quantity: 1,
											name_ext: s.name_ext
										}
									}
								})
							}
						})

						//Ak máme aspoň jednu položku, vyskladáme vstupné dáta a zobrazíme faktúru
						if (Object.keys(invItems).length > 0) {
							let invoiceData = []
							Object.keys(invItems).forEach((key) => {
								const item = invItems[key]
								invoiceData.push({
									itemName: item.name_ext,
									itemUnitPrice: item.price,
									itemQuantity: item.quantity,
									itemPrice: this.roundTotalCost(item.quantity * item.price)
								})
							})

							InvoiceFormStore.setNewInvoiceData({items: invoiceData})
							this.close()
						} else {
							WarningStore.open(noEventsText)
						}
					}

					UIStore.isFormSaving = false
				})
		}
	}
}

var singleton = new GenerateInvoiceFromServiceDialogStore()
export default singleton
