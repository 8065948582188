import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./medicalExaminationDriverTemplateFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

@injectIntl
@observer
export default class MedicalExaminationDriverTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div style={{padding: "20px", width: "800px"}}>
				<Grid container direction="column" align="top">
					<Grid item xs={3}>
						<XsInput field={this.form.$("paperNumber")} maxLength={40} />
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("name")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("birthdate")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel1" />
					</div>
					<Grid container direction="row" align="left" spacing={16}>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_AM")}
								onChange={(field) => {
									this.form.$("has_AMx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_A1")}
								onChange={(field) => {
									this.form.$("has_A1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_A2")}
								onChange={(field) => {
									this.form.$("has_A2x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_A")}
								onChange={(field) => {
									this.form.$("has_Ax").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_B1")}
								onChange={(field) => {
									this.form.$("has_B1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_B")}
								onChange={(field) => {
									this.form.$("has_Bx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_BE")}
								onChange={(field) => {
									this.form.$("has_BEx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_C1")}
								onChange={(field) => {
									this.form.$("has_C1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_C1E")}
								onChange={(field) => {
									this.form.$("has_C1Ex").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_C")}
								onChange={(field) => {
									this.form.$("has_Cx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_CE")}
								onChange={(field) => {
									this.form.$("has_CEx").value = !field.value
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={16}>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_D1")}
								onChange={(field) => {
									this.form.$("has_D1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_D1E")}
								onChange={(field) => {
									this.form.$("has_D1Ex").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_D")}
								onChange={(field) => {
									this.form.$("has_Dx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_DE")}
								onChange={(field) => {
									this.form.$("has_DEx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("has_T")}
								onChange={(field) => {
									this.form.$("has_Tx").value = !field.value
								}}
							/>
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel2" />
					</div>
					<Grid container direction="row" align="left" spacing={16}>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_AM")}
								onChange={(field) => {
									this.form.$("req_AMx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_A1")}
								onChange={(field) => {
									this.form.$("req_A1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_A2")}
								onChange={(field) => {
									this.form.$("req_A2x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_A")}
								onChange={(field) => {
									this.form.$("req_Ax").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_B1")}
								onChange={(field) => {
									this.form.$("req_B1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_B")}
								onChange={(field) => {
									this.form.$("req_Bx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_BE")}
								onChange={(field) => {
									this.form.$("req_BEx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_C1")}
								onChange={(field) => {
									this.form.$("req_C1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_C1E")}
								onChange={(field) => {
									this.form.$("req_C1Ex").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_C")}
								onChange={(field) => {
									this.form.$("req_Cx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_CE")}
								onChange={(field) => {
									this.form.$("req_CEx").value = !field.value
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={16}>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_D1")}
								onChange={(field) => {
									this.form.$("req_D1x").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_D1E")}
								onChange={(field) => {
									this.form.$("req_D1Ex").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_D")}
								onChange={(field) => {
									this.form.$("req_Dx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_DE")}
								onChange={(field) => {
									this.form.$("req_DEx").value = !field.value
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox
								field={this.form.$("req_T")}
								onChange={(field) => {
									this.form.$("req_Tx").value = !field.value
								}}
							/>
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel3" />
					</div>
					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel4" />
					</div>
					<div>
						<XsCheckbox
							field={this.form.$("opinion_driver1Inv")}
							onChange={(field) => {
								if (field.value == false && this.form.$("opinion_driver1Inv").value == false) {
									this.form.$("opinion_driver1Inv").value = true
								} else if (field.value) {
									this.form.$("opinion_driver2Inv").value = false
								}
							}}
						/>
					</div>
					<div>
						<XsCheckbox
							field={this.form.$("opinion_driver2Inv")}
							onChange={(field) => {
								if (field.value == false && this.form.$("opinion_driver2Inv").value == false) {
									this.form.$("opinion_driver2Inv").value = true
								} else if (field.value) {
									this.form.$("opinion_driver1Inv").value = false
								}
							}}
						/>
					</div>
					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel5" />
					</div>
					<Grid container direction="column" align="left">
						<Grid item xs={12}>
							<XsInput field={this.form.$("result1_cat")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("result2_cat")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("result3_cat")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={10} style={{paddingTop: 30}}>
							<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.validateTo" />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("validateTo")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalExaminationDriver.FormLabel6" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("in")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
					<div style={{marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel2" />
					</div>
				</Grid>
			</div>
		)
	}
}
