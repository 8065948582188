import React, {useState} from "react"
import {observer} from "mobx-react"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {FormattedMessage, injectIntl} from "react-intl"
import CancelIcon from "@material-ui/icons/Clear"
import PatientPrescriptionForm from "./patientPrescriptionForm"
// import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
// import PatientPrescriptionContainerStore from "../../../stores/PatientPrescriptionContainerStore"
import PatientPrescriptionFormSave from "../patientPrescriptionForm/patientPrescriptionSaveButtons"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
// import Storage from "../../../../../global/storage/storage"
// import DataStore from "../../../stores/DataStore"
// import UIStore from "../../../stores/UIStore"

// @observer
// export default class PatientPrescriptionContainer extends React.Component {
function PatientPrescriptionContainer(props) {
	// constructor(props) {
	// 	super(props)

	// 	this.state = {
	// 		forms: {},
	// 		maxId: 0
	// 	}

	// 	this.formRefs = {}
	// 	this.saveButtonsRef = null
	// 	this.canceledSessionLoad = false
	// }
	const [maxFormKey, setMaxFormKey] = useState(Math.max(...Object.keys(props.forms)))

	const addForm = () => {
		const firstForm = props.forms[Math.min(...Object.keys(props.forms))]
		props.forms[+maxFormKey + 1] = props.options.createFormFunction()
		props.forms[+maxFormKey + 1].$("diagnosis").value = firstForm.$("diagnosis").value

		RegisterCacheStore.diagnosisRegister
		if (isNotEmpty(firstForm.$("diagnosis").value)) {
			let diagnosis = RegisterCacheStore.diagnosisRegister.find((diag) => diag.code == firstForm.$("diagnosis").value)
			if (isSafe(diagnosis)) {
				if (diagnosis.code_ext == "U99.01") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. II.st."
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				} else if (diagnosis.code_ext == "U99.02") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. III.st. trvalá"
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				} else if (diagnosis.code_ext == "U99.03") {
					props.forms[+maxFormKey + 1].$("prescriptionReason").value = "Ink. III.st. trvalá, nezvratná"
					props.forms[+maxFormKey + 1].$("showDetails").value = true
				}
			}
		}

		props.forms[+maxFormKey + 1].$("substitutingPersonnelExpertiseId").value = firstForm.$(
			"substitutingPersonnelExpertiseId"
		).value
		props.forms[+maxFormKey + 1].$("substitutingDoctor").value = firstForm.$("substitutingDoctor").value
		props.forms[+maxFormKey + 1].$("substitutingExpertiseId").value = firstForm.$("substitutingExpertiseId").value
		props.forms[+maxFormKey + 1].$("substitutingDoctorOUPZS").value = firstForm.$("substitutingDoctorOUPZS").value

		if (!props.forms[+maxFormKey + 1].$("showDetails").value) {
			props.forms[+maxFormKey + 1].$("showDetails").value =
				isNotEmpty(firstForm.$("substitutingDoctor").value) ||
				isNotEmpty(firstForm.$("substitutingExpertiseId").value) ||
				isNotEmpty(firstForm.$("substitutingDoctorOUPZS").value)
		}
		setMaxFormKey(+maxFormKey + 1)
		// const maxId = this.state.maxId + 1
		// const formJSX = (
		// 	<PatientPrescriptionForm
		// 		key={maxId}
		// 		getFormHandler={this.getForm.bind(this)}
		// 		keyVal={maxId}
		// 		prescriptionId={prescriptionIds[0]}
		// 		clearAllPrescriptions={() => this.clearAllPrescriptions()}
		// 		canceledSessionLoad={this.canceledSessionLoad}
		// 		setDefaultDiagnosis={(form, state) => {
		// 			this.setDefaultDiagnosis(form, state)
		// 		}} /*ref={(ref) => refVal = ref}*/
		// 		checkSaveButtons={() => this.checkSaveButtons()}
		// 		exact={true}
		// 	/>
		// )

		// this.setState(
		// 	{maxId: maxId, forms: Object.assign({}, this.state.forms, {[maxId]: {/*ref: refVal,*/ jsx: formJSX}})},
		// 	() => {
		// 		numberOfForms > 1 ? (prescriptionIds.shift(), this.addForm(numberOfForms - 1, prescriptionIds)) : null
		// 	}
		// )
	}

	// clearAllFromStorage = () => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).filter(
	// 		(keyVal) => keyVal.includes("PatientPrescriptionForm") && keyVal.includes(DataStore.patientDTO.get("patientID"))
	// 	)
	// 	storageKey.forEach((key) => {
	// 		Storage.removeItem(key)
	// 	})
	// }

	// deleteFromStorage = (key) => {
	// 	let data = Storage.getAll()
	// 	let storageKey = Object.keys(data).find(
	// 		(keyVal) =>
	// 			keyVal.includes("PatientPrescriptionForm") &&
	// 			keyVal.includes(DataStore.patientDTO.get("patientID")) &&
	// 			keyVal.includes(key)
	// 	)
	// 	Storage.removeItem(storageKey)
	// }

	// loadFromStorageCancel = () => {
	// 	this.canceledSessionLoad = true
	// }

	// addFormsFromStorage = () => {
	// 	this.clearAllPrescriptions(this.insertFormsFromStorage)
	// 	// this.insertFormsFromStorage()
	// }

	// insertFormsFromStorage = () => {
	// 	let dataFromDataStore = null
	// 	let multiFormData = JSON.parse(JSON.stringify(DataStore.multiFormsFromStorage["PatientPrescriptionForm"]))
	// 	if (isSafe(multiFormData) && isSafe(multiFormData[Object.keys(multiFormData)[0]])) {
	// 		dataFromDataStore = multiFormData[Object.keys(multiFormData)[0]].object
	// 	}
	// 	let prescriptionKeys = Object.keys(multiFormData)
	// 	let maxKey = 0
	// 	let formKeys = []
	// 	prescriptionKeys.forEach((key) => {
	// 		let obj = JSON.parse(key)
	// 		maxKey = obj.key > maxKey ? obj.key : maxKey
	// 		formKeys.push(+obj.key)
	// 	})

	// 	let formsToRender = null

	// 	for (let i = 1; i <= maxKey; i++) {
	// 		const maxId = i
	// 		const formJSX = (
	// 			<PatientPrescriptionForm
	// 				key={maxId}
	// 				getFormHandler={this.getForm.bind(this)}
	// 				keyVal={maxId}
	// 				clearAllPrescriptions={() => this.clearAllPrescriptions()}
	// 				setDefaultDiagnosis={(form, state) => {
	// 					this.setDefaultDiagnosis(form, state)
	// 				}}
	// 				dataFromDataStore={i == 1 ? dataFromDataStore : null}
	// 				exact={true}
	// 			/>
	// 		)

	// 		if (formKeys.includes(+i)) {
	// 			formsToRender = Object.assign({}, formsToRender, {[maxId]: {/*ref: refVal,*/ jsx: formJSX}})
	// 		}
	// 	}

	// 	if (isSafe(formsToRender)) {
	// 		this.setState({
	// 			maxId: maxKey,
	// 			forms: formsToRender
	// 		})
	// 	} else {
	// 		this.addForm()
	// 	}
	// }

	// getForm(key, form) {
	// 	if (isSafe(this.formRefs)) this.formRefs[key] = form
	// }

	// checkSaveButtons = () => {
	// 	if (isSafe(this.saveButtonsRef)) {
	// 		this.saveButtonsRef.forceUpdate()
	// 	}
	// }

	// setDefaultDiagnosis(form, state) {
	// 	if (isSafe(this.formRefs) && isSafe(this.formRefs[1]) && isSafe(this.formRefs[1].$("diagnosis"))) {
	// 		form.$("diagnosis").set("value", this.formRefs[1].$("diagnosis").value)
	// 		if (isNotEmpty(this.formRefs[1].$("substitutingPersonnelExpertiseId").value)) {
	// 			form
	// 				.$("substitutingPersonnelExpertiseId")
	// 				.set("value", this.formRefs[1].$("substitutingPersonnelExpertiseId").value)
	// 			form.$("substitutingDoctorOUPZS").set("value", this.formRefs[1].$("substitutingDoctorOUPZS").value)
	// 			state.showDetails = true
	// 		}
	// 	}
	// }

	// clearAllPrescriptions(callback) {
	// 	this.setState({maxId: 0, forms: {}}, () => {
	// 		if (typeof callback == "function") {
	// 			callback()
	// 		}
	// 	})
	// }

	const checkFormValidation = (form) => {
		if (form.$("repeatType").value == "0") {
			if (form.$("maxRepeats").value < 2) {
				return false
			}
		}
		if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "first") {
			if (+form.$("morning").value + +form.$("lunch").value + +form.$("evening").value + +form.$("sleep").value <= 0) {
				return false
			}
		} else if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "second") {
			if (
				+form.$("dosage").value + +form.$("everyHour").value <= 0 ||
				+form.$("dosage").value <= 0 ||
				+form.$("everyHour").value <= 0
			) {
				return false
			}
		}
		if (form.$("quantization").value !== "FIX" && form.$("quantization").value != "REC") {
			if (form.$("quantization").value == "VAR") {
				if (htmlToPlain(form.$("dosage").value).length < 3) {
					return false
				}
			} else {
				if (htmlToPlain(form.$("dosage").value).length == 0) {
					return false
				}
			}
		}
		if (
			isNotEmpty(form.$("sendingDoctor").value) ||
			isNotEmpty(form.$("doctorOUPZS").value) ||
			isNotEmpty(form.$("doctorDate").value)
		) {
			if (
				!(
					isNotEmpty(form.$("sendingDoctor").value) &&
					isNotEmpty(form.$("doctorOUPZS").value) &&
					isNotEmpty(form.$("doctorDate").value)
				)
			) {
				return false
			}
		}
		if (isNotEmpty(form.$("substitutingDoctor").value) || isNotEmpty(form.$("substitutingDoctorOUPZS").value)) {
			if (!(isNotEmpty(form.$("substitutingDoctor").value) && isNotEmpty(form.$("substitutingDoctorOUPZS").value))) {
				return false
			}
		}
		if (form.$("pacientPays").value) {
			if (isEmpty(form.$("pacientPaysReason").value)) {
				return false
			}
		}
		if (form.$("replacementProhibited").value) {
			let probChips = form.$("prohibitedChipsForm").value
			if (isEmpty(probChips) || (isNotEmpty(probChips) && (probChips == "[]" || probChips == "{}"))) {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.label.prohibitedDrugRequired"}))
				return false
			}
		}
		if (form.$("forcedSave").value) {
			if (isNotSafe(form.$("forcedSaveReason").value) || isEmpty(form.$("forcedSaveReason").value)) {
				return false
			}
		}
		if (isNotEmpty(form.$("days").value) && +form.$("days").value < 1) {
			return false
		}
		return true
	}

	// invalidForm() {
	// 	PatientPrescriptionStore.validationSnackbar = true
	// }

	// componentWillUnmount() {
	// 	PatientPrescriptionContainerStore.cardRef = null
	// }

	// UNSAFE_componentWillMount() {
	// 	if (Object.keys(this.state.forms).length === 0 && !PatientPrescriptionContainerStore.isGoingToReprescribe()) {
	// 		this.formRefs = {}
	// 		const jsx = (
	// 			<PatientPrescriptionForm
	// 				key={1}
	// 				/*checkFormValidation={(form,index,modify)=>{this.checkFormValidation(form,index,modify)}}*/ getFormHandler={this.getForm.bind(
	// 					this
	// 				)}
	// 				clearAllPrescriptions={() => this.clearAllPrescriptions()}
	// 				multiLoad={() => this.addFormsFromStorage()}
	// 				onLoadCancel={() => {
	// 					this.loadFromStorageCancel()
	// 				}}
	// 				setDefaultDiagnosis={(form) => {
	// 					this.setDefaultDiagnosis(form)
	// 				}}
	// 				checkSaveButtons={() => this.checkSaveButtons()}
	// 				keyVal={1}
	// 			/>
	// 		)
	// 		this.setState({maxId: 1, forms: {1: {ref: this.formRefs[1], jsx: jsx}}})
	// 		PatientPrescriptionContainerStore.cardRef = this
	// 	} else {
	// 		PatientPrescriptionContainerStore.cardRef = this
	// 		PatientPrescriptionContainerStore.represcribeIdsToForms()
	// 	}
	// }

	const renderMultiplePrescriptions = () => {
		const prescriptionForms = []
		if (isSafe(props.forms)) {
			Object.keys(props.forms).forEach((formKey, idx) => {
				prescriptionForms.push(
					<PatientPrescriptionForm
						key={idx}
						keyVal={idx}
						formKey={formKey}
						form={props.forms[formKey]}
						options={props.options}
					/>
				)
				if (Object.keys(props.forms).length > 1) {
					prescriptionForms.push(
						<div className="xs-cancel-btn">
							<XsButton
								className="xs-danger xs-outline pullLeft"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									const handler = () => {
										if (isSafe(PatientPrescriptionStore.drugPrices)) {
											PatientPrescriptionStore.drugPrices.delete(formKey)
										}
										delete props.forms[formKey]
										setMaxFormKey(maxFormKey + 1)
									}
									GlobalStore.openConfirmationDialog("patientCardAction", {
										onConfirm: handler,
										text: "Common.label.cancelForm"
									})
								}}
								icon={<CancelIcon />}
							/>
						</div>
					)
				}
			})
		}
		return prescriptionForms
	}
	// Object.keys(this.state.forms).forEach((key) => {
	// 	prescriptionForms.push(this.state.forms[key].jsx)
	// 	if (Object.keys(this.state.forms).length > 1) {
	// 		prescriptionForms.push(
	// 			<div key={"buttonsDiv" + key} className="xs-cancel-btn">
	// 				<XsButton
	// 					className="xs-danger xs-outline pullLeft"
	// 					text={<FormattedMessage id="Common.label.cancel" />}
	// 					onClick={() => {
	// 						let forms = this.state.forms
	// 						delete this.formRefs[key]
	// 						delete forms[key]
	// 						this.deleteFromStorage(key)
	// 						this.setState({forms: forms})
	// 					}}
	// 					icon={<CancelIcon />}
	// 				/>
	// 			</div>
	// 		)
	// 	}
	// })

	return (
		<div id="patientPrescriptionFormContainer">
			<PatientPrescriptionFormSave
				top
				options={props.options}
				form={props.forms}
				formValidation={(form, index) => checkFormValidation(form, index)}
				addForm={() => {
					addForm()
				}}
			/>
			{renderMultiplePrescriptions()}
			<PatientPrescriptionFormSave
				options={props.options}
				form={props.forms}
				formValidation={(form, index) => checkFormValidation(form, index)}
				addForm={() => {
					addForm()
				}}
			/>
		</div>
	)
}

export default injectIntl(observer(PatientPrescriptionContainer))
