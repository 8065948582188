import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import LabResultsStore from "../../../stores/LabResultsStore"

@injectIntl
@observer
export default class LabResultDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsLabResultDialog"
				open={LabResultsStore.isOpenTextDialog}
				onClose={() => {
					LabResultsStore.closeTextDialog()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info xs-relative">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								LabResultsStore.closeTextDialog()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-flask fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.value" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<div>{LabResultsStore.fullText}</div>
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => LabResultsStore.closeTextDialog()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
