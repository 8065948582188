"use strict"

import {observable, action} from "mobx"
import DDaction from "../actions/patientCard"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"
import api from "../../../modules/ambulance/actions/api"

class StornoPrescriptionDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable isWarning = false
	prescriptionId = null
	prescriptionIds = null

	@action open(warning, isWarning, id, ids) {
		this.isOpen = true
		this.warning = warning
		this.isWarning = isWarning
		this.prescriptionId = id
		this.prescriptionIds = ids
	}

	@action close() {
		this.isOpen = false
		this.prescriptionId = null
		this.prescriptionIds = null
		const providerID = getSelectedOrgUnitID()
		DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
			DataStore.setPatientEHR(overview)

			let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
			if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
				DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
			}
		})
		// DrugInteractionDialogStore.cancel()
	}

	@action async revertDelete() {
		if (isSafe(this.prescriptionId)) {
			api
				.resetRecordAfterDelete({_id: this.prescriptionId, _type: "EHR.Data.Medication", active: true})
				.call()
				.then(() => {
					this.close()
				})
		}

		if (isSafe(this.prescriptionIds)) {
			for (let i = 0; i < this.prescriptionIds.length; i++) {
				const id = this.prescriptionIds[i]
				await api.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true}).call()
			}
			this.close()
		}
	}
}

var singleton = new StornoPrescriptionDialogStore()
export default singleton
