import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "doctors_opinion_guns"
				},
				paperTemplateId: {
					value: "doctors_opinion_guns_Sevt"
				},

				doctorName: {},

				orgunitname: {},
				orgunitaddress: {},
				companyIdentifier: {},

				///

				paperNumber: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.paperNumber" />
				},

				name: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.name" />
				},
				birthdate: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.birthdate" />,
					type: "date"
				},

				address: {},
				addressForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.address" />
				},

				competencyTrueInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.competencyTrue" />,
					type: "checkbox",
					value: true
				},

				competencyFalseInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.competencyFalse" />,
					type: "checkbox",
					value: false
				},

				in: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.in" />
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
