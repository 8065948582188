"use strict"

import React from "react"
import {FormattedMessage} from "react-intl"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import Moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {defaultScheme} from "./consentBindings"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import fields from "./contractFields"
import DataStore from "../../../stores/DataStore"
import ContractFormStore from "../../../stores/ContractFormStore"
import api from "../../../actions/api"
import {saveBinding, updateBinding} from "./contractBindings"
import {
	loadDataToFormByScheme,
	deleteDataByScheme,
	resetForm,
	insertFormDataByScheme
} from "../../../../../global/helpers/bindings"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import {getUserCompanyInfo} from "../../../../../global/helpers/actions"
import {getFilters} from "../../../../../global/helpers/api"

@observer
export default class ConsentTableEx extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				logger("contract_id", form.$("contract_id").value)
				if (isEmpty(form.$("contract_id").value)) {
					const searchRequest = getFilters([`person_id=${props.id}`])

					api
						.getEmployee(form.$("company_id").value, searchRequest)
						.call()
						.then((res) => {
							if (isSafe(res)) {
								let request = {
									_id: isSafe(res.rows) && res.rows.length > 0 ? res.rows[0].relationship : "",
									_ref: false,
									_type: "Entity.Data.Employee",
									category: {
										_id: "Worker",
										_ref: true,
										_type: "Entity.CL.EmployeeCategory"
									},
									company: {
										_id: form.$("company_id").value,
										_ref: true,
										_type: "Entity.Data.Company"
									},
									entity: {
										_id: props.id,
										_ref: true,
										_type: "Entity.Data.Person"
									}
								}

								api
									.saveSpecificRel(request)
									.call()
									.then((employee) => {
										if (isSafe(employee) && isSafe(employee._id)) {
											insertFormDataByScheme(
												form,
												saveBinding,
												{employeeId: employee._id, contractId: ""},
												(res) => {
													if (isSafe(res)) {
														form.reset()
														ContractFormStore.isAdd = false
													}
													DataStore.getEmployeeContracts(props.id)
												},
												false,
												"Patient.form.contactForm.saveMessage"
											)
										}
									})
							}
						})
				} else {
					insertFormDataByScheme(
						form,
						updateBinding,
						{
							employeeId: form.$("employee_id").value,
							contractId: form.$("contract_id").value,
							companyId: form.$("company_id").value
						},
						(res) => {
							if (isSafe(res)) {
								form.reset()
								ContractFormStore.isAdd = false
							}
							DataStore.getEmployeeContracts(props.id)
						},
						false,
						"Patient.form.contactForm.saveMessage"
					)
				}
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		this.initForm()
	}

	form

	initForm() {
		resetForm(this.form, defaultScheme)
	}

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, saveBinding, {contractId: dataRow.delete}, (data) => {
			this.form.$("contract_id").value = data._id
			this.form.$("employee_id").value = data.employee._id
		})

		ContractFormStore.editable = true
		ContractFormStore.isAdd = true
	}

	deleteContract = () => {
		deleteDataByScheme(
			saveBinding,
			GlobalStore.confirmationDialogParams.contractId,
			() => DataStore.getEmployeeContracts(this.props.id),
			"Patient.form.contactForm.deleteMessage"
		)
		ContractFormStore.editable = false
		this.initForm()
	}

	render() {
		return (
			<div className="xs-consent-table-ex">
				<div className="xs-table">
					<XsTable
						config={{
							columnDefs: {
								// "capacity": {
								//   title: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
								//   type: "string",
								//   design: {
								//     width: "200px"
								//   },
								//   sortable: true
								// },
								org_unit_name_ext: {
									title: <FormattedMessage id="Patient.form.contractForm.contract" />,
									type: "string",
									sortable: true
								},
								from: {
									title: <FormattedMessage id="Common.label.from" />,
									type: "datetime",
									design: {
										width: "200px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? Moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								to: {
									title: <FormattedMessage id="Common.label.to" />,
									type: "datetime",
									design: {
										width: "200px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? Moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "42px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i
																	className="fal fa-trash-alt fa-lg xs-greyDefault"
																	data-tip
																	data-for="removeContract"
																/>
																<ReactTooltip id="removeContract" effect="solid">
																	<FormattedMessage id="Common.label.removeContract" />
																</ReactTooltip>
															</span>
														}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePatientContract", {contractId: props.value})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										capacity: dataRow.capacity,
										org_unit_name_ext: dataRow.org_unit_name_ext,
										from: isSafe(dataRow.validity) ? dataRow.validity.from : "",
										to: isSafe(dataRow.validity) ? dataRow.validity.to : "",
										delete: dataRow.employment_contract_id
									}
								}
							},
							dataSource: DataStore.patientDTO.get("contracts")
						}}
					/>
				</div>
				<div className="xs-form">
					{ContractFormStore.isAdd ? (
						<form>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<XsAutocomplete
										field={this.form.$("org_unit")}
										filterValue="provider_id"
										minLengthForSearch="3"
										api={api.loadEntityOrgUnits}
										addSpecialFilter={() => {
											let company = getUserCompanyInfo()
											return [
												{
													associated_column: "object_owner",
													predicate: "=",
													values: [
														{
															id_value: company.object_owner
														}
													]
												}
											]
										}}
										white={true}
										// disabled={ContactPersonFormStore.contactPersonID ? true : false}
										// filterValue={"personnel_expertise_id"}
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj.provider_id}
										postAction={(obj) => {
											this.form.$("company_id").value = obj.company_id
										}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Patient.form.contractForm.code_ext" />,
														design: {
															width: "25%"
														},
														type: "string"
														// dbName: "code_ext",
														// filter: {
														//   gridWidth: 3,
														//   defaultValue: "",
														//   renderElement: "input"
														// }
													},
													name_ext: {
														title: <FormattedMessage id="Patient.form.contractForm.name_ext" />,
														type: "string",
														// dbName: "name_ext",
														dbName: "search_column",
														design: {
															width: "75%"
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												},
												filterValue: [
													{
														associated_column: "object_owner",
														predicate: "=",
														values: [
															{
																id_value: getUserCompanyInfo().object_owner
															}
														]
													}
												]
											}
										}}
									/>
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("from")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("to")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={5} className="xs-form-actions">
									{ContractFormStore.editable ? (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.save" />}
											icon={<SaveIcon />}
										/>
									) : (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.add" />}
											icon={<SaveIcon />}
										/>
									)}
									<XsButton
										className="xs-default xs-outline"
										onClick={() => {
											this.initForm()
											ContractFormStore.isAdd = false
											ContractFormStore.editable = false
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
									/>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								ContractFormStore.isAdd = true
								ContractFormStore.editable = false
								// this.initForm()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					type="danger"
					name="xsDeletePatientContract"
					text={<FormattedMessage id="Patient.form.employeeCardContactForm.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.employeeContact.delete"
					}}
					onConfirmation={() => this.deleteContract()}
				/>
			</div>
		)
	}
}
