"use strict"

import {action} from "mobx"
import api from "../actions/api"
import UIStore from "./UIStore"
import DataStore from "./DataStore"
import RouterStore from "../../../global/store/RouterStore"
import WarningStore from "../../../global/store/WarningStore"
import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"

class PatientInfoFormStore {
	warningText = ""

	formRef = null

	@action loadDataFromEhealth(id) {
		UIStore.isFormSaving = true
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetPatientSummaryContactInformation`,
			body: JSON.stringify({
				ClassName: "DajPacientskySumarKontaktneUdaje_v3",
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
					// WarningStore.open(res.ErrorMessage, true)
					GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
				} else {
					if (res.Count1 == 0 && res.Count2 == 0) {
						WarningStore.open(this.warningText)
					}
				}
				UIStore.isFormSaving = false
				RouterStore.push(`/patient?id=${id}`)
				UIStore.patientDetailTab = "PersonalInfo"
			})
			.catch(() => {
				UIStore.isFormSaving = false
				RouterStore.push(`/patient?id=${id}`)
				setTimeout(() => {
					UIStore.patientDetailTab = "PersonalInfo"
				}, 0)
			})
	}

	@action updateDataInEhealth() {
		UIStore.isFormSaving = true
		const patientId = DataStore.patientDTO.get("patientID")
		const personalIdNumber = DataStore.patientDTO.get("patient").identifier
		if (isNotEmpty(patientId)) {
			api
				.loadEhealthPatientContacts(patientId)
				.call()
				.then((response) => {
					UIStore.isFormSaving = false
					if (isNotEmpty(response.xml_data_block) && isNotEmpty(response.xml_envelope)) {
						const selectedOrgUnit = getSelectedOrgUnit()
						const user = getUser()
						const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
						const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
						const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
						const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
						const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

						const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
							? DataStore.patientDTO.get("patient").validInfo.client
							: null
						const JRUZIDObj = isSafe(patientClientInfo.identifiers)
							? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
							: null

						GWApiCall({
							method: "POST",
							uri: `${config.GW_BASE_URL}/UpdatePatientSummaryContactInformation`,
							body: JSON.stringify({
								ClassName: "ZapisPacientskehoSumaruKontaktneUdaje_v3",
								XMLEnvelope: response.xml_envelope,
								XMLData: response.xml_data_block,
								AmbeePatientId: patientId,
								PatientIdentificationNumber: personalIdNumber,
								PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
								AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
								AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
								AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
								AmbeePersonnelId: getUserPersonnelID(),
								OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
								PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
								PersonnelExpertiseJRUZVersion: specVer,
								PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
								RecordSignerData: isSafe(response.record_signer_data)
									? response.record_signer_data.map((x) => {
											return {
												Ref: x._ref,
												Type: x._type,
												RecordClass: x.record_class,
												RecordExternalId: x.record_external_id,
												RecordId: x.record_id
											}
									  })
									: null
							})
						})
							.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
							.then((res) => {
								if (isSafe(res)) {
									if (isSafe(res.ErrorMessage)) {
										// WarningStore.generateErrorMessage(res.IsEhealthException, res.ErrorMessage)
										GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
									} else {
										GWApiCall({
											method: "POST",
											uri: `${config.GW_BASE_URL}/GetPatientSummaryContactInformation`,
											body: JSON.stringify({
												ClassName: "DajPacientskySumarKontaktneUdaje_v3",
												AmbeePatientId: patientId,
												PatientIdentificationNumber: personalIdNumber,
												PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
												AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
												AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
												AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
												AmbeePersonnelId: getUserPersonnelID(),
												OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
												PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
												PersonnelExpertiseJRUZVersion: specVer,
												PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
											})
										})
											.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
											.then((resGet) => {
												if (isSafe(resGet)) {
													if (isSafe(resGet.ErrorMessage)) {
														GlobalStore.checkEHGWError(resGet.ErrorCode, resGet.ErrorMessage, resGet.IsEhealthException)
														// WarningStore.generateErrorMessage(resGet.IsEhealthException, resGet.ErrorMessage)
													} else {
														if (resGet.Count1 == 0 && resGet.Count2 == 0) {
															WarningStore.open(this.warningText)
														}
													}
												}
											})
											.catch((err) => logger(err))
									}
								}
							})
							.catch((err) => logger(err))
					}
				})
				.catch(() => {
					UIStore.isFormSaving = false
				})
		}
	}
}

var singleton = new PatientInfoFormStore()
export default singleton
